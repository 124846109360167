import { fn, db } from "../../database";
import * as actionTypes from './actionTypes';
import Moment from 'moment';

const smsRef = db.collection('messages');
const userRef = db.collection('users');

const sendSmsStart = () => ({
  type: actionTypes.SEND_SMS_START
});

const sendSmsSuccess = (payload) => ({
  type: actionTypes.SEND_SMS_SUCCESS,
  payload
});

const sendSmsFail = (error) => ({
  type: actionTypes.SEND_SMS_FAIL,
  error
});

const sendHelpSmsStart = () => ({
  type: actionTypes.SEND_HELP_SMS_START
});

const sendHelpSmsSuccess = () => ({
  type: actionTypes.SEND_HELP_SMS_SUCCESS,
});

const sendHelpSmsFail = (error) => ({
  type: actionTypes.SEND_HELP_SMS_FAIL,
  error
});

// const scheduleSmsStart = () => ({
//   type: actionTypes.SCHEDULE_SMS_START
// });

// const scheduleSmsSuccess = () => ({
//   type: actionTypes.SCHEDULE_SMS_SUCCESS
// });

// const scheduleSmsFail = (error) => ({
//   type: actionTypes.SCHEDULE_SMS_FAIL,
//   error
// });

const sendPostSurveyStart = (uid) => ({
  type: actionTypes.SEND_POST_SURVEY_START,
  payload: {uid}
});

const sendPostSurveySuccess = () => ({
  type: actionTypes.SEND_POST_SURVEY_SUCCESS
});

const sendPostSurveyFail = (error) => ({
  type: actionTypes.SEND_POST_SURVEY_FAIL,
  error
});

export const sendSms = ({phoneNumber, url, language, template, organization}) => dispatch => {
  dispatch(sendSmsStart());
  const sendSignInSms = fn.httpsCallable('sendSignInSms');
  return sendSignInSms({phoneNumber, url, language, template, organization}).then(
    response => {
      dispatch(sendSmsSuccess(response));
      return response;
  }).catch((error) => {
    dispatch(sendSmsFail(error));
    throw error;
  })
};

export const sendWhatsApp = ({phoneNumber, url, language, template, organization}) => dispatch => {
  dispatch(() => ({type: "whatsapp/START"}));
  const functionInviteViaWhatsApp = fn.httpsCallable('sendInviteWhatsApp');
  return functionInviteViaWhatsApp({phoneNumber, url, language, template, organization}).then(
    response => {
      dispatch(() => ({type: "whatsapp/SUCCESS", payload: response}));
      return response;
  }).catch((error) => {
    dispatch(() => ({type: "whatsapp/ERROR", error}));
    throw error;
  })
};

export const sendHelpSms = (data) => dispatch => {
  dispatch(sendHelpSmsStart());
  const sendHelpSms = fn.httpsCallable('sendHelpSms');
  sendHelpSms({phoneNumber: data.phoneNumber, userId: data.userId}).then(() => {
    dispatch(sendHelpSmsSuccess())
  }).catch((error) => {dispatch(sendHelpSmsFail(error))})
};

/// Schedule reminder to be sent before appointment date
// export const scheduleSms = (smsContent) => dispatch => {
//   dispatch(scheduleSmsStart());
//   smsRef.add(smsContent).then( (doc) => {
//     if (!doc || !doc.id) {
//       dispatch(scheduleSmsFail(new Error('Failed to schedule a sms message!')));
//     } else {
//       dispatch(scheduleSmsSuccess());
//     }
//   }).catch((error) => {
//     dispatch(scheduleSmsFail(error));
//   });
// };


export const sendPostSurvey = (messageContent) => dispatch => {
  dispatch(sendPostSurveyStart(messageContent.uid));
  userRef.doc(messageContent.uid)
  .get()
  .then(user => {
    const apptDate = user.data().appointmentDate; // TODO switch to new appointments system
    if (apptDate) {
      const message = {
          content: messageContent.content,
          queueDate: Moment(new Date(apptDate)).add(2, 'days').format('MM/DD/YYYY'),
          sentDatetime: '',
          patientPhone: user.data().phone,
          patientEmail: user.data().email,
          sid: '',
          uid: user.id,
          doa: apptDate
        }
        // dispatch(scheduleSms(message));
      }
    }).catch((error) => {
      // console.error(error);
      dispatch(sendPostSurveyFail(error));
    });
}