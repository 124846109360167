import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { createUserTicket, sendEmail } from "../../../store/actions";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import Button from "react-bootstrap/Button";
import { sendHelpSms } from "../../../store/actions";
import {
  FaQuestionCircle,
  FaCheck,
} from "react-icons/fa";
import { alertEvent } from "../GlobalAlerts";

import { getCurrentQuestionnaire } from "../../../store/slices/questionnaire";
import { useTranslation } from "react-i18next";

// todo: validate the email and phone number inputs are valid
const FloatingAction = ({ onSendHelpMessage, userId, isSend = false, context, sessionDataId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [params, setParams] = useState({
    'name':'',
    'email':'',
    'countryCode': '',
    'phoneNumber': '',
    'message': ''
  });

   const names = {
    'name': 'Name',
    'email':'Email',
    'phoneNumber': 'Phone Number',
    'countryCode': 'Country Code',
    'message': 'Message'
  };

  const currentQ = useSelector(s => getCurrentQuestionnaire(s));
  const accessKey = useSelector(s => s.auth.accessKey);
  const questionnaireNavData = useSelector(s => s.questionnaire.navigation);
  const [t, i18n] = useTranslation();

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    if (typeof e.target.value !== 'undefined' && typeof e.target.name !== 'undefined') {
      setParams({
        ...params,
        [e.target.name]: e.target.value});
    }
  }

  const handleShow = () => {
    setShow(!show);
  };

  const dispatch = useDispatch();

  // todo: add option to enter either email or phone number and validate
  const sendMessage = (e) => {
    e.preventDefault();

    let info = [];
    Object.keys(params).map((key, value) => {
      info.push({
        field: names[key],
        details: params[key],
      });
    });

    
    if (params.name && params.phoneNumber && params.message) {
      dispatch(createUserTicket({
        ...params,
        userId: userId || "",
        t_created: Date.now(),
        questionnaire: currentQ?.screener,
        language: i18n.language || "none",
        context,
        sessionDataId,
        questionnaireNavData,
        accessKey,
        currentURL: window.location.href,
        status: "new"
      })).then(success => {
        if (success?.meta?.requestStatus === "fulfilled") {
          dispatch(alertEvent("Successfully sent your message!"));
          setShow(false);
        } else {
          setErrorMessage("Could not send email due to a server problem!");
          console.error(success);
        }
      }, err => {
        setErrorMessage("Could not send email due to a server problem!");
        console.error(err);
      })
    } else {
      // TODO: all the other error handling here
      setErrorMessage("Please fill in the fields marked with *.");
    }
  };

  return (
    <>
      {show && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            right: "1rem",
            marginBottom: "7rem",
            zIndex: 20
          }}
        >
          <span
            style={{
              float: "right",
              color: "gray",
              cursor: "pointer",
              padding: "0.2rem",
            }}
            onClick={handleShow}
          >
            <CloseSharpIcon />
          </span>
          <div
            className="shadow-sm p-md-3 mb-5 rounded"
            style={{ background: "white", width: "20rem", minHeight: "20rem" }}
          >
            <div style={{ padding: "0.5rem", textAlign: "center" }}>
              <h6>Hi! Do you need help?</h6>
              {
                <>
                  <div style={{ textAlign: "left", padding: "0.25rem" }}>
                    <small>
                      We're happy to talk to you! Let us know what you're having
                      trouble with and we'll get in touch.
                    </small>{" "}
                    <br />
                    <small className="text-danger">
                      <em>
                        This form is for technical support; please do not enter
                        any health information into your message! If you need
                        medical help, please contact your provider directly.
                      </em>
                    </small>{" "}
                    <br />

                    <Form>

                      <Form.Group>
                      <small>
                        <strong>Full Name*</strong>
                      </small>{" "}
                        <Form.Control
                          name="name"
                          type="text"
                          placeholder="Your name"
                          value={params['name']}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>

                      <Form.Group>
                      <small>
                        <strong>Phone Country Code</strong>
                      </small>{" "}
                        <Form.Control
                          name="countryCode"
                          type="text"
                          placeholder="+001"
                          value={params['countryCode']}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>

                      <Form.Group>
                      <small>
                        <strong>Phone Number*</strong>
                      </small>{" "}
                        <Form.Control
                          name="phoneNumber"
                          type="text"
                          placeholder="555-555-0123"
                          value={params['phoneNumber']}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>

                      <Form.Group>
                      <small>
                        <strong>Email</strong>
                      </small>{" "}
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="name@example.com"
                          value={params['email']}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>

                      <Form.Group>
                      <small>
                        <strong>Message*</strong>
                      </small>{" "}
                        <Form.Control
                          name="message"
                          type="textarea"
                          placeholder="Message"
                          value={params['message']}
                          onChange={(e) => handleChange(e)}
                        />
                    </Form.Group>
                    {errorMessage ? <div className="small text-danger">{errorMessage}</div> : null}
                      <Button
                        type="submit"
                        className="btn btn-info"
                        style={{ margin: "0.5rem" }}
                        onClick={sendMessage}
                      >
                        <FaCheck /> Send
                      </Button>
                    </Form>
                  </div>
                </>
              }

              {/* <small>Click the button below and someone will reach out to you shortly.</small><br/>
              <Button className="btn btn-info" style={{margin: "0.5rem"}} onClick={handlePingHelp}><FaMobileAlt/> Ping Me!</Button> */}
            </div>
          </div>
        </div>
      )}
      <div style={{ position: "fixed", bottom: "5rem", right: "0" }}>
        <div className={classes.root} style={{ right: "0" }}>
          <Fab
            style={{ color: "#E14798" }}
            aria-label="add"
            onClick={handleShow}
          >
            <FaQuestionCircle
              style={{ height: "4rem", width: "4rem", borderRadius: "3rem", backgroundColor: "#EEEEEE" }}
            />
            {/* <img src="/images/pin-icon.png" style={{height: '4rem', width: '4rem', borderRadius: "3rem"}} /> */}
          </Fab>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendHelpMessage: (data) => dispatch(sendHelpSms(data))
  };
};

function mapStateToProps(state, ownProps) {
  return {
    userId: state.auth.user.uid,
    isLoading: state.email.isLoading,
    isSent: state.email.isSent,
    isError: state.email.isError,
    sessionDataId: state.auth.sessionDataId
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FloatingAction);