import React from "react";
import { Container } from "../UI/Grid/Grid";

const PrivacyNotice = () => (
  <Container className="mt-5">
    <h2 className="text-center text-viridian-green h1">Privacy Policy</h2>
    <p className="text-center text-muted small">Last modified: May 13, 2020</p>
    <h3 className="heading3 text-viridian-green mt-5">Introduction</h3>

    <p>
      LiteraSeed, Inc (<strong>"Company"</strong> or <strong>"We"</strong>)
      respect your privacy and are committed to protecting it through our
      compliance with this policy.
    </p>
    <p>
      This policy describes the types of information we may collect from you or
      that you may provide when you visit the website{" "}
      <a href="/home">literaseed.io</a> (our <strong>"Website"</strong>) and our
      practices for collecting, using, maintaining, protecting, and disclosing
      that information.
    </p>

    <p>This policy applies to information we collect:</p>
    <ul className="list">
      <li>On this Website.</li>
      <li>
        In email, text, and other electronic messages between you and this
        Website.
      </li>
      <li>
        Through mobile and desktop applications you download from this Website,
        which provide dedicated non-browser-based interaction between you and
        this Website.
      </li>
      <li>
        When you interact with our advertising and applications on third-party
        websites and services if those applications or advertising include links
        to this policy.
      </li>
      <li>User-generated data.</li>
    </ul>

    <p>It does not apply to information collected by:</p>
    <ul className="list">
      <li>
        us offline or through any other means, including on any other website
        operated by Company or any third party (including our affiliates and
        subsidiaries); or{" "}
      </li>
      <li>
        any third party (including our affiliates and subsidiaries), including
        through any application or content (including advertising) that may link
        to or be accessible from or on the Website.
      </li>
    </ul>

    <p>
      Please read this policy carefully to understand our policies and practices
      regarding your information and how we will treat it. If you do not agree
      with our policies and practices, your choice is not to use our Website. By
      accessing or using this Website, you agree to this privacy policy. This
      policy may change from time to time (see Changes to Our Privacy Policy).
      Your continued use of this Website after we make changes is deemed to be
      acceptance of those changes, so please check the policy periodically for
      updates.
    </p>

    <h3 className="heading3 text-viridian-green mt-5">
      Children Under the Age of 13
    </h3>
    <p>
      Our Website is not intended for children under 13 years of age. No one
      under age 13 may provide any personal information to or on the Website. We
      do not knowingly collect personal information from children under 13. If
      you are under 13, do not use or provide any information on this Website or
      on or through any of its features/register on the Website, make any
      purchases through the Website, use any of the interactive or public
      comment features of this Website or provide any information about yourself
      to us, including your name, address, telephone number, email address, or
      any screen name or user name you may use. If we learn we have collected or
      received personal information from a child under 13 without verification
      of parental consent, we will delete that information. If you believe we
      might have any information from or about a child under 13, please contact
      us at hello@literaseed.io.
    </p>

    <h3 className="heading3 text-viridian-green mt-5">
      Information We Collect About You and How We Collect It
    </h3>
    <p>
      We collect several types of information from and about users of our
      Website, including information:
    </p>
    <ul className="list">
      <li>
        By which you may be personally identified, such as name, username, date
        of birth, native language, country of national origin, e-mail address,
        telephone number, symptoms of present condition, comprehensive medical
        history including: past diagnoses, drugs, allergies, social history and
        any healthcare information that might be important for a patient/user to
        provide to their doctor or for keeping track of their health using our
        Website, or ANY OTHER INFORMATION THE WEBSITE COLLECTS THAT IS DEFINED
        AS PERSONAL OR PERSONALLY IDENTIFIABLE INFORMATION UNDER AN APPLICABLE
        LAW/any other identifier by which you may be contacted online or offline
        (<strong>"personal information"</strong>);
      </li>
      <li>
        That is about you but individually does not identify you, such as your
        healthcare provider, and appointment time and day; and/or
      </li>
      <li>
        About your internet connection, the equipment you use to access our
        Website and usage details.
      </li>
    </ul>

    <p>We collect this information:</p>
    <ul className="list">
      <li>Directly from you when you provide it to us.</li>
      <li>
        Automatically as you navigate through the site. Information collected
        automatically may include usage details, IP addresses, and information
        collected through cookies, web beacons, and other tracking technologies.
        This Information shall be used to verify the user information and ensure
        security.
      </li>
      <li>From third parties, for example, our business partners.</li>
    </ul>

    <h3 className="heading3 text-viridian-green mt-5">
      Information You Provide to Us.
    </h3>
    <p>The information we collect on or through our Website may include:</p>
    <ul className="list">
      <li>
        Information that you provide by filling in forms on our Website. This
        includes information provided at the time of registering to use our
        Website, subscribing to our service, or requesting further services. We
        may also ask you for information when you report a problem with our
        Website.
      </li>
      <li>
        Records and copies of your correspondence (including email addresses),
        if you contact us.
      </li>
      <li>
        Your responses to surveys that we might ask you to complete for research
        purposes.
      </li>
      <li>
        Images of prescriptions, medications, health insurance cards, et cetera.
      </li>
    </ul>

    <h3 className="heading3 text-viridian-green mt-5">
      Information We Collect Through Automatic Data Collection Technologies.
    </h3>
    <p>
      As you navigate through and interact with our Website, we may use
      automatic data collection technologies to collect certain information
      about your equipment, browsing actions, and patterns, including:
    </p>
    <ul className="list">
      <li>
        Details of your visits to our Website, including traffic data, location
        data, logs, and other communication data and the resources that you
        access and use on the Website.
      </li>
      <li>
        Information about your computer and internet connection, including your
        IP address, operating system, and browser type.
      </li>
    </ul>
    <p>
      We also may use these technologies to collect information about your
      online activities over time and across third-party websites or other
      online services (behavioral tracking).
    </p>

    <p>
      The information we collect automatically is statistical data and may
      include personal information, but/or we may maintain it or associate it
      with personal information we collect in other ways or receive from third
      parties. It helps us to improve our Website and to deliver a better and
      more personalized service, including by enabling us to:
    </p>
    <ul className="list">
      <li>Estimate our audience size and usage patterns.</li>
      <li>
        Store information about your preferences, allowing us to customize our
        Website according to your individual interests.
      </li>
      <li>Speed up your searches.</li>
      <li>Recognize you when you return to our Website.</li>
    </ul>

    <p>
      The technologies we use for this automatic data collection may include:
    </p>
    <ul className="list">
      <li>
        <strong>Cookies (or browser cookies).</strong> A cookie is a small file
        placed on the hard drive of your computer. You may refuse to accept
        browser cookies by activating the appropriate setting on your browser.
        However, if you select this setting you may be unable to access certain
        parts of our Website. Unless you have adjusted your browser setting so
        that it will refuse cookies, our system will issue cookies when you
        direct your browser to our Website.
      </li>
      <li>
        <strong>Flash Cookies.</strong> Certain features of our Website may use
        local stored objects (or Flash cookies) to collect and store information
        about your preferences and navigation to, from, and on our Website.
        Flash cookies are not managed by the same browser settings as are used
        for browser cookies. For information about managing your privacy and
        security settings for Flash cookies, see Choices About How We Use and
        Disclose Your Information.
      </li>
      <li>
        <strong>Web Beacons.</strong>Pages of our the Website and our e-mails
        may contain small electronic files known as web beacons (also referred
        to as clear gifs, pixel tags, and single-pixel gifs) that permit the
        Company, for example, to count users who have visited those pages or
        opened an email and for other related website statistics (for example,
        recording the popularity of certain website content and verifying system
        and server integrity).
      </li>
    </ul>
    <p>
      [We do not collect personal information automatically, but we may tie this
      information to personal information about you that we collect from other
      sources or you provide to us.]
    </p>

    <h3 className="heading3 text-viridian-green mt-5">
      Third-Party Use of Cookies and Other Tracking Technologies.
    </h3>
    <p>
      Some content or applications, including advertisements, on the Website are
      served by third parties, including advertisers, ad networks and servers,
      content providers, and application providers. These third parties may use
      cookies [alone or in conjunction with web beacons or other tracking
      technologies] to collect information about you when you use our website.
      The information they collect may be associated with your personal
      information or they may collect information, including personal
      information, about your online activities over time and across different
      websites and other online services. They may use this information to
      provide you with interest-based (behavioral) advertising or other targeted
      content.
    </p>
    <p>
      We do not control these third parties' tracking technologies or how they
      may be used. If you have any questions about an advertisement or other
      targeted content, you should contact the responsible provider directly.
      For information about how you can opt out of receiving targeted
      advertising from many providers, see Choices About How We Use and Disclose
      Your Information.
    </p>

    <h3 className="heading3 text-viridian-green mt-5">
      How We Use Your Information.
    </h3>
    <p>
      We use information that we collect about you or that you provide to us,
      including any personal information:
    </p>
    <ul className="list">
      <li>To present our Website and its contents to you.</li>
      <li>
        To provide you with information, products, or services that you request
        from us, including personalized health data and reports.
      </li>
      <li>To improve service via internal algorithms.</li>
      <li>To fulfill any other purpose for which you provide it.</li>
      <li>
        To provide you with notices about your account, including expiration and
        renewal notices.
      </li>
      <li>
        To carry out our obligations and enforce our rights arising from any
        contracts entered into between you and us, including for billing and
        collection.
      </li>
      <li>
        To notify you about changes to our Website or any products or services
        we offer or provide though it.
      </li>
      <li>
        To aggregate and anonymize data for research and quality assurance
        purposes.
      </li>
      <li>To deliver analytics to healthcare providers.</li>
      <li>
        In any other way we may describe when you provide the information.
      </li>
      <li>For any other purpose with your consent.</li>
    </ul>

    <h3 className="heading3 text-viridian-green mt-5">
      Disclosure of Your Information.
    </h3>
    <p>
      We may disclose aggregated information about our users, and information
      that does not identify any individual, without restriction.{" "}
    </p>
    <p>
      We may disclose personal information that we collect or you provide as
      described in this privacy policy:
    </p>
    <ul className="list">
      <li>To our subsidiaries and affiliates.</li>
      <li>
        To contractors, service providers (such as partnered healthcare
        organizations; partnered researchers; and language service providers for
        translation services) and other third parties we use to support our
        business and who are bound by contractual obligations to keep personal
        information confidential and use it only for the purposes for which we
        disclose it to them.
      </li>
      <li>
        To a buyer or other successor in the event of a merger, divestiture,
        restructuring, reorganization, dissolution, or other sale or transfer of
        some or all of LiteraSeed, Inc's assets, whether as a going concern or
        as part of bankruptcy, liquidation, or similar proceeding, in which
        personal information held LiteraSeed, Inc about our Website users is
        among the assets transferred.
      </li>
      <li>
        To third parties to market their products or services to you if you have
        consented to these disclosures. For more information, see Choices About
        How We Use and Disclose Your Information.
      </li>
      <li>
        To fulfill the purpose for which you provide it. For example, if you
        give us an email address to use the "email a friend" feature of our
        Website, we will transmit the contents of that email and your email
        address to the recipients.
      </li>
      <li>
        For any other purpose disclosed by us when you provide the information.
      </li>
      <li>With your consent.</li>
      <li>
        To our third-party providers, such as Google Cloud Services, Google G
        Suite, and Mailgun.
      </li>
    </ul>
    <p>We may also disclose your personal information:</p>
    <ul className="list">
      <li>
        To comply with any court order, law, or legal process, including to
        respond to any government or regulatory request.
      </li>
      <li>
        To enforce or apply our <a href="/termsofservice">terms of use</a> or
        terms of sale and other agreements, including for billing and collection
        purposes.
      </li>
    </ul>
    <p>
      If we believe disclosure is necessary or appropriate to protect the
      rights, property, or safety of LiteraSeed, Inc, our customers, or others.
    </p>

    <h3 className="heading3 text-viridian-green mt-5">
      Choices About How We Use and Disclose Your Information.
    </h3>
    <p>
      We strive to provide you with choices regarding the personal information
      you provide to us. We have created mechanisms to provide you with the
      following control over your information:
    </p>
    <ul className="list">
      <li>
        <strong>Tracking Technologies and Advertising.</strong> You can set your
        browser to refuse all or some browser cookies, or to alert you when
        cookies are being sent. To learn how you can manage your Flash cookie
        settings, visit the Flash player settings page on Adobe's website. If
        you disable or refuse cookies, please note that some parts of this site
        may then be inaccessible or not function properly.
      </li>
      <li>
        <strong>Promotional Offers from the Company.</strong> If you do not wish
        to have your email address/contact information used by the Company to
        promote our own or third parties' products or services, you can opt-out
        by checking the relevant box located on the form on which we collect
        your data (the registration form) or at any other time by logging into
        the Website and adjusting your user preferences in your account profile
        by checking or unchecking the relevant boxes or by sending us an email
        stating your request to hello@literaseed.io. If we have sent you a
        promotional email, you may send us a return email asking to be omitted
        from future email distributions. This opt out does not apply to
        information provided to the Company as a result of a product purchase,
        warranty registration, product service experience or other transactions.
      </li>
      <li>
        We do not control third parties' collection or use of your information
        to serve interest-based advertising. However, these third parties may
        provide you with ways to choose not to have your information collected
        or used in this way. You can opt out of receiving targeted ads from
        members of the Network Advertising Initiative (<strong>"NAI"</strong>)
        on the NAI's website.
      </li>
    </ul>

    <h3 className="heading3 text-viridian-green mt-5">
      Your California Privacy Rights.
    </h3>
    <p>
      California Civil Code Section §1798.83 permits users of our Website that
      are California residents to request certain information regarding our
      disclosure of personal information to third parties for their direct
      marketing purposes. To make such a request, please send an email to{" "}
      <strong>hello@literaseed.io</strong> or write us at: PO Box 86714 Phoenix,
      Arizona 85080.
    </p>

    <h3 className="heading3 text-viridian-green mt-5">Data Security</h3>
    <p>
      We have implemented measures designed to secure your personal information
      from accidental loss and from unauthorized access, use, alteration, and
      disclosure. All information you provide to us is stored by Google Cloud
      Services under a business associate agreement (“BAA”) and compliant with
      the Health Insurance Portability and Accountability Act (“HIPAA”). Any
      payment transactions will be encrypted using SSL technology.
    </p>
    <p>
      Although the guidelines of HIPAA require data storage for at least six
      years from the later of the date of creation or the last effective date,
      we commit to retaining data for ten years.
    </p>
    <p>
      The safety and security of your information also depends on you. Where we
      have given you (or where you have chosen) a password for access to certain
      parts of our Website, you are responsible for keeping this password
      confidential. We ask you not to share your password with anyone.
    </p>
    <p>
      Unfortunately, the transmission of information via the internet is not
      completely secure. Although we do our best to protect your personal
      information, we cannot guarantee the security of your personal information
      transmitted to our Website. Any transmission of personal information is at
      your own risk. We are not responsible for circumvention of any privacy
      settings or security measures contained on the Website.
    </p>

    <h3 className="heading3 text-viridian-green mt-5">
      Health Insurance Portability and Accountability Act (“HIPAA”) Disclosure.
    </h3>
    <p>
      Pursuant to HIPAA rules, we pledge to do the following regarding your
      personal health information:
    </p>
    <ul className="list">
      <li>
        Not use or disclose protected health information other than as permitted
        or required by this policy or as required by law;
      </li>
      <li>
        Use appropriate safeguards, and comply with Subpart C of 45 CFR Part 164
        with respect to electronic protected health information, to prevent use
        or disclosure of protected health information other than as provided for
        by this policy;
      </li>
      <li>
        Implement technical policies and procedures that allow only authorized
        persons to access electronic protected health information (e-PHI);
      </li>
      <li>
        Implement hardware, software, and/or procedural mechanisms to record and
        examine access and other activity in information systems that contain or
        use e-PHI;
      </li>
      <li>
        Implement policies and procedures to ensure that e-PHI is not improperly
        altered or destroyed;
      </li>
      <li>
        Implement technical security measures that guard against unauthorized
        access to e-PHI that is being transmitted over an electronic network;
        and
      </li>
      <li>
        Periodically review and update its documentation in response to
        environmental or organizational changes that affect the security of
        electronic protected health information (e-PHI).
      </li>
    </ul>
    <p>
      LiteraSeed, Inc is not a healthcare provider and cannot diagnose or treat
      health issues and cannot be held liable for any injury to an individual or
      individuals as a result of undiagnosed or misdiagnosed health issues.
    </p>

    <h3 className="heading3 text-viridian-green mt-5">
      Changes to Our Privacy Policy.
    </h3>
    <p>
      It is our policy to post any changes we make to our privacy policy on this
      page with a notice that the privacy policy has been updated on the Website
      home page. If we make material changes to how we treat our users' personal
      information, we will notify you by email to the primary email address
      specified in your account and/or through a notice on the Website home
      page. The date the privacy policy was last revised is identified at the
      top of the page. You are responsible for ensuring we have an up-to-date
      active and deliverable email address for you, and for periodically
      visiting our Website and this privacy policy to check for any changes.
    </p>

    <h3 className="heading3 text-viridian-green mt-5">Contact Information</h3>
    <p>
      To ask questions or comment about this privacy policy and our privacy
      practices, contact us at:
    </p>
    <p>LiteraSeed, Inc.</p>
    <p>1902 W Union Hills Dr PO Box 86714</p>
    <p>Phoenix, Arizona 85027-9998</p>
    <p>hello@literaseed.io</p>
  </Container>
);

export default PrivacyNotice;
