import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { FaBook, FaHandRock } from "react-icons/fa";
import { sendFeedback, updateFeedback } from "../../store/actions";
import PlayAudio from "../Audio/PlayAudio";
import { Row } from "../UI/Grid/Grid";
import { educationActions, getAllModules } from "../../store/slices/education";
import { evaluator } from "../../utils/evaluator";
import { Card } from "../UI/Card/Card";
import { useHistory } from "react-router-dom";
import { SIMPLE_ED_MODULE } from "../../constants/routes";
import { CardImg, Modal } from "react-bootstrap";
import { getCurrentQuestionnaire } from "../../store/slices/questionnaire";
import { CardBody } from "react-bootstrap/Card";


const EmailConfirmation = ({ userId, accountType, email }) => {

  const [feedbackFreeText, setFeedbackFreeText] = useState("");
  const [feedback, setFeedback] = useState();
  const [feedbackId, setFeedbackId] = useState(null); // used to display followup modal
  const feedbackHandler = (value) => {
    setFeedback(value);
    dispatch(sendFeedback({rating: value}, userId, new Date())).then(feedbackId => {
        setFeedbackId(feedbackId);
    })
  };
  const addFeedbackFollowup = () => {
    dispatch(updateFeedback({id: feedbackId, freeText: feedbackFreeText})).then(() => {
      setFeedbackId(null);
    });
  }
  const audioFileName = 'email_confirmation.mp3';

  const dispatch = useDispatch();
  const history = useHistory();
  const modules = useSelector(getAllModules);
  const answers = useSelector(s => s.questionnaire.answers);
  const questionnaire = useSelector(getCurrentQuestionnaire);
  const [activeModules, setActiveModules] = useState([]);
  useEffect(() => {
    if (!Array.isArray(modules)) {
      if (Array.isArray(activeModules)) setActiveModules([]);
      return;
    }
    const newActive = modules.filter(m => m.displayWhen && evaluator(m.displayWhen, answers, questionnaire, true));
    setActiveModules(newActive);
  }, [modules]);

  function goToModule (module) {
    dispatch(educationActions.startModule(module.name));
    history.push(SIMPLE_ED_MODULE);
  }

  const clinicianTerm = useSelector(s => s.auth.organization?.terminology?.clinicianUser || "medical provider");

  return (
    <div
      className="report p-md-3 mb-5 rounded"
    >
        <div style={{ textAlign: "center", margin: "5%" }}>
            <h2 className="text-dark-blue">Success!</h2>
            <hr className="modal-card-hr"/>
            <br/>
            <p className="body-text text-center">
            Your answers have been sent to your {clinicianTerm}!
          </p>

          <img style={{display: "block", margin: "0 auto", paddingBottom: "6px", maxWidth: "150px"}} src="/images/Success_Page_2.png" alt="success"/>
          <small><i style={{color: "grey"}}>
          Your answers will not be seen until your appointment. If your health or symptoms get worse, call your {clinicianTerm}'s office and seek medical attention immediately.
          </i></small>
        </div>
        <div style={{ textAlign: "center", padding: "1em" }}>
          {!feedback && (
            <>
            <p className="body-text text-center pb-2">
              How would you rate your experience?
              {" "}<PlayAudio filename={audioFileName} />
            </p>
            <div style={{display: "inline-flex"}}>
            <Card
            style={{margin: "6px"}}
                onClick={() => feedbackHandler("Satisfied")}
              >
                <CardImg style={{width: "100%", maxWidth: "100px"}} src="/images/Feedback_1.png" alt="smile"/>
              </Card>
              <Card
              style={{margin: "6px"}}
                onClick={() => feedbackHandler("Neutral")}
              >
                <CardImg style={{width: "100%", maxWidth: "100px"}} src="/images/Feedback_2.png" alt="meh"/>
              </Card>
              <Card
              style={{margin: "6px"}}
                onClick={() => feedbackHandler("Dissatisfied")}
              >
               <CardImg style={{width: "100%", maxWidth: "100px"}} src="/images/Feedback_3.png" alt="frown"/>
              </Card>
            </div>
            </>
          )}

          {feedback && (
            <p>
              Thanks for your feedback!
            </p>
          )}
        </div>

          {activeModules.length > 0 ?
            <Row className="mx-4 mt-3">
            <h3>Prepare for Your Appointment</h3>
              {activeModules.map(m =>
              <Card
                style={{cursor: "pointer"}}
                className="card-horizontal"
                onClick={() => goToModule(m)}>
                {m.cardImage ? <img
                  src={m.cardImage}
                  className="col-md-4"
                  alt={m.cardAltText}
                ></img> : <FaBook></FaBook>}
                <div className="card-body col-md-8">
                  <h4 className="card-title mb-0 heading3 text-viridian-green">
                    {m.title}
                  </h4>
                  {/* <p className="text-muted small">{newsData.byline}</p> */}
                  <p>{m.description}</p>
                </div>
              </Card>)}
          </Row>
          : console.log("No educational content found for this user.")}

      <Modal show={!!feedbackId} onHide={() => setFeedbackId(null)} size="md">
        <Modal.Header closeButton style={{border: "none"}}>
          <Modal.Title className="text-dark-blue">Feedback Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <hr className="modal-card-hr"/>
        <br />
          <p className="mb-3">Would you like to add any comments to your feedback?</p>
          <textarea className="form-control" value={feedbackFreeText} onChange={e => setFeedbackFreeText(e.target.value)}></textarea>
        </Modal.Body>
        <Modal.Footer style={{border: "none"}}>
          <button className="btn btn-pink" onClick={() => addFeedbackFollowup()}>Submit</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    accountType: state.auth.user.accountType,
    userId: state.auth.user.uid,
    email: props.email,
  };
}

export default connect(
  mapStateToProps
)(EmailConfirmation);
