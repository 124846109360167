import React, {useEffect, useState} from 'react';
import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {createMuiTheme, createTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import moment from "moment";
import { ModeComment } from '@material-ui/icons';

const materialTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: "#1ab984",
          },
        },
        MuiPickersCalendarHeader: {
          switchHeader: {
            backgroundColor: "white",
            color: "#1ab984",
          },
        },
        MuiPickersDay: {
          daySelected: {
            backgroundColor:  "#1ab984",
          }
        }
    },
});
export const CustomCalendar = ({
    changed,
    value,
    openTo,
    suggestedDate,
    inline = false,
    monthOnly = false,
    disableFuture = false,
    disablePast = false,
    sendEventOnInvalid = true,
    fullwidth = false,
    inPage = false,
    landscape = false
}) => {

  if (value instanceof Date) {
    throw new Error("As of 05/2022, CustomCalendar expects string dates");
  }
  const initialValue = value ? (monthOnly ? moment(value, "MM-YYYY") : moment(value, "MM-DD-YYYY")) : null;
  const [selectedDate, setDate] = useState(initialValue);
  const [inputValue, setInputValue] = useState();

  function formatForOutput (date, value) {
    if (monthOnly) {
      return moment(date).format("MM-yyyy");
    } else {
      return moment(date).format("MM-DD-yyyy");
    }
  }

  const onDateChange = (date, value) => {
    console.log({date, value});
    setDate(date);
    setInputValue(value);
    if (isNaN(date)) {
      if (sendEventOnInvalid) {
        // TODO: send something other than formatted! but first we need to see what might rely on this...
        changed(formatForOutput(date));
      }
    } else {
      changed(formatForOutput(date));
    }
  };

  return (
    <div style={{display: inline ? "inline-block" : "block"}}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            variant={inPage ? "static" : "inline"}
            autoOk
            value={(!value || value === "Invalid Date") ? null : selectedDate}
            onChange={onDateChange}
            disableFuture={!!disableFuture}
            disablePast={!!disablePast}
            inputValue={value === null? null : inputValue}
            views={monthOnly ? ["month", "year"] : ["date"]}
            format={monthOnly ? "MM-yyyy" : "MM-dd-yyyy"} /* "MM-yyyy" */
            placeholder={monthOnly ? "mm-yyyy" : "mm-dd-yyyy"}
            openTo={openTo ? openTo : (monthOnly ? "month" : "date")}
            initialFocusedDate={suggestedDate}
            style={fullwidth ? {width: "100%"} : null}
            orientation={landscape ? "landscape" : "portrait"}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default CustomCalendar;