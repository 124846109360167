import React from "react";
import Questionnaire from './Questionnaire';
import queryString from "query-string";

import * as ROUTES from '../../constants/routes';

const History = (props) => {

    const {type} = queryString.parse(decodeURIComponent(props.location.search));

    return (
    <Questionnaire type={type} nextLocations={[ROUTES.REPORTS]}/>
    )
}

export default History;
