import moment from "moment";
import React, { Fragment, useEffect, useMemo, useRef, useState, RefObject } from "react";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight, FaChevronCircleDown, FaChevronCircleUp, FaVolumeMute } from "react-icons/fa";
import { connect, useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useParams, useRouteMatch } from "react-router";
import { IS_DEVELOPMENT, IS_QA } from "../../constants/app";
import { Status } from "../../constants/communication";
import { dualName } from "../../constants/locales";
import * as ROUTES from "../../constants/routes";
import { PageType, ScreeningType } from "../../constants/screenings";
import { useTextDirection } from "../../i18n";
import {
  createPublicUserReport, createReport, logout, processMetrics, recordPageData, sendPostSurvey
} from "../../store/actions";
import { playGlobalAudio } from "../../store/slices/audio";
import { basicQuestionnaireActions, changeAnswers, changeOutcomes, getCurrentQuestionnaire, requestQuestionnaire, shouldSkip } from "../../store/slices/questionnaire";
import { getAllReports, synthesizeCurrent, clearSynthesized } from "../../store/slices/reports";
import { updateScreening } from "../../store/slices/screenings";
import classes from "../../styles/History.module.scss";
// import Moment from "moment";
import "../../styles/questionnaire.css";
import { evaluator } from "../../utils/evaluator";
import { useAppendingSet, useBooleanState, useKeyboardEvents, useQuery } from "../../utils/hooks";
import PlayAudio from "../Audio/PlayAudio";
import Report from "../Report/Report";
import Alert from "../UI/Alert/Alert";
import FloatingAction from "../UI/FloatingAction/FloatingAction";
import { alertEvent, snack } from "../UI/GlobalAlerts";
import { Col, Container, Row } from "../UI/Grid/Grid";
import IntroCard from "../UI/IntroCard/IntroCard";
import { sanitizeEmptyValues } from "./questionnaire-utils";
import QuestionnairePage from "./QuestionnairePage";
import QuestionnaireTablePage from "./QuestionnaireTablePage";
import QuestionTitlePage from "./QuestionTitlePage";
import ResponseChoices from "./ResponseChoices";
// import Button from "react-bootstrap/Button";
// import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
// todo: add user data intake for Becks Depression

/**
 * The main root component for all Questionnaire flows, including all of the
 * dynamic content generated from the questionnaire JSON.
 */
const Questionnaire = ({
  user,
  userId,
  isAnonymous,
  dataLoaded,
  status,
  error,
  isError,
  nextLocations,
  verifiedPatient,
  type,
  sections,
  questions,
  pages,
  structure,
  introduction,
  loadedLocale,
  timeStarted,
  languageOverride
}) => {
  // Hooks to retrieve and interact with the URL and navigation history
  const query = useQuery();
  const params = useParams();
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const authOrg = useSelector(s => s.auth.organization);

  // Metadata about the current language and text direction
  const [t, i18n] = useTranslation();
  const language = languageOverride || i18n.language;
  const dir = useTextDirection();

  /** @type {{number: number, details: object, lastMovement: number, history: number[], multiplex: any, followups: {index: number, forCurrent: any[]}}} */
  const page = useSelector(s => s.questionnaire.navigation);
  function setPage (p) {
    dispatch(basicQuestionnaireActions.jumpToPage({page: p}))
  }

  /* Track whether this is the first run of the URL<->state synchronization
     code below. No you really shouldn't need this kind of statefulness in
     React, but I haven't solved how to get the values not to loop, since we
     don't have a single source of truth (you can enter the questionnaire from
     a blank slate, where it should set the page number in the URL, *OR* you can
     enter at a specific page URL in which case it should set the global page
     state...) */
  const {value: afterFirstRun, on: setFirstRun} = useBooleanState(false)

  /**
   * On initial load and whenever the pageNumber parameter of the URL (the last 
   * segment in the route /anonymous/history/<pageNumber>) changes, we "jump" to
   * the initial page, which is either defined as:
   *  1. the value of the search query ?initialPage=<PAGE> if it is set and we
   *     are on the first run of this code (e.g. initial set up)
   *  2. the value of the pageNumber parameter
   * NOTE 1: We only jump if the page is different from the current number.
   * NOTE 2: The values in the URL slots are 1-based but our state is 0-based
   * indexing, so we have to math slightly.
   */
  useEffect(() => {
    if (params.pageNumber) {
      const fromQueryFirstRun = afterFirstRun ? null : query.get("initialPage");
      if (fromQueryFirstRun) console.log(`Using initialPage value from query string (${fromQueryFirstRun})`);
      const parsed = Number.parseInt(fromQueryFirstRun ?? params.pageNumber, 10);
      if (!Number.isNaN(parsed) && parsed > 0 && parsed !== (page.number+1)) {
        dispatch(basicQuestionnaireActions.jumpToPage({page: parsed - 1}));
      }
    }
  }, [params.pageNumber]);

  /**
   * Update the URL to match the current page number when it changes, but only
   * after the first run of this code to avoid a dangerous loop behavior.
   */
  useEffect(() => {
    if (afterFirstRun) {
      const newPath = generatePath(routeMatch.path, {pageNumber: page.number + 1});
      if (newPath !== routeMatch.url) {
        history.push({
          search: history.location.search,
          pathname: newPath
        });
      }
    } else {
      setFirstRun();
    }
  }, [page.number])

  /**
   * @type {boolean}
   * Whether the access key has ever been opened before (regardless of what was
   * done afterward.)
   */
  const previouslyUsedKey = useSelector(s => s.auth.accessKey?.previouslyOpened || false);
  
  /**
   * @type {object?}
   * The synthesized report for this user's session, or null if it doesn't exist
   */
  const synthesized = useSelector(s => getAllReports(s).find(r => r.synthesized && r.userId === userId));

  /**
   * @type {number}
   * The 0-based integer index of which row we are currently at within a
   * multiplex "table".
   */
  const multiplexRow = useSelector(s => s.questionnaire.navigation.multiplex.row);

  const qState = useSelector(s => s.questionnaire);

  /**
   * @type {RefObject<HTMLDivElement>}
   * The <div> element root for a Question Group, used to dynamically identify
   * the magic scroll waypoints by finding its <hr> children.
   */
  const groupRef = useRef(null);
  /**
   * How many pixels from the scroll "target" the page needs to be to enable
   * the scrolling option for that target; this allows you to be a little bit
   * above or below a waypoint and not have the page snap you a tiny amount of
   * distance on the first click.
   */
  const SCROLL_BUFFER = 50;
  /**
   * @type {{top: boolean, bottom: boolean}}
   * Whether the upward and downward scroll buttons are available.
   */
  const [canScroll, setCanScroll] = useState({top: false, bottom: false});

  /**
   * Identify whether we are far enough from the top or bottom to show the
   * corresponding scroll buttons.
   */
  function updateCanScroll () {
    const rootEl = document.getElementById("root");
    const top = (window.scrollY >= SCROLL_BUFFER);
    const bottom = (window.innerHeight + window.pageYOffset) <= (rootEl.offsetHeight - SCROLL_BUFFER);
    // console.log(`${window.innerHeight}, ${window.pageYOffset}, ${rootEl.offsetHeight}`);
    setCanScroll({top, bottom});
  }

  /* Ensure the scroll button display is reevaluated when we change pages. */
  useEffect(() => {updateCanScroll();}, [page.number]);
  /* Ensure the scroll button display is reevaluated whenever we scroll. */
  useEffect(() => {
    window.addEventListener('scroll', updateCanScroll);
    // make sure we remove the global listener when the component is destroyed
    return () => window.removeEventListener('scroll', updateCanScroll);
  }, []);

  /**
   * Find the current scroll target for a particular direction and scroll to it.
   * @param {boolean} upward true for going up, false for going down
   */
  function buttonScroll (upward = false) {
    if (groupRef.current) {
      // when there is a question group <div> on the page, we attempt to find a
      // valid scroll waypoint by iterating through the children of the div
      // until we find an <hr> whose top value is in the correct direction from
      // the current screen position (but beyond a certain buffer distance)
      let target = null;
      if (upward) {
        for (let i = groupRef.current.childElementCount - 1; i >= 0; i--) {
          const child = groupRef.current.children[i];
          if (child.nodeName === "HR") {
            const rect = child.getBoundingClientRect();
            if (rect.top + SCROLL_BUFFER < 0) {
              target = rect.top;
              break;
            }
          }
        }
        if (target === null && window.scrollY > SCROLL_BUFFER) target = -1 * window.scrollY;
      } else {
        for (let child of groupRef.current.children) {
          if (child.nodeName === "HR") {
            const rect = child.getBoundingClientRect();
            if (rect.top > SCROLL_BUFFER) {
              target = rect.top;
              break;
            }
          }
        }
      }
      if (target !== null) {
        window.scrollTo({top: window.scrollY + target, behavior: "smooth"});
        return; 
      } 
      // if we *didn't* find a valid <hr> divider target, we fall back to the
      // non-waypoint option
    }
    // the less "smart" scroll method, which simply moves up or down by 1 full
    // screen height of pixels
    const factor = upward ? (-1) : 1;
    window.scrollTo({top: window.scrollY + (factor * window.innerHeight), behavior: "smooth"});
  }

  const answers = useSelector(s => s.questionnaire.answers);
  const outcomes = useSelector(s => s.questionnaire.outcomes);

  const [loadFailures, addLoadFailure] = useAppendingSet();
  /** Whether the debug page jump dialog is open, and its setter fn. */
  const [pageJumpDialog, setPageJumpDialog] = useState(false);
  /**
   * Handle a page selection made by the debug "jump to" dialog box
   * @param {React.FormEvent<HTMLSelectElement>} event
   */
  function unconditionalJumpFromDialog (event) {
    const index = Number.parseInt(event.target.value, 10) || 0;
    setPage(index);
    setPageJumpDialog(false);
  }

  function jumpByBreadcrumb (level) {
    const setting = structure.pageSettings?.breadcrumbs;
    if (!setting || setting === "no-link") return;
    let target = 0;
    switch (level) {
      case 0:
        console.warn("Jumping to beginning");
        return setPage(target);
      case 1:
        target = pages.findIndex(p => p.sectionKey === page.details?.sectionKey);
        if (target > -1) {
          console.warn("Jumping to matching section on page "+target);
          return setPage(target);
        }
        break;
      case 2:
        target = pages.findIndex(p => (
          (p.sectionKey === page.details?.sectionKey) &&
          (p.addlNavKeys?.subsection === page.details?.addlNavKeys?.subsection)
        ));
        if (target > -1) {
          console.warn("Jumping to matching subsection on page "+target);
          return setPage(target);
        }
        break;
      default:
        console.error("Unknown breadcrumb level "+level);
    }
  }

  const dispatch = useDispatch();

  function getAnswerKey (baseKey, basePage) {
    const details = (basePage?.details || page?.details);
    if (details?.multiplex) {
      return `${baseKey || details.key}[${multiplexRow}]`;
    } else {
      return baseKey || details?.key;
    }
  }

  function specialLoadFlags () {
    return {
      dualLanguage: !!query.get("dualLang") && (IS_QA || IS_DEVELOPMENT),
      forceLinearPagination: !!query.get("assetCheck") && (IS_QA || IS_DEVELOPMENT)
    };
  }

  useEffect(() => {
    if ((status === Status.Unstarted && language)
      || ((status === Status.Ready || status === Status.LoadFailed) && language !== loadedLocale.language)
    ) {
      if (loadFailures.has(`${type}_${language}`)) {
        return console.error(`Cannot request questionnaire ${type}, ${language} -- load already failed this session!`);
      }
      console.log(`Requesting ${(status === Status.Unstarted) ? "new " : ""} questionnaire content: ${type}, ${language}`);
      dispatch(requestQuestionnaire({type, locale: {language}, flags: specialLoadFlags()})).catch(err => {
        addLoadFailure(`${type}_${language}`)
        console.error(`Failed loading ${type}_${language}`, err);
      });
    }
  }, [
    type,
    language,
    status
  ]);

  const forwardKey = {
    ltr: "ArrowRight",
    rtl: "ArrowLeft"
  };
  const backwardKey = {
    ltr: "ArrowLeft",
    rtl: "ArrowRight"
  }
  useKeyboardEvents([
      `shift.${forwardKey[dir]}`, () => advanceOrSkip(),
      `shift.${backwardKey[dir]}`, () => backPage(),
      `ctrl.j`, () => setPageJumpDialog(IS_DEVELOPMENT),
    ], "keyup", () => true, [page, answers]);

  const followupIndex = useSelector(s => s.questionnaire.navigation.followups.index);
  const followups = useSelector(s => s.questionnaire.navigation.followups.forCurrent);

  const RECORD_PAGE_MOVEMENTS = ["advancePage"];
  const advancePage = () => {
    dispatch(basicQuestionnaireActions.walkPage({backward: false}));
    if (RECORD_PAGE_MOVEMENTS.includes("advancePage")) {
      dispatch(recordPageData())
    }
  }
  
  const backPage = () => {
    if (pageTurnTooFast()) return;
    if (page.details.type === PageType.ReviewReport) {
      dispatch(clearSynthesized({userId}));
    }
    dispatch(basicQuestionnaireActions.walkPage({backward: true}));
  }

  const evaluateOutcomes = (outcomeDefinitions) => {
    if (!outcomeDefinitions) return {};
    const outcomes = {};
    const answersWithOutcomes = {...answers};
    for (let [name, defn] of Object.entries(outcomeDefinitions)) {
      let result = evaluator(defn.formula, answersWithOutcomes, structure);
      outcomes[name] = result;
      answersWithOutcomes[`~${name}`] = {
        isOutcome: true,
        value: result
      };
    }
    dispatch(changeOutcomes({outcomes}));
    return outcomes;
  }

  useEffect(() => {
    switch (page.details.type) {
      case PageType.SubmitPage:
        submitFinalAnswers(evaluateOutcomes(structure.reportOutcomes));
        break;
      case PageType.ReviewReport:
        if (!synthesized) {
          dispatch(synthesizeCurrent({userId, questionnaire: structure, answers}));
        }
        break;
      case PageType.QuestionPage:
      case PageType.QuestionGroup:
      case PageType.QuestionTable:
        const qKeys = [page.details.key].concat(page.details.additionalKeys || []);
        const updates = {};
        let hasUpdates = false;
        qKeys.forEach(qKey => {
          const resps = questions[qKey]?.responses;
          const answer = answers[getAnswerKey(qKey, page)];
          if (Array.isArray(resps) && !answer?.answered) {
            const changesForQuestion = [];
            resps.forEach((r, i) => {
              if (r.suggestWhen) {
                const suggest = evaluator(r.suggestWhen, answers, structure, true, false, `questions.${qKey}.responses.${i}.suggestWhen`);
                if (!!suggest) changesForQuestion.push(i);
              }
            });
            if (changesForQuestion.length > 0) {
              hasUpdates = true;
              updates[qKey] = {...answer, suggested: changesForQuestion};
            }
          }

          if (Array.isArray(questions[qKey]?.text)) {
            dispatch({type: "dynamic-text/UPDATE", payload: {[qKey]: `${evaluator(questions[qKey].text, answers, structure, false, false , `questions.${qKey}.text`)}`}})
          }
          if (Array.isArray(questions[qKey]?.tableText)) {
            dispatch({type: "dynamic-text/UPDATE", payload: {[qKey + ".table"]: `${evaluator(questions[qKey].tableText, answers, structure, false, false , `questions.${qKey}.tableText`)}`}})
          }
        });
        if (hasUpdates) {
          dispatch(changeAnswers(updates));
        }
        break;
      default:
        // do nothing
    }
  }, [page])

  const MIN_TIME_BETWEEN_USER_PAGE_NAV = 250;
  const [ lastPageTurn, setLastPageTurn ] = useState(0);
  function pageTurnTooFast () {
    const now = Date.now();
    if (now - lastPageTurn < MIN_TIME_BETWEEN_USER_PAGE_NAV) {
      return true;
    } else {
      setLastPageTurn(now);
      return false;
    }
  }

  const [exitDialog, setExitDialog] = useState(null);

  const advanceOrSkip = () => {
    if (pageTurnTooFast()) return;
    const [answered, inputRequirementsMet, inputsRequired, earlyExits] = allAnswered();
    if (inputRequirementsMet) {
      if (answered) {
        if (earlyExits.length > 0) {
          if (earlyExits.length > 1) {
            console.warn("Page had MULTIPLE early exit triggers", earlyExits);
          }
          setExitDialog(earlyExits[0]);
        } else {
          advancePage();
        }
      } else {
        attemptSkip();
      }
    } else {
      dispatch(alertEvent(
        `You must provide details for ${inputsRequired.map(([q,n]) => `${q} response #${n+1}`).join(", ")}`));
    }
  }

  /**
   * Determine if all (in the case of groups) the questions are answered and any
   * required inputs are complete.
   * @returns boolean
   */
  const allAnswered = () => {
    if (![PageType.QuestionPage, PageType.QuestionGroup].includes(page.details.type)) {
      // if it's not a page with questions, we treat it as "answered"
      return [true, true, [], []];
    }
    // TODO: do we need table entry checking for PageType.QuestionTable
    // how do we define it?

    // collect all of the keys that we need to investigate
    let keysToCheck;
    if (followupIndex > -1) {
      // if we're on a followup question we only check that
      keysToCheck = [followups[followupIndex]];
    } else {
      // otherwise we check the base question and any other questions in the group
      keysToCheck = [page.details.key].concat(page.details.additionalKeys || []).map(k => getAnswerKey(k));
    }
    const allHaveSelections = keysToCheck.every(k => answers[k]?.answered);

    // in addition to checking whether the questions are marked answered, the
    // questions may opt-in to requiring input for certain responses
    const inputsToCheck = keysToCheck.map(k => {
      let keyIndexPairs;
      if (questions[k]?.requiredInput === "all")
        keyIndexPairs = questions[k].responses?.map((r, i) => [k, i]);
      if (questions[k]?.requiredInput === "selected")
        keyIndexPairs = answers[k].selected?.map(s => [k, s]) || [];
      if (Array.isArray(questions[k]?.requiredInput))
        keyIndexPairs = questions[k]?.requiredInput;
      return keyIndexPairs || [];
    }).flat();
    const inputsFilled = inputsToCheck.every(([k, i]) => {
      let option = answers[k]?.options[i];
      return option && (("input" in option && !["", null, undefined].includes(option.input)) || "key" in option);
    });
    const earlyExitRequests = keysToCheck.flatMap(k => 
      (answers[k]?.selected || []).map(s => questions[k].responses[s]?.earlyExit).filter(x => x));
    console.warn({earlyExitRequests});
    return [allHaveSelections, inputsFilled, inputsToCheck, earlyExitRequests];
  }

  /**
   * The definition of the skip question, which is hard-coded to live at the
   * question key "allowSkips" when enabled.
   */
  const skipQuestion = (structure.pageSettings?.allowSkips === "with-explanation") ?
    structure.questions.allowSkips : null;
  const [ showSkipModal, setShowSkipModal ] = useState(false);

  /**
   * Handle an attempt to move forward without enough answers (generally 0)
   * provided to the current question, possibly displaying a dialog for the
   * user's reason for skipping.
   *
   * @returns {boolean} navigate Whether to continue onward or halt navigation
   */
  const attemptSkip = () => {
    const allowed = structure.pageSettings?.allowSkips;
    if (allowed === "always" || (allowed === "public-only" && isAnonymous)) {
      advancePage();
      return true;
    } else if (skipQuestion) {
      setShowSkipModal(true);
      return false;
    } else {
      dispatch(playGlobalAudio("ALERT"));
      dispatch(alertEvent(page.details.additionalKeys
        ? "Please select answers for all questions before continuing"
        : "Please select an answer before continuing"));
      return false;
    }
  }

  /**
   * Accept the provided skip reason and advance to the next page.
   */
  const closeSkipModal = () => {
    const answer = answers[page.details.key];
    if (Number.isInteger(answer?.skipReason)) {
      setShowSkipModal(false);
      advancePage();
    } else {
      dispatch(alertEvent("You must select a reason for skipping to continue"));
    }
  }

  /**
   * Leave the skip modal and undo any provided skip answer (the user will be
   * required to answer the main question)
   */
  const clearSkipModal = () => {
    setShowSkipModal(false);
    // TODO: verify we didn't sneakily move to a different page or something else odd?
    const updatedAnswer = {...answers[getAnswerKey()]};
    delete updatedAnswer.skipReason;
    dispatch(changeAnswers({[getAnswerKey()]: updatedAnswer}));
  }
  /**
   * Accept a click on a skip reason response card, and either select it or
   * deselect it.
   * 
   * @param {number} responseId Integer of the selected response within the
   *                            skip reason question
   */
  const toggleSkipReason = (responseId) => {
    const updatedAnswer = {...answers[getAnswerKey()]};
    if (Number.isInteger(updatedAnswer.skipReason) && updatedAnswer.skipReason === responseId) {
      updatedAnswer.skipReason = null;
    } else {
      updatedAnswer.skipReason = responseId;
    }
    dispatch(changeAnswers({[getAnswerKey()]: updatedAnswer}));
  }

  const activeGroupKeys = useMemo(() => {
    if (![PageType.QuestionGroup, PageType.QuestionTable].includes(page.details.type)) return [];
    const groupKeys = [page.details.key].concat(page.details.additionalKeys);
    const activeKeys = groupKeys.filter(k => !shouldSkip(questions[k], qState, `group/table questions ${k}`));
    if (activeKeys.length === 0) {
      console.error(`An answer change on question group/table ${page.details?.key} caused that group/table to become empty (of ${groupKeys.length} possible)!`);
      return [];
    } else {
      return activeKeys;
    }
  }, [page, answers]);

  const screeningId = useSelector(s => s.questionnaire.currentScreeningID);
  const previouslySubmitted = useSelector(s => s.auth.accessKey?.previouslySubmitted || false);

  const [lastSubmission, setLastSubmission] = useState(0);
  const [repeatSubmitWarningSeen, setRepeatSubmitWarningSeen] = useState(false)
  const MINIMUM_RESUBMIT_SECONDS = 30;

  // then go on to display the patient report
  const submitFinalAnswers = (incomingOutcomes) => {
    const now = moment();
    if (now - lastSubmission < MINIMUM_RESUBMIT_SECONDS * 1000) {
      if (!repeatSubmitWarningSeen) {
        dispatch(snack(`You can't submit more often than once every ${MINIMUM_RESUBMIT_SECONDS} seconds; yours was ${((now - lastSubmission) / 1000).trunc(1)}s ago`));
        setRepeatSubmitWarningSeen(true);
      }
      console.warn(`Skipping submission, last submit was ${now - lastSubmission}ms ago`);
      return;
    }
    const submissionTime = [
      now.format("ddd MMM DD YYYY"),
      now.format("HH:mm")
    ];
    const t_submitted = now.valueOf();
    const durationSeconds = (Number.isSafeInteger(timeStarted)) ? Math.floor((t_submitted - timeStarted) / 1000) : null;

    const sanitizedAnswers = sanitizeEmptyValues(answers);

    // Handle saving the user report to the database depending on user status (e.g. public vs. private)
    if (userId) {
      const metrics = processMetrics(structure, answers, incomingOutcomes);
      dispatch(createReport(
        userId,
        user.providerId,
        user.organizationId || authOrg?.id, 
        screeningId,
        {
          version: 3,
          answers: sanitizedAnswers,
          outcomes: incomingOutcomes,
          type,
          screenerVersion: structure.version,
          t_submitted,
          durationSeconds,
          completionLanguage: language,
          submitter: {lastName: user.lastName, dob: user.dob},
        },
        metrics
      )).then((reportId) => {
        dispatch(updateScreening(screeningId, {readBy: {}}));
        if (reportId) {
          navigateOnward(reportId, submissionTime);
        }
      });
      const url = `${process.env.REACT_APP_POST_SURVEY_LINK}${userId}`;
      const content = `Thank you for taking part in this study. Please take this post survey about your experience with your doctor: ${url}`;
      dispatch(sendPostSurvey({
        content,
        uid: userId,
      }));
    } else {
      dispatch(createPublicUserReport(
        {answers},
        {
          version: 3,
          outcomes: incomingOutcomes,
          type,
          screenerVersion: structure.version,
          t_submitted,
          durationSeconds,
          completionLanguage: language,
          submitter: {lastName: "", public: true, dob: "1900-01-01"}
        },
      )).then((reportId) => {
        if (reportId) {
          navigateOnward(reportId, submissionTime);
        }
      });
    }
  };

  function navigateOut () {
    dispatch(logout());
    history.push({pathname: "/"});
  }

  const navigateOnward = (reportId, submission_time) => {
    const target = ROUTES.acceptsReportParam(nextLocations[0])
      ? `${nextLocations[0]}/${reportId}` : nextLocations[0];
    const inputsWithQuestions = Object.entries(answers).map(([key, answer]) => {
      return answer; // TODO: this isn't right
    });
    history.push({
      pathname: target,
      // search: history.location.search,
      // TODO: This state-passing seems... *questionable*. Why wouldn't the
      // receiving component be able to derive this all from global? [tdhs]
      state: {
        type: "Email Report",
        reportId,
        report: {
          // this object is a little different than our one bound for the
          // database... it basically needs to be ready for use by handlebars
          inputs: inputsWithQuestions,
          date_time: submission_time,
          type,
          completionLanguage: dualName(language),
          ds_submitted: moment().format("YYYY-MM-DD HH:mm"),
          submitter: {lastName: user.lastName, dob: user.dob},
          id: reportId
        },
        nextLocations: nextLocations.slice(1),
        verifiedPatient: userId ? {verifiedPatient} : undefined
      }
    });
  }

  const renderQuestionnaire =
    (dataLoaded && questions) ? (
      <>
        <div
          className={`${
            page.details?.sectionKey
              ? classes[sections[page.details.sectionKey]?.colorScheme]
              : ""
          } ${classes.rootContainer} ${dir.toUpperCase()}`}>
          <div className={`progress ${classes.FullSize}`} style={{backgroundColor: "rgba(240, 240, 240, 1)", borderRadius: 0}} title={`Page ${page.number} of ${pages?.length}`}>
            <div
              className="progress-bar"
              style={{ background: "#17207a", width: `${(page.number / pages.length) * 100}%` }}>
            </div>
          </div>
          {previouslyUsedKey && page.number === 0 ? 
          <div className="alert alert-warning text-center">
            You have used this link to login before. If this was not you, please contact your provider or LiteraSeed directly.<br/> You can submit the form multiple times to update your answers.
          </div> : null}
          {page.details?.type === PageType.SectionIntro ? (
            <QuestionTitlePage
              sectionTitle={sections[page.details.key].title}
              sectionDescription={
                sections[page.details.key].sectionDescription
              }
              sectionKey={page.details.key}
              imageUrl={sections[page.details.key].imageUrl}
              previous={() => backPage()}
              next={() => advancePage()}
              pageNumber={page.number}
              audioFileName={sections[page.details.key].audioFileName}/>
          ) : null}
          {page.details?.type === PageType.QuestionPage ? (
            <QuestionnairePage
              questionKey={page.details.key}
              handleForward={() => advanceOrSkip()}
              handleBack={() => backPage()}
              breadcrumbNav={l => jumpByBreadcrumb(l)}
              skipPredicate={(o, n) => shouldSkip(o, qState, n)}
              multiplexIndex={page.details.multiplex ? multiplexRow : null}
              answerKey={getAnswerKey()}/>
          ) : null}
          {page.details?.type === PageType.QuestionGroup ? (
            <div ref={groupRef}>
              {activeGroupKeys.length > 0 ? null : <div className="alert alert-warning">The questions on this page are no longer relevant to you!</div>}
            {activeGroupKeys.map((k, i) =>
              <Fragment key={k}>
                {i > 0 ? <hr  style={{margin: "4rem 0"}}/> : null}
                <QuestionnairePage
                  inline={activeGroupKeys.length === 1 ? false : (i === 0 ? "top" : (i === activeGroupKeys.length - 1 ? "bottom" : true))}
                  questionKey={k}
                  handleForward={() => advanceOrSkip()}
                  handleBack={() => backPage()}
                  breadcrumbNav={l => jumpByBreadcrumb(l)}
                  skipPredicate={(o, n) => shouldSkip(o, qState, n)}/>
              </Fragment>)}
          </div>) : null}
          {page.details?.type === PageType.QuestionTable ? (
            <QuestionnaireTablePage
              questionKey={page.details.key}
              activeTableKeys={activeGroupKeys}
              handleForward={() => advanceOrSkip()}
              handleBack={() => backPage()}
              breadcrumbNav={l => jumpByBreadcrumb(l)}
              skipPredicate={(o, n) => shouldSkip(o, qState, n)}/>
            ) : null}
          {page.details?.type === PageType.ReviewReport ? (
            synthesized ? 
              <Report reportId={synthesized.id} review={true} handleBack={() => backPage()} handleForward={() => advanceOrSkip()}/>
              : <h3>Building report...</h3>
            ) : null}
          {page.details?.type === PageType.StaticContent ? page.details?.html : null}
          {/*
            page.details?.type === PageType.EncouragementMessage ? null : null
          */}
        </div>

        {query.has('initialPage') ? null : <IntroCard content={introduction} customClass={dir.toUpperCase() + " " + classes.Onboarding}/>}
        {userId && !structure.pageSettings?.showHelpForm ? null : <FloatingAction context="questionnaire"/>}

        <FaChevronCircleUp
            style={{position: "fixed", right: (canScroll.top ? "5px" : "-35px"), top: "5px", fontSize: "30px", transition: "right 1s"}}
            onClick={() => buttonScroll(true)}
            title="Scroll up"/>
        <FaChevronCircleDown
          style={{position: "fixed", right: (canScroll.bottom ? "5px" : "-35px"), bottom: "5px", fontSize: "30px", transition: "right 1s"}}
          onClick={() => buttonScroll(false)}
          title="Scroll down"/>

        {/* followup questions */}
        <Modal show={followupIndex > -1} onHide={() => dispatch(basicQuestionnaireActions.dismissFollowup())} size="xl">
          <Modal.Header>
            <Modal.Title>
              Follow-up Question
              &nbsp;<small className="text-muted">
                {followupIndex + 1} of {followups?.length}
              </small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {followups?.[followupIndex] ? <QuestionnairePage
              inline="true"
              questionKey={followups[followupIndex]}
              handleForward={() => advanceOrSkip()}
              handleBack={() => backPage()}
            /> : <em>Question not found!</em>}
          </Modal.Body>
          <Modal.Footer>
            <Col className="text-center">
              <Button className="back-btn pl-3 pr-3 mr-3 ml-3" style={{minWidth: "50%"}} onClick={() => backPage()}>
                {dir === 'ltr' ? <FaArrowLeft /> : <FaArrowRight />} <span className="pr-2 font-weight-bold">Back</span>
              </Button>
            </Col>
            <Col className="text-center">
              <Button className="forward-btn pl-3 pr-3 mr-3 ml-3 text-white" style={{minWidth: "50%"}} onClick={() => advanceOrSkip()} >
                <span className="pr-2 font-weight-bold">Next</span> {dir === 'ltr' ? <FaArrowRight /> : <FaArrowLeft />}
              </Button>
            </Col>
          </Modal.Footer>
        </Modal>

        <Modal show={!!showSkipModal} backdrop="static" keyboard={false} size="lg">
          <Modal.Body>
          {skipQuestion ?
            <Container>
            <Row className="justify-content-center">
            <div className="headerWithSound">
              <h2 className={`${classes.QuestionText} pt-3 pb-2 pb-md-4 pt-md-5`}>
                {skipQuestion.text}
              </h2>
              {skipQuestion.audioFileName ?
                <div className="headerSound">
                  <PlayAudio filename={skipQuestion.audioFileName} />
                </div>
                : <div className="headerSound disabled" title="Audio not available">
                    <FaVolumeMute/>
                  </div>
              }
            </div>
            <ResponseChoices
              key={-1}
              questionId={-1}
              questionKey={"allowSkips"}
              answerKey={page.details.key}
              choices={skipQuestion.responses}
              selected={[]}
              asSkip={true}
              toggleResponse={toggleSkipReason}
              // changeValue={changeSubValue}
              fluidCards={true}
            />
            </Row>
            </Container>
            : null}
          </Modal.Body>
          <Modal.Footer style={{display: "flex"}}>
            <Button style={{flex: 1}} className="back-btn" onClick={() => clearSkipModal()}>
              Nevermind
              {/* {" "}
              <FaTimes/> */}
            </Button>
            <Button style={{flex: 1}} className="forward-btn" onClick={() => closeSkipModal()} disabled={!Number.isInteger(answers[page.details.key]?.skipReason)}>
              Submit
              {/* {" "}
              <FaForward/> */}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={pageJumpDialog} onHide={() => setPageJumpDialog(false)} >
        <Modal.Body>
            <label>Choose a question to jump to...</label>
            <select onChange={(v) => unconditionalJumpFromDialog(v)} className="form-control" defaultValue={page.number}>
              {pages?.length > 0 ?
                pages.map((p, i) => <option key={p.key || (p.type+i)} value={i}>
                  {(p.multiplex ? `{table of ${p.multiplex.unitLabel || p.multiplex.key}} `: "")}
                  {p.type === PageType.SectionIntro ? `--- SECTION INTRO: ${p.key} ---` : ""}
                  {p.type === PageType.QuestionGroup ? `(group of ${p.additionalKeys.length + 1}) ${p.key},${p.additionalKeys.join(",")}` : ""}
                  {p.type === PageType.QuestionTable ? `(table of ${p.additionalKeys.length + 1}) ${p.key},${p.additionalKeys.join(",")}` : ""}
                  {p.type === PageType.QuestionPage ? p.key : ""}
                  {p.type === PageType.ReviewReport ? "~ review report ~" : ""}
                  {p.type === PageType.SubmitPage ? "~ SUBMIT ~" : ""}
                </option>)
              : null}
            </select>
            <small className="text-muted">Note: using this dialog will not evaluate any of the system's normal navigational logic</small>
        </Modal.Body>
        </Modal>

        <Modal show={exitDialog} onHide={() => setExitDialog(null)} >
        <Modal.Header>
            <Modal.Title>
              {exitDialog?.title ?? "Leave Questionnaire?"}
            </Modal.Title>
          </Modal.Header>
        <Modal.Body>
            <p>{exitDialog?.text}</p>
        </Modal.Body>
        <Modal.Footer style={{display: "flex"}}>
          <Button style={{flex: 1}} className="btn btn-primary-outline" onClick={() => setExitDialog(null)}>
            Go Back
          </Button>
          <Button style={{flex: 1}} className="btn btn-danger" onClick={() => navigateOut()}>
            Leave
          </Button>
        </Modal.Footer>
        </Modal>
      </>
    ) : isError ? (
      <Alert error={error} />
    ) : (
      <Spinner />
    );

  return renderQuestionnaire;
};

const EmptyQuestionnaire = () => ({
  questions: {},
  sections: {},
  pages: [{type: PageType.EMPTY}],
  introduction: null
});

// TODO: switch to selectors
function mapStateToProps(state, ownProps) {
  const structure = getCurrentQuestionnaire(state) ?? EmptyQuestionnaire();

  return {
    user: state.auth.user,
    userId: state.auth.user.uid,
    isAnonymous: state.auth.user.isAnonymous === undefined || state.auth.user.isAnonymous,
    dataLoaded: structure?.status >= Status.Ready,
    isError: state.questionnaire.isError || state.report.isError,
    error: state.questionnaire.error || state.report.error,
    status: structure.status ?? Status.Unstarted,
    loadedLocale: structure.locale || {},
    timeStarted: state.questionnaire.timeStarted,
    structure,
    type: ownProps.type ? ownProps.type : ScreeningType.COVID,
    questions: structure.questions,
    sections: structure.sections,
    pages: structure.pages,
    introduction: structure.introduction,
    nextLocations: ownProps.nextLocations
      ? ownProps.nextLocations
      : [ROUTES.WAITLIST],
    verifiedPatient: ownProps.verifiedPatient
      ? ownProps.verifiedPatient.user
      : {},
  };
}

export default connect(mapStateToProps)(Questionnaire);
