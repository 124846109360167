import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import * as ROUTES from '../../constants/routes';
import { ROLES } from '../../constants/roles';

const Verify = ({
  resendVerification,
  isAuthenticated,
  role,
  redirectPath
}) => (
<>
 {/*Disable email verification*/}
  { (isAuthenticated && (role === ROLES.PATIENT || (role === ROLES.PROVIDER)) ) ?
    <Redirect to={redirectPath} />
    :
    <div className="jumbotron">
      <h1 className="display-5">Email pending verification!</h1>
      <p className="lead mt-4">We sent you a verification email. Please click the verification link in your email to get started.</p>
      {resendVerification?
        <>
        <hr className="my-4"/>
        <p>Did not receive it? Maybe check the spam folder or click the button below to resend.</p>
        <p className="lead">
          <button className="btn btn-primary" onClick={resendVerification}>Resend Verification Email</button>
        </p>
        </> : ""}
    </div>
  }
</>
)

function mapStateToProps(state, ownProps) {

  return {
    isAuthenticated: state.auth.isAuthenticated,
    role: state.auth.user? state.auth.user.role : "",
    isLoading: state.auth.isLoading,
    error: state.auth.error,
    redirectPath: ownProps.location.from || (state.auth.user.role === ROLES.PROVIDER? ROUTES.PROVIDER : ROUTES.HOME)
  };
}

export default connect(mapStateToProps)(Verify);
