// weird workaround to support node usage
// import * as toolkitRaw from '@reduxjs/toolkit';
// const { createAction } = toolkitRaw.default ?? toolkitRaw;
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { alertEvent } from "../../components/UI/GlobalAlerts";
import { ACCOUNT, ROLES } from '../../constants/roles';
import { db, firebase as myFirebase, fn } from "../../database";
import { ScreeningStatus, UserClinicStatus } from "../../database/model/user";
import { isObject } from "../../utils";
import { Fulfilled } from "../../utils/promises";
import { addScreeningDataFromOtherSource } from "../slices/screenings";
import * as actionTypes from './actionTypes';
import { getClinicianColleagues } from "./index";


const usersRef = db.collection('users');
const screeningsRef = db.collection('screenings');

/**********
* Action functions
***********/

/* Verify Email action  functions*/

const sendEmailVerificationStart = () => ({
  type: actionTypes.SEND_EMAIL_VERIFICATION_START
});

const sendEmailVerificationSuccess = () => ({
  type: actionTypes.SEND_EMAIL_VERIFICATION_SUCCESS,
});

const sendEmailVerificationFail = (error) => ({
  type: actionTypes.SEND_EMAIL_VERIFICATION_FAIL,
  error
});

/* Login action  functions*/
const loginStart = () => ({
  type: actionTypes.LOGIN_START
});

const loginSuccess = (user) => ({
  type: actionTypes.LOGIN_SUCCESS,
  user
});

const loginFail = (error) => ({
  type: actionTypes.LOGIN_FAIL,
  error
});

/* Anonymous signin */

const signInAnonymouslyStart = () => ({
  type: actionTypes.SIGNIN_ANONYMOUSLY_START
});

const signInAnonymouslySuccess = (user) => ({
type: actionTypes.SIGNIN_ANONYMOUSLY_SUCCESS,
  user
});

const signInAnonymouslyFail = (error) => ({
  type: actionTypes.SIGNIN_ANONYMOUSLY_FAIL,
  error
});


/* Logout action  functions*/

const logoutStart = () => ({
  type: actionTypes.LOGOUT_START
});

const logoutSuccess = () => ({
  type: actionTypes.LOGOUT_SUCCESS
});

const logoutFail = () => ({
  type: actionTypes.LOGOUT_FAIL
});

/* Verify auth action  functions*/

const authStart = () => ({
  type: actionTypes.AUTH_START
});

const authSuccess = (user) => ({
  type: actionTypes.AUTH_SUCCESS,
  user
});

const authFail = (error) => ({
  type: actionTypes.AUTH_FAIL,
  error
});

/* Reset password */

const authResetPasswordStart = () => ({
  type: actionTypes.AUTH_RESET_PASSWORD_START
});

const authResetPasswordSuccess = (user) => ({
  type: actionTypes.AUTH_RESET_PASSWORD_SUCCESS,
  user
});

const authResetPasswordFail = (error) => ({
  type: actionTypes.AUTH_RESET_PASSWORD_FAIL,
  error
});


/* Check pre created patient */
const checkPreCreatedPatientStart = () => {
  return {
    type: actionTypes.CHECK_PRE_CREATED_PATIENT_START
  }
}
const checkPreCreatedPatientSuccess = (payload) => {
  return {
    type: actionTypes.CHECK_PRE_CREATED_PATIENT_SUCCESS,
    payload
  }
}
const checkPreCreatedPatientFail = (error) => {
  return {
    type: actionTypes.CHECK_PRE_CREATED_PATIENT_FAIL,
    error
  }
}

/* Check pre created patient */
const updateUserLastNameStart = () => {
  return {
    type: actionTypes.UPDATE_USER_LAST_NAME_START
  }
}
const updateUserLastNameSuccess = (user) => {
  return {
    type: actionTypes.UPDATE_USER_LAST_NAME_SUCCESS,
    user
  }
}
const updateUserLastNameFail = (error) => {
  return {
    type: actionTypes.UPDATE_USER_LAST_NAME_FAIL,
    error
  }
}


/**********
* Action thunks
***********/
async function getAccountType (email) {

  let accountType = ACCOUNT.NO_ACCOUNT;

  if (myFirebase.auth().currentUser && myFirebase.auth().currentUser.isAnonymous) {

    let accountType = ACCOUNT.ANONYMOUS_ACCOUNT;
    return accountType;

  } else {
    await myFirebase.auth().fetchSignInMethodsForEmail(email)
      .then((signInMethods) => {

        if (signInMethods.indexOf(
                myFirebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
          // User can sign in with email/password. Can also has email link signin method
          accountType = ACCOUNT.PASSWORD_ACCOUNT;

        } else if (signInMethods.indexOf(
                 myFirebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !== -1) {
           // User can sign in with email/link.
          accountType = ACCOUNT.PASSWORDLESS_ACCOUNT;
        }
      })
      .catch((error) => {
      });

    return accountType;
  }
}

// Verify email
export const sendEmailVerification = (user) => dispatch => {

  dispatch(sendEmailVerificationStart());

  user
    .sendEmailVerification()
    .then(data => {
      dispatch(sendEmailVerificationSuccess());
    })
    .catch(error => {
      dispatch(sendEmailVerificationFail(error));
    });
};

/**
 * Generate a collection of device, browser, and session data that may be useful
 * to debugging efforts and record it in the user's sessions table.
 * @param {string} uid 
 * @param {WriteBatch} batch 
 * @returns {void}
 */
export const recordDeviceFingerprint = createAsyncThunk("session-fingerprint/store", ({uid, batch = null}) => {
  const data = {
    t_generated: Date.now(),
    userAgent: navigator.userAgent,
    appVersion: navigator.appVersion,
    platform: navigator.platform,
    hardwardConcurrency: navigator.hardwareConcurrency || -1,
    maxTouchPoints: navigator.maxTouchPoints || -1,
    initialWidth: window.innerWidth,
    initialHeight: window.innerHeight,
    devicePixelRatio: window.devicePixelRatio,
    historyLength: window.history.length || -1,
    connectionType: navigator.connection?.type || (navigator.connection?.effectiveType ? (navigator.connection?.effectiveType + " (effective)") : ""),
    connectionDownlink: navigator.connection?.downlink || -1,
    connectionRTT: navigator.connection?.rtt || -1
  };
  const ref = usersRef.doc(uid).collection("sessions").doc();
  let promise;
  if (batch) {
    batch.set(ref, data);
    promise = Fulfilled(ref.id); // this doesn't actually track the result timeline
  } else {
    promise = ref.set(data).then(() => {
      console.log("Successfully stored device fingerprint");
      return ref.id;
    });
  }
  fn.httpsCallable("reflectMyIP")({userId: uid, sessionId: ref.id}).then(result => console.log(result));
  return promise;
});

// Login
export const login = (email, password) => async dispatch => {

  dispatch(loginStart());

  return myFirebase.auth().setPersistence(myFirebase.auth.Auth.Persistence.SESSION)
  .then(function() {

    return getAccountType(email).then(accountType => {
      return myFirebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(authCredential => {
          // Pull user data
          return usersRef.doc(authCredential.user.uid).get().then(userDoc => {
            if (userDoc && userDoc.id && userDoc.data()) {
              const userData = {
                ...userDoc.data(),
                email: authCredential.user.email,
                uid: authCredential.user.uid,
                id: authCredential.user.uid,
                accountType
              };
              if (userData.active === false) {
                dispatch(alertEvent(`Your account is currently marked inactive. Please contact your organizational administrator to be granted access.`, "Account Disabled"));
                dispatch(loginFail(new Error(`This account is inactive!`)));
                return;
              }
              if (userData.organizationId) {
                dispatch(getOrganization(userData.organizationId));
                if (userDoc.role === ROLES.PROVIDER) {
                  dispatch(getClinicianColleagues(userData.organizationId));
                }
              }
              if (userData.role === ROLES.PATIENT) {
                throw new Error("User sign-in by account password is not available!");
                // dispatch(loginSuccess(userData));
                // dispatch(recordDeviceFingerprint({uid: userDoc.id}));
                // return userData;
              } else if (userData.role === ROLES.PROVIDER) {
                if (isObject(userData.verification, true)) {
                  if (userData.verification.timestamp > Date.now()) {
                    throw new Error(`Provider verification timestamp in the future! ${userData.verification.timestamp}`);
                  } else {
                    dispatch(loginSuccess(userData));
                    dispatch(recordDeviceFingerprint({uid: userDoc.id}));
                  }
                } else {
                  dispatch(loginFail(new Error("You are not a verified provider")));
                }
              } else if (userData.role === ROLES.ADMIN) {
                // TODO: this probably has more to it!
                dispatch(loginSuccess(userData));
                dispatch(recordDeviceFingerprint({uid: userDoc.id}));
                return userData;
              } else {
                dispatch(loginFail(new Error(`Unsupported user role ${userData.role}`)));
              }
            } else {
                console.error("user not found");
                dispatch(loginFail(new Error("User not found!")));
            }
          }).catch ( error => {
            // TODO customize msg for auth/wrong-password
              console.error("Error in usersRef", error);
              dispatch(loginFail(error));
          });
        })
        .catch(error => {
          console.error("Error in signInWithEmailAndPassword", error);
          dispatch(loginFail(error));
        });
    }).catch ( accountError => {
        dispatch(loginFail(accountError));
    });

  }) // End set persistence
  .catch(function(error) {
    dispatch(loginFail(error));
  });
};

// Logout
export const logout = () => dispatch => {

  dispatch(logoutStart());
  myFirebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(logoutSuccess());
    })
    .catch(error => {
      dispatch(logoutFail(error));
    });
};

/**
 * Check the existing authentication state
 * @returns 
 */
export const verifyAuth = () => dispatch => {
  dispatch(authStart());

  return myFirebase.auth().setPersistence(myFirebase.auth.Auth.Persistence.SESSION)
    .then(() => {

    return myFirebase
      .auth()
      .onAuthStateChanged(user => {

        if (user !== null) {

            return usersRef.doc(user.uid).get().then( doc => {
              if (doc && doc.exists) {
                const userDoc = doc.data();

                if (userDoc.active === false) {
                  dispatch(authFail());
                  return;
                }

                const userData = (userDoc.role === ROLES.PROVIDER)?
                    {
                      uid: user.uid,
                      ...userDoc
                    }
                    :
                    {
                      uid: user.uid,
                      ...userDoc,
                      participantId: userDoc.patientNumber, 
                      providerIdDB: userDoc.providerId
                  };

                  if (user.isAnonymous) {
                    userData.isAnonymous = true;
                  }

                  if (userData.organizationId) {
                    dispatch(getOrganization(userData.organizationId));
                    if (userDoc.role === ROLES.PROVIDER) {
                      dispatch(getClinicianColleagues(userData.organizationId));
                    }
                  }

                // Call AuthSuccess
                dispatch(authSuccess(userData));
                return userData;
              } else {
                // User not found
                dispatch(authFail());
              }
            }).catch ((error) => {
                dispatch(authFail(error));
            })
          } else {
            // Email not verified
            dispatch(authFail());
          }
      });
    }) // end of set persistance
    .catch((error) => {
      dispatch(authFail());
    })
};

// Sign in Anonymously
export const signInAnonymously = (userData) => dispatch => {
  dispatch(signInAnonymouslyStart());

  myFirebase.auth().setPersistence(myFirebase.auth.Auth.Persistence.SESSION)
    .then(() => {

    myFirebase
      .auth()
      .signInAnonymously()
      .then( (data) => {
        usersRef.doc(data.user.uid)
        .set({role: userData.role, appointmentDate: userData.doa, providerId: userData.pid, lastName: userData.lastName, email: userData.email, dob: userData.dob, phone: userData.phone})
        .then(()=> {
          userData.isAnonymous = true;
          dispatch(signInAnonymouslySuccess({
            uid: data.user.uid,
            ...userData
          }));
          if (!data.user.trialGroup) {
            if (!data.user.created) {
              usersRef.doc(data.user.uid).set({
              created: myFirebase.firestore.FieldValue.serverTimestamp()
            }, {merge: true})
            }
            // userData.participantId = result.data;
            dispatch(signInAnonymouslySuccess({
              uid: data.user.uid,
              ...userData
            }));
          }
        })
      })
      .catch( (error) => {
      // Handle Errors here.
      dispatch(signInAnonymouslyFail(error));
    });
  }) // end of set persistance
  .catch((error) => {
    dispatch(authFail());
  })
}


export const authResetPassword = (data) => dispatch => {

  const {email} = data;

  dispatch(authResetPasswordStart());

  myFirebase.auth().sendPasswordResetEmail(email).then(function() {
    dispatch(authResetPasswordSuccess());

  }).catch(function(error) {
    dispatch(authResetPasswordFail( error));
  });

}

export const getOrganization = (orgId) => dispatch => {
  db.collection('organizations').doc(orgId).get().then(snapshot => {
    if (snapshot.exists) {
      dispatch({
        type: "organization/FOUND",
        payload: {...snapshot.data(), id: orgId, loaded: true}
      });
    } else {
      dispatch({
        type: "organization/NOT-FOUND"
      });
    }
  });
}

// Verify pre created patient
export const checkPreCreatedPatient = (pid) => dispatch => {
  dispatch(checkPreCreatedPatientStart());

  myFirebase.auth().setPersistence(myFirebase.auth.Auth.Persistence.SESSION)
    .then(() => {

    myFirebase
      .auth()
      .signInAnonymously()
      .then((data) => {
        return db.collection('accessKeys').doc(pid).get().then(akDoc => {
          if (akDoc.exists) {
            const accessKey = akDoc.data()
            const expirationDiff = moment().diff(accessKey.t_expires);
            if (expirationDiff < 0) {
              dispatch({
                type: "access-key/AUTHENTICATED",
                payload: {...accessKey, id: pid}
              });
              if (accessKey.organizationId) {
                dispatch(getOrganization(accessKey.organizationId));
              }
              return accessKey;
            } else {
              // access key is already expired
              dispatch({
                type: "access-key/EXPIRED",
                // TODO: should we censor the user/invite IDs from here?
                payload: accessKey
              });
              throw new Error(`Access key ${pid} expired ${expirationDiff}ms ago!`);
            }
          }
        }).then(accessKey => {
          return Promise.all([
            usersRef.doc(accessKey.userId).get(),
            screeningsRef.doc(accessKey.inviteId).get()
          ]).then(([userDoc, screeningDoc]) => {
            if (!userDoc.exists) {
              throw new Error('Could not find user associated with this access key', accessKey.userId);
            } else if (!screeningDoc.exists) {
              throw new Error('Could not find health report invite associated with this access key', accessKey.inviteId);
            } else {
              const batch = db.batch();
              const now = Date.now();
              if (!accessKey.previouslyOpened) {
                batch.update(
                  db.collection('accessKeys').doc(pid),
                  {previouslyOpened: true});
                if (screeningDoc.data().status === ScreeningStatus.InviteSent) {
                  batch.update(
                    db.collection('screenings').doc(accessKey.inviteId),
                    {status: ScreeningStatus.InviteOpened, t_statusUpdated: now})
                }
              }
              if ([UserClinicStatus.InviteSent, UserClinicStatus.InviteReceived].includes(userDoc.data().status)) {
                batch.update(
                  usersRef.doc(accessKey.userId),
                  {status: UserClinicStatus.AppOpened, t_statusUpdated: now});
              }
              dispatch(recordDeviceFingerprint({uid: accessKey.userId, batch}));
              batch.commit();
              dispatch(checkPreCreatedPatientSuccess({
                user: {
                  ...userDoc.data(),
                  uid: userDoc.id
                },
                previouslyOpened: accessKey.previouslyOpened,
                screeningId: accessKey.inviteId
              }));
              dispatch(addScreeningDataFromOtherSource({id: screeningDoc.id, ...screeningDoc.data()}));
              return accessKey;
            }
          });
        })
        .catch((error) => {
          dispatch(checkPreCreatedPatientFail(error));
         });
     })
     .catch( (error) => {
          // Handle Errors here.
        dispatch(signInAnonymouslyFail(error));
      });
  }) // end of set persistance
  .catch((error) => {
    dispatch(authFail());
  })
}


export const updateUserLastName = (userData) => dispatch => {
  dispatch(updateUserLastNameStart());
  usersRef
    .doc(userData.userId)
    .set({
      patientInputLastName: userData.lastName
    }, {merge: true})
    .then(doc => {
      dispatch(updateUserLastNameSuccess(doc));
    })
    .catch((error) => {
      dispatch(updateUserLastNameFail());
    });
}

export const debugChangePassword = (newPassword) => {
  const user = myFirebase.auth().currentUser;
  user.updatePassword(newPassword).then(() => {
    alert("Password successfully changed!");
  }).catch((error) => {
    console.error(error);
    alert("Could not change password, see console.");
  });
}

export const sessionExpired = createAction("session/EXPIRED-BY-TIMEOUT");

export const debugTweakAccessKey = createAction("access-key/DEBUG-TWEAK");
export const updateAccessKeyDetail = createAsyncThunk(
  "access-key/detail-update",
  ({id, language, method, referrer}, thunkAPI) => {
    id = id ?? thunkAPI.getState().auth?.accessKey?.id;
    const update = {t_updated: Date.now()};
    if (language) update.chosenLanguage = language;
    if (method) update.lastMethodUsed = method;
    if (referrer) update.lastReferrer = referrer;
    return db.collection("accessKeys").doc(id).update(update);
  }
);