import React from "react";

import PasswordChangeForm from "../PasswordChange";
import { Row, Col } from '../UI/Grid/Grid';

import "../../styles/homepage.css";
import { Link } from "react-router-dom";
import { PROVIDER } from "../../constants/routes";
import { useSelector } from "react-redux";

const AccountPage = () => {
  const user = useSelector(s => s.auth.user || {});
  const org = useSelector(s => s.auth.organization || {});
  
  return (
  <div className="home-container m-5">
    <Row className="justify-content-center">
      <Col className="col-lg-10">
        <div className="mb-2">
          {/* <Link to={PROVIDER} className="btn btn-outline">← Back to Clinician Home</Link> */}
        </div>
        <h1>Your Account</h1>
        <table className="table">
          <tbody>
            <tr>
              <th>Name</th>
              <td>{user.name}</td>
              <td style={{opacity: "30%"}} title="Debug information">{user.uid}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
              <td></td>
            </tr>
            <tr>
              <th>Title</th>
              <td>{user.jobTitle}</td>
              <td></td>
            </tr>
            <tr>
              <th>Organization</th>
              <td>
                {org.name || <em>(unknown)</em>}
              </td>
              <td style={{opacity: "30%"}} title="Debug information">{org.id}</td>
            </tr>
            <tr>
              <th>System Role</th>
              <td><span className="badge badge-dark">{user.role}</span></td>
              <td><span className="badge badge-success">Account Active</span></td>
            </tr>
          </tbody>
        </table>
        <br/>
        <h2>Modify...</h2>
        <PasswordChangeForm />
      </Col>
    </Row>
  </div>
  );
}
export default AccountPage;
