import * as d3 from "d3";
import "d3-force";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { FaCogs, FaRegFolderOpen, FaRegSave, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Language } from "../../constants/locales";
import { ScreeningType } from "../../constants/screenings";
import { getQuestionnaireById, mapFormulas, requestQuestionnaire, shouldSkipCustomContext } from "../../store/slices/questionnaire";
import { assignOnly, capitalizeFirstLetter, clamp, objectEntries } from "../../utils";
import { findDependencies } from "../../utils/evaluator";
import { useQuery } from "../../utils/hooks";
import ResponseChoices from "../History/ResponseChoices";
import { alertEvent, snack } from "../UI/GlobalAlerts";

export const ExploreQuestionnaire = ({type: componentType, match}) => {
  const type = componentType || match?.params?.type || ScreeningType.TEST_AND_DEBUG;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestQuestionnaire({type, locale: {language: Language.English}, flags: {noAliasReplacement: true, overrideId: `explore:${type}`}}));
  }, []);
  const target = useSelector(s => getQuestionnaireById(s, `explore:${type}`));

  /**  @type {React.RefObject<HTMLTableCellElement>} */
  const innerRef = createRef();

  const colorScale = useRef(null);

  const query = useQuery();

  const [selected, setSelected] = useState();
  const [links, setLinks] = useState([]);
  
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [graphConfig, setGraphConfig] = useState({
    separationForce: 1.0,
    linkingForce: 1.0,
    membershipForce: 1.0,
    orderingForce: 1.0,
    orderingAngle: Math.acos(Math.sqrt(4/5))
  });

  function buildQuestionnaireGraph (questionnaire) {
    if (!questionnaire || !questionnaire.questions) return [[], []];
    const nodes = []
    const links = [];
    const qs = Array.from(Object.entries(questionnaire.questions || {}));
    qs.forEach(([k,q], i) => {
      nodes.push({id: `?${k}`, type: "question", order: i});
      // if (k.includes(".")) {
      //   const short = "?" + k.split(".")[0];
      //   nodes.push({id: short, type: "<name bug>"});
      //   links.push({source: short, target: `?${k}`, type: "<name bug>"});
      // }
      if (q.section) {
        links.push({source: `?${k}`, target: `§${q.section}`, type: "member of section"});
      }
    });
    const ss = Array.from(Object.entries(questionnaire.sections || {}));
    ss.forEach(([k,q], i) => {
      nodes.push({id: `§${k}`, type: "section", order: null}); // i/((ss.length * qs.length) + Number.EPSILON)
    });
    Array.from(Object.entries(questionnaire.reportOutcomes || {})).forEach(([k,q], i) => {
      nodes.push({id: `~${k}`, type: "outcome", order: null});
    });
    Array.from(Object.entries(questionnaire.formulaAliases || {})).forEach(([k,q], i) => {
      nodes.push({id: `=${k}`, type: "formulaAlias", order: null});
    });
    function addLink(def, path) {
      const sourceNode = nodes.find(n => n.id === def.source);
      if (!sourceNode) {
        const possibilities = nodes.filter(n => n.id.startsWith(def.source));
        if (possibilities.length === 1) {
          console.log(`Assuming "${def.source}" means "${possibilities[0].id}" due to path separator ambiguity`);
          def.source = possibilities[0].id;
        } else {
          console.warn(`Couldn't find source of ${def.type} '${def.source}'  (in ${path})`)
          nodes.push({id: def.source, type: "MISSING ENTRY"});
        }
      }
      const targetNode = nodes.find(n => n.id === def.target);
      if (!targetNode) {
        const possibilities = nodes.filter(n => n.id.startsWith(def.target));
        if (possibilities.length === 1) {
          console.log(`Assuming "${def.target}" means "${possibilities[0].id}" due to path separator ambiguity`);
          def.target = possibilities[0].id;
        } else {
          console.warn(`Couldn't find target of ${def.type} '${def.target}'  (in ${path})`)
          nodes.push({id: def.target, type: "MISSING ENTRY"});
        }
      }
      links.push(def);
    }
    mapFormulas(questionnaire, (_, formula, path) => {
      const dependencies = findDependencies(formula);
      console.warn(dependencies);
      const segments = path.split(".");
      const source = segments[0] === "questions" ? 
        ("?" + segments[1])
        : (segments[0] === "reportOutcomes" ?
          ("~" + segments[1]) 
          : (segments[0] === "formulaAliases" ?
            ("=" + segments[1]) 
            : (segments[0] === "sections" ?
              ("§" + segments[1]) 
              : (null))));
      if (source) {
        dependencies.questions.forEach(qk => {
          addLink({source, target: "?" + qk, type: segments[segments.length - 1]}, path);
        });
        dependencies.formulaAliases.forEach(ak => {
          addLink({source, target: "=" + ak, type: segments[segments.length - 1]}, path);
        });
        dependencies.reportOutcomes.forEach(ok => {
          addLink({source, target: "~" + ok, type: segments[segments.length - 1]}, path);
        });
      }
    }, true, true);
    console.log(links);
    setLinks(links);
    return [nodes, links];
  }

  function buildGraphNode (questionnaire) {
    const [nodes, links] = buildQuestionnaireGraph(questionnaire);
    console.log("building graph");

    let wasDragging = false;
    const drag = simulation => {
      let distance = 0;
      function dragstarted(event, d) {
        console.log("dragstart");
        d.wasFixedAtStartOfDrag = d.fixedByUser;
        d.nonPhysicsDrag = event.sourceEvent.metaKey;
        d.fx = d.x;
        d.fy = d.y;
        if (d.nonPhysicsDrag) {
          simulation.updateNonPhysics();
        } else {
          simulation.alphaTarget(0.3).restart();
        }
      }
      
      function dragged(event, d) {
        console.log("dragged");
        distance += Math.abs(d.x - event.x) + Math.abs(d.y - event.y);
        d.x = d.fx = event.x;
        d.y = d.fy = event.y;
        if (d.nonPhysicsDrag) simulation.updateNonPhysics();
      }
      
      function dragended(event, d) {
        console.log("dragended: " + distance);
        if (!event.sourceEvent?.shiftKey && !d.fixedByUser) {
          d.fx = null;
          d.fy = null;
        } else {
          d.fixedByUser = true;
        }
        if (d.nonPhysicsDrag) {
          simulation.updateNonPhysics();
        } else if (event.active === 0) {
          simulation.alphaTarget(0);
        }
      }
      return d3.drag()
        .container(svg)
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    }

    function linkArc(d) {
      const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
      return `
        M${d.source.x},${d.source.y}
        A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
      `;
    }
    function linkStraight(d) {
      return `
        M${d.source.x},${d.source.y}
        L${d.target.x},${d.target.y}
      `;
    }

    function orderForce (nodes, baseVector = [4.0/5.0, 3.0/5.0], strength = 20) {
      let orderMin = Number.MAX_SAFE_INTEGER;
      let orderMax = Number.MIN_SAFE_INTEGER;
      let filteredNodes = nodes.filter(n => {
        if (Number.isFinite(n.order)) {
          if (n.order > orderMax) orderMax = n.order;
          if (n.order < orderMin) orderMin = n.order;
          return true;
        }
        return false;
      });
      console.log(`Found ${filteredNodes.length} nodes betweeen ${orderMin} and ${orderMax}`);
      let run = 0;
      return (alpha) => {
        let posMin = Number.MAX_SAFE_INTEGER;
        let posMax = Number.MIN_SAFE_INTEGER;
        filteredNodes.forEach(n => {
          n._orderPos = n.x * baseVector[0] + n.y * baseVector[1];
          if (n._orderPos > posMax) posMax = n._orderPos;
          if (n._orderPos < posMin) posMin = n._orderPos;
        });
        // console.log(`Min and max positions were ${posMin} and ${posMax}`);
        filteredNodes.forEach(n => {
          const targetRatio = n.order / (orderMax - orderMin);
          const currentRatio = n._orderPos / (posMax - posMin);
          const delta = (targetRatio - currentRatio) * (alpha);
          n.vx += baseVector[0] * delta * strength;
          n.vy += baseVector[1] * delta * strength;
        })
        run++;
      }
    }

    const mainLinks = links.filter(l => !l.type.startsWith("member"));
    const membershipLinks = links.filter(l => l.type.startsWith("member"));
    const nodeTypes = Array.from(new Set(nodes.map(d => d.type)))
    const linkTypes = Array.from(new Set(mainLinks.map(d => d.type)))
    const color = d3.scaleOrdinal(linkTypes, d3.schemeCategory10);
    const nodeColor = d3.scaleOrdinal(nodeTypes, d3.schemeDark2.slice().reverse());
    colorScale.current = {links: color, nodes: nodeColor};
    console.log(d3.schemeDark2);
    console.log(nodeTypes);
    
    const simulation = d3.forceSimulation(nodes)
        .force("link", d3.forceLink(mainLinks).id(d => d.id).strength(0.25 * graphConfig.linkingForce))
        .force("membership", d3.forceLink(membershipLinks).id(d => d.id).strength(0.1 * graphConfig.membershipForce))
        .force("charge", d3.forceManyBody().strength(-400 * graphConfig.linkingForce))
        .force("ordering", orderForce(nodes, [Math.cos(graphConfig.orderingAngle), Math.sin(graphConfig.orderingAngle)], 50, graphConfig.orderingForce))
        .force("x", d3.forceX())
        .force("y", d3.forceY());

    simulation.localSelected = selected;
  
    console.log(innerRef.current);
    let width = 100;
    let height = 100;
    const svg = d3.create("svg")
        .attr("viewBox", [-width/2, -height/2, width, height])
        .attr("height", "100%")
        .attr("width", "100%")
        .style("font", "12px sans-serif")
        .style("border", "1px solid gray");

    simulation.updateViewBox = (w, h) => {
      width = w;
      height = h;
      svg.attr("viewBox", [-w/2, -h/2, w, h]);
      debugBox.attr("transform", `translate(${-width/2}, ${height/2-13})`);
    }

    const mainBox = svg.append("g"); //.attr("transform-origin", "center")

    const debugBox = svg.append("g").attr("transform", `translate(${-width/2}, ${height/2-10})`).attr("font-style", "italic");
    const status = debugBox.append("text").text("Running (tick 0)");


    // Per-type markers, as they don't inherit styles.
    mainBox.append("defs").selectAll("marker")
      .data(linkTypes)
      .join("marker")
        .attr("id", d => `arrow-${d}`)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 15)
        .attr("refY", -0.5)
        .attr("markerWidth", 6)
        .attr("markerHeight", 6)
        .attr("orient", "auto")
      .append("path")
        .attr("fill", color)
        .attr("d", "M0,-5L10,0L0,5");

  
    const link = mainBox.append("g")
        .attr("fill", "none")
        .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(mainLinks)
      .join("path")
        .attr("stroke", d => color(d.type))
        .attr("marker-end", d => `url(${new URL(`#arrow-${d.type}`, window.location.href)})`);
    const memberLink = mainBox.append("g")
        .attr("fill", "none")
        .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(membershipLinks)
      .join("path")
        .attr("stroke", "rgba(0,0,0,0.1)")
        .attr("marker-end", d => `url(${new URL(`#arrow-${d.type}`, window.location.href)})`);
  
    const node = mainBox.append("g")
    .attr("stroke-linecap", "round")
    .attr("stroke-linejoin", "round")
    .selectAll("g")
    .data(nodes)
      .classed("selected-node", d => (d && d.id === simulation.localSelected))
      .join("g")
        .call(drag(simulation));

    mainBox.insert("rect", ":first-child")
      .attr("x", "-50%")
      .attr("y", "-50%")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("fill", "rgba(240, 240, 256, 0.1)");
    svg.call(d3.drag()
          .container(svg)
          .on("start", mainDragStarted)
          .on("drag", mainDragged)
          .on("end", mainDragEnded));
    let dragX = 0;
    let dragY = 0;
    let dragStartX;
    let dragStartY;
    function mainDragStarted(event, d) {
      dragStartX = event.x;
      dragStartY = event.y;
      simulation.updateNonPhysics();
    }
    function mainDragged(event, d) {
      dragX = event.x - dragStartX;
      dragY = event.y - dragStartY;
      simulation.updateNonPhysics()
    }
    function mainDragEnded(event, d) {
      globalY += dragY;
      globalX += dragX;
      dragX = 0;
      dragY = 0;
      simulation.updateNonPhysics()
    }

    node.on("click", (event, d) => {
      // d3.select(d.id).classed("selected-node", true);
      if (event.shiftKey) {
        console.log("click with shift");
        if (d.wasFixedAtStartOfDrag) {
          toggleNodeFixedness(d);
        }
      } else {
        setSelected(d.id);
        simulation.localSelected = d.id;
      }
      event.stopPropagation();
    });
    svg.on("click", (e, d) => {
      setSelected(null);
      simulation.localSelected = null;
    })
  
    node.filter(d => d.type !== "section").append("circle")
        .attr("stroke", "white")
        .attr("stroke-width", 1.5)
        .attr("fill", d => nodeColor(d.type))
        .attr("r", 4);
    node.filter(d => d.type === "section").append("rect")
        .attr("stroke", d => nodeColor(d.type))
        .attr("stroke-width", 1)
        .attr("fill", "none")
        .attr("x", -4)
        .attr("y", -4)
        .attr("width", 8)
        .attr("height", 8);
  
    node.append("text")
        .attr("x", 8)
        .attr("y", "0.31em")
        .text(d => d.id)
      .clone(true).lower()
        .attr("fill", "none")
        .attr("stroke", "white")
        .attr("stroke-width", 3);

    let globalX = 0, globalY = 0, globalZoom = 1.0;
    if (query.has("coords") || match?.params?.coords) {
      let coords = query.get("coords") || match?.params?.coords;
      if (typeof coords === "string") {
        coords = coords.split(",");
        if (coords.length === 3) {
          globalX = Number.parseInt(coords[0], 10) || 0;
          globalY = Number.parseInt(coords[1], 10) || 0;
          globalZoom = (Number.parseInt(coords[2], 10) || 1000)/1000;
        }
      }
    }
    const ZOOM_FACTOR = 1.05;
    const ZOOM_LIMIT = 5;

    svg.on("wheel", event => {
      if (event.deltaY < 0) {
        globalZoom = Math.max(globalZoom / ZOOM_FACTOR, 1/ZOOM_LIMIT);
      } else {
        globalZoom = Math.min(globalZoom * ZOOM_FACTOR, ZOOM_LIMIT);
      }
      simulation.updateNonPhysics();
    })
    const PAN_KEYS = {
      ArrowRight: [-2, 0],
      ArrowLeft: [2, 0],
      ArrowUp: [0, 2],
      ArrowDown: [0, -2],
    }
    const ZOOM_KEYS = {
      "+": 1.025,
      "-": 0.975
    };
    const heldKeys = new Set();
    d3.select("body").on("keydown", event => {
      if (PAN_KEYS[event.key] || ZOOM_KEYS[event.key]) {
        heldKeys.add(event.key)
        simulation.updateNonPhysics();
      } else if (event.key === "0") {
        globalZoom = 1.0;
        simulation.updateNonPhysics();
      } else if (event.key === " ") {
        briefRestart();
      }
    })
    d3.select("body").on("keyup", event => {
      heldKeys.delete(event.key)
      // if (heldKeys.size === 0) simulation.alphaTarget(0);
    });
    simulation.setView = function (x, y, zoom) {
      globalX = x;
      globalY = y;
      globalZoom = clamp(zoom, 1/ZOOM_LIMIT, ZOOM_LIMIT);
    }
    simulation.getView = function () {
      return {globalX, globalY, globalZoom};
    }

    simulation.hiddenCategories = [];

    simulation.updateNonPhysics = () => {
      node.attr("transform", d => `translate(${d.x},${d.y})`);
      link.attr("d", linkStraight); // linkArc
      memberLink.attr("d", linkStraight);
      node.classed("selected-node", d => (d && d.id === simulation.localSelected));
      const pauseScrollFactor = running ? 1.0 : 5.0;
      for (let k of heldKeys) {
        if (PAN_KEYS[k]) {
          globalX += PAN_KEYS[k][0] * pauseScrollFactor;
          globalY += PAN_KEYS[k][1] * pauseScrollFactor;
        }
        if (ZOOM_KEYS[k]) {
          globalZoom = clamp(globalZoom * ZOOM_KEYS[k], 1/ZOOM_LIMIT, ZOOM_LIMIT);
        }
      }
      node.classed("fixed-node", d => typeof d.fx === "number");
      node.classed("hidden-node", d => (simulation.hiddenCategories.indexOf(d.type) > -1));
      link.classed("hidden-link", l => {
        const toCompare = new Set([l.type, l.source?.type, l.target?.type]);
        return simulation.hiddenCategories.find(c => toCompare.has(c));
      });
      memberLink.classed("hidden-link", l => {
        const toCompare = new Set([l.type, l.source?.type, l.target?.type]);
        return simulation.hiddenCategories.find(c => toCompare.has(c));
      });
      mainBox.attr("transform", `translate(${globalX + dragX}, ${globalY + dragY}) scale(${globalZoom} ${globalZoom})`);
      updateStatus();
    }
  
    let tickNum = 0;
    let running = true;
    function updateStatus () {
      status.text(`${running ? "Running" : "Paused"} tick (${tickNum}) center (${-globalX}, ${-globalY}) zoom (${Math.round(globalZoom * 100)/100})`)
    }
    simulation.on("tick", () => {
      tickNum++;
      running = true;
      wasDragging = false;
      simulation.updateNonPhysics();
    });
    simulation.on("end", () => {
      running = false;
      updateStatus();
    })
  
    const chart = svg.node();
    return [chart, simulation];
  }
  const [graphSVGNode, simulation] = useMemo(() => buildGraphNode(target), [target])
  const [hiddenCategories, setHiddenCategories] = useState(simulation.hiddenCategories);

  const selectedDetails = useMemo(() => {
    if (!target) return null;
    if (typeof selected === "string") {
      const linksFrom = links.filter(l => l.source.id === selected);
      const linksTo = links.filter(l => l.target.id === selected);
      const node = simulation.nodes().find(n => n.id === selected);
      switch (selected[0]) {
        case "?":
          return {type: "question", definition: target?.questions?.[selected.slice(1)], linksFrom, linksTo, node};
        case "~":
          return {type: "outcome", definition: target?.reportOutcomes?.[selected.slice(1)], linksFrom, linksTo, node};
        case "§":
          return {type: "section", definition: target?.sections?.[selected.slice(1)], linksFrom, linksTo, node};
        case "=":
          return {type: "formula alias", definition: target?.formulaAliases?.[selected.slice(1)], linksFrom, linksTo, node};
        default:
          return {type: "unknown"};
      } 
    } else {
      return {type: "unsupported"};
    }
  }, [target, selected, links]);

  useEffect(() => {
    if (innerRef.current instanceof HTMLTableCellElement) {
      simulation.updateViewBox(innerRef.current.clientWidth, innerRef.current.clientHeight);
      innerRef.current.replaceChildren(graphSVGNode);
    }
  }, [innerRef, graphSVGNode]);

  function updateSelection (s) {
    setSelected(s);
    simulation.localSelected = s;
    simulation.updateNonPhysics();
  }

  function toggleHiddenType (t) {
    const index = simulation.hiddenCategories.indexOf(t);
    if (index > -1) {
      simulation.hiddenCategories.splice(index, 1);
    } else {
      simulation.hiddenCategories.push(t);
    }
    setHiddenCategories(simulation.hiddenCategories.slice());
    simulation.updateNonPhysics();
  }

  const [detailsType, setDetailsType] = useState("definition_raw")
  const detailsDropdown = [
    "definition_raw",
    "node_raw",
    "answer_controls"
  ];

  const [forcedUpdates, setForcedUpdates] = useState(0);
  function forceRerender () {
    setForcedUpdates(forcedUpdates + 1);
  }

  function briefRestart () {
    simulation.alpha(0.2);
    simulation.restart();
  }

  function toggleNodeFixedness (n) {
    console.log("toggling");
    if (!n) return;
    if (!n.fixedByUser) {
      n.fixedByUser = true;
      n.fx = n.x;
      n.fy = n.y;
    } else {
      n.fixedByUser = false;
      n.fx = null; 
      n.fy = null;
      // briefRestart();
    }
    simulation.updateNonPhysics();
    forceRerender();
  }

  function saveNodePos () {
    const view = simulation.getView();
    const output = JSON.stringify(simulation.nodes().map(n => ({
      id: n.id,
      x: n.x,
      y: n.y,
      fx: n.fx,
      fy: n.fy,
      fixedByUser: !!n.fixedByUser
    })).concat({id: "_global", x: view.globalX, y: view.globalY, zoom: view.globalZoom, type}));
    window.localStorage.setItem("explore-positions",output);
  }

  function loadNodePos () {
    const v = window.localStorage.getItem("explore-positions");
    if (v) {
      const arr = JSON.parse(v);
      if (!Array.isArray(arr)) throw new Error("Expected saved positions to be array");
      const nodes = simulation.nodes();
      arr.forEach(n => {
        const local = nodes.find(l => l.id === n.id);
        if (local) {
          assignOnly(n, local, ["x", "y", "fx", "fy", "fixedByUser"]);
        } else if (n.id === "_global") {
          simulation.setView(n.x, n.y, n.zoom);
          if (n.type !== type) {
            dispatch(alertEvent(`Saved positions are from questionnaire type (${n.type}), attempting to load anyway...`));
          }
        }
      });
      console.log(simulation.nodes());
      simulation.updateNonPhysics();
      // simulation.alphaTarget(0.1).restart();
    }
  }

  function getCameraLink () {
    if (simulation) {
      const coords = simulation.getView();
      const url = `${window.location.origin}/explore/${type}?coords=${coords.globalX},${coords.globalY},${Math.round(coords.globalZoom * 1000)}`;
      navigator.clipboard.writeText(url).then(() => {
        dispatch(snack(`Copied URL to clipboard: ${url}`));
      }, err => {
        dispatch(alertEvent(`Could not URL copy to clipboard: ${url}`));
      })
    }
  }

  const answers = {};

  function evaluateEnabled () {
    if (!target || !simulation) return;
    const skips = {};
    objectEntries(target.sections).forEach(([key, def]) => {
      skips["§"+key] = shouldSkipCustomContext(def, answers, target, `sections.${key}`);
    });
    objectEntries(target.questions).forEach(([key, def]) => {
      skips["?"+key] = skips["§"+def.section] || shouldSkipCustomContext(def, answers, target, `questions.${key}`);
    });
    // entries for other enablable things that are rarer? (responses, flags, outcomes)

    simulation.nodes().forEach(n => {
      n.skippedLastEval = !!skips[n.id];
    });

    // enable showing

  }

  return <div>
    {target ? 
      <div style={{position: "relative"}}><table className="w-100" style={{height: "calc(100vh - 62px)"}}><tbody>
        <tr>
          <td width="25%" style={{background: "aliceblue", padding: "1em", verticalAlign: "top"}}>
            {selected ? 
              <><h3>{capitalizeFirstLetter(selectedDetails?.type || "Node")} Details</h3>
              <p>
                <strong>Key:</strong>{" "}
                <em>{selected?.slice(1)}</em>{" "}
                {Number.isFinite(selectedDetails?.definition?.naturalOrder) ? <span className="badge badge-dark float-right">#{selectedDetails?.definition?.naturalOrder}</span> : null}
              </p>
                <p>
                  <strong>Fixed:</strong>{" "}
                  <input type="checkbox" checked={selectedDetails?.node?.fixedByUser} onChange={e => toggleNodeFixedness(selectedDetails?.node)}/>
                </p>
              {selectedDetails?.linksFrom ?
                <><strong>Links to:</strong>
                <ul>
                  {selectedDetails?.linksFrom?.map((l, i) => <li onClick={() => updateSelection(l.target.id)} key={i}>{l.target.id}</li>)}
                  {selectedDetails?.linksFrom?.length ? null : <li><em>(nothing)</em></li>}
                </ul>
                </>
              : null}
              {selectedDetails?.linksTo ?
                <><strong>Is linked from:</strong>
                <ul>
                  {selectedDetails?.linksTo?.map((l, i) => <li onClick={() => updateSelection(l.source.id)} key={i}>{l.source.id}</li>)}
                  {selectedDetails?.linksTo?.length ? null : <li><em>(nothing)</em></li>}
                </ul>
                </>
              : null}
              <select value={detailsType} onChange={e => setDetailsType(e.target.value)}>
                {detailsDropdown.map(d => <option>{d}</option>)}
              </select>
              </>
              :
              <>
                <h3>
                  Overall Summary
                  <span className="float-right" onClick={() => setShowSettingsModal(true)}><FaCogs/></span>
                </h3>
                <ul>
                  <li><em>Sections:</em> {Object.keys(target?.sections ?? {}).length}</li>
                  <li><em>Questions:</em> {Object.keys(target?.questions ?? {}).length}</li>
                  <li><em>Formula Aliases: {Object.keys(target?.formulaAliases ?? {}).length}</em></li>
                  <li><em>Outcomes:</em> {Object.keys(target?.reportOutcomes ?? {}).length}</li>
                </ul>
                <p className="text-small" style={{textDecoration: "underline"}} onClick={getCameraLink}>Get link to current camera view</p>
              </>
              }
          </td>
          <td rowSpan={2} width="75%" id="force-graph" ref={innerRef} />
        </tr>
        {selected === null ? null : <tr style={{height: "60vh"}}>
          <td style={{}}> {/* overflowY: "scroll",  */}
            {detailsType === "definition_raw" ? <textarea readOnly={true} value={JSON.stringify(selectedDetails?.definition, null, 2)} style={{width: "100%", height: "100%"}}/> : null}
            {detailsType === "node_raw" ? <textarea readOnly={true} value={JSON.stringify(selectedDetails?.node, null, 2)} style={{width: "100%", height: "100%"}}/> : null}
            {detailsType === "answer_controls" ? <div style={{}}> {/* transform: scale(0.5) */}
              <ResponseChoices
               key={-1}
               questionId={-1}
               questionKey={selectedDetails?.definition?.id}
               answerKey={selectedDetails?.definition?.id}
               choices={selectedDetails?.definition?.responses}
               selected={[]}
               toggleResponse={(r) => {/* TODO */}}
               // changeValue={changeSubValue}
               fluidCards={true}/> 
            </div> : null}
          </td>
        </tr>}
      </tbody></table>
        <div style={{position: "absolute", top: "5px", right: "5px"}}>
          {colorScale.current && typeof colorScale.current.links === "function" ?
            <>
            Links: {colorScale.current.links.domain().map(s => <span key={s} style={{background: colorScale.current.links(s), padding: "2px", margin: "2px", color: "white", fontSize: "75%", textDecoration: (hiddenCategories.indexOf(s) > -1) ? "line-through" : "none", opacity: (hiddenCategories.indexOf(s) > -1) ? "50%" : "100%"}} onClick={() => toggleHiddenType(s)}>{s}</span>)}
            <span style={{background: "rgba(0,0,0,0.1)", padding: "2px", margin: "2px", color: "#444", fontSize: "75%", textDecoration: (hiddenCategories.indexOf("member of section") > -1) ? "line-through" : "none", opacity: (hiddenCategories.indexOf("member of section") > -1) ? "50%" : "100%"}} onClick={() => toggleHiddenType("member of section")}>section membership</span>
            <br/>
            Nodes: {colorScale.current.nodes.domain().map(s => <span key={s} style={{background: colorScale.current.nodes(s), padding: "2px", margin: "2px", color: "white", fontSize: "75%", textDecoration: (hiddenCategories.indexOf(s) > -1) ? "line-through" : "none", opacity: (hiddenCategories.indexOf(s) > -1) ? "50%" : "100%"}} onClick={() => toggleHiddenType(s)}>{s}</span>)}
            {/* <span style={{float: "right", fontSize: "75%"}}>attempting to sort order towards bottom-right</span> */}
            </>
          : "no colors..." + (typeof colorScale)}
        </div>
        <div style={{position: "absolute", top: "5px", left: "calc(25% + 5px)"}}>
          <button className="btn btn-xs btn-primary" onClick={() => saveNodePos()}><FaRegSave/> Save Layout</button>{" "}
          <button className="btn btn-xs btn-primary" onClick={() => loadNodePos()}><FaRegFolderOpen/> Load Layout</button>
          <br/>
          <button className="btn btn-xs btn-warning" onClick={() => evaluateEnabled()}><FaSearch/> Show Active</button>
        </div>
      </div>

    : <h4 className="text-danger">Questionnaire ({type}) not loaded</h4>}
  </div>;
}