import React from "react";
import { Container, Row, Col } from "../UI/Grid/Grid";
// import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
// import Button from "../UI/Button/Button";
import classes from "../../styles/About.module.scss";
import * as ROUTES from "../../constants/routes";

const About = () => (
  <>
    <div className="public-page text-center">
      <Row className="title-banner bg-light-red-orange m-0">
        <Container>
          <h1 className="heading1 mb-0 text-left text-magenta-pink">Our Story</h1>
        </Container>
      </Row>
      <section id="public-page-info">
        <Container>
          <Row className="align-items-center mt-5 mb-5">
            <Col
              className="col-12 col-lg-6 mt-4 mb-lg-4 text-left"
            >
              <h2
                className="heading2 text-uppercase text-magenta-pink pb-4"
                style={{ fontSize: "30px" }}
              >
                Communication should not be a barrier to getting livesaving
                medical care
              </h2>
              <p
                className="text-dark-grey"
                style={{
                  lineHeight: "40px"
                }}
              >
                Our approach is informed by the lived experience of multiple,
                heartbreaking tragedies that could have been prevented with good
                health communication between patients and their medical care
                teams.
              </p> <br /> 
              <p
                className="text-dark-grey"
                style={{lineHeight: "40px"}}
              >
                These experiences motivated our work.
              </p>
            </Col>
            <Col className="col-12 col-lg-6">
              <img
                src="/images/results.png"
                className="cartoon-img full-width-image"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="approach"  className="bg-persian-green justify-content-center pb-5">
        <Container>
          <Row style={{ textAlign: "center", padding: "45px", margin: "0 auto" }}>
            <div style={{ width: "980px", margin: "0 auto" }}>
              <h2 className="text-white text-uppercase">Our Approach</h2>
              <p className="text-white" style={{ fontSize: "20px" }}>
                We collaborate with doctors, health systems, and public health
                professionals to design and develop our solutions. Our aim is to
                bridge the communication gap between medical providers and
                patients by addressing the needs of both, enabling more
                efficient and targeted healthcare delivery where all patients
                are comfortable seeking care at the earliest sign of a problem.
              </p> 
              <br /> 
              <p className="text-white" style={{ fontSize: "20px" }}>
                Our solution is being tested with linguistically and culturally
                diverse patients and their medical providers in real-world
                clinical settings within safety-net health systems and clinics.
              </p>
            </div>
          </Row>
        </Container>
      </section>

      <section id="process" style={{ backgroundImage: "url(/images/pattern-bg.png)" }}>
        <Container>
          <Row
            className="justify-content-center"
          >
            <Col className="col-12 mb-4">
              <h2
                className="h1 text-viridian-green"
                style={{ marginTop: "30px" }}
              >
                Our Process
              </h2>
              <h3 className="heading2 text-viridian-green">
                How We Decrease Miscommunication Between Patients And Medical
                Teams
              </h3>
            </Col>
            <Row className="mb-5 justify-content-center card-wrapper">
              <div className="card-deck">
                <Col className="card mb-4">
                  <div className="card-body">
                    <img
                      src="/images/accessibility.png"
                      alt="accessibility"
                      className="card-img-top"
                      style={{ height: "75px", width: "75px" }}
                    />
                    <div className="card-title text-viridian-green pt-3">
                      Accessible To <br /> Everyone
                    </div>
                    <p
                      className="card-text text-left"
                      style={{ fontSize: "14px" }}
                    >
                      Our digital products are usable by everyone, no matter
                      their ability to read or speak English.We work with
                      hospitals and clinics to implement an approach that works
                      best for their patient population.
                    </p>
                  </div>
                </Col>
                <div className="w-100 d-block d-md-none"></div>
                <Col className="card mb-4">
                  <div className="card-body">
                    <img
                      src="/images/app-success.png"
                      alt="app success"
                      className="card-img-top"
                      style={{ height: "75px", width: "75px" }}
                    />
                    <div className="card-title text-viridian-green pt-3">
                      Easy to <br /> Understand
                    </div>
                    <p
                      className="card-text text-left"
                      style={{ fontSize: "14px" }}
                    >
                      We use plain, simple, and direct language to make sure
                      that anyone can understand. We support understanding with
                      pictures, text-to-speech audio, and explanations of
                      medical terms.
                    </p>
                  </div>
                </Col>
                <div className="w-100 d-block d-lg-none"></div>
                <Col className="card mb-4">
                  <div className="card-body">
                    <img
                      src="/images/patient-chart.png"
                      alt="patient chart"
                      className="card-img-top"
                      style={{ height: "75px", width: "75px" }}
                    />
                    <div className="card-title text-viridian-green pt-3">
                      Simple <br /> Reporting
                    </div>
                    <p
                      className="card-text text-left"
                      style={{ fontSize: "14px" }}
                    >
                      We make it easy for busy medical professionals to quickly
                      understand their patient's medical condition and
                      seamlessly integrate with clinical workflows to support
                      charting requirements.
                    </p>
                  </div>
                </Col>
                <div className="w-100 d-block d-md-none"></div>
                <Col className="card mb-4">
                  <div className="card-body">
                    <img
                      src="/images/fast-time.png"
                      alt="fast time"
                      className="card-img-top"
                      style={{ height: "75px", width: "75px" }}
                    />
                    <div className="card-title text-viridian-green pt-3">
                      Improved Time Efficiency
                    </div>
                    <p
                      className="card-text text-left"
                      style={{ fontSize: "14px" }}
                    >
                      Both patient and provider are better prepared ahead of the
                      visit, leading to a more time efficient medical
                      consultation.
                    </p>
                  </div>
                </Col>
              </div>
            </Row>
            {/* <Col className="col-12 mb-4">
          <p className="font-weight-bold text-viridian-green" style={{width: "75%", margin: "0 auto"}}>
            Anyone who struggles with communicating to doctors can understand
            the survey, record their symptoms, and send their report to their
            doctor.
          </p>
        </Col> */}
          </Row>
        </Container>
      </section>

      <section id="progress" className="bg-viridian-green-gradient">
        <Container>
          <Row className="justify-content-center py-5 px-3 px-md-5">
            <Col className="col-12 col-lg-7 text-center card-wrapper">
              <h2 className="h1 text-viridian-green">Our Progress</h2>
              <p>
                LiteraSeed, Inc. is an early-stage company funded by a National
                Science Foundation Small Business Technology Transfer (STTR)
                grant.
              </p>
              <div
                className="btn cta-primary text-white mt-2"
              >
                <a
                  href="https://www.youtube.com/watch?v=qaMs1_Ww4e8"
                  target="_blank"
                >
                  See more
                </a>
              </div>
              <p className="mt-5">
                In November 2020, LiteraSeed launched it's pilot at{" "}
                <a
                  href="https://valleywisehealth.org/"
                  style={{ color: "#A47DB2", textDecoration: "underline" }}
                >
                  Valleywise Health
                </a>{" "}
                with its{" "}
                <a
                  href="https://valleywisehealth.org/services/womens-health/womens-refugee-clinic/"
                  style={{ color: "#A47DB2", textDecoration: "underline" }}
                >
                  Refugee Women's Clinic
                </a>
                .
              </p>
              <div className="btn cta-primary text-white mt-2">
                <a
                  href="https://www.youtube.com/watch?v=VvAuByZC2_Y"
                  target="_blank"
                >
                  See more
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="spacer2"></div>

      <section id="team">
        <Container>
          <Row
            className="justify-content-center card-wrapper"
            style={{ margin: "0 auto" }}
          >
            <Col className="col-12 mb-4">
              <h2 className="h1 text-viridian-green">Our Team</h2>
            </Col>
            <Row className="mb-5 justify-content-center">
              <Col className="card col-10 col-md-6 col-lg-3 card mb-4">
                <img
                  src="/images/Aziza.jpeg"
                  alt="Aziza"
                  className="card-img-top"
                />
                <div className="card-body">
                  <h4 className="card-title heading3 text-viridian-green">
                    Aziza Ismail
                  </h4>
                  <p className="card-text">Co-founder</p>
                </div>
              </Col>
              <Col className="card mx-5 col-10 col-md-6 col-lg-3 card mb-4">
                <img
                  src="/images/John.jpg"
                  alt="John"
                  className="card-img-top"
                />
                <div className="card-body">
                  <h4 className="card-title heading3 text-viridian-green">
                    John Waldeisen
                  </h4>
                  <p className="card-text">Co-founder</p>
                </div>
              </Col>
              <Col className="card col-10 col-md-6 col-lg-3 card mb-4">
                <img
                  src="/images/Taylor.jpg"
                  alt="Judy"
                  className="card-img-top"
                />
                <div className="card-body">
                  <h4 className="card-title heading3 text-viridian-green">
                    Taylor Smith
                  </h4>
                  <p className="card-text ">Software Engineer</p>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
      </section>

      <div className="spacer2"></div>

      <section id="advisors">
        <Container>
          <Row
            className="justify-content-center card-wrapper"
            style={{ margin: "0 auto" }}
          >
            <Col className="col-12 mb-4">
              <h2 className="h1 text-viridian-green">Advisory Board</h2>
            </Col>
            <Row className="mb-5 justify-content-center">
              <Col className="card col-10 col-md-6 col-lg-3 card mb-4">
                <img
                  src="/images/Michael.jpg"
                  alt="Michael"
                  className="card-img-top"
                />
                <div className="card-body">
                  <h4 className="card-title heading3 text-viridian-green">
                    Michael Carroll
                  </h4>
                  <p className="card-text">
                    Digital Health &amp; Technical Advisor
                  </p>
                </div>
              </Col>
              <Col className="card mx-5 col-10 col-md-6 col-lg-3 card mb-4">
                <img
                  src="/images/Toni.jpg"
                  alt="Toni"
                  className="card-img-top"
                />
                <div className="card-body">
                  <h4 className="card-title heading3 text-viridian-green">
                    Toni Eberhardt
                  </h4>
                  <p className="card-text">Healthcare Marketing Advisor</p>
                </div>
              </Col>
              <Col className="card col-10 col-md-6 col-lg-3 card mb-4">
                <img
                  src="/images/Hoda.jpeg"
                  alt="Hoda"
                  className="card-img-top"
                />
                <div className="card-body">
                  <h4 className="card-title heading3 text-viridian-green">
                    Hoda Eldardiry
                  </h4>
                  <p className="card-text">
                    Artificial Intelligence &amp; Machine Learning
                  </p>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
      </section>

      {/* <Row>
        <Col
          style={{
            background: "#008F99",
            padding: "30px 40px",
            color: "#FFFFFF",
            fontSize: "18px",
            textTransform: "uppercase",
          }}
        >
          <a href="https://www.youtube.com/watch?v=VvAuByZC2_Y" target="_blank">Listen to our co-founder talk about her story</a>
        </Col>
        <Col
          style={{
            background: "#4CB0B7",
            padding: "30px 40px",
            color: "#FFFFFF",
            fontSize: "18px",
            textTransform: "uppercase",
          }}
        >
          <a href="https://valleywisehealth.org/valleywise-health-collaborates-with-local-startup-literaseed-to-reduce-health-disparities-and-cultural-barriers-to-care/" target="_blank">Read about our clinical study</a>
        </Col>
        <Col
          style={{
            background: "#008F99",
            padding: "30px 40px",
            color: "#FFFFFF",
            fontSize: "18px",
            textTransform: "uppercase",
          }}
        >
          Watch our presentation explaining LiteraSeed
        </Col>
      </Row> */}

      <div className="banner-sm bg-persian-green">
        <Row className="justify-content-center pb-4 pt-4  m-0">
          <a
            href={ROUTES.FAQ}
            className="text-uppercase text-white"
            style={{ fontSize: "15px" }}
          >
            Questions? Read our FAQs
          </a>
        </Row>
      </div>
    </div>
    <Footer />
  </>
);

export default About;
