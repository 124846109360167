import { Dvr } from "@material-ui/icons";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { nanoid } from "nanoid";
import { allows, FallbackLang } from "../../constants/screenings";
import { db } from "../../database";
import { UserClinicStatus, UserInviteStatus, ScreeningStatus } from "../../database/model/user";
import { sendEmail, sendSms } from "../../store/actions";
import { chunkedBy } from "../../utils";
import { snapshotDataWithIds } from "../../utils/database";
import { getComplexRelations, getRelationsChunked, Rejected } from "../../utils/promises";
import { hourDayToTimeslot, PLURAL_DAY_NAMES } from "../../utils/time";
import * as actionTypes from './actionTypes';
import { sendWhatsApp } from "./sms";


const providersRef = db.collection('providers');
const usersRef = db.collection('users');


/* Set Prefered provider */
const setPreferedProviderStart = () => ({
  type: actionTypes.SET_PREFERED_PROVIDER_START
});

const setPreferedProviderSuccess = (data) => ({
  type: actionTypes.SET_PREFERED_PROVIDER_SUCCESS,
  data
});

const setPreferedProviderFail = (error) => ({
  type: actionTypes.SET_PREFERED_PROVIDER_FAIL,
  error
});

const savePreferedProviderIdSuccess = (data) => ({
  type: actionTypes.SAVE_PREFERED_PROVIDER_ID_SUCCESS,
  data
});



export const setPreferedProvider = (pid) => dispatch => {
  let provider = {};
  dispatch(setPreferedProviderStart());
  providersRef
    .doc(pid)
    .get()
    .then( doc =>  {
      if (doc && doc.data()) {
        dispatch(setPreferedProviderSuccess({
          uid: doc.id,
          providerName: doc.data().providerName
        }
        ));
      } else {
        dispatch(setPreferedProviderFail("Provider not found!"));
      }

    })
    .catch (error => {
      dispatch(setPreferedProviderFail(error));
    })
}

const appHostName = process.env.REACT_APP_STAGE === 'prod'
  ? process.env.REACT_APP_PROD_WEBSITE_URL
  : (process.env.NODE_ENV === 'development' ? window.location.host : process.env.REACT_APP_QA_WEBSITE_URL);
const protocol = process.env.NODE_ENV === 'development' ? "://" : "https://";
export function buildInviteUrl (accessKey, method) {
  return `${protocol}${appHostName}/anonymous/history?pid=${accessKey}${!!method ? "&method="+method : ""}`;
}

function createScheduledScreenings (screening, batch, dispatch, patientRef, user, apptRef, appointment, organization, scheduling, options) {
  const scheduledRef = db.collection('scheduledInvites').doc();
  screening.lang = allows(screening.type, user.preferredLanguage, "enrollment")
  ? user.preferredLanguage : FallbackLang;
  screening.messages = [];
  // screening.accessKey = accessKeyRef.id;
  screening.managerId = user.providerId;
  screening.userId = patientRef.id;
  screening.userDOB = user.dob;
  screening.userLastName = user.lastName;
  screening.status = ScreeningStatus.InviteSent;
  // screening.t_lastSent = now;
  // screening.t_created = now;
  if (appointment) {
    if (appointment.timestamp) {
      screening.t_appointment = appointment.timestamp;
    } else {
      screening.d_appointment = appointment.date;
    }
    screening.appointmentId = apptRef.id;
  }

  const inviteMethods = options.inviteMethods ?? {
    email: user.email,
    sms: user.phone && user.phoneType !== "whatsapp",
    whatsapp: user.phone && user.phoneType === "whatsapp"
  };

  const now = Date.now();
  batch.set(scheduledRef, {
    version: 2,
    active: true,
    userId: patientRef.id,
    organizationId: organization.id,
    period: "ONCE", // should be overriden by scheduling object for recurring invites
    ...scheduling,
    inviteMethods,
    t_lastSent: 0,
    t_created: now,
    t_statusUpdated: now,
    invites: [],
    template: screening,
    options: {
      akOneDay: !!options.akOneDay,
      akShortKey: !!options.akShortKey,
      useAdditionalMessage: !!options.useAdditionalMessage,
      credentialType: options.credentialType ?? "name+DOB"
    }
  });

  return [];
}

const SHORT_ACCESS_KEY_LENGTH = 7;

function createScreeningObjects (screenings, batch, dispatch, patientRef, user, apptRef, appointment, organization, options) {
  const inviteURLs = [];
  const now = Date.now();
  screenings.forEach(screening => {
    const accessKeyRef = options?.akShortKey ? db.collection('accessKeys').doc(nanoid(SHORT_ACCESS_KEY_LENGTH)) : db.collection('accessKeys').doc();
    // const requestedScreeningRef = patientRef.collection('requestedScreenings').doc();
    const screeningRef = db.collection('screenings').doc();

    screening.lang = allows(screening.type, user.preferredLanguage, "enrollment")
      ? user.preferredLanguage : FallbackLang;
    screening.messages = [];
    screening.accessKey = accessKeyRef.id;
    screening.managerId = user.providerId;
    screening.organizationId = organization.id;
    screening.userId = patientRef.id;
    screening.userDOB = user.dob;
    screening.userLastName = user.lastName;
    screening.status = ScreeningStatus.InviteSent;
    screening.t_lastSent = now;
    screening.t_created = now;
    screening.t_statusUpdated = now;

    batch.set(accessKeyRef, {
      inviteId: screeningRef.id,
      organizationId: organization.id,
      userId: patientRef.id,
      screener: screening.type,
      language: screening.lang,
      t_expires: getAccessKeyExpiration(options?.akOneDay ? [1, "day"] : [1, "week"]),
      previouslyOpened: false,
      credentialType: options.credentialType
    });
    const url = buildInviteUrl(accessKeyRef.id, "direct_copy");
    inviteURLs.push(url);

    if (appointment) {
      if (appointment.timestamp) {
        screening.t_appointment = appointment.timestamp;
      } else {
        screening.d_appointment = appointment.date;
      }
      screening.appointmentId = apptRef.id;
    }

    const inviteMethods = options.inviteMethods ?? {
      email: user.email,
      sms: user.phone && user.phoneType !== "whatsapp",
      whatsapp: user.phone && user.phoneType === "whatsapp"
    };
    if (!inviteMethods.email && !inviteMethods.sms && !inviteMethods.whatsapp) {
      throw new Error("No known invite methods chosen! " + inviteMethods);
    }

    const baseMessageObject = {
      DIR: "out",
      status: "requested",
      knownConversation: true,
      type: "invite_template",
      t_created: now,
      t_statusUpdated: now,
      userId: patientRef.id,
      managerId: user.providerId,
      organizationId: organization.id,
      screeningId: screeningRef.id,
      accessKeyId: accessKeyRef.id,
      language: screening.lang
    };

    if (options.additionalMessages) {
      baseMessageObject.additionalMessages = options.additionalMessages;
    }

    if (inviteMethods.email) {
      const ref = db.collection("externalMessages").doc();
      screening.messages.push(ref.id);
      batch.set(ref, {
        ...baseMessageObject,
        method: "email",
        toAddress: user.email,
        templateName: organization?.templates?.email || ""
      });
    }
    if (inviteMethods.whatsapp) {
      const ref = db.collection("externalMessages").doc();
      screening.messages.push(ref.id);
      batch.set(ref, {
        ...baseMessageObject,
        method: "whatsapp",
        toAddress: user.phone,
        templateName: organization?.templates?.whatsapp || organization?.templates?.phone_general || "",
        additionalMessage: !!options.useAdditionalMessage
      });
    }
    if (inviteMethods.sms) {
      const ref = db.collection("externalMessages").doc();
      screening.messages.push(ref.id);
      batch.set(ref, {
        ...baseMessageObject,
        method: "sms",
        toAddress: user.phone,
        templateName: organization?.templates?.sms || organization?.templates?.phone_general || "",
        additionalMessage: !!options.useAdditionalMessage
      });
    }
    batch.set(screeningRef, screening);
  });
  return inviteURLs;
}

export const existingPatientNewInvite = createAsyncThunk(
  "provider/existing-patient-invite",
  async ({user, appointment, screenings, organization, scheduling, options}, thunkAPI) => {
    if (screenings.length === 0) {
      throw new Error("User had no screeners selected!");
    }
    const patientRef = usersRef.doc(user.id);
    const apptRef = db.collection('appointments').doc();
    const batch = db.batch();
    const now = Date.now();
    const userUpdate = {
      status: UserClinicStatus.InviteSent
    };
    if (appointment)
    {
      if (appointment.timestamp) {
        userUpdate.t_nextAppointment = appointment.timestamp;
      } else {
        userUpdate.d_nextAppointment = appointment.date;
      }
      userUpdate.appointments = [apptRef.id];
      appointment.userId = patientRef.id;
      batch.set(apptRef, appointment);
    }
    if (user.email) userUpdate.email = user.email;
    if (user.phone) userUpdate.phone = user.phone;

    // add any new screeners to the used list
    const newScreeners = screenings.map(({type}) => type).filter(t => !user.screeningsUsed?.includes(t))
    if (newScreeners.length > 0) {
      userUpdate.screeningsUsed = (user.screeningsUsed || []).concat(newScreeners);
    }

    batch.update(patientRef, userUpdate);
    const inviteURLs = scheduling ?
      createScheduledScreenings(screenings[0], batch, thunkAPI.dispatch, patientRef, user, apptRef, appointment, organization, scheduling, options)
      : createScreeningObjects(screenings, batch, thunkAPI.dispatch, patientRef, {...user, ...userUpdate}, apptRef, appointment, organization, options);

    const batchResult = await batch.commit();
    return inviteURLs;
  }
);

export const newPatientInvite = createAsyncThunk(
  "provider/new-patient-invite",
  async ({user, appointment, screenings, organization, scheduling, options}, thunkAPI) => {
  if (screenings.length === 0) {
    throw new Error("User had no screeners selected!");
  }

  const apptRef = db.collection('appointments').doc();
  const patientRef = usersRef.doc();
  const batch = db.batch();
  const now = Date.now();

  if (appointment)
  {
    if (appointment.timestamp) {
      user.t_nextAppointment = appointment.timestamp;
    } else {
      user.d_nextAppointment = appointment.date;
    }
    user.appointments = [apptRef.id];
    appointment.userId = patientRef.id;
    batch.set(apptRef, appointment);
  } else {
    user.appointments = [];
  }
  user.screeningsUsed = screenings.map(({type}) => type);
  batch.set(patientRef, {version: 2, t_created: now, organizationId: organization.id, ...user});
  const inviteURLs = scheduling ?
    createScheduledScreenings(screenings[0], batch, thunkAPI.dispatch, patientRef, user, apptRef, appointment, organization, scheduling, options)
    : createScreeningObjects(screenings, batch, thunkAPI.dispatch, patientRef, user, apptRef, appointment, organization, options);
  const batchResult = await batch.commit();
  return inviteURLs;
});

export const savePreferedProviderId = (pid) => dispatch => {
  dispatch(savePreferedProviderIdSuccess(pid));
}

export const providerReportsRequested = (providerId) => ({
  type: actionTypes.PROVIDER_REPORTS_REQUESTED,
  payload: {providerId}
});
export const providerReportsReceived = (reports) => ({
  type: actionTypes.PROVIDER_REPORTS_SUCCESS,
  payload: {reports}
});
export const providerReportAddition = (report) => ({
  type: actionTypes.PROVIDER_REPORT_ADDITION,
  payload: {report}
});
export const providerReportsFailed = (error) => ({
  type: actionTypes.PROVIDER_REPORTS_FAIL,
  error
});
export const getSpecificUser = createAsyncThunk(
  "provider-user",
  async (userId, thunkAPI) => {
    const user = await usersRef.doc(userId).get();
    if (user.exists) {
      return {...user.data(), id: user.id};
    }
    throw new Error(`Could not find user ${userId}`);
  }
)
export const getUserByDetails = createAsyncThunk(
  "user-by-org-and-details",
  async ({providerId, lastName, dob}, thunkAPI) => {
    const users = await usersRef.where("providerId", "==", providerId).where("lastName", "==", lastName).where("dob", "==", dob).get();
    if (!users.empty) {
      return users.docs.map(u => ({...u.data(), id: u.id}));
    }
    return false;
  }
)

export const getUserInvites = userId => dispatch => {
  dispatch({type: "provider/USER-INVITES-REQUESTED", payload: {userId}});
  return db.collection('screenings').where('userId', '==', userId).get().then(snapshot => {
    const payload = {userId, invites: snapshotDataWithIds(snapshot)};
    dispatch({type: "provider/USER-INVITES-SUCCEEDED", payload});
    return payload;
  }, reason => dispatch({type: "provider/USER-INVITES-FAILED", error: reason}));
}
export const getUserReports = userId => dispatch => {
  dispatch({type: "provider/USER-REPORTS-REQUESTED", payload: {userId}});
  return db.collection('reports').where('userId', '==', userId).get().then(snapshot => {
    const payload = {userId, reports: snapshotDataWithIds(snapshot)};
    dispatch({type: "provider/USER-REPORTS-SUCCEEDED", payload});
    return payload;
  }, reason => dispatch({type: "provider/USER-REPORTS-FAILED", error: reason}));
}
export const getUserScheduledInvites = createAsyncThunk("scheduledInvites", async (userId, thunkAPI) => {
  const scheduleSnapshot = await db.collection('scheduledInvites').where('userId', '==', userId).get();
  return scheduleSnapshot.docs.map(d => ({...d.data(), id: d.id}));
});
export const getUserMessages = createAsyncThunk("messages", async (userId, thunkAPI) => {
  const msgSnap = await db.collection('externalMessages').where('userId', '==', userId).get();
  return msgSnap.docs.map(d => ({...d.data(), id: d.id}));
});
export const getMessagesByManager = createAsyncThunk("messages-by-provider", async (managerId, thunkAPI) => {
  const msgSnap = await db.collection('externalMessages').where('managerId', '==', managerId).get();
  return msgSnap.docs.map(d => ({...d.data(), id: d.id}));
});
export const getMessagesByOrganization = createAsyncThunk("messages-by-organization", async (organizationId, thunkAPI) => {
  const msgSnap = await db.collection('externalMessages').where('organizationid', '==', organizationId).get();
  return msgSnap.docs.map(d => ({...d.data(), id: d.id}));
});

export const getReportsByRequester = (providerId) => dispatch => {
  dispatch(providerReportsRequested(`requesterId == ${providerId}`));
  db.collection('reports').where('requesterId', '==', providerId).get().then(snapshot =>
    dispatch(providerReportsReceived(snapshot.docs.map(e => e.data())))
  ).catch(error => dispatch(providerReportsFailed(error)));
};
export const getReportsByOrganization = createAsyncThunk("reports/by-organization",
 async ({organizationId, withScores = false, start, end}) => {
  const snapshot = (start === undefined) ?
    await db.collection('reports').where('organizationId', '==', organizationId).get() :
    await db.collection('reports').where('organizationId', '==', organizationId).where('t_submitted', ">=", start).where('t_submitted', '<', end).get();
    if (withScores) {
      const userScorePairs = snapshot.docs.flatMap(r => {
        const rData = r.data();
        return Array.isArray(rData.associatedScores) ? rData.associatedScores.map(as => [rData.userId, as]) : [];
        // if (Array.isArray(r.associatedScores)) {
        //   return chunkedBy(r.associatedScores, 10).map(as => ([r.userId, as]))
        // } else {
        //   return [];
        // }
      });
      const scores = await (await Promise.all(userScorePairs.map(([userId, scoreId]) => db.doc(`users/${userId}/scores/${scoreId}`).get()))).map(doc => ({...doc.data(), id: doc.id}));
      return snapshot.docs.map(doc => {
        const rData = doc.data();
        return ({
          ...rData,
          id: doc.id,
          REL_scores: Array.isArray(rData.associatedScores) ? scores.filter(s => rData.associatedScores.includes(s.id)) : []
        })
      });
    }
    return snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
});
//   dispatch(providerReportsRequested(`requesterId == ${providerId}`));
//   db.collection('reports').where('requesterId', '==', providerId).get().then(snapshot =>
//     dispatch(providerReportsReceived(snapshot.docs.map(e => e.data())))
//   ).catch(error => dispatch(providerReportsFailed(error)));
// };
export const getReportsByRequesterUpdates = (providerId) => dispatch => {
  return db.collection('reports')
    .where('requesterId', '==', providerId)
    .where('t_submitted', '>', Date.now())
    .onSnapshot(snapshot =>
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
          console.warn(change);
          dispatch(providerReportAddition(change.doc.data()));
      }
    })
  );
};

export const getUsersForProvider = createAsyncThunk(
  "users/by-provider",
  async (providerId, thunkAPI) => {
    const users = await db.collection('users').where('providerId', '==', providerId).get();
    return users.docs.map(d => ({...d.data(), id: d.id}));
  }
)
export const getUsersForOrganization = createAsyncThunk(
  "users/by-organization",
  async (organizationId, thunkAPI) => {
    const users = await db.collection('users').where('organizationId', '==', organizationId).where("role", "==", "Patient").get();
    return users.docs.map(d => ({...d.data(), id: d.id}));
  }
)

export const updateUserNote = (userId, authorId, notes) => dispatch => {
  const promise = db.collection('users').doc(userId).update({notes});
  dispatch({type: "provider/USER-NOTE-UPDATE", payload: {userId, authorId, notes}});
  return promise;
}
export const updateUserDetails = createAsyncThunk("update-user-details", ({id, update}) => {
  return db.collection('users').doc(id).update(update);
})
export const updateScheduledInvite = (scheduledId, updateContent, userId) => dispatch => {
  const promise = db.collection('scheduledInvites').doc(scheduledId).update(updateContent).then(
    success => dispatch({type: "provider/SCHEDULED-INVITE-UPDATE", payload: {updateContent, id: scheduledId, userId}}),
    error => {
      console.error(error);
    }
  );
  return promise;
}
export const addExceptionToRecurringInvite = (recurringInvite, exception, userId) => {
  return updateScheduledInvite(recurringInvite.id, {exceptions: (recurringInvite.exceptions || []).concat(exception)}, userId);
}
export const splinterScheduledOccurence = (scheduledId, recurrenceObject, {date, timezone, preferredHour}, userId) => dispatch => {
  const batch = db.batch();
  const newOneTimeRef = db.collection('scheduledInvites').doc();
  const nextSend = hourDayToTimeslot(date, timezone, preferredHour);
  const targetDate = nextSend.format("YYYY-MM-DD");
  const removedDate = moment(recurrenceObject.nextSend).format("YYYY-MM-DD");
  const now = Date.now();
  const newOneTimeObject = {
    active: true,
    derivedFromRecurrence: scheduledId,
    inviteMethods: {...recurrenceObject.inviteMethods},
    id: newOneTimeRef.id,
    invites: [],
    nextSend: nextSend.valueOf(),
    options: {...recurrenceObject.options},
    organizationId: recurrenceObject.organizationId,
    period: "ONCE",
    preferredDay: PLURAL_DAY_NAMES[nextSend.day()],
    preferredHour,
    t_created: now,
    t_lastSent: 0,
    t_statusUpdated: now,
    targetDate,
    template: {...recurrenceObject.template},
    userId: recurrenceObject.userId,
    version: 2
  };
  const exceptions = Array.isArray(recurrenceObject.exceptions) ? recurrenceObject.exceptions.concat(removedDate) : [removedDate];
  batch.update(db.collection('scheduledInvites').doc(scheduledId), {exceptions});
  batch.set(newOneTimeRef, newOneTimeObject);
  return batch.commit().then(r => {
    dispatch({type: "provider/SCHEDULE-NEW-INVITE", payload: {...newOneTimeObject, id: newOneTimeRef.id}});
    dispatch({type: "provider/SCHEDULED-INVITE-UPDATE", payload: {userId, id: scheduledId, updateContent: {exceptions}}});
  });
};

export const getAccessKeyExpiration = (amount) => moment().add(...(amount || [1, "week"])).valueOf();

export const createNewAccessKey = (userId, invite) => dispatch => {
  dispatch({type: "access-key/REQUEST-NEW", payload: {userId, inviteId: invite.id}});
  const accessKeyRef = db.collection("accessKeys").doc();
  const accessKeyData = {
    userId,
    inviteId: invite.id,
    lang: invite.lang,
    screener: invite.type,
    previouslyOpened: false,
    t_expires: getAccessKeyExpiration()
  };
  const inviteRef = db.collection("users").doc(userId).collection("requestedScreenings").doc(invite.id);
  const batch = db.batch();
  batch.set(accessKeyRef, accessKeyData);
  batch.update(inviteRef, {accessKey: accessKeyRef.id});
  return batch.commit().then(() => {
    const payload = {...accessKeyData, id: accessKeyRef.id};
    dispatch({type: "access-key/CREATED", payload});
    return payload;
  });
}


export const getAccessKey = createAsyncThunk("access-key/GET",
  async ({id}) => {
    const snapshot = await db.collection('accessKeys').doc(id).get();
    if (!snapshot.exists) throw new Error(`Access key ${id} not found!`);
    return {...snapshot.data(), id};
});

export const resetAccessKeyExpiration = createAsyncThunk("access-key/EXTEND",
  async ({id}) => {
    const t_expires = getAccessKeyExpiration();
    const result = await db.collection('accessKeys').doc(id).update({t_expires});
    return t_expires;
});

export const sendAdditionalInviteMessage = (user, screening, contactType, contactTarget, createAccessKeyIfNeeded, organization) => dispatch => {
  if (!screening.accessKey) {
    if (createAccessKeyIfNeeded) {
      return dispatch(createNewAccessKey(user.id, screening)).then(
        ak => dispatch(sendAdditionalInviteMessage(user, {...screening, accessKey: ak.id}, contactType, contactTarget, false)));
    }
    dispatch({type: "invite-message/NEW-FAILED", error: "Cannot resend invite, user has no valid access key!"});
    return Rejected("Cannot resend invite, user has no valid access key!");
  }

  const now = Date.now();
  const baseMessageObject = {
    DIR: "out",
    status: "requested",
    knownConversation: true,
    type: "invite_template_RESEND",
    t_created: now,
    t_statusUpdated: now,
    userId: user.id,
    managerId: screening.managerId,
    organizationId: organization.id,
    screeningId: screening.id,
    accessKeyId: screening.accessKey,
    language: screening.lang,
    method: contactType,
    toAddress: contactTarget,
    templateName: organization?.templates?.[contactType] || (contactType !== "email" ? organization?.templates?.phone_general : "") || ""
  };

  const batch = db.batch();
  batch.set(db.collection("externalMessages").doc(), baseMessageObject);
  if ((contactType === "whatsapp" || contactType === "sms") && contactTarget !== user.phone) {
    batch.update(db.collection("users").doc(user.id), {phone: contactTarget});
  }
  if (contactType === "email" && contactTarget !== user.email) {
    batch.update(db.collection("users").doc(user.id), {phone: contactTarget});
  }
  batch.update(db.collection("screenings").doc(screening.id), {t_lastSent: now});

  const url = buildInviteUrl(screening.accessKey, contactType);
  return batch.commit().then(success => {
    return {externalMessage: baseMessageObject, userId: user.id, inviteId: screening.id, url};
  })

  // dispatch({type: "invite-message/NEW-STARTED", payload: {...newRequest, url}});
      // const combinedResults = {updatedMessages, ...result, userId: user.id, inviteId: screening.id};
}

export const setVisibility = (userId, visibility) => dispatch => {
  return usersRef.doc(userId).update({visibility}).then(() => {
    dispatch({type: "provider/VISIBILITY-UPDATED", payload: {userId, visibility}});
  });
}

export const updateEnrollmentUser = createAction("provider/enrollment-user-update");
export const resetEnrollmentUser = createAction("provider/enrollment-user-reset");
export const getClinicianColleagues = createAsyncThunk("colleagues", async (orgId, thunkAPI) => {
  const colleageSnapshot = await db.collection('users').where("organizationId", "==", orgId).where("role", "!=", "Patient").get();
  return colleageSnapshot.docs.map(d => ({...d.data(), id: d.id}));
});
export const switchProviderView = createAction("provider/switch-provider-view");
export const changeUserOwner = createAsyncThunk(
  "patient-owner-change",
  async ({userId, providerId}) => {
    const success = await usersRef.doc(userId).update({providerId});
    return success;
  }
)

export const updateOrganizationSettings = createAsyncThunk(
  "update-organization-settings",
  async ({organizationId, updates}) => {
    const success = await db.collection("organizations").doc(organizationId).update({settings: updates});
    return success;
  }
)
export const getScheduledInvitesByManager = createAsyncThunk("scheduled-invites/by-manager",
  async (managerId, thunkAPI) => {
    const snapshot = await db.collection('scheduledInvites').where('template.managerId', '==', managerId).get();
    const result = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
    return result;
  }
);
export const getScheduledInvitesByOrganization = createAsyncThunk("scheduled-invites/by-organization",
  async (organizationId, thunkAPI) => {
    const snapshot = await db.collection('scheduledInvites').where('organizationId', '==', organizationId).get();
    const result = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
    return result;
  }
);

export const getUserScores = createAsyncThunk("scores/by-user",
  async (userId, thunkAPI) => {
    const snapshot = await db.collection("users").doc(userId).collection("scores").get();
    return snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
  }
);
export const getUserAlerts = createAsyncThunk("alerts/by-user",
  async (userId, thunkAPI) => {
    const snapshot = await db.collection("userAlerts").where("userId", "==", userId).get();
    return snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
  }
);
export const updateUserAlert = createAsyncThunk("alert/update",
  async ({alertId, changes}, thunkAPI) => {
    return await db.collection("userAlerts").doc(alertId).update(changes);
  }
);
export const getUserAlertsByOrganization = createAsyncThunk("alerts/by-organization",
  async ({organizationId, timeWindow}, thunkAPI) => {
    const alertSnapshot = await db.collection("userAlerts")
      .where("organizationId", "==", organizationId)
      .where("t_generated", ">=", timeWindow.start)
      .where("t_generated", "<", timeWindow.end)
      .get();
    const alertsData = alertSnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
    // if (withScores) {
    //   const idPairs = alertsData.filter(d => d.scoreId).map(d => ([d.userId, d.scoreId]));
    //   const reqs = idPairs.map()
    // }
    return alertsData;
  }
);