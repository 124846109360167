import { Slider } from '@material-ui/core';
import moment from "moment";
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { emptyAnswer } from '../../database/model/answer';
import { getCurrentQuestionnaire } from '../../store/slices/questionnaire';
import classes from "../../styles/History.module.scss";
import QuestionCard from "../QuestionCard";
import Button from "../UI/Button/Button";
import CustomCalendar from "../UI/Calendar/Calendar";
import { Col, Row } from "../UI/Grid/Grid";
import { ImageWithFallback } from '../UI/ImageWithFallback';

// export const MULTI_UNIT_SIZE = {
//   "feet_inches": 2,
//   // "pounds_ounces": 2
// };
export const MULTI_UNIT_LABELS = {
  "feet_inches": ["Feet (ft)", "Inches (in)"],
  "pounds_ounces": ["Pounds (lb)", "Ounces (oz)"]
}
export const MULTI_UNIT_LIMITS = {
  "feet_inches": [{min: 0}, {min: 0, max: 11}],
  "pounds_ounces": [{min: 0}, {min: 0, max: 15}]
}
export function multiUnitTransformers (muType) {
  switch (muType) {
    case "feet_inches":
      return [
        ([feet, inches]) => `${feet ?? 0}ft ${inches ?? 0}in`,
        (str) => ((str ?? "").match(/^(\d+)ft (\d+)in$/) ?? []).slice(1,3)
      ];
    case "pounds_ounces":
      return [
        ([lbs, oz]) => `${lbs ?? 0}lb ${oz ?? 0}oz`,
        (str) => ((str ?? "").match(/^(\d+)lb (\d+)oz$/) ?? []).slice(1,3)
      ];
    default:
      console.error(`Unknown multi-unit type ${muType}! Using identity transformers`);
      return [x => x, x => x];
  }
}

const ResponseChoices = ({questionKey, answerKey, choices, toggleResponse, changeValue, listIndex, fluidCards, compacts, asSkip, fullwidth}) => {

  const question = useSelector(state =>
    getCurrentQuestionnaire(state)?.questions?.[questionKey] ?? {});
  const answer = useSelector(state => state.questionnaire.answers?.[answerKey || questionKey] ?? emptyAnswer());
  const debugMode = useSelector(s => s.session.debugMode);

  function getOpt (ri, isInput) {
    const container = answer.options?.[ri]?.[isInput ? "input" : "key"];
    if (Number.isSafeInteger(listIndex) && Array.isArray(container)) {
      return container[listIndex];
    }
    return container;
  }

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const InputLikeTags = ["INPUT", "TEXTAREA", "SELECT"];
  const NumberKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => `${n}`);
  /**
   * Add listeners for the number keys to select the associated index response
   */
  useEffect(() => {
    const listener = (keyEvent) => {
      // If the current focus is in a classic editable element, we don't fire any shortcuts
      // (alternatively we could prevent event bubbling on each input, but this
      // is a wider-reaching safety measure)
      if (InputLikeTags.indexOf(document.activeElement.tagName) > -1) {
        return;
      }
      const index = NumberKeys.indexOf(keyEvent.key);
      if (index > -1 && index in choices) {
        toggleResponse(index);
      }
      if (keyEvent.key.length === 1) {
        // assume if the key name is one character, it is a printed character
        const found = choices.findIndex(c => !c.inputOptions && c.value?.startsWith(keyEvent.key));
        if (found > -1) {
          toggleResponse(found);
        }
      }
    }
    // only add the event listener if there is more than one choice
    if (choices?.length > 1) {
      window.addEventListener("keyup", listener);
    }
    return () => window.removeEventListener("keyup", listener);
  })

  // ** Handles the inputType of "scale", "textbox", "number" and "calendar"
  if (Array.isArray(choices) && choices.some(c => !c._skipEvaluated)) {
    return choices.map( (resp, choiceIndex) => {
      // if this choice was deactivated by the last run of its skipWhen or 
      // displayWhen logic, we don't show anything
      if (resp._skipEvaluated) return null;
      const isSelected = (asSkip
        ? answer?.skipReason === choiceIndex
        : answer?.selected?.includes(choiceIndex) );
      const isSuggested = (asSkip ? false : answer?.suggested?.includes(choiceIndex));
      const previousResponse = choices[choiceIndex - 1];
      const nextResponse = choices[choiceIndex + 1];

      switch (resp.inputType) {

        case "scale":
          const hasPredefinedOptions = Array.isArray(resp.inputOptions) && resp.inputOptions.length > 1;
          const min = (hasPredefinedOptions ? resp.inputOptions[0].value : resp.minimum) || 0;
          const max = (hasPredefinedOptions ? resp.inputOptions[resp.inputOptions.length - 1].value : resp.maximum) || 10;
          const step = hasPredefinedOptions ? null : (resp.step || 1);
          const MAX_HASH_MARKS = 10;
          const defaultLength = Math.floor((max - min) / (step || 1)) + 1
          const options = hasPredefinedOptions ? resp.inputOptions :
            Array.from(Array(defaultLength).keys()).map(i => {
              const value = min + (i * step);
              if (defaultLength > MAX_HASH_MARKS) return ({value});
              const labelText = `${value}${resp.unitHint ? " " + resp.unitHint : ""}`;
              const label = resp.scaleImage ?
                <img url={resp.scaleImage.replace("<i>", i).replace("<on/off>", value >= i ? "on" : "off")} alt={labelText}/>
                : labelText;
              return ({value, label});
            });
          const value = getOpt(choiceIndex, true) || min;
          return (
            <Col className={`${fullwidth ? "" : "col-lg-8"} col-12`} key={choiceIndex}>
              <Row className="justify-content-center m-0 pb-1">
                <label htmlFor={`range-${question.key}-${choiceIndex}`}>
                  {resp.label? <p className="mb-2">{resp.label}</p> : ""}
                </label>
                <Slider
                  min={min}
                  max={max}
                  step={step}
                  value={value}
                  marks={options}
                  onChange={(e, newValue) => changeValue(choiceIndex, {input: newValue})}
                  title={resp.unitHint || ""}
                  style={{marginLeft: "15px", marginRight: "15px"}}
                />
              </Row>
            </Col>
          );

        case "textbox":
        {
          const handleInputChange = event => {
            // TODO: debounce the events
            if (event.target.value === "") {
              toggleResponse(choiceIndex);
            } else {
              changeValue(choiceIndex, {input: event.target.value});
            }
          }

          const value = getOpt(choiceIndex, true) || "";

          return (
            <Col className={`${fullwidth ? "" : "col-lg-8"} col-12`} key={choiceIndex}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={resp.placeholder?resp.placeholder: ""}
                  onChange={handleInputChange}
                  value={value}/>
                {resp.unitHint ? <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon2">{resp.unitHint}</span>
                    </div> : null}
              </div>
            </Col>
            );
        }

        case "freeform":
        {
          const handleInputChange = event => {
            if (event.target.value === "") {
              toggleResponse(choiceIndex);
            } else {
              changeValue(choiceIndex, {input: event.target.value});
            }
          }

          const value = answer.options?.[choiceIndex]?.input || "";

          return (
            <Col className={`${fullwidth ? "" : "col-lg-8"} col-12`} key={choiceIndex}>
              {resp.label? <p className="mb-2">{resp.label}</p> : ""}
              <div className="input-group">
                <textarea
                  className="form-control"
                  placeholder={resp.placeholder?resp.placeholder: ""}
                  onChange={handleInputChange}
                  value={value}
                  />
                {resp.unitHint ? <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon2">{resp.unitHint}</span>
                    </div> : null}
              </div>
            </Col>
            );
        }

        case "number":
        {
          if (Array.isArray(resp.inputOptions)) {
            // select-based number with input options dropdown!
            const handleInputChange = (input, actionMeta) => {
              if(input === null) {
                // Clear the input
                changeValue(choiceIndex, null);
              } else if (actionMeta.action === 'select-option' ||
                 actionMeta.action === 'create-option') {
                changeValue(choiceIndex, {key: input.index});
              }
            }

            let valueIndex = getOpt(choiceIndex, false);
            let value = Number.isInteger(valueIndex) ? resp.inputOptions[valueIndex] : null;

            const opts = resp.inputOptions ?
              resp.inputOptions.map((v, i) => Object.assign({index: i}, v)) : [];

            return (
              <Col key={choiceIndex} className="col-12">
                {resp.imgUrl ? <Row className="justify-content-center m-0 p-0">
                  <Col className="col-lg-6 col-md-8 text-center">
                    <ImageWithFallback src={resp.imgUrl} style={{width: "300px"}} alt="" />
                  </Col>
                </Row> : null}
                <Row className="justify-content-center m-0 p-0">
                  <Col className="col-8 col-md-6 text-center">
                    <Select
                        isSearchable={false}
                        onChange={handleInputChange}
                        options={opts}
                        value={value}
                      />
                  </Col>
                </Row>
              </Col>
            );
          } else {
            // non-select-based number
            const handleInputChange = (event) => {
              changeValue(choiceIndex, {input: event.target.value})
            }
            const value = getOpt(choiceIndex, true) || undefined;
            return (
              <Col key={choiceIndex} className="col-12">
                {resp.imgUrl ? <Row className="justify-content-center m-0 p-0">
                  <Col className="col-lg-6 col-md-8 text-center">
                    <ImageWithFallback src={resp.imgUrl} style={{width: "300px"}} alt="" />
                  </Col>
                </Row> : null}
                <Row className="justify-content-center m-2 p-0">
                  <Col className="col-8 col-md-6 text-center">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      name="numeric"
                      value={value}
                      onChange={handleInputChange}/>
                    {resp.unitHint ? <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon2">{resp.unitHint}</span>
                    </div> : null}
                  </div>
                  </Col>
                </Row>
              </Col>
            );
          }
        }

        case "calendar":
        {
          const handleInputChange = value => {
            changeValue(choiceIndex, {input: value});
          }

          const value = getOpt(choiceIndex, true) || null;

          return(
              <Col className="col-12 text-center" key={choiceIndex}>
                <Row className="justify-content-center m-0 p-0">
                <Col className="col-12 text-center my-auto">
                    <CustomCalendar
                      className="text-center"
                      changed={(value) => handleInputChange(value, choiceIndex)}
                      monthOnly={!!resp.inputSettings?.calendarMonthResolution}
                      value={value}
                      openTo={resp.inputSettings?.calendarInitialView}
                      suggestedDate={Array.isArray(resp.inputSettings?.calendarInitial) ? moment().add(...resp.inputSettings.calendarInitial).format("MM-DD-YYYY") : null}
                      disableFuture={resp.inputSettings?.calendarDisableFuture}
                      disablePast={resp.inputSettings?.calendarDisablePast}
                      fullwidth={fullwidth}
                    />
                  </Col>
                  {resp.imgUrl ? <Col className="col-12 col-md-6 text-center">
                    <ImageWithFallback src={resp.imgUrl} style={{width: "300px"}} alt="calendar" />
                  </Col> : null}
                </Row>
              </Col>
          );
        }

        case "button":
          {
            // Style the button color based on the response color
            const btnClass = resp.color && resp.color === 'red'? isSelected ? classes.RedButtonActive : classes.RedButton
                               :  resp.color === 'green'? isSelected ? classes.GreenButtonActive : classes.GreenButton
                               :  isSelected ? classes.BlueButtonActive : classes.BlueButton;

          return (
            <>
            {previousResponse?.inputType !== "button" ? <div className="w-100"/> : null}
            <Col className="col-6 col-lg-4 text-center p-0" key={choiceIndex}>
              <Button
                autoFocus={false}
                style={{width: "90%"}}
                className={`${btnClass} ${isSuggested ? "suggested" : ""} pl-1 pr-1 mt-3 text-white`}
                onClick={() => {toggleResponse(choiceIndex)}}>
                <img src={resp.imgUrl} height="45px" className={'pr-2'} alt="" />
                {resp.label}
                {debugMode ? <small title="DEBUG MODE KEY" className="text-muted"><br/>{resp.value}</small> : null}
              </Button>
            </Col>
            </>
          )
        }

        case "multi-unit":
          const [toAnswerValue, fromAnswerValue] = multiUnitTransformers(resp.multiUnitType);
          const currentAnswer = fromAnswerValue(getOpt(choiceIndex, true));
          const handleInputChange = (event, index) => {
            const nextInput = Number.parseInt(event.target.value, 10);
            if (Number.isNaN(nextInput)) {
              delete currentAnswer[index];
            } else {
              currentAnswer[index] = nextInput;
            }
            if (currentAnswer.every(x => x === undefined || x === 0)) {
                toggleResponse(choiceIndex);
            } else {
                changeValue(choiceIndex, {input: toAnswerValue(currentAnswer)});
            }
          }

          const labels = MULTI_UNIT_LABELS[resp.multiUnitType];
          const limits = MULTI_UNIT_LIMITS[resp.multiUnitType];
          console.warn({fullwidth});

          return (
            <Col className={`${fullwidth ? "" : "col-lg-8"} col-12`} key={choiceIndex}>
              {resp.label ? <p className="mb-2 font-weight-bold">{resp.label}</p> : ""}
              <form className="form" name={`mf-${choiceIndex}`}>
              <Row>
                {!labels ?
                  <span className="text-danger">Unknown unit type {resp.multiUnitType}, cannot collect data</span>
                  :
                  labels.map((label, i) => <Col className="" key={`mu-${label}`}>
                      <label className="" htmlFor={`unit-input-${label}`}>{label}</label>
                      <input
                        type="number"
                        className="form-control mb-2 mr-sm-2"
                        id={`unit-input-${label}`}
                        onChange={event => handleInputChange(event, i)}
                        value={currentAnswer?.[i] ?? 0}
                        min={limits[i]?.min ?? 0}
                        max={limits[i]?.max ?? null}
                        />
                  </Col>)
                }
              </Row>
              </form>
            </Col>
            );

        case "custom-button": {

          return (
            <>
            {previousResponse?.inputType !== "custom-button" ? <div className="w-100"/> : null}
            <Col key={choiceIndex} className={`col-12 col-sm-6 pt-3 pb-3 px-3`}>
              <div className={`card ${isSelected ? "selected" : (isSuggested ? "suggested" : "")}`} onClick={() => {toggleResponse(choiceIndex)}} >
              <Row className="align-items-center my-auto">
                {resp.imgUrl ? <Col className="col-auto" >
                  <ImageWithFallback src={resp.imgUrl} className="rounded-start" alt="..." style={{minWidth: "70px", maxWidth: "100px", minHeight: "70px", padding: "10px"}} />
                </Col> : null}
                <Col className="col">
                  <div className="card-body" style={{padding: "0.75rem"}}>
                    <div className={`card-text ${resp.imgUrl ? "" : "text-center"}`} style={{fontSize: "20px"}}>
                      {resp.label}
                      {debugMode ? <small title="DEBUG MODE KEY" className="text-muted"><br/>{resp.value}</small> : null}
                    </div>
                    <p className="card-text"><small className="text-muted">{resp.subtext}</small></p>
                  </div>
                </Col>
              </Row>
              </div>
            </Col>
            {nextResponse?.inputType !== "custom-button" ? <div className="w-100"/> : null}
            </>
          )

        }

        case "compact-card":
          return (
            <>
            {/* {previousResponse?.inputType !== "custom-button" ? <div className="w-100"/> : null} */}
            <Col key={choiceIndex} className={`col-10 ${fullwidth ? "" : "col-sm-6"} pt-2 px-1`}>
              <div className={`card ${isSelected ? "selected" : (isSuggested ? "suggested" : "")}`} onClick={() => {toggleResponse(choiceIndex)}} >
              <Row className="align-items-center my-auto">
                {resp.imgUrl ? <Col className="col-auto" >
                  <ImageWithFallback src={resp.imgUrl} className="rounded-start" alt="..." style={{minWidth: "70px", maxWidth: "100px", minHeight: "70px", margin: "-10px"}} />
                </Col> : null}
                <Col className="col" style={{flexGrow: "0"}}>
                  <div className="card-body" style={{padding: "0"}}>
                    <div className={`card-text ${resp.imgUrl ? "" : "text-center"}`} style={{fontSize: "20px"}}>
                      {resp.label}
                      {debugMode ? <small title="DEBUG MODE KEY" className="text-muted"><br/>{resp.value}</small> : null}
                    </div>
                  </div>
                </Col>
                <Col>
                  <span className="card-text mr-1"><small className="text-muted">{resp.subtext}</small></span>
                </Col>
              </Row>
              </div>
            </Col>
            {nextResponse?.inputType !== "custom-button" ? <div className="w-100"/> : null}
            </>
          )

        case "none":
        default:
          return (
            <Col
              key={choiceIndex} className={(fluidCards ? "" : "col-6 pb-3 col-md-4 col-lg-3")}>
              <QuestionCard
                id={choiceIndex}
                key={questionKey+choiceIndex}
                card={resp}
                name={resp.index}
                value={resp.label}
                isSelected={isSelected}
                isSuggested={isSuggested}
                options={resp.inputOptions}
                optionText={resp.optionText? resp.optionText : ""}
                toggleResponse={toggleResponse}
                changeValue={changeValue}
                listIndex={listIndex}
                audioFileName={resp.audioFileName}
              />
              </Col>
          );
      }
    });
  } else {
    console.error(`Received response choices without choices: ${questionKey} / ${answerKey}`);
    console.error(choices);
    return <h4>No choices available.</h4>;
  }
}

export default ResponseChoices;
