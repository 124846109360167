import queryString from 'query-string';
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
// import Jumbotron from "react-bootstrap/Jumbotron";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from 'react-i18next';
import { FaFileMedicalAlt } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { ScreeningType } from "../../constants/screenings";
import { savePreferedProviderId } from '../../store/actions';
import { loadReportsByUser } from "../../store/slices/reports";
import "../../styles/homepage.css";
import ReportTable from "../Report/ReportTable";
import Alert from '../UI/Alert/Alert';
import { Container } from '../UI/Grid/Grid';

const Home = ({
    isLoading,
    isReportUpdated,
    reports,
    userId,
    urlParams,
    error,
    isError,
    redirectPath,
    participantId,
    pid,
  }) => {

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (urlParams && urlParams.pid && !isLoading && !isError) {
      dispatch(savePreferedProviderId(urlParams.pid));
    } else {
      dispatch(savePreferedProviderId(pid));
    }
  }, [urlParams.pid, pid]);


  useEffect (() => {
    // Fetch reports if not fetched before
    if (userId && !isLoading && !isReportUpdated && !isError) {
      dispatch(loadReportsByUser(userId))
    }
  }, [isReportUpdated, isLoading, userId]);


  // Reports
  const Reports =
                  (error && isError) ?
                  <Alert error={error} />
                  :
                    isLoading?
                          <div style={{ textAlign: "center" }}>
                              <Spinner animation="border" role="status">
                                  <span className="sr-only">Loading...</span>
                              </Spinner>
                          </div>
                      :
                      (reports && Object.keys(reports).length !== 0)? (
                          <ListGroup variant="flush">
                              <ReportTable reports={reports}/>
                          </ListGroup>
                          ) : (
                              <div> There are no past screenings. Select "Start Survey" to get started. </div>

                          )

  const pastReports = userId?
            <>
            <h4>Past Reports: </h4>
           {Reports}
            </> : ""
  return (
    <div className="home-container">
      <Container className="mb-6">
          <div style={{ textAlign: "center", marginBottom: "5rem" }}>
             <h2 className="mt-5">Health Condition Questionnairess</h2>
              <p style={{paddingBottom: "2rem"}}>
                Please complete the relevant health condition questionnaire below within 24 hours of your visit. Select the illustrations that best describes your how you feel. Finally, email the results to your provider at the end.
              </p>

            <Row style={{margin: "auto", maxWidth: "500px"}}>
                <Button
                  type="button"
                  variant="info"
                  size="lg"
                  href={`${redirectPath}?type=${ScreeningType.COVID}`}
                  className="btn btn-block"
                >
                  <FaFileMedicalAlt style={{ marginRight: "8px" }} size={24}/>
                  COVID Screening
                </Button>

                <Button
                  type="button"
                  variant="info"
                  size="lg"
                  href={`${redirectPath}?type=${ScreeningType.OBGYN}`}
                  className="btn btn-block"
                >
                  <FaFileMedicalAlt style={{ marginRight: "8px" }} size={24}/>
                  OBGYN Intake
                </Button>

                <Button
                  type="button"
                  variant="info"
                  size="lg"
                  href={`${redirectPath}?type=${ScreeningType.BECKSDEP}`}
                  className="btn btn-block"
                >
                  <FaFileMedicalAlt style={{ marginRight: "8px" }} size={24}/>
                  Becks Depression Inventory
                </Button>
            </Row>
          </div>
          {pastReports}
      </Container>
  </div>)
};

function mapStateToProps(state, props) {

  const pathname = props.location && props.location.pathname? props.location.pathname : "";
  const userId = state.auth.user && state.auth.user.uid? state.auth.user.uid : "";
  const participantId = state.auth.user && state.auth.user.participantId? state.auth.user.participantId : "";
  const pid = state.auth.user && state.auth.user.providerIdDB? state.auth.user.providerIdDB : "";
  return {
    userId: state.auth.user.uid,
    participantId: participantId,
    pid,
    isLoading: state.report.isLoading || state.provider.isLoading,
    isReportUpdated: state.report.isUpdated,
    reports: state.report.reports,
    error: state.report.error,
    urlParams: props.location? queryString.parse(decodeURIComponent(props.location.search)) : "",
    redirectPath: (pathname === ROUTES.HOME_ANONYMOUS)? ROUTES.HISTORY_ANONYMOUS : ROUTES.HISTORY,
    isError: state.report.isError
  };
}

export default connect(mapStateToProps)(Home);
