import React, { useEffect } from "react"; // useState,
import Button from "react-bootstrap/Button";
import { Container } from "../UI/Grid/Grid";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import classes from "../../styles/History.module.scss";
import PlayAudio from "../Audio/PlayAudio";
import { useTextDirection } from "../../i18n";
import { useSelector } from "react-redux";
import { ImageWithFallback } from "../UI/ImageWithFallback";

const QuestionTitlePage = ({
  imageUrl,
  imageAlt,
  sectionTitle,
  sectionDescription,
  sectionKey,
  previous,
  next,
  pageNumber,
  audioFileName
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNumber]);
  const dir = useTextDirection();
  const debugMode = useSelector(s => s.session.debugMode);

  return (
    <Container className={`pb-5 ${classes.sectionCard}`} style={{ position: "relative"}}>
     <div className={classes.InnerContainer + ""} style={{textAlign: "center", maxWidth: "425px"}}>
        <ImageWithFallback src={imageUrl} style={{width: "250px", marginTop: "1rem"}} alt={imageAlt}/>
        <h1>
          {sectionTitle}
          {debugMode ? <small title="DEBUG MODE KEY" className="text-muted"><br/>{sectionKey}</small> : null}
        </h1>
        <p className="mb-2">{sectionDescription}</p>
        <PlayAudio filename={audioFileName} />
      </div>
      <div className={classes.Footer}>
      <div className={`text-center`}><small>&copy; All rights reserved</small></div>
        <div>
          {/* we might want to re-examine these as Bootstrap buttons... we're manually overriding the hover effect here for example */}
          { pageNumber > 0 ?
            <Button className="back-btn pl-3 pr-3 mr-3 ml-3" onClick={previous}>
              {dir === 'ltr' ? <FaArrowLeft /> : <FaArrowRight />} <span className="pr-2 font-weight-bold">Back</span>
            </Button>
          : null}
          <Button className="forward-btn pl-3 pr-3 mr-3 ml-3 text-white" onClick={next} >
            <span className="pr-2 font-weight-bold">Next</span> {dir === 'ltr' ? <FaArrowRight /> : <FaArrowLeft />}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default QuestionTitlePage;
