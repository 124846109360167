import React, {useState, useRef, useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import IdleTimer from 'react-idle-timer';
import Spinner from '../UI/Spinner/Spinner';
import * as ROUTES from '../../constants/routes';
// import { ROLES } from '../../constants/roles';
import { logout, setPreferedProvider } from "../../store/actions";

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isAnonymous,
  pid,
  isLoading,
  isError,
  onSetProvider,
  role,
  onLogout,
  props,
  ...rest
}) => {

  const [idleTimer, setIdleTimer] = useState(null);
  const idleTimerRef = useRef(idleTimer);
  const [isTimeout, setIsTimeout] = useState(false);

const onIdle = (e) => {
  // Set inactive to true
  setIsTimeout(true);
  onLogout(true);
}

  useEffect(() => {

    if (pid && !isLoading && !isError) {
        onSetProvider(pid);
    }
  }, [pid])

return (
  <>
    <IdleTimer
        ref={ref => { setIdleTimer(ref) }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 60 * 60 * 2} />

    <Route
      {...rest}
      render={props =>
        isLoading? (
          <Spinner />
        ) : (isAuthenticated && isAnonymous)? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.WAITLIST,
              from: props.location,
              isInactive: isTimeout
            }}
          />
        )
      }
    />
  </>
)
};

const mapDispatchToProps = dispatch => {

return {
    onLogout: () => dispatch( logout() ),
    onSetProvider: (pid) => dispatch(setPreferedProvider(pid))
};
};

function mapStateToProps(state, ownProps) {
return {
  isAuthenticated: state.auth.isAuthenticated,
  isAnonymous: state.auth.user && state.auth.user.isAnonymous? true : false,
  role: state.auth.user? state.auth.user.role : "",
  isLoading: state.auth.isLoading || state.provider.isLoading,
  // pid: state.auth.user.providerIdDB,
  pid: state.auth.user.providerIdDB,
  props: ownProps
};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
