import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window.__BUILD = {
  time: process.env.REACT_APP_BUILD_TIME,
  hash: process.env.REACT_APP_GIT_SHA,
  dirty: process.env.REACT_APP_GIT_DIRTY,
  branch: process.env.REACT_APP_GIT_BRANCH
};

if (process.env.NODE_ENV === 'development') {
  window.__ENV = process.env;
  window.__BUILD.NOTICE = `The values here reflect the time npm start began and do not update automatically because they are based on build *process* values.`;
}
// helpful for debugging date issues to have a copy on the window
window.moment = moment;
