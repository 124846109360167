import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isUpdated: false,
  isError: false,
  error: {},
  reports: {},
  publicReport: {},
  reportId: null,
  emailOverride: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case actionTypes.GET_REPORT_START:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     isError: false,
    //     error: {},
    //   };
    // case actionTypes.GET_REPORT_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     reports: {
    //       ...state.reports,
    //       [action.id]: {
    //         ...action.data,
    //       },
    //     },
    //   };
    // case actionTypes.GET_REPORT_FAIL:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     isError: true,
    //     error: action.error,
    //   };
      // case actionTypes.GET_PUBLIC_USER_REPORT_START:
      //   return {
      //     ...state,
      //     isLoading: true,
      //     isError: false,
      //     error: {},
      //   };
      // case actionTypes.GET_PUBLIC_USER_REPORT_SUCCESS:
      //   return {
      //     ...state,
      //     isLoading: false,
      //     reportId: action.id,
      //     publicReport: action.data
      //   };
      // case actionTypes.GET_PUBLIC_USER_REPORT_FAIL:
      //   return {
      //     ...state,
      //     isLoading: false,
      //     isError: true,
      //     error: action.error,
      //   };
    case actionTypes.GET_REPORTS_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: {},
      };
    case actionTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        reports: action.data,
      };
    case actionTypes.GET_REPORTS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case actionTypes.CREATE_REPORT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: {},
      };
    case actionTypes.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportId: action.data.id,
        reports: {
          ...state.reports,
          [action.data.id]: {
            ...state.reports[action.id],
            ...action.data,
          },
        },
      };
    case actionTypes.CREATE_REPORT_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case actionTypes.CREATE_PUBLIC_USER_REPORT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: {},
      };
    case actionTypes.CREATE_PUBLIC_USER_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportId: action.id,
        publicReport: action.data
      };
    case actionTypes.CREATE_PUBLIC_USER_REPORT_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case "report/SET_EMAIL_OVERRIDE":
      return {
        ...state,
        emailOverride: action.payload
      }
    case "report/UPDATE-FLAG-SUCCESS":
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.reportId]: {
            ...state.reports[action.payload.reportId],
            statusFlags: action.payload.statusFlags
          }
        }
      }
    default:
      return state;
  }
};

export default reducer;