import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { verifyAuth } from "./actions/";
import { LOGOUT_START } from './actions/actionTypes';
import appReducer from "./reducers";
import { sessionTimeoutMiddleware } from "./reducers/auth";
import { audioMiddleware } from "./slices/audio";
import { moduleBaseDefinitionRequested } from "./slices/education";


// const bindMiddleware = middleware => {
//   if (process.env.NODE_ENV !== 'production') {
//     const { composeWithDevTools } = require('redux-devtools-extension')
//     return composeWithDevTools(applyMiddleware(...middleware))
//   }
//   return applyMiddleware(...mi ddleware)
// }

// Clean up after logout
const rootReducer = ( state, action ) => {
  if ( action.type === LOGOUT_START ) {
    state = undefined;
  }

  return appReducer(state, action)
}

const defaultStartup = (store) => {
  store.dispatch(verifyAuth()).then((userData) => {
    console.warn("UD", userData);
  });
  store.dispatch(moduleBaseDefinitionRequested());
};

export const initStore = ( persistedState, startupFn = defaultStartup ) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: persistedState,

    // this default in prod is just [thunk] and in development it is
    // [thunk, immutableStateInvariant, serializableStateInvariant]
    // see https://redux-toolkit.js.org/api/getDefaultMiddleware for details
    // getDefaultMiddleware => getDefaultMiddleware().concat([/* additions */])

    middleware: getDefaultMiddleware => [thunkMiddleware, sessionTimeoutMiddleware, audioMiddleware]
  });

  // dispatch initial events
  startupFn(store);

  return store;
}
