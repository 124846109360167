import React from "react";
import Report from "./Report";

import "../../styles/homepage.css";
import "../../styles/report.css";

const CovidReportContainer = (props) => {

  return (
    <div className="home-container">
    <Report
      reportId={props.match.params.reportId}
      report={props.location &&
        props.location.state &&
        props.location.state.report?
        props.location.state.report :
        ""
      }
      from={props.location &&
        props.location.from?
        props.location.from :
        ""}
      />

  </div>
  );
};

export default CovidReportContainer;