import React, { useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import { FaCheckCircle, FaDotCircle, FaInfoCircle } from 'react-icons/fa';
// import { BsInfoCircle } from 'react-icons/bs'; 
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import "../../styles/button.scss";
import "../../styles/card.css";
import PlayAudio from "../Audio/PlayAudio";
import { Card, CardBody, CardFooter, CardImg, CardSlider, CardText } from '../UI/Card/Card';
import { ResourceDialog } from '../Education/ResourceDialog';
import { getCurrentQuestionnaire } from "../../store/slices/questionnaire"
import { ImageWithFallback } from '../UI/ImageWithFallback';
import { playGlobalAudio } from '../../store/slices/audio';

const QuestionCard = (props) => {
  const dispatch = useDispatch();
  const options = props.options ?
    props.options.map((v, i) => Object.assign({index: i}, v)) : null;

  const questionnaire = useSelector(getCurrentQuestionnaire);

  const [selectionInputActive, setInputActive] = useState(false);
  const [buttonText, setButtonText] = useState(props.optionText);
  const [selectedOption, setSelectedOption] = useState("");
  const infoLinkDialogState = useState(false);
  const debugMode = useSelector(s => s.session.debugMode);

  useEffect( () => {
    if (!props.isSelected) {
      setSelectedOption("");
    } else {
      if (props.options) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].value === props.card.selectedOption) {
            setSelectedOption(options[i]);
            break;
          }
        }
      }
    }
  }, [props, options]);

  const handleSelectChange = selectedOption => {
    setSelectedOption(selectedOption);
    props.changeValue(props.id, {key: selectedOption.index});
    setButtonText(selectedOption.label);
    setInputActive(false);
  }

  const handleInputChange = event => {
    setSelectedOption(event.target.value);
    props.changeValue(props.id, {input: event.target.value});
  }

  const handleSliderChange = (event, newValue) => {
    setSelectedOption(newValue);
    props.changeValue(props.id, {input: newValue});
  }

  const showSelector = selectedOption => {
    if (selectionInputActive) {
      setInputActive(false);
    } else {
      setInputActive(true);
    }
  }
  const input = props.card.input && (props.isSelected || props.isSuggested) ?
                 options ?
                 <div className="pt-2 text-center">
                 {selectionInputActive ?
                  <Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    options={options}
                    placeholder={props.optionText ? props.optionText : "Select ..."}
                    inputProps={{readOnly:true}}
                    isSearchable={ false }
                    menuIsOpen={true}
                    /> : <Button className="dropdown-btn" onClick={showSelector}>{buttonText}</Button> }
                    </div>
                    :
                    <div className="pb-3">
                    <input
                      className="form-control"
                      type="text"
                      value={selectedOption.value}
                      onChange={handleInputChange}
                      placeholder={props.optionText ? props.optionText : "Enter ..."}
                      />
                    </div>
                  : ""
  const slider = props.card.slider && props.isSelected?
    <div className="pb-3">
      <CardSlider className="pt-1 pb-1 pt-md-3 pb-md-3 text-center"
      onChange={handleSliderChange}
      mark={props.marks}
      defaultValue={props.defaultValue}
      valueText={props.valueText}
      step={props.step}>
      </CardSlider>
    </div> : ""

  /** @type {"bottom"|"right"} */
  const TRAY_POSITION = "bottom";
  
  const hasContextualIcons = ((props.card.infoLink && questionnaire.pageSettings?.highlightKeywords) || (typeof props.audioFileName !== undefined));

  return (
    <>
      <Card
        id={props.id}
        className={(props.isSelected ? "selected" : (props.isSuggested ? "suggested" : "")) + " question-card mx-2"}
        name={props.name}
        onClick={() => {props.toggleResponse(props.id); dispatch(playGlobalAudio("TAP"))}}
        >
          {props.isSelected ? <div className="included-check"><FaCheckCircle/></div> : null}
          {props.isSuggested ? <div className="included-check"><FaDotCircle/></div> : null}
          <div className="card-img-top">
            {TRAY_POSITION === "right" && hasContextualIcons ?
              <div className="card-icon-tray" style={{}}>
              {(props.card.infoLink && questionnaire.pageSettings?.highlightKeywords)
                ? <div style={{cursor: "pointer"}}
                    onClick={ev => {ev.stopPropagation(); infoLinkDialogState[1](true);}}>
                    {/* <BsInfoCircle/> */}
                    <img src="/images/question_icon_wh.png" className="icon" style={{height: "25px"}}/>
                  </div>
                : null}
              <PlayAudio style={{maxHeight: '2rem', verticalAlign: '50%' }} skipLoad={true} filename={props.audioFileName} />
              </div> : null
            }
            <ImageWithFallback
              src={props.card.imgUrl}
              alt={props.report_text}
              onClick={props.onClick}
              className={``}
            />
        </div>
        <CardBody className="pl-2 pr-2 pl-md-3 pr-md-3 pt-0 pb-0">
          <CardText className="text-center" style={{fontSize: '1rem'}}>
            {props.card.label}
            {debugMode ? <small title="DEBUG MODE KEY" className="text-muted"><br/>{props.card.value}</small> : null}
          </CardText>
          <div style={{color: 'grey', fontSize: "12px", textAlign: "center"}}>
            {props.card.subtext}
          </div>
          <div onClick={e => e.stopPropagation()} onFocus={e => e.stopPropagation()} style={{margin: "4px auto 6px", textAlign: 'center', marginTop: "3px", paddingBottom: "0px"}}>
            <div style={{maxHeight: '3rem', display: 'inline', verticalAlign: 'middle' }}>
              {input}
              {slider}
            </div>
          </div>
        </CardBody>
        {TRAY_POSITION === "bottom" && hasContextualIcons ? <CardFooter>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
          {props.isSuggested ? <em>Based on your past answers, we this this might be correct.</em> : null}
        {(props.card.infoLink && questionnaire.pageSettings?.highlightKeywords) ?
            <div style={{cursor: "pointer", paddingRight: "1em"}}
              onClick={ev => {ev.stopPropagation(); infoLinkDialogState[1](true);}}>
              {/* <BsInfoCircle/> */}
              <img src="/images/question_icon_wh.png" className="icon" style={{height: "25px"}}/>
            </div>
          : null}
        <PlayAudio style={{maxHeight: '2rem', verticalAlign: '50%' }} filename={props.audioFileName} skipLoad={true} />
        </div></CardFooter> : null}
      </Card>
      <ResourceDialog url={props.card.infoLink} show={infoLinkDialogState}/>
    </>);
}

export default QuestionCard;
