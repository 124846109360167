import queryString from "query-string";
import React, { useEffect, useMemo, useRef, useState } from "react"; // , useState
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Language, LanguageEndonym, LanguageList, SelectorAssets } from "../../constants/locales";
import * as ROUTES from "../../constants/routes";
import { allows, hasDynamicBodyTypes } from "../../constants/screenings";
import { checkPreCreatedPatient, updateAccessKeyDetail } from "../../store/actions";
import { playLocalAudio, resolveAudioURLs } from "../../store/slices/audio";
import { basicQuestionnaireActions } from "../../store/slices/questionnaire";
import { updateScreening } from "../../store/slices/screenings";
import SignInAnonymous from "../SignIn/SigninAnonymous";
import { Card, CardBody, CardImg, CardText, CardTitle } from "../UI/Card/Card";
import { Col, Container, Row } from "../UI/Grid/Grid";
import Spinner from "../UI/Spinner/Spinner";
import Questionnaire from "./Questionnaire";

const LanguagePicker = ({autoNarrate = false, languages, disableByWhitelist = false}) => {
  const dispatch = useDispatch();
  const [timerID, setTimerID] = useState(null);
  const timerRef = useRef();
  useEffect(() => timerRef.current = timerID, [timerID]);
  const DEFAULT_FOCUS_SECONDS = 4;
  const options = Array.isArray(languages) ? languages : LanguageList.filter(lang => lang !== Language.UNDETERMINED);
  
  const available = disableByWhitelist ? options.filter(l => allows(disableByWhitelist, l)) : options.slice();
  const focus = useRef(autoNarrate ? available[0] : null);

  function advanceFocus (schedule = true) {
    const index = ((available.indexOf(focus.current) + 1) % available.length) || 0;
    console.log(`advancing from ${focus.current} at index ${available.indexOf(focus.current)} to ${index} (${available[index]})`);
    focus.current = (available[index]);
    if (SelectorAssets[focus.current]?.narrationFile) {
      dispatch(playLocalAudio(SelectorAssets[focus.current]?.narrationFile));
    } else {
      // forceUpdate();
    }
    if (schedule) scheduleNext();
  }

  function scheduleNext () {
    let time = Number.isFinite(SelectorAssets[focus.current]?.narrationLength) ?
      SelectorAssets[focus.current].narrationLength :
      DEFAULT_FOCUS_SECONDS;
    const tid = setTimeout(() => advanceFocus(), time * 1000);
    console.log(`scheduling next focus for ${time}s (id: ${tid})`);
    setTimerID(tid);
  }

  useEffect(() => {
    if (autoNarrate) {
      if (SelectorAssets[focus.current]?.narrationFile) {
        dispatch(playLocalAudio(SelectorAssets[focus.current]?.narrationFile));
      }
      if (available.length <= 1) {
        console.warn("Cannot cycle, 1 or less available languages");
        return;
      }
      if (!Number.isInteger(timerID)) {
        scheduleNext();
      }
    }
    return () => {
      clearTimeout(timerRef.current);
      setTimerID(null);
    }
  }, []);

  const [selected, setSelected] = useState(null);

  function chooseLanguage (language) {
    if (available.indexOf(language) === -1) {
      console.error(`Language ${language} is not selectable for screener ${disableByWhitelist}`);
      return;
    }
    setSelected(language);
  }
  function submitLanguage () {
    if (!selected) {
      return;
    }
    
    dispatch(updateAccessKeyDetail({id: null, language: selected}));
  }

  return <Container className="my-4">
    <h2 className="text-center">Select a language below:</h2>
    <Row>
        {options.map(lang =>
          <Col key={lang} className="col-6 col-lg-3 pb-3">
            <Card
              className={`${focus.current === lang ? "border border-info" : ""} ${selected === lang ? "selected" : ""} ${available.indexOf(lang) === -1 ? "disabled text-muted" : ""}`}
              style={available.indexOf(lang) === -1 ? {opacity: "75%", cursor: "not-allowed"} : {cursor: "pointer"}}
              title={available.indexOf(lang) === -1 ? "This language is not available for your assigned screening!" : ""}
              onClick={() => chooseLanguage(lang)}>
              <CardBody>
                <h2 className={`card-title mb-0 pb-0 ${focus.current === lang ? "text-info" : ""}`}>{LanguageEndonym[lang]}</h2>
                <p className="card-subtitle" style={{fontSize: "2rem"}}>{SelectorAssets[lang] ? SelectorAssets[lang].flags : "⛳️"}</p>
                <CardText>
                  <p style={{}}>{SelectorAssets[lang]?.prompt || <em className="text-muted">«Language-specific prompt not configured»</em>}</p>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        )}
    </Row>
    <Row>
      <Col>
        <button className={`btn btn-success w-100`} disabled={!selected} onClick={submitLanguage}>Continue</button>
      </Col>
    </Row>
  </Container>;
};

const BodyTypeSelector = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  function chooseType (bodyType) {
    setSelected(bodyType);
  }
  function submitType () {
    if (!selected) {
      return;
    }
    dispatch(basicQuestionnaireActions.setBodyTypePreference(selected));
  }


  return <Container className="my-4">
      <h2 className="heading2 text-center">What sex were you assigned at birth?</h2>
      <p className="mb-4 text-center">This is the sex you find on your birth certificate.</p>
      <Row className="mb-4">
        <Col className="col-6 col-lg-4 offset-lg-2">
          <Card onClick={() => chooseType("male")} className={selected === "male" ? "selected" : ""}>
            <CardImg src="/images/man.png" style={{maxWidth: "432px", margin: "auto"}} />
            <CardBody>
              <CardTitle className="card-title text-center">Male</CardTitle>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-6 col-lg-4">
          <Card onClick={() => chooseType("female")} className={selected === "female" ? "selected" : ""}>
            <CardImg src="/images/woman.png" style={{maxWidth: "432px", margin: "auto"}} />
            <CardBody>
              <CardTitle className="card-title text-center">Female</CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <button className={`btn btn-success w-50`} disabled={!selected} onClick={submitType}>Continue</button>
        </Col>
      </Row>
    </Container>;
};

const OnSiteSignIn = ({onComplete}) => {
  const dispatch = useDispatch();

  function start () {
    if (typeof onComplete === "function") onComplete();
    dispatch({type: "auth-details/BEGIN-ON-SITE-FLOW"});
  }

  return <Container className="pr-0 pl-0 mt-5">
    <Row className="justify-content-center">
      <Col className="col-12 col-md-8 col-lg-6">
        <div className="p-2 pb-4">
          <p className="m-3 text-center text-info" style={{fontStyle: "italic"}}>
            We have a landing page here to clearly delineate when the patient
            has started the app flow, which assists with both our usage metrics
            and any debugging. This is also a convenient place where we could
            include reminders to the staff managing the tablet on the process
            and how to interact with study participants.
          </p>
          <p className="m-3 text-center">
            When the patient is ready to begin, click <strong>Start</strong> to
            initiate the app, which will take them to the language selection
            screen.
          </p>
          {/* <p className="m-3 text-center">
            When the patient is finished they should return the tablet to you.
            The completion screen will display a a 4-character study identifier
            that you should enter into a note on their record {" "}
            <em className="text-info">
              ...or whatever our final workflow is here for connecting LS and EMR records.
            </em>
          </p> */}
        </div>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col className="col-12 col-md-8 col-lg-6">
        <button className="btn btn-success w-100 text-center" onClick={() => start()}>
          Start
        </button>
      </Col>
    </Row>
  </Container>
};


// ! React Hook useEffect has a missing dependency: 'onCheckPreCreatedPatient'.
// ! Either include it or remove the dependency array.
// ! If 'onCheckPreCreatedPatient' changes too often, find the parent
// ! component that defines it and wrap that definition in useCallback
const HistoryAnonymous = ({
  HistoryAnonymous,
  isAuthenticated,
  isLoading,
  isAnonymous,
  isError,
  error,
  verifiedPatient,
  props,
  url,
  fileName
}) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const confirmed = useSelector(s => s.auth.confirmedDetails);
  const accessKey = useSelector(s => s.auth.accessKey);
  const bodyType = useSelector(s => s.questionnaire.bodyTypePreference);
  const urlParams = useMemo(() => props.location
    ? queryString.parse(decodeURIComponent(props.location.search))
    : "", [props.location]);
  const languageSelectorRequired = accessKey?.language === Language.UNDETERMINED;
  const configStepsRemaining = useMemo(() => {
    let out = [];
    if (languageSelectorRequired) out.push("language selector");
    if (hasDynamicBodyTypes(accessKey?.screener) && !bodyType) out.push("body type selector");
    return out;
  }, [languageSelectorRequired, accessKey, bodyType]);

  useEffect(() => {
    console.warn("Checking HistoryAnonymous UE")
    if (languageSelectorRequired) {
      dispatch(resolveAudioURLs({filenames: LanguageList.flatMap(l => SelectorAssets[l]?.narrationFile || [])}));
    }
  }, [languageSelectorRequired])

  useEffect(() => {
    if (urlParams.pid) {
      dispatch(checkPreCreatedPatient(urlParams.pid));
    } else {
      // this is required sooooo...?
    }
  }, [urlParams.pid, urlParams.emailOverride, url, fileName]);

  useEffect(() => {
    // these should probably be set higher in the app? [tdhs]
    if(accessKey && i18n.language !== accessKey.language) {
      i18n.changeLanguage(accessKey.language);
    }
  }, [accessKey, i18n.language])

  function updateMethod () {
    if (urlParams.method) {
      dispatch(updateScreening({id: accessKey.inviteId, changes: {lastMethodUsed: urlParams.method, lastReferrer: document.referrer || ""}}));
    }
  }

  return isLoading ? (
    <Spinner />
  ) : isAuthenticated && isAnonymous ? (
    <>
    {!confirmed ?
    <>
      {accessKey?.verificationNeeded === "NONE" ? <OnSiteSignIn onComplete={updateMethod} /> : null} 
      {(accessKey?.verificationNeeded === "name+dob") || (!accessKey?.verificationNeeded) ? <SignInAnonymous location={{}} onComplete={updateMethod}/> : null} 
    </>
    :
      (configStepsRemaining?.length > 0 ? 
        <>
        {(configStepsRemaining[0] === "language selector") ? <LanguagePicker autoNarrate={true} disableByWhitelist={accessKey.screener} /> : 
        (configStepsRemaining[0] === "body type selector" ?  <BodyTypeSelector /> : <h1 className="text-error">Unknown step: {configStepsRemaining[0]}</h1>)
        }
        {/* <em className="text-center text-muted mt-5">Steps remaining: {configStepsRemaining?.length} ({configStepsRemaining.join(",")})</em> */}
        </>
        : 
      <Questionnaire
        nextLocations={[ROUTES.SEND_EMAIL_ANONYMOUS]}
        verifiedPatient={verifiedPatient}
        type={accessKey.screener}
      />)
    }
    </>
  ) : urlParams.pid ? (
    isError ?
    <Container>
      <Col>
        <div className="alert alert-danger text-center" title={error}>
          Your access key is expired or invalid. If you believe you should have
          access, please contact your clinic or care provider.
        </div>
      </Col>
    </Container>
    :
    <>
      <p style={{ textAlign: "center" }}>
        The questionnaire is loading. If it does not complete in the next 10
        seconds, please try again later, or contact your provider directly.
      </p>
    </>
  ) : (
    <Redirect
      to={{
        pathname: ROUTES.WAITLIST,
        from: props.location,
      }}
    />
  );
};

function mapStateToPros(state, ownProps) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
    pid: state.auth.verifiedPatient
      ? state.auth.verifiedPatient.providerId
      : "",
    isAnonymous: state.auth.user && state.auth.user.isAnonymous ? true : false,
    verifiedPatient: state.auth.verifiedPatient,
    error: state.auth.error,
    isError: state.auth.isError,
    props: ownProps
  };
}

export default connect(mapStateToPros)(HistoryAnonymous);
