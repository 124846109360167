import Moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { FaArrowLeft, FaArrowRight, FaChevronCircleDown, FaChevronCircleLeft, FaExclamationTriangle, FaFileSignature, FaFileUpload, FaRegCopy, FaUndo } from 'react-icons/fa';
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { dualName, Language, Region } from "../../constants/locales";
import { ROLES } from "../../constants/roles";
import * as ROUTES from "../../constants/routes";
import {
  ScreenerNames
} from "../../constants/screenings";
import {
  getQuestionnaireByTypeAndKind,
  requestQuestionnaire
} from "../../store/slices/questionnaire";
import { getReportById, loadReport } from "../../store/slices/reports";
import historyClasses from "../../styles/History.module.scss";
import { ordinals, snakeCase, spliceOutOf } from "../../utils";
import { evaluator } from "../../utils/evaluator";
import Button from "../UI/Button/Button";
import FloatingAction from "../UI/FloatingAction/FloatingAction";
import { alertEvent } from "../UI/GlobalAlerts";

const PROVIDER_LOCALE = {
  language: Language.English,
  region: Region.UnitedStates,
};

const Report = ({
  redirectPath,
  email,
  phone,
  userId,
  userRole,
  reportId,
  fromPublic,
  // questionnaire,
  // questions,
  // sections,
  // reportSettings,
  // outcomeDefs,
  isLoading,
  review,
  handleBack,
  handleForward,
  // providerId,
  error,
  launchPrintDialog,
}) => {
  const report = useSelector((s) => getReportById(s, reportId));
  const type = report?.type;
  const accessKeyLocale = useSelector((s) => {
    const ak = s.auth.accessKey;
    if (ak) return { language: ak.language };
    return null;
  });
  const questionnaire = useSelector(
    (s) =>
      getQuestionnaireByTypeAndKind(
        s,
        type,
        accessKeyLocale || PROVIDER_LOCALE
      ) || { loadPlaceholder: true }
  );
  const questions = questionnaire.questions;
  const sections = questionnaire.sections;
  const outcomeDefs = questionnaire.reportOutcomes;
  const reportSettings = questionnaire.reportSettings || {};

  useEffect(() => {
    console.error(
      `report change ${!!report} ${Object.keys(report || {}).length}`
    );
    console.log(report);
  }, [report]);
  useEffect(() => {
    console.error(
      `questionnaire change ${!!questionnaire} placeholder: ${
        questionnaire.loadPlaceholder
      }, type ${type} == ${questionnaire.screener}`
    );
  }, [questionnaire]);
  useEffect(() => {
    console.error(`questions change ${!!questions} ${questions?.length}`);
  }, [questions]);
  useEffect(() => {
    console.error(`isLoading change ${isLoading}`);
  }, [isLoading]);

  const history = useHistory();

  const [processedReport, setProcessedReport] = useState();
  const previouslySubmitted = useSelector(
    (s) => s.auth.accessKey?.previouslySubmitted || false
  );

  const componentRef = useRef();
  const [processedOutcomes, setProcessedOutcomes] = useState([]);
  const dispatch = useDispatch();
  const questionCount = Object.values(questions || {}).length;

  const filteredRep = (item) => {
    let updatedResp = { ...item };
    if (
      questions &&
      questions[item.currentIndex] &&
      questions[item.currentIndex].responses.length > 0
    ) {
      // Match the response to the user's answer
      for (let i = 0; i < questions[item.currentIndex].responses.length; i++) {
        for (let j = 0; j < item.answer.length; j++) {
          // If the response value matches the answer, check if skipInReport is false
          if (
            item.answer[j].includes(
              questions[item.currentIndex].responses[i].value
            ) &&
            questions[item.currentIndex].responses[i].skipInReport === true
          ) {
            updatedResp.answer.splice(j, 1);
            break;
          }
        }
      }
    }
    return updatedResp;
  };

  // add a warning before users depart the page if they haven't submitted
  useEffect(() => {
    const warning = (event) => {
      // TODO: detect if user is anonymous or not
      // TODO: detect if email already sent and don't show?
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", warning);

    const cleanup = () => window.removeEventListener("beforeunload", warning);
    return cleanup;
  });

  /* Step 1/3: Fetch questionnaire based on type to
  enable mapping inputs to the original questions */
  useEffect(() => {
    const MATRIX_LOCALE = { language: Language.English };
    if (type && !questionnaire?.screener) {
      dispatch(requestQuestionnaire({ type, locale: MATRIX_LOCALE }));
    }
  }, [type]);

  // Step 2/3: Fetch user report if not fetched before
  useEffect(() => {
    if (!report || (report && Object.keys(report).length === 0)) {
      if (userId) {
        if (userRole === ROLES.ADMIN || userRole === ROLES.PROVIDER) {
          dispatch(loadReport({ reportId, fromPublic })).then((action) => {
            dispatch({
              type: "recent-records/REMEMBER",
              payload: action.payload.userId,
            });
          });
        } else {
          dispatch(loadReport({ reportId })).then((action) => {
            // We used to do a sanity check to assert that this was the right
            // userId, but this should be handled by security rules, right?
            // TODO
            dispatch({
              type: "recent-records/REMEMBER",
              payload: action.payload.userId,
            });
          });
        }
      } else if (!userId) {
        dispatch(
          alertEvent(
            `Loading public reports is temporarily disabled (tried loading ${reportId} with no user ID)`
          )
        );
        // onFetchPublicUserReport(reportId);
      }
    }
  }, [report, reportId, userId]);

  function askForEmail() {
    // do nothing, disabled now
    dispatch(
      alertEvent(
        "Emailing reports is currently disabled due to system security settings!"
      )
    );
  }

  const reportOrderSorterV3_NEW = ([aKey, aValue], [bKey, bValue]) => {
    const qa = questions[aKey];
    const qb = questions[bKey];
    const indexA =
      (Number.isSafeInteger(qa?.orderInReport) ? qa?.orderInReport : 10000) +
      (qa?.naturalOrder || 0) / questionCount;
    const indexB =
      (Number.isSafeInteger(qb?.orderInReport) ? qb?.orderInReport : 10000) +
      (qb?.naturalOrder || 0) / questionCount;
    console.log(`sorting ${aKey} with order ${indexA}`);
    return indexA - indexB;
  };
  const reportOrderSorterV2 = (a, b) => {
    const indexA = Number.isSafeInteger(
      questions[a.currentIndex]?.orderInReport
    )
      ? questions[a.currentIndex].orderInReport
      : a.currentIndex;
    const indexB = Number.isSafeInteger(
      questions[b.currentIndex]?.orderInReport
    )
      ? questions[b.currentIndex].orderInReport
      : b.currentIndex;
    return indexA - indexB;
  };

  /**
   * Build the
   */
  const buildHistoryV2 = () => {
    const history = new Map(); // maps sections to answer bundles
    const sectionByIndex = Object.values(questions).map((q) => q.reportSection);
    if (!Array.isArray(report.inputs)) debugger; // refactor V2 into separate component
    const inputs = report.inputs.slice();
    inputs.sort(reportOrderSorterV2);
    inputs
      .filter((i) => i.answer)
      .forEach((item) => {
        const section = sectionByIndex[item.currentIndex];
        let entry;
        if (!history.has(section)) {
          entry = {
            notReported: false,
            none: false,
            answer: [],
            question: section,
          };
          history.set(section, entry);
        } else {
          entry = history.get(section);
        }
        if (item.answer.includes("None of these")) {
          entry.none = true;
        } else if (item.answer.includes("not reported")) {
          entry.notReported = true;
          entry.answer = ["not reported"];
        } else {
          entry.answer.push(...filteredRep(item).answer);
        }
      });
    setProcessedReport(
      Array.from(history.values()).filter((e) => e.answer.length > 0)
    );
  };

  const [collapsed, setCollapsed] = useState([]);
  function toggleCollapse(item) {
    if (collapsed.indexOf(item) > -1) {
      setCollapsed(spliceOutOf(item, collapsed));
    } else {
      setCollapsed(collapsed.concat(item));
    }
  }
  const [tables, setTables] = useState([]);
  const buildHistoryV3 = () => {
    const history = new Map(); // maps sections to answer bundles
    /**
     * @type {Map<string, Map<string, object[]>}
     */
    const multiplexes = new Map();
    const questionTables = new Map();
    const sectionOrder = [];
    const sortedAnswers = Object.entries(report.answers).sort(
      reportOrderSorterV3_NEW
    );
    const multiplexRegex = /\[(\d+)\]$/;
    let lastSubsection;
    console.warn(sortedAnswers);
    const tabledHeaderEntries = Object.fromEntries(Object.entries(questions).flatMap(([qk, qv]) => Array.isArray(qv.tableWith) ? [[qk, qk], ...qv.tableWith.map(k => [k, qk])] : []));
    for (let [answerKey, answer] of sortedAnswers) {
      const answerUpdates = {}; // our answers are unextensible read-only objects
      const multiplexFound = answerKey.match(multiplexRegex);
      const hasMultiplex = multiplexFound !== null;
      const questionKey = hasMultiplex
        ? answerKey.slice(0, multiplexFound.index)
        : answerKey;
      console.log(questionKey);
      const question = Object.assign(
        { key: questionKey },
        questions[questionKey]
      );
      if (question?.multiplexOver) {
        question.multiplexLabel = JSON.stringify(question.multiplexOver);
        let table = multiplexes.get(question.multiplexLabel);
        if (!table) {
          table = new Map();
          table.set("__maxIndex", 0);
          multiplexes.set(question.multiplexLabel, table);
          question.firstForMultiplex = true;
        }
        let row = table.get(questionKey);
        if (!row) {
          row = [];
          row.question = question; // string keys aren't emitted by array.map
          table.set(questionKey, row);
        }
        const multiplexIndex = Number.parseInt(multiplexFound[1], 10);
        row[multiplexIndex] = answer;
        if (multiplexIndex > table.get("__maxIndex"))
          table.set("__maxIndex", multiplexIndex);
      }
      if (tabledHeaderEntries[questionKey]) {
        if (hasMultiplex) {
          const tableHeader = tabledHeaderEntries[questionKey];
          question.multiplexLabel = questions[tableHeader]?.tableKey || questions[tableHeader]?.tableUnit;
          question.multiplexUnit = questions[tableHeader]?.tableUnit;
          let table = (multiplexes.get(question.multiplexLabel));
          if (!table) {
            table = new Map();
            table.set("__maxIndex", (report.answers[tableHeader]?.tableSize || 1) - 1);
            multiplexes.set(question.multiplexLabel, table);
            question.firstForMultiplex = true;
          }
          if (tableHeader === questionKey) {
            table.set("__header", question);
          }
          let row = table.get(questionKey);
          if (!row) {
            row = [];
            row.question = question; // string keys aren't emitted by array.map
            table.set(questionKey, row);
          }
          const multiplexIndex = Number.parseInt(multiplexFound[1], 10);
          row[multiplexIndex] = answer;
          if (multiplexIndex > table.get("__maxIndex"))
            table.set("__maxIndex", multiplexIndex);
        } else if (tabledHeaderEntries[questionKey] === questionKey) {
          if (answer.tableSize !== 0 || !answer.value) continue;
        } else {
          console.warn(`Question ${questionKey} appears to be part of table, but has non-indexed answer!`);
        }
      }

      if (!(questionKey in questions)) {
        question.NOT_FOUND = true;
        question.responses = [];
      }
      if (!question) {
        console.error(
          `Couldn't find question for key ${questionKey} -- is this an old report?`
        );
        continue;
      }
      if (question.subsection && question.subsection !== lastSubsection) {
        question.firstForSubsection = true;
        lastSubsection = question.subsection;
      }
      if (
        question.skipInReport === true ||
        (question.skipInReport === "admin-only" && userRole !== "ADMIN") ||
        answer.selected?.some((i) => question.responses?.[i]?.skipInReport)
      ) {
        // TODO: determine why sometimes responses is undefined
        console.log(
          `Skipping ${answerKey} because the question and/or a response were marked skipInReport`
        );
        continue;
      }
      if (answer.isMeta) {
        console.log(`Skipping meta answer ${answerKey}`);
        continue;
      }
      let sectionEntry;
      const sectionLabel = question.reportSection
        || sections[question.section]?.reportTitle
        || sections[question.section]?.title;
      if (history.has(sectionLabel)) {
        sectionEntry = history.get(sectionLabel);
      } else {
        sectionEntry = {
          notReported: false,
          none: false,
          entries: [],
          title: sectionLabel,
        };
        history.set(sectionLabel, sectionEntry);
        sectionOrder.push(sectionLabel);
      }
      let flags = question.flags || [];
      if (Array.isArray(sections[question.section]?.commonFlags)) {
        // shallow clone
        flags = flags.concat(
          sections[question.section].commonFlags.map((o) =>
            Object.assign({}, o)
          )
        );
      }
      flags = flags
        .filter((f) =>
          evaluator(
            f.displayWhen,
            report.answers,
            { questions, this: answer },
            true
          )
        )
        .map((f) => {
          if (Array.isArray(f.description)) {
            f.description = evaluator(f.description, report.answers, {
              questions,
              this: answer,
            });
          }
          return f;
        });

      // Depending on the report settings, we sort the responses by the order
      // of their definitions, rather than the order they were selected.
      if (!reportSettings.keepOriginalListOrdering) {
        if (
          Array.isArray(answer.value) &&
          Array.isArray(answer.selected) &&
          question.display !== "list"
        ) {
          const sorted = [];
          answer.selected.forEach((originalPos, currentIndex) => {
            sorted[originalPos] = answer.value[currentIndex];
          });
          try {
            answerUpdates.value = sorted.filter((x) => x);
            answerUpdates.selected = answer.selected
              .slice()
              .sort((a, b) => a - b);
          } catch (err) {
            console.error(err);
          }
        }
      }

      sectionEntry.entries.push({
        question,
        answer: { ...answer, ...answerUpdates },
        flags,
      });
    }
    const historyOut = sectionOrder.map((s) => history.get(s));
    setProcessedReport(historyOut);
    const newTables = {};
    multiplexes.forEach((table, key) => {
      const sortedArray = Array.from(table.values())
        .filter((row) => Array.isArray(row))
        .sort((a, b) =>
          reportOrderSorterV3_NEW([a.question?.key], [b.question?.key])
        );
      sortedArray.maxLength = table.get("__maxIndex");
      sortedArray.header = table.get("__header");
      newTables[key] = sortedArray;
    });
    console.warn(newTables);
    setTables(newTables);
    let outcomes = [];
    // const REEVALUATE = true;
    // const answersWithOutcomes = {...report.answers};
    // for (let [key, dfn] of Object.entries(outcomeDefs)) {
    //   let value = evaluator(dfn.formula, answersWithOutcomes, {questions});
    //   report.outcomes[key] = value;
    //   // console.error(`Evaluated ${key} to get ${value}`);
    //   answersWithOutcomes[`~${key}`] = {
    //     isOutcome: true,
    //     value
    //   };
    // }
    // report.answers = answersWithOutcomes;
    if (report.outcomes) {
      for (let [key, value] of Object.entries(report.outcomes)) {
        const dfn = outcomeDefs[key];
        const obj = { key, value, flags: [] };
        if (dfn) {
          if (
            dfn.skipInReport === true ||
            (dfn.skipInReport === "admin-only" && userRole !== "ADMIN")
          )
            continue;
          obj.flags = dfn.flags
            ?.filter((f, i) =>
              evaluator(
                f.displayWhen,
                report.answers,
                { questions, this: value },
                true
              )
            )
            .map((f) => {
              if (Array.isArray(f.description)) {
                f.description = evaluator(f.description, report.answers, {
                  questions,
                  this: value,
                });
              }
              return f;
            });
        } else {
          obj.definitionNotFound = true;
        }
        outcomes.push(obj);
      }
      setProcessedOutcomes(outcomes);
    }
    return historyOut;
    // console.error(outcomes);
  };

  const [questionDetails, setQuestionDetails] = useState(false);

  function examineQuestion(key) {
    if (userRole !== "Patient") {
      setQuestionDetails({ key, ...questions[key] });
    }
  }

  // const modules = useSelector(getAllModules);
  // const [activeModules, setActiveModules] = useState([]);
  // useEffect(() => {
  //   if (!report || !questionnaire)
  //   if (!Array.isArray(modules)) {
  //     if (Array.isArray(activeModules)) setActiveModules([]);
  //     return;
  //   }
  //   const newActive = modules.filter(m => m.displayWhen && evaluator(m.displayWhen, report.answers, questionnaire, true));
  //   setActiveModules(newActive);
  // }, [modules]);

  const [builds, setBuilds] = useState(0);
  // Step 3/3: Map questions and report to history
  useEffect(() => {
    if (
      !isLoading &&
      questions &&
      questions.length !== 0 &&
      report &&
      Object.keys(report).length !== 0
    ) {
      if (builds > 10) {
        throw new Error("Excessive history builds, report UI may be looping");
      }
      setBuilds(builds + 1);
      if (report.version <= 2) {
        buildHistoryV2();
      } else if (report.version === 3) {
        buildHistoryV3();
      } else {
        throw new Error(`Unsupported report version ${report.version}`);
      }
    }
  }, [isLoading, questions, report]);

  const debugMode = useSelector(s => s.session.debugMode);
  function rebuild () {
    if (report.version === 3) {
      setBuilds(builds + 1);
      buildHistoryV3();
    }
  }

  const [printAttempted, setPrintAttempted] = useState(false);
  useEffect(() => {
    if (processedReport?.length && launchPrintDialog && !printAttempted) {
      console.warn(questions);
      console.warn(report);
      setTimeout(() => window.print(), 500);
      setPrintAttempted(true);
    }
  }, [processedReport, printAttempted]);

  function copyEntry(e) {
    const text = Array.isArray(e.answer.value)
      ? e.answer.value.join("\n")
      : e.answer.value;
    navigator.clipboard.writeText(text);
  }
  function entryToLabeledText(e) {
    const label =
      (
        e.question.reportLabel ||
        e.question.key ||
        "<unknown question>"
      ).toLocaleUpperCase() + ":";
    if (Array.isArray(e.answer.value)) {
      return `${label}\n${e.answer.value.map((v) => " - " + v).join("\n")}`;
    }
    return `${label} ${e.answer.value}`;
  }
  function copySection(section) {
    const header =
      section.title.toLocaleUpperCase() +
      "\n" +
      section.title.replace(/./g, "-") +
      "\n";
    const texts = section.entries.map(entryToLabeledText);
    navigator.clipboard.writeText(header + texts.join("\n"));
  }
  function copySubsection(subsectionName) {
    const header =
      subsectionName.toLocaleUpperCase() +
      "\n" +
      subsectionName.replace(/./g, "-") +
      "\n";
    const texts = processedReport.flatMap((s) =>
      s.entries
        .filter((e) => e.question?.subsection === subsectionName)
        .map(entryToLabeledText)
    );
    navigator.clipboard.writeText(header + texts.join("\n"));
  }

  function titleReplacements(template, defaultStr = "") {
    return (template || defaultStr)
      .replace("${ScreenerName}", ScreenerNames[type])
      .replace("${version}", report?.version);
  }

  const DEFAULT_TABLE_ORIENTATION = "question-rows";

  // History report, has to be a class for react-print to work properly
  class HistoryReport extends React.Component {
    buildFlag(f) {
      if ((f.clinicanOnly || reportSettings.allFlagsClinicianOnly) && review)
        return null;
      return (
        <div
          key={"flag-" + f.key}
          className={
            "small float-right alert alert-" + (f.alertType || "warning")
          }
          style={{
            display: "inline-block",
            padding: "4px",
            marginBottom: 0,
            marginLeft: "4px",
          }}
        >
          <strong>{f.title}:</strong>&nbsp;
          {f.description}
        </div>
      );
    }

    outcomes() {
      if (!report?.outcomes || Object.values(report.outcomes).length < 1) {
        return null;
      }
      return (
        <tbody>
          <tr>
            <th>
              {questionnaire?.reportSettings?.labels?.outcomes || "Outcomes"}
            </th>
            <th></th>
            <th></th>
          </tr>
          {report.version <= 2
            ? report?.outcomes
              ? Object.entries(report.outcomes).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                ))
              : null
            : processedOutcomes.map((o) => (
                <tr key={o.key}>
                  <td className="text-right text-secondary small">
                    <em
                      className={o.definitionNotFound ? "text-danger" : ""}
                      title={
                        o.definitionNotFound
                          ? "This outcome is no longer a part of the screener definition!"
                          : ""
                      }
                    >
                      {o.reportLabel || o.key}
                    </em>
                  </td>
                  <td>{`${o.value}`}</td>
                  <td>{o.flags?.map((f) => this.buildFlag(f))}</td>
                  <td />
                </tr>
              ))}
        </tbody>
      );
    }

    render() {
      return (
        <div className="content-wrapper">
          {!isLoading && processedReport ? (
            <>
              <div className="patient-history"></div>
              <p id="copyright" style={{ display: "none" }}>
                <small>
                  This report is generated by LiteraSeed Inc @ literaseed.io all
                  copyrights reserved.
                </small>
              </p>
              {true ? null : (
                <>
                  <h2 className="pr-3 pt-3 pl-3">
                    <div className="plain-English">
                      <span style={{ display: "inline-block" }}>
                        {titleReplacements(
                          questionnaire?.reportSettings?.labels?.title,
                          "${ScreenerName}"
                        )}
                      </span>
                      <small
                        className="text-muted"
                        style={{ paddingLeft: "1em", display: "inline-block" }}
                        title={`version ${report?.version}`}
                      >
                        {titleReplacements(
                          questionnaire?.reportSettings?.labels?.subtitle,
                          "Patient Self Report"
                        )}
                      </small>
                      <div className="float-right print-only-block">
                        <img
                          style={{ width: "120px", verticalAlign: "top" }}
                          src="/images/Green.svg"
                        />
                      </div>
                    </div>
                  </h2>
                  <p className="text-center font-italic">
                    This survey represents the {patientTerm}'s own understanding
                    of their present condition. The answers do not constitute
                    medical diagnoses and should be evaluated together with both{" "}
                    {patientTerm} and {clinicianTerm}.
                  </p>
                </>
              )}
              {report.version <= 2 ? (
                <div className="alert alert-danger">
                  This report was generated in an outdated format{" "}
                  <span className="badge badge-dark badge-v-center">
                    v{report.version || 0}
                  </span>{" "}
                  and does not support newer features. If you need this record
                  updated, please contact a LiteraSeed administrator.
                </div>
              ) : null}
              {report.screenerVersion !== questionnaire.version ? (
                <div className="alert alert-warning">
                  These answers were provided with an earlier version of the
                  questionnaire{" "}
                  <span className="badge badge-dark badge-v-center">
                    v{report?.screenerVersion}
                  </span>
                  , be aware that question and response text may have changed
                  slightly in the current version{" "}
                  <span className="badge badge-dark badge-v-center">
                    v{questionnaire?.version}
                  </span>
                  .
                </div>
              ) : null}
              {review && previouslySubmitted ? (
                <div className="alert alert-warning">
                  You have already submitted this questionnaire. By submitting
                  again, you will overwrite your previous answers. However your
                  clinic will still be able access your old submissions.
                </div>
              ) : null}

              <Row style={review ? { marginBottom: "100px" } : {}}>
                <Col>
                  <table id="history" className="table table-print-denser">
                    <tbody>
                      {userRole === ROLES.PROVIDER ||
                      userRole === ROLES.ADMIN ? (
                        <>
                          <tr>
                            <th colSpan="3">Patient Information</th>
                          </tr>
                          {report.submitter && !fromPublic ? (
                            <>
                              <tr style={{ fontStyle: "italic" }}>
                                <td />
                                <td>Last Name:</td>
                                <td>{report.submitter.lastName}</td>
                              </tr>
                              <tr style={{ fontStyle: "italic" }}>
                                <td />
                                <td>Date of Birth:</td>
                                <td>{report.submitter.dob}</td>
                              </tr>
                            </>
                          ) : fromPublic ? (
                            <tr style={{ fontStyle: "italic" }}>
                              <td />
                              <td colSpan="2">
                                <span className="badge badge-warning">
                                  THIS IS A PUBLIC REPORT - NO USER DATA
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <th colSpan="3">Not available</th>
                            </tr>
                          )}
                        </>
                      ) : null}
                      <tr style={{ fontStyle: "italic" }}>
                        <td />
                        <td>Submitted:</td>
                        <td>
                          {Moment.utc(report.t_submitted)
                            .local()
                            .format("MM/DD/YYYY [at] h:mm a")}
                        </td>
                      </tr>
                      <tr style={{ fontStyle: "italic" }}>
                        <td />
                        <td>Interview completed in:</td>
                        <td>
                          {report.completionLanguage
                            ? dualName(report.completionLanguage)
                            : "unknown"}
                        </td>
                      </tr>
                    </tbody>
                    {reportSettings.outcomePosition === "top"
                      ? this.outcomes()
                      : null}
                    <tbody>
                      {report.version <= 2
                        ? processedReport.map((historyItem, key) => (
                            <React.Fragment key={key}>
                              <tr>
                                <th colSpan="3">{historyItem.question}</th>
                              </tr>
                              {historyItem.answer ? (
                                historyItem.answer.map((answer, i) =>
                                  // TODO: this is terrible duck-typing, we should confirm
                                  // that the response has sub-values structurally! [tdhs]
                                  answer.includes(":") ? (
                                    <tr key={i}>
                                      <td />
                                      <td>{answer.split(":")[0]}:</td>
                                      <td>
                                        {answer.split(":").slice(1).join("")}
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr key={i}>
                                      <td />
                                      <td colSpan="2">{answer}</td>
                                    </tr>
                                  )
                                )
                              ) : (
                        <tr><th className="text-danger">No answer!</th></tr>
                    )}
                  </React.Fragment>
                  ))

              :  /* version 3+ */
                processedReport.map(section => (
                  <React.Fragment key={section.title}>
                    <tr>
                      <th colSpan="3">{section.title || (console.warn(section) || "«untitled section»")}</th>
                      <td className="d-print-none text-muted text-center">
                        {userRole === "Patient" ? null : <a onClick={() => copySection(section)} title="Copy whole section as text"><FaRegCopy/></a>}
                      </td>
                    </tr>
                    {section.entries.map(e =>
                      (e.question.multiplexLabel) ?
                        (e.question.firstForMultiplex ?
                        <tr key={e.question.key} title={e.question.multiplexLabel || e.question.key}>
                          <td colSpan="3">
                          {((tables?.[e.question.multiplexLabel]?.header?.tableReportOrientation || DEFAULT_TABLE_ORIENTATION) === "question-columns" ?
                              <table className="table">
                                {console.warn(tables?.[e.question.multiplexLabel]?.header)}
                              <thead>
                                <tr>
                                  <td><small>{e.question.multiplexUnit || e.question.tableUnit} Table</small></td>
                                  {tables?.[e.question.multiplexLabel]?.map(
                                    (row, ri, table) => <th title={row?.question?.key} onClick={() => examineQuestion(row?.question?.key)}>
                                    {row?.question?.reportLabel || row?.question?.key}
                                    {row?.question?.responses?.some?.(r => ['freeform', 'textbox'].includes(r.inputType)) ? <>{" "}<FaFileSignature className="text-primary float-right" style={{height: "22.5px"}} title="Patient freeform response"/></> : null}
                                  </th>
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {ordinals(tables?.[e.question.multiplexLabel]?.maxLength + 1).map(i =>
                                  <tr key={`row-${e.question.multiplexLabel}-N${i}`}>
                                  <th key={`header-${e.question.multiplexLabel}-${i}`}>#{i+1}</th>
                                  {tables?.[e.question.multiplexLabel]?.map(row => <td key={`cell-${row?.question?.key}-${i}`}>
                                    {row[i] ?
                                    (Array.isArray(row[i].value) ? row[i].selected.map((s, i) => <>
                                      {row[i].value[i]}
                                      {" "}
                                      {row?.question?.responses?.[s]?.unitHint ? <span className="badge badge-light">{row?.question?.responses?.[s]?.unitHint}</span> : null}
                                      {i < row[i].selected.length - 1 ? ", " : ""}
                                    </>) : row[i].value)
                                    :null}
                                  </td>)}
                                </tr>)}
                              </tbody>
                              </table>
                          :
                              <table className="table">
                              <thead>
                                <tr>
                                  <th>{e.question.multiplexUnit || e.question.tableUnit} Table</th>
                                  {ordinals(tables?.[e.question.multiplexLabel]?.maxLength + 1).map(
                                    i => <th key={`header-${e.question.multiplexLabel}-${i}`}>#{i+1}</th>
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {tables?.[e.question.multiplexLabel]?.map(row => <tr key={`row-${e.question.multiplexLabel}-${row.question.key}`}>
                                  <td title={row?.question?.key} onClick={() => examineQuestion(row?.question?.key)}>
                                    {row?.question?.reportLabel || row?.question?.key}
                                    {row?.question?.responses?.some?.(r => ['freeform', 'textbox'].includes(r.inputType)) ? <><br/><FaFileSignature className="text-primary" title="Patient freeform response"/></> : null}
                                  </td>
                                  {row?.map((col, i) => <td key={`cell-${row?.question?.key}-${i}`}>
                                    {col ?
                                    (Array.isArray(col.value) ? col.selected.map((s, i) => <>
                                      {col.value[i]}
                                      {" "}
                                      {row?.question?.responses?.[s]?.unitHint ? <span className="badge badge-light">{row?.question?.responses?.[s]?.unitHint}</span> : null}
                                      {i < col.selected.length - 1 ? ", " : ""}
                                    </>) : col.value)
                                    :null}
                                  </td>)}
                                </tr>)}
                              </tbody>
                              </table>
                          )}
                          </td>
                        </tr>
                          : null)
                      :
                      (<React.Fragment key={e.question.key}>
                        {e.question.firstForSubsection ?
                          <tr>
                            <th colSpan="3" className="subheading text-muted" id={`subheading-${snakeCase(e.question.subsection)}`}>
                              <em>{e.question.subsection}</em>
                              <span onClick={() => toggleCollapse(e.question.subsection)} className="pl-4" title="Expand/collapse">
                                {collapsed.indexOf(e.question?.subsection) === -1 ? <FaChevronCircleDown/> : <FaChevronCircleLeft/>}
                              </span>
                            </th>
                            <td className="d-print-none text-muted text-center">
                            {userRole === "Patient" ? null : <a onClick={() => copySubsection(e.question.subsection)} title="Copy whole section as text"><FaRegCopy/></a>}
                            </td>
                          </tr>
                        : null}
                        {
                          collapsed.indexOf(e.question?.subsection) === -1 ?
                          <tr title={e.question.key}>
                          <td className="text-right text-secondary small question-label" onClick={() => examineQuestion(e.question.key)} title="Click for details">
                            <em>
                              {e.question.reportLabel || (reportSettings.useQuestionKeysAsLabels ? e.question.key : "")}
                            </em>
                            {e.question.NOT_FOUND ?
                              <span className="text-danger" title={`This answer references a question [${e.question.key}] that no longer exists in the questionnaire. Contextual information may be limited.`}>
                                outdated question&nbsp;
                                <FaExclamationTriangle/>
                              </span>
                            : null}
                          </td>
                          {Number.isInteger(e.answer.skipReason) ?
                          <td colSpan="2">
                            <span className="badge badge-secondary">skipped</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <em className="text-secondary">{questions.allowSkips?.responses?.[e.answer.skipReason]?.label}</em>
                          </td>
                          :
                          <td colSpan="2">
                            {((e.question.display === "multi_options") ?
                              <ul>
                                {Array.isArray(e.answer.value)
                                  ? (e.answer.selected.map((s, i) => 
                                    <li key={i} className={e.answer.value.length === 1 ? "pl-0" : ""}>
                                      {e.answer.value.length > 1 ? <em className="small text-muted">{e.question.responses[s]?.reportLabel || (reportSettings.enumerateAnswerLists ? `#${s+1}` : "•")} </em> : null}
                                      {e.answer.value[i]}
                                      {reportSettings?.displaySubtext ? <span className="badge badge-light">{e.question.responses[s].subtext}</span> : null}
                                      {['freeform', 'textbox'].indexOf(e.question.responses[s]?.inputType) > -1 ?  
                                        <>{" "}<FaFileSignature className="text-primary" title="Patient freeform response"/></>
                                      : null}
                                      {e.question.responses[s]?.reportLinkTo ?
                                        <a className="btn btn-xs btn-primary ml-3 d-print-none" href={e.question.responses[s].reportLinkTo.url}>
                                          {e.question.responses[s].reportLinkTo.label}
                                        </a>
                                      : null}
                                    </li>))
                                  // e.answer.value.map((a, i) => <li key={i}>{`${a}`}</li>)
                                  : `${e.answer.value}`}
                              </ul>
                              :
                              <>
                              {(e.question.display === "list") ?
                                <ol>
                                {e.answer.value?.map((v, i) => 
                                    <li key={i}>{v + " "}</li>
                                )}
                                </ol>
                              :
                              <>{e.answer.value} {reportSettings?.displaySubtext ? <span className="badge badge-light">{e.question.responses[e.answer.selected[0]].subtext}</span> : null}</>}
                              {Array.isArray(e.answer?.selected) && e.answer.selected.some(i => ['freeform', 'textbox'].indexOf(e.question.responses?.[i]?.inputType) > -1) ?
                                <FaFileSignature className="text-primary" title="Patient freeform response"/>
                                : null
                              }
                              </>
                            )}
                            {e.answer.selected?.map(s => e.question.responses?.[s]?.unitHint)?.filter(u => u)?.map((u, i) =>
                              <>{" "}<span className="badge badge-light" key={i}>{u}</span></>)}
                            {e.flags?.map((f, i) => <>{i > 0 ? null : null}{this.buildFlag(f)}</>)}
                          </td>
                          }
                          <td className="d-print-none text-muted text-center">
                          {userRole === "Patient" ? null : <a onClick={() => copyEntry(e)} title="Copy whole section as text"><FaRegCopy/></a>}
                          </td>
                        </tr>
                        : null
                        }
                      </React.Fragment>)
                    )}
                  </React.Fragment>
                  ) /* end section map */
                  )
              }
            </tbody>
            {reportSettings.outcomePosition !== 'top' ? this.outcomes() : null}
            </table>
            </Col>
            </Row>
            {review ? <div className={historyClasses.Footer}>
        <div className={`text-center`}><small>&copy; All rights reserved</small></div>
          <div className="d-print-none">
            {/* we might want to re-examine these as Bootstrap buttons... we're manually overriding the hover effect here for example */}
            <Button className="back-btn pl-3 pr-3 mr-3 ml-3" onClick={handleBack}>
              {dir === 'ltr' ? <FaArrowLeft /> : <FaArrowRight />} <span className="pr-2 font-weight-bold">Back</span>
            </Button>
            <Button className="btn-pink pl-3 pr-3 mr-3 ml-3" onClick={handleForward} >
              <span className="pr-2 font-weight-bold">Submit</span> {dir === 'ltr' ? <FaArrowRight /> : <FaArrowLeft />}
            </Button>
          </div>
        </div> : null}
          </>) : !isLoading && !processedReport ? (
            <p className="pl-3 pt-4">
              {console.log(
                `reportId: ${reportId}, isLoading: ${isLoading}, history: ${
                  processedReport && processedReport.length
                }`
              )}
              Report not found or deleted
            </p>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
      );
    }
  }

  const dir = "ltr"; // TODO

  const authOrg = useSelector((s) => s.auth.organization);
  const clinicianTerm =
    authOrg.terminology?.clinicianUser || "medical provider";
  const patientTerm = authOrg.terminology?.endUser || "patient";

  return (
    <div className="report shadow-sm print-no-borders p-md-3 mb-5 rounded">
      <div style={{ textAlign: "center" }} className="no-print pt-3">
        {userRole === ROLES.PATIENT ? (
          review ? (
            <>
              <h2>Review Answers</h2>
              <p className="pb-3">
                Please review report before submitting to your {clinicianTerm}.
                If you see any mistakes, click "Back" to return to the
                questionnaire.
              </p>
              {review ? null : (
                <>
                  <br />
                  <Button
                    className="back-btn pl-3 pr-3 mr-3 ml-3"
                    style={{ color: "#17207a" }}
                    onClick={handleBack}
                  >
                    <FaUndo />{" "}
                    <span className="pr-2 font-weight-bold">Back</span>
                  </Button>
                  <Button
                    className="forward-btn pl-3 pr-3 mr-3 ml-3 text-white"
                    onClick={handleForward}
                  >
                    <span className="pr-2 font-weight-bold">Submit</span>{" "}
                    <FaFileUpload />
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <h2>Thank you for completing the previsit symptom screener!</h2>
            </>
          )
        ) : (
          <>
            <Row>
              <Col className="d-flex">
                <p className="btn btn-primary-outline p-2 mb-3 justify-content-start">
                  {userRole === ROLES.ADMIN ? (
                    <Link to={ROUTES.ADMIN}>← Return to Admin Home</Link>
                  ) : (
                    <a onClick={() => history.goBack()}>
                      ← Return to Previous Page
                    </a>
                  )}
                </p>
              </Col>

            </Row>
            <Row className="justify-content-center">
              <Col>
                {report?.submitter ? (
                  <h2>
                    Report for Patient - {report?.submitter?.lastName}{" "}
                    <small className="text-muted">
                      &emsp;DOB: {report?.submitter?.dob}
                    </small>
                  </h2>
                ) : (
                  <h2>Your Symptoms Summary</h2>
                )}
              </Col>
            </Row>
          </>
        )}
        {false ? null : (
          <>
            <ReactToPrint
              trigger={() => (
                <Button
                  className="ml-3 btn-primary"
                  style={{ minWidth: "150px" }}
                >
                  <i className="fas fa-print"></i> Print
                </Button>
              )}
              content={() => componentRef.current}
            />
            {/* <button
              className="ml-3 btn btn-info disabled"
              style={{ minWidth: "150px" }}
              onClick={() => askForEmail()}
            >
              <i className="fas fa-envelope"></i>
              {" "}Email...
            </button> */}
          </>
        )}
        <hr />
      </div>

      <HistoryReport ref={componentRef} />

      {userId ? null : <Row><FloatingAction reportId={reportId} context="report"/></Row>}

      <Modal show={!!questionDetails} size="lg" onHide={() => setQuestionDetails(false)}>
          <Modal.Header>
            <Modal.Title>
              Question Details for {questionDetails?.reportLabel}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>
                    {questionDetails?.reportLabel}<br/>
                    <span className="badge badge-dark">{questionDetails?.key}</span>
                  </td>
                </tr>
                <tr>
                  <th>Section</th>
                  <td>{questionDetails.section}</td>
                </tr>
                <tr>
                  <th>Text</th>
                  <td><p>{`${questionDetails?.text}` || '«no text»'}</p>{questionDetails?.subtext? <p className="text-muted">{questionDetails?.subtext}</p> : null}</td>
                </tr>
                <tr>
                  <th>Responses</th>
                  <td>
                    <ol start="0">
                      {questionDetails?.responses?.map((r, i) => <li key={i}>
                        <p>
                          {r.label}
                          {r.reportLabel ? (
                            <em className="text-muted">
                              &nbsp;&nbsp;{r.reportLabel}
                            </em>
                          ) : null}
                        </p>
                        <span
                          className={`badge ${
                            r.inputType ? "badge-warning" : "badge-light"
                          }`}
                        >
                          {r.inputType || "text (default)"}
                        </span>
                        {r.inputOptions ? (
                          <ul>
                            {r.inputOptions.map((io) => (
                              <li>
                                {io.label} <em>{io.value}</em>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </li>
                    )}
                  </ol>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Col className="text-center">
            <Button
              style={{ color: "#17207a", minWidth: "50%" }}
              onClick={() => setQuestionDetails(false)}
            >
              OK
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
// *** END OF HpiReport Function ***

const mapDispatchToProps = (dispatch) => {
  return {};
};

function mapStateToProps(state, ownProps) {
  /* ownProps references props from the React Router Redirect function
     within the function that handles rendering the questionnaire component */
  const reportId = ownProps.reportId;
  const fromPublic = ownProps.fromPublic;

  console.log(
    `${state.report.isLoading} | ${state.questionnaire.isLoading} | ${state.provider.isLoading}`
  );
  return {
    providerId: state.auth.user.providerIdDB
      ? state.auth.user.providerIdDB
      : "",
    providerName: state.auth.user.provider ? state.auth.user.provider.name : "",
    userId: state.auth.user.uid,
    userRole: state.auth.user.role,
    email: state.auth.user.email,
    phone: state.auth.user.phone,
    reportId,
    fromPublic,
    isLoading: state.report.isLoading || state.provider.isLoading, // state.questionnaire.isLoading ||
    isReportUpdated: state.report.isUpdated && state.questionnaire.isUpdated,
    // questions: questionnaire.questions,
    // sections: questionnaire.sections,
    // questionnaire,
    // reportSettings: questionnaire.reportSettings || {},
    // outcomeDefs: questionnaire.reportOutcomes || {},
    // report,
    error: state.report.error,
    // redirectPath: ownProps.from === ROUTES.REPORTS_ANONYMOUS?
    //               ROUTES.SEND_EMAIL_ANONYMOUS : ROUTES.SEND_REPORT
    redirectPath: ROUTES.SEND_EMAIL_ANONYMOUS,
    review: ownProps.review || false,
    handleBack: ownProps.handleBack || (() => {}),
    handleForward: ownProps.handleForward || (() => {}),
    launchPrintDialog: ownProps.launchPrintDialog || false,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
