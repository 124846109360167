import build from "@date-io/date-fns";
import {
  createAsyncThunk,
  createEntityAdapter, createSlice
} from "@reduxjs/toolkit";
import { db } from "../../database";
import { assignOnly } from "../../utils";
import { getRelationsChunked } from "../../utils/promises";

const screeningsAdapter = createEntityAdapter({
  selectId: entity => entity.id,
  sortComparer: (a, b) => a.t_created - b.t_created
});

const INITIAL_STATE = screeningsAdapter.getInitialState({
  current: null
});

export class RequestedScreening {
  static CollectionRef = db.collection('screenings');
  static WriteVersion = 4;
  /**
   * @type {string} The ID of the screening itself
   */
  id;
  /**
   * @type {string} The ID of the provider managing this user
   */
  userId;
  /**
   * @type {string}
   */
  appointmentId;
  /**
   * @type {string}
   */
  managerId;
  /**
   * @type {string}
   */
  organizationId;
  /**
   * @type {string} The ID of the main report for this screening
   */
  primaryReportId;
  /**
   * @type {string} The ID of the main report for this screening
   */
  accessKey;
  /**
   * @type {string[]}
   */
  additionalReports = [];
  /**
   * @type {string}
   */
  userDOB;
  /**
   * @type {string}
   */
  userLastName;

  type;
  language;

  /**
   * 
   * @param {Array<*>}  
   */
  messages = [];

  /**
   * @constructor
   * @param {*} baseObject 
   */
  constructor (baseObject) {
    if (baseObject.doc) {
      this.id = baseObject.doc.id;
    } else if (baseObject.id) {
      // this.document = Report.CollectionRef.doc(baseObject.id);
      this.id = baseObject.id;
    } else {
      // this.document = Report.CollectionRef.doc();
      this.id = RequestedScreening.CollectionRef.doc().id;
    }
    assignOnly(baseObject, this, [
      ["version", RequestedScreening.WriteVersion],
      "userId",
      "appointmentId",
      "managerId",
      "accessKey",
      "type",
      "language",
      "primaryReportId",
      ["additionalReports", []],
      "userDOB",
      "userLastName",
      ["messages", []]
    ]);
  }

  static fromFirestore (snapshot, options) {
    return new RequestedScreening({...snapshot.data(options), id: snapshot.id});
  }
  static toFirestore (obj) {
    if (!(obj instanceof RequestedScreening))
      throw new Error("Only real Report objects are supported!");
    return assignOnly(
      {version: RequestedScreening.WriteVersion},
      obj,
      [
        "id",
        "userId",
        "appointmentId",
        "managerId",
        "accessKey",
        "type",
        "language",
        "primaryReportId",
        ["additionalReports", []],
        "userDOB",
        "userLastName",
        ["messages", []]
      ],
      true);
  }
  static FirestoreConverter = RequestedScreening.CollectionRef.withConverter(RequestedScreening);
}

export const getScreening = createAsyncThunk("screening/get",
  async (id, thunkAPI) => {
    const screening = await RequestedScreening.CollectionRef.doc(id).get();
    if (!screening.exists)
      throw new Error(`Screening ${id} could not be located`);
    return {...screening.data(), id};
  }
);
export const updateScreening = createAsyncThunk("screening/update",
  async ({id, changes}, thunkAPI) => {
    return await RequestedScreening.CollectionRef.doc(id).update(changes)
  }
);
export const getScreeningsByUser = createAsyncThunk("screening/by-user",
  async (userId, thunkAPI) => {
    const snapshot = await RequestedScreening.CollectionRef.where('userId', '==', userId).get();
    return snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
  }
);
export const getScreeningsByManager = createAsyncThunk("screening/by-manager",
  async (managerId, thunkAPI) => {
    const snapshot = await RequestedScreening.CollectionRef.where('managerId', '==', managerId).get();
    return snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
  }
);
export const getScreeningsByOrganization = createAsyncThunk("screening/by-organization",
  async ({organizationId, withMessages = false, withKeys = false, start = 0, end = Number.MAX_SAFE_INTEGER}, thunkAPI) => {
    const snapshot = await RequestedScreening.CollectionRef.where('organizationId', '==', organizationId).where('t_created', ">=", start).where('t_created', '<', end).get();
    const idsNeeded = snapshot.docs.map(d => d.id);
    const messages = await getRelationsChunked(idsNeeded, db.collection('externalMessages'), 'screeningId');
    const screenings = snapshot.docs.map(doc => ({...doc.data(), id: doc.id, REL_externalMessages: messages.filter(m => m.screeningId === doc.id)}));
    return screenings;
  }
);

export const createScreening = createAsyncThunk("screening/create",
  async (screeningObject, thunkAPI) => {
    return await RequestedScreening.CollectionRef.doc(screeningObject.id).set(screeningObject);
  }
);

const screeningsSlice = createSlice({
  name: 'screenings',
  initialState: INITIAL_STATE,
  reducers: {
    addScreeningDataFromOtherSource(state, action) {
      screeningsAdapter.upsertOne(state, action.payload)
    }
  },
  extraReducers: builder => {
    builder.addCase(createScreening.fulfilled, (state, action) => {
      screeningsAdapter.addOne(state, {
        ...action.meta.arg,
        _loaded: {action: action.type, timestamp: Date.now()}
      });
    });
    builder.addCase(updateScreening.fulfilled, (state, action) => {
      screeningsAdapter.updateOne(state, action.meta.arg);
    });
    builder.addCase(getScreening.fulfilled, (state, action) => {
      screeningsAdapter.upsertOne(state, {
        ...action.payload,
        _loaded: {action: action.type, timestamp: Date.now()}
      });
    });
    builder.addCase(getScreeningsByUser.fulfilled, (state, action) => {
      action.payload.forEach(s => s._loaded = {action: action.type, timestamp: Date.now()});
      screeningsAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(getScreeningsByManager.fulfilled, (state, action) => {
      action.payload.forEach(s => s._loaded = {action: action.type, timestamp: Date.now()});
      screeningsAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(getScreeningsByOrganization.fulfilled, (state, action) => {
      action.payload.forEach(s => s._loaded = {action: action.type, timestamp: Date.now()});
      screeningsAdapter.upsertMany(state, action.payload);
    });
    builder.addCase("access-key/CREATED", (state, action) => {
      screeningsAdapter.updateOne(state, {id: action.payload.inviteId, changes: {accessKey: action.payload.id}});
    });
    builder.addCase("invite-message/NEW-SUCCESS", (state, action) => {
      screeningsAdapter.updateOne(state, {id: action.payload.inviteId, changes: {messages: action.payload.updatedMessages}});
    });
    builder.addCase("provider/USER-INVITES-SUCCEEDED", (state, action) => {
      screeningsAdapter.upsertMany(state, action.payload.invites);
    });
  }
});

const screeningSelector = screeningsAdapter.getSelectors(state => state.screenings);
export const getAllScreenings = screeningSelector.selectAll;
export const getScreeningById = screeningSelector.selectById;
export const addScreeningDataFromOtherSource = screeningsSlice.actions.addScreeningDataFromOtherSource;

export default screeningsSlice.reducer;