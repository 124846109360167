import React, { Suspense, useEffect, useRef, useState } from "react";
import { Route, Switch, Redirect, useHistory, Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

// Universal
import Navigation from "./components/Navigation";

// Public
import Landing from "./components/Landing";
import About from "./components/About";
import Product from "./components/Product";
import Contact from "./components/Contact";
// import Screening from "./components/Screening/Screening";
import FAQ from "./components/FAQ";
import PRESS from "./components/Press";
import LocalArticle from "./components/Press/LocalArticle";
import CAREERS from "./components/Careers";
import RESULTS from "./components/Results";
import CovidScreener from "./components/History/CovidScreener";
import CovidReportContainer from "./components/Report/CovidReportContainer";

// Access
import SignIn from "./components/SignIn/Signin";
import signInAnonymously from "./components/SignIn/SigninAnonymous";
import SignOut from "./components/SignOut";
import Verify from "./components/SignIn/Verify";
import Waitlist from "./components/Waitlist/Waitlist";
import WaitlistThanks from "./components/Waitlist/Thanks";
import TermsOfService from "./components/Legal/TermsOfService.js"; 
import PrivacyNotice from "./components/Legal/PrivacyNotice.js";

// Private
import Home from "./components/Home/Home";
import History from "./components/History/History";
import ReportContainer from "./components/Report/ReportContainer";
import SendEmail from "./components/SendEmail/SendEmail";

// anonymous
import HistoryAnonymous from "./components/History/HistoryAnonymous";
// import SymptomAnonymous from "./components/Symptom/SymptomAnonymous";

// Account
import PasswordForgetPage from "./components/PasswordForget";
import AccountPage from "./components/Account";

// Provider
import ClinicianHome, { PROVIDER_DOCS_LINK } from "./components/Provider/ClinicianHome";
import PatientDetails from "./components/Provider/PatientDetails";
import ProviderSignIn from "./components/Provider/Signin";
import OrganizationAdmin from "./components/Admin/OrganizationAdmin";

// Admin
import AdminHome from "./components/Admin/AdminHome";
import AnalyticsDashboard from "./components/Admin/AnalyticsDashboard"; 

import * as ROUTES from "./constants/routes";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ProtectedRouteAnonymous from "./components/ProtectedRoute/ProtectedRouteAnonymous";
// import ProtectedRouteProvider from "./components/ProtectedRoute/ProtectedRouteProvider";

import "./styles/common.scss";
import { ROLES } from "./constants/roles";
import EnrollPatient from "./components/Provider/EnrollPatient";
import { IS_QA } from "./constants/app";
import { GlobalAlerts, snack } from "./components/UI/GlobalAlerts";
import { DebugPage } from "./components/Debug/debugPage";
import { SimpleModule } from "./components/Education/SimpleModule";
import { useKeyboardEvents } from "./utils/hooks";
import { ExploreQuestionnaire } from "./components/Debug/Explore";
import { TicketDetails } from "./components/UI/FloatingAction/TicketDetails";
import OrganizationDetails from "./components/Admin/OrganizationDetails";
import { Col, Row } from "./components/UI/Grid/Grid";
import { FaChevronCircleDown, FaChevronCircleRight, FaChevronCircleUp, FaChevronDown, FaChevronUp, FaDotCircle, FaEllipsisV, FaExternalLinkAlt, FaUserPlus } from "react-icons/fa";
import { resetEnrollmentUser } from "./store/actions";

const App = ({ isAuthenticated }) => {
  const recentNavs = useRef([]);
  function averageTimeSeparation (window = 5) {
    if (recentNavs.current.length < window) return undefined;
    recentNavs.current = recentNavs.current.slice(0, window);
    const diff = recentNavs.current[0] - recentNavs.current[window - 1];
    return diff/window;
  }
  const history = useHistory();
  useEffect(() => {
    const unregister = history.listen(update => {
      recentNavs.current.unshift(Date.now());
      const ats = averageTimeSeparation();
      if (ats !== undefined && ats < 100) {
        console.error(recentNavs.current);
        console.error(`ATS = ${ats}ms!`);
        throw new Error("Average navigation time sustained below 100ms, bailing out to avoid possible infinite nav loop");
      }
      console.log(`navigation (ats = ${ats})`, update);
    });
    return () => unregister();
  }, [])

  const dispatch = useDispatch();

  useKeyboardEvents([
    "F2", () => dispatch({type: "debug/TOGGLE"}),
    "F3", () => history.push("/debug")
  ], "keyup", () => IS_QA);

  const authUser = useSelector(s => s.auth.user);
  const organization = useSelector(s => s.auth.organization);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  function quickSnack (msg, severity = "") {
    dispatch(snack(msg, undefined, {style: severity}))
  }
  const [hiddenSections, setHiddenSections_raw] = useState(new Set());
  function toggleSidebarSection (section) {
    if (hiddenSections.has(section)) {
      hiddenSections.delete(section);
      setHiddenSections_raw(new Set(hiddenSections));
    } else {
      hiddenSections.add(section);
      setHiddenSections_raw(new Set(hiddenSections));
    }
  }
  function toggleSidebarAll () {
    if (sidebarCollapsed) {
      setHiddenSections_raw(new Set());
    } else {
      setHiddenSections_raw(new Set([
        "healthreports",
        "patients",
        "analytics",
        "account",
        "organization",
      ]))
    }
    setSidebarCollapsed(!sidebarCollapsed);
  }

  // const globalSearch = useSelector(s => s.provider.globalSearch);

  /**
   * @type {['received'|'pending'|'inactive'|'scheduled'|'scheduled_occurrences'|'all'|'PT_all', (newTab: 'received'|'pending'|'inactive'|'all'|'PT_all') => void]}
   */
  const [inboxTab, setInboxTab_raw] = useState("all");
  function setInboxTab (tab) {
    setInboxTab_raw(tab);
    if (history.location.pathname !== ROUTES.PROVIDER) {
      history.push(ROUTES.PROVIDER);
    }
  }

  function isCurrentTab (tab, page = ROUTES.PROVIDER) {
    if (history.location.pathname !== page) {
      return false;
    }
    if (!tab) return true;
    return tab === inboxTab;
  }

  const enrollee = useSelector(s => s.provider.enrollee);
  function enrollNewPatient (){
    if (enrollee.id) {
      // if we have an incomplete RE-enrollment, wipe it away since we asked for
      // a NEW enrollment...
      dispatch(resetEnrollmentUser());
      // otherwise we leave it since we want the user to be able to leave the
      // form and come back if interrupted
    }
    history.push(ROUTES.PROVIDER_ENROLL_PATIENT);
  }

  const SHOW_SECTION_CHEVRON = false;

  return (
  <>
    <Suspense fallback="loading">
      <Navigation isAuthenticated={isAuthenticated} />
      <Row>
      {authUser.role === "Provider" || authUser.role === "Admin" /* in the future we'll have a custom admin bar */ ?
        <Col className={`col-3 col-xl-2 clinician-sidebar`}>
        <div style={{position: "absolute", top: "50px", right: "10px", opacity: "60%"}} onClick={() => toggleSidebarAll()}>
        {sidebarCollapsed ? <FaChevronCircleDown title="Expand all entries" /> : <FaChevronCircleUp title="Collapse all entries" /> }
        </div>
        {/* style={{flexBasis: (sidebarCollapsed ? "0%" : "16.66667%"), transition: "flex-basis 1s ease-in-out"}} */}
        <div className="page-links">
          <Row className="justify-content-center mb-2">
            <button type="button" className="btn btn-pink py-1" onClick={e => enrollNewPatient(e)} style={{}}> {/* {width: "calc(100% - 2em)", marginLeft: "1em"} */}
              <FaUserPlus/>
              <span className="d-none d-lg-inline"> Send New</span>
            </button>
          </Row>
          {/* === SCREENINGS === */}
          <h4 className="sidebar-title" onClick={() => toggleSidebarSection("healthreports")} title="Requested, scheduled, and completed health reports." data-toggle="tooltip" data-placement="right">
            {SHOW_SECTION_CHEVRON ? hiddenSections.has("healthreports") ? <FaChevronCircleRight /> : <FaChevronCircleDown /> : null} Health Reports
          </h4>
          {hiddenSections.has("healthreports") ? null :
            <>
              <h4 className="sidebar-subtitle" title="All requests to complete a health report.">Requested</h4>
              <div className={`entry ${isCurrentTab("received") ? "selected" : ""}`} onClick={() => setInboxTab("received")} title="Reports completed by patients and ready for viewing." data-toggle="tooltip" data-placement="right">
                Ready
              </div>
              <div className={`entry ${isCurrentTab("pending") ? "selected" : ""}`} onClick={() => setInboxTab("pending")} data-toggle="tooltip" data-placement="right" title="All active requests sent out and awaiting patient completion.">
                Awaiting Response
              </div>
              <div className={`entry ${isCurrentTab("inactive") ? "selected" : ""}`} onClick={() => setInboxTab("inactive")}>
                Expired / Archived
              </div>
              <div className={`entry ${isCurrentTab("all") ? "selected" : ""}`} onClick={() => setInboxTab("all")}>
                All
              </div>
              <h4 className="sidebar-subtitle">Scheduled</h4>
              <div className={`entry ${isCurrentTab("scheduled") ? "selected" : ""}`} onClick={() => setInboxTab("scheduled")}>
                Upcoming
              </div>
              <div className={`entry sublevel ${isCurrentTab("scheduled_onetime") ? "selected" : ""}`} onClick={() => setInboxTab("scheduled_onetime")}>
                One-Time
              </div>
              <div className={`entry sublevel ${isCurrentTab("scheduled_recurring") ? "selected" : ""}`} onClick={() => setInboxTab("scheduled_recurring")}>
                Recurring
              </div>
              {/* <div className={`entry ${isCurrentTab("scheduled_inactive") ? "selected" : ""}`} onClick={() => setInboxTab("scheduled_inactive")}>
                Canceled
              </div> */}
              <div className={`entry ${isCurrentTab("scheduled_inactive") ? "selected" : ""}`} onClick={() => setInboxTab("scheduled_inactive")}>
                Inactive
              </div>
              {/* <div className="entry text-muted" style={{textDecoration: "line-through"}} onClick={() => quickSnack("Not yet available")}>
                Calendar
              </div> */}
            </>
          }
          {/* === PATIENTS === */}
          <h4 className="sidebar-title" onClick={() => toggleSidebarSection("patients")}>
            {SHOW_SECTION_CHEVRON ? hiddenSections.has("patients") ? <FaChevronCircleRight /> : <FaChevronCircleDown /> : null} Patients
          </h4>
          {hiddenSections.has("patients") ? null :
            <>
              <div className={`entry ${isCurrentTab("PT_all") ? "selected" : ""}`} onClick={() => setInboxTab("PT_all")}>
                Active
              </div>
              <div className={`entry text-muted ${isCurrentTab("PT_inactive") ? "selected" : ""}`} onClick={() => quickSnack("This feature will be made available soon! For now, all patients are listed in the 'Active' table.")}>
                Inactive
              </div>
              <div className={`entry text-muted ${isCurrentTab("PT_inactive") ? "selected" : ""}`} onClick={() => quickSnack("This feature will be made available soon! For now, all patients are listed in the 'Active' table.")}>
                Recently Viewed
              </div>
            </>
          }
          {/* === ANALYTICS === */}
          <h4 className="sidebar-title" onClick={() => toggleSidebarSection("analytics")}>
            {SHOW_SECTION_CHEVRON ? hiddenSections.has("analytics") ? <FaChevronCircleRight /> : <FaChevronCircleDown /> : null} Analytics
          </h4>
          {hiddenSections.has("analytics") ? null :
            <>
              <Link className={`entry ${isCurrentTab(null, ROUTES.SCHEDULER_ANALYTICS) ? "selected" : ""}`} to={ROUTES.SCHEDULER_ANALYTICS}>
                Dashboard
              </Link>
              {/* <div className="entry text-muted" style={{textDecoration: "line-through"}} onClick={() => quickSnack("Not yet available")}>
                Performance
              </div>
              <div className="entry text-muted" style={{textDecoration: "line-through"}} onClick={() => quickSnack("Not yet available")}>
                Reports
              </div> */}
            </>
          }
          {/* === SETTINGS === */}
          <h4 className="sidebar-title" onClick={() => toggleSidebarSection("account")}>
            {SHOW_SECTION_CHEVRON ? hiddenSections.has("account") ? <FaChevronCircleRight /> : <FaChevronCircleDown /> : null} Account
          </h4>
          {hiddenSections.has("account") ? null :
            <>
              <Link className={`entry ${isCurrentTab(null, ROUTES.ACCOUNT) ? "selected" : ""}`} to={ROUTES.ACCOUNT}>
                Settings
              </Link>
              {/* <div className="entry text-muted" style={{textDecoration: "line-through"}} onClick={() => quickSnack("Not yet available")}>
                Activity Log
              </div>
              <div className="entry text-muted" style={{textDecoration: "line-through"}} onClick={() => quickSnack("Not yet available")}>
                Notifications
              </div> */}
              <a className="link entry" href={PROVIDER_DOCS_LINK} target="_blank">
                Help <FaExternalLinkAlt className="float-right" title="This is an external link, which will open in a new tab" style={{margin: "2px"}} />
              </a>
            </>
          }
          {/* === MANAGEMENT === */}
          {(authUser.isManager || authUser.role === "Admin") ?
            <><h4 className="sidebar-title" /* style={{backgroundColor: "#FFFFDD"}} */ onClick={() => toggleSidebarSection("organization")}>
              {SHOW_SECTION_CHEVRON ? hiddenSections.has("organization") ? <FaChevronCircleRight /> : <FaChevronCircleDown /> : null} Organization
            </h4>
            {hiddenSections.has("organization") ? null :
              <>
                <Link className={`entry ${isCurrentTab(null, ROUTES.ORGANIZATION_ADMIN) ? "selected" : ""}`} to={ROUTES.ORGANIZATION_ADMIN}>
                  Settings
                </Link>
                <Link className="entry text-muted" onClick={() => quickSnack("This feature will be made available soon! Until then, please contact LiteraSeed for user activation and deactivation.")}>
                  User List
                </Link>
              </>
            }
            </>
            : null
          }
        </div>
      </Col>
    : null}
    <Col className="pl-0 pr-0" style={{height: "calc(100vh - 62px)", overflowY: "scroll"}}>
      <Switch>
        {/* Public */}
        <Route exact path={ROUTES.LANDING} component={Landing} />
        <Route exact path={ROUTES.ABOUT} component={About} />
        <Route exact path={ROUTES.PRODUCT} component={Product} />
        <Route exact path={ROUTES.CONTACT} component={Contact} />
        {/* <Route exact path={ROUTES.SCREENING} component={Screening} /> */}
        <Route exact path={ROUTES.FAQ} component={FAQ} />
        <Route exact path={ROUTES.PRESS} component={PRESS} />
        <Route path={`${ROUTES.PRESS}/:articleId`} component={LocalArticle} />
        <Route path={`/changelogs/:articleId`} component={LocalArticle}/>
        <Route exact path={ROUTES.CAREERS} component={CAREERS} />
        <Route exact path={ROUTES.RESULTS} component={RESULTS} />
        <Route exact path={ROUTES.COVID_SCREENING} component={CovidScreener} />
        <Route path={`${ROUTES.COVID_REPORT}/:reportId`} component={CovidReportContainer} />

        {/* Access */}
        <Route path={ROUTES.SIGN_IN} component={SignIn} />
        <Route path={ROUTES.SIGN_IN_ANONYMOUS} component={signInAnonymously} />
        {/* <Route path={ROUTES.SIGN_UP} component={SignUp} /> */}
        <Route path={ROUTES.SIGN_OUT} component={SignOut} />
        <Route path={ROUTES.TERMS_OF_SERVICE} component={TermsOfService} />
        <Route path={ROUTES.PRIVACY_NOTICE} component={PrivacyNotice} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        {/* <Route path={ROUTES.THANKS} component={Thanks} /> */}
        <Route path={ROUTES.VERIFY} component={Verify} />
        <Route path={ROUTES.WAITLIST} component={Waitlist} />
        <Route path={ROUTES.WAITLIST_THANKS} component={WaitlistThanks} />

        {/* Private */}

        <Route path={ROUTES.HOME} component={Home} />
        <ProtectedRoute
          allowedRoles={[ROLES.PATIENT, ROLES.PROVIDER, ROLES.ADMIN]}
          path={`${ROUTES.REPORTS}/:reportId`}
          component={ReportContainer}
        />
        <ProtectedRoute path={ROUTES.HISTORY} component={History} />
        <ProtectedRoute path={ROUTES.SEND_REPORT} component={SendEmail} />

        <ProtectedRouteAnonymous
          exact
          path={ROUTES.HOME_ANONYMOUS}
          component={Home}
        />
        <ProtectedRouteAnonymous
          path={`${ROUTES.REPORTS_ANONYMOUS}/:reportId`}
          component={ReportContainer}
        />
        <Route path={`${ROUTES.HISTORY_ANONYMOUS}/:pageNumber?`} component={HistoryAnonymous} />
        <ProtectedRouteAnonymous
          path={ROUTES.SEND_EMAIL_ANONYMOUS}
          component={SendEmail}
        />

        {/* Provider */}
        <ProtectedRoute
          exact
          allowedRoles={[ROLES.PROVIDER, ROLES.ADMIN]}
          path={ROUTES.PROVIDER}
          component={ClinicianHome}
          inboxTab={inboxTab}
        />
        <ProtectedRoute
          exact
          allowedRoles={[ROLES.PROVIDER, ROLES.ADMIN]}
          path={ROUTES.PROVIDER_ENROLL_PATIENT}
          component={EnrollPatient}
        />
        <ProtectedRoute
          allowedRoles={[ROLES.PROVIDER, ROLES.ADMIN]}
          path={`${ROUTES.PROVIDER_PATIENT_DETAILS}/:patientId`}
          component={PatientDetails}
        />
        {/* <Route path={ROUTES.PROVIDER_SIGN_UP} component={ProviderSignUp} /> */}
        <Route path={ROUTES.PROVIDER_SIGN_IN} component={ProviderSignIn} />

        {/* Account */}
        <ProtectedRoute
          allowedRoles={[ROLES.PATIENT, ROLES.PROVIDER, ROLES.ADMIN]}
          path={ROUTES.ACCOUNT}
          component={AccountPage} />

        <ProtectedRoute
          allowedRoles={[ROLES.PROVIDER]}
          path={ROUTES.ORGANIZATION_ADMIN}
          component={OrganizationAdmin} />

          {/* Admin */}
        <ProtectedRoute
          allowedRoles={[ROLES.ADMIN]}
          exact
          path={ROUTES.ADMIN}
          component={AdminHome} />
        <ProtectedRoute
          allowedRoles={[ROLES.ADMIN, ROLES.PROVIDER]}
          path={`/ticket/:ticketId`}
          component={TicketDetails} />
        <ProtectedRoute
          allowedRoles={[ROLES.PROVIDER, ROLES.ADMIN]}
          path={ROUTES.SCHEDULER_ANALYTICS}
          component={AnalyticsDashboard} /> 
        <ProtectedRoute
          allowedRoles={[ROLES.ADMIN]}
          path={`${ROUTES.ORGANIZATION_DETAILS}/:orgId`}
          component={OrganizationDetails} />

        <ProtectedRoute
          allowedRoles={[ROLES.PATIENT, ROLES.PROVIDER, ROLES.ADMIN]}
          debugOnly={false}
          path={"/debug"}
          component={DebugPage} />
        <Route
          allowedRoles={[ROLES.PATIENT, ROLES.PROVIDER, ROLES.ADMIN]}
          debugOnly={false}
          path={"/explore/:type"}
          component={ExploreQuestionnaire} />

          {/* Education */}
          <Route path={ROUTES.SIMPLE_ED_MODULE} component={SimpleModule} />

        {/*Redirect*/}
        <Redirect to="/" />
      </Switch>
      </Col>
      </Row>
    </Suspense>
    <GlobalAlerts/>
  </>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default connect(mapStateToProps)(App);
