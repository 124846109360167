import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isObject } from "../../utils";

export function determineSource (src, bodyType) {
  if (!isObject(src)) return src;
  if (bodyType && bodyType in src) return src[bodyType];
  if (src.default) return src.default;
  console.warn(`Dynamic image did not have a match for body type (${bodyType}) or default!`, src);
  return "";
} 

export const DynamicImage = ({src, style, alt, ...props}) => {
  const bodyType = useSelector(s => s.questionnaire.bodyTypePreference);
  const source = useMemo(() => determineSource(src, bodyType), [src, bodyType]);
  return <img alt={alt ? `${alt}${bodyType ? " (" + bodyType + ")" : ""}` : null} src={source} style={style} {...props} />
}