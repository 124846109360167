import React, {useState, useRef, useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import IdleTimer from 'react-idle-timer';
import Spinner from '../UI/Spinner/Spinner';
// import Alert from '../UI/Alert/Alert';
import * as ROUTES from '../../constants/routes';
import { ROLES } from '../../constants/roles';
import { logout } from "../../store/actions";

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isEmailLink,
  isLoading,
  roleIsAllowed,
  isAllowedByDebug,
  redirectPath,
  props,
  ...rest
}) => {

  const dispatch = useDispatch();
  const [idleTimer, setIdleTimer] = useState(null);
  const idleTimerRef = useRef(idleTimer);
  const [isTimeout, setIsTimeout] = useState(false);

  const onIdle = (e) => {
    // Set inactive to true
    setIsTimeout(true);
    dispatch(logout(true));
  }

  const allowed = (
    isAuthenticated
    && !isEmailLink
    && roleIsAllowed
    && isAllowedByDebug);

  return (
    <>
      {/* <IdleTimer
          ref={ref => { setIdleTimer(ref) }}
          element={document}
          onIdle={onIdle}
          debounce={250}
          timeout={1000 * 60 * 30} /> */}
      <Route
        {...rest}
        render={routeProps =>
          isLoading ? <Spinner/> : (
            allowed ? <Component {...props} {...routeProps}/>
            : <Redirect
                to={{
                  pathname: redirectPath,
                  from: routeProps.location,
                  isInactive: isTimeout
                }}
              />
          )
        }
      />
    </>
  )
};

function mapStateToProps(state, ownProps) {
  const allowedRoles = ownProps.allowedRoles || [ROLES.PATIENT];
  const isProvider = state.auth.user.role === ROLES.PROVIDER;
  return {
    redirectPath: isProvider ? ROUTES.PROVIDER_SIGN_IN : ROUTES.SIGN_IN,
    isAuthenticated: state.auth.isAuthenticated,
    isEmailLink: state.auth.isEmailLink,
    isLoading: state.auth.isLoading || state.provider.isLoading,
    roleIsAllowed: allowedRoles.includes(state.auth.user.role),
    isAllowedByDebug: !ownProps.debugOnly || state.session.debugMode,
    props: ownProps
  };
}

export default connect(mapStateToProps)(ProtectedRoute);
