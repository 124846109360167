import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from 'react';
import { FaRegClock } from 'react-icons/fa';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import MaskedInput from 'react-text-mask';
// import DatePicker from 'react-datepicker';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import { checkValidity } from './helpers';
import './Input.scss';


const formValidityMessages = (messages, viewErrors, viewSuccess) => {
  let content = (messages && messages.map) ? messages.map ( msg => ( (msg.pass && viewSuccess) || (!msg.pass && viewErrors))?  (
        <div key={msg.msg} className={msg.pass? "text-green pb-2": "text-red pb-2"}>
        {msg.pass? <IoMdCheckmark className="mr-2"/> : <IoMdClose className="mr-2" />}
        {msg.msg}
        <br/>
        </div>
  ) : '') : ''

  return (
    messages && messages.length > 0 && content?
      <div className='mt-3 mb-3'>
        {content}
      </div>
    : ''
  );
}

const formInfoMessages = (messages, viewInfo) => {

  let content = (messages && messages.map) ? messages.map ( msg => (
        viewInfo?
        <div key={msg.msg} className={msg.pass? "text-green pb-2": "text-grey pb-2"}>
        {msg.pass? <IoMdCheckmark className="mr-2"/> : <IoMdClose className="mr-2" />}
        {msg.msg}
        <br/>
        </div>
  : '')) : '';

  return (
    messages && messages.length > 0 && content?
      <div className='mt-3 mb-3'>
        {content}
      </div>
    : ''
  );
}

export const Input = ({
  id,
  name,
  elementType,
  type,
  label,
  labelClassName,
  labelAddendum,
  InputIcon,
  className,
  value,
  rows,
  options,
  required,
  rules,
  changed,
  onBlur,
  disabled,
  placeholder,
  viewErrors = false,
  viewSuccess = false,
  viewInfo = false,
  initialValidate = true
}) => {
  const [valid, setValid] = useState(initialValidate ? true : false);
  const [messages, setMessages] = useState([]);

  if (typeof changed !== "function") {
    console.error(`Input ${name || id} has no changed fn, or non-fn value!`);
    changed = () => {};
  }

  useEffect (() => {
      if (initialValidate) {

        const validity = checkValidity (
          value, // value
          rules // additional rules
        );

        setMessages(validity);
        const allValid = validity.every(v => v.pass);
        setValid(allValid);

        changed(value, allValid);
      }
  }, [initialValidate, value, rules]);

  const onChangeHandler = ( {target} ) => {
    const value = elementType === "checkbox" ? target.checked : target.value;
    const validity = checkValidity (
      value, // value
      rules
    );

    if (messages.length > 0) {
      setMessages(validity);
    }

    const allValid = validity.every(v => v.pass);
    setValid(allValid);
    // Pass value to parent.
    changed(value, allValid);
  }

  const datepickerChangeHandler = (date, value) => {
    const validity = checkValidity (
      value, // value
      rules // additional rules
    );

    setMessages(validity);
    const allValid = validity.every(v => v.pass);
    setValid(allValid);

    changed(value, allValid);
  }

  const timepickerChangeHandler = (time, value) => {
    const validity = checkValidity (
      value, // value
      rules // additional rules
    );

    setMessages(validity);
    const allValid = validity.every(v => v.pass);
    setValid(allValid);

    changed(time, allValid);
  }

  const onFocusHandler = ( {target} ) => {
    if (viewInfo){;
      const validity = checkValidity (
        target.value, // value
        rules // additional rules
      );

      setMessages(validity);
    }
  }

  const onBlurHandler = (event) => {
    const validity = checkValidity (
      event.target.value, // value
      rules // additional rules
    );

    setMessages(validity);

    const allValid = validity.every(v => v.pass);
    setValid(allValid);

    // Clear messages
    if (allValid) {
      setMessages([]);
    }

    if (onBlur) {
      onBlur(event, validity);
    }
  }

  let formElement= '';

  switch (elementType) {
    case 'textarea':
      formElement =
      <div className="form-group">
        {label? <label className={labelClassName}>{label} {required? <span className="text-danger">*</span> : ""}</label> : ""}
        <textarea
              key={id}
              name={name}
              style={{float: 'left', clear: 'left'}}
              className={`form-control ${className}`}
              type={type? type : 'text'}
              value={value}
              placeholder={placeholder}
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler}
              rows={rows}
              disabled={disabled}
             />
        {messages? (viewInfo? formInfoMessages(messages, viewInfo) : formValidityMessages(messages, viewErrors, viewSuccess) ) : ''}
      </div>
    break;

    case 'select':
      formElement =
        <div className="form-group">
          {label? <label className={labelClassName}>{label} {required? <span className="text-danger">*</span> : ""}</label> : ""}
          <select
                  key={id}
                  name={name}
                  className={`form-control ${className}`}
                  value={value}
                  disabled={disabled}
                  onChange={onChangeHandler}
                  onBlur={onBlurHandler}>
                  {options.map(option => (
                      <option key={option.value} value={option.value}>
                          {option.displayValue}
                      </option>
                  ))}
              </select>
          {messages?  (viewInfo? formInfoMessages(messages, viewInfo) : formValidityMessages(messages, viewErrors, viewSuccess) ) : ''}
        </div>
    break;

    // case 'radio':
    //   formElement =
    //     <div className="form-group">
    //     {label? <label className={labelClassName} style={{marginRight: '1rem'}}>{label} {required? <span className="text-danger">*</span> : ""}</label> : ""}
    //     <input
    //           name="checkbox"
    //           type="checkbox"
    //           className="checkbox mr-3"
    //           defaultChecked={false}
    //           onChange={onChangeHandler}/>

    //       {messages?  (viewInfo? formInfoMessages(messages, viewInfo) : formValidityMessages(messages, viewErrors, viewSuccess) ) : ''}
    //   </div>

    case 'checkbox':
      formElement =
       <div className="form-group">
        {label? <label className={labelClassName} style={{marginRight: '1rem'}}>{label} {required? <span className="text-danger">*</span> : ""}</label> : ""}
        <input
              name="checkbox"
              type="checkbox"
              className={`checkbox mr-3 ${className}`}
              checked={value}
              disabled={disabled}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}/>

          {messages?  (viewInfo? formInfoMessages(messages, viewInfo) : formValidityMessages(messages, viewErrors, viewSuccess) ) : ''}
      </div>
    break;

    case 'date':
      formElement =
        <div className="form-group">
            {label? <label className={labelClassName}>{label} {required? <span className="text-danger">*</span> : ""}</label> : ""}
            <div className={`input-group ${className}`}>
              {InputIcon? <div className="input-group-prepend">
                <span className="input-group-text" id="input_icon"><InputIcon /></span>
              </div> : ""}
             <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  clearable
                  format="MM/dd/yyyy"
                  fullWidth={true}
                  // TODO: set maxDate when needed, or possibly disableFuture?
                  value={value}
                  disabled={disabled}
                  onChange={datepickerChangeHandler}
                  onBlur={onBlurHandler}
                  placeholder="mm/dd/yyyy"
                  custominput={
                    <MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      keepCharPositions={true}
                      guide={true}
                    placeholder="mm/dd/yyyy"
                    />
                }/>
              </MuiPickersUtilsProvider>
             {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                 selected={value}
                 scrollableYearDropdown={true}
                showYearDropdown={true}
                showMonthDropdown={true}
                adjustDateOnChange={true}
                onChange={changed}
                value={value}
                placeholder={placeholder}
                dateFormat="MM/dd/yyyy"
                dropdownMode="select"
                shouldCloseOnSelect={false}
                customInput={
                  <MaskedInput
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  keepCharPositions={true}
                  guide={true}
                  placeholder="MM/dd/yyyy"
                />
              }/>
              </MuiPickersUtilsProvider> */}
            </div>
            {messages?  (viewInfo? formInfoMessages(messages, viewInfo) : formValidityMessages(messages, viewErrors, viewSuccess) ) : ''}
        </div>
    break;
    case 'time':
      formElement =
        <div className="form-group">
            {label? <label className={labelClassName}>{label} {required? <span className="text-danger">*</span> : ""}</label> : ""}
            <div className="input-group">
              {InputIcon? <div className="input-group-prepend">
                <span className="input-group-text" id="input_icon"><InputIcon /></span>
              </div> : ""}
             <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  clearable
                  format="hh:mm a"
                  fullWidth={true}
                  value={value}
                  disabled={disabled}
                  onChange={timepickerChangeHandler}
                  onBlur={onBlurHandler}
                  placeholder="--:-- --"
                  minutesStep={5}
                  keyboardIcon={<FaRegClock/>}
                />
              </MuiPickersUtilsProvider>
            </div>
            {messages?  (viewInfo? formInfoMessages(messages, viewInfo) : formValidityMessages(messages, viewErrors, viewSuccess) ) : ''}
        </div>
        break;
    case 'input':
    default:
      formElement = (
        <div className="form-group">
          {label? <label className={labelClassName}>{label} {required? <span className="text-danger">*</span> : ""}</label> : ""}
          <div className="input-group">
            {InputIcon? <div className="input-group-prepend">
              <span className="input-group-text" id="input_icon"><InputIcon /></span>
            </div> : ""}
            <input
                key={id}
                name={name}
                style={{float: 'left', clear: 'left'}}
                className={`
                  form-control
                  ${className}
                  ${messages.length > 0 && !viewInfo && !valid && viewErrors? "is-invalid" : ""}
                  ${valid && viewSuccess? "is-valid" : ""}`}
                type={type? type : 'text'}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                autoCorrect="off"
                // autocapitalize="off"
                //spellcheck="false"
                />
              </div>
          {messages?  (viewInfo? formInfoMessages(messages, viewInfo) : formValidityMessages(messages, viewErrors, viewSuccess) ) : ''}
        </div>
        )
      break;
  }

  return formElement;
};

export default Input;
