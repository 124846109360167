import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PROVIDER } from "../../constants/routes";
import { updateOrganizationSettings } from "../../store/actions";
import "../../styles/homepage.css";
import { Col, Row } from '../UI/Grid/Grid';
import Input from "../UI/Input/Input";



/**
 * Default value for settings when cleared or not loaded / set on server
 */
const DEFAULT_SETTINGS = {
  inactiveSessionTimeout: 60,
  maximumSessionTimeout: 600,
  sendAdminDigests: true
};

const OrganizationAdmin = () => {
  const org = useSelector(s => s.auth.organization || {});
  const dispatch = useDispatch();

  // whenever we get a new value for org, we update the local settings
  useEffect(() => {
    // TODO: what to do in the odd case that there are already changes when this
    // update comes in? shouldn't really be relevant with our current flow (as
    // the organization is only loaded as part of login...)
    setLocalSettings({...org.settings, ...DEFAULT_SETTINGS});
    setUnsavedChanges(false);
  }, [org])

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [localSettings, setLocalSettings] = useState({...DEFAULT_SETTINGS});
  function updateLocal (setting, value) {
    setLocalSettings({...localSettings, [setting]: value});
    setUnsavedChanges(true);
    setSubmissionStatus(null);
  }

  function submit () {
    dispatch(updateOrganizationSettings({organizationId: org.id, updates: localSettings})).then(
      s => {
        if (s.error) {
          setSubmissionStatus(s.error)
        } else {
          setSubmissionStatus(true);
        }
      });
  }
  
  return (
  <div className="home-container m-5">
    <Row className="justify-content-center">
      <Col className="col-lg-8">
        <div className="mb-2">
          {/* <Link to={PROVIDER} className="btn btn-outline">← Back to Clinician Home</Link> */}
        </div>
        <h1>Organization Settings</h1>
        <div>
          <Input
            id="inactiveTimeout"
            name="inactiveTimeout"
            type="number"
            elementType="input"
            label={"Inactive Session Timeout (minutes)"}
            placeholder="30"
            value={localSettings.inactiveSessionTimeout}
            changed={(value, valid) => updateLocal("inactiveSessionTimeout", value)}
            rules={{ }}
            initialValidate={false} />
          <Input
            id="maxTimeout"
            name="maxTimeout"
            type="number"
            elementType="input"
            label={"Maximum Session Timeout (minutes)"}
            placeholder="30"
            value={localSettings.maximumSessionTimeout}
            changed={(value, valid) => updateLocal("maximumSessionTimeout", value)}
            rules={{ }}
            initialValidate={false} />
          <Input
            id="sendDigest"
            name="sendDigest"
            elementType="checkbox"
            label={"Send Daily Digests to Organization Admins?"}
            value={localSettings.sendAdminDigests}
            changed={(value, valid) => updateLocal("sendAdminDigests", value)}
            rules={{ }}
            initialValidate={false} />
        </div>
        <div className="float-right">
        <button className="btn btn-success" onClick={submit} disabled={!unsavedChanges}>Save Changes</button>
        {submissionStatus === true ? <p className="text-success">Successfully changed!</p> : null}
        </div>
      </Col>
    </Row>
  </div>
  );
}
export default OrganizationAdmin;
