import { DataGrid, GridFooterContainer, GridPagination, GridToolbar, useGridSlotComponentProps } from '@mui/x-data-grid';
import { default as moment } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaArrowLeft, FaAsterisk, FaCalendar, FaChevronRight, FaCodeBranch, FaFileExport, FaGhost, FaPhoneSlash, FaRegCalendarTimes, FaRegClock, FaSearch, FaStar, FaStepBackward, FaUndoAlt, FaUser, FaUsers, FaUserSlash } from "react-icons/fa";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { EnglishNames } from "../../constants/locales";
import * as ROUTES from "../../constants/routes";
import { ScreenerBadgeClasses, ScreenerShortNames } from "../../constants/screenings";
import {
  ScreeningStatus, ScreeningStatusHR, UserClinicStatus
} from "../../database/model/user";
import {
  buildInviteUrl, changeUserOwner, createNewAccessKey, getAccessKey, getClinicianColleagues, getMessagesByManager, getMessagesByOrganization, getReportsByRequesterUpdates, getScheduledInvitesByManager, getScheduledInvitesByOrganization, getSpecificUser, getUsersForOrganization, getUsersForProvider, resetAccessKeyExpiration, sendAdditionalInviteMessage, splinterScheduledOccurence, switchProviderView,
  updateEnrollmentUser, updateScheduledInvite, updateUserNote
} from "../../store/actions";
import { getAllMessages, getAllScheduledInvites, newEnrollment } from '../../store/reducers/provider';
import { emulateIdentity } from '../../store/slices/admin';
import { getAllScreenings, getScreeningsByManager, getScreeningsByOrganization, updateScreening } from '../../store/slices/screenings';
import "../../styles/badge.css";
import "../../styles/clinician.scss";
import { capitalizeFirstLetter, deepCopy, isObject, pluralizeUnit, _switch } from "../../utils";
import { Fulfilled } from '../../utils/promises';
import { findNextTimeslot, hourDayToTimeslot, jumpByPeriod, PLURAL_DAY_NAMES, STANDARD_HOUR_OPTIONS } from '../../utils/time';
import CustomCalendar from '../UI/Calendar/Calendar';
import { alertEvent, confirmEvent, snack } from '../UI/GlobalAlerts';
import { Col, Row } from "../UI/Grid/Grid";
import Input from '../UI/Input/Input';

const ALLOW_UNIVERSAL_COLLEAGUE_BROWSING = true;

export const StatusBadgeClass = {
  [UserClinicStatus.Created]: "badge-primary",
  [UserClinicStatus.InviteSent]: "badge-primary",
  [UserClinicStatus.InviteReceived]: "badge-primary",
  [UserClinicStatus.AppOpened]: "badge-secondary",
  [UserClinicStatus.ReportReady]: "badge-success",
  [UserClinicStatus.NotFound]: "badge-danger",
  [UserClinicStatus.AppointmentCompleted]: "badge-success",
  [UserClinicStatus.AppointmentNoShow]: "badge-danger",
  [UserClinicStatus.Archived]: "badge-dark",
  undefined: "badge-danger",
};

const ContactTypeBadgeClass = {
  sms: "badge-dark",
  whatsapp: "badge-dark",
  email: "badge-dark",
};

export const DAY_ONLY_RELATIVE_FMT = {
  sameDay: "[Today] (MM/DD)",
  nextDay: "[Tomorrow] (MM/DD)",
  nextWeek: "dddd (MM/DD)",
  lastDay: "[Yesterday] (MM/DD)",
  lastWeek: "[Last] dddd (MM/DD)",
  sameElse: "MM/DD/YYYY",
};
export const ALWAYS_SHOW_TIME_FMT = {
  sameElse: "MM/DD/YYYY [at] h:mm a",
};

export const PROVIDER_DOCS_LINK = "https://docs.google.com/document/d/1SpQ_ZltM5STFzDPSfjTHgzUA58n8C7evI1lN9WFQAxw/edit?usp=sharing";

export const UserAppointment = (u) => <>
  {u.t_nextAppointment
    ? moment(u.t_nextAppointment).calendar(
        ALWAYS_SHOW_TIME_FMT
      )
    : u.d_nextAppointment
    ? moment(u.d_nextAppointment).calendar(
        DAY_ONLY_RELATIVE_FMT
      )
    : "none"}
  </>;


/**
 * @typedef {Object} InviteDialogData
 * @property {boolean} show
 * @property {boolean=} loading
 * @property {string=} lastName
 * @property {string=} target
 * @property {string=} type
 * @property {string=} status
 * @property {number=} statusUpdated
 * @property {string=} userId
 * @property {object=} invite
 * @property {string=} accessKey
 */
/**
 * @template {*} T
 * @typedef {[T, (next: T) => void]} StateHook
 */

const ClinicianHome = ({
  user,
  userId,
  loginTime,
  providerName,
  inboxTab = "received"
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // useEffect(() => {
  //   const unblock = history.block(transition => {
  //     dispatch({type: "update-search", payload: ""});
  //     unblock();
  //   });
  // }, [])
  /** @type {boolean} */
  const debugMode = useSelector(s => s.session.debugMode)
  /**
   * @type {[InviteDialogData, (next: InviteDialogData) => void]}
   */
  const [changeInviteDialog, setChangeInviteDialog] = useState({ show: false });

  const [dateFilterType, setDateFilterType] = useState("NATURAL");
  const [timeWindow, setTimeWindow] = useState(buildRangeAll());

  function titlecase (str) {
    if (typeof str !== "string") return str;
    return str.replace(/^[a-z]|\s[a-z]/g, inner => inner.toLocaleUpperCase());
  }

  const asOtherOrg = useSelector(s => s.admin.as.organizationId);
  const authUser = useSelector(s => s.auth.user);
  const organization = useSelector(s => s.auth.organization);
  const authOrgList = useSelector( s => Object.values(s.admin.organizations));
  const effectiveOrganization = asOtherOrg ? (authOrgList.find(o => o.id === asOtherOrg) || {}) : organization;
  // console.warn({effectiveOrganization})
  // console.warn({orgUsers: effectiveOrganization?.users})

  useEffect(() => {
    if (effectiveOrganization?.id && !effectiveOrganization.timezone?.name) {
      dispatch(snack("Your organization has no timezone data! Scheduling functions may not work.", undefined, {style: "error"}));
    }
  }, [organization]);

  function protocolLinkForInvite(invite) {
    switch (invite.type) {
      case "whatsapp":
        return `whatsapp:${invite.target}`;
      case "sms":
        return `sms:${invite.target}`;
      case "email":
        return `mailto:${invite.target}`;
      default:
        console.error(`Unknown invite type ${invite.type}`);
        return "#";
    }
  }

  function updateNotes(u) {
    if (!u.notes) {
      u.notes = {};
    }
    const newNote = window.prompt(
      `Enter the new note for ${u.lastName}`,
      u.notes[userId] || ""
    );
    if (newNote === null) return;
    u.notes[userId] = newNote;
    dispatch(updateUserNote(u.id, userId, { ...u.notes }));
  }
  function sendNewMessage (screeningId, userId) {
    const user = currentProviderUsers.find(u => u.id === userId);
    const screening = currentProviderScreenings.find(s => s.id === screeningId);
    const outgoing = currentProviderMessages.filter(m => m.screeningId === screeningId && m.DIR === "out").sort((a, b) => a.t_created - b.t_created);
    console.log(user);
    console.log(outgoing);
    const mostRecent = (outgoing.length === 0) ? {type: 'email', target: 'not_found@example.com'} : outgoing[outgoing.length - 1];
    const cid = {
      show: true,
      loading: false,
      lastName: screening.userLastName,
      type: mostRecent.method,
      target: mostRecent.method === "email" ? user.email : user.phone,
      status: screening.status,
      statusUpdated: screening.t_statusUpdated,
      userId: screening.userId,
      user,
      invite: screening,
      accessKey: screening.accessKey
    };
    setChangeInviteDialog(cid);
    dispatch(getAccessKey({id: screening.accessKey})).then(action => {
      setChangeInviteDialog({...cid, accessKey: action.payload});
    });
  }

  function dialogUpdate(field, value) {
    setChangeInviteDialog({
      ...changeInviteDialog,
      [field]: value,
    });
  }
  function sendDialogInvite() {
    if (changeInviteDialog.loading) return;
    dispatch(
      sendAdditionalInviteMessage(
        changeInviteDialog.user,
        changeInviteDialog.invite,
        changeInviteDialog.type,
        changeInviteDialog.target,
        true, // automatically creates new access keys if they are missing
        organization
      )
    ).then(_ => {
      setChangeInviteDialog({
        ...changeInviteDialog,
        loading: false,
        show: false
      });

      dispatch(alertEvent(`A new invite link has been sent to ${changeInviteDialog.target}`, "Resend Invite Successful"));
    }).catch(err => {
      setChangeInviteDialog({
        ...changeInviteDialog,
        loading: false,
        show: false
      });
      console.error(err);
      dispatch(alertEvent(`Could not send message: either the contact (${changeInviteDialog.target}) was invalid or the mail service was unavailable.`, "Resend Invite Failed"));
    })
    setChangeInviteDialog({
      ...changeInviteDialog,
      loading: true,
    });
  }

  function generateAccessKey(invite, userId, forModal) {
    dispatch(createNewAccessKey(userId, invite)).then(accessKey => {
      if (forModal) {
        setChangeInviteDialog({...changeInviteDialog, accessKey});
      }
    })
  }

  function extendAccessKey(accessKeyId, forModal) {
    dispatch(resetAccessKeyExpiration({id: accessKeyId})).then(action => {
      if (forModal) {
        setChangeInviteDialog({...changeInviteDialog, accessKey: {...changeInviteDialog.accessKey, t_expires: action.payload}});
      }
    });
  }

  function viewReportPageFor(report, launchPrintDialog = false) {
    if (typeof report === "string") {
      history.push(`${ROUTES.REPORTS}/${report}`, { reportId: report, launchPrintDialog });
    } else {
      if (typeof report?.id === "string") {
        history.push(`${ROUTES.REPORTS}/${report.id}`, { reportId: report.id, launchPrintDialog });
      } else {
        console.error(`Could not find id in ${report} (got ${report?.id})`);
      }
    }
  }

  function copyInviteLink(inviteId) {
    const screening = currentProviderScreenings.find(i => i.id === inviteId);
    if (!screening) throw new Error(`Cannot get URL for non-visibile invite ${inviteId}`);
    const url = buildInviteUrl(
      screening.accessKey,
      "direct_copy"
    );
    navigator.clipboard.writeText(url).then(
      () => console.log(`Invite URL was copied to clipboard:\n${url}`));
  }

  function createNewInvite(id) {
    let user = currentProviderUsers.find(u => u.id === id);
    let promise = (user === undefined) ? dispatch(getSpecificUser(id)) : Fulfilled(user);
    promise.then(u => {
      let appt = {};
      dispatch(updateEnrollmentUser({
        ...newEnrollment(),
        ...u,
        ...appt
      }));
      history.push(ROUTES.PROVIDER_ENROLL_PATIENT);
    })
  }

  const lastLoadedFor = useSelector(s => s.provider.userLoads);
  const recentlyViewed = useSelector(s => s.session.recentRecords);
  const recentlyViewed_Objects = useSelector(s => s.session.recentRecords.map(id => s.provider.users.find(u => u.id === id) || {error: "not found"}));
  const screeningsCompletedLastWeek = useSelector(s => {
    const all = getAllScreenings(s);
    return (all
      .filter(i => i.reportId && i.managerId === userId && moment(i.t_submitted).add(1, 'week').isAfter(Date.now()))
      .sort((a, b) => b.t_submitted - a.t_submitted));
  });
  const viewingUser = useSelector(s => s.provider.browsingOwnerId);
  const loadDataId = useMemo(() => {
    if (viewingUser === -1 || viewingUser === -2) {
      return effectiveOrganization.id;
    }
    return viewingUser;
  });
  const currentProviderUsers = useSelector(s => {
    const id = s.provider.browsingOwnerId;
    if (id === -1) {
      return s.provider.users.filter(u => !u.providerId);
    } else if (id === -2) {
      return s.provider.users.slice();
    } else {
      return s.provider.users.filter(u => u.providerId === id);
    }
  });
  const currentProviderScreenings = useSelector(s => {
    const screenings = getAllScreenings(s);
    const id = s.provider.browsingOwnerId;
    if (id === -1) {
      return screenings.filter(u => !u.managerId);
    } else if (id === -2) {
      return screenings.slice();
    } else {
      return screenings.filter(u => u.managerId === id);
    }
  });
  const currentProviderScheduledInvites = useSelector(s => {
    const schedules = getAllScheduledInvites(s);
    console.warn({schedules});
    console.warn({mgrIds: schedules.map(s => s.template.managerId)});
    const id = s.provider.browsingOwnerId;
    if (id === -1) {
      return schedules.filter(u => !u.template.managerId);
    } else if (id === -2) {
      return schedules.slice();
    } else {
      return schedules.filter(u => u.template.managerId === id);
    }
  });
  const filteredScheduledInvites = useMemo(() => {
    let result = currentProviderScheduledInvites;
    switch (inboxTab) {
      case "scheduled":
        result = result.filter(s => s.active);
        break;
      case "scheduled_onetime":
        result = result.filter(s => s.active && s.period === "ONCE");
        break;
      case "scheduled_recurring":
        result = result.filter(s => s.active && s.period !== "ONCE");
        break;
      case "scheduled_inactive":
        result = result.filter(s => !s.active);
        break;
      default:
        return [];
    }
    const filterByDate = _switch(dateFilterType,
      "APPT", s => hasAppointmentInWindow(s, true),
      "NATURAL", s => hasDateFieldInWindow(s, naturalFilter().field) || hasDateFieldInWindow(s.template, naturalFilter().field),
      s => hasDateFieldInWindow(s, dateFilterType)
    );
    return result.filter(filterByDate);
  }, [inboxTab, currentProviderScheduledInvites, dateFilterType, timeWindow]);
  const currentProviderMessages = useSelector(s => {
    const messages = getAllMessages(s);
    const id = s.provider.browsingOwnerId;
    if (id === -1) {
      return messages.filter(u => !u.managerId);
    } else {
      return messages.filter(u => u.managerId === id);
    }
  });

  // function nextSend (targetDay, targetHour, afterDate) {
  //   const date = moment().tz("America/Phoenix");
  //   const targetDayNumber = DAYS_OF_WEEK.indexOf(targetDay) || 0;
  //   if (date.day() >= targetDayNumber) { // TODO: handle same day correctly
  //     date.day(targetDayNumber + 7);
  //   } else {
  //     date.day(targetDayNumber);
  //   }
  //   date.startOf('hour');
  //   return date.hour(hourStringToInt(targetHour));
  // }

  // const MAX_RUNS = 25;
  // const currentProviderInviteOccurrences = useMemo(() => {
  //   const out = currentProviderScheduledInvites.flatMap(s => {
  //     if (s.period === "ONCE") {
  //       if (s.active) {
  //         return [s];
  //       } else {
  //         return [];
  //       }
  //     }
  //     // remaining are all recurring, which we project forward for the next 3 months
  //     const windowEnd = moment().add(3, "months");
  //     // our first occurrence is either:
  //     //   - the value of the nextSend timestamp, if it's in the future
  //     //   - one period after the nextSend timestamp, although this represents unclean data
  //     //   - the next valid timeslot for the day+hour preferences, if no nextSend is available
  //     let next = s.nextSend ?
  //       (s.nextSend > Date.now() ?
  //         moment(s.nextSend) :
  //         jumpByPeriod(effectiveOrganization.timezone.name, s.period, s.preferredDay, s.preferredHour, s.nextSend)) :
  //       findNextTimeslot(effectiveOrganization.timezone.name, s.period, s.preferredDay, s.preferredHour);
  //     if (s.valueOf() < Date.now()) return [];
  //     const occurrences = [];
  //     let i = 0;
  //     while (next.isBefore(windowEnd) && i++ < MAX_RUNS) {
  //       const dateText = next.tz(effectiveOrganization.timezone.name).format("YYYY-MM-DD");
  //       occurrences.push({
  //         ...s,
  //         _exception: s.exceptions?.includes(dateText),
  //         id: `${s.id}~~~~${i++}`,
  //         nextSend: next.clone(),
  //         _originalId: s.id,
  //         _isOccurrence: true
  //       });
  //       next = jumpByPeriod(effectiveOrganization.timezone.name, s.period, s.preferredDay, s.preferredHour, next);
  //     }
  //     return occurrences;
  //   });
  //   return out;
  // }, [currentProviderScheduledInvites]);

  function reloadCurrent (reason) {
    console.log(`Reloading clinician content for ${viewingUser} ${reason ? ": " + reason : ""}`);
    if (viewingUser === -1) {
      dispatch(getUsersForOrganization(effectiveOrganization.id));
      dispatch(getScreeningsByOrganization({organizationId: effectiveOrganization.id}));
      dispatch(getScheduledInvitesByOrganization(effectiveOrganization.id));
      dispatch(getMessagesByOrganization(effectiveOrganization.id));
    } else if (viewingUser === -2) {
      dispatch(getUsersForOrganization(effectiveOrganization.id));
      dispatch(getScreeningsByOrganization({organizationId: effectiveOrganization.id}));
      dispatch(getScheduledInvitesByOrganization(effectiveOrganization.id));
      dispatch(getMessagesByOrganization(effectiveOrganization.id));
    } else {
      dispatch(getUsersForProvider(viewingUser));
      dispatch(getScreeningsByManager(viewingUser));
      dispatch(getScheduledInvitesByManager(viewingUser));
      dispatch(getMessagesByManager(viewingUser));
    }
  }

  const RELOAD_FRESHNESS_MS = 120000;
  const REVISIT_FRESHNESS_MS = 60000;

  useEffect(() => {
    if (loadDataId) {
      if (!(loadDataId in lastLoadedFor)) {
        reloadCurrent("first load for user");
      } else if (Date.now() - lastLoadedFor[loadDataId] > REVISIT_FRESHNESS_MS) {
        reloadCurrent(`data not fresh enough returning to home [${Date.now() - lastLoadedFor[loadDataId] / 1000}s]`);
      }
    }
    const intervalId = setInterval(() => reloadCurrent(`scheduled refresh (${RELOAD_FRESHNESS_MS/1000}s)`), RELOAD_FRESHNESS_MS);
    return () => clearInterval(intervalId);
  }, [lastLoadedFor, loadDataId]);

  // useEffect(() => {
  //   if (user && !effectiveOrganization.loaded) {
  //     if (!user.organizationId) {
  //       throw new Error(`User logged in but has no organization ID!`);
  //     } else {
  //       dispatch(getUserOrganization(user.organizationId));
  //       dispatch(getClinicianColleagues(user.organizationId));
  //     }
  //   }
  // }, [organization, user]);

  useEffect(() => {
    let release = () => {};
    let result = dispatch(getReportsByRequesterUpdates(userId));
    console.warn(result);
    return () => release();
  }, []);

  const globalSearch = useSelector(s => s.provider.globalSearch);
  const [localSearch, setLocalSearch] = useState(globalSearch);
  useEffect(() => {
    if (localSearch !== globalSearch) {
      setLocalSearch(globalSearch);
    }
  }, [globalSearch]);

  function nextSendOrApproximate (timeObject, useTz) {
    if (!timeObject?.preferredDay || !timeObject?.preferredHour) {
      console.error("Time object did not have preferred day or hour", timeObject);
      return moment.invalid();
    };
    const mDate = timeObject.nextSend ? moment(timeObject.nextSend) : findNextTimeslot(effectiveOrganization.timezone.name, timeObject.preferredDay, timeObject.preferredHour);
    if (useTz) return mDate.tz(effectiveOrganization.timezone.name);
    return mDate.local();
  }

  const [ changeUserModal, setChangeUserModal] = useState(false);
  function changeViewingOwner (newUserId) {
    setChangeUserModal(false);
    dispatch(switchProviderView(newUserId));
  }

  const CLINICIAN_SCREENING_COLUMNS = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true
    },
    {
      field: 'readBy',
      headerName: 'Read?',
      hide: true,
      valueGetter: (params) => getReadStatus(params.row)
    },
    {
      field: 'name',
      headerName: "Patient",
      valueGetter: (params) => [params.row.userFirstName, params.row.userLastName].filter(x => x).join(" ")
    },
    {
      field: 'age',
      headerName: 'Age',
      valueGetter: (params) => `${moment().diff(params.row.userDOB, "years", false)} y.o.`,
      flex: 0.5
    },
    {
      field: 'appointment_dateString',
      hide: true,
      headerName: 'Appt',
      valueGetter: (params) => {
        // TODO: until the DB representation is updated or this data is pulled
        // via the "invite" object, getting appt data requires an additional
        // DB request...
        const timestamp = params.getValue(params.id, "t_appointment");
        const dateString = params.getValue(params.id, "d_appointment");
        if (timestamp){
          return moment(timestamp).format("YYYY-MM-DD");
        }
        if (dateString) {
          return moment(dateString).format("YYYY-MM-DD");
        }
        return null;
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      renderCell: params => (<>
        <span className={`badge ${ScreenerBadgeClasses[params.value] || "badge-primary"}`}>{ScreenerShortNames[params.value] || params.value}</span>
          {params.row.recurrenceIsActive ? <>&nbsp;&nbsp;<FaRegClock color="#E14798" className="float-right" title="Invite is part of a recurring series" /></> : null}
        </>)
    },
    {
      field: 'lang',
      headerName: 'Language',
      renderCell: params => <span className={`badge badge-light`}>{EnglishNames[params.value] || params.value}</span>,
      flex: 0.5
    },
    {
      field: 'recurrenceId',
      headerName: 'Recurrence ID',
      hide: true
    },
    {
      field: 'recurrenceIsActive',
      headerName: 'Active Recurrence',
      flex: 0.25,
      hide: true
    },
    {
      field: 't_submitted',
      headerName: 'Submitted',
      valueFormatter: ({value}) => {
        return value ? moment(value).format("MM/DD/YYYY h:mm a") : null;
      },
      hide: true,
    },
    {
      field: 't_lastSent',
      headerName: 'Last Sent',
      valueFormatter: ({value}) => {
        return value ? moment(value).format("MM/DD/YYYY h:mm a") : null;
      },
      renderCell: ({formattedValue, row}) => {
        return <>
          {formattedValue}
          {row.flags?.unacknowledgedFailure ? <FaPhoneSlash className="ml-2 text-danger" title="At least one message failed to send!"/> : null}
        </>;
      },
      hide: true
    },
    {
      field: 't_created',
      headerName: 'First Sent',
      valueFormatter: ({value}) => {
        return value ? moment(value).format("MM/DD/YYYY h:mm a") : null;
      },
      hide: true
    },
    {
      field: 't_inactive',
      headerName: 'Marked Inactive',
      valueFormatter: ({value}) => {
        return value ? moment(value).format("MM/DD/YYYY h:mm a") : null;
      },
      hide: true
    },
    {
      field: 't_statusUpdated',
      headerName: 'Last Status Update',
      valueFormatter: ({value}) => {
        return value ? moment(value).format("MM/DD/YYYY h:mm a") : null;
      },
      hide: true
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: params => ScreeningStatusHR[params.row.status] || params.row.status,
      hide: true
    },
    {
      field: 'answerCount',
      headerName: 'Answers',
      hide: true,
      valueGetter: params => params.getValue(params.id, "answers")?.length || 0
    },
    {
      field: 'outcomeCount',
      headerName: 'Outcomes',
      hide: true,
      valueGetter: params => params.getValue(params.id, "outcomes")?.length || 0
    },
    {
      field: 'actions',
      headerName: 'Actions',
      valueGetter: () => null,
      sortable: false,
      filterable: false,
      renderCell: params => <ActionMenu id={params.id} params={params}></ActionMenu>
    }
  ].map(def => ({flex: 1, ...def}));

  const SCHEDULED_INVITE_COLUMNS = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true
    },
    {
      field: 'name',
      headerName: "Patient",
      valueGetter: (params) => params.row.template.userLastName
    },
    {
      field: 'age',
      headerName: 'Age',
      valueGetter: (params) => `${moment().diff(params.row.template.userDOB, "years", false)} y.o.`,
      flex: 0.5,
      hide: true
    },
    {
      field: 'type',
      headerName: 'Type',
      valueGetter: params => params.row.template.type,
      // renderCell: params => (<>
      //   <span className={`badge badge-primary`}>{ScreenerShortNames[params.value] || params.value}</span>
      //     {params.row.recurrenceIsActive ? <FaRegClock className="float-right" title="Invite is part of a recurring series" /> : null}
      //   </>)
    },
    {
      field: 'lang',
      headerName: 'Language',
      valueGetter: params => params.row.template.lang,
      renderCell: params => <span className={`badge badge-light`}>{EnglishNames[params.value] || params.value}</span>,
      flex: 0.5
    },
    // {
    //   field: 'contact',
    //   headerName: 'Contact',
    //   valueGetter: params => `${params.row.contactType}: ${params.row.contactTarget}`
    // },
    {
      field: 'inviteMethods',
      headerName: 'Invite Methods',
      valueGetter: params => ['email', 'sms', 'whatsapp'].filter(m => params.row.inviteMethods?.[m]).join(", ")
    },
    {
      field: 'active',
      headerName: 'Active Recurrence',
      flex: 0.25,
      hide: true
    },
    // {
    //   field: 'period',
    //   headerName: 'Period',
    //   flex: 0.5,
    //   hide: true
    // },
    {
      field: 'periodAndActiveStatus',
      headerName: 'Frequency',
      valueGetter: params => `${params.row.period?.toLocaleLowerCase()}${params.row.active ? "" : " (COMPLETED)"}`,
      hide: true
    },
    {
      field: 'preferred time',
      headerName: 'Time',
      valueGetter: params => `${params.row.targetDate ?? params.row.preferredDay} at ${params.row.preferredHour}`
    },
    {
      field: 'nextSend',
      headerName: "Next (your TZ)",
      valueGetter: params => nextSendOrApproximate(params.row, false),
      valueFormatter: params => params.value.format("M/D/YY H:mma"),
      renderCell: params => {
        return <>
          {!params.row.active || params.row._exception ? <FaRegCalendarTimes title="This entry was cancelled" className="mr-2" /> : null}
          {params.row.derivedFromRecurrence ? <FaCodeBranch title="This one-time invite was rescheduled from a recurring invite" className="mr-2" /> : null}
          {(!!params.value) ?
            (params.row.active && (+params.value < Date.now()) ? <FaStepBackward title="This occurrence is marked active but is in the past. Verify that the system successfully sent it." className="mr-2" /> : null) :
            <FaAsterisk title={`This occurrence has incomplete scheduling data (${params.value}) but will still run as marked`} className="mr-2" />}
          {params.formattedValue}
        </>
      }
    },
    {
      field: 'nextSend_in_tz',
      headerName: "Next (org TZ)",
      hide: true,
      valueGetter: params => nextSendOrApproximate(params.row, true),
      valueFormatter: params => params.value.format("M/D/YY H:mma"),
      renderCell: params => {
        return <>
          {!params.row.active || params.row._exception ? <FaRegCalendarTimes title="This entry was cancelled" className="mr-2" /> : null}
          {(!!params.value) ?
            (params.row.active && (+params.value < Date.now()) ? <FaStepBackward title="This occurrence is marked active but is in the past. Verify that the system successfully sent it." className="mr-2" /> : null) :
            <FaAsterisk title={`This occurrence has incomplete scheduling data (${params.value}) but will still run as marked`} className="mr-2" />}
          {params.formattedValue}
        </>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      valueGetter: () => null,
      sortable: false,
      filterable: false,
      renderCell: params => <ActionMenuScheduled id={params.id} params={params}></ActionMenuScheduled>
    }
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   valueGetter: () => null,
    //   sortable: false,
    //   filterable: false,
    //   renderCell: params => <button onClick={() => openPatientDetails(params.row.template.userId)} className="btn btn-sm btn-primary">View patient</button>
    // }
  ].map(def => ({flex: 1, ...def}));

  const CLINICIAN_INBOX_COLUMNS = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true
    },
    {
      field: 'name',
      headerName: "Patient",
      valueGetter: (params) => [params.getValue(params.id, "firstName"), params.getValue(params.id, "lastName")].filter(x => x).join(" ")
    },
    {
      field: 'age',
      headerName: 'Age',
      valueGetter: (params) => `${moment().diff(params.getValue(params.id, "dob"), "years", false)} y.o.`,
      flex: 0.5
    },
    {
      field: 'nextAppointmentReadable',
      headerName: 'Appt',
      valueGetter: (params) => {
        const timestamp = params.getValue(params.id, "t_nextAppointment");
        const dateString = params.getValue(params.id, "d_nextAppointment");
        if (timestamp){
          return moment(timestamp).calendar(ALWAYS_SHOW_TIME_FMT);
        }
        if (dateString) {
          return moment(dateString).calendar(DAY_ONLY_RELATIVE_FMT);
        }
        return "-";
      }
    },
    {
      field: 'nextAppointment_dateString',
      hide: true,
      headerName: 'Appt',
      valueGetter: (params) => {
        const timestamp = params.getValue(params.id, "t_nextAppointment");
        const dateString = params.getValue(params.id, "d_nextAppointment");
        if (timestamp){
          return moment(timestamp).format("YYYY-MM-DD");
        }
        if (dateString) {
          return moment(dateString).format("YYYY-MM-DD");
        }
        return null;
      }
    },
    {
      field: 'screeningsUsed',
      headerName: 'Type',
      valueFormatter: params => Array.isArray(params.value) ? params.value.join(", ") : ""
    },
    {
      field: 'preferredLanguage',
      headerName: 'Language',
      renderCell: params => <span className={`badge badge-light`}>{EnglishNames[params.value] || params.value}</span>,
      flex: 0.5
    },
    {
      field: 'status',
      headerName: 'Status',
      hide: true,
      valueGetter: params => ScreeningStatusHR[params.row.status] || params.row.status,
      renderCell: params => <span className={`badge ${StatusBadgeClass[params.value]}`}>{params.value}</span>
    },
    {
      field: 't_statusUpdated',
      headerName: 'Last Update',
      valueFormatter: ({value}) => value ? moment(value).format("M/D/YY h:mm a") : ""
    },
    {
      field: 'reportCount',
      headerName: 'Report Count',
      hide: true,
      valueGetter: params => params.getValue(params.id, "reports")?.length || 0
    },
    {
      field: 'quickOpen',
      headerName: 'Quick Open',
      valueGetter: () => null,
      renderCell: params => <button className="btn btn-sm btn-success" onClick={() => openPatientDetails(params.id)}><FaFileExport/> View</button>,
      flex: 0.5
    }
  ].map(def => ({flex: 1, ...def}));

  function openPatientDetails (userId) {
    history.push(`${ROUTES.PROVIDER_PATIENT_DETAILS}/${userId}`);
  }

  function markAllInactive (ids) {
    // TODO: change this to be batch operation!
    ids.forEach(id => {
      dispatch(updateScreening({id, changes: {status: ScreeningStatus.Inactive, t_inactive: Date.now()}}));
    });
  }
  function markAllActive (ids) {
    // TODO: change this to be batch operation!
    ids.forEach(id => {
      let screening = currentProviderScreenings.find(i => i.id === id);
      if (!screening)
        throw new Error(`Tried to update screening not present in provider list! ${id}`);
      const newStatus = screening.reportId ? ScreeningStatus.ReportReady : ScreeningStatus.InviteSent;
      dispatch(updateScreening({id, changes: {status: newStatus}}));
    });
  }

  const [reassignModal, setReassignModal] = useState(false);

  const UserTableFooter = () => {
    const { state, rows } = useGridSlotComponentProps();
    // const selected = state.selection?.map(id => rows.find(r => r.id === id)) || [];
    return (
      <>
    <GridFooterContainer>
      {/* <Slide direction="up" in={selected.length === 1} mountOnEnter unmountOnExit>
        <h4>{selected?.[0]?.lastName}</h4>
      </Slide> */}
      <div className="MuiDataGrid-rowCount">
        <em className="mr-4">{pluralizeUnit(state.selection?.length, "patient", null, {0: "No"})} selected</em>
        <button className='btn btn-outline btn-sm' disabled={!state.selection?.length} onClick={() => setReassignModal(state.selection)}>Assign To..</button>
      </div>
      <span className="text-muted">
        Current as of {(loadDataId in lastLoadedFor) ? moment(lastLoadedFor[loadDataId]).format("h:mm:ss a") : <em>never</em>}
        <FaUndoAlt onClick={() => reloadCurrent("manual user request")} style={{marginLeft: "8px", marginRight: "-4px", cursor: "pointer"}} /> {/* color="#339499"  */}
      </span>
      <GridPagination/>
    </GridFooterContainer>
    </>
  )};

  const ScreeningTableFooter = () => {
    const { state, rows } = useGridSlotComponentProps();
    // const selected = state.selection?.map(id => rows.find(r => r.id === id)) || [];
    return (
      <>
    <GridFooterContainer>
      {/* <Slide direction="up" in={selected.length === 1} mountOnEnter unmountOnExit>
        <h4>{selected?.[0]?.lastName}</h4>
      </Slide> */}
      <div className="MuiDataGrid-rowCount">
        <span className="mr-4">{pluralizeUnit(state.selection?.length, "screening", null, {0: "No"})} selected</span>
        {inboxTab === 'inactive' ?
          <button className='btn btn-outline btn-sm' disabled={!state.selection?.length} onClick={() => markAllActive(state.selection)}>De-archive</button>
        :
          <button className='btn btn-outline btn-sm' disabled={!state.selection?.length} onClick={() => markAllInactive(state.selection)}>Archive</button>
        }
        <button className='btn btn-outline btn-sm ml-2' disabled={!state.selection?.length} onClick={() => setReassignModal(state.selection)}>Assign to...</button>
      </div>
      <span className="text-muted">
        Current as of {(loadDataId in lastLoadedFor) ? moment(lastLoadedFor[loadDataId]).format("h:mm:ss a") : <em>never</em>}
        <FaUndoAlt onClick={() => reloadCurrent("manual user request")} style={{marginLeft: "8px", marginRight: "-4px", cursor: "pointer"}} /> {/* color="#339499"  */}
      </span>
      <GridPagination/>
    </GridFooterContainer>
    </>
  )};
  const ScheduledInvitesFooter = () => {
    const { state, rows } = useGridSlotComponentProps();
    // const selected = state.selection?.map(id => rows.find(r => r.id === id)) || [];
    return (
      <>
    <GridFooterContainer>
      {/* <Slide direction="up" in={selected.length === 1} mountOnEnter unmountOnExit>
        <h4>{selected?.[0]?.lastName}</h4>
      </Slide> */}
      <div className="MuiDataGrid-rowCount">
        <span className="mr-4">{pluralizeUnit(state.selection?.length, "screening", null, {0: "No"})} selected</span>
        {/* {inboxTab === 'inactive' ?
          <button className='btn btn-outline btn-sm' disabled={!state.selection?.length} onClick={() => markAllActive(state.selection)}>Reset to Active</button>
        :
          <button className='btn btn-outline btn-sm' disabled={!state.selection?.length} onClick={() => markAllInactive(state.selection)}>Mark Inactive</button>
        }
        <button className='btn btn-outline btn-sm' disabled={!state.selection?.length} onClick={() => setReassignModal(state.selection)}>Assign To..</button> */}
      </div>
      <span className="text-muted">
        Current as of {(loadDataId in lastLoadedFor) ? moment(lastLoadedFor[loadDataId]).format("h:mm:ss a") : <em>never</em>}
        <FaUndoAlt onClick={() => reloadCurrent("manual user request")} style={{marginLeft: "8px", marginRight: "-4px", cursor: "pointer"}} /> {/* color="#339499"  */}
      </span>
      <GridPagination/>
    </GridFooterContainer>
    </>
  )};

  const SORTS_FOR_TABS = {
    received: [{field: 't_submitted', sort: 'desc'}],
    pending: [{field: 't_lastSent', sort: 'desc'}],
    inactive: [{field: 't_inactive', sort: 'desc'}],
    all: [{field: 't_statusUpdated', sort: 'desc'}],
    scheduled: [{field: 'nextSend', sort: 'asc'}],
    scheduled_occurrences: [{field: 'nextSend', sort: 'asc'}],
    scheduled_onetime: [{field: 'nextSend', sort: 'asc'}],
    scheduled_recurring: [{field: 'nextSend', sort: 'asc'}]
  }

  const [sortModels, setSortModels] = useState({...SORTS_FOR_TABS});
  /**
   * 
   * @param {'received'|'pending'|'inactive'|'all'|'PT_all'|'scheduled'|'scheduled_occurrences'|'scheduled_onetime'|'scheduled_recurring'} newTab 
   */
  // function setInboxTab (newTab) {
  //   updateSortModel(SORTS_FOR_TABS[newTab]);
  //   setInboxTab_raw(newTab);
  // }
  function updateSortModel (model) {
    if (JSON.stringify(model) !== JSON.stringify(sortModels[inboxTab])) {
      console.warn({model});
      // setSortModel(model);
      setSortModels({
        ...sortModels,
        [inboxTab]: deepCopy(model)
      });
    }
  }

  // TODO: make this a component
  function buildRangeAll () {
    return {start: moment("1900-01-01"), end: moment().add(2, "years").endOf("year").valueOf(), unit: "ALL"};
  }
  function buildRange (unit, anchor) {
    return {start: moment(anchor).startOf(unit).valueOf(), end: moment(anchor).endOf(unit).valueOf(), unit};
  }
  const STEPPABLE_TIME_UNITS = ["year", "month", "week", "day"];
  function stepTimeWindow (forward = true) {
    if (!STEPPABLE_TIME_UNITS.includes(timeWindow.unit)) {
      return dispatch(snack(
        `Cannot move to ${forward ? "next" : "previous"} date range when unit type is ${timeWindow.unit}`,
        undefined,
        {style: "error"}));
    }
    const newAnchor = moment(forward ? timeWindow.end : timeWindow.start).add((forward ? 1 : -1), "minute");
    const range = buildRange(timeWindow.unit, newAnchor);
    setTimeWindow(range)
  }
  function timeWindowUnitName (prefix, nonSteppableLabel) {
    if (timeWindow && timeWindow.unit !== "CUSTOM" && timeWindow.unit !== "ALL") {
      return `${prefix}${capitalizeFirstLetter(timeWindow.unit)}`;
    }
    return nonSteppableLabel || "Period";
  }

  function hasAppointmentOn (x, compareDate, useNext = true) {
    const [timeField, dateField] = useNext ? ['t_nextAppointment', 'd_nextAppointment'] : ['t_appointment', 'd_appointment'];
    if (!x[timeField] && !x[dateField]) return false;
    let apptDate = moment(x[timeField] || x[dateField]);
    return apptDate.isSame(moment(compareDate), "day");
  }
  function hasAppointmentInWindow (x, useNext = true) {
    const [timeField, dateField] = useNext ? ['t_nextAppointment', 'd_nextAppointment'] : ['t_appointment', 'd_appointment'];
    if (!x[timeField] && !x[dateField]) return false;
    let apptDate = moment(x[timeField] || x[dateField]);
    console.log(`${x.id} ${apptDate}`);
    return apptDate >= timeWindow.start && apptDate < timeWindow.end;
  }
  // const FIELD_ALTERNATIVES = {};
  function hasDateFieldInWindow (x, field) {
    if (!Number.isInteger(x[field])) return false;
    let date = moment(x[field]);
    return date >= timeWindow.start && date < timeWindow.end;
  }

  const filteredUsers = useMemo(() => {
    if (!Array.isArray(currentProviderUsers)) {
      console.error(`Current provider users wasn't an array, found ${currentProviderUsers} (${typeof currentProviderUsers})`);
      return [];
    }
    const filterByDate = _switch(dateFilterType,
      "APPT", s => hasAppointmentInWindow(s),
      "NATURAL", s => hasDateFieldInWindow(s, naturalFilter().field),
      s => hasDateFieldInWindow(s, dateFilterType)
    );
    return currentProviderUsers.filter(u => {
      // if (!tabFilter(u)) return false;
      if (localSearch && !(u.lastName || "").toLocaleLowerCase().includes(localSearch?.toLocaleLowerCase())) return false
      if (timeWindow.unit === "ALL") return true;
      return filterByDate(u);
    });
  }, [currentProviderUsers, localSearch, inboxTab, timeWindow, dateFilterType]);

  const CONTEXTUAL_DATE_COLUMN = {
    'received': 't_submitted',
    'pending': 't_lastSent',
    'inactive': 't_inactive',
    'all': 't_statusUpdated'
  };
  const MEMOIZED_COL_DEFS = {all: []};
  for (let tab of ['received', 'pending', 'inactive', 'all']) {
    const colDef = CLINICIAN_SCREENING_COLUMNS.map(o => ({...o}));
    const dateCol = CONTEXTUAL_DATE_COLUMN[tab];
    (colDef.find(c => c.field === dateCol)).hide = false;
    MEMOIZED_COL_DEFS[tab] = colDef;
  }
  
  // useEffect(() => {
  //   const defs = MEMOIZED_COL_DEFS[inboxTab];
  //   if (defs) {
  //     const filterColField = dateFilterType === "NATURAL" ? naturalFilter().field :
  //     (dateFilterType === "APPT" ? "t_appointment" : dateFilterType); // TODO: handle d_appointment
  //     const filterColDef = defs.find(c => c.field === filterColField);
  //     console.log({filterColField, filterColDef});
  //     if (filterColDef && filterColDef.hide === true) {
  //       MEMOIZED_COL_DEFS[inboxTab] = defs.map(c => {
  //         if (c === filterColDef) {
  //           c.hide = false;
  //           c._showingDueToFilter = true;
  //         } else if (c._showingDueToFilter && !c.hide) {
  //           c.hide = true;
  //         }
  //         return {...c};
  //       });
  //     }
  //   }
  // }, [inboxTab, dateFilterType])


  const filteredScreenings = useMemo(() => {
    if (!Array.isArray(currentProviderScreenings)) {
      console.error(`Current provider screenings wasn't an array, found ${currentProviderScreenings} (${typeof currentProviderScreenings})`);
      return [];
    }
    // TODO: these filters won't work with reports as-is, we need to update DB representation
    const tabFilter = _switch(inboxTab,
      "received", i => i.status === ScreeningStatus.ReportReady,
      "pending", i => [ScreeningStatus.InviteSent, ScreeningStatus.InviteOpened, ScreeningStatus.Started].includes(i.status),
      "inactive", i => i.status === ScreeningStatus.Inactive,
      i => i);
    const filterByDate = _switch(dateFilterType,
      "APPT", s => hasAppointmentInWindow(s, false),
      "NATURAL", s => hasDateFieldInWindow(s, naturalFilter().field),
      s => hasDateFieldInWindow(s, dateFilterType)
    );
    // if (dateFilterType === "APPT") debugger;
    return currentProviderScreenings.filter(s => {
      if (!tabFilter(s)) return false;
      if (localSearch && !(s.userLastName || "").toLocaleLowerCase().includes(localSearch?.toLocaleLowerCase())) return false
      return filterByDate(s);
    });
  }, [currentProviderScreenings, localSearch, inboxTab, timeWindow, dateFilterType]);

  function reassignGroupTo (ids, providerId) {
    if (inboxTab === 'PT_all') {
      ids.forEach(userId => dispatch(changeUserOwner({userId, providerId})));
    } else {
      ids.forEach(screeningId => dispatch(updateScreening({id: screeningId, changes: {managerId: providerId}})));
    }
    setReassignModal(false);
  }

  function getReadStatus (screening) {
    return (isObject(screening.readBy) && userId in screening.readBy) ? screening.readBy[userId] : false;
  }
  function getReadStatusRowStyle (rowParams) {
    return (getReadStatus(rowParams.row) ? "read" : "unread");
  }
  function getScheduledRowStyle (rowParams) {
    return (rowParams.row._exception || !rowParams.row.active) ? "bg-light text-muted font-italic" : ((!!rowParams.row.nextSend && rowParams.row.nextSend < Date.now()) ? "bg-light text-danger font-italic" : "");
  }

  function toggleReadStatus (id, forceState = undefined) {
    const screening = currentProviderScreenings.find(i => i.id === id);
    const newStatus = (forceState !== undefined) ? forceState : !getReadStatus(screening);
    const newFlags = {...screening.readBy, [userId]: newStatus};
    dispatch(updateScreening({id, changes: {readBy: newFlags}}));
  }

  function InboxToolbar () {
    return (<>
      {debugMode ? <GridToolbar/> : null}
    </>);
  }

  function viewScreeningReport ({id, reportId}, printFlag = false) {
    toggleReadStatus(id, true);
    viewReportPageFor(reportId, printFlag);
  }

  const ActionMenu = ({id, params}) => {
    let val = "choose";
    function click (option, event) {
      switch (option) {
        case "choose":
          return;
        case "view report":
          viewScreeningReport(params.row);
          break;
          case "print report":
          viewScreeningReport(params.row, true);
          break;
        case "view patient":
          if (inboxTab === 'PT_all') {
            openPatientDetails(id);
          } else {
            toggleReadStatus(id, true);
            openPatientDetails(params.row.userId);
          }
          break;
        case "new message":
          sendNewMessage(id, params.row.userId);
          break;
        case "modify details":
          alert(`Cannot find details for screening ${id}`);
          break;
        case "new screening":
          if (inboxTab === 'PT_all') {
            createNewInvite(id);
          } else {
            createNewInvite(params.row.userId);
          }
          break;
        // case "cancel request":
        //   return cancelAndInvalidateKey(id);
        case "copy invite url":
          copyInviteLink(id);
          break;
        case "mark inactive":
          markAllInactive([id]);
          break;
        case "mark active":
          markAllActive([id]);
          break;
        case "reassign":
          setReassignModal([id]);
          break;
        case "toggle read status":
          return toggleReadStatus(id);
        default:
          alert(`Unknown option ${option}!`);
      }
      val = "choose";
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    return (<>
      <select className="form-control" onClick={e => e.stopPropagation()} onChange={evt => click(evt.target.value, evt)} value={val}>
        <option value="choose">Choose...</option>
        {inboxTab === 'received' ? <option value="view report">View Report</option> : null}
        {inboxTab === 'received' ? <option value="print report">Print Report</option> : null}
        {true ? <option value="view patient">View patient</option> : null}
        {inboxTab !== 'PT_all' ? <option value="new message">Send New Message</option> : null}
        {inboxTab !== 'PT_all' ? <option disabled value="modify details">Change Screening</option> : null}
        {true ? <option value="new screening">Start New Screening</option> : null}
        {inboxTab === 'pending' && false ? <option value="cancel request">Cancel Request</option> : null}
        {inboxTab === 'pending' ? <option value="copy invite url">Copy Invite URL</option> : null}
        {inboxTab === 'pending' || inboxTab === 'received' ? <option value="mark inactive">Move to Inactive</option> : null}
        {inboxTab === 'inactive' ? <option value="mark active">Move to Active</option> : null}
        {inboxTab !== 'PT_all' ? <option value="reassign">Reassign to...</option> : null}
        {true ? <option value="toggle read status">Mark Read/Unread</option> : null}
      </select>
      {/* <div class="dropdown">
        <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Actions
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </div> */}
    </>);
  }

  const [modifyScheduledInviteModal, setModifyScheduledInvite] = useState(null);
  const [rescheduling, setRescheduling_raw] = useState({date: moment()});
  function setRescheduleData (update) {
    console.warn(rescheduling, update);
    setRescheduling_raw({...rescheduling, ...update});
  }

  function rescheduleInvite (row) {
    const originalId = row._originalId || row.id;
    if (row.period === "ONCE") {
      const nextSend = hourDayToTimeslot(rescheduling.date, effectiveOrganization.timezone?.name, rescheduling.preferredHour);
      const preferredDay = PLURAL_DAY_NAMES[nextSend.day()];
      const preferredHour = nextSend.format("ha");
      console.log(preferredHour);
      dispatch(updateScheduledInvite(originalId, {
        nextSend: nextSend.valueOf(),
        preferredDay,
        preferredHour
      }, userId)).then(() => setModifyScheduledInvite(null));
    } else {
      dispatch(splinterScheduledOccurence(
        originalId,
        row,
        {...rescheduling, timezone: effectiveOrganization.timezone.name},
        userId
      )).then(() => setModifyScheduledInvite(null));
    }
  }

  const ActionMenuScheduled = ({id, params}) => {
    function click (option, event) {
      switch (option) {
        case "view patient":
          openPatientDetails(params.row.template.userId);
          break;
        case "cancel series":
          dispatch(confirmEvent(
            updateScheduledInvite(originalId, {active: false}, userId),
            "Confirm Deletion",
            params.row.period === "ONCE" ?
              `Are you sure you want to delete this one-time scheduling?` :
              `Are you sure you want to delete this recurring invite (occurs every ${params.row.period})?`));
          break;
        case "cancel occurrence":
          if (!params.row._isOccurrence) {
            console.error(params.row);
            throw new Error("Cannot cancel occurence, unexpected structure");
          }
          const exceptions = Array.isArray(params.row.exceptions) ? params.row.exceptions.slice() : [];
          const nextSend = nextSendOrApproximate(params.row, true);
          exceptions.push(nextSend.format("YYYY-MM-DD"));
          dispatch(confirmEvent(
            updateScheduledInvite(originalId, {exceptions}, userId),
            `Confirm Deletion`,
            `Are you sure you want to remove this date (${nextSend.format("ddd, MMM Do, YYYY")}) from the series?`));
          break;
        case "reschedule":
          setRescheduleData({
            date: params.row.nextSend || findNextTimeslot(effectiveOrganization.timezone?.name, params.row.preferredDay, params.row.preferredHour),
            preferredHour: params.row.preferredHour
          });
          setModifyScheduledInvite({...params.row, originalId, fromRecurrence: params.row.period !== "ONCE"});
          break;
        default:
          console.error("Unknown option: " + option);
      }
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    let val = "choose";
    let inactive = !params.row.active || params.row._exception;
    let originalId = params.row._originalId || id;
    return <>
      <select className="form-control" onClick={e => e.stopPropagation()} onChange={evt => click(evt.target.value, evt)} value={val}>
        <option value="choose">Choose...</option>
        <option value="view patient">View patient</option>
        {!inactive ? <option value="cancel series">Cancel {params.row.period === "ONCE" ? "One-Time Invite" : "Series"}</option> : null}
        {(!inactive && params.row._isOccurrence) ? <option value="cancel occurrence">Cancel Occurrence</option> : null}
        {!inactive && (params.row.period === "ONCE" || params.row._isOccurrence) ? <option value="reschedule">Reschedule {params.row.period === "ONCE" ? "One-Time Invite" : "Occurrence"}</option> : null}
      </select>
    </>;
  }

  const SHORT_IN_WEEK_FORMAT = {
    sameDay: "H:mma [today]",
    lastDay: "H:mma [yesterday]",
    lastWeek: "H:mma ddd",
  }

  const unreadReportCount = screeningsCompletedLastWeek.filter(s => !getReadStatus(s)).length;

  function switchAdminOrgView (event) {
    const organizationId = event.target.value;
    if (!organizationId) return;
    if (organizationId === organization.id) {
      dispatch(emulateIdentity(null));
      if (!organization.users) {
        dispatch(getClinicianColleagues(organizationId));
      }
    } else {
      dispatch(emulateIdentity({organizationId}));
    }
    // TODO: reset filter
  }

  const TIME_WINDOW_CAL_FORMAT = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd (MM/DD)',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd (MM/DD)',
    sameElse: 'MM/DD/YYYY'
};
  const DATE_FILTER_TARGET_OPTIONS = "send+appt";

  const [showCustomRangeModal, setShowCustomRangeModal] = useState(false);
  const [customRangeDisplay, setCustomRangeDisplay] = useState({from: moment(), to: moment()});
  function openCustomDateModal () {
    setShowCustomRangeModal(true);
    setCustomRangeDisplay({from: moment(timeWindow.from), to: moment(timeWindow.to)})
  }
  function recordCustomRangeInput (from, to) {
    if (from) {
      setCustomRangeDisplay({...customRangeDisplay, from});
    } else if (to) {
      setCustomRangeDisplay({...customRangeDisplay, to});
    }
  }
  function acceptCustomRange() {
    setTimeWindow({start: moment(customRangeDisplay.from).valueOf(), end: moment(customRangeDisplay.to).valueOf(), unit: "CUSTOM"});
    setShowCustomRangeModal(false);
  }

  function dateFilterName (filterType = dateFilterType) {
    if (filterType === "NATURAL") return naturalFilter().label;
    if (filterType === "APPT") {
      if (inboxTab.startsWith("scheduled")) {
        return "Next Appt.";
      } else {
        return "Appt. Date"
      }
    }
    return `(${filterType})`;
  }

  function naturalFilter () {
    switch (inboxTab) {
      case 'received':
        return {label: "Received", field: 't_submitted'};
      case 'pending':
        return {label: "Sent", field: "t_lastSent"};
      case 'inactive':
        return {label: "Archived", field: 't_inactive'};
      case 'scheduled':
      case 'scheduled_onetime':
      case 'scheduled_recurring':
      case 'scheduled_occurrences':
        return {label: "for", field: "nextSend"};
      case 'scheduled_inactive':
        // return {label: "Last Sent", field: "t_lastSent"};
      case 'all':
      case 'PT_all':
      default:
        return {label: "Last Status Change", field: "t_statusUpdated"};
    }
  }
  function hasAllDateOptions () {
    return ["PT_all", "all"].includes(inboxTab);
  }
  useEffect(() => {
    if (!hasAllDateOptions() && !["APPT", "NATURAL"].includes(dateFilterType)) {
      setDateFilterType("NATURAL");
    }
  }, [inboxTab])

  function changeSearch (val) {
    dispatch({type: "update-search", payload: val});
  }

  const BREADCRUMBS = {
    'received': "Ready",
    'pending': "Awaiting Response",
    'inactive': "Expired / Archived",
    'scheduled': "Scheduled",
    'scheduled_occurrences': "UNUSED",
    'all': "All",
    'PT_all': "All",
    "PT_inactive": "Inactive",
    "scheduled_onetime": "Scheduled (one-time only)",
    "scheduled_recurring": "Scheduled (recurring)",
    "scheduled_inactive": "Scheduled (inactive / cancelled)"
  }
  function tabNoun (tab, plural = false) {
    if (["PT_all", "PT_inactive"].includes(tab)) return ("Patient" + (plural ? "s" : ""));
    return ("Health Report" + (plural ? "s" : ""));
  }
  function breadcrumbForTab (tab) {
    if (tab in BREADCRUMBS) return BREADCRUMBS[tab];
    return "«unknown»"
  }

  const providerV6 = (
    <>
    <div className="clinician-main">
      <div className="px-5 py-3 my-3">
        { authUser.role === "Admin" ? <Row className="mb-2 align-items-center" style={{opacity: "70%", backgroundColor: "#EEFFDD", padding: "6px", borderRadius: "4px"}} title="This is a superadmin-only interface">
          <Col>
            <Link to={ROUTES.ADMIN} className="link"><FaArrowLeft/> Back to admin panel</Link>
          </Col>
          <Col className="text-right">
            <label>Viewing org: </label>
          </Col>
          <Col>
            <select className="form-control" value={asOtherOrg} onChange={switchAdminOrgView}>
              {authOrgList.map(o => <option value={o.id}>{o.name}{o.id === organization.id ? " (your org)" : ""}</option>)}
            </select>
          </Col>
        </Row> : null }
        <Row className="mb-2 align-items-center">
          <Col className="">
            {(ALLOW_UNIVERSAL_COLLEAGUE_BROWSING && authUser.role === "Provider") || authUser.isManager || authUser.role === "Admin" ? 
              <h3 className="heading2 mb-2">
              {(viewingUser === authUser.uid) ? "Welcome, " : `Viewing ${viewingUser < 0 ? "" : "as "}`}
              <span className="in-text-select" onClick={() => setChangeUserModal(true)}>
                  {(viewingUser === authUser.uid) ?
                  providerName
                :
                  (viewingUser === -1 ? "unassigned records" : (viewingUser === -2 ? " entire organization" : effectiveOrganization.users?.find?.(u => u.id === viewingUser)?.name))
                }
              </span>{(viewingUser === authUser.uid) ? "!" : ":"}</h3>
            :
              <h3 className="heading2 mb-2">Welcome, {providerName}!</h3>
            }
            <div>
              <h3 className="mt-4">
                <span>{tabNoun(inboxTab)}</span>
                <span className="mx-2"><FaChevronRight/></span>
                <span style={{fontWeight: "normal"}}>
                  <span>{breadcrumbForTab(inboxTab)}</span>
                  <span className="mx-2"><FaChevronRight/></span>
                  <select className="mr-2 in-text-select" onChange={(e) => setDateFilterType(e.target.value)}>
                    {hasAllDateOptions() ?
                      <>
                        <option selected={dateFilterType === "NATURAL"} value="NATURAL">{dateFilterName("NATURAL")}</option>
                        <option selected={dateFilterType === "APPT"} value="APPT">{dateFilterName("APPT")}</option>
                        <option selected={dateFilterType === "t_submitted"} value="t_submitted">Report Received</option>
                        <option selected={dateFilterType === "t_lastSent"} value="t_lastSent">Request Sent</option>
                        {naturalFilter().field !== "t_statusUpdated" ? <option selected={dateFilterType === "t_statusUpdated"} value="t_statusUpdated">Status Updated</option> : null}
                      </>
                      :
                      <>
                        <option selected={dateFilterType === "NATURAL"} value="NATURAL">{dateFilterName("NATURAL")}</option>
                        <option selected={dateFilterType === "APPT"} value="APPT">{dateFilterName("APPT")}</option>
                      </>
                    }
                  </select>
                  {/* <span className="mr-2" style={{color: "gray", borderBottom: "1px dotted gray"}} onClick={toggleDateFilterType}>{dateFilterName()}</span> */}
                  <span style={{color: "gray"}}>
                    {moment(timeWindow.start).calendar("MM/DD/YYYY").toLocaleLowerCase()}
                    {timeWindow.unit === "day" ? null : <> — {moment(timeWindow.end).calendar("MM/DD/YYYY").toLocaleLowerCase()}</>}
                  </span>
                </span>
              </h3>
            {DATE_FILTER_TARGET_OPTIONS === "send+appt" ? null : null
              // <span className="form-inline justify-content-center pt-2">
              //   <label className="mr-2">Filter by: </label>
              //   <select className="form-control form-control-sm">
              //     <option>Next appointment</option>
              //     <option>Previous appointment</option>
              //     <option>Last invite</option>
              //     <option>Last report completion</option>
              //     <option>Last status update</option>
              //     <option>Last reassignment</option>
              //     <option>Patient added to LiteraSeed</option>
              //     <option>NONE</option>
              //   </select>
              // </span>
            }
            </div>
          </Col>
        </Row>
        <div className="datagrid-wrapper" style={{background: "white", borderRadius: "4px"}}>
          <div className="datagrid-customheader" style={{borderBottom: "1px solid rgba(224, 224, 224, 1)"}}>
            <Row>
              <Col className="col-sm-8">
              <div className="time-nav">
          <div className="text-center my-3 mx-1">
            <Row className="align-items-center">
            <Col className="col-auto pr-0 pl-4">
            <a className="btn btn-sm btn-outline-primary mr-1 page-link" href="#" onClick={() => stepTimeWindow(false)} style={{padding: "0.3rem"}}>
              <FaAngleDoubleLeft className=""/>
            </a>
            </Col>
            <Col className="px-0">
            <nav aria-label="...">
              <ul
                className="pagination pagination-sm mb-0"
                style={{ justifyContent: "space-around" }}
              >
                <li
                  className={`page-item text-center ${timeWindow?.unit === "CUSTOM" ? "active" : ""}`}
                  style={{ width: "100%" }}
                >
                  <a className={`page-link ${timeWindow?.unit === "CUSTOM" ? "active" : ""}`} href="#"
                    onClick={openCustomDateModal}>
                  <FaCalendar/> Custom
                  </a>
                </li>
                <li className={`page-item text-center ${timeWindow?.unit === "day" ? "active" : ""}`} style={{ width: "100%" }}>
                  <a className={`page-link ${timeWindow?.unit === "day" ? "active" : ""}`} href="#"
                    onClick={() => setTimeWindow(buildRange("day"))}
                    >
                    Day
                  </a>
                </li>
                <li className={`page-item text-center ${timeWindow?.unit === "week" ? "active" : ""}`} style={{ width: "100%" }} aria-current="page">
                <a
                    className={`page-link ${timeWindow?.unit === "week" ? "active" : ""}`}
                    href="#"
                    onClick={() => setTimeWindow(buildRange("week"))}
                  >Week</a>
                </li>
                <li className={`page-item text-center ${timeWindow?.unit === "month" ? "active" : ""}`} style={{ width: "100%" }}>
                  <a className={`page-link ${timeWindow?.unit === "month" ? "active" : ""}`} href="#"
                    onClick={() => setTimeWindow(buildRange("month"))}
                    >
                    Month
                  </a>
                </li>
                <li className={`page-item text-center ${timeWindow?.unit === "year" ? "active" : ""}`} style={{ width: "100%" }}>
                  <a className={`page-link ${timeWindow?.unit === "year" ? "active" : ""}`} href="#"
                    onClick={() => setTimeWindow(buildRange("year"))}
                    >
                    Year
                  </a>
                </li>
                <li className={`page-item text-center ${timeWindow?.unit === "ALL" ? "active" : ""}`} style={{ width: "100%" }}>
                  <a className={`page-link ${timeWindow?.unit === "ALL" ? "active" : ""}`} href="#"
                    onClick={() => setTimeWindow(buildRangeAll())}
                    >
                    All
                  </a>
                </li>
              </ul>
            </nav>
            </Col>
            <Col className="col-auto pl-0">
            <a className="btn btn-sm btn-outline-primary ml-1 page-link" href="#" onClick={() => stepTimeWindow(true)} style={{padding: "0.3rem"}}>
              <FaAngleDoubleRight className=""/>
            </a>
            </Col>
            </Row>
          </div>
        </div>
              </Col>
              <Col className="col-sm-4">
                <div className="m-3">
                <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"><FaSearch/></span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search patient name"
                  style={{backgroundColor: localSearch ? "lightyellow" : "white"}}
                  value={localSearch}
                  onChange={e => setLocalSearch(e.target.value)}
                  />
                {/* <div className="input-group-append">
                  <button type="button" className="btn btn-danger" onClick={() => changeSearch("")}><FaTimes/></button>
                </div> */}
              </div>
                </div>
              </Col>
            </Row>
          </div>
        {['scheduled', 'scheduled_onetime', 'scheduled_recurring', 'scheduled_inactive'].includes(inboxTab) ?
          <DataGrid
            columns={SCHEDULED_INVITE_COLUMNS}
            rows={filteredScheduledInvites}
            loading={!(loadDataId in lastLoadedFor)}
            components={{Toolbar: InboxToolbar, Footer: ScheduledInvitesFooter}}
            pageSize={25}
            autoHeight={true}
            checkboxSelection={true}
            sortModel={sortModels.scheduled}
            onSortModelChange={updateSortModel}
            __unused={console.warn({filteredScheduledInvites})}
            // onRowClick={}
          />
          : null
        }
        {inboxTab === 'scheduled_occurrences' ?
          <h4 className="text-warning">This view is currently disabled, but will return with the calendar feature.</h4>
          // <DataGrid
          //   columns={SCHEDULED_INVITE_COLUMNS}
          //   rows={currentProviderInviteOccurrences}
          //   loading={!(loadDataId in lastLoadedFor)}
          //   components={{Toolbar: InboxToolbar}}
          //   getRowClassName={getScheduledRowStyle}
          //   pageSize={25}
          //   autoHeight={true}
          //   checkboxSelection={true}
          //   sortModel={sortModels.scheduled_occurrences}
          //   onSortModelChange={updateSortModel}
          //   // onRowClick={}
          // />
          : null
        }
        {['received', 'pending', 'inactive', 'all'].includes(inboxTab) ?
          <DataGrid
            columns={MEMOIZED_COL_DEFS[inboxTab]}
            rows={filteredScreenings}
            loading={!(loadDataId in lastLoadedFor)}
            components={{Toolbar: InboxToolbar, Footer: ScreeningTableFooter}}
            getRowClassName={getReadStatusRowStyle}
            pageSize={25}
            autoHeight={true}
            checkboxSelection={true}
            sortModel={sortModels[inboxTab]}
            onSortModelChange={updateSortModel}
            __unused={console.warn({filteredScreenings})}
            // onRowClick={}
          />
          : null
        }
        {inboxTab === 'PT_all' ?
          <DataGrid
            columns={CLINICIAN_INBOX_COLUMNS}
            rows={filteredUsers}
            loading={!(loadDataId in lastLoadedFor)}
            components={{Toolbar: InboxToolbar, Footer: UserTableFooter}}
            pageSize={25}
            autoHeight={true}
            checkboxSelection={true}
            sortModel={sortModels.PT_all}
            __unused={console.warn({filteredUsers})}
            // onRowClick={}
          />
          : null
        }
        </div>
        </div>
    </div>

    <Modal show={Array.isArray(reassignModal)} onHide={() => setReassignModal(false)} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Choose Assignment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You are reassigning {reassignModal?.length} records. Please choose a user below:</p>
        <button className="btn btn-danger w-100" onClick={() => reassignGroupTo(reassignModal, "")}>Unassign (No one will manage these users!)</button>
        {
          Array.isArray(effectiveOrganization?.users) ? effectiveOrganization.users.map(u =>
            <button key={u.id} className="btn btn-outline w-100 mt-1" onClick={() => reassignGroupTo(reassignModal, u.id)}>
              {u.id === userId ? <FaStar/> : null}
              {' '}
              {u.name} ({u.jobTitle || "no title"})
            </button>
          ) : <em className="text-danger">Employee list could not be loaded!</em>
        }
      </Modal.Body>
    </Modal>

    <Modal show={showCustomRangeModal} onHide={() => setShowCustomRangeModal(false)} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Choose Date Range</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 className="pb-0">From:</h2>
        <CustomCalendar inPage={true} landscape={true} value={customRangeDisplay.from} changed={dateString => recordCustomRangeInput(dateString)} />
        <h2 className="mt-5 pb-0">To:</h2>
        <CustomCalendar inPage={true} landscape={true} value={customRangeDisplay.to} changed={dateString => recordCustomRangeInput(undefined, dateString)} />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-success" onClick={() => acceptCustomRange()}>Select</button>
        <button className="btn btn-danger" onClick={() => setShowCustomRangeModal(false)}>Cancel</button>
      </Modal.Footer>
    </Modal>

    <Modal show={changeUserModal} onHide={() => setChangeUserModal(false)} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Choose User to View</Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <div className="organization">
         {effectiveOrganization.loaded ?
           <>
           
             <div className={`p-2 ${viewingUser === user?.uid ? "bg-warning" : ""}`} onClick={() => changeViewingOwner(user?.uid)}>
             {effectiveOrganization.id !== organization.id ? <FaGhost className="text-muted" title="You are outside your organization!"/> : <FaStar title="This is you!"/>}{" "}{user.name} (you)
               <br/>
               {viewingUser === user?.uid ? <em className="float-right">Currently Selected</em> : null}
               <span className="text-muted small">{user.jobTitle || "no title"}</span>
             </div>
             {effectiveOrganization.users?.filter(ou => ou.id !== user?.uid && !ou.hideFromOrgs?.includes?.(effectiveOrganization.id))?.map(ou =>
               <div className={`p-2 ${viewingUser === ou.id ? "bg-warning" : ""}`} key={ou.id} onClick={() => changeViewingOwner(ou.id)}>
                 <FaUser />{" "}{ou.name}<br/>
                 {viewingUser === ou.id ? <em className="float-right">Currently Selected</em> : null}
                 <span className="text-muted small">{ou.jobTitle || "no title"}</span>
               </div>)
              }
              <div className={`p-2 ${viewingUser === -1 ? "bg-warning" : ""}`} style={{borderTop: "1px gray solid"}} onClick={() => changeViewingOwner(-1)}>
               <FaUserSlash/> Unassigned<br/>
               {/* <span className="text-muted small">(records with no owner)</span> */}
             </div>
              <div className={`p-2 ${viewingUser === -2 ? "bg-warning" : ""}`} style={{borderTop: "1px gray solid"}} onClick={() => changeViewingOwner(-2)}>
               <FaUsers/> View All {effectiveOrganization.name}<br/>
               {/* <span className="text-muted small">(records with no owner)</span> */}
             </div>
           </>
         : <Spinner/>}
       </div>
      </Modal.Body>
    </Modal>

    <Modal show={!!modifyScheduledInviteModal} onHide={() => setModifyScheduledInvite(null)} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Reschedule Invite</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Choose a date for this occurrence:</p>
        <Input
            id="doa"
            name="doa"
            type="text"
            elementType="date"
            value={rescheduling?.date}
            // label={}
            labelClassName="w-100"
            placeholder="mm/dd/yyyy"
            changed={(value, valid) => setRescheduleData({date: moment.tz(value, "MM/DD/YYYY", effectiveOrganization.timezone?.name)})}
            rules={{ }}
            viewErrors={true}
            initialValidate={false} />
          <div className="form-group">
            <label>Preferred time of day</label>
            <select
              className="form-control"
              name="preferredHour"
              value={rescheduling.preferredHour}
              onChange={event => setRescheduleData({preferredHour: event.target.value})}>
              {STANDARD_HOUR_OPTIONS.map((optionLabel, i) => <option key={"rHour" + i}>{optionLabel}</option>)}
            </select>
          </div>
          <div className="form-group">
            <label>Local time</label>
            <p className="font-italic">
              {modifyScheduledInviteModal ? hourDayToTimeslot(rescheduling.date, effectiveOrganization.timezone?.name, rescheduling.preferredHour).local().calendar(ALWAYS_SHOW_TIME_FMT) : null}
            </p>
            {modifyScheduledInviteModal?.fromRecurrence ? <p className="text-info">
              Since you are modifying an occurrence within a series, clicking
              "Set New Date" will cancel that occurrence and create a new
              one-time invite corresponding to your selected date + time.
            </p> : null}
          </div>
        <button
          className="btn btn-primary w-100"
          onClick={() => rescheduleInvite(modifyScheduledInviteModal, rescheduling)}
          disabled={!rescheduling.date || hourDayToTimeslot(rescheduling.date, effectiveOrganization.timezone?.name, rescheduling.preferredHour) < Date.now()}>
          Set New Date
        </button>
      </Modal.Body>
    </Modal>

    <Modal
      show={changeInviteDialog.show}
      onHide={() =>
        setChangeInviteDialog({ ...changeInviteDialog, show: false })
      }
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit/Resend Invite</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          {changeInviteDialog.accessKey ?
          <>
            <strong>Access Key:</strong>&nbsp;
            {((typeof changeInviteDialog.accessKey === "string")
              ? <span className="text-warning">{changeInviteDialog.accessKey}</span>
              : <>
                  {changeInviteDialog.accessKey?.id}
                  <br/>
                  <strong>Expire{changeInviteDialog.accessKey.t_expires < Date.now() ? "d" : "s"}:</strong>&nbsp;
                  <span className={changeInviteDialog.accessKey.t_expires < Date.now() ? "text-danger" : "text-success"}>
                    {moment(changeInviteDialog.accessKey?.t_expires).calendar(ALWAYS_SHOW_TIME_FMT)}
                  </span>
                </>)}
          </>
          : <>
            <div className="alert alert-danger">Because this record is old, you need to generate a new access key for the patient.</div>
            <strong>Access Key:</strong>&nbsp;
            <span className="text-muted">None</span>
          </>}
          <div>
            <button
              onClick={() => extendAccessKey(typeof changeInviteDialog.accessKey === "string" ? changeInviteDialog.accessKey : changeInviteDialog.accessKey?.id, true)}
              className="btn btn-sm btn-outline w-50">
              Reset expiration
            </button>
            <button
              onClick={() => generateAccessKey(changeInviteDialog.invite, changeInviteDialog.userId, true)}
              className="btn btn-sm btn-outline w-50">
              Create new access key
            </button>
          </div>
          <hr/>
        <strong>User:</strong> {changeInviteDialog.lastName}
        <br />
        <h6 className="font-weight-bold">Contact via:</h6>
        <div className="text-center">
          <div className="form-check form-check-inline px-2">
            <input
              className="form-check-input"
              type="radio"
              name="contactType"
              id="sms"
              value="sms"
              checked={changeInviteDialog.type === "sms"}
              onChange={() => dialogUpdate("type", "sms")}
            />
            <label className="form-check-label" htmlFor="sms">
              Text (SMS){" "}
            </label>
          </div>
          <div className="form-check form-check-inline px-2">
            <input
              className="form-check-input"
              type="radio"
              name="contactType"
              id="whatsapp"
              value="whatsapp"
              checked={changeInviteDialog.type === "whatsapp"}
              onChange={() => dialogUpdate("type", "whatsapp")}
            />
            <label className="form-check-label" htmlFor="whatsapp">
              WhatsApp{" "}
            </label>
          </div>
          <div className="form-check form-check-inline px-2">
            <input
              className="form-check-input"
              type="radio"
              name="contactType"
              id="email"
              value="email"
              checked={changeInviteDialog.type === "email"}
              onChange={() => dialogUpdate("type", "email")}
            />
            <label className="form-check-label" htmlFor="email">
              Email{" "}
            </label>
          </div>
        </div>
        <h6 className="font-weight-bold pt-3">
          Phone Number / Email Address
        </h6>
        <input
          type="text"
          className="form-control"
          value={changeInviteDialog.target}
          onChange={(ev) => dialogUpdate("target", ev.target.value)}
        />
        <div className="pt-2">
          <button className={`w-100 btn ${changeInviteDialog.loading ? "btn-secondary" : "btn-success"}`} disabled={true && changeInviteDialog.loading} onClick={sendDialogInvite}>
            Send New Invite Message
          </button>
        </div>
      </Modal.Body>
    </Modal>
    </>
  );

  return providerV6;
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.auth.user,
    userId: state.auth.user.uid,
    loginTime: state.auth.loginTime,
    providerName: state.auth.user.name,
    providerReports: state.provider.reports,
    inboxTab: ownProps.inboxTab
  };
}

export default connect(mapStateToProps)(ClinicianHome);
