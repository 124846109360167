
import moment from 'moment-timezone';
import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { FaExclamationTriangle, FaQuestionCircle } from 'react-icons/fa';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { dualName, EnglishNames, LanguageList } from '../../constants/locales';
import { ROLES } from '../../constants/roles';
import * as ROUTES from "../../constants/routes";
import { allows, Assignable, ScreenerNames, ScreeningType } from '../../constants/screenings';
import { UserClinicStatus } from '../../database/model/user';
import { existingPatientNewInvite, getUserByDetails, getUsersForProvider, newPatientInvite, resetEnrollmentUser, updateEnrollmentUser } from "../../store/actions";
import { newEnrollment } from '../../store/reducers/provider';
// import "../../styles/enroll.css";
import LanguageSelector from "../Locale/LanguageSelector";
import Alert from '../UI/Alert/Alert';
import { alertEvent, snack } from '../UI/GlobalAlerts';
import { Col, Container, Row } from '../UI/Grid/Grid';
import { selectionFlagHandler } from '../UI/Input/helpers';
import { Input } from '../UI/Input/Input';
import Spinner from '../UI/Spinner/Spinner';
import { UserAppointment } from './ClinicianHome';
import "../../styles/form.css"; 
import { equalCaseInsensitive, objectEntries, _switch } from '../../utils';
import QRCode from "qrcode-svg"
import { hourStringToInt, PLURAL_DAY_NAMES, STANDARD_HOUR_OPTIONS } from '../../utils/time';
import { FormControlLabel, Switch } from '@material-ui/core';

const DEFAULT_SCREENER_SELECTION = Object.fromEntries(
  Assignable.map(n => [n, false]));

const EMAIL_OR_PHONE_ERROR = "Please enter either email address or phone number.";
const NONE_SELECTED_ERROR = "You must select at least one questionnaire.";
const NO_METHODS_ERROR = "You must select at least one invite method.";

const CONFIRMATION_MODAL_INITIAL_STATE = {
  show: false,
  lastName: '',
  dob: '',
  urls: []
};

/**
 * A fallback default hour -- this shouldn't actually get used except in cases
 * of errors or when the user has cleared parts of the form (rendering it not
 * able to be submitted.)
 */
export const FALLBACK_HOUR = "9am";


export const CREDENTAL_TYPE = {
  MRN: "Medical Record Number",
  "name+DOB": "Last Name and Date of Birth"
};
const ENROLLMENT_FIELDS_REQUIRED_FOR_TYPE = {
  MRN: ["activeMRN"],
  "name+DOB": ["lastName", "dob"]
}
const DEFAULT_CRED_TYPE = "name+DOB";

const EnrollPatient = ({
  userId,
  authError,
  isLoading
}) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const globalEnrollee = useSelector(s => s.provider.enrollee);
  const providerUsers = useSelector(s => s.provider.users);
  // const providerOrg = useSelector(s => s.provider.organization);
  const authOrg = useSelector(s => s.auth.organization);
  // form data
  const [enrollee, setEnrollee] = useState(globalEnrollee);
  const [formValidity, setFormValidity] = useState({dob: false, lastName: false});
  const [viewNonInitialErrors, setViewNonInitialErrors] = useState(false);
  const [customErrors, setCustomErrors] = useState({valid: false, error: EMAIL_OR_PHONE_ERROR});
  const [selectedTypes, setSelectedTypes] = useState({...DEFAULT_SCREENER_SELECTION}); // todo move to enrollee?
  // modal dialog state
  const [confirmationModal, setConfirmationModalData] = useState ({ ...CONFIRMATION_MODAL_INITIAL_STATE });
  const [duplicatesModal, setDuplicatesModal] = useState([]);
  const [useRepeat, setUseRepeat] = useState(false);
  // const [repeatSchedule, setRepeatSchedule] = useState(["weekly", "Mondays"]);
  const allowedCredentialTypes = useMemo(() => {
    if (Array.isArray(authOrg?.allowedCredentialTypes)) {
      updateEnrollee(true, "credentialType", authOrg.allowedCredentialTypes[0])
    }
    return authOrg?.allowedCredentialTypes ?? ["name+DOB"]
  }, [authOrg]);

  /**
   * Ensure changes in the global state propagate to and overwrite our local
   */
  useEffect(() => {
    // wrap the dob in moment because strings get a weird UTC conversion when
    // passed on to MUI datepickers. TODO: might be addressable at the input
    // itself, maybe based on DateFnUtils, but couldn't find it...
    const dob = globalEnrollee.dob ? moment(globalEnrollee.dob) : null;
    setEnrollee({
      send: "now",
      recurrenceDay: PLURAL_DAY_NAMES[0],
      preferredHour: STANDARD_HOUR_OPTIONS[0],
      recurrencePeriod: "week",
      sendDate: moment().add(1, "day").format(),
      daysBeforeAppointment: 1,
      inviteVia_sms: true,
      inviteVia_email: true,
      originalContact: {phone: globalEnrollee.phone, email: globalEnrollee.email},
      ...globalEnrollee,
      dob: dob?.isValid() ? dob : null,
      credentialType: globalEnrollee.credentialType ?? defaultCredentialType()
    });
    setFormValidity({});
    setViewNonInitialErrors(false);
  }, [globalEnrollee]);

  /**
   * Reset the form to a blank slate for new enrollment.
   */
  function resetForNewUser () {
    dispatch(resetEnrollmentUser());
    setFormValidity({});
    setViewNonInitialErrors(false);
  }

  /**
   * Accept a change to a form field
   * @param {boolean} valid Whether Input believes this is valid value
   * @param {string} key The field's key (prop within the enrollee object)
   * @param {any} value The new value
   */
  function updateEnrollee (valid, key, value) {
    if (formValidity[key] !== valid) {
      setFormValidity({...formValidity, [key]: valid});
    }
    if (enrollee[key] !== value) {
      setEnrollee({...enrollee, [key]: value})
    } else {
      // 
    }
    // dispatch(updateEnrollmentUser({[key]: value}));
  }

  // TODO: Changing languages has this special sanity check... really we should
  // move this to an Input rule so it is reflected in validity instead
  const changeLanguage = (event) => {
    if (!event.target.value in LanguageList) {
      throw new Error(`Unknown language ${event.target.value}`);
    }
    setEnrollee({...enrollee, preferredLanguage: event.target.value})
    // dispatch(updateEnrollmentUser({preferredLanguage: event.target.value}));
  };

  // The prefill object is a map of question keys -> answers, so its updates
  // have an additional layer of mutation beyond what handleFormChange would
  // provide.
  const setPrefill = (key, value) => {
    // (no validity checks)
    const prefill = enrollee.prefill ? {...enrollee.prefill} : {};
    if (prefill[key] !== value)
    {
      prefill[key] = value;
      setEnrollee({...enrollee, prefill});
    }
  }

  const tzGuess = moment.tz.guess();
  const tzOrg = "America/Phoenix";

  /**
   * The date the invite (or first invite, in the case of recurring invites)
   * will be sent if using the current form values.
   */
     const sendDate = useMemo(() => {
      let date = moment(); // set to now
      if (enrollee.send === "on_specific") {
        date = moment(enrollee.sendDate);
        const hourString = enrollee.preferredHour || FALLBACK_HOUR;
        return date.hour(hourStringToInt(hourString)).minute(0);
      } else if (enrollee.send === "recurring") {
        const targetDayNumber = PLURAL_DAY_NAMES.indexOf(enrollee.recurrenceDay) || 0;
        // preferredDay: form.preferredDay.value,
        // preferredHour: form.preferredHour.value
        let rollForward = 0;
        if (date.day() > targetDayNumber ||
            (date.day() === targetDayNumber && date.hour() >= hourStringToInt(enrollee.preferredHour || FALLBACK_HOUR))) {
         rollForward = 7;
        }
        date.day(targetDayNumber + rollForward);
        date.startOf('hour');
        const hourString = enrollee.preferredHour || FALLBACK_HOUR;
        return date.hour(hourStringToInt(hourString));
      } else if (enrollee.send === "appointment_relative") {
        date = moment(enrollee._enrollmentForm_doa);
        date.subtract(enrollee.daysBeforeAppointment || 0, "days");
        const hourString = enrollee.preferredHour || FALLBACK_HOUR;
        return date.hour(hourStringToInt(hourString)).minute(0);
      } else {
        // unset or immediate 
        // we advance one minute simply to suggest to the user that the server
        // processes are not "perfectly" immediate
        return date.add(1, "minute");
      }
    }, [enrollee]);

    const [firstError, setFirstError] = useState(true);
    const MANUAL_TIMEZONE_DISCREPANCY = ((7*60) - (new Date()).getTimezoneOffset()) * 60 * 1000;
    function additionalErrorCheck () {
      const otherErrors = [];
      if (!Object.values(selectedTypes).some(x => x)) {
        otherErrors.push(NONE_SELECTED_ERROR, <br key="postNoneSelected"/>);
      }
      if (!(enrollee.inviteVia_whatsapp && enrollee.phone) && !(enrollee.inviteVia_sms && enrollee.phone) && !(enrollee.inviteVia_email && enrollee.email)) {
        otherErrors.push(NO_METHODS_ERROR, <br key="postNoMethods"/>);
      }
      if (!enrollee.email && !enrollee.phone) {
        otherErrors.push(EMAIL_OR_PHONE_ERROR, <br key="postEmail"/>);
      }
      if (enrollee.phone && !enrollee.phoneType) {
        otherErrors.push("You must select a phone type for phone numbers.", <br key="postPhoneType"/>);
      }
      if (enrollee.send !== "now" && sendDate && sendDate.valueOf() < (Date.now() - MANUAL_TIMEZONE_DISCREPANCY)) {
        otherErrors.push("Invite would be sent in the past", <br key="postPastSchedule"/>);
      }
      if (enrollee.phone && authOrg?.settings?.phoneNumberValidation) {
        try {
          const re = new RegExp(authOrg?.settings?.phoneNumberValidation)
          if (re.test(enrollee.phone)) {
            // passed
          } else {
            otherErrors.push(`Phone number does not match desired format (${authOrg?.settings?.phoneNumberValidationMessage})`, <br key="orgPhoneRegExp"/>);
          }
        } catch (err) {
          if (firstError) {
            dispatch(snack("Org phone validation could not be processed!", null, {style: "error"}))
            console.error(err);
            setFirstError(false);
          }
        }
      }
      return otherErrors;
    }

  /**
   * Update the overall validity flag of the form
   */
  useEffect(() => {
    const otherErrors = additionalErrorCheck();
    if (otherErrors.length > 0) {
      setCustomErrors({valid: false, error: <>{otherErrors.slice(0,-1)}</>});
    } else {
      setCustomErrors({valid: true, error: ""});
    }
  }, [selectedTypes, enrollee])

  /**
   * If the provider users aren't loaded (for example we started the app on this
   * page rather than clinician home), request them.
   */
  useEffect(() => {
    if (!Array.isArray(providerUsers)) {
      dispatch(getUsersForProvider(userId));
    }
  }, [providerUsers]);

  /**
   * Look up the last name + date in the provider's user list to see if there
   * are any possible duplicates, and if so, display a modal for them
   */
  function checkForExisting (update) {
    if (enrollee.id) return; // bail out, this user already exists
    if (enrollee._duplicatesIgnored) return; // we already showed the warning
    if (!Array.isArray(providerUsers)) {
      console.error("Wanted to perform user duplicate lookup, but provider users failed or did not yet load!");
      return;
    }
    // the blur callback we attach to will run before enrollee gets its updates
    // so we check for incoming values first...
    const dob = update.dob || enrollee.dob;
    const lastName = update.lastName || enrollee.lastName;
    if (dob && lastName) {
      const formattedDOB = moment(dob).format("YYYY-MM-DD");
      const matching = providerUsers.filter(
        u => equalCaseInsensitive(u.lastName, lastName, true) && u.dob === formattedDOB);
      if (matching.length > 0) {
        setDuplicatesModal(matching);
      } else {
        dispatch(getUserByDetails({providerId: userId, lastName, dob: formattedDOB})).then(
          success => {
            if (success.payload) {
              setDuplicatesModal(success.payload)
            }
          },
          err => console.log(err)
        );
      }
    }
  }

  /**
   * Switch the form from new enrollment (assumed) to re-enrolling a user from
   * the possible duplicates list.
   * @param {User} user the user to switch to enrolling
   */
  function acceptDuplicate (user) {
    setDuplicatesModal([]);
    dispatch(updateEnrollmentUser({
      ...newEnrollment(),
      ...user
    }));
  }

  /**
   * Close the duplicates modal and ignore further warnings on this user.
   */
  function ignoreDuplicates () {
    setDuplicatesModal([]);
    dispatch(updateEnrollmentUser({
      ...enrollee,
      _duplicatesIgnored: true
    }));
  }

  const [confirmChangeContact, setConfirmChangeContact] = useState(false);

  /**
   * Attempt to submit the form, either creating a new user with the described
   * invite(s), or by locating an existing user and updating it to reflect the
   * new invite(s).
   * @param {Event} event
   * @returns void
   */
  const submitHandler = ( event ) => {
    event?.preventDefault?.();
    try {
      const [success, result] = submissionInner(event === true);
      if (success === false) {
        if (result === SUBMIT_ERROR.CONTACT_UPDATE) {
          setConfirmChangeContact(true);
        } else {
          dispatch(snack("Your submission has errors, please fix the issues identified in red text.", undefined, {style: "error"}));
        }
      }
    } catch (err) {
      console.error(err);
      dispatch(snack("The system encountered an error during submission. Please see the browser console (Ctrl+Shift+J / Cmd+Option+J) for details."), undefined, {style: "error"});
    }
  }

  const SUBMIT_ERROR = {
    MISSING_IDENTIFIER: "missing last name and/or date of birth",
    FORM_VALIDATION: "general issues with form content",
    CONTACT_UPDATE: "updating contact information requires confirmation"
  }

  function submissionInner (confirm) {
    const required = ENROLLMENT_FIELDS_REQUIRED_FOR_TYPE[enrollee.credentialType] ?? [];
    if (required.some(r => !enrollee[r])) {
      setViewNonInitialErrors(true);
      return [false, SUBMIT_ERROR.MISSING_IDENTIFIER];
    }
    // use the form tools (see checkValidity for details) to validate
    const isFormValid = Object.values(formValidity).every(i => !!i);
    if (!isFormValid || !customErrors.valid) {
      // display error information
      setViewNonInitialErrors(true);
      return [false, SUBMIT_ERROR.FORM_VALIDATION];
    }
    if (additionalErrorCheck().length > 0) {
      return [false, SUBMIT_ERROR.FORM_VALIDATION];
    }
    if (useRepeat) {
      if (Object.values(selectedTypes).filter(x => x).length !== 1) {
        setCustomErrors({value: false, error: "Recurring invites can only select a single screening type"});
        return [false, SUBMIT_ERROR.FORM_VALIDATION];
      }
    }
    if (!confirm && enrollee.id && (
      (enrollee.originalContact.phone && enrollee.originalContact.phone !== enrollee.phone) ||
      (enrollee.originalContact.email && enrollee.originalContact.email !== enrollee.email)
    )) {
      return [false, SUBMIT_ERROR.CONTACT_UPDATE];
    }

    // Construct the appropriate date object based on whether it is a date
    // or a date+time
    let apptDate = null;
    let apptObj;
    if (enrollee._enrollmentForm_doa) {
      apptDate = moment(enrollee._enrollmentForm_doa);
      if (enrollee._enrollmentForm_toa) {
        const timeOfDay = moment(enrollee._enrollmentForm_toa);
        apptDate.set({
          hour: timeOfDay.get('hour'),
          minute: timeOfDay.get('minute')
        });
        apptObj = {timestamp: apptDate.valueOf()};
      } else {
        apptObj = {date: apptDate.format('YYYY-MM-DD')};
      }
    }

    const scheduling = _switch(enrollee.send,
      "recurring", {
        period: enrollee.recurrencePeriod,
        preferredDay: enrollee.recurrenceDay,
        preferredHour: enrollee.preferredHour,
        nextSend: sendDate.valueOf()
      },
      "on_specific", {
        preferredDay: PLURAL_DAY_NAMES[sendDate.day()],
        preferredHour: enrollee.preferredHour,
        targetDate: sendDate.format("YYYY-MM-DD"),
        nextSend: sendDate.valueOf()
      },
      "appointment_relative", {
        preferredDay: PLURAL_DAY_NAMES[sendDate.day()],
        preferredHour: enrollee.preferredHour,
        targetDate: sendDate.format("YYYY-MM-DD"),
        nextSend: sendDate.valueOf()
      },
      false);
    // additionalErrorCheck already validates that sendDate is in the future

    const userObject = {
      role: ROLES.PATIENT,
      providerId: userId,
      organizationId: authOrg.id,
      lastName: (enrollee.lastName ?? "").trim(),
      preferredLanguage: enrollee.preferredLanguage,
      status: UserClinicStatus.InviteSent,
      t_statusUpdated: Date.now(),
      prefill: enrollee.prefill
    };
    if (enrollee.dob) {
      userObject.dob = moment(enrollee.dob).format("YYYY-MM-DD");
    }
    if (enrollee.phone) {
      userObject.phone = enrollee.phone;
    }
    if (enrollee.email) {
      userObject.email = enrollee.email;
    }
    if (enrollee.id) {
      userObject.id = enrollee.id;
    }

    const onlySelectedTypes =
      Object.entries(selectedTypes)
        .filter(([type, chosen]) => chosen)
        .map(([type, chosen]) => ({type}));

    const options = {
      credentialType: enrollee.credentialType ?? defaultCredentialType(),
      akShortKey: !!enrollee.akShortKey,
      akOneDay: !!enrollee.akOneDay,
      inviteMethods: {
        email: !!(enrollee.inviteVia_email && enrollee.email),
        sms: !!(enrollee.inviteVia_sms && enrollee.phone),
        whatsapp: !!(enrollee.inviteVia_whatsapp && enrollee.phone)
      }
    };
    // checkAdditionalErrors already validates that we have at least one method selected
    if (enrollee.additionalMessages) {
      options.additionalMessages = enrollee.additionalMessages;
    }

    const invitePromise = (enrollee.id
      ? dispatch(existingPatientNewInvite({
          user: userObject,
          appointment: apptObj,
          screenings: onlySelectedTypes,
          organization: authOrg,
          scheduling,
          options
        }))
      : dispatch(newPatientInvite({
          user: userObject,
          appointment: apptObj,
          screenings: onlySelectedTypes,
          organization: authOrg,
          scheduling,
          options
        }))
      );

    const promise = invitePromise.then(response => {
      if (response?.meta?.requestStatus === "fulfilled") {
        setConfirmationModalData({
          dob: moment(enrollee.dob).format('MM/DD/YYYY'),
          lastName: enrollee.lastName.trim(),
          urls: response.payload,
          scheduling,
          show: true
        });
        // if (useQR) {
        //   generateQRLink(response.payload[0]);
        // }
        dispatch(resetEnrollmentUser());
      } else {
        console.error(response);
        dispatch(alertEvent(`Could not create invite: ${response.error?.message}`));
      }
    }, reason => {
      console.error("Invite failed:", reason);
    });
    return [true, promise];
  }

  /** @type {React.RefObject<HTMLDivElement | null>} */
  const qrContainer = useRef();

  const [useQR, setUseQR] = useState(false);

  function generateQRLink (url) {
    if (!qrContainer.current) return console.error("Container is not available!");
    const q = new QRCode(url);
    qrContainer.current.innerHTML = q.svg();
  }

  function defaultCredentialType () {
    if (Array.isArray(allowedCredentialTypes)) {
      return allowedCredentialTypes[0];
    }
    return DEFAULT_CRED_TYPE;
  }
  function singletonCredentialType () {
    if (Array.isArray(allowedCredentialTypes) && allowedCredentialTypes.length === 1) {
      return allowedCredentialTypes[0];
    }  else {
      return null;
    }
  }
  function credTypeRequires (fieldName) {
    const t = singletonCredentialType();
    const r = ENROLLMENT_FIELDS_REQUIRED_FOR_TYPE[t];
    if (!t || !Array.isArray(r)) return false;
    return r.includes(fieldName);
  }

  return (
    <Container className="mt-2 pr-0 pl-0"> {/* style={{background: "#F9F9F9"}} */}
    <Row>
      {authOrg?.id ? null : <div className="alert alert-danger">No organization found. Please contact LiteraSeed to ensure your user permissions are set correctly.</div>}
      <Col>
        {/* <p><Link to={ROUTES.PROVIDER} className="btn btn-outline">← Back to Clinician Home</Link></p> */}
      </Col>
      <Col className="text-right">
        <p>
          <a className="btn btn-outline-danger" onClick={() => resetForNewUser()}>
            Clear Form
          </a>
        </p>
      </Col>
    </Row>
    <Row className="justify-content-center">
    <Col className="col-12 col-md-10 col-lg-9">
      {authError && <Alert error={authError} />}
      <h1 className="heading1 text-dark-blue text-center pb-2 pt-2" style={{marginBottom: "0"}}>
        {enrollee.id ? "Send New Invite" : "Enroll A New Patient"}
      </h1>
      <p className="text-center px-3">
      {enrollee.id
        ? <em>You are sending a new invite to an existing patient. If you meant to create a new patient, use the clear button in the upper right.</em>
        : "It's easy to enroll patients who would benefit from LiteraSeed! All we need is some basic information."}
      </p>
      <div className="pb-3 pb-sm-5 px-3 px-sm-5">

      <form onSubmit={submitHandler} autoComplete="off">
        <div className="boxedForm">
          <div className='label-wrapper'>
            <h5 className="sectionLabel">
              Patient Details
            </h5>
          </div>
          <Input
            id="lastName"
            name="lastName"
            // type="text"
            elementType="input"
            label="Last Name"
            placeholder="Patient Last Name"
            value={enrollee.lastName}
            changed={(value, valid) => updateEnrollee(valid, "lastName", value)}
            onBlur={(e) => checkForExisting({lastName: e.target.value})}
            rules={{required: credTypeRequires("lastName")}}
            viewErrors={true}
            initialValidate={viewNonInitialErrors}
            disabled={!!enrollee.id} />
            {/* {viewNonInitialErrors && !enrollee.lastName ? <em className="text-danger mb-1">You must enter a last name.</em> : null} */}
          <Input
            id="dob"
            name="dob"
            type="date"
            elementType="date"
            label="Date of Birth"
            placeholder="mm/dd/yyyy"
            value={enrollee.dob}
            changed={(value, valid) => updateEnrollee(valid, "dob", value)}
            onBlur={(e) => checkForExisting({dob: e.target.value})}
            rules={{mandatory: credTypeRequires("dob")}}
            viewErrors={true}
            initialValidate={viewNonInitialErrors}
            disabled={!!enrollee.id} />
          <div style={{position: "relative", top: "-0.75rem", marginBottom: "0.25rem"}}>
            <em className="small">
              Please double-check the date of birth is correct; the patient must
              enter the same value in order to submit the form.
            </em>
          </div>
          {allowedCredentialTypes.includes("MRN") ?
          <Input
            id="activeMRN" 
            name="activeMRN"
            // type="text"
            elementType="input"
            label="Medical Record Number"
            placeholder="MR#"
            value={enrollee.activeMRN}
            changed={(value, valid) => updateEnrollee(valid, "activeMRN", value)}
            // onBlur={(e) => checkForExisting({activeMRN: e.target.value})}
            rules={{required: credTypeRequires("activeMRN")}}
            viewErrors={true}
            initialValidate={viewNonInitialErrors}
            disabled={!!enrollee.id} /> : null}
          <Input
            id="doa"
            name="doa"
            type="text"
            elementType="date"
            label={<>Date of Appointment<span className="text-muted float-right">(optional)</span></>}
            labelClassName="w-100"
            placeholder="mm/dd/yyyy"
            value={enrollee._enrollmentForm_doa}
            changed={(value, valid) => updateEnrollee(valid, "_enrollmentForm_doa", value)}
            rules={{ }}
            viewErrors={true}
            initialValidate={false} />
          <Input
            id="toa"
            name="toa"
            type="date"
            elementType="time"
            label={<>Time of Appointment<span className="text-muted float-right">(optional)</span></>}
            labelClassName="w-100"
            placeholder="hh:mm"
            value={enrollee._enrollmentForm_toa}
            changed={(value, valid) => updateEnrollee(valid, "_enrollmentForm_toa", value)}
            rules={{ }}
            viewErrors={true}
            initialValidate={false} />
        </div>
        <div className="boxedForm">
          <div className='label-wrapper'>
            <h5 className="sectionLabel">
              Contact Information
            </h5>
          </div>
          <Input
            id="patientEmail"
            name="patientEmail"
            type="email"
            elementType="input"
            label="Patient Email"
            placeholder="address@example.com"
            value={enrollee.email}
            changed={(value, valid) => updateEnrollee(valid, "email", value)}
            rules={{ }}
            viewErrors={true}
            initialValidate={false} />
          <Input
            id="phoneNumber"
            name="phoneNumber"
            type="input"
            elementType="input"
            label="Phone Number"
            placeholder="(xxx) yyy-zzzz"
            value={enrollee.phone}
            changed={(value, valid) => updateEnrollee(valid, "phone", value)}
            rules={{ }}
            viewErrors={true}
            initialValidate={false} />
          <div className="text-center">
            <label className="form-check-inline px-2" style={{verticalAlign: "top"}}>Invite Via: </label>
            <div className="form-check form-check-inline px-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="contactType"
                id="sms"
                value="sms"
                checked={enrollee.phone && enrollee.inviteVia_sms}
                disabled={!enrollee.phone}
                onChange={() => updateEnrollee(true, 'inviteVia_sms', !enrollee.inviteVia_sms)}/>
              <label className="form-check-label" htmlFor="sms">Text (SMS) </label>
            </div>
            <div className="form-check form-check-inline px-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="contactType"
                id="whatsapp"
                value="whatsapp"
                checked={enrollee.phone && enrollee.inviteVia_whatsapp}
                disabled={!enrollee.phone}
                onChange={() => updateEnrollee(true, 'inviteVia_whatsapp', !enrollee.inviteVia_whatsapp)}/>
              <label className="form-check-label" htmlFor="whatsapp">WhatsApp </label>
            </div>
            <div className="form-check form-check-inline px-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="contactType"
                id="email"
                value="email"
                checked={enrollee.email && enrollee.inviteVia_email}
                disabled={!enrollee.email}
                onChange={() => updateEnrollee(true, 'inviteVia_email', !enrollee.inviteVia_email)}/>
              <label className="form-check-label" htmlFor="whatsapp">Email </label>
            </div>
          </div>
        </div>
        <div className="boxedForm">
          <div className='label-wrapper'>
            <h5 className="font-weight-bold pt-3 sectionLabel">Invitation Settings</h5>
          </div>
          <div className="form-group">
            <label className="px-2" style={{verticalAlign: "top", fontWeight: "bold"}}>Send this Invitation: </label>
            <div className="form-check px-2 pl-4">
              <input
                className="form-check-input"
                type="radio"
                name="sendTiming"
                id="send_now"
                value="now"
                checked={enrollee.send === 'now'}
                onChange={() => updateEnrollee(true, 'send', 'now')}/>
              <label className="form-check-label" htmlFor="send_now">Immediately </label>
            </div>
            <div className="form-check px-2 pl-4">
              <input
                className="form-check-input"
                type="radio"
                name="sendTiming"
                id="send_specific"
                value="on_specific"
                checked={enrollee.send === 'on_specific'}
                onChange={() => updateEnrollee(true, 'send', 'on_specific')}/>
              <label className="form-check-label" htmlFor="send_specific">On Specific Day... </label>
            </div>
            <div className="form-check px-2 pl-4" 
                title={enrollee._enrollmentForm_doa ? "" : "This option is only available when the patient has an appointment date set."}>
              <input
                className="form-check-input"
                type="radio"
                name="sendTiming"
                id="send_appt"
                value="appointment_relative"
                checked={enrollee.send === 'appointment_relative'}
                disabled={!enrollee._enrollmentForm_doa}
                onChange={() => updateEnrollee(true, 'send', 'appointment_relative')}/>
              <label className="form-check-label" htmlFor="send_appt">Before Appointment... </label>
            </div>
            <div className="form-check px-2 pl-4">
              <input
                className="form-check-input"
                type="radio"
                name="sendTiming"
                id="send_recurring"
                value="recurring"
                checked={enrollee.send === 'recurring'}
                onChange={() => updateEnrollee(true, 'send', 'recurring')}/>
              <label className="form-check-label" htmlFor="send_recurring">Recurring... </label>
            </div>
          </div>
          { /* Recurring Invites */
            enrollee.send === 'recurring' ? <>
              <div className="form-group">
                <label>Repeat Every...</label>
                <select className="form-control" name="period" onChange={event => {updateEnrollee(true, 'recurrencePeriod', event.target.value)}}>
                  <option>week</option>
                  <option>two weeks</option>
                  <option>month</option>
                </select>
              </div>
              <div className="form-group">
                <label>Send on...</label>
                <select className="form-control" name="preferredDay" onChange={event => {updateEnrollee(true, 'recurrenceDay', event.target.value)}}>
                  {PLURAL_DAY_NAMES.map((dayPlural, index) => <option key={"day" + index}>{dayPlural}</option>)}
                </select>
              </div>
              <div className="form-group">
                <label>Preferred time of day</label>
                <select className="form-control" name="preferredHour" onChange={event => {updateEnrollee(true, 'preferredHour', event.target.value)}}>
                  {STANDARD_HOUR_OPTIONS.map((optionLabel, i) => <option key={"rHour" + i}>{optionLabel}</option>)}
                </select>
              </div>
              {/* <div className="form-group">
                <label>End after ...</label>
                <select className="form-control" name="preferredDay" onChange={event => {updateEnrollee(true, 'recurrenceDay', event.target.value)}}>
                  {PLURAL_DAY_NAMES.map((dayPlural, index) => <option key={"day" + index}>{dayPlural}</option>)}
                </select>
              </div> */}
            </>
          : null}
          { /* Specific Date */
            enrollee.send === 'on_specific' ? <>
            <Input
              id="specificDate"
              name="specificDate"
              type="text"
              elementType="date"
              label="Date"
              labelClassName="w-100"
              className="mui-to-bs"
              placeholder="mm/dd/yyyy"
              value={enrollee.sendDate}
              changed={(value, valid) => updateEnrollee(valid, "sendDate", value)}
              rules={{ }}
              viewErrors={true}
              initialValidate={true} />
            <div>
              <label>Preferred time of day</label>
              <select className="form-control" name="preferredHour" onChange={event => {updateEnrollee(true, 'preferredHour', event.target.value)}}>
                {STANDARD_HOUR_OPTIONS.map((optionLabel, i) => <option key={"sHour" + i}>{optionLabel}</option>)}
              </select>
            </div>
            </>
          : null}
          { /* Appointment-Relative */
            enrollee.send === 'appointment_relative' ? <>
            <Input
              id="daysBeforeAppointment"
              name="daysBeforeAppointment"
              type="number"
              elementType="input"
              label="Number of days before appointment"
              min={0}
              labelClassName="w-100"
              placeholder="1"
              value={enrollee.daysBeforeAppointment}
              changed={(value, valid) => updateEnrollee(valid, "daysBeforeAppointment", value)}
              rules={{ }}
              viewErrors={true}
              initialValidate={true} />
            <div>
              <label>Preferred time of day</label>
              <select className="form-control" name="preferredHour" onChange={event => {updateEnrollee(true, 'preferredHour', event.target.value)}}>
                {STANDARD_HOUR_OPTIONS.map((optionLabel, i) => <option key={"sHour" + i}>{optionLabel}</option>)}
              </select>
            </div>
            </>
          : null}
          <p className="text-center py-2">
            {enrollee.send !== "recurring" ? "I" : "First i"}nvite would be sent on{" "}
            <span className="text-success">{sendDate?.format("dddd, MMM Do [@] h:mm a")}</span>
          </p>
          {tzGuess !== tzOrg ? <div className="text-info text-center"><small>It looks like your timezone ({tzGuess}) differs from that of your organization ({tzOrg})!</small></div> : null}
          <div className="form-group mt-2">
            {/* <p style={{opacity: "60%", background: "#DDEEFF", padding: "6px", borderRadius: "4px"}}><strong>[DEBUG] org allowed types:</strong> {allowedCredentialTypes.join(", ")}<br/></p> */}
            <label className="px-2" style={{verticalAlign: "top", fontWeight: "bold"}}>Require Verification by: </label>
            <div className="form-check px-2 pl-4" title={allowedCredentialTypes.includes("name+DOB") ? "" : "This credential type is not available to your organization!"}>
              <input
                className="form-check-input"
                type="radio"
                name="credentialType"
                id="name+DOB"
                value="name+DOB"
                checked={(enrollee.credentialType ?? defaultCredentialType()) === 'name+DOB'}
                disabled={!allowedCredentialTypes.includes("name+DOB")}
                onChange={() => updateEnrollee(true, 'credentialType', 'name+DOB')}/>
              <label className="form-check-label" htmlFor="name+DOB">{CREDENTAL_TYPE['name+DOB']}</label>
            </div>
            <div className="form-check px-2 pl-4" title={allowedCredentialTypes.includes("MRN") ? "" : "This credential type is not available to your organization!"}>
              <input
                className="form-check-input"
                type="radio"
                name="credentialType"
                id="MRN"
                value="MRN"
                checked={(enrollee.credentialType ?? defaultCredentialType()) === 'MRN'}
                disabled={!allowedCredentialTypes.includes("MRN")}
                onChange={() => updateEnrollee(true, 'credentialType', 'MRN')}/>
              <label className="form-check-label" htmlFor="MRN">{CREDENTAL_TYPE.MRN}</label>
            </div>
          </div>

          {authOrg.customMessages?.enabled ? <div className="form-group pt-1">
            <label className="px-2" style={{fontWeight: "bold"}}>Additional Messages:</label>
            <div>
              <button className="btn-danger btn-xs" type="button" onClick={() => updateEnrollee(true, "messageAddendum", "")}>Clear</button>
              {objectEntries(authOrg.customMessages?.presets).map(([name, value]) =>
                <button className="btn-info btn-xs" type="button" onClick={() => updateEnrollee(true, "messageAddendum", value)}>{name}</button>)}
            </div>
            <textarea
              className="form-control"
              placeholder={"Enter additional messages here that you want the patient to receive with their invite."}
              onChange={e => updateEnrollee(true, 'messageAddendum', e.target.value ? e.target.value : null)}
              value={enrollee.messageAddendum}
              />
          </div> : null}
          {authOrg?.settings?.showAdvancedEnrollmentOptions ? 
          <div className="pt-1"> {/* text-muted */}
            <label className="px-2" style={{verticalAlign: "top", fontWeight: "bold"}}>Advanced Options: </label>
            {/* <div className="px-2">
              <FormControlLabel control={
                <Switch
                  checked={!!enrollee.useAdditionalMessage}
                  onChange={v => {updateEnrollee(true, 'useAdditionalMessage', v.target.checked);}}/>
              } label={<>Send custom organization message <FaQuestionCircle onClick={showCustomOrgMessage}/></>}/>
            </div> */}
            {/* <label className="form-check-inline px-2" style={{verticalAlign: "top", fontStyle: "italic"}}>Advanced Access Key Options: </label> */}
            <div
              className="px-2"
              title="Whether to reduce the length of the access key itself, creating a shorter overall URL better suitable for communicating verbally or hand-written."
              >
              <FormControlLabel label="Generate Short Key (~8 characters)" control={
                <Switch
                  // className="form-check-input"
                  // type="checkbox"
                  name="akShortKey"
                  // id="akShortKey"
                  checked={!!enrollee.akShortKey}
                  onChange={() => updateEnrollee(true, 'akShortKey', !enrollee.akShortKey)}/>
              }/>
            </div>
            <div
              className="px-2"
              title="Links are normally valid for two weeks, but this option reduces their validity period to 24 hours."
              >
                <FormControlLabel label="Single-day expiration" control={
                  <Switch
                    // className="form-check-input"
                    // type="checkbox"
                    name="akOneDay"
                    // id="akOneDay"
                    checked={!!enrollee.akOneDay}
                    onChange={() => updateEnrollee(true, 'akOneDay', !enrollee.akOneDay)}/>
                }/>
            </div>
          </div> 
          : null}
        </div>
        <div className="boxedForm">
          <div className='label-wrapper'>
            <h5 className="font-weight-bold pt-3 sectionLabel">Questionnaires</h5>
          </div>
        <div>
          <label style={{marginRight: '1rem'}}>Preferred Language:</label>
          <LanguageSelector
            whitelist={authOrg.allowedLanguages}
            value={enrollee.preferredLanguage}
            onChange={changeLanguage}
            showEnglish={true}
            width="200px"/>
        </div>
        <table style={{borderSpacing: '5px', borderCollapse: 'separate'}}><tbody>
        {Assignable.filter(s => authOrg.allowedScreeners?.includes(s) && allows(s, enrollee.preferredLanguage, "enrollment")).map(screener => (
          <tr key={screener} style={{}}>
            <td>
              <input
                type="checkbox"
                id={`send-${screener}`}
                checked={selectedTypes[screener]}
                onChange={selectionFlagHandler(selectedTypes, setSelectedTypes, screener)}
              />
            </td>
            <td>
              <label
                style={{marginLeft: '1rem', marginBottom: '0'}}
                htmlFor={`send-${screener}`}
              >
                {ScreenerNames[screener]}
              </label>
            </td>
          </tr>
        ))}
        {Assignable.filter(s => authOrg.allowedScreeners?.includes(s) && !allows(s, enrollee.preferredLanguage, "enrollment")).map((screener, i) => (
          <>
          {i === 0 ? 
            <tr key="explanation"><td colSpan={2} className="text-danger">
              The remaining questionnaires are not available in {EnglishNames[enrollee.preferredLanguage]}.
              If you select any of these, the patient will receive an <u>English</u> version instead:
            </td></tr>
          : null}
          <tr key={screener} style={{}}>
            <td>
              <input
                type="checkbox"
                id={`send-${screener}`}
                checked={selectedTypes[screener]}
                onChange={selectionFlagHandler(selectedTypes, setSelectedTypes, screener)}
              />
            </td>
            <td>
              <label
                style={{marginLeft: '1rem', marginBottom: '0'}}
                htmlFor={`send-${screener}`}
                className="text-muted"
              >
                {ScreenerNames[screener]}
              </label>
            </td>
          </tr>
          </>
        ))}
        </tbody></table>
        </div>

        {selectedTypes[ScreeningType.OBGYN] ?
          <div className="prefillQuestions boxedForm">
            <div className='label-wrapper'>
              <h5 className="font-weight-bold pt-3 sectionLabel">Visit Details</h5>
            </div>
            <p style={{fontStyle: "italic", textAlign: "center", background: "rgba(62, 175, 196, 0.35)", borderRadius: "2px"}}>Optional</p>
            <div style={{padding: "8px"}}>
              <p style={{fontWeight: "bold", marginBottom: "8px"}}>Is this a new patient to the clinic or an existing patient?</p>
              <div className="form-check form-check-inline px-2">
                <input className="form-check-input" type="radio" name="prefill_isNewPatient" id="new" value="New patient" checked={enrollee.prefill?.["new to clinic"] === 'New patient'} onChange={() => setPrefill("new to clinic", "New patient")}/>
                <label className="form-check-label" htmlFor="new">New to Clinic </label>
              </div>
              <div className="form-check form-check-inline px-2">
                <input className="form-check-input" type="radio" name="prefill_isNewPatient" id="established" value="Established patient" checked={enrollee.prefill?.["new to clinic"] === 'Established patient'} onChange={() => setPrefill("new to clinic", "Established patient")}/>
                <label className="form-check-label" htmlFor="established">Established Patient </label>
              </div>
            </div>
            <div style={{padding: "8px"}}>
              <p style={{fontWeight: "bold", marginBottom: "8px"}}>Is the patient pregnant?</p>
              <div className="form-check form-check-inline px-2">
                <input className="form-check-input" type="radio" name="prefill_isPregnant" id="OB" value="OB" checked={enrollee.prefill?.["is pregnant"] === 'OB'} onChange={() => setPrefill("is pregnant", "OB")}/>
                <label className="form-check-label" htmlFor="OB">Yes (OB appt) </label>
              </div>
              <div className="form-check form-check-inline px-2">
                <input className="form-check-input" type="radio" name="prefill_isPregnant" id="GYN" value="GYN" checked={enrollee.prefill?.["is pregnant"] === 'GYN'} onChange={() => setPrefill("is pregnant", "GYN")}/>
                <label className="form-check-label" htmlFor="GYN">No (GYN appt) </label>
              </div>
            </div>
            <br/>
          </div>
          : null}
        {selectedTypes[ScreeningType.OBGYN_PLUS] ?
          <div className="prefillQuestions boxedForm">
            <div className='label-wrapper'>
              <h5 className="font-weight-bold pt-3 sectionLabel">OB/GYN Plus Visit Details</h5>
            </div>
            <p style={{fontStyle: "italic", textAlign: "center", background: "rgba(62, 175, 196, 0.35)", borderRadius: "2px"}}>Optional</p>
            <div style={{padding: "8px"}}>
              <p style={{fontWeight: "bold", marginBottom: "8px"}}>Is this a new patient to the clinic or an existing patient?</p>
              <div className="form-check form-check-inline px-2">
                <input className="form-check-input" type="radio" name="prefill_isNewPatient" id="new" value="New patient" checked={enrollee.prefill?.["patient status"] === 'New patient'} onChange={() => setPrefill("patient status", "New patient")}/>
                <label className="form-check-label" htmlFor="new">New to Clinic </label>
              </div>
              <div className="form-check form-check-inline px-2">
                <input className="form-check-input" type="radio" name="prefill_isNewPatient" id="established" value="Established patient" checked={enrollee.prefill?.["patient status"] === 'Established patient'} onChange={() => setPrefill("patient status", "Established patient")}/>
                <label className="form-check-label" htmlFor="established">Established Patient </label>
              </div>
            </div>
            <div style={{padding: "8px"}}>
              <p style={{fontWeight: "bold", marginBottom: "8px"}}>Is the patient pregnant?</p>
              <div className="form-check form-check-inline px-2">
                <input className="form-check-input" type="radio" name="prefill_isPregnant" id="OB" value="OB" checked={enrollee.prefill?.["is pregnant"] === 'Recently pregnant'} onChange={() => setPrefill("is pregnant", "Recently pregnant")}/>
                <label className="form-check-label" htmlFor="OB">Yes, new pregnancy </label>
              </div>
              <div className="form-check form-check-inline px-2">
                <input className="form-check-input" type="radio" name="prefill_isPregnant" id="OB" value="OB" checked={enrollee.prefill?.["is pregnant"] === 'Pregnant'} onChange={() => setPrefill("is pregnant", "Pregnant")}/>
                <label className="form-check-label" htmlFor="OB">Yes, established pregnancy </label>
              </div>
              <div className="form-check form-check-inline px-2">
                <input className="form-check-input" type="radio" name="prefill_isPregnant" id="GYN" value="GYN" checked={enrollee.prefill?.["is pregnant"] === "Not pregnant"} onChange={() => setPrefill("is pregnant", "Not pregnant")}/>
                <label className="form-check-label" htmlFor="GYN">No </label>
              </div>
            </div>
            <br/>
          </div>
          : null}

            <small className="text-danger">{customErrors.error}</small>
        <button type="submit" className="btn btn-primary btn-3 btn-block mt-2">{isLoading? <Spinner /> : "Send Screening Questions"}</button>
      </form>

      <Modal show={confirmationModal.show} onHide={() => setConfirmationModalData({...confirmationModal, show: false})}>
        <Modal.Header closeButton>
          <Modal.Title>Enrolled Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Last Name:</strong> {confirmationModal.lastName}<br/>
          <strong>DOB:</strong> {confirmationModal.dob}<br/>
          {useQR ? <div ref={qrContainer}></div> : null}
          {confirmationModal.scheduling ? 
              (confirmationModal.scheduling.period ?
                <>
                <strong>Scheduled for:</strong> every {confirmationModal.scheduling.period}, {confirmationModal.scheduling.preferredDay} at {confirmationModal.scheduling.preferredHour}
                </>
              :
                <>
                <strong>Scheduled for:</strong> {confirmationModal.scheduling.targetDate} at {confirmationModal.scheduling.preferredHour}
                </>
              )
          : <><strong>Invite URLs</strong><br/>
          <ul className="list-group">{confirmationModal.urls.map(u =>
            <li key={u} className="list-group-item"><small><a href={u}>{u}</a></small></li>)}</ul>
          <em>These have been automatically sent to the patient via either text
            or email. They are included here in case you need to send them
            manually through another medium.</em></>}
        </Modal.Body>
      </Modal>

      <Modal show={duplicatesModal?.length > 0} onHide={ignoreDuplicates} size="xl">
        <Modal.Header closeButton>
          <Modal.Title><FaExclamationTriangle className="text-warning"/> Possible Duplicate Patients</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-danger">
            The following records have the same last name + date of birth. If
            one of these is the correct patient, Send a New Invite instead to
            avoid creating a duplicate record.
          </p>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>DOB</th>
                <th>Next Appt</th>
                <th>Created</th>
                <th>Screener Types</th>
                <th>Reports</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {duplicatesModal.map(d =>
                <tr key={d.id}>
                  <td>{d.lastName}</td>
                  <td>{moment(d.dob).format("MM/DD/YYYY")}</td>
                  <td>
                    {UserAppointment(d)}
                  </td>
                  <td>
                    <small>{moment(d.t_created).format("MM/DD/YYYY HH:mm a")}</small>
                  </td>
                  <td>{d.screeningsUsed?.map(s => `${s} `)}</td>
                  <td>{d.reports?.length || 0}</td>
                  <td><a className="btn btn-outline-warning" onClick={() => acceptDuplicate(d)}>Send New Invite</a></td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <a className="btn btn-outline float-right" onClick={ignoreDuplicates}>Create New User</a>
        </Modal.Footer>
      </Modal>

      <Modal show={!!confirmChangeContact} onHide={() => setConfirmChangeContact(false)}>
      {confirmChangeContact ? <>
      <Modal.Header>
        <Modal.Title>
          Contact information changed!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are about to override previously-entered contact details. By 
          proceeding with this action, any active scheduled or recurring invites
          will be updated accordingly.
        </p>
        <ul className="mt-2">
          {(enrollee.originalContact.phone && enrollee.originalContact.phone !== enrollee.phone) ? 
            <li><tt>{enrollee.originalContact.phone}</tt> → <tt>{enrollee.phone}</tt></li>
          : null}
          {(enrollee.originalContact.email && enrollee.originalContact.email !== enrollee.email) ? 
            <li><tt>{enrollee.originalContact.email}</tt> → <tt>{enrollee.email}</tt></li>
          : null}
        </ul>
        <p>
          Do you want to continue?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-warning" onClick={() => setConfirmChangeContact(false)}>Go Back to Form</button>
        <button className="btn btn-success" onClick={() => {setConfirmChangeContact(false); submitHandler(true);}}>Continue</button>
      </Modal.Footer></> : null}
    </Modal>
      </div>
    </Col>
    </Row>
    </Container>
    );
}


function mapStateToProps(state, ownProps) {
  return {
    userId: state.auth.user.uid,
    isLoading: state.auth.isLoading,
    authError: state.auth.error,
    // providerName: state.auth.user.name,
    // providerReports: state.provider.reports
  };
}


export default connect(mapStateToProps)(EnrollPatient);