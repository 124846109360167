import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap/ModalHeader";
import { FaArrowLeft, FaArrowRight, FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ScreenerNames } from "../../constants/screenings";
import { emptyAnswer } from "../../database/model/answer";
import { useTextDirection } from "../../i18n";
import { resolveAudioURLs } from "../../store/slices/audio";
import { changeAnswers, getCurrentQuestionnaire } from "../../store/slices/questionnaire";
import "../../styles/button.scss";
import classes from "../../styles/History.module.scss";
import { ordinals, shortOrdinal, spliceOutOf } from "../../utils";
import PlayAudio from "../Audio/PlayAudio";
import { ResourceDialog } from "../Education/ResourceDialog";
import { Col, Container, Row } from "../UI/Grid/Grid";
import { HighlightKeywords } from "../UI/HighlightKeywords";
import { ImageWithFallback } from "../UI/ImageWithFallback";
import "../UI/IntroCard/IntroCard.css";
import ResponseChoices from "./ResponseChoices";

function camelToHyphen (text = "") {
  return text.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
}

function applyStylesWithImportance (styles = {}) {
  const toApply = Object.entries(styles);
  if (toApply.length > 0) {
    return (el) => {
      if (el) {
        // debugger;
        toApply.forEach(([key, value]) => {
          const i = value.indexOf("!important");
          if (i > -1) {
            el.style.setProperty(camelToHyphen(key), value.slice(0, i).trim(), "important");
          } else {
            el.style.setProperty(camelToHyphen(key), value);
          }
        });
      }
    }
  }
}

const QuestionnairePage = ({
  questionKey,
  answerKey,
  handleBack,
  handleForward,
  navigationDisabled,
  inline,
  inlineStyles = {},
  rowFormat = false,
  multiplexIndex,
  skipPredicate = () => false,
  breadcrumbNav,
  fullwidth
}) => {
  answerKey = answerKey ?? questionKey;
  const questionnaire = useSelector(getCurrentQuestionnaire);
  const question = useSelector(state =>
    getCurrentQuestionnaire(state)?.questions?.[questionKey] ?? {});
  const answer = useSelector(state => state.questionnaire.answers?.[answerKey] ?? emptyAnswer());
  const dynamicText = useSelector(state => state.questionnaire.dynamicText[questionKey])
  const listLength = Number.isSafeInteger(answer?.size) ? answer.size : 1;
  const questionAnswered = !!answer.answered;
  const isScalar = question.display !== "multi_options";

  const [activeKeyword, setActiveKeyword] = useState(false);
  const keywordList = (question.keywordLinks && questionnaire.pageSettings?.highlightKeywords) ? Object.keys(question.keywordLinks) : [];

  const dispatch = useDispatch();
  const dir = useTextDirection();

  const [ deselectConfirmation, setDeselectConfirmation] = useState(false);

  /**
   * Handle the user either confirming or cancelling the selection of an answer
   * that will deselect all others
   */
  const closeDeselectConfirmation = (confirm) => {
    if (confirm && deselectConfirmation) {
      delete answer.skipReason;
      dispatch(
        changeAnswers({[answerKey]: {
          ...answer,
          answered: true,
          selected: [deselectConfirmation.id],
          options: {[deselectConfirmation.id]: {value: [deselectConfirmation.value]}},
      }}));
    }
    setDeselectConfirmation(false);
  };

  /**
   * Scroll to top whenever the question changes
   */
  useEffect(() => {
    if (!inline || inline === "top") {
      window.scrollTo(0, 0);
    }
    if (question && question.responses) {
      const filenames = question.responses.map(r => r.audioFileName).concat(question.audioFileName).filter(s => s);
      dispatch(resolveAudioURLs({filenames}));
    }
  }, [question, inline]);

  const shownResponses = useMemo(() => {
    if (!question || !Array.isArray(question.responses)) return [];
    return question.responses.map((r, i) => ({...r, _skipEvaluated: skipPredicate(r, `questions.${questionKey}.responses.${i}`)}));
  }, [question]);

  const debugMode = useSelector(s => s.session.debugMode);

  // TODO this should move to answer model / util class
  const reevaluateListValue = (a) => {
    return a.selected.map(id => {
      let response = question.responses[id];
      let inner = a.options[id];
      if (!inner) {
        return response.value;
      }
      if ("input" in inner) {
        return inner.input;
      } else if ("key" in inner) {
        return response.inputOptions[inner.key]?.value;
      } else {
        console.error(`Unknown inner value format`, inner);
        return "«error»";
      }
    });
  };

  /**
   * Remove any exclusive responses and then add the new response.
   */
  const addToSelected = (selected, newItem) => {
    return selected.filter(i => !question.responses[i]?.exclusive).concat(newItem)
  };

  /**
   * The user is attempting to change whether a response was selected at all,
   * likely by clicking the response card.
   */
  const toggleResponse = (responseId) => {
    const response = question.responses[responseId];
    const previouslySelected = answer.selected?.includes(responseId);
    const otherAnswersSelected = answer.selected?.some(id => id !== responseId);

    if (response.exclusive && otherAnswersSelected) {
      return setDeselectConfirmation({value: response.value, id: responseId});
    }

    const selected = Array.isArray(answer.selected) ? answer.selected.slice() : [];
    const updatedAnswer = {...answer, selected, answered: true, prefilled: false};
    if (updatedAnswer.options) updatedAnswer.options = {...updatedAnswer.options};
    delete updatedAnswer.skipReason;

    if (isScalar || response.exclusive) {
      if (previouslySelected) {
        updatedAnswer.selected = [];
        updatedAnswer.suggested = [];
        updatedAnswer.options = {};
        updatedAnswer.value = undefined;
        updatedAnswer.answered = false;
      } else {
        updatedAnswer.selected = [responseId];
        updatedAnswer.suggested = [];
        updatedAnswer.options = {}
        updatedAnswer.value = response.value;
      }
    } else {
      if (previouslySelected) {
        updatedAnswer.selected = spliceOutOf(responseId, updatedAnswer.selected);
        delete updatedAnswer.options[responseId];
        updatedAnswer.value = reevaluateListValue(updatedAnswer);
        if (!updatedAnswer.selected.some(x => x !== undefined)) {
          updatedAnswer.answered = false;
        }
      } else {
        if (updatedAnswer.suggested) {
          updatedAnswer.suggested = spliceOutOf(responseId, updatedAnswer.suggested);
        }
        updatedAnswer.selected = addToSelected(updatedAnswer.selected, responseId);
        // updatedAnswer.options[responseId] = response.value;
        updatedAnswer.value = reevaluateListValue(updatedAnswer);
      }
    }

    dispatch(changeAnswers({[answerKey]: updatedAnswer}));
  };

  /**
   * The user is changing an "inner" value of a response, such as a dropdown or
   * a specific input. These generally represent "further details" of an answer,
   * but note that our question model ALWAYS represents responses, so an input
   * of a single "response" question is still considered an inner value, even
   * though it is really the primary value we care about.
   */
  const changeSubValue = (responseId, optionOrValue, size) => {
    const previouslySelected = answer.selected?.includes(responseId);

    const updatedAnswer = {...answer, answered: true, options: {...answer.options}};
    delete updatedAnswer.skipReason;
    if (!previouslySelected) {
      // if the response wasn't marked selected, we treat an edit as selecting
      // that response as well, hence the usual logic applies (turn off all
      // others if scalar, otherwise add this and remove exclusives)
      if (isScalar) {
        updatedAnswer.selected = [responseId];
        updatedAnswer.options = {};
      } else {
        updatedAnswer.selected = addToSelected(updatedAnswer.selected ?? [], responseId);
      }
    }

    updatedAnswer.options[responseId] = optionOrValue;

    const valueArray = reevaluateListValue(updatedAnswer);
    if (isScalar) {
      // we could have a non-list function for this case, but eh.
      updatedAnswer.value = valueArray[0];
    } else {
      updatedAnswer.value = valueArray;
    }
    if (size) updatedAnswer.size = size;

    dispatch(changeAnswers({[answerKey]: updatedAnswer}));
  }

  const changeListValue = (index) => (responseId, optionOrValue) => {
    if (!("input" in optionOrValue)) {
      console.error("changeListValue only supports value type", optionOrValue);
      return;
    }
    const existing = (answer?.options?.[responseId]?.input || []).slice();
    existing[index] = optionOrValue?.input;
    console.log(existing);
    changeSubValue(responseId, {input: existing}, Math.max(answer.size || 0, index+1));
  }

  const extendList = () => {
    const currentSize = answer.size || 1;
    dispatch(changeAnswers({[answerKey]: {size: currentSize + 1}}))
  }

  /**
   * Substitute special strings present in question text related to tables and
   * multiplexing with their dynamic values. This allows the question text to,
   * for example, talk about which 'row' within a multiplex the question is
   * inquiring about.
   */
  function replaceMultiplexSymbols () {
    return ((dynamicText || question.text)
      .replace("<multiplex_index>", multiplexIndex)
      .replace("<multiplex_index+1>", multiplexIndex+1)
      .replace("<multiplex_ordinal>", shortOrdinal(multiplexIndex+1))
    );
  }

  let InputRenderer = () => <div/>;
  if (question.customInputRenderer) {
    // TODO: ensure local only?
    InputRenderer = lazy(() => import(`${question.customInputRenderer}`));
  }

  const USE_HORIZONTAL_RULE = true;
  const USE_COMPACT_LM = false;
  const USE_COMPACT_SBS_IMG = false;

  const section = questionnaire.sections?.[question.section];

  const compactStyle = question.responses?.[0]?.inputType === "compact-card";

  if (rowFormat) {
    return (
      <Row className="align-items-center justify-content-between" style={{ position: "relative"}}>
        {answer.prefilled ?
          <div className="alert alert-primary">
            This question was partially answered by your clinic. If this is not correct, please select the correct answer.
          </div>
          : null}
        {Number.isInteger(answer.skipReason) ?
          <div className="alert alert-secondary">
            You originally skipped this question, but you can still select an answer.
          </div>
          : null}

        {question.imgUrl ? <Col className="col-12">
          <ImageWithFallback src={question.imgUrl} style={{width: "50%", maxWidth: "300px", marginLeft: "25%"}}/>
        </Col> : null}
        <Col>
        {/* <HighlightKeywords/> */}
          <h4>{Number.isInteger(multiplexIndex) ? replaceMultiplexSymbols() : (dynamicText || question.text)}</h4>
        </Col>
        <Col className="col-auto"><PlayAudio filename={question.audioFileName} skipLoad={true}/></Col>
        <Col className="col-12 col-lg" style={{flexGrow: "2"}}>
        {
            (question.display === "list" ? <span className="text-danger">Not available in rowFormat yet!</span> :
            <ResponseChoices
              fullwidth={true}
              key={questionKey}
              questionKey={questionKey}
              answerKey={answerKey}
              choices={shownResponses}
              toggleResponse={toggleResponse}
              changeValue={changeSubValue}
            />)
          }
        </Col>
      
        {/* keywordList.length > 0 ?
          <p style={{color: "#333333", marginTop: "-10px", marginBottom: "10px", letterSpacing: ".5px", textUnderlineOffset: "2px", textAlign: (compactStyle && USE_COMPACT_LM) ? "right" : "inherit", fontSize: (compactStyle && USE_COMPACT_LM) ? "0.9rem" : "inherit"}}>
            <span
              style={{textDecoration: (compactStyle && USE_COMPACT_LM) || true ? "underline" : "none", cursor: "pointer"}}
              onClick={() => setActiveKeyword(keywordList[0])}>
              LEARN MORE
            </span>
          </p>
        : null */}
        
          {/* (
            <>
              {question.subtext?
                <p className={`text-center pb-3`}>
                  <small>{question.subtext}</small>
                </p>
              : null}
              {question.imgUrl?
                <Row className={`justify-content-center pt-0 pt-md-3 mb-0 ${classes.Page}`}>
                    <ImageWithFallback src={question.imgUrl} style={{width: compactStyle ? "175px" : "300px"}} alt=""/>
                </Row>
              : null}
            </>
          ) */}

        <Modal show={!!deselectConfirmation} onHide={() => setDeselectConfirmation(false)}>
          <Modal.Body>
            <p>
              Selecting this button will clear up all the answers you choose.
              Are you sure you want to continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={() => closeDeselectConfirmation(false)}>
              No
            </Button>
            <Button variant="info" onClick={() => closeDeselectConfirmation(true)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <ResourceDialog url={question.keywordLinks?.[activeKeyword]} show={[activeKeyword, setActiveKeyword]}/>
        {/* <Modal show={!!keywordModal} onHide={() => setKeywordModal(false)} size="lg">
          <Modal.Body>
            {keywordModal && (new URL(question.keywordLinks?.[keywordModal])?.host !== window.location.host) ?
              <div className="alert alert-danger">
                The content below is an external page from <em>{question.keywordLinks?.[keywordModal]}</em>.
                <br/>
                Be cautious before entering personal data!
              </div>
              : null}
            <iframe src={question.keywordLinks?.[keywordModal]} style={{width: "100%", minHeight: "60vh", border: "none"}}></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={() => setKeywordModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
    </Row>
    );
  }

  return (
    <Container className={`half-default-padding`} style={{ position: "relative"}}>
      {(questionnaire.pageSettings?.breadcrumbs && (!inline || inline === "top")) ? <nav aria-label="breadcrumb">
        <ol className="breadcrumb mt-3">
          {[ScreenerNames[questionnaire.screener], section?.title, question.subsection].filter(x => x).map((crumb, level) => 
            <li
              className="breadcrumb-item"
              style={questionnaire.pageSettings?.breadcrumbs === "no-link" ? {} : {"cursor": "pointer"}}
              onClick={() => breadcrumbNav?.(level)}>
              {crumb}
            </li>
          )}
        </ol>
      </nav> : null}
      <div className={(!inline || inline === "bottom") ? "pad-for-footer" : ""}>
        <div className="headerWithSound">
          <h2 className={`${classes.QuestionText} ${((inline && inline !== "top") || questionnaire.pageSettings?.breadcrumbs) ? "pt-0" : "pt-3 pt-md-5"} pb-2 pb-md-4`} ref={applyStylesWithImportance(inlineStyles?.header_inner)}>
            <HighlightKeywords keywords={keywordList} keywordClass="keyword-highlight" click={(k) => setActiveKeyword(k)}>{Number.isInteger(multiplexIndex) ? replaceMultiplexSymbols() : (dynamicText || question.text)}</HighlightKeywords>
            {debugMode ? <small title="DEBUG MODE KEY" className="text-muted float-right">{answerKey}</small> : null}
          </h2>
          <div className="headerSound">
            <PlayAudio filename={question.audioFileName} skipLoad={true}/>
          </div>
        </div>
        {USE_HORIZONTAL_RULE ? <>
          <hr className="modal-card-hr"/>
          {inlineStyles?.postline_br === false || (compactStyle && USE_COMPACT_LM) ? null : <br />}
        </> : null}
        {keywordList.length > 0 ?
          <p style={{color: "#333333", marginTop: "-10px", marginBottom: "10px", letterSpacing: ".5px", textUnderlineOffset: "2px", textAlign: (compactStyle && USE_COMPACT_LM) ? "right" : "inherit", fontSize: (compactStyle && USE_COMPACT_LM) ? "0.9rem" : "inherit"}}>
            <span
              style={{textDecoration: (compactStyle && USE_COMPACT_LM) || true ? "underline" : "none", cursor: "pointer"}}
              onClick={() => setActiveKeyword(keywordList[0])}>
              LEARN MORE
            </span>
          </p>
        : null}
        {answer.prefilled ?
          <div className="alert alert-primary">
            This question was answered by your clinic. If this is not correct, please select the correct answer.
          </div>
          : null}
        {Number.isInteger(answer.skipReason) ?
          <div className="alert alert-secondary">
            You originally skipped this question, but you can still select an answer.
          </div>
          : null}
          {(compactStyle && USE_COMPACT_SBS_IMG) && question.subtext && question.imgUrl ? (
            <Col className="col-md-8 offset-md-2 px-0">
            <Row className="align-items-center">
              <Col className={`col-auto pt-0 mb-0 ${classes.Page}`}>
                {/* <Col className={`col col-6 col-md-4`}> */}
                  <ImageWithFallback src={question.imgUrl} style={{width: compactStyle ? "175px" : "300px", maxWidth: "40vw"}} alt=""/>
                {/* </Col> */}
              </Col>
              <Col className={`text-center pb-3`}>
                <small>{question.subtext}</small>
              </Col>
            </Row>
            </Col>
          ) : (
            <>
              {question.subtext?
                <p className={`text-center pb-3`}>
                  <small>{question.subtext}</small>
                </p>
              : null}
              {question.imgUrl?
                <Row className={`justify-content-center pt-0 pt-md-3 mb-0 ${classes.Page}`}>
                  {/* <Col className={`col col-6 col-md-4`}> */}
                    <ImageWithFallback src={question.imgUrl} style={{width: compactStyle ? "175px" : "300px"}} alt=""/>
                  {/* </Col> */}
                </Row>
              : null}
            </>
          )}

        <div><Row className={`justify-content-center no-gutters pt-0 ${compactStyle ? "pt-md-3" : "pt-md-3"} pb-0 custom-mx-neg-2 ${inline ? "" : classes.Page}`}>
          {question.customInputRenderer ?
            <Suspense fallback="Loading custom renderer...">
              <InputRenderer
                key={questionKey}
                questionKey={questionKey}
                answerKey={answerKey}
                choices={question.responses}
                toggleResponse={toggleResponse}
                changeValue={changeSubValue}
              />
            </Suspense>
          : 
            (question.display === "list" ? <><ol>
              {ordinals(answer.size||1).map(num =>
              <li key={num}>
                <ResponseChoices
                  fullwidth={fullwidth}
                  key={questionKey}
                  questionKey={questionKey}
                  answerKey={answerKey}
                  choices={shownResponses}
                  listIndex={num}
                  toggleResponse={() => {console.error(answer, num);}}
                  changeValue={changeListValue(num)}
                />
              </li>)}
            </ol>
            <Col className="col-6 col-md-4 col-lg-3 pb-3">
              <button className="btn btn-success" onClick={extendList}><FaPlusCircle className="mr-2"/> Add Row</button>
            </Col>
            </> :
            <ResponseChoices
              fullwidth={fullwidth}
              key={questionKey}
              questionKey={questionKey}
              answerKey={answerKey}
              choices={shownResponses}
              toggleResponse={toggleResponse}
              changeValue={changeSubValue}
            />)
          }
        </Row>
        </div>
        <Modal show={!!deselectConfirmation} onHide={() => setDeselectConfirmation(false)}>
          <Modal.Body>
            <p>
              Selecting this button will clear up all the answers you choose.
              Are you sure you want to continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={() => closeDeselectConfirmation(false)}>
              No
            </Button>
            <Button variant="info" onClick={() => closeDeselectConfirmation(true)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <ResourceDialog url={question.keywordLinks?.[activeKeyword]} show={[activeKeyword, setActiveKeyword]}/>
        {/* <Modal show={!!keywordModal} onHide={() => setKeywordModal(false)} size="lg">
          <Modal.Body>
            {keywordModal && (new URL(question.keywordLinks?.[keywordModal])?.host !== window.location.host) ?
              <div className="alert alert-danger">
                The content below is an external page from <em>{question.keywordLinks?.[keywordModal]}</em>.
                <br/>
                Be cautious before entering personal data!
              </div>
              : null}
            <iframe src={question.keywordLinks?.[keywordModal]} style={{width: "100%", minHeight: "60vh", border: "none"}}></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={() => setKeywordModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
      {(inline && inline !== "bottom") ? null :
      <div className={classes.Footer}>
        <div className={`text-center`}><small>&copy; All rights reserved</small></div>
        <div>
          {/* we might want to re-examine these as Bootstrap buttons... we're manually overriding the hover effect here for example */}
          <Button className="back-btn pl-3 pr-3 mr-3 ml-3" onClick={handleBack} disabled={!!navigationDisabled}>
            {dir === 'ltr' ? <FaArrowLeft /> : <FaArrowRight />} <span className="pr-2 font-weight-bold">Back</span>
          </Button>
          <Button className="forward-btn pl-3 pr-3 mr-3 ml-3 text-white" onClick={handleForward} disabled={!!navigationDisabled}>
            <span className="pr-2 font-weight-bold">Next</span> {dir === 'ltr' ? <FaArrowRight /> : <FaArrowLeft />}
          </Button>
        </div>
      </div>}
    </Container>
  );
};

export default QuestionnairePage;
