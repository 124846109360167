import React, { useEffect, useState } from 'react';

export const HighlightKeywords = ({children, keywords, style, keywordClass, click}) => {
  keywords = Array.isArray(keywords) ? keywords.filter(k => k) : []; // filter out empty strings

  const processed = React.Children.map(children, (child) => {
    if (typeof child === "string") {
      // text node
      const replacements = [];
      keywords.forEach(keyword => {
        let start = child.indexOf(keyword);
        if (start > -1) {
          // TODO: detect if overlapping another keyword!
          replacements.push({start, end: start + keyword.length, keyword});
        }
        // TODO: should we detect multiple occurrences of a keyword?
      });

      if (replacements.length > 0) {
        const interleaved = [];
        let lastSnipEnd = 0;
        replacements.forEach(rep => {
          if (rep.start > lastSnipEnd) {
            // add the text from the last snip to the beginning of this one
            interleaved.push(child.slice(lastSnipEnd, rep.start));
          }
          interleaved.push(rep);
          lastSnipEnd = rep.end;
        });
        if (lastSnipEnd !== child.length) {
          interleaved.push(child.slice(lastSnipEnd));
        }
        return (<>{interleaved.map(node => typeof node === "string" ? node :
          <a key={node.keyword + node.start} className={keywordClass} onClick={() => click(node.keyword)} style={style}>{node.keyword}</a>
        )}</>);
      } // otherwise fall through to basic return
    }
    return child;
  });

  return processed || null;
}