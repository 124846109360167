import { db } from "../../database";
import * as actionTypes from './actionTypes';

const emailRef = db.collection('mail');
// const providersRef = db.collection('providers');

const sendEmailStart = (payload) => ({
  type: actionTypes.SEND_EMAIL_START,
  payload
});

// NOTE: this doesn't mean the email was received or even sent, just that the
// database accepted it into the to-be-sent table
const sendEmailSuccess = () => ({
  type: actionTypes.SEND_EMAIL_SUCCESS,
});

const sendEmailFail = (error) => ({
  type: actionTypes.SEND_EMAIL_FAIL,
  error
});


// Send email
export const sendEmail = (emailContent, id) => dispatch => {
  dispatch(sendEmailStart(emailContent));
  const newEmailRef = id ? emailRef.doc(id) : emailRef.doc();
  return newEmailRef.set(emailContent).then(() => {
    dispatch(sendEmailSuccess());
    return true;
  }).catch((error) => {
    dispatch(sendEmailFail(error))
    return error;
  });
};
