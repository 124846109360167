import { Language } from "./locales";
import { IS_DEVELOPMENT } from "./app";

export const ScreeningType = {
  ED: "emergency", 
  BECKSDEP: "becksdpr",
  ANXIETY: "anxiety", 
  COVID: "covid",
  OBGYN: "obgyn",
  OBGYN_PLUS: "obgynplus", 
  OB_PAK: "obpak",
  RESPINFECT: "respinfect",
  TEST_AND_DEBUG: "test~"
}

// Human readable names for each screening type
export const ScreenerNames = {
  [ScreeningType.ED]: "Emergency Dept Intake", 
  [ScreeningType.OBGYN]: "OB/GYN Pre-visit Interview",
  [ScreeningType.OBGYN_PLUS]: "New OB/GYN Extended Interview",
  [ScreeningType.OB_PAK]: "OB AKU Questionnaire",
  [ScreeningType.COVID]: "COVID-19 Risk Screener",
  [ScreeningType.RESPINFECT]: "Respiratory Infections",
  [ScreeningType.BECKSDEP]: "Beck's Depression",
  [ScreeningType.ANXIETY]: "Beck's Anxiety",
  [ScreeningType.TEST_AND_DEBUG]: "TESTING SCREENER"
}
export const ScreenerShortNames = {
  [ScreeningType.ED]: "ED", 
  [ScreeningType.OBGYN]: "OB/GYN",
  [ScreeningType.OBGYN_PLUS]: "OB/GYN Extended",
  [ScreeningType.OB_PAK]: "OB",
  [ScreeningType.COVID]: "COVID-19",
  [ScreeningType.RESPINFECT]: "Resp. Infections",
  [ScreeningType.BECKSDEP]: "Beck's Dep Inventory",
  [ScreeningType.ANXIETY]: "Beck's Anxiety", 
  [ScreeningType.TEST_AND_DEBUG]: "TESTING"
}
export const ScreenerBadgeClasses = {
  [ScreeningType.ED]: "badge-danger", 
  [ScreeningType.OBGYN]: "badge-primary",
  [ScreeningType.OBGYN_PLUS]: "badge-primary",
  [ScreeningType.OB_PAK]: "badge-primary",
  [ScreeningType.COVID]: "badge-dark",
  [ScreeningType.RESPINFECT]: "badge-warning",
  [ScreeningType.BECKSDEP]: "badge-success",
  [ScreeningType.ANXIETY]: "badge-info", 
  [ScreeningType.TEST_AND_DEBUG]: "badge-warning"
}

// export const NominalResponses = {
//   [ScreeningType.ED]: ["None of these", "not reported"],
//   [ScreeningType.OBGYN]: ["None of these", "not reported"],
//   [ScreeningType.OBGYN_PLUS]: ["None of these", "not reported"],
//   [ScreeningType.OB_PAK]: ["None of these", "not reported"],
//   [ScreeningType.COVID]: ["None of these", "not reported"],
//   [ScreeningType.RESPINFECT]: ["None of these", "not reported"],
//   [ScreeningType.BECKSDEP]: ["None of these", "not reported"],
//   [ScreeningType.TEST_AND_DEBUG]: ["None of these", "not reported"]
// };

/**
 * This setting determines which specific locales are allowed for each screener.
 * At present these are only parameterized by language, but in the future add'l
 * locale details may be incorporated.
 */
export const AllowedPairings = {
  [ScreeningType.ED]: [Language.English],
  [ScreeningType.BECKSDEP]: [Language.English, Language.Spanish],
  [ScreeningType.ANXIETY]: [Language.English], 
  [ScreeningType.COVID]: [Language.English],
  [ScreeningType.RESPINFECT]: [Language.English],
  [ScreeningType.OBGYN]: [
    Language.Arabic,
    Language.Burmese,
    Language.English,
    Language.Spanish,
    Language.Swahili,
    Language.Test
  ],
  [ScreeningType.OBGYN_PLUS]: [Language.English],
  [ScreeningType.OB_PAK]: [Language.English, Language.Urdu]
};

// screeners that are only available in dev environments (i.e. NODE_ENV is set
// to 'development', which will usually only happen during the `npm start` cmd)
export const AdditionalPairingsForDev = {
  [ScreeningType.ED]: [],
  [ScreeningType.BECKSDEP]: [],
  [ScreeningType.ANXIETY]: [], 
  [ScreeningType.COVID]: [Language.Arabic],
  [ScreeningType.RESPINFECT]: [],
  [ScreeningType.OBGYN]: [],
  [ScreeningType.OBGYN_PLUS]: [],
  [ScreeningType.OB_PAK]: [Language.English],
  [ScreeningType.TEST_AND_DEBUG]: [Language.English]
};

/**
 * Check whether a particular screening type & language is allowed in the
 * current environment.
 * @param {string} screener 
 * @param {string} lang 
 * @returns {boolean}
 */
export function allows (screener, lang, purpose) {
  if (lang === Language.UNDETERMINED && purpose === "enrollment") return true;
  if (Array.isArray(AllowedPairings[screener]) && AllowedPairings[screener].includes(lang)) {
    return true;
  } else if (IS_DEVELOPMENT) {
    return (Array.isArray(AdditionalPairingsForDev[screener]) && AdditionalPairingsForDev[screener].includes(lang));
  }
  return false;
}

export const FallbackLang = Language.English;

// The "Assignable" arrays determine which screeners are valid for enrollment
// in each environment type (a screener needs to be listed here AND in the org's
// whitelist in order to appear on the patient enrollment form, for example)
export const DevAssignable = [
  ScreeningType.ED,
  ScreeningType.OBGYN,
  ScreeningType.BECKSDEP,
  ScreeningType.ANXIETY,
  ScreeningType.COVID,
  ScreeningType.RESPINFECT,
  ScreeningType.OBGYN_PLUS,
  ScreeningType.OB_PAK,
  ScreeningType.TEST_AND_DEBUG
];
export const ProdAssignable = [
  ScreeningType.OBGYN,
  ScreeningType.OBGYN_PLUS,
  ScreeningType.BECKSDEP,
  ScreeningType.ANXIETY,
  ScreeningType.COVID,
  ScreeningType.ED
];
export const Assignable = IS_DEVELOPMENT ? DevAssignable : ProdAssignable;

export function hasDynamicBodyTypes (screener) {
  return [ScreeningType.TEST_AND_DEBUG, ScreeningType.ED, ScreeningType.COVID].includes(screener);
}


// Aliases for filename lookups, useful for migrations in names or when two
// screeners are exactly the same / have yet to diverge.
export const FileAliases = new Map([
  // ["original_en.json", "replacement-file-to-load.json"]  // example entry
]);


export const PageType = {
  Empty: "EMPTY",
  QuestionPage: "question",
  QuestionGroup: "question-group",
  QuestionTable: "question-table",
  SectionIntro: "section intro",
  StaticContent: "static content",
  // DemographicData: "demographics",
  // EncouragementMessage: "encouragement",
  SubmitPage: "submit",
  ReviewReport: "review",
}
