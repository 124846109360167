import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom"; // Link,
import * as ROUTES from "../../constants/routes";
// import { ROLES } from "../../constants/roles";
import { Row, Col, Container } from "../UI/Grid/Grid";
import Button from "react-bootstrap/Button";
import { updateObject } from "../UI/Input/helpers";
import { Input } from "../UI/Input/Input";
import Alert from "../UI/Alert/Alert";
import { FaEnvelope, FaRegLightbulb } from "react-icons/fa";
import { waitlist } from "../../store/actions";

const WaitlistPage = ({
  onWaitlist, //onSignup
  email,
  error,
  isError,
}) => {
  const [options, setOptions] = useState([
    {
      value: "",
      displayValue: "Choose a reason",
    },
    {
      value: "I am a healthcare provider",
      displayValue: "I am a healthcare provider",
    },
    { value: "I am a patient", displayValue: "I am a patient" },
    { value: "Others", displayValue: "Others" },
  ]);

  const INITIAL_STATE = {
    email: {
      required: true,
      label: "Email",
      placeholder: "Email",
      inputIcon: FaEnvelope,
      type: "Email",
      elementType: "input",
      value: email,
      valid: false,
      viewErrors: true,
      viewSuccess: email ? false : true,
      viewInfo: false,
      initialValidate: email ? true : false,
      rules: { email: true },
    },
    reason: {
      label: "Reason of interests",
      inputIcon: FaRegLightbulb,
      type: "text",
      elementType: "select",
      value: "",
      valid: false,
      viewErrors: false,
      viewSuccess: true,
      viewInfo: true,
      initialValidate: false,
      options,
      rules: {
        required: true,
      },
    },
  };

  const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

  const ERROR_MSG_ACCOUNT_EXISTS = `
	An account with this E-mail address already exits.
	You are already on our subscription list.
`;

  const [waitlistForm, setWaitlistForm] = useState({ ...INITIAL_STATE });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isShowErrors, setIsShowErrors] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  //Called with each element change
  const inputChangedHandler = (value, valid, inputIdentifier) => {
    const updatedFormElement = updateObject(waitlistForm[inputIdentifier], {
      value,
      valid,
    });
    const updatedForm = updateObject(waitlistForm, {
      [inputIdentifier]: updatedFormElement,
    });
    let isFormValid = true;
    for (let inputIdentifier in updatedForm) {
      isFormValid = updatedForm[inputIdentifier].valid && isFormValid;
    }

    setWaitlistForm(updatedForm);
    setIsFormValid(isFormValid);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    let isValid = true;
    for (let inputIdentifier in waitlistForm) {
      isValid = waitlistForm[inputIdentifier].valid && isValid;
    }
    if (!isValid) {
      let updatedForm = { ...waitlistForm };
      for (let key in updatedForm) {
        updatedForm[key].viewErrors = true;
        updatedForm[key].viewInfo = false;
        updatedForm[key].initialValidate = true;
      }
      setIsShowErrors(true);
      setWaitlistForm(updatedForm);
    } else {
      onWaitlist({
        email: waitlistForm.email.value,
        reason: waitlistForm.reason.value,
        dateTime: new Date(),
      });
      setIsSubmitted(true);
    }
    event.preventDefault();
  };

  const onCheck = () => {
    setIsAgreed(!isAgreed);
  };

  let content = "";
  content = (
    <div>
      <form onSubmit={submitHandler} autoComplete="off">
        {Object.keys(waitlistForm)
          .sort()
          .map((key) => (
            <Input
              key={key}
              id={key}
              elementType={waitlistForm[key].elementType}
              name={waitlistForm[key].name}
              label={waitlistForm[key].label}
              labelClassName="font-weight-bold"
              InputIcon={waitlistForm[key].inputIcon}
              placeholder={waitlistForm[key].placeholder}
              value={waitlistForm[key].value}
              required={waitlistForm[key].required}
              options={waitlistForm[key].options}
              rules={waitlistForm[key].rules}
              type={waitlistForm[key].type}
              autoComplete="false"
              changed={(value, valid) => inputChangedHandler(value, valid, key)}
              viewErrors={waitlistForm[key].viewErrors}
              viewSuccess={waitlistForm[key].viewSuccess}
              viewInfo={waitlistForm[key].viewInfo}
              initialValidate={waitlistForm[key].initialValidate}
            />
          ))}
        {/*<label className="form-check-label mb-3">
				<input
				name="checkbox"
				type="checkbox"
				className="checkbox mr-3"
				defaultChecked={false}
				onChange={onCheck} />
				I agree to the <Link to={ROUTES.TERMS_OF_SERVICE} rel="noopener noreferrer" target="_blank"><u className="text-info">Terms of Service</u></Link>
				and <Link to={ROUTES.PRIVACY_NOTICE} target="_blank" rel="noopener noreferrer"><u className="texx-info">Privacy Notice</u></Link>
 			</label>
 			{isShowErrors ? <p className="text-red">You need to agree to th term of service and privacy notice.</p> : ""}*/}
        <Button
          type="submit"
          className="btn-primary btn-block mt-3 btn-viridian-green"
        >
          Join
        </Button>
      </form>
      <p className="mt-4 mb-3 text-muted">&copy; LiteraSeed, Inc.</p>
    </div>
  );

  const waitlist = isSubmitted ? (
    <Redirect to={ROUTES.WAITLIST_THANKS} />
  ) : (
    <Container className="pl-0 pr-0">
      <Row className="justify-content-center mt-5">
        {isError ? <Alert error={error} /> : ""}
      </Row>
      <Row className="justify-content-center mt-5">
        <Col className="col-12 col-md-8 col-lg-6 text-center">
          <h1 className="h1 text-viridian-green text-center">
            Join the Waitlist
          </h1>
          <p>
            Please fill out the questions below to let us know a bit more about
            you. Thank you!
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-12 col-md-8 col-lg-6">
          <div className="bg-outline p-3 p-sm-5">{content}</div>
        </Col>
      </Row>
    </Container>
  );
  return waitlist;
};

const mapDispatchToProps = (dispatch) => {
  return {
    onWaitlist: (userData) => dispatch(waitlist(userData)),
  };
};

function mapStateToProps(state, ownProps) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isWaitlisted: true,
    email: state.auth.user.email
      ? state.auth.user.email
      : ownProps.location && ownProps.location.email
      ? ownProps.location.email
      : "",
    isAnonymous: state.auth.user && state.auth.user.isAnonymous ? true : false,
    isLoading: state.auth.isLoading,
    isError: state.auth.isError,
    error: state.auth.error,
    role: state.auth.user ? state.auth.user.role : "",
    redirectPath: ownProps.location.from
      ? ownProps.location.from
      : ROUTES.WAITLIST_THANKS,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitlistPage);
