import { combineReducers } from "redux";
import auth from "./auth";
import email from "./email";
import provider from './provider';
// import questionnaire from './questionnaire';
import report from "./report";
import session from './session';
import waitlist from './waitlist';

import audio from '../slices/audio';
import admin from "../slices/admin";
import education from "../slices/education";
import reports from "../slices/reports";
import users from "../slices/users";
import screenings from "../slices/screenings";
import questionnaire from "../slices/questionnaire";

export default combineReducers({
  // older reducer-only files
  audio,
  auth,
  email,
  provider,
  report,
  session,
  waitlist,
  // newer slice-based reducers
  admin,
  education,
  questionnaire,
  reports,
  users,
  screenings
});
