import React from "react";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "../UI/Grid/Grid";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import * as ROUTES from "../../constants/routes";
import "../../styles/landing-page.scss";
import { FaArrowRight } from "react-icons/fa";

const Hero = ({prelim, noButton}) => (
  <section id="hero">
  <Container>
    <Row className="align-items-center">
      <Col className="col-12 text-center text-lg-left col-lg-6 order-2 order-lg-1">
        <h1 className="mb-4 mt-lg-4 heading1 text-viridian-green uppercase">
          {prelim ?? ""} Reduce the risk of adverse medical events
        </h1>
        <h3 className="text-dark-grey heading3 pb-2">
          LiteraSeed makes a clinical encounter more productive by improving
          patient-doctor communication.
        </h3>
        {noButton ? null : <p className="mb-4">
        <Button
          href={ROUTES.PRODUCT}
          className="cta-primary text-white text-uppercase"
        >
          How It Works{" "}<FaArrowRight style={{position: "relative", top: "-1px"}}/>
        </Button>
        </p>}
      </Col>

      <Col className="col-12 col-lg-6 order-1 order-lg-2 bg-white full-width-image">
        <img
          className="img-fluid cartoon-img"
          src="images/generic_onboarding_3_1.png"
          alt="patient and doctor encounter"
        />
      </Col>
    </Row>
  </Container>
  </section>
);

export default Hero;
