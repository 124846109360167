// Signup actions
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

// Verify Email
export const SEND_EMAIL_VERIFICATION_START = "SEND_EMAIL_VERIFICATION_START";
export const SEND_EMAIL_VERIFICATION_SUCCESS = "SEND_EMAIL_VERIFICATION_SUCCESS";
export const SEND_EMAIL_VERIFICATION_FAIL = "SEND_EMAIL_VERIFICATION_FAIL";
export const EMAIL_VERIFIED_SUCCESS = "EMAIL_VERIFIED_SUCCESS";
export const EMAIL_VERIFIED_FAIL = "EMAIL_VERIFIED_FAIL";

// Send Email
export const SEND_EMAIL_START = "SEND_EMAIL_START";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL";

// Send SMS
export const SEND_SMS_START = "SEND_SMS_START";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_FAIL = "SEND_SMS_FAIL";

// Send SMS
export const SEND_HELP_SMS_START = "SEND_HELP_SMS_START";
export const SEND_HELP_SMS_SUCCESS = "SEND_HELP_SMS_SUCCESS";
export const SEND_HELP_SMS_FAIL = "SEND_HELP_SMS_FAIL";

// Schedule SMS
export const SCHEDULE_SMS_START = "SCHEDULE_SMS_START";
export const SCHEDULE_SMS_SUCCESS = "SCHEDULE_SMS_SUCCESS";
export const SCHEDULE_SMS_FAIL = "SCHEDULE_SMS_FAIL";

// Send Post Survey SMS
export const SEND_POST_SURVEY_START = "SEND_POST_SURVEY_START";
export const SEND_POST_SURVEY_SUCCESS = "SEND_POST_SURVEY_SUCCESS";
export const SEND_POST_SURVEY_FAIL = "SEND_POST_SURVEY_FAIL";

// Login actions
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// Logout actions
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

// Verify login
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

// reset password
export const AUTH_RESET_PASSWORD_START = "AUTH_RESET_PASSWORD_START";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAIL = "AUTH_RESET_PASSWORD_FAIL";

// Signin Anonymously
export const SIGNIN_ANONYMOUSLY_START = "SIGNIN_ANONYMOUSLY_START";
export const SIGNIN_ANONYMOUSLY_SUCCESS = "SIGNIN_ANONYMOUSLY_SUCCESS";
export const SIGNIN_ANONYMOUSLY_FAIL = "SIGNIN_ANONYMOUSLY_FAIL";

// Send Signin Email Link
export const SEND_SIGNIN_LINK_TO_EMAIL_START = "SEND_SIGNIN_LINK_TO_EMAIL_START";
export const SEND_SIGNIN_LINK_TO_EMAIL_SUCCESS = "SEND_SIGNIN_LINK_TO_EMAIL_SUCCESS";
export const SEND_SIGNIN_LINK_TO_EMAIL_FAIL = "SEND_SIGNIN_LINK_TO_EMAIL_FAIL";

// Signin Using Email Link
export const SIGNIN_EMAIL_LINK_START = "SIGNIN_EMAIL_LINK_START";
export const SIGNIN_EMAIL_LINK_SUCCESS = "SIGNIN_EMAIL_LINK_SUCCESS";
export const SIGNIN_EMAIL_LINK_FAIL = "SIGNIN_EMAIL_LINK_FAIL";

// Get User actions
export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAILURE";

// Get Report
export const GET_REPORT_START = "GET_REPORT_START";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";

// Get Public User Report
export const GET_PUBLIC_USER_REPORT_START = "GET_PUBLIC_USER_REPORT_START";
export const GET_PUBLIC_USER_REPORT_SUCCESS = "GET_PUBLIC_USER_REPORT_SUCCESS";
export const GET_PUBLIC_USER_REPORT_FAIL = "GET_PUBLIC_USER_REPORT_FAIL";

// Get Reports
export const GET_REPORTS_START = "report/MULTIPLE-REQUESTED";
export const GET_REPORTS_SUCCESS = "report/MULTIPLE-RECEIVED";
export const GET_REPORTS_FAIL = "report/MULTIPLE-FAILED";
export const PROVIDER_REPORTS_REQUESTED = "provider/REPORTS-REQUESTED";
export const PROVIDER_REPORTS_SUCCESS = "provider/REPORTS-RECEIVED";
export const PROVIDER_REPORTS_FAIL = "provider/REPORTS-FAILED";
export const PROVIDER_REPORT_ADDITION = "provider/REPORTS-ADDITION";
export const CREATE_REPORT_START = "report/CREATE";
export const CREATE_REPORT_SUCCESS = "report/CREATION-SUCCESS";
export const CREATE_REPORT_FAIL = "report/CREATION-FAILED";

// Create Public User report
export const CREATE_PUBLIC_USER_REPORT_START = "CREATE_PUBLIC_USER_REPORT_START";
export const CREATE_PUBLIC_USER_REPORT_SUCCESS = "CREATE_PUBLIC_USER_REPORT_SUCCESS";
export const CREATE_PUBLIC_USER_REPORT_FAIL = "CREATE_PUBLIC_USER_REPORT_FAIL";

// Create SYMPTOM report
// export const CREATE_SYMPTOM_REPORT_START = "CREATE_SYMPTOM_REPORT_START";
// export const CREATE_SYMPTOM_REPORT_SUCCESS = "CREATE_SYMPTOM_REPORT_SUCCESS";
// export const CREATE_SYMPTOM_REPORT_FAIL = "CREATE_SYMPTOM_REPORT_FAIL";

// Create ROS report
export const CREATE_ROS_REPORT_START = "CREATE_ROS_REPORT_START";
export const CREATE_ROS_REPORT_SUCCESS = "CREATE_ROS_REPORT_SUCCESS";
export const CREATE_ROS_REPORT_FAIL = "CREATE_ROS_REPORT_FAIL";

// // Update Report
// export const UPDATE_REPORT_START = "UPDATE_REPORT_START";
// export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
// export const UPDATE_REPORT_FAIL = "UPDATE_REPORT_FAIL";

// Remove Report
export const REMOVE_REPORT_START = "REMOVE_REPORT_START";
export const REMOVE_REPORT_SUCCESS = "REMOVE_REPORT_SUCCESS";
export const REMOVE_REPORT_FAIL = "REMOVE_REPORT_FAIL";

// New "questionnaire" events
export const QUESTIONNAIRE_REQUESTED = "questionnaire/REQUESTED";
export const QUESTIONNAIRE_RECEIVED = "questionnaire/RECEIVED";
export const QUESTIONNAIRE_FAILED = "questionnaire/FAILED";

// get providers
export const GET_PROVIDERS_START = "GET_PROVIDERS_START";
export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS";
export const GET_PROVIDERS_FAIL = 'GET_PROVIDERS_FAIL';

// Set prefered provider
export const SET_PREFERED_PROVIDER_START = "SET_PREFERED_PROVIDER_START";
export const SET_PREFERED_PROVIDER_SUCCESS = "SET_PREFERED_PROVIDER_SUCCESS";
export const SET_PREFERED_PROVIDER_FAIL = 'SET_PREFERED_PROVIDER_FAIL';

export const SAVE_PREFERED_PROVIDER_ID_SUCCESS = 'SAVE_PREFERED_PROVIDER_ID_SUCCESS';

// Waitlist subscription
export const WAITLIST_START = 'WAITLIST_START';
export const WAITLIST_SUCCESS = 'WAITLIST_SUCESS';
export const WAITLIST_FAIL = 'WAITLIST_FAIL';

// Feedback subscription
export const FEEDBACK_START = 'FEEDBACK_START';
export const FEEDBACK_SUCCESS = 'FEEDBACK_SUCESS';
export const FEEDBACK_FAIL = 'FEEDBACK_FAIL';

// Check pre created patient
export const CHECK_PRE_CREATED_PATIENT_START = 'CHECK_PRE_CREATED_PATIENT_START';
export const CHECK_PRE_CREATED_PATIENT_SUCCESS = 'CHECK_PRE_CREATED_PATIENT_SUCCESS';
export const CHECK_PRE_CREATED_PATIENT_FAIL = 'CHECK_PRE_CREATED_PATIENT_FAIL';

// Update user last name
export const UPDATE_USER_LAST_NAME_START = 'UPDATE_USER_LAST_NAME_START';
export const UPDATE_USER_LAST_NAME_SUCCESS = 'UPDATE_USER_LAST_NAME_SUCCESS';
export const UPDATE_USER_LAST_NAME_FAIL = 'UPDATE_USER_LAST_NAME_FAIL';

// Find audio file
export const FIND_AUDIO_FILE_START = 'FIND_AUDIO_FILE_START';
export const FIND_AUDIO_FILE_SUCCESS = 'FIND_AUDIO_FILE_SUCCESS';
export const FIND_AUDIO_FILE_FAIL = 'FIND_AUDIO_FILE_FAIL';

// Update current audio file
export const UPDATE_CURRENT_AUDIO_FILE_SUCCESS = 'UPDATE_CURRENT_AUDIO_FILE_SUCCESS';

export const OPEN_SESSION_FEATURE = "session/OPEN-FEATURE";
export const CLOSE_SESSION_FEATURE = "session/CLOSE-FEATURE";
