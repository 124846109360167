import React, { useEffect, Fragment } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import { Alert } from "@material-ui/lab"
import { nanoid } from "nanoid";
import { FaTimesCircle } from "react-icons/fa";

export function alertEvent (message, title = "Alert") {
  return {
    type: "alerts/NEW",
    payload: {title, message}
  }
}

export function snack (message, id = nanoid(), options = {}) {
  return {
    type: "snack/NEW",
    payload: {message, id, options}
  }
}

export function useAlert () {
  const d = useDispatch();
  return (message, title) => d(alertEvent(message, title));
}

export function confirmEvent (event, title, message) {
  return {
    type: "confirm/ASK",
    payload: {event, title, message}
  }
}

export const GlobalAlerts = () => {
  const activeAlerts = useSelector(s => s.session.alerts);
  const activeSnacks = useSelector(s => s.session.snacks || []);
  const confirmation = useSelector(s => s.session.confirmation);

  const dispatch = useDispatch();
  function dismiss () {
    dispatch({type: "alerts/DISMISS"});
  }
  function dismissSnack (s) {
    dispatch({type: "snack/DISMISS", payload: s.id});
  }
  function dismissAllSnacks () {
    activeSnacks.forEach(s => dismissSnack(s));
  }
  function affirmConfirmation () {
    dispatch(confirmation.event);
    dispatch({type: "confirm/DISMISS"});
  }
  function denyConfirmation () {
    dispatch({type: "confirm/DISMISS"});
  }

  function CloseIcon ({s}) {
    return <IconButton size="small" aria-label="close" color="inherit" onClick={() => dismissSnack(s)}>
        <FaTimesCircle fontSize="small" />
    </IconButton>
  }
  
  return <>
  <Modal show={activeAlerts.length > 0} onHide={() => dismiss()}>
    <Modal.Header>
      <Modal.Title>
        {activeAlerts.length <= 1 ? activeAlerts[0]?.title : "Multiple Alerts"}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {activeAlerts.map((alert, i) => <Fragment key={alert.message}>
        <div>
          {activeAlerts.length > 1 ? <h3>{alert.title}</h3> : null}
          {alert.message}
        </div>
        {i < (activeAlerts.length - 1) ? <hr/> : null}
      </Fragment>)}
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-primary" onClick={dismiss}>OK</button>
    </Modal.Footer>
  </Modal>

  <Modal show={!!confirmation} onHide={() => denyConfirmation()}>
      {confirmation ? <>
      <Modal.Header>
        <Modal.Title>
          {confirmation.title || "Confirm Action"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {confirmation.message || "Are you sure you want to do this?"}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-warning" onClick={() => denyConfirmation()}>No</button>
        <button className="btn btn-danger" onClick={() => affirmConfirmation()}>Yes</button>
      </Modal.Footer></> : null}
    </Modal>

  {activeSnacks.map((s, i) =>
  <Snackbar 
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    style={{bottom: `${i*3.5 + 1.5}rem`}}
    open={true}
    onClose={() => dismissSnack(s)}
    autoHideDuration={s.options?.timeout || 5000}
    key={s.id}>
      {s?.options?.style ?
      <Alert variant="filled" severity={s?.options?.style || "info"}>{s.message} <CloseIcon s={s}></CloseIcon></Alert> :
      <SnackbarContent key={s.id} message={s.message} action={<CloseIcon s={s}></CloseIcon>}/>}
  </Snackbar>
  )}
  </>
}