import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  isError: false,
  isSent: false,
  error: {}
};

const reducer = ( state = initialState, action ) => {
  switch (action.type) {
    case actionTypes.SEND_EMAIL_START:
      return {
        ...state,
        isLoading: true,
        isSent: true,
        isError: false
      };
    case actionTypes.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.SEND_EMAIL_FAIL:
    return {
      ...state,
      isLoading: false,
      isError: true,
      isSent: false,
      error: action.error
    };
    default:
      return state;
  }
};

export default reducer;
