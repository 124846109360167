// Provider account
export const PROVIDER_ACCOUNT = "PROVIDER_ACCOUNT";

export const ROLES = {
  PATIENT: "Patient",
  PROVIDER: "Provider",
  ADMIN: "Admin"
}

export const ACCOUNT = {
  // No account detected
  NO_ACCOUNT: 'NO_ACCOUNT',

  // User has email and password account (can be with email password signin method only
  // or email and password and passwordless)
  PASSWORD_ACCOUNT: 'PASSWORD_ACCOUNT',

  // Account has passwordless signin method only
  PASSWORDLESS_ACCOUNT: 'PASSWORDLESS_ACCOUNT',

  // Anonymous account
  ANONYMOUS_ACCOUNT: 'ANONYMOUS_ACCOUNT',
}

export const STATUS_FLAG = {
  COMPLETE: "complete"
};
