import React, {useEffect, useState} from 'react';
import { ContainerFluid } from '../Grid/Grid';
import classes from './Alert.module.scss';

const Alert = ( {error, success} ) => {

  const [message, setMessage] = useState("");
  const [customClass, setCustomClass] = useState('');

  useEffect( () =>{

    let updatedMessage = '';
    let updatedCustomClass = '';

    if (error) {
        updatedCustomClass = `alert alert-danger ${classes.CustomAlert}`;
        updatedMessage = error.message;
    } else if (success) {
      updatedCustomClass = `alert alert-success ${classes.CustomAlert}`;
      updatedMessage = success.message;
    }

    setMessage(updatedMessage);
    setCustomClass(updatedCustomClass);

  }, [error, success]);

  let content = '';
  if (message) {
    content = (
      <ContainerFluid>
        <div className={customClass} role="alert">
          <p>{message}</p>
        </div>
      </ContainerFluid>
    )
  }

  return content;
}

export default Alert;
