import {
  createAsyncThunk,
  createEntityAdapter, createSlice
} from "@reduxjs/toolkit";
import { Status } from "../../constants/communication";
import { db } from "../../database";
import { assignOnly } from "../../utils";


// export class DBCollectionEntity {
//   static CollectionRef;
//   static WriteVersion;
//   document;
// }

export class Report {
  static CollectionRef = db.collection('reports');
  static PublicCollectionRef = db.collection('publicCovidScreeningResponses');
  static WriteVersion = 3;

  /**
   * @type {string} The ID of the report itself
   */
  id;

  synthesized;

  /**
   * @type {string} The ID of the user who completed the report
   */
  userId;

  /**
   * @type {string} The ID of the user that requested the report or sent the initial invite
   */
  requesterId;

  organizationId;

  /**
   * @type {number} The version of the data structure this had while stored in the database
   */
  version;

  /**
   * @type {ScreeningType} Which screener this report is for
   */
  type;

  /**
   * @type {string} SemVer of the version of the screener this was generated with
   */
  screenerVersion;

  /**
   * @type {{[key: string]: Answer}}
   */
  answers = {};

  /**
   * @type {{lastName: string, dob: string}} Quick access to key details of submitting user (should match those of user with userId)
   */
  outcomes = {};

  /**
   * @type {{[key: string]: Answer}}
   */
  submitter;

  /**
   * @type {number} timestamp of the moment this was first submitted
   */
  t_submitted;

  /**
   * @type {Language} lang string for the language this was completed in
   */
  completionLanguage;

  /**
   * @type {number} number of seconds from questionnaire start to report creation
   */
  durationSeconds;

  /**
   * @constructor
   * @param {*} baseObject 
   */
  constructor (baseObject) {
    if (baseObject.doc) {
      this.id = baseObject.doc.id;
    } else if (baseObject.id) {
      // this.document = Report.CollectionRef.doc(baseObject.id);
      this.id = baseObject.id;
    } else {
      // this.document = Report.CollectionRef.doc();
      this.id = Report.CollectionRef.doc().id;
    }
    assignOnly(baseObject, this, [
      "userId",
      "requesterId",
      "organizationId",
      ["version", Report.WriteVersion],
      "type",
      "screenerVersion",
      ["answers", {}],
      ["outcomes", {}],
      "submitter",
      "durationSeconds",
      "t_submitted",
      "completionLanguage",
      ["synthesized", false]
    ]);
    
    switch (baseObject.version) {
      case 2:
        this.inputs = baseObject.inputs;
        console.log(`Incoming report object had version 2, including raw inputs array`);
        break;
      case Report.WriteVersion:
        console.log(`Incoming report object had current version (${Report.WriteVersion})`);
        break;
      default:
        console.warn(`Incoming report had unknown or absent version tag (${baseObject.version})`);
    }
  }

  static fromFirestore (snapshot, options) {
    return new Report({...snapshot.data(options), id: snapshot.id});
  }
  static toFirestore (reportObject) {
    if (!(reportObject instanceof Report))
      throw new Error("Only real Report objects are supported!");
    return assignOnly(
      {version: Report.WriteVersion},
      reportObject,
      [
        "id",
        "userId",
        "requesterId",
        "organizationId",
        "type",
        "screenerVersion",
        ["answers", {}],
        ["outcomes", {}],
        "submitter",
        "durationSeconds",
        ["t_submitted", Date.now()],
        "completionLanguage"
      ],
      true);
  }
  static FirestoreConverter = Report.CollectionRef.withConverter(Report);
  static PublicFirestoreConverter = Report.PublicCollectionRef.withConverter(Report);
}

const reportsAdapter = createEntityAdapter({
  selectId: report => report.id
});

const initialState = reportsAdapter.getInitialState(
    {status: Status.Unstarted, error: null, current: null});

export const loadReport = createAsyncThunk(
  "reports/load-single",
  async ({reportId, fromPublic}, thunkAPI) => {
    const report = await (fromPublic ? Report.PublicFirestoreConverter : Report.FirestoreConverter).doc(reportId).get();
    if (!report.exists)
      throw new Error(`Report ${reportId} could not be located`);
    return {...report.data(), id: reportId};
  }
);

export const loadReportsByUser = createAsyncThunk(
  "reports/load-by-user",
  async (id, thunkAPI) => {
    const reportSnapshot = await Report.FirestoreConverter.where('userId', '==', id).get();
    return reportSnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
  }
);

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    synthesizeCurrent (state, action) {
      const report = new Report({
        userId: action.payload.userId,
        type: action.payload.questionnaire.screener,
        screenerVersion: action.payload.questionnaire.version,
        answers: action.payload.answers,
        completionLanguage: action.payload.questionnaire.locale.language,
        synthesized: true
      });
      reportsAdapter.upsertOne(state, report);
    },
    clearSynthesized (state, action) {
      const ids = state.ids.filter(i => state.entities[i]?.userId === action.payload.userId && state.entities[i]?.synthesized === true);
      if (ids.length > 0) {
        reportsAdapter.removeMany(state, ids);
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(loadReport.fulfilled,
      (state, action) => reportsAdapter.upsertOne(state, action.payload));
    builder.addCase(loadReportsByUser.fulfilled,
      (state, action) => reportsAdapter.upsertMany(state, action.payload))
  }
});

const reportSelectors = reportsAdapter.getSelectors(s => s.reports);
export const getReportById = reportSelectors.selectById;
export const getAllReports = reportSelectors.selectAll;
export const getReportsByUserId = (state, userId) => getAllReports(state).filter(r => r.userId === userId);

export const { synthesizeCurrent, clearSynthesized } = reportsSlice.actions;
export default reportsSlice.reducer;