// Public
export const LANDING = "/";
export const ABOUT = "/about";
export const PRODUCT = "/product";
// export const TEAM = '/team';
export const CONTACT = "/contact";
export const FAQ = "/faq";
export const PRESS = "/press";
export const CAREERS = "/careers";
export const GUIDE = "/how-it-works"; 
export const RESULTS = "/results"; 

export const SCREENING = "/screening";
export const COVID_SCREENING = "/covid";
export const COVID_REPORT = "/covid/report"

// Access
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const SIGN_OUT = "/signout";
export const THANKS = "/thanks";
export const VERIFY = "/verify";
export const TERMS_OF_SERVICE = "/termsofservice";
export const PRIVACY_NOTICE = "/privacynotice";
export const WAITLIST = "/waitlist";
export const WAITLIST_THANKS = "/waitlistthanks";

// Private
export const HOME = "/home";
export const VISIT = "/visit";
export const HISTORY = "/history";
export const REPORTS = "/reports";
export const RECORD = "/record";
export const SEND_REPORT = "/sendemail";

export const SIMPLE_ED_MODULE = "/learning/module";

// Anonymous
export const SIGN_IN_ANONYMOUS = "/anonymous/signin";
export const HISTORY_ANONYMOUS = "/anonymous/history";
export const HOME_ANONYMOUS = "/anonymous/home";
export const REPORTS_ANONYMOUS = "/anonymous/reports";
export const SEND_EMAIL_ANONYMOUS = "/anonymous/sendemail";
export const SYMPTOM_ANONYMOUS = "/anonymous/symptom";

// Provider
export const PROVIDER = "/provider";
export const PROVIDER_PATIENT_DETAILS = "/provider/patient";
export const PROVIDER_ENROLL_PATIENT = "/provider/enroll";
export const PROVIDER_SIGN_IN = "/provider/signin";
export const PROVIDER_SIGN_UP = "/provider/signup";
export const ORGANIZATION_ADMIN = "/provider/orgsettings";

// Account
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/pw-forget";

// Restricted Roles
export const ADMIN = "/admin";
export const SCHEDULER_ANALYTICS = "/analytics/dashboard"; 
export const ORGANIZATION_DETAILS = "/admin/org";

export function acceptsReportParam (route) {
  return [REPORTS, COVID_REPORT, REPORTS_ANONYMOUS].includes(route);
}
