import { createAction, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../constants/communication";
import { db } from "../../database";
import { getRelationsChunked } from "../../utils/promises";
import { getClinicianColleagues } from "../actions";

const usersAdapter = createEntityAdapter();
const reportsAdapter = createEntityAdapter();
const screeningsAdapter = createEntityAdapter();
const ticketsAdapter = createEntityAdapter();

const initialState = {
  users: usersAdapter.getInitialState({status: Status.Unstarted, error: null}),
  reports: reportsAdapter.getInitialState({status: Status.Unstarted, error: null}),
  tickets: ticketsAdapter.getInitialState(),
  screenings: screeningsAdapter.getInitialState({status: Status.Unstarted, error: null}),
  organizations: {},
  as: {organizationId: null}
};

export const adminUsersRequested = createAsyncThunk(
  'admin/users',
  async (_, thunkAPI) => {
    const snapshot = await db.collection('users').get();
    return snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
  }
);
export const adminOrgsRequested = createAsyncThunk(
  'admin/organizations',
  async (_, thunkAPI) => {
    const snapshot = await db.collection('organizations').get();
    return snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
  }
);

export const adminScreeningsRequested = createAsyncThunk(
  'admin/screenings',
  async ({start, end}, thunkAPI) => {
    const snapshot = await db.collection('screenings').where("t_created", ">", start).where("t_created", "<", end).get();
    const idsNeeded = snapshot.docs.map(d => d.id);
    const messages = await getRelationsChunked(idsNeeded, db.collection('externalMessages'), 'screeningId');
    const screenings = snapshot.docs.map(doc => ({...doc.data(), id: doc.id, REL_externalMessages: messages.filter(m => m.screeningId === doc.id)}));
    return screenings;
  }
);

export const adminReportsRequested = createAsyncThunk(
  'admin/reports',
  async (_, thunkAPI) => {
    const snapshot = await db.collection('reports').orderBy('t_submitted', 'desc').limit(200).get();
    return snapshot.docs.map(doc => ({...doc.data()}));
  }
);

export const adminPublicReportsRequested = createAsyncThunk(
  'admin/reports-PUBLIC',
  async (_, thunkAPI) => {
    const snapshot = await db.collection('publicCovidScreeningResponses').orderBy('t_submitted', 'desc').limit(200).get();
    return snapshot.docs.map(doc => ({...doc.data()}));
  }
);

export const adminTicketsRequested = createAsyncThunk(
  'admin/all-tickets',
  async () => {
    const snapshot = await db.collection('userTickets').get();
    return snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
  }
);
export const userTicketRequested = createAsyncThunk(
  'admin/ticket',
  async (id) => {
    const snap = await db.collection('userTickets').doc(id).get();
    if (snap.exists) {
      const ticketData = snap.data();
      const additionalData = {};
      if (ticketData.sessionDataId) {
        const sessionSnap = await db.collection('users').doc(ticketData.userId).collection('sessions').doc(ticketData.sessionDataId).get();
        if (sessionSnap.exists) {
          additionalData.sessionData = sessionSnap.data();
        }
      }
      return {...ticketData, ...additionalData, id: snap.id};
    } else {
      return false;
    }
  }
)
export const updateUserTicket = createAsyncThunk("admin/ticket-update", async ({id, changes}) => {
  return (await db.collection("userTickets").doc(id).update(changes));
})

export const getUserOrganization = createAsyncThunk("organization", async (orgId, thunkAPI) => {
  const orgObject = await db.collection('organizations').doc(orgId).get();
  if (orgObject.exists) {
    return {...orgObject.data(), id: orgId};
  } else {
    throw new Error(`Organization ${orgId} could not be found!`);
  }
});

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    emulateIdentity (state, action) {
      if (!action.payload) {
        // if the payload is falsy, we reset emulation
        state.as = {
          organizationId: null
        };
        return;
      }
      if (action.payload?.userId) throw new Error(`Emulating users is not yet supported (rec'd userId ${action.payload.userId}`);
      if (action.payload?.organizationId) {
        state.as.organizationId = action.payload.organizationId;
      }
    }
  },
  extraReducers: builder => {
    // users
    // builder.addCase(adminUsersRequested.fulfilled, (state, action) => {
    //   usersAdapter.setAll(state.users, action.payload);
    //   state.users.status = Status.Ready;
    // });
    // builder.addCase(adminUsersRequested.pending, (state, action) => {
    //   state.users.status = Status.Loading;
    // });
    // builder.addCase(adminUsersRequested.rejected, (state, action) => {
    //   state.users.status = Status.LoadFailed;
    //   state.users.error = action.error;
    // });
    // reports
    // TODO: all the report reducers should get merged into one slice later
    builder.addCase(adminReportsRequested.fulfilled, (state, action) => {
      reportsAdapter.upsertMany(state.reports, action.payload);
      state.reports.status = Status.Ready;
    });
    builder.addCase(adminReportsRequested.pending, (state, action) => {
      state.reports.status = Status.Loading;
    });
    builder.addCase(adminReportsRequested.rejected, (state, action) => {
      state.reports.status = Status.LoadFailed;
      state.reports.error = action.error;
    });
    builder.addCase(adminPublicReportsRequested.fulfilled, (state, action) => {
      reportsAdapter.upsertMany(state.reports, action.payload.map(r => ({FROM_PUBLIC: true, ...r})));
    });
    builder.addCase(userTicketRequested.fulfilled, (state, action) => {
      if (action !== false) {
        ticketsAdapter.upsertOne(state.tickets, {...action.payload, id: action.meta.arg});
      }
    });
    builder.addCase(adminTicketsRequested.fulfilled, (state, action) => {
      ticketsAdapter.upsertMany(state.tickets, action.payload);
    });
    builder.addCase(updateUserTicket.fulfilled, (state, action) => {
      ticketsAdapter.updateOne(state.tickets, action.meta.arg);
    });
    builder.addCase(adminScreeningsRequested.fulfilled, (state, action) => {
      screeningsAdapter.upsertMany(state.screenings, action.payload);
      state.screenings.status = Status.Ready;
    });
    builder.addCase(getUserOrganization.fulfilled, (state, action) => {
      const existingOrgData = state.organizations[action.meta.arg] || {};
      state.organizations[action.meta.arg] = {...existingOrgData, ...action.payload};
    });
    builder.addCase(adminOrgsRequested.fulfilled, (state, action) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach(d => state.organizations[d.id] = {loaded: true, ...d});
      } else {
        console.error("Got non-array org result payload", action.payload);
      }
    });
    builder.addCase(getClinicianColleagues.fulfilled, (state, action) => {
      const existingOrgData = state.organizations[action.meta.arg] || {};
      const inactiveCount = action.payload.filter(u => u.active === false).length;
      if (inactiveCount > 0) {
        console.warn(`Ditching ${inactiveCount} inactive colleagues from list...`);
      }
      state.organizations[action.meta.arg] = {...existingOrgData, users: action.payload.filter(u => u.active !== false)};
    });
  }
});

const userSelectors = usersAdapter.getSelectors(state => state.admin.users);
export const getAllUsers = userSelectors.selectAll;
export const getUserById = userSelectors.selectById;
const reportSelectors = reportsAdapter.getSelectors(state => state.admin.reports);
export const getAllReports = reportSelectors.selectAll;
export const getReportById = reportSelectors.selectById;
const ticketSelectors = ticketsAdapter.getSelectors(state => state.admin.tickets);
export const getAllTickets = ticketSelectors.selectAll;
export const getTicketById = ticketSelectors.selectById;

const screeningSelectors = screeningsAdapter.getSelectors(state => state.admin.screenings);
export const getAllScreenings = screeningSelectors.selectAll;
export const getScreeningById = screeningSelectors.selectById;

export const emulateIdentity = adminSlice.actions.emulateIdentity;

export default adminSlice.reducer;