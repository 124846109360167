import React from "react";
import { Container, Row, Col } from "../UI/Grid/Grid";
import Footer from "../Footer/Footer";
import Banner from "../Footer/Banner";
import Button from "react-bootstrap/Button";
import "../../styles/landing-page.scss";
import "../../styles/footer.css";

import {
  FaHandsHelping,
  FaLanguage,
  FaHandHoldingHeart,
  FaVial,
  FaCheck,
  FaArrowRight,
} from "react-icons/fa";

import * as ROUTES from "../../constants/routes";

import Hero from "./hero";
import { Link } from "react-router-dom";

// Use this to set a current banner
const CURRENT_ALERT = <></>;
/** ALERT BANNER TEMPLATE
<div className="landing-alert">
  <div className="alert alert-warning" role="alert">
    <strong>Breaking 9/1/21</strong> &mdash;&nbsp;
    <Link to={`${ROUTES.PRESS}/20210901-SBIR`}>
      LiteraSeed Awarded NSF Grant to Accelerate Development of Health Literacy App for Underserved Populations
    </Link>
  </div>
</div>
 */

const Education = () => (
  <section
    id="informational"
    className="bg-pattern"
  >
    <Container>
      <Row>
        <Col>
          <div
          className="text-dark-grey bg-white content-box content-box-outer top-border"
          style={{letterSpacing: "0.05px", lineHeight: "40px"}}
          >
            Good health communication is key to <span className="bg-light-orange"><strong>high quality</strong></span> &amp; <span className="bg-light-orange"><strong>safe
            healthcare</strong></span> delivery. Yet current patient communication with
            providers is slow, inefficient, and error-prone, which <span className="bg-light-orange"><strong>increases
            costs</strong></span> and <span className="bg-light-orange"><strong>puts lives at risk</strong></span>.
          </div>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          <div
          className="text-dark-grey bg-white content-box" style={{letterSpacing: "0.05px", lineHeight: "40px"}}
          >
            <div style={{maxWidth: "320px", margin: "auto"}}>
              <span className="text-viridian-green magnify-text" style={{display: "block"}}>
                78%
              </span>
              of medical errors are caused by poor health
              communication and result in at least
              <span className="text-viridian-green magnify-text magnify-text-middle" style={{display: "block"}}>
                200,000
              </span>
               avoidable hospital deaths each year in the U.S.
            </div>
          </div>
        </Col>
        <Col>
          <div className="text-dark-grey bg-white content-box content-box-middle" style={{letterSpacing: "0.05px", lineHeight: "40px"}}>
          <div style={{maxWidth: "335px", margin: "auto"}}>
            <img src="/images/language_icon.png" style={{display: "block", margin: "auto", height: "75px"}}/>{" "}
            Patients with language, and cultural barriers are 
            <span className="text-viridian-green magnify-text magnify-text-middle" style={{display: "block"}}>
              2X
            </span>
              disproportionately affected by medical errors.
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="bg-white content-box content-box-outer bottom-border">
            <div className="text-dark-grey mb-4">
            Poor health communication <span className="bg-light-orange"><strong>costs</strong></span> the U.S. healthcare system:{" "}
            </div>
            <Row>
              <Col className="text-dark-grey">
                <div className="text-viridian-green magnify-text">
                $19.5B
                </div>
                <div style={{maxWidth: "120px", margin: "auto", letterSpacing: "0.05px", lineHeight: "40px"}}>
                in total direct costs
                </div>
              </Col>
              <Col className="text-dark-grey content-box-middle-text">
              <div className="text-viridian-green magnify-text">
                  $386,849
              </div>
              <div style={{maxWidth: "180px", margin: "auto", letterSpacing: "0.05px", lineHeight: "40px",}}>
                per legal claim of misdiagnosis
              </div>
              </Col>
              <Col className="text-dark-grey">
                <div className="text-viridian-green magnify-text">
                  $58,776
                </div>
                <div style={{maxWidth: "180px", margin: "auto", letterSpacing: "0.05px", lineHeight: "40px",}}>
                per each individual injury
                 </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

const Features = () => (
  <section id="features" className="spacer2">
    <Container className="">
      <h2 className="text-center h1 text-viridian-green">3 Easy Steps:</h2>
      <Row>
        <Col className="col-12 col-lg-6 order-2 order-lg-1 text-center text-lg-left my-auto pt-5 pt-lg-0">
          <h2 className="text-capitalize text-viridian-green">
            Tell your story in pictures
          </h2>
          <p className="TextContainer">
            {" "}
            Our visual symptom guide helps you describe what you're feeling,
            even if you don't know the words for it in English.
            <br />
            <br />
            All you do is browse our library of simple and clear illustrations
            and click the ones that match your symptoms.
          </p>
        </Col>
        <Col className="col-12 col-lg-6 order-1 order-lg-2 text-center">
          <img
            className="img-fluid"
            src="images/visual-symptoms.jpg"
            alt="talk about symptoms visually"
          />
        </Col>
      </Row>
      <Row className="mt-5 mt-lg-0">
        <Col className="col-12 col-lg-6 pt-lg-0 text-center">
          <img
            className="img-fluid"
            src="images/secure-send.jpg"
            alt="securely send report"
          />
        </Col>
        <Col className="col-12 col-lg-6 my-auto text-center text-lg-left">
          <h2 className="text-capitalize text-viridian-green">
            Share with your doctor
          </h2>
          <p className="TextContainer">
            {" "}
            When you finish recording your symptoms, you'll get a summary with
            all the key facts your doctor needs to know.
            <br />
            <br />
            You can securely send your report to your doctor, print to share in
            person, or use it as a guide during your next appointment.
          </p>
        </Col>
      </Row>
      <Row className="mt-5 mt-lg-0">
        <Col className="col-12 col-lg-6 order-2 order-lg-1 text-center text-lg-left my-auto pt-5 pt-lg-0">
          <h2 className="text-capitalize text-viridian-green">
            Get the right treatment, quickly
          </h2>
          <p className="pb-5 TextContainer">
            {" "}
            Now that you clearly communicated your symptoms, your doctor will be
            better prepared to give you the care you need.
          </p>
          <Button
            href={ROUTES.WAITLIST}
            className="pl-3 pr-3 mt-5 btn btn-primary btn-lg btn-viridian-green font-weight-bold"
          >
            Join Waitlist
          </Button>
        </Col>
        <Col className="col-12 col-lg-6 order-1 order-lg-2">
          <img className="img-fluid" src="images/right-care.png" alt="" />
        </Col>
      </Row>
    </Container>
  </section>
);

const Solution = () => (
  <section id="solution" className="justify-content-center bg-light-orange">
    <Container style={{maxWidth: "845px"}}>
      <h2 className="text-uppercase text-magenta-pink">
        LiteraSeed is the Solution
      </h2>
      <p className="paragraph">
        <strong>We enable patients to communicate clearly</strong>
      </p>
      <p className="paragraph">
        LiteraSeed helps patients <strong>express how they feel</strong> before they visit a
        doctor by using a visual-based web application for health communication.
        We help patients get the right care, at the right time.
      </p>
      <p className="paragraph">
        Designed for <strong>universal accessibility</strong> and HIPAA-compliant communication,
        LiteraSeed can be especially advantageous for public health providers
        that serve a disproportionately high number of <strong>patients who do not speak
        English</strong>.
      </p>
    </Container>
  </section>
);

const About = () => (
  <section id="about" className="bg-viridian-green-gradient">
    <Container className="pt-5">
      <h2 className="text-capitalize text-center mb-4 text-viridian-green">
        The right words can help you get better healthcare.
      </h2>
      <Row className="justify-content-center">
        <Col className="col-12 col-sm-6 col-md-4 col-lg-3 ColContainer text-center">
          <FaHandsHelping size={60} className="text-viridian-green" />
          <h3 className="pt-4 text-viridian-green">Build Trust</h3>
          <p className="pt-4 pt-md-2 pb-0">
            Make sure your doctor listens to and understands your concerns.
          </p>
        </Col>
        <Col className="col-12 col-sm-6 col-md-4 col-lg-3 ColContainer text-center">
          <FaHandHoldingHeart size={60} className="text-viridian-green" />
          <h3 className="pt-4 text-viridian-green">Quality Care</h3>
          <p className="pt-4 pt-md-2 pb-0">
            Describe symptoms effectively so you can get high-quality care.
          </p>
        </Col>
        <Col className="col-12 col-sm-6 col-md-4 col-lg-3 ColContainer text-center ">
          <FaLanguage size={60} className="text-viridian-green" />
          <h3 className="pt-4 text-viridian-green">Improved Access</h3>
          <p className="pt-4 pt-md-2 pb-0">
            Avoid doors closing on you because of language barriers.
          </p>
        </Col>
        <Col className="col-12 col-sm-6 col-md-4 col-lg-3 ColContainer text-center">
          <FaVial size={60} className="text-viridian-green" />
          <h3 className="pt-4 text-viridian-green">Timely Treatment</h3>
          <p className="pt-4 pt-md-2 pb-0">
            Avoid the risk of misdiagnosis and incorrect treatment from the
            start.
          </p>
        </Col>
      </Row>
    </Container>
  </section>
);

const Steps = () => (
  <section
    id="steps"
    className="bg-pattern"
  >
    <Container className="justify-content-center">
      <div
      className="bg-white content-box"
      >
        <h2 className="text-dark-grey text-uppercase">
          Three Easy Steps
        </h2>

        <Button
          href={ROUTES.PRODUCT}
          className="cta-primary btn text-white text-uppercase"
        >
          How It Works{" "}<FaArrowRight style={{position: "relative", top: "-1px"}}/>
        </Button>

        <Row className="justify-content-center" style={{lineHeight: "1.8em"}}>
          <Col className="col-12 col-md text-dark-grey my-4 my-md-auto">
            <img src="/images/hold-phone.png" className="icon-top"/><br /> 
            Patient signs <br /> into app
          </Col>
          <Col className="col-12 col-md text-dark-grey my-4 my-md-auto">
          <img src="/images/click-app.png" className="icon-top"/> <br/>
            Selects the pictures <br />
            that match symptoms
          </Col>
          <Col className="col-12 col-md text-dark-grey my-4 my-md-auto">
          <img src="/images/talk-app.png" className="icon-top"/> <br/>
            App shares results <br />
            with medical staff
          </Col>
        </Row>
      </div>
    </Container>
  </section>
);

const propositionsText = [
  "Ensure communication is not a barrier to life-saving care.",
  "Improve access to care for people with communication barriers.",
  "Increase patient adherence with their treatment plan.",
  "Reduce cost of care through timely treatment of symptoms.",
  "Improve patient satisfaction.",
  "Optimize time spent with patients."
];
const ValueProps = () => (
<section id="valueprops" className="bg-persian-green">
  <Container>
    <Row>
      <Col className="col-12 col-lg-6 my-5">
        <h2 className="text-capitalize text-center text-white font-weight-bold pb-2" style={{fontSize: "40px"}}>
          We Help Healthcare Providers
        </h2>
        <table className="text-white paragraph">
          <tbody>
            {propositionsText.map(text => <tr>
              <td><FaCheck style={{margin: "6px 12px", fontSize: "1.5rem"}}></FaCheck></td>
              <td>{text}</td>
            </tr>)}
          </tbody>
        </table>
      </Col>

      <Col className="col-12 col-lg-6">
        <img
          style={{"maxWidth": "100%"}}
          alt="Cartoon depiction of doctor administering a vaccine"
          src="/images/vaccination.png"
          className="cartoon-img" /> 
      </Col>
    </Row>
  </Container>
</section>
); 

const Partners = () => (
  <section id="partners"
    className="bg-pattern"
  >
    <Container className="justify-content-center">
      <div
      className="bg-white content-box"
      >
        <h1 className="text-viridian-green text-uppercase pb-5">
          Our Partners
        </h1>

        <Row className="text-center">
        <div className="col-sm-4">
          <img
            className="img-fluid partner-logo"
            src="images/Valleywise_Logo_Color.png"
            alt="Valleywise Health"
          />
        </div>
        <div className="col-sm-4">
          <img
            className="img-fluid partner-logo"
            src="images/Virginia_Tech_Logo.png"
            alt="Virgnia Tech"
          />
        </div>
        <div className="col-sm-4">
          <img
            className="img-fluid partner-logo"
            src="images/NSF-white-bg.png"
            alt="National Science Foundation"
          />
        </div>
      </Row>
      </div>
    </Container>
  </section>
);

// If re-enabled, compare with <Banner/> and update accordingly
// const Subscribe = () => {
//   return (
//     <Container>
//  <Row className="justify-content-center pb-4 pt-4 bg-light-cyan-blue">
//       <div id="mc_embed_signup">
//         <p className="font-weight-bold text-center text-magenta-pink">
//           Subscribe to our newsletter!
//         </p>
//         <form
//           action="https://literaseed.us15.list-manage.com/subscribe/post?u=75046940797701e74224deadf&amp;id=161a3e380a"
//           method="post"
//           id="mc-embedded-subscribe-form"
//           name="mc-embedded-subscribe-form"
//           className="validate"
//           target="_blank"
//           noValidate
//         >
//           <div id="mc_embed_signup_scroll">
//             <input
//               type="email"
//               name="EMAIL"
//               className="email col-md-8"
//               id="mce-EMAIL"
//               placeholder="email address"
//               required
//             />
//             {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
//             <div
//               style={{ position: "absolute", left: "-5000px" }}
//               aria-hidden="true"
//             >
//               <input
//                 type="text"
//                 name="b_75046940797701e74224deadf_161a3e380a"
//                 tabIndex="-1"
//                 defaultValue=""
//               />
//             </div>
//             <div className="clear">
//               <input
//                 type="submit"
//                 value="Sign up"
//                 name="subscribe"
//                 id="mc-embedded-subscribe"
//                 className="button btn ml-md-3 text-white border-green font-weight-bold bg-magenta-pink"
//                 style={{
//                   fontSize: "16px",
//                 }}
//               />
//             </div>
//           </div>
//         </form>
//       </div>
//     </Row>
//     </Container>
   
//   );
// };

const FAQ = () => (
  <section id="questions">
    <div className="banner-sm bg-persian-green">
      <Row className="justify-content-center pb-4 pt-4 m-0">
        <a href={ROUTES.FAQ} className="text-uppercase text-white" style={{fontSize: "15px"}}>Questions? Read our FAQs</a>
      </Row>
    </div>
  </section>
); 


const Landing = () => {
  return (
    <>
      {CURRENT_ALERT}
      <Hero />
      <Banner />
      <Education />
      <Solution />
      <Steps />
      <ValueProps /> 
      <Partners />
      {/* <Subscribe /> */}
      <FAQ /> 
      <Footer />
    </>
  );
};

export default Landing;
