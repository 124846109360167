// TODO: add option to edit HPI from the report, update saved report
// Todo: have options to update progress and track long term of same symptoms or condition

// NOTE: This appears unused right now.

import React from "react";
import Report from "./Report";

import "../../styles/homepage.css";
import "../../styles/report.css";

function ReportContainer(props) {
    return (
        <div className="home-container">
          <Report
            reportId={props.match.params.reportId}
            fromPublic={props.location.state?.fromPublic || false}
            from={props.location &&
                    props.location.from?
                    props.location.from :
                    ""}
            launchPrintDialog={props.location.state?.launchPrintDialog || false}
          />
        </div>
    );

}

export default ReportContainer;
