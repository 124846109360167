import { capitalize } from "@material-ui/core";
import { stackOffsetExpand } from "d3";
import React, { Fragment, lazy, Suspense, useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap/ModalHeader";
import { FaArrowLeft, FaArrowRight, FaPlusCircle, FaPlusSquare } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ScreenerNames } from "../../constants/screenings";
import { emptyAnswer } from "../../database/model/answer";
import { useTextDirection } from "../../i18n";
import { resolveAudioURLs } from "../../store/slices/audio";
import { changeAnswers, getCurrentQuestionnaire } from "../../store/slices/questionnaire";
import "../../styles/button.scss";
import classes from "../../styles/History.module.scss";
import { capitalizeFirstLetter, ordinals, pluralizeUnit, shortOrdinal, spliceOutOf } from "../../utils";
import PlayAudio from "../Audio/PlayAudio";
import { ResourceDialog } from "../Education/ResourceDialog";
import { snack } from "../UI/GlobalAlerts";
import { Col, Container, Row } from "../UI/Grid/Grid";
import { HighlightKeywords } from "../UI/HighlightKeywords";
import { ImageWithFallback } from "../UI/ImageWithFallback";
import "../UI/IntroCard/IntroCard.css";
import QuestionnairePage from "./QuestionnairePage";
import ResponseChoices from "./ResponseChoices";


const QuestionnaireTablePage = ({
  questionKey,
  activeTableKeys,
  answerKeys,
  handleBack,
  handleForward,
  navigationDisabled,
  inline,
  multiplexIndex,
  skipPredicate = () => false,
  breadcrumbNav
}) => {
  answerKeys = answerKeys ?? [...activeTableKeys];
  const questionnaire = useSelector(getCurrentQuestionnaire);
  const mainQuestion = useSelector(state =>
    getCurrentQuestionnaire(state)?.questions?.[questionKey] ?? {});
  const allQuestions = useSelector(state => {
    const q = getCurrentQuestionnaire(state)?.questions;
    if (q) {
      return activeTableKeys.map(k => q?.[k]);
    }
    return [];
  })
  const mainAnswer = useSelector(state => state.questionnaire.answers?.[questionKey] ?? emptyAnswer());
  const length = mainAnswer?.tableSize || 1;
  const rowIndices = useMemo(() => ordinals(length), [length]);
  const answers = useSelector(state => 
    {
      let obj = {};
      answerKeys.forEach(ak => {
        let col = [];
        for (let i = 0; i < length; i++) {
          col[i] = state.questionnaire.answers?.[indexedAnswer(ak, i)] ?? emptyAnswer();
        }
        obj[ak] = col;
      });
      return obj;
  });
  const hasAnswers = length > 1 || answerKeys.some(ak => answers[ak][0]?.answered);
  console.warn(answers);
  const dynamicText = useSelector(state => state.questionnaire.dynamicText[questionKey + ".table"])

  function indexedAnswer (key, rowIndex) {
    return `${key}[${rowIndex}]`;
  }

  const [editIndex, setEditIndex] = useState(-1);

  function acceptCurrentDialog () {
    if (answerKeys.some(ak => answers[ak]?.[editIndex]?.answered)) {
      setEditIndex(-1);
    } else {
      dispatch(snack("No answers were provided; deleting entry."));
      deleteRow(editIndex);
    }
  }

  const dispatch = useDispatch();
  const dir = useTextDirection();

  function addAnswer () {
    if (hasAnswers) {
      dispatch(changeAnswers({[questionKey]: {...mainAnswer, tableSize: length + 1, value: undefined}}));
      setEditIndex(length);
    } else {
      dispatch(changeAnswers({[questionKey]: {...mainAnswer, tableSize: 1, value: undefined}}));
      setEditIndex(0);
    }
  }

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  function maybeDelete () {
    if (hasAnswers) {
      setConfirmDeleteModal(true);
    }
  }
  function deleteAll (setToZero = false) {
    const indices = ordinals(length);
    const update = Object.fromEntries(
      answerKeys.flatMap(
        ak => indices.map(
          i => [indexedAnswer(ak, i), {answered: false, value: ""}]
    )));
    if (setToZero) {
      update[questionKey] = {...mainAnswer, answered: true, tableSize: 0, value: mainQuestion.tableEmptyValue || "N/A"};
    }
    dispatch(changeAnswers(update));
    setConfirmDeleteModal(false);
  }

  function deleteRow (i) {
    setEditIndex(-1);
    let update = {};
    answerKeys.forEach(ak => {
      for (let r = i; r < length - 1; r++) {
        // shift future rows back 1
        update[indexedAnswer(ak, r)] = {...answers[ak][r+1]};
      }
      update[indexedAnswer(ak, length -1)] = {answered: false, value: ""};
    });
    update[questionKey] = {...mainAnswer, tableSize: (length - 1) || 1};
    dispatch(changeAnswers(update));
  }

  /**
   * Scroll to top whenever the question changes
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [mainQuestion]);

  const debugMode = useSelector(s => s.session.debugMode);
  const USE_HORIZONTAL_RULE = true;

  const section = questionnaire.sections?.[mainQuestion.section];

  const ANSWER_DISPLAY_MODE = "card"; // "table"

  return (
    <Container>
      {(questionnaire.pageSettings?.breadcrumbs && (!inline || inline === "top")) ? <nav aria-label="breadcrumb">
        <ol className="breadcrumb mt-3">
          {[ScreenerNames[questionnaire.screener], section?.title, mainQuestion.subsection].filter(x => x).map((crumb, level) => 
            <li
              className="breadcrumb-item"
              style={questionnaire.pageSettings?.breadcrumbs === "no-link" ? {} : {"cursor": "pointer"}}
              onClick={() => breadcrumbNav?.(level)}>
              {crumb}
            </li>
          )}
        </ol>
      </nav> : null}

      <div className="headerWithSound">
        <h2 className={`${classes.QuestionText} ${(questionnaire.pageSettings?.breadcrumbs) ? "pt-0" : "pt-3 pt-md-5"} pb-2 pb-md-4`}>
          {(dynamicText || mainQuestion.tableText)}
          {debugMode ? <small title="DEBUG MODE KEY" className="text-muted float-right">{answerKeys?.join?.(", ")}</small> : null}
        </h2>
        <div className="headerSound">
          <PlayAudio filename={mainQuestion.tableAudioFileName} skipLoad={true}/>
        </div>
      </div>
      {USE_HORIZONTAL_RULE ? <>
        <hr className="modal-card-hr"/>
        <br />
      </> : null}

      {activeTableKeys.length > 0 ? null : <div className="alert alert-warning">The questions on this page are no longer relevant to you!</div>}

      {mainQuestion.tableImgUrl?
        <Row className={`justify-content-center pt-0 pt-md-3 mb-0 ${classes.Page}`}>
          {/* <Col className={`col col-6 col-md-4`}> */}
            <ImageWithFallback src={mainQuestion.tableImgUrl} style={{width: "300px"}} alt=""/>
          {/* </Col> */}
        </Row>
      : null}

      {ANSWER_DISPLAY_MODE === "table" ? <div className="card">
        <div className="card-header">
          <h3 className="card-title mb-0">Answers provided</h3>
        </div>
        <div className="card-body">
          {hasAnswers ? (<table className="table">
            <thead>
              <tr>
                <th>{capitalizeFirstLetter(mainQuestion.tableUnit) || "Entry"}</th>
                {activeTableKeys.map(qk => <th key={`header-${qk}`}>{qk}</th>)}
              </tr>
            </thead>
            <tbody>
              {rowIndices.map(i => <tr key={i}>
                <th><button className="btn btn-primary btn-sm" onClick={() => setEditIndex(i)}>{`Edit #${i+1}`}</button></th>
                {activeTableKeys.map((qk, qi) => <td key={`preview-${qk}-${i}`}>{answers[qk][i]?.value}</td>)}
              </tr>)}
            </tbody>
          </table>) : <h3 className="text-center">No {mainQuestion.tableUnit || "answer"} entered</h3>}
        </div>
        <div className="card-footer text-center">
          <button className="px-4 btn btn-success" onClick={() => addAnswer()}>Add {mainQuestion.tableUnit || "answer"}</button>
          <button className={`px-4 btn btn-info ${mainQuestion.answered && mainQuestion.tableSize === 0 ? "selected" : ""}`} onClick={() => maybeDelete(true)}>Continue with no {mainQuestion.tableUnit || "answers"}</button>
        </div>
      </div> : null}

      {ANSWER_DISPLAY_MODE === "card" ? <section>
        <h3 className="">Answers provided</h3>
        <Row className="justify-content-center">
        {hasAnswers ? rowIndices.map(i =>
          <div className="col col-12 col-md-6 py-2" key={`preview-card-${i}`}>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">{capitalizeFirstLetter(mainQuestion.tableUnit) || "Entry"} {i+1}</h3>
            </div>
            <div className="card-body">
              <ul className="mb-0">
                {activeTableKeys.map((qk, qi) => <li key={`preview-${i}-${qk}`}><strong>{allQuestions[qi]?.reportLabel || qk}</strong> {answers[qk][i]?.value || <span className="badge badge-dark">n/a</span>}</li>)}
              </ul>
            </div>
            <div className="card-footer">
              <Row>
                <div className="col col-6"><button className="btn btn-outline-primary w-100" onClick={() => setEditIndex(i)}>{`Edit`}</button></div>
                <div className="col col-6"><button className="btn btn-outline-danger w-100" onClick={() => deleteRow(i)}>{`Delete`}</button></div>
              </Row>
            </div>
          </div>
          </div>
          ) : <div className="col col-med-4"><div className="card"><div className="card-body text-muted"><h4>No {mainQuestion.tableUnit?.toLocaleLowerCase?.() || "answer"} entered</h4></div></div></div>}
        </Row>
        <div className="w-100 mt-2 text-center">
          <button className="px-4 m-2 btn btn-success px-4" onClick={() => addAnswer()}><FaPlusSquare className="mx-2"/> Add {mainQuestion.tableUnit || "answer"}</button>
          <button className={`px-4 m-2 btn btn-outline-primary ${mainAnswer.answered && mainAnswer.tableSize === 0 ? "selected" : ""}`} onClick={() => maybeDelete(true)}>Continue with No {mainQuestion.tableUnit || "answers"}</button>
        </div>
      </section> : null}

      <Modal show={editIndex !== -1} onHide={() => acceptCurrentDialog()} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{capitalizeFirstLetter(mainQuestion.tableUnit) || "Answer"} #{editIndex+1}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeTableKeys.map((k, i) =>
            <Fragment key={k}>
              {i > 0 ? <hr style={{margin: "1rem 0"}}/> : null}
              <QuestionnairePage
                inline={true}
                rowFormat={true}
                inlineStyles={{header_inner: {paddingBottom: "0px !important", fontSize: "1.4rem"}, postline_br: false}}
                questionKey={k}
                fullwidth={true}
                answerKey={indexedAnswer(k, editIndex)}
                // handleForward={() => {}}
                // handleBack={() => {}}
                // breadcrumbNav={() => {}}
                skipPredicate={skipPredicate}/>
            </Fragment>)}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={() => deleteRow(editIndex)}>Delete Answer</button>
          <button className="btn btn-success" onClick={() => acceptCurrentDialog()}>Accept and Close</button>
        </Modal.Footer>
      </Modal>

      <Modal show={!!confirmDeleteModal} onHide={() => setConfirmDeleteModal(false)}>
          <Modal.Body>
            <p>
              Selecting this button will delete all the answers you entered.
              Are you sure you want to continue?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={() => setConfirmDeleteModal(false)}>
              No
            </Button>
            <Button variant="info" onClick={() => deleteAll(true)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

      <div className={classes.Footer}>
        <div className={`text-center`}><small>&copy; All rights reserved</small></div>
        <div>
          {/* we might want to re-examine these as Bootstrap buttons... we're manually overriding the hover effect here for example */}
          <Button className="back-btn pl-3 pr-3 mr-3 ml-3" onClick={handleBack} disabled={!!navigationDisabled}>
            {dir === 'ltr' ? <FaArrowLeft /> : <FaArrowRight />} <span className="pr-2 font-weight-bold">Back</span>
          </Button>
          <Button className="forward-btn pl-3 pr-3 mr-3 ml-3 text-white" onClick={handleForward} disabled={!!navigationDisabled}>
            <span className="pr-2 font-weight-bold">Next</span> {dir === 'ltr' ? <FaArrowRight /> : <FaArrowLeft />}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default QuestionnaireTablePage;
