import { batch } from "react-redux";
import {db} from "../../database";
import { User, UserClinicStatus, UserInviteStatus, ScreeningStatus } from "../../database/model/user";
import { evaluator } from "../../utils/evaluator";
import * as actionTypes from './actionTypes';

const usersRef = db.collection('users');
const covidRef = db.collection('publicCovidScreeningResponses');

const createReportStart = (data) => ({
  type: actionTypes.CREATE_REPORT_START,
  payload: data
});
const createReportSuccess = (report) => ({
  type: actionTypes.CREATE_REPORT_SUCCESS,
  data: report
});
const createReportFail = (error) => ({
  type: actionTypes.CREATE_REPORT_FAIL,
  error
});

/* Create COVID report action functions */
const createPublicUserReportStart = () => ({
  type: actionTypes.CREATE_PUBLIC_USER_REPORT_START
});

const createPublicUserReportSuccess = (id, report) => ({
  type: actionTypes.CREATE_PUBLIC_USER_REPORT_SUCCESS,
  data: report,
  id
});

const createPublicUserReportFail = (error) => ({
  type: actionTypes.CREATE_PUBLIC_USER_REPORT_FAIL,
  error
});

/* Get report action functions */
// const getPublicUserReportStart = () => ({
//   type: actionTypes.GET_PUBLIC_USER_REPORT_START
// });

// const getPublicUserReportSuccess = (id, data) => ({
//   type: actionTypes.GET_PUBLIC_USER_REPORT_SUCCESS,
//   data,
//   id
// });

// const getPublicUserReportFail = (error) => ({
//   type: actionTypes.GET_PUBLIC_USER_REPORT_FAIL,
//   error
// });

// // Get reports
// const getReportsStart = (condition) => ({
//   type: actionTypes.GET_REPORTS_START,
//   payload: condition
// });

// const getReportsSuccess = (data) => ({
//   type: actionTypes.GET_REPORTS_SUCCESS,
//   data
// });

// const getReportsFail = (error) => ({
//   type: actionTypes.GET_REPORTS_FAIL,
//   error
// });


/**********
* Action thunks
***********/

// ** We might decide to collect some demographic data
// Get PUBLIC COVID-19 Report
// export const getPublicUserReport = (reportId) => dispatch => {

//   dispatch(getPublicUserReportStart());

//   return covidRef.doc(reportId)
//     .get()
//     .then( doc => {
//         dispatch(getPublicUserReportSuccess(reportId, doc.data()));
//     })
//     .catch (error => {
//       dispatch(getPublicUserReportFail(error));
//     })
// };

// Get report from own user
// export const getSelfReport = (userId, reportId) => dispatch => {
//   dispatch(getReportStart());
//   usersRef.doc(userId).collection('reports').doc(reportId)
//     .get()
//     .then( doc => {
//         dispatch(getReportSuccess(reportId, doc.data()));
//     })
//     .catch (error => {
//       dispatch(getReportFail(error));
//     })
// };

// // get report from ANY user
// export const getReport = (reportId) => dispatch => {
//   dispatch(getReportStart());
//   // db.collectionGroup('reports').where("t_submitted", "==", 1635176257513)
//   db.collection('reports').where("id", "==", reportId)
//     .get()
//     .then( snapshot => {
//       if (snapshot.size === 1)
//       {
//         dispatch(getReportSuccess(reportId, snapshot.docs[0].data()));
//         // Need way to retrieve user info as well, but I guess that will change
//         // once this collection moves anyway! [tdhs]
//       } else {
//         dispatch(getReportFail(`Found ${snapshot.size} results for report query`));
//       }
//     })
//     .catch (error => {
//       console.error(error);
//       dispatch(getReportFail(error));
//     })
// };


// export const getReportsByUser = (userId) => dispatch => {
//   dispatch(getReportsStart(`userId == ${userId}`));
//   db.collection('reports').where('userId', '==', userId).get().then(snapshot =>
//     dispatch(getReportsSuccess(snapshot.docs.map(e => e.data())))
//   ).catch(error => dispatch(getReportsFail(error)));
// };

const InviteStatesForReport = [
  UserInviteStatus.Queued,
  UserInviteStatus.Delivered,
  UserInviteStatus.Read,
  UserInviteStatus.Interaction
];

export function processMetrics (q, answers, outcomes) {
  if (q.metrics) {
    const metrics = {};
    if (q.metrics.primaryScore) {
      if ((q.metrics.primaryScore.sourceOutcome in outcomes)) {
        const value = outcomes[q.metrics.primaryScore.sourceOutcome];
        metrics.scores = [{
          title: q.metrics.primaryScore.title || "Primary Score",
          value,
          type: q.metrics.primaryScore.type || (typeof value),
          t_generated: Date.now(),
          screener: q.screener
        }];
      } else {
        metrics.error = `Unknown score source ${q.metrics.primaryScore.sourceOutcome} in primaryScore`;
        console.error(metrics.error);
      }
    }
    if (q.metrics.alerts) {
      const mergedAnswers = {...answers, ...Object.fromEntries(Object.entries(outcomes).map(([k, value]) => ([`~${k}`, {value}])))};
      metrics.alerts = Object.entries(q.metrics.alerts).filter(([key, alertDef]) => {
        return evaluator(alertDef.formula, mergedAnswers, q, true, false, `metrics.alerts.${key}.formula`);
      }).map(([key, alertDef]) => {
        const value = evaluator(alertDef.value, mergedAnswers, q, false, false, `metrics.alerts.${key}.value`);
        return {
          title: alertDef.title,
          severity: alertDef.severity,
          value,
          t_generated: Date.now(),
          screener: q.screener
        };
      });
    }
    return metrics;
  }
  return null;
}

export const createReport = (userId, requesterId, organizationId, screeningId, reportData, metricsData) => dispatch => {
  const reportRef = db.collection('reports').doc();
  const userRef = usersRef.doc(userId);
  const reportContent = {
    ...reportData,
    id: reportRef.id,
    userId,
    screeningId,
    requesterId: requesterId || "",
    organizationId: organizationId || ""
  };
  dispatch(createReportStart(reportContent));
  return userRef.get().then(userDoc => {
    if (!userDoc.exists) {
      throw new Error(`Could not find user ${userId}`);
    }
    const userData = userDoc.data();
    let reportIds = userData.reports;
    if (!Array.isArray(reportIds)) {
      reportIds = [];
    }
    const hasStatusChange = [UserClinicStatus.InviteSent, UserClinicStatus.AppOpened, UserClinicStatus.InviteReceived].includes(userData.status);
    const batch = db.batch();
    return db.collection("screenings").doc(screeningId).get().then(screeningDoc => {
      const now = Date.now();
      const screeningData = screeningDoc.data();
      if (screeningData.reportId) {
        const additionalReports = (screeningData.additionalReports || []).concat(screeningData.reportId);
        batch.update(screeningDoc.ref, {
          reportId: reportRef.id,
          readBy: [],
          additionalReports,
          // TODO: should we affect the status somehow?
          t_submitted: now
        });
      } else {
        batch.update(screeningDoc.ref, {
          reportId: reportRef.id,
          status: ScreeningStatus.ReportReady,
          t_statusUpdated: now,
          t_submitted: now
        });
      }
      batch.update(userRef, {
        reports: reportIds.concat(reportRef.id),
        status: hasStatusChange ? UserClinicStatus.ReportReady : userData.status,
        t_statusUpdated: hasStatusChange ? Date.now() : userData.t_statusUpdated
      });
      if (metricsData?.alerts?.length > 0) {
        metricsData.alerts.forEach(a => batch.set(db.collection('userAlerts').doc(), {...a, organizationId, requesterId, userId, screeningId, active: true}));
      }
      const scoreIDs = [];
      if (metricsData?.scores?.length > 0) {
        metricsData.scores.forEach(s => {
          const scoreRef = db.collection('users').doc(userId).collection('scores').doc();
          batch.set(scoreRef, {...s, reportId: reportRef.id, screeningId});
          scoreIDs.push(scoreRef.id);
        });
      }
      reportContent.associatedScores = scoreIDs;
      batch.set(reportRef, reportContent);
      return batch.commit().then(() => {
        // TODO: include metadata
        dispatch(createReportSuccess(reportContent));
        return reportRef.id;
      }).catch(error => {
        console.error(error);
        dispatch(createReportFail(error))
      });
    })
  }).catch(error => {
    console.error(error);
    dispatch(createReportFail(error))
  });
};

// export const createHpiReport = (userId, hpi, metadata) => dispatch => {
//   dispatch(createHpiReportStart());
//   const newReportRef = usersRef.doc(userId).collection('reports').doc();
//   const reportContent = {
//     hpi,
//     ...metadata,
//     id: newReportRef.id
//   };
//   return newReportRef.set(reportContent).then(() => {
//     dispatch(createHpiReportSuccess(reportContent));
//     return newReportRef.id;
//   })
//   .catch(error => dispatch(createReportFail(error)));
// }

export const createPublicUserReport = (covidScreener, metadata) => dispatch => {
  dispatch(createPublicUserReportStart());
  const newReportRef = covidRef.doc();
  const reportContent = {
    ...covidScreener,
    ...metadata,
    id: newReportRef.id
  };
  return newReportRef.set(reportContent).then(() => {
    // TODO: include metadata
    dispatch(createPublicUserReportSuccess(newReportRef.id, covidScreener));
    return newReportRef.id;
  })
  .catch(error => dispatch(createPublicUserReportFail(error)));
}

export const updateReportFlag = (reportId, statusFlags) => dispatch => {
  dispatch({type: "report/UPDATE-FLAG"});
  return db.collection('reports').doc(reportId).update({statusFlags}).then(
    () => dispatch({type: "report/UPDATE-FLAG-SUCCESS", payload: {reportId, statusFlags}})
  ).catch(error => dispatch({type: "report/UPDATE-FLAG-FAIL", error}));
}
