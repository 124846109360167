import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { resourceMarkdownRequested } from "../../store/slices/education";
import { Button, Modal } from "react-bootstrap";
import rehypeRaw from "rehype-raw";

export const ResourceDialog = ({url, show: [show, setShow]}) => {
  const dispatch = useDispatch();
  const valid = (typeof url === "string" && url.length > 0);

  const isMarkdown = valid && url.endsWith(".md");
  const isExternal = valid && (new URL(url, window.location.href)?.host !== window.location.host);
  const markdown = useSelector(s => s.education.resources[url]);
  useEffect(() => {
    if (valid && isMarkdown && markdown === undefined) {
      dispatch(resourceMarkdownRequested(url));
    }
  }, [url]);

  if (!valid) return null;
  return (
  <Modal show={!!show} onHide={() => setShow(false)} size="lg">
  <Modal.Body>
    {isExternal ?
      <div className="alert alert-danger">
        The content below is an external page from <em>{url}</em>.
        <br/>
        Be cautious before entering personal data!
      </div>
    : null}
    {isMarkdown
      ? (markdown?.error ? <h3 className="text-danger">{markdown.error.message}</h3> :
         <ReactMarkdown
          children={markdown}
          rehypePlugins={[rehypeRaw]}
          components={{}}
          className="education resource-dialog popup-education-wrapper">
        </ReactMarkdown>)
      : <iframe src={url} style={{width: "100%", minHeight: "60vh", border: "none"}}></iframe>
    }
    </Modal.Body>
    <Modal.Footer style={{border: "none"}}>
      <Button class="btn-primary" onClick={() => setShow(false)}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>);
}