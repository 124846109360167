import React from "react";
import Questionnaire from '../History/Questionnaire';

import { ScreeningType } from "../../constants/screenings";
import * as ROUTES from '../../constants/routes';
import { Language } from "../../constants/locales";

const CovidScreener = () => {
    return (
        <Questionnaire type={ScreeningType.COVID} languageOverride={Language.English} nextLocations={ROUTES.COVID_REPORT}/>
    );
};

export default CovidScreener;