import React from "react";
import { Container } from "../UI/Grid/Grid";

const TermsOfService = () => (
  <Container className="my-5">
    <h2 className="h1">
      LiteraSeed, Inc END USER LICENSE AGREEMENT TERMS AND CONDITIONS
    </h2>
    <p>
      PLEASE READ THE END-USER LICENSE AGREEMENT (“Agreement”) CAREFULLY BEFORE
      PROCEEDING. LICENSOR PROVIDES THIS SOFTWARE SOLELY ON THE TERMS AND
      CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT LICENSEE
      ACCEPTS AND COMPLIES WITH THEM. BY CLICKING THE “I AGREE BUTTON” OR USING
      THE LiteraSeed, Inc SYSTEM (“SYSTEM”), YOU ARE AGREEING TO BE BOUND BY THE
      TERMS AND CONDITION OF THIS AGREEMENT. NOTWITHSTANDING ANYTHING TO THE
      CONTRARY IN THIS AGREEMENT OR YOUR ACCEPTANCE OF THE TERMS AND CONDITIONS
      OF THIS AGREEMENT, NO LICENSE IS GRANTED (WHTHER EXPRESSLY, BY
      IMPLICATION, OR OTHERWISE) UNDER THIS AGREEMENT, AND THIS AGREEMENT
      EXPRESSLY EXCLUDES ANY RIGHT CONCERNING ANY SOFTWARE THAT LICENSEE DID NOT
      ACQUIRE LAWFULLY OR THAT IS NOT A LEGITIMATE, AUTHORIZED COPY OF
      LICENSOR'S SOFTWARE.
    </p>
    <p>
      IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT USE THE SYSTEM.
    </p>

    <h3>1. LITERASEED, INC: </h3>
    <p>
      LiteraSeed, Inc (“LITERASEED”) is an online platform for a user-generated
      health-related system which helps all people, most specifically
      immigrants, refugees, the elderly, non-English speakers, and people with
      disabilities communicate symptoms with health professionals. This system
      is developed and operated by LiteraSeed, Inc (“LITERASEED, INC”) a limited
      liability company in the State of Arizona, USA. LITERASEED uses an
      illustrated symptom reporting survey written in simple language and
      illustrations. The system's purpose is to further improve patient
      communication with healthcare providers and reduce or eliminate
      miscommunication between them.
    </p>

    <h3>2. TERMS &amp; TERMINATION: </h3>
    <p>
      These terms govern the User's use of the LITERASEED system's website,
      software, application, and services available on it. They apply
      regardless of the delivery platform used to access the website. Accessing
      the LITERASEED website constitutes acceptance of these Terms and
      Conditions by an individual and the entity represented by the individual.
    </p>
    <p>
      This Agreement shall remain in effect until terminated by you or
      LITERASEED. LITERASEED may, in its sole discretion, at any time and for
      any or no reason, suspend or terminate this Agreement with or without
      prior notice.
    </p>
    <p>
      In the event you fail to comply with any provision of this Agreement, this
      Agreement will terminate immediately without prior notice from LITERASEED,
      INC and all use and/or access will cease. You may also terminate this
      Agreement by discontinuing your online access.
    </p>
    <p>
      Upon termination of this Agreement, you shall cease all use of the System
      and delete all copies of the System from your devices or from your
      desktop. You will immediately cease use of your online account(s).
    </p>

    <h3>3. AMENDMENTS TO THIS AGREEMENT:</h3>
    <p>
      LiteraSeed, Inc reserves the right, at its sole discretion, to modify or
      replace these Terms and Conditions from time to time as it deems
      appropriate and will publish any changes on the website. Users should
      check the LITERASEED website periodically for changes and if the User does
      not agree to such changes, the User should cease using the LITERASEED
      website.
    </p>

    <h3>4. USER'S FEES:</h3>
    <p>
      Access to the LITERASEED website content and/or services is available only
      to Users upon their acceptance of these terms and payment of the required
      User fees, if applicable. The User fees shall vary depending on the
      accessibility and permissions offered and accepted. This application
      offers various modules and content which will be limited based on your fee
      agreement.
    </p>
    <p>
      Excess use beyond the original agreement, shall result in additional fees
      without notice.
    </p>

    <h3>5. FEE CHANGES:</h3>
    <p>
      LiteraSeed, Inc may change the User fee for each level and/or the annual
      renewal cost of system at its discretion. All such changes shall be
      effective when posted or upon electronic notification within 60 days
      before renewal and shall remain in effect until further changes are
      posted.
    </p>

    <h3>6. TERMS OF USERSHIP:</h3>
    <p>
      Users shall have the right to access and use the system for a given period
      of time. The period of time associated with a particular fee shall be
      provided via invoice. Payment of the fee in full at the time the User
      accepts this license shall entitle the User to the designated level and
      amount of access for the designated period of time.{" "}
    </p>

    <h3>7. PERMITTED USE:</h3>
    <p>
      LiteraSeed, Inc owns the LITERASEED software application, content and all
      intellectual property rights (including copyright and database rights) to
      them. A User may use the system and operate customer content only in
      accordance with the user agreement. You may be limited on your access to
      content, data, and services based on your user agreement.
    </p>
    <p>
      LiteraSeed, Inc grants you a revocable, non-exclusive, non-transferable,
      limited license to use, install, and access the application for you
      business or personal use strict in accordance with the terms of this
      agreement.
    </p>

    <h3>8. RESTRICTIONS ON USE:</h3>
    <p>
      A User may not use the LITERASEED system and/or its data, content, and
      services for any unlawful purpose. Except as expressly allowed by a
      separate licensing agreement for particular data and content. You agree
      not to, and will not permit others to copy, reproduce, publish, broadcast,
      transmit, modify, adapt, reverse engineer, disassemble, decompile, decode,
      create derivative works from the table and content, store, archive,
      publicly display or in any way commercially exploit any of LITERASEED data
      and content. A User may do the following only with a separate written
      consent with LITERASEED, INC:
    </p>
    <p>
      a. Redistribute, rent, lease, lend, sell, sublicense, assign, distribute,
      publish, transfer, or otherwise make available the LITERASEED system table
      and content to any Third Party for any reason; or
    </p>
    <p>
      b. Remove, delete, alter, or obscure the copyright or trademark notice
      from any copies of LITERASEED website system table and content; or
    </p>
    <p>
      c. Create a database in electronic or structured manual form by
      systematically and/or regularly downloading, catching, printing
      and storing any or all of the LITERASEED table and content; or
    </p>
    <p>
      d. Frame, harvest, or scrape the LITERASEED table and content or otherwise
      access the LITERASEED system and content for similar purposes.
    </p>

    <h3>9. TRADEMARK AND COPYRIGHT:</h3>
    <p>
      The User acknowledges that any trademark and/or copyright of any
      LiteraSeed, Inc system table and content may be used only with the prior
      written consent of LITERASEED, INC.
    </p>

    <h3>10. INTELLECTUAL PROPERTY RIGHTS:</h3>
    <p>
      The User acknowledges that the LiteraSeed, Inc system table and content is
      provided under license, and not sold, to User. The User does not acquire
      ownership interest in LITERASEED under this Agreement, or any other rights
      to the LITERASEED system table and content other than to use the system in
      accordance with the license granted under this Agreement, subject to all
      terms, conditions, and restrictions.
    </p>

    <h3>
      11. CONTRACTUAL AND LEGAL RESTRICTiONS ON USE OF COURSES AND CONTENT:
    </h3>
    <p>
      LiteraSeed, Inc has the right under both applicable law and under these
      Terms and Conditions to restrict the types of use a User can make of the
      LITERASEED system table and content.
    </p>

    <h3>12. PRIVACY POLICY AND REGISTRATION:</h3>
    <p>
      All information received from a User by LiteraSeed, Inc shall be used only
      in accordance with its{" "}
      <a href="/privacynotice">
        <strong>Privacy Policy</strong>
      </a>
      . All registration information and data must be accurate and complete. The
      User is responsible to update and make changes to this information as
      necessary. LiteraSeed, Inc is entitled to rely upon the registration
      information supplied by User. {" "}
    </p>

    <h3>13. USERNAME AND PASSWORD:</h3>
    <p>
      Upon registration, a User will choose a Username and password. You will
      not permit any other User to use the same username.
    </p>

    <h3>14. USER'S RESPONSIBILITY:</h3>
    <p>
      User is responsible for all use made by it of LITERASEED system and
      content and for preventing any unauthorized use of LITERASEED system table
      and content accessed by User. If a User learns or discovers any
      unauthorized use, disclosure, or theft of any LITERASEED system or
      content, a User's username, password or payment information, the User
      shall immediately inform LITERASEED, INC.
    </p>

    <h3>15. APPROPRIATE E-MAIL ADDRESS:</h3>
    <p>
      User warrants that he or she can receive messages, courses and content on
      the email address provided by the User to LITERASEED, INC.
    </p>

    <h3>16. TECHNICAL REQUIREMENTS AND SITE SECURITY:</h3>
    <p>
      Users shall access the LITERASEED system and content via Internet enabled
      devices with a recommended and supported web browser. User's payment
      information will be encrypted using 128 Secure Socket Layer technology.
    </p>

    <h3>17. WARRANTIES AND LIMITATION OF LIABILITY:</h3>
    <p>
      The system's content does not constitute any form of specific or express
      advice, recommendation, representation, endorsement, or arrangement by
      LiteraSeed, Inc to address the particular or unique requirements of the
      User.
    </p>

    <h3>18. WARRANTY &amp; EXCEPTIONS:</h3>
    <p>
      LiteraSeed, Inc shall develop and operate the LITERASEED website with
      reasonable skill and care and has reasonable security procedures in place.
    </p>
    <p>
      EXCEPTIONS: LiteraSeed, Inc DOES NOT GIVE ANY WARRANTIES WITH RESPECT TO
      THE LITERASEED SYSTEM AND CONTENT. LiteraSeed, Inc DISCLAIMS ALL OTHER
      WARRANTIES, CONDITIONS, AND DUTIES OF ANY NATURE WHATSOEVER TO THE EXTENT
      ALLOWED BY APPLICABLE LAW, EXCEPT FOR THE DUTY TO ACT IN GOOD FAITH. THE
      DISCLAIMED WARRANTIES INCLUDE, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES
      OF SATISFACTORY QUALITY, MERCHANTABILITY OR OF FITNESS FOR A PURPOSE, ANY
      EXPRESS OR STATUTORY WARRANTIES, AND ANY WARRANTIES OR DUTIES REGARDING
      ACCURACY, TIMELINESS, COMPLETENESS, PERFORMANCE, AVAILABILITY, LACK OF
      NEGLIGENCE OR OF WORKMAN LIKE EFFORT. LiteraSeed, Inc DOES NOT WARRANT THE
      SYSTEM &amp; ITS WEBSITE IS FREE FROM INFECTION BY VIRUSES OR ANYTHING ELSE
      THAT HAS CONTAMINATING OR DESTRUCTIVE PROPERTIES.
    </p>

    <h3>19. LIABILITY EXCLUSION:</h3>
    <p>
      USER AGREES TO THE FULLEST EXTENT ALLOWED BY LAW THAT LiteraSeed, Inc WILL
      NOT BE LIABLE TO USER FOR ANY LOSSES WHICH RELATE TO USER'S USE OF THE
      LITERASEED SYSTEM AND SERVICES OR WHICH ARE NOT A DIRECT CONSEQUENCE OF
      USER'S USE OF THE LITERASEED SYSTEM (INCLUDING LOST PROFITS OR LOSS OF
      PRIVACY OR LOSS OF OR DAMAGE TO DATA) OR WHICH ARISE AS A RESULT OF USER
      USING THE SYSTEM OUTSIDE OF THE SCOPE OF THESE TERMS AND CONDITIONS OR FOR
      ANY USE, INTERRUPTION, DELAY, OR INABILITY TO USE THE LITERASEED
      SYSTEM. USER FURTHER AGREES THAT SEPARATE FROM THE FOREGOING AND TO THE
      FULL EXTENT ALLOWED BY APPLICABLE LAW, THAT LiteraSeed, Inc WILL NOT BE
      LIABLE TO USER FOR ANY OTHER INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL,
      PUNITIVE OR EXEMPLARY DAMAGES WHATSOEVER THAT ARISE OUT OF OR ARE RELATED
      TO USERS USE OF OR INABILITY TO USE THE LITERASEED SYSTEM.
    </p>

    <h3>20. USER'S REMEDIES:</h3>
    <p>
      LiteraSeed, Inc SHALL USE REASONABLE EFFORTS TO PROMPTLY REMEDY ANY FAULTS
      IN THE LITERASEED SYSTEM OF WHICH IT IS AWARE. USER'S ONLY OTHER REMEDY
      (INCLUDING NEGLIGENCE) FOR ANY DAMAGES THAT USER MAY INCUR OR THAT ARISE
      OUT OF USER'S USE OF THE SYSTEM (TO THE EXTENT THAT LITERASEED, INC'
      LIABILITY IS NOT EXCLUDED BY SECTION 17 &amp; 18) IS LIMITED TO THE FEES PAID
      BY USER TO LiteraSeed, Inc PURSUANT TO THESE TERMS AND CONDITIONS DURING
      THE PERIOD IN WHICH THE DAMAGE IS INCURRED.
    </p>

    <h3>21. UNENFORCEABLE LIABILITY LIMITATIONS:</h3>
    <p>
      IF A COURT OF COMPETENT JURISDICTION DETERMINES THAT EITHER SECTION 19 OR
      20 ARE NOT ENFORCEABLE, USER AGREES THAT LITERASEED, INC'S TOTAL LIABILITY
      TO USER SHALL NOT EXCEED AN AMOUNT EQUAL TO TWICE THE VALUE OF THE HIGHEST
      APPLICABLE ANNUAL LITERASEED SYSTEM USER FEE.
    </p>

    <h3>22. ACTS BEYOND LITERASEED, INC'S CONTROL:</h3>
    <p>
      Without limiting the terms of Sections 18, 19, and 20, LiteraSeed, Inc is
      not liable for any matters beyond its reasonable control. LITERASEED, INC
      does not control postal or courier services, telephones, third party
      communication networks (including User's internet service provider), the
      internet, acts of God, the acts of third parties, earthquakes, labor
      disputes, shortage of supplies, riots, war, fire, epidemics, or delays of
      common carriers. The obligations and rights of an excused party shall be
      extended on a day to day basis for the time period equal to the period of
      the excusable delay.
    </p>

    <h3>23. THIRD PARTY SITES AND SERVICES:</h3>
    <p>
      The LITERASEED website may contain content provided by independent third
      parties. This content may be co-branded and therefore include and/or bear
      the LiteraSeed, Inc mark. LiteraSeed, Inc is not responsible for these
      third party prepared LITERASEED system table and content, except as
      otherwise set forth in this Agreement.
    </p>

    <h3>24. SOFTWARE LICENSES: </h3>
    <p>
      The copyright of any software potentially made available or downloaded
      from LiteraSeed, Inc belongs to LiteraSeed, Inc or its licensors. The
      User's right to use such software, courses, and content is governed by
      this agreement. LiteraSeed, Inc is not responsible for any technical or
      any other issues that may arise from the User accessing the LITERASEED
      courses and content. The downloading of any LITERASEED content constitutes
      User's agreement to the terms of the courses' and content's license.
    </p>

    <h3>25. ADVERTISING:</h3>
    <p>
      If the LITERASEED website contains any advertising or sponsorship, the
      advertisers or sponsors are solely responsible for ensuring that their
      material complies with national and international law. LITERASEED, INC
      will not be responsible for any error or inaccuracy in any advertising or
      sponsorship materials.
    </p>

    <h3>26. CHOICE OF LAW AND JURISDICTION:</h3>
    <p>
      The parties agree that the Courts of the State of Arizona, USA shall have
      exclusive jurisdiction to hear and resolve any dispute which may arise out
      of, under or in connection with, these Terms and Conditions.
      Notwithstanding the forgoing, LiteraSeed, Inc for its exclusive benefit
      shall retain the right to bring or enforce proceedings in the User's state
      or country of residence or the User's principal place of business. The
      validity and performance of these Terms and Conditions shall be
      interpreted according to the laws of the State of Arizona as if it were
      performed wholly within that State and without giving effect to its
      conflict of law principles. The Parties specifically disclaim the United
      Nations Convention on Contracts for the International Sale of Goods.
    </p>

    <h3>27. ASSIGNMENT:</h3>
    <p>
      User may NOT assign, sub-license, delegate, or otherwise transfer any of
      the User's rights, obligations, or performance, under Terms and Conditions
      of this Agreement, in each case whether voluntarily, involuntarily, by
      operation of law, or otherwise, without the prior written consent of
      LiteraSeed, Inc which consent LiteraSeed, Inc may give or withhold in its
      sole discretion. For purposes of the preceding sentence, and without
      limiting its generality, any merger, consolidation, or reorganization
      involving User (regardless of whether User is a surviving or disappearing
      entity) will be deemed to be a transfer of rights, obligations, or
      performance under this Agreement for which User's prior written consent is
      required. No delegation or other transfer will relieve User of any of its
      obligations or performance under this Agreement. Any purported assignment,
      delegation, or transfer in violation of this Section is void.{" "}
    </p>

    <h3>28. THIRD-PARTY BENEFICIARIES:</h3>
    <p>
      This Agreement is for the sole benefit of the parties hereto and their
      respective successors and permitted assigns, and nothing herein, express
      or implied, is intended to or shall confer on any other Person any legal
      or equitable right, benefit, or remedy of any nature whatsoever under or
      by reason of this Agreement.
    </p>

    <h3>29. SEVERANCE:</h3>
    <p>
      If any term or provision of these Terms and Conditions are found to be
      invalid, illegal, or unenforceable by a court with competent jurisdiction,
      the invalidity, illegality, or unenforceability of that provision will not
      affect the validity of the remaining provisions of these Terms and
      Conditions or invalidate or render unenforceable such terms or provision
      in any other jurisdiction and they shall remain in full force and effect.
    </p>

    <h3>30. WAIVER:</h3>
    <p>
      Failure of either party to exercise any right under these Terms and
      Conditions will not be considered a waiver of that right or remedy and any
      waiver of any right or remedy on one occasion by either party shall not
      constitute a subsequent waiver of such right or remedy on any other
      occasion.
    </p>

    <h3>31. HEADINGS:</h3>
    <p>
      Headings in these Terms and Conditions are for convenience only and will
      have no legal meaning or effect.
    </p>

    <h3>32. ACCURATE PAYMENT INFORMATION:</h3>
    <p>
      User is responsible for providing accurate and complete payment
      information to LITERASEED, INC. If payment is by credit or debit card,
      User is warranting that he or she is duly authorized to use of the credit
      or debit card. In the event of an unauthorized use of a credit or debit
      card, LiteraSeed, Inc shall have the right to terminate or suspend the
      User's license or licenses.
    </p>

    <h3>33. SPEEDY PROCESSING OF INFORMATION:</h3>
    <p>
      LiteraSeed, Inc will seek to promptly process any payment information and
      to promptly make available the LITERASEED system to User. However, there
      is no specific time requirement required to make the LITERASEED system and
      content available.
    </p>

    <h3>34. CURRENCY:</h3>
    <p>
      LiteraSeed, Inc will charge User in U.S. Dollars or local currency of any
      of LiteraSeed, Inc sellers or Distributors.
    </p>

    <h3>35. LOCAL TAXES or GST:</h3>
    <p>
      User will also be responsible for any local sales or use taxes on the
      User's fees.
    </p>

    <h3>36. CANCELLATION OF USERSHIP:</h3>
    <p>
      A User may cancel his or her license at any time, however, all User fees
      paid to the date of cancellation, or within 30 days following the
      cancellation date for subscriptions shall remain the property of
      LiteraSeed, Inc LITERASEED and User shall not be entitled to any refunds.
      Paid Users shall provide LiteraSeed, Inc with an express intent to cancel
      via email or written document.
    </p>

    <h3>37. ATTORNEY'S FEES:</h3>
    <p>
      The prevailing party in any suit or proceeding relating to these Terms and
      Conditions shall have the right to recover from the other party its costs
      and reasonable attorney's, accountant's, and other professionals' fees and
      expenses incurred in connection with the suit or proceeding.
    </p>

    <h3>38. RELATIONSHIP BETWEEN THE PARTIES:</h3>
    <p>
      These Terms and Conditions do not create any agency, partnership, joint
      venture or franchise relationship between the parties and neither the User
      nor its employees shall be deemed to be, a legal representative, agent, or
      employee of LITERASEED, INC. Neither party has the right nor the
      authority to assume or create any obligations of any nature on behalf of
      the other party or bind the other party in any respect and neither party
      shall do so.
    </p>

    <h3>39. ENTIRE AGREEMENT:</h3>
    <p>
      This Agreement, together with the Terms and Conditions contained herein,
      constitutes the sole and entire agreement between User and LITERASEED, INC
      with respect to the subject matter contained herein, and supersedes all
      prior and contemporaneous understandings, agreements, representations, and
      warranties, both written and oral, with respect to such subject matter.
    </p>

    <h3>40. USER ACCEPTANCE:</h3>
    <p>
      By clicking on the “I accept” icon, User acknowledges that he or she has
      fully read and understands these Terms and Conditions and accepts them in
      their entirety.
    </p>

    <h3>41. INDEMNIFICATION OF LiteraSeed, Inc BY SYSTEM USER:</h3>
    <p>
      System User shall defend, indemnify, and hold LiteraSeed, Inc harmless
      from and against any and all claims, losses, damages, liabilities,
      settlements, costs, or expenses (including legal expenses and the expenses
      of other professionals) incurred, arising out of or in connection with any
      claim that any materials used by System User and/or any modification made
      by a System User infringe on a third party's intellectual property rights
      or any other rights.
    </p>
  </Container>
);

export default TermsOfService;
