import { spliceIntoAt } from '../../utils';
import * as actionTypes from '../actions/actionTypes';
import { produce } from 'immer';
import { changeUserOwner, getClinicianColleagues, getMessagesByManager, getMessagesByOrganization, getReportsByOrganization, getScheduledInvitesByManager, getScheduledInvitesByOrganization, getSpecificUser, getUserAlerts, getUserAlertsByOrganization, getUserByDetails, getUserMessages, getUserScheduledInvites, getUserScores, getUsersForOrganization, getUsersForProvider, resetEnrollmentUser, switchProviderView, updateEnrollmentUser, updateOrganizationSettings, updateUserAlert, updateUserDetails } from '../actions';
import { Language } from '../../constants/locales';
import { createEntityAdapter } from '@reduxjs/toolkit';

const scheduledInvitesAdapter = createEntityAdapter({
  selectId: entity => entity.id,
  // sortComparer: (a, b) => a.t_created - b.t_created
});
const messagesAdapter = createEntityAdapter({});

const initialState = {
  isLoading: false,
  isError: false,
  error: {},
  reports: null,
  users: [],
  alerts: [],
  userLoads: {},
  enrollee: newEnrollment(),
  // organization: {name: "", users: []},
  browsingOwnerId: null,
  globalSearch: "",
  scheduledInvites: scheduledInvitesAdapter.getInitialState(),
  messages: messagesAdapter.getInitialState()
};

/*
  * Generate a fresh enrollment object for a new user
  */
export function newEnrollment () {
  return {
    // these values need to be initialized because undefined dates/times will
    // default to showing the current date/time
    dob: null,
    _enrollmentForm_doa: null,
    _enrollmentForm_toa: null,

    prefill: {},
    preferredLanguage: Language.English,

    lastName: "",
    email: "",
    phone: "",
    phoneType: "sms"
  };
}

const reducer = ( state = initialState, action ) => {
  switch (action.type) {
    case actionTypes.GET_PROVIDERS_START:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case actionTypes.GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        providers: action.providers
      };
    case actionTypes.GET_PROVIDERS_FAIL:
    return {
      ...state,
      isLoading: false,
      isError: true,
      error: action.error
    };

    case actionTypes.SET_PREFERED_PROVIDER_START:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case actionTypes.SET_PREFERED_PROVIDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        preferredProvider: action.data
      };
    case actionTypes.SET_PREFERED_PROVIDER_FAIL:
    return {
      ...state,
      isLoading: false,
      isError: true,
      error: action.error
    };

    case actionTypes.SAVE_PREFERED_PROVIDER_ID_SUCCESS:
    return {
      ...state,
      providerId: action.data
    };
    // case actionTypes.PRE_CREATE_PATIENT_SUCCESS:
    //   return {
    //     ...state,
    //     patientUid: action.patientUid
    //   }
    case actionTypes.PROVIDER_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload.reports
      }
    case `${getReportsByOrganization.fulfilled}`:
      return {
        ...state,
        reports: action.payload
      }
    case actionTypes.PROVIDER_REPORTS_FAIL:
      return {
        ...state,
        reports: null
      }
    case actionTypes.PROVIDER_REPORT_ADDITION:
      const newReports = spliceIntoAt(
        action.payload.report,
        state.reports,
        r => r.id === action.payload.report.id);
      return {
        ...state,
        reports: newReports
      }
    case `${getUsersForProvider.fulfilled}`:
    case `${getUsersForOrganization.fulfilled}`:
      const updatedIds = new Set(action.payload.map(u => u.id));
      const mergedUsers = state.users.filter(u => !updatedIds.has(u.id)).concat(action.payload);
      return {
        ...state,
        users: mergedUsers,
        userLoads: {...state.userLoads, [action.meta.arg]: Date.now()}
      };
    case `${getSpecificUser.fulfilled}`:
      return {
        ...state,
        users: spliceIntoAt(action.payload, state.users, u => u.id === action.payload.id)
      };
    case `${getUserByDetails.fulfilled}`:
      if (!action.payload || !Array.isArray(action.payload)) return state;
      return produce(state, s => {
        action.payload.forEach(u => {
          const index = state.users.findIndex(x => x.id === u.id);
          if (index === -1) {
            state.users.push(u);
          } else {
            state.users[index] = u;
          }
        });
      });
    case "provider/USER-REPORTS-SUCCEEDED":
      return produce(state, s => {
        const target = s.users.find(u => u.id === action.payload.userId);
        // sort the reports with most recent first
        target.reports = action.payload.reports.sort((r1, r2) => r2.t_submitted - r1.t_submitted);
        // TODO: when we switch to entity handlers, we'll need to move this!
      });
    case `${getUserScheduledInvites.fulfilled}`:
      return produce(state, s => {
        const target = s.users.find(u => u.id === action.meta.arg);
        if (!target) {
          console.error(`Could not find target user ${action.meta.arg}`);
        } else {
          target.scheduledInvites = action.payload;
        }
        // TODO: when we switch to entity handlers, we'll need to move this!
      });
    case `${getUserMessages.fulfilled}`:
      return produce(state, s => {
        const target = s.users.find(u => u.id === action.meta.arg);
        if (!target) {
          console.error(`Could not find target user ${action.meta.arg}`);
        } else {
          target.messages = action.payload.sort((a, b) => a.t_created - b.t_created);
        }
        messagesAdapter.upsertMany(s.messages, action.payload);
        // TODO: when we switch to entity handlers, we'll need to move this!
      });
    case `${getMessagesByManager.fulfilled}`:
    case `${getMessagesByOrganization.fulfilled}`:
      return produce(state, s => {
        messagesAdapter.upsertMany(s.messages, action.payload);
      })
    case "provider/USER-NOTE-UPDATE":
      return produce(state, s => {
        const target = s.users.find(u => u.id === action.payload.userId);
        target.notes = action.payload.notes;
      });
    case `${updateUserDetails.fulfilled}`:
      return produce(state, s => {
        const index = s.users.findIndex(u => u.id === action.meta.arg.id);
        if (index === -1) {
          console.error(`Couldn't find user ${action.meta.arg.id}`);
        } else {
          const updated = {...s.users[index], ...action.meta.arg.update};
          s.users[index] = updated;
        }
      });
    case "provider/SCHEDULED-INVITE-UPDATE":
      return produce(state, s => {
        const user = s.users.find(u => u.id === action.payload.userId);
        if (user) {
          let index = user.scheduledInvites?.findIndex(i => i.id === action.payload.id);
          if (index > -1) {
            user.scheduledInvites[index] = {...user.scheduledInvites[index], ...action.payload.updateContent};
          }
        }
        scheduledInvitesAdapter.updateOne(s.scheduledInvites, {id: action.payload.id, changes: action.payload.updateContent});
      });
    case "provider/SCHEDULE-NEW-INVITE":
      return produce(state, s => {
        // TODO: does this need to update the user as well?
        scheduledInvitesAdapter.addOne(s.scheduledInvites, action.payload);
      });
    case `${getUserScheduledInvites.rejected}`:
      return {
        ...state,
        users: []
      };
    case "report/UPDATE-FLAG-SUCCESS":
      let index = Array.isArray(state.reports) ? state.reports.findIndex(r => r.id === action.payload.reportId) : -1;
      if (index > -1) {
        return {
          ...state,
          reports: [
            ...state.reports.slice(0, index),
            {
              ...state.reports[index],
              statusFlags: action.payload.statusFlags
            },
            ...state.reports.slice(index+1)
          ]
        };
      } else {
        return state;
      }
    case "provider/VISIBILITY-UPDATED":
      return produce(state, s => {
        const target = s.users.find(u => u.id === action.payload.userId);
        target.visibility = action.payload.visibility;
      });
    case `${updateEnrollmentUser}`:
      return {
        ...state,
        enrollee: {...state.enrollee, ...action.payload}
      }
    case `${resetEnrollmentUser}`:
      return {
        ...state,
        enrollee: newEnrollment()
      }
    // case `${getUserOrganization.fulfilled}`:
    //   return {
    //     ...state,
    //     organization: {...state.organization, ...action.payload, loaded: true}
    //   }
    case "AUTH_SUCCESS":
      return {
        ...state,
        browsingOwnerId: action.user.role === "Provider" ? action.user.uid : -1
      }
    case `${switchProviderView}`:
      return {
        ...state,
        browsingOwnerId: action.payload
      }
    case `${changeUserOwner.fulfilled}`:
      return produce(state, s => {
        const toUpdate = s.users.find(u => u.id === action.meta.arg.userId);
        if (!toUpdate) console.error(`Cannot change local owner of user ${action.meta.arg.userId} - not found`);
        toUpdate.providerId = action.meta.arg.providerId;
      });
    case "update-search":
      return {
        ...state,
        globalSearch: action.payload
      }
    case `${getScheduledInvitesByManager.fulfilled}`:
    case `${getScheduledInvitesByOrganization.fulfilled}`:
      return {
        ...state,
        scheduledInvites: scheduledInvitesAdapter.upsertMany(state.scheduledInvites, action.payload)
      }
    case `${getUserScores.fulfilled}`:
      return produce(state, s => {
        const index = s.users.findIndex(u => u.id === action.meta.arg);
        if (index === -1) {
          console.error(`Couldn't find user ${action.meta.arg}`);
        } else {
          const updated = {...s.users[index], scores: action.payload};
          s.users[index] = updated;
        }
      });
    case `${getUserAlerts.fulfilled}`:
      return produce(state, s => {
        const index = s.users.findIndex(u => u.id === action.meta.arg);
        if (index === -1) {
          console.error(`Couldn't find user ${action.meta.arg}`);
        } else {
          const updated = {...s.users[index], alerts: action.payload};
          s.users[index] = updated;
        }
      });
    case `${getUserAlertsByOrganization.fulfilled}`:
      return produce(state, s => {
        // should we update users?
        s.alerts = action.payload;
      });
    case `${updateUserAlert.fulfilled}`:
      return produce(state, s => {
        for (let user of s.users) {
          const alertIndex = user.alerts?.findIndex?.(a => a.id === action.meta.arg.alertId);
          if (alertIndex >= 0) {
            user.alerts[alertIndex] = {...user.alerts[alertIndex], ...action.meta.arg.changes};
            break;
          }
        }
        const alertIndex = s.alerts?.findIndex?.(a => a.id === action.meta.arg.alertId);
        if (alertIndex >= 0) {
          s.alerts[alertIndex] = {...s.alerts[alertIndex], ...action.meta.arg.changes};
        }
        // console.error(`Couldn't find user with alert ${action.meta.arg.alertId}`);
      });
    default:
      return state;
  }
};

const scheduledSelectors = scheduledInvitesAdapter.getSelectors(s => s.provider.scheduledInvites);
export const getAllScheduledInvites = scheduledSelectors.selectAll;
const messageSelectors = messagesAdapter.getSelectors(s => s.provider.messages);
export const getAllMessages = messageSelectors.selectAll;

export default reducer;
