import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { REPORTS } from "../../constants/routes";
import Moment from "moment";
import { STATUS_FLAG } from "../../constants/roles";
import { FaCheckCircle } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { updateReportFlag } from "../../store/actions";

// TODO: use this for report lists


const ReportTable = ({reports, actions, showStatus, showNames, showIds, defaultSort, defaultAscending, userId}) => {
  if (showNames !== true) {
    // if the value is absent or anything but true, set to false
    showNames = false;
  }

  const getReportArray = () => Object.entries(reports).map(
    ([key, report]) => Object.assign({key}, report));

  useEffect(() => {
    setSorting({result: getReportArray(), field: null, descending: !defaultAscending});
    if (defaultSort) {
      if (defaultSort in sortMethod) {
        sortMethod[defaultSort](!!defaultAscending);
      } else {
        console.warn(`Unknown sorting method ${sortMethod}`);
      }
    }
  }, [reports]);

  const [sorting, setSorting] = useState({result: getReportArray(), field: null, descending: true});
  const sortBy = (comparator, label, invert) => {
    if (sorting.field === label) {
      sorting.descending = !sorting.descending;
    } else {
      sorting.field = label;
      sorting.descending = invert !== true;
    }
    let newSort = sorting.result.sort(
      (a, b) => sorting.descending ? comparator(a,b) : -1 * comparator(a,b));
    setSorting({...sorting, result: newSort});
  }

  const sortMethod = {
    id: (inv) => {
      sortBy((a, b) => a.id.localeCompare(b.id), "id", inv);
    },
    lastName: (inv) => {
      sortBy((a, b) => a.submitter.lastName.localeCompare(b.submitter.lastName), "lastName", inv);
    },
    type: (inv) => {
      sortBy((a, b) => a.type.localeCompare(b.type), "type", inv);
    },
    submissionDate: (inv) => {
      sortBy((a, b) => a.t_submitted - b.t_submitted, "submissionDate", inv);
    },
    appointmentDate: (inv) => {
      sortBy((a, b) => a.appointment?.t_date - b.appointment?.t_date, "appointmentDate", inv);
    },
    firstAnswer: (inv) => {
      sortBy((a, b) => ((a.inputs[0]?.answer || [])[0] || "").localeCompare(
        (b.inputs[0]?.answer || [])[0] || ""), "firstAnswer", inv)
    }
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const viewReport = (report) => () => {
    history.push(`${REPORTS}/${report.id}`, {report: report, reportId: report.id});
  }

  const markComplete = (report, event) => {
    event.stopPropagation();
    const flags = report.statusFlags || {};
    flags[userId] = STATUS_FLAG.COMPLETE;
    dispatch(updateReportFlag(report.id, flags)).then(() => report.statusFlags = flags);
  }

  return (<table className="table table-hover">
    <thead>
      <tr className="sortable" title="Click headers to sort by column">
        {showIds ? <th onClick={sortMethod.id}>ID</th> : null}
        {showNames ? <th onClick={sortMethod.lastName}>Last Name</th> : null}
        <th onClick={sortMethod.type}>Type {sorting.field == "type" ? (sorting.descending ? "↓" : "↑") : null}</th>
        <th onClick={sortMethod.submissionDate}>Submission Date {sorting.field == "submissionDate" ? (sorting.descending ? "↓" : "↑") : null}</th>
        <th onClick={sortMethod.appointmentDate}>Appointment Date {sorting.field == "appointmentDate" ? (sorting.descending ? "⚠️" : "⚠️") : null}</th>
        <th onClick={sortMethod.firstAnswer}>First Answer {sorting.field == "firstAnswer" ? (sorting.descending ? "↓" : "↑") : null}</th>
        {showStatus ? <th>Status</th> : null}
        {actions ? <th>Actions</th> : null}
      </tr>
    </thead>
    <tbody>
      {sorting.result.map((report, i) =>
        (<tr onClick={viewReport(report)} key={report.id}>
          {showIds ? <td><small>{report.id}</small></td> : null}
          {showNames ? <td>{report.submitter?.lastName}</td> : null}
          <td>{report.type}</td>
          <td title={Moment(report.t_submitted).format('MM/DD/YYYY HH:mm:ss')}>
            {Moment(report.t_submitted).format('MM/DD/YYYY h:mm:ss a')}
          </td>
          <td>{report.appointment?.date || <em>not available</em>}</td>
          <td>{(report.version <= 2 ? (report.inputs?.[0]?.answer?.[0]) : (report.answers?.[0]?.value)) || "—"}</td>
          {showStatus ?
            <td>{
              report.statusFlags && report.statusFlags[userId] === STATUS_FLAG.COMPLETE
              ? <span className="text-success"><FaCheckCircle/> Complete</span>
              : <a className="btn-sm btn-outline" onClick={ev => markComplete(report, ev)}>Mark Complete</a>
            }</td>
            : null}
          {actions ? <td>{actions.map(a => <p><a onClick={a.fn}>{a.label}</a></p>)}</td> : null}
        </tr>)
      )}
    </tbody>
  </table>);
};

function mapStateToProps(state, ownProps) {
  return {
    userId: state.auth.user.uid,
    ...ownProps
  };
}


export default connect(mapStateToProps)(ReportTable);