import { createAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { storage } from "../../database";

const audioAdapter = createEntityAdapter({
  selectId: m => m.filename
});

const initialState = {
  audioSources: audioAdapter.getInitialState(),
  activeAudio: {},
  hasPlayedAudio: false
};

const ALLOWED_ERRORS = ["storage/object-not-found"];

export const resolveAudioURLs = createAsyncThunk(
  'audio/storage-urls',
  async ({filenames, requestCached = false}, thunkAPI) => {
    if (!Array.isArray(filenames)) throw new Error("Illegal argument: filenames should be array!");
    const filtered = requestCached ? filenames : filenames.filter(f => !thunkAPI.getState().audio.audioSources.ids.includes(f));
    if (filtered.length === 0 && filenames.length > 0) {
      console.log(`All ${filenames.length} audio files were already known`);
      return [];
    }
    let allowedErrorCount = 0;
    const urls = await Promise.all(
      filtered.map(filename => storage.ref().child(filename).getDownloadURL().then(
        url => ({
          filename,
          src: url
        }),
        error => {
          if (ALLOWED_ERRORS.includes(error?.code)) {
            allowedErrorCount++;
            return ({filename, requestError: error});
          } else {
            throw error;
          }
        }
      ))
    );
    console.warn({audioUrls: urls, allowedErrorCount});
    return urls;
  }
);


const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    playLocalAudio: (state, action) => {
      state.activeAudio.filename = action.payload;
      state.activeAudio.playing = true;
      const lookup = audioAdapter.getSelectors().selectById(state.audioSources, action.payload);
      if (lookup) {
        state.activeAudio.src = lookup.src;
      }
    },
    localAudioFinished: (state, action) => {
      if (state.activeAudio.filename === action.payload) {
        state.activeAudio.playing = false;
      } else {
        console.warn(`Tried to stop audio ${action.payload}, but wasn't active`);
      }
    },
    playGlobalAudio: (state, action) => {
      state.activeAudio.playing = false; // ?
    },
    audioStateChange: (state, action) => {
      audioAdapter.updateOne(state.audioSources, action.payload);
    }
  },
  extraReducers: builder => {
    builder.addCase(resolveAudioURLs.fulfilled, (state, action) => {
      audioAdapter.addMany(state.audioSources, action.payload);
    });
  }
});

export const audioMiddleware = ({getState}) => {

  const unknownFiles = new Set();

  const globalAudios = new Map([
    ["TAP", "https://firebasestorage.googleapis.com/v0/b/literaseed-5b6b8.appspot.com/o/sound%2Fui_tap-variant-01.wav?alt=media&token=a976620d-5886-42d6-b363-eb94eb70494c"],
    ["ALERT", "https://firebasestorage.googleapis.com/v0/b/literaseed-5b6b8.appspot.com/o/sound%2Falert_error-02.wav?alt=media&token=8e30e38d-b227-40a3-9c98-e7d9b5869ff0"]
  ].map(([k, src]) => [k, new Audio(src)]));

  /** @type {Map<string, HTMLAudioElement>} */
  const recentAudios = new Map();
  const recentKeys = [];
  const RECENT_AUDIO_LIMIT = 5;
  function addToRecent (src, obj) {
    if (recentAudios.has(src)) return;
    if (recentKeys.length >= RECENT_AUDIO_LIMIT) {
      recentAudios.delete(recentKeys.unshift());
    }
    recentKeys.push(src);
    recentAudios.set(src, obj);
  }

  /**
   * 
   * @param {string} src 
   * @returns {HTMLAudioElement}
   */
  function getRecentOrBuild (src) {
    if (recentAudios.has(src)) return recentAudios.get(src);
    const audio = new Audio(src);
    addToRecent(src, audio);
    return audio;
  }

  return next => action => {
    switch (action.type) {
      case `${audioSlice.actions.playLocalAudio}`:
        const storageFile = audioAdapter.getSelectors().selectById(getState().audio.audioSources, action.payload);
        if (storageFile) {
          const audio = getRecentOrBuild(storageFile.src);
          recentAudios.forEach(a => {
            if (a.duration > 0 && !a.paused) {
              a.pause();
            }
          });
          audio.currentTime = 0;
          audio.play().then(
            () => {
              //
            },
            err => {
              console.error(`Error playing audio ${action.payload}: ${err}`);
              next(audioSlice.actions.audioStateChange({filename: action.payload, status: "error"}));
            }
          );
          audio.onended = () => {
            next(audioSlice.actions.localAudioFinished(action.payload));
          };
        } else {
          console.error(`Asked to play an audio file whose URL was not known: '${action.payload}'`);
          unknownFiles.add(action.payload);
        }
        break;
      case `${audioSlice.actions.playGlobalAudio}`:
        if (globalAudios.has(action.payload)) {
          globalAudios.get(action.payload).play().then(
            () => {},
            err => console.error(`Error playing GLOBAL audio ${action.payload}: ${err}`)
          )
        } else {
          console.error(`Unknown global audio name ${action.payload}`);
        }
        break;
      default:
        break;
    }
    next(action);
  };
}

export const playLocalAudio = audioSlice.actions.playLocalAudio;
export const playGlobalAudio = audioSlice.actions.playGlobalAudio;

export const audioSourceSelectors = audioAdapter.getSelectors(s => s.audio.audioSources);
export const getAudioSource = audioSourceSelectors.selectById;

export default audioSlice.reducer;
