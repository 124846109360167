import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { adminUsersRequested, adminReportsRequested, getAllReports, adminPublicReportsRequested, adminOrgsRequested, emulateIdentity } from "../../store/slices/admin";
import { getAllUsers } from "../../store/slices/users";
import UsersTable from "./UsersTable"
import ReportTable from "../Report/ReportTable";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ContainerFluid } from "../UI/Grid/Grid";
import { Link, useHistory } from "react-router-dom";
import { ORGANIZATION_DETAILS, PROVIDER, PROVIDER_ENROLL_PATIENT, REPORTS } from "../../constants/routes";
import moment from "moment";
import { findFeedbackForUser, getClinicianColleagues } from "../../store/actions";
import { NullsToBottom } from "../../utils";

const TIMESTAMP_COL = {
  type: "number",
  valueFormatter: ({value}) => value ? moment(value).format("M/D/YY hh:mm:ss") : ""
}
const LENGTH_COL = {
  type: "number",
  valueFormatter: ({value}) => Array.isArray(value) ? value.length : 0
}
const ROLE_BADGES = {
  Patient: "badge-light",
  Provider: "badge-primary",
  Admin: "badge-secondary"
}
const FEEDBACK_BADGES = {
  Satisfied: "badge-success",
  Neutral: "badge-light",
  Dissatisfied: "badge-warning"
};
const ROLE_SORT = {
  Admin: 0,
  Provider: 10,
  Patient: 50
}

const AdminHome = ({users, reports, publicReports}) => {
  const dispatch = useDispatch();
  const organizations = useSelector(s => Object.values(s.admin.organizations));
  const history = useHistory();
  useEffect(() => {
    dispatch(adminUsersRequested());
    dispatch(adminOrgsRequested());
    dispatch(adminReportsRequested());
    dispatch(adminPublicReportsRequested());
  }, []);

  const [feedback, setFeedback] = useState(null);
  function getFeedback (userId) {
    dispatch(findFeedbackForUser(userId)).then(feedbackResponse => {
      if (Array.isArray(feedbackResponse)) {
        feedbackResponse.sort((a, b) => NullsToBottom(a, b, (a, b) => moment(a?.datetime).diff(b?.datetime)));
        setFeedback(feedbackResponse);
      }
    })
  }

  function viewDashboardFor (organizationId) {
    dispatch(emulateIdentity({organizationId}));
    dispatch(getClinicianColleagues(organizationId)).then(orgUsers => {
      history.push(PROVIDER);
    })
  }

  const ORGS_COLUMNS = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => <>
        <button className="btn btn-primary btn-sm" onClick={() => history.push(`${ORGANIZATION_DETAILS}/${params.id}`, {})}>View Details</button>
        <button className="btn btn-info btn-sm" onClick={() => viewDashboardFor(params.id)}>View Dashboard</button>
        </>
    }
  ];

  const USERS_COLUMNS = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.75,
      hide: true
    },
    {
      field: 'role',
      flex: 0.75,
      headerName: "Role",
      type: 'singleSelect',
      valueOptions: ['Patient', 'Provider', 'Admin'],
      renderCell: params => <span className={`badge ${ROLE_BADGES[params.value]}`}>{params.value}</span>,
      sortComparator: (a, b) => ROLE_SORT[a] - ROLE_SORT[b]
    },
    {
      field: 'lastName',
      headerName: 'Last name'
    },
    {
      field: 'name',
      headerName: 'Name (as provider)',
      hide: true
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'phone',
      headerName: 'Phone',
    },
    {
      field: 'phoneType',
      flex: 0.5,
      headerName: 'Phone Type',
      type: 'singleSelect',
      valueOptions: [
        'sms', 'whatsapp'
      ],
    },
    {
      field: 't_created',
      headerName: 'Created',
      ...TIMESTAMP_COL
    },
    {
      field: 't_nextAppointment',
      headerName: 'Next Appt (day+time)',
      hide: true,
      ...TIMESTAMP_COL
    },
    {
      field: 'd_nextAppointment',
      headerName: 'Next Appt (day)',
      type: "string",
      hide: true,
    },
    {
      field: 'combined_nextAppointment',
      headerName: 'Next Appt',
      valueGetter: (params) => {
        const t = params.getValue(params.id, 't_nextAppointment');
        const d = params.getValue(params.id, 'd_nextAppointment');
        if (t) {
          return moment(t).format("MM/DD/YY hh:mm:ss");
        }
        if (d) {
          return moment(d).format("MM/DD/YY")
        }
        return null;
      }
    },
    {
      field: 'status',
      headerName: 'Status',
    },
    {
      field: 't_statusUpdated',
      headerName: 'Last Update',
      ...TIMESTAMP_COL
    },
    {
      field: 'screeningsUsed',
      headerName: 'Screenings Used'
    },
    {
      field: 'providerId',
      headerName: 'Provider ID',
      hide: true
    },
    {
      field: 'appointments',
      headerName: '#appts',
      flex: 0.5,
      hide: true,
      ...LENGTH_COL
    },
    {
      field: 'reports',
      headerName: '#reports',
      flex: 0.5,
      hide: true,
      ...LENGTH_COL
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => <button className="btn btn-primary btn-sm" onClick={() => getFeedback(params.id)}>Find Feedback</button>
    },
    {
      field: 'version',
      headerName: 'DB Version',
      hide: true
    }
  ].map(i => {
    if (!i.flex) i.flex = 1;
    return i;
  });

  const REPORT_COLUMNS = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.75
    },
    {
      field: 'lastName',
      headerName: "Last Name",
      valueGetter: (params) => {
        const submitter = params.getValue(params.id, "submitter");
        if (submitter) {
          return submitter.lastName;
        }
        return "";
      }
    },
    {
      field: 'type',
      headerName: 'Screening'
    },
    {
      field: 'screenerVersion',
      headerName: 'Scr. Version'
    },
    {
      field: 'completionLanguage',
      headerName: 'Language'
    },
    {
      field: 'durationSeconds',
      headerName: 'Duration (s)'
    },
    {
      field: 'requesterId',
      headerName: 'Requester ID',
      hide: true
    },
    {
      field: 't_submitted',
      headerName: 'Created',
      ...TIMESTAMP_COL
    },
    {
      field: 'answer count',
      headerName: 'Answer count',
      valueGetter: (params) => {
        const a = params.getValue(params.id, 'answers');
        return a ? Object.values(a).length : 0;
      }
    },
    {
      field: 'outcome count',
      headerName: 'Outcome count',
      valueGetter: (params) => {
        const a = params.getValue(params.id, 'outcomes');
        return a ? Object.values(a).length : 0;
      },
      hide: true
    },
    {
      field: 'version',
      headerName: 'DB Version',
      hide: true
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => <button className="btn btn-primary btn-sm" onClick={() => history.push(`${REPORTS}/${params.id}`, { reportId: params.id, fromPublic: !!params.row.FROM_PUBLIC })}>Open</button>
    }
  ].map(i => {
    if (!i.flex) i.flex = 1;
    return i;
  });

  return <ContainerFluid>
    <Row>
    <Col className="col-12">
      <h2 className="mt-3">Organizations</h2>
      <DataGrid
        columns={ORGS_COLUMNS}
        rows={organizations}
        loading={!(organizations?.length > 0)}
        components={{Toolbar: GridToolbar}}
        pageSize={20}
        autoHeight={true}
        />
    </Col>
    <Col className="col-6">
      <h2 className="mt-3">Users</h2>
    </Col>
    <Col className="col-6">
      <Link
        className="btn btn-primary mt-3"
        to={PROVIDER_ENROLL_PATIENT}>
          Enroll a New Patient
      </Link>
    </Col>
    <Col className="col-12">
      <DataGrid
        columns={USERS_COLUMNS}
        rows={users}
        loading={!(users?.length > 0)}
        components={{Toolbar: GridToolbar}}
        pageSize={20}
        autoHeight={true}
        />
      <br/>
      <h2 className="">
        Reports
        {reports?.length === 200 ? <small className="text-wdanger" style={{paddingLeft: "2em", display: "inline-block"}}>...only most recent 200 displayed!</small> : null}
      </h2>
      <DataGrid
        columns={REPORT_COLUMNS}
        rows={reports}
        loading={!(reports?.length > 0)}
        components={{Toolbar: GridToolbar}}
        pageSize={20}
        autoHeight={true}
        />
      <br/>
      <h2 className="">
        <em>Public</em> Reports
        {publicReports?.length === 200 ? <small className="text-wdanger" style={{paddingLeft: "2em", display: "inline-block"}}>...only most recent 200 displayed!</small> : null}
      </h2>
      <DataGrid
        columns={REPORT_COLUMNS}
        rows={publicReports}
        loading={!(publicReports?.length > 0)}
        components={{Toolbar: GridToolbar}}
        pageSize={20}
        autoHeight={true}
        />
    </Col>
  </Row>
  <Modal show={!!feedback} onHide={() => setFeedback(null)} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>User Feedback</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <table className="table">
        <tbody>
          {feedback?.length > 0 ?
          feedback.map((f, i) => <tr>
            <td>{i}</td>
            <td>
              {moment.unix(f.datetime.seconds).calendar()}
            </td>
            <td>
              {typeof f.feedbackData === "string" ? 
                <span className={`badge ${FEEDBACK_BADGES[f.feedbackData]}`}>
                  {f.feedbackData}
                </span>
                :
                <>
                <span className={`badge ${FEEDBACK_BADGES[f.feedbackData.rating]}`}>
                  {f.feedbackData.rating}
                </span>
                {" "}
                <em>{f.feedbackData.freeText}</em>
                </>
              }
            </td>
          </tr>)
          : <tr><td><h3>No feedback found</h3></td></tr>}
        </tbody>
      </table>
    </Modal.Body>
    <Modal.Footer>
      
    </Modal.Footer>
  </Modal>
  
  </ContainerFluid>;
}

function mapStateToProps(state, ownProps) {
  return {
    reports: getAllReports(state).filter(r => !r.FROM_PUBLIC),
    publicReports: getAllReports(state).filter(r => r.FROM_PUBLIC),
    users: getAllUsers(state)
  };
}

export default connect(mapStateToProps)(AdminHome);