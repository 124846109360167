import { firestore } from "firebase";
import Moment from "moment";
// import { hash } from "../../utils";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import { FaBirthdayCake, FaIdCard, FaUser } from "react-icons/fa";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { IS_DEVELOPMENT, IS_QA } from "../../constants/app";
import { LanguageList } from "../../constants/locales";
import * as ROUTES from "../../constants/routes";
import { DevAssignable } from "../../constants/screenings";
import { ScreeningStatus } from "../../database/model/user";
import {
  debugTweakAccessKey,
  // signInAnonymously,
  setPreferedProvider,
  updateUserLastName
} from "../../store/actions";
import { getScreeningById, updateScreening } from "../../store/slices/screenings";
import { equalCaseInsensitive } from "../../utils";
import PlayAudio from "../Audio/PlayAudio";
import Alert from "../UI/Alert/Alert";
import FloatingAction from "../UI/FloatingAction/FloatingAction";
import { alertEvent, snack } from "../UI/GlobalAlerts";
// import { ROLES } from "../../constants/roles";
import { Col, Container, Row } from "../UI/Grid/Grid";
import { updateObject } from "../UI/Input/helpers";
import { Input } from "../UI/Input/Input";
// import { MdPhone } from "react-icons/md";
import Spinner from "../UI/Spinner/Spinner";


const SigninAnonymously = ({
  debugLocation,
  onSigninAnonymously,
  onSaveProviderId,
  redirectPath,
  redirectState,
  isError,
  error,
  isLoading,
  isAnonymous,
  isAuthenticated,
  isInactive,
  urlParams,
  // report,
  // reportId,
  user,
  onUpdateUserLastName,
  authOrg,
  onComplete
}) => {
  const INITIAL_CREDENTIAL_STATE = {
    a_lastName: {
      label: "Last Name",
      placeholder: "Last Name",
      inputIcon: FaUser,
      type: 'text',
      elementType: 'input',
      value: user.lastName,
      valid: true,
      viewInfo: false,
      viewErrors: true,
      viewSuccess: false,
      initialValidate: false,
      rules: {
        required: true,
      },
    },
    b_dob: {
      label: "Date of Birth",
      placeholder: "MM/DD/YYYY",
      inputIcon: FaBirthdayCake,
      type: "input",
      elementType: "date",
      value: null,
      viewInfo: false,
      viewErrors: false,
      viewSuccess: false,
      initialValidate: false,
      valid: true,
      rules: {
        mandatory: true,
      },
    },
    c_medicalRecordNumber: {
      label: "Medical Record Number",
      placeholder: "Medical Record Number",
      inputIcon: FaIdCard,
      type: 'text',
      elementType: 'input',
      value: null,
      valid: true,
      viewInfo: false,
      viewErrors: true,
      viewSuccess: false,
      initialValidate: false,
      rules: {
        required: true,
      },
    }
  };
  const NAME_BASED_FORM = {
    a_lastName: INITIAL_CREDENTIAL_STATE.a_lastName,
    b_dob: INITIAL_CREDENTIAL_STATE.b_dob
  }
  const MRN_BASED_FORM = {
    c_medicalRecordNumber: INITIAL_CREDENTIAL_STATE.c_medicalRecordNumber
  }

  const history = useHistory();
  const dispatch = useDispatch();
  const [isAgreed, setIsAgreed] = useState(true);
  const [isShowErrors, setIsShowErrors] = useState(false);
  const [signinForm, setSigninForm] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLinkMatch, setIsLinkMatch] = useState(true);
  const accessKey = useSelector(s => s.auth.accessKey);
  const formType = useMemo(() => accessKey ? accessKey.credentialType ?? "name+DOB" : "none", [accessKey])
  const debugMode = useSelector(s => s.session.debugMode);

  // ! React Hook useEffect has missing dependencies: 'onSaveProviderId',
  // ! 'urlParams', and 'user.providerId'. Either include them or remove the
  // ! dependency array. If 'onSaveProviderId' changes too often, find the
  // ! parent component that defines it and wrap that definition in useCallback
  useEffect(() => {
    if (urlParams && urlParams.pid && !isLoading && !isError) {
      onSaveProviderId(urlParams.pid);
    }
    if (user.providerId) {
      onSaveProviderId(user.providerId);
    }
  }, [urlParams.pid, isLoading, isError]);

  useEffect(() => {
    if (accessKey) {
      if (accessKey.credentialType === "MRN") {
        setSigninForm({...MRN_BASED_FORM});
      } else {
        setSigninForm({...NAME_BASED_FORM});
      }
    }
  }, [accessKey]);

  // Called with each element change
  const inputChangedHandler = (value, valid, inputIdentifier) => {
    const updatedFormElement = updateObject(signinForm[inputIdentifier], {
      value,
      valid: inputIdentifier === "b_dob" ? true : valid,
    });
    const updatedForm = updateObject(signinForm, {
      [inputIdentifier]: updatedFormElement,
    });

    let isFormValid = true;
    for (let inputIdentifier in updatedForm) {
      isFormValid = updatedForm[inputIdentifier].valid && isFormValid;
    }
    setSigninForm(updatedForm);
    setIsFormValid(isFormValid);
  };

  const invite = useSelector(s => getScreeningById(s, accessKey.inviteId));

  const submitHandler = (event) => {
    event.preventDefault();
    let isValid = true;
    if (!isFormValid) {
      // make sure to verify if some form elements are not mandatory
      for (let inputIdentifier in signinForm) {
        isValid = signinForm[inputIdentifier].valid && isValid;
      }
    }
    if (!isValid || !isAgreed) {
      let updatedForm = { ...signinForm };
      for (let key in updatedForm) {
        updatedForm[key].viewErrors = true;
        updatedForm[key].viewInfo = false;
        updatedForm[key].initialValidate = true;
      }

      setIsShowErrors(true);
      setSigninForm(updatedForm);
    } else {
      const now = Date.now();
      const shouldAdvanceInviteStatus = [ScreeningStatus.InviteSent, ScreeningStatus.InviteOpened].includes(invite?.status);
      if (formType === "name+DOB") {
        if (!Moment(signinForm.b_dob.value).isSame(user.dob, 'day')) {
          setIsShowErrors(true);
          setIsLinkMatch(false);
        } else {
          if (equalCaseInsensitive(signinForm.a_lastName.value, user.lastName)) {
            setIsLinkMatch(true);
            setIsShowErrors(false);
            setIsAgreed(false);
            if (shouldAdvanceInviteStatus) {
              dispatch(updateScreening({id: accessKey.inviteId, changes: {status: ScreeningStatus.Started, t_started: now, t_statusUpdated: now}}));
            }
            dispatch({
              type: "auth-details/CONFIRM-CREDENTIALS-LOCALLY"
            });
            continueTo();
          } else {
            dispatch(updateScreening({id: accessKey.inviteId, changes: {loginFailures: firestore.FieldValue.arrayUnion(Date.now())}}));
            setIsShowErrors(true);
            setIsLinkMatch(false);
            return;
          }
        }
      } else if (formType === "MRN") {
        if (equalCaseInsensitive(signinForm.c_medicalRecordNumber.value, user.activeMRN)) {
          setIsLinkMatch(true);
          setIsShowErrors(false);
          setIsAgreed(false);
          if (shouldAdvanceInviteStatus) {
            dispatch(updateScreening({id: accessKey.inviteId, changes: {status: ScreeningStatus.Started, t_started: now, t_statusUpdated: now}}));
          }
          dispatch({
            type: "auth-details/CONFIRM-CREDENTIALS-LOCALLY"
          });
          continueTo();
        } else {
          dispatch(updateScreening({id: accessKey.inviteId, changes: {loginFailures: firestore.FieldValue.arrayUnion(Date.now())}}));
          setIsShowErrors(true);
          setIsLinkMatch(false);
          return;
        }
      }
    }
  };

  function debugQuickLogin () {
    const now = Date.now();
    dispatch(updateScreening({id: accessKey.inviteId, changes: {status: ScreeningStatus.Started, t_started: now, t_statusUpdated: now}}));
    dispatch({
      type: "auth-details/CONFIRM-CREDENTIALS-LOCALLY"
    });
    continueTo();
  }

  function continueTo () {
    if (typeof onComplete === "function") {
      onComplete();
    } else {
      if (redirectPath) {
        history.push(redirectPath, redirectState);
      }
    }
  }

  const audioFileName = 'sign_in_anonymous.mp3';

  const onCheckAgreement = () => {
    setIsAgreed(!isAgreed);
  };

  let content = "";

  content = (
    <div>
      <form
        onSubmit={submitHandler}
        autoComplete="off"
        className="form-signin__anonymous"
      >
        {signinForm ? Object.keys(signinForm)
          .sort()
          .map((key) => (
            <Input
              key={key}
              id={key}
              elementType={signinForm[key].elementType}
              name={signinForm[key].name}
              label={signinForm[key].label}
              labelClassName="font-weight-bold"
              InputIcon={signinForm[key].inputIcon}
              placeholder={signinForm[key].placeholder}
              value={signinForm[key].value}
              required={signinForm[key].required}
              options={signinForm[key].options}
              rules={signinForm[key].rules}
              type={signinForm[key].type}
              autoComplete="false"
              changed={(value, valid) => inputChangedHandler(value, valid, key)}
              viewErrors={signinForm[key].viewErrors}
              viewSuccess={signinForm[key].viewSuccess}
              viewInfo={signinForm[key].viewInfo}
              initialValidate={signinForm[key].initialValidate}
            />
          )) : <h3 className="text-muted">Loading...</h3>}
        <label className="form-check-label mb-3">
          <input
            name="checkbox"
            type="checkbox"
            className="checkbox mr-3"
            defaultChecked={true}
            onChange={onCheckAgreement}
          />
          By clicking "Continue" below, you agree to our {" "}
          <Link
            to={ROUTES.TERMS_OF_SERVICE}
            rel="noopener noreferrer"
            target="_blank"
          >
            <u className="text-info">Terms of Service</u>
          </Link>{" "}
          and{" "}
          <Link
            to={ROUTES.PRIVACY_NOTICE}
            target="_blank"
            rel="noopener noreferrer"
          >
            <u className="text-info">Privacy Notice</u>
          </Link>
        </label>
        {/* <label className="form-check-label mb-3">
          <input
            name="checkbox"
            type="checkbox"
            className="checkbox mr-3"
            defaultChecked={true}
            onChange={() => {}} // onCheckStudy
          />
          I agree to participate in the study. {" "}
          <Link
            to={ROUTES.PRIVACY_NOTICE}
            target="_blank"
            rel="noopener noreferrer"
          >
            <u className="text-info">STUDY DISCLOSURE</u>
          </Link>
        </label> */}
        {isShowErrors && !isAgreed ? (
          <p className="text-red">
            You need to agree to the terms of service and privacy notice.
          </p>
        ) : (
          ""
        )}
        {
          IS_QA ? <div className="text-danger mb-2" title="This is a debug-only box, it will not appear outside of QA." style={{opacity: "70%", backgroundColor: "#DDEEFF", padding: "6px", borderRadius: "4px"}}>
            <em>invitation overrides</em>
            <select defaultValue={0} className="form-control" onChange={e => dispatch(debugTweakAccessKey({screener: e.target.value}))}>
              <option disabled value={0}>SCREENING TYPE</option>
              {DevAssignable.sort((a, b) => a.localeCompare(b)).map(t => <option key={t}>{t}</option>)}
            </select>
            <select defaultValue={0} className="form-control" onChange={e => dispatch(debugTweakAccessKey({language: e.target.value}))}>
              <option disabled value={0}>LANGUAGE</option>
              {LanguageList.map(l => <option key={l}>{l}</option>)}
            </select>
            {IS_DEVELOPMENT ? <button onClick={debugQuickLogin} className="btn btn-danger btn-block">
              Skip Verification (Local-only)
            </button> : null}
          </div> : null
        }
        <Button type="submit" className="btn-primary btn-block">
          Continue
        </Button>
      </form>
      <p className="mt-4 mb-3 text-muted">&copy; LiteraSeed, Inc.</p>
    </div>
  );

  const isActiveMsg = isInactive ? (
    <Alert
      error={new Error("For security reasons your session has expired!")}
    />
  ) : (
    ""
  );

  const clinicianTerm = authOrg.terminology?.clinicianUser || "doctor";
  const organizationName = authOrg.name || "your health clinic";

  return isLoading ? <Spinner /> : (!isAuthenticated || !isAnonymous)
  ? <Redirect to={{pathname: ROUTES.WAITLIST, search: history.location.search}}/>
  : (
    <Container className="pr-0 pl-0">
      <Row className="justify-content-center mt-5">
        <Col className="col-12">
          {isError && <Alert error={error} />}
          {isShowErrors && !isLinkMatch && (
            <Alert
              error={
                new Error(
                  "Entered information doesn't match the invitation link."
                )
              }
            />
          )}
          {isActiveMsg}
        </Col>
      </Row>
      {/*<Row className="justify-content-center mt-5">
      <Col className="col-12 col-md-8 col-lg-6">
        <p className="mb-5 text-center px-3">Thank you for taking the COVID-19 screening questions! Please verify your information below in order to send the report to your provider!</p>
      <h1 className="h3 mb-5 text-center px-3">Start the COVID-19 Screening Questions!</h1>
      </Col>
    </Row>*/}
      <Row className="justify-content-center">
        <Col className="col-auto">
          <img style={{maxWidth: "100%", padding: "5px"}} src={authOrg.logoURL || "/images/Green.png"} alt={"logo "+authOrg.name}/>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-12 col-md-8 col-lg-6">
          <div className="p-2 pb-4">
            <p className="m-3 text-center">
              Your {clinicianTerm} at {organizationName} has requested that you answer a few questions before your visit!
              First, enter your <strong>last name</strong> and <strong>date of birth</strong> to confirm that it's you.
              {/* To send this report to your doctor, please enter your last name
              and date of birth. */}
            </p>
            <p className="text-center"><PlayAudio filename={audioFileName} /></p>
            {content}
          </div>
        </Col>
      </Row>
      <FloatingAction context="sign-in-anonymous"/>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onSigninAnonymously: (data) => dispatch(signInAnonymously(data)),
    // onSaveProviderId: (pid) => dispatch(savePreferedProviderId(pid))
    onSaveProviderId: (providerId) => dispatch(setPreferedProvider(providerId)),
    onUpdateUserLastName: (userData) => dispatch(updateUserLastName(userData)),
  };
};

function mapStateToProps(state, ownProps) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
    authOrg: state.auth.organization,
    // pid: state.auth.user.providerId,
    isAnonymous: state.auth.user && state.auth.user.isAnonymous ? true : false,
    error: state.auth.error,
    isError: state.auth.isError,
    // reportId: state.report.reportId,
    // report: state.report.reports[state.report.reportId],
    redirectPath: ownProps.location.from ||
      (Array.isArray(ownProps.location.state?.nextLocations)
        ? ownProps.location.state.nextLocations[0] : ROUTES.REPORTS_ANONYMOUS),
    redirectState: ownProps.location.state,
    debugLocation: ownProps.location,
    onComplete: ownProps.onComplete,
    urlParams: ownProps.location
      ? queryString.parse(decodeURIComponent(ownProps.location.search))
      : "",
    isInactive: ownProps.location.isInactive,
    user: state.auth.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SigninAnonymously);
