import React from "react";
import PropTypes from "prop-types";

const VimeoEmbed = ({ embedId }) => (

  <div className="video-responsive">
    <iframe
      src={`https://player.vimeo.com/video/${embedId}`}
      width="843"
      height="480"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title="Embedded Vimeo video"
    />
  </div>
);

VimeoEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default VimeoEmbed;