import i18n from "i18next";
import { initReactI18next, useTranslation } from 'react-i18next';

import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { LANGUAGE_WHITELIST } from "./constants/locales";

const baseResource = {
  "en": {
    
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({

    react: {
      useSuspense: false
    },

    debug: true,
    // lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    whitelist: LANGUAGE_WHITELIST,

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;

export function useTextDirection() {
  const [t, language] = useTranslation();
  return language.dir();
}
