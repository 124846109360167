import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dualName } from "../../../constants/locales";
import { ScreenerNames } from "../../../constants/screenings";
import { getTicketById, updateUserTicket, userTicketRequested } from "../../../store/slices/admin";
import { Container, Row, Col } from "../Grid/Grid";

export const TicketDetails = (props) => {
  const ticketId = props.ticketId || props.match?.params?.ticketId;
  const ticket = useSelector(s => getTicketById(s, ticketId));
  const dispatch = useDispatch();

  function stringify (json) {
    try {
      return JSON.stringify(json, undefined, 2);
    } catch (err) {
      return `Could not stringify: ${err}`;
    }
  }

  function toggleOpen () {
    if (ticket?.status === "closed") {
      dispatch(updateUserTicket({id: ticketId, changes: {status: "open"}}));
    } else {
      dispatch(updateUserTicket({id: ticketId, changes: {status: "closed"}}));
    }
  }

  useEffect(() => {
    if (!ticket && ticketId) {
      dispatch(userTicketRequested(ticketId)).then(result => {
        // nothing, got moved into async request
      })
    }
  }, [ticketId]);

  return <Container><Row className="justify-content-center"><Col className="col-md-9">
    {ticket ? <div className="my-4 p-4" style={{boxShadow: "0 0 5px gray", borderRadius: "10px"}}>
      <h3>User Ticket ({ticket.t_created ? moment(ticket.t_created).calendar() : "unknown date"})
        <span 
          onClick={toggleOpen}
          title="Click to toggle open/closed status"
          className={`badge ${ticket.status !== "closed" ? "badge-success" : "badge-danger"} float-right`}>
          {ticket.status ?? "new"}
        </span>
        <span className="badge badge-light float-right">{ticketId}</span>
      </h3>
      <ul className="list">
        <li><strong>Name:</strong> {ticket.name}</li>
        <li><strong>Phone:</strong> {ticket.phoneNumber ? <a href={`tel:${ticket.countryCode}${ticket.phoneNumber}`}>{ticket.countryCode} {ticket.phoneNumber}</a> : <span className="badge badge-dark">N/A</span>}</li>
        <li><strong>Email:</strong> {ticket.email ? <a href={`mailto:${ticket.email}`}>{ticket.email}</a> : <span className="badge badge-dark">N/A</span>}</li>
        <li><strong>Context / URL:</strong>
          <span className="badge badge-info ml-2">{ticket.context}</span>
          <p className="text-info mx-2">{ticket.currentURL ? <a href={ticket.currentURL}>{ticket.currentURL}</a> : <span className="badge badge-dark">N/A</span>}</p>
        </li>
        <li>
          <strong>Message</strong>
          <p className="text-danger mx-2">{ticket.message}</p>
        </li>
        <hr className="mt-0"/>
        <li><strong>Language:</strong> {dualName(ticket.language)}</li>
        <li><strong>Screener:</strong> {ScreenerNames[ticket.questionnaire || ticket.accessKey?.screener]} <span className="badge badge-light">{ticket.questionnaire || ticket.accessKey?.screener}</span></li>
        <li><strong>User ID:</strong>
          <span className="badge badge-light ml-2">{ticket.userId}</span>
          <p className="text-info mx-2"><a href={`/provider/patient/${ticket.userId}`}>View patient details page (if it exists)</a></p>
        </li>
        <li><strong>Access Key:</strong> {ticket.accessKey ? <>
          <br/>
          <pre className="p-2 mb-0" style={{whiteSpace: "pre-wrap", background: "#EEE", borderRadius: "8px"}}>{stringify(ticket.accessKey)}</pre>
        </> : <span className="badge badge-dark">N/A</span>}</li>
        <li><strong>Navigation History:</strong> {ticket.questionnaireNavData ? <>
          <br/>
          <pre className="p-2 mb-0" style={{whiteSpace: "pre-wrap", background: "#EEE", borderRadius: "8px"}}>{stringify(ticket.questionnaireNavData)}</pre>
        </> : <span className="badge badge-dark">N/A</span>}</li>
        <li><strong>Session Data:</strong> {ticket.sessionData ? <>
          <br/>
          <pre className="p-2 mb-0" style={{whiteSpace: "pre-wrap", background: "#EEE", borderRadius: "8px"}}>{stringify(ticket.sessionData)}</pre>
        </> : <span className="badge badge-dark">N/A</span>}</li>
      </ul>
    </div> : <h2 className="text-danger">Ticket for #{ticketId} not found!</h2>}
    </Col></Row></Container>
};
