import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { adminUsersRequested } from "./admin";
import { db } from "../../database";


const usersAdapter = createEntityAdapter(entity => entity.id);

const INITIAL_STATE = usersAdapter.getInitialState({
  current: null
});

export class User {
  static CollectionRef = db.collection('users');
  static WriteVersion = 4;
  /**
   * @type {string} The ID of the report itself
   */
   id;
  /**
   * @type {string} The ID of the provider managing this user
   */
   providerId;
   /**
    * @type {string} The user's last name. This is required for patients but 
    * optional for providers and admins.
    */
  lastName;
  /**
   * @type {string}
   */
  name;
  /**
   * @type {string}
   */
  email;
  /**
   * @type {string}
   */
  phone;
  /**
   * @type {'sms'|'whatsapp'}
   */
  phoneType;
  /**
   * @type {string}
   */
  dob;
  /**
   * @type {*}
   */
  verification;
  /**
   * @type {'Patient'|'Provider'|'Admin'}
   */
  role;

  /**
   * @type {}
   */
  appointments;
  screenings;
  reports;
}

const usersSlice = createSlice({
  name: 'users',
  initialState: INITIAL_STATE,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(adminUsersRequested.fulfilled, (state, action) => {
      action.payload.forEach(u => u._loaded = {action: action.type, timestamp: Date.now()});
      usersAdapter.upsertMany(state, action.payload);;
    });
  }
});

const userSelectors = usersAdapter.getSelectors(state => state.users);
export const getAllUsers = userSelectors.selectAll;
export const getUserById = userSelectors.selectById;
export const getCurrentUser = state => userSelectors.selectById(state, state.users.current);

export default usersSlice.reducer;