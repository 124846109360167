import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "../UI/Grid/Grid";
import Footer from "../Footer/Footer";
import { NEWS_ENTRIES } from "./list";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "../../styles/press.css";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";



const LocalArticle = (props) => {
    const [markdown, setMarkdown] = useState("");
    const [error, setError] = useState(false);
    const isChangelog = props.match?.path?.startsWith("/changelogs") ?? false;

    useEffect(() => {
        const articleName = props.match.params.articleId;
        if (NEWS_ENTRIES.find(entry => entry.isLocal && entry.url.endsWith("/"+articleName)))
        {
            const dir = isChangelog ? "changelogs" : "press";
            fetch(`/content/${dir}/${articleName}.md`)
                .then(response => response.text())
                .then(content => setMarkdown(content),
                    error => setError(error));
        } else {
            setError(`key ${articleName} did not match known local entry`);
        }
    }, []);

    return (
        <>
            <Container className="text-center public-page my-5">
                <Row>
                    <Col className="col-sm-10 text-left">
                        {isChangelog 
                        ? <Link to={ROUTES.PRODUCT}><em className="text-info"> ← Back to Product Summary </em></Link>
                        : <Link to={ROUTES.PRESS}><em className="text-info"> ← Back to News </em></Link>}
                    </Col>
                </Row>
                <Row className="justify-content-center mt-3">
                    <Col className="col-sm-11 col-lg-9">
                        {(markdown !== "" && !error) ?
                        <ReactMarkdown
                            children={markdown}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                h2: ({node, ...props}) => <h3 className="mt-2" {...props} />,
                                ul: ({node, ...props}) => <ul style={{listStyle: "inherit"}} {...props} />,
                            }}
                            className="press-article text-left">
                        </ReactMarkdown>
                        : <h2 title={`[Error: ${error}]`}>Article not found!</h2>}
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    );
}

export default LocalArticle;
