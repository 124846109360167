import React from 'react';

import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import i18n from "./i18n";

import App from "./App";
import { initStore } from "./store/store";

const store = initStore();

function Root() {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
}

export default Root;
