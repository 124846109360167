import moment from "moment";

/**
 * Centralized definition of the plural names of the days of the week, used to
 * generate day dropdowns. NOTE: the code currently depends on this starting
 * with Sunday at index 0 to match up with the behavior of moment.day(<int>)
 */
export const PLURAL_DAY_NAMES = [
  "Sundays",
  "Mondays",
  "Tuesdays",
  "Wednesdays",
  "Thursdays",
  "Fridays",
  "Saturdays"
];

export const SERVER_TARGET_MINUTE = 5;

export const DEFAULT_TIMEZONE = "America/Phoenix";

/**
 * Centralized definition of the available hours for scheduling messages. This
 * is converted to an appropriate integer 0-23 by the function below.
 */
export const STANDARD_HOUR_OPTIONS = [
  "7am",
  "9am",
  "11am",
  "1pm",
  "3pm",
  "5pm",
  "7pm",
  "9pm",
];

export function hourStringToInt (hourString) {
  if (typeof hourString !== "string") return -1;
  return (hourString.toLocaleLowerCase().endsWith("pm") ? 12 : 0) + (Number.parseInt(hourString.slice(0, -2), 10) % 12);
}

/**
 * 
 */
export const MOMENT_DELTA_BY_PERIOD = {
  "week": [1, "week"],
  "two weeks": [2, "weeks"],
  "month": [4, "weeks"],
  "ONCE": [-30, "years"] // help identify any potential leakage of this value
};

/**
 * Find the next timeslot in which an occurrence *MAY* run, ignorant of what
 * additional logic may be applied by its nextSend or exceptions. Basically
 * the next time the server function will even look at the entry via query.
 * 
 * This does not attempt to guarantee minute-level precision vs how the timeslot
 * is scheduled on the server, but should represent the same timing on a
 * "human-level".
 * @returns {moment.Moment}
 */
export function findNextTimeslot (timezone, preferredDay, preferredHour, relativeDate) {
  const base = relativeDate ? moment(relativeDate) : moment();
  base.tz(timezone);

  const targetDayIndex = PLURAL_DAY_NAMES.indexOf(preferredDay);
  const targetHourIndex = hourStringToInt(preferredHour);

  if (targetDayIndex === base.day()) {
    // same day of week
    if (base.hour() < targetHourIndex ||
        (base.hour() === targetHourIndex && base.minute() < SERVER_TARGET_MINUTE)) {
      return base.hour(targetHourIndex).minute(SERVER_TARGET_MINUTE);
    } else {
      
    }
  }

  const dayNumberWithDelta = ((base.day() >= targetDayIndex) ? 7 : 0) + targetDayIndex;
  return base.day(dayNumberWithDelta).hour(targetHourIndex).minute(SERVER_TARGET_MINUTE);
}

export function hourDayToTimeslot (dateString, tz = "Etc/UTC", hourString) {
  if (typeof dateString === "string") debugger; // refactor soon
  const date = (typeof dateString === "string" ? moment.tz(dateString, tz) : moment(dateString).tz(tz));
  return date.hour(hourStringToInt(hourString)).minute(SERVER_TARGET_MINUTE);
}

/**
 * Jump an entire period, as if we just ran the recurrence and are figuring out
 * the nextSend after the present moment.
 * @returns {moment.Moment}
 */
export function jumpByPeriod (timezone, period, preferredDay, preferredHour, relativeDate) {
  const base = relativeDate ? moment(relativeDate).tz(timezone) : moment().tz(timezone);
  const targetHourIndex = hourStringToInt(preferredHour);

  if (period === "month") {
    const thisDayNextMonth = base.add(1, "month")
    const targetDayIndex = PLURAL_DAY_NAMES.indexOf(preferredDay);
    const dayNumber = ((thisDayNextMonth.day() > targetDayIndex) ? 7 : 0) + targetDayIndex;
    return thisDayNextMonth.day(dayNumber).hour(targetHourIndex).minute(SERVER_TARGET_MINUTE);
  } else {
    if (!(period in MOMENT_DELTA_BY_PERIOD)) {
      console.error(`Unknown period type '${period}'`);
    } else {
      const delta = MOMENT_DELTA_BY_PERIOD[period];
      return base.add(delta[0], delta[1]).hour(targetHourIndex).minute(SERVER_TARGET_MINUTE);
    }
  }
}