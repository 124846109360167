import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LanguageList, LanguageEndonym, Language } from "../../constants/locales";
import LanguageSelector from "../Locale/LanguageSelector";
// import { ContainerFluid } from "../UI/Grid/Grid";
import * as ROUTES from "../../constants/routes";
import { ROLES } from "../../constants/roles";
import "../../styles/homepage.css";
import classes from "../../styles/Navigation.module.scss";
import "../../styles/Navigation.module.scss";
import { FaArrowRight, FaBug, FaHome, FaRegUserCircle, FaSearch, FaTimes, FaUserPlus } from "react-icons/fa";
import { FiLogOut, FiUser } from "react-icons/fi";
import { AllowedPairings, AdditionalPairingsForDev, ScreeningType } from "../../constants/screenings";
import { IS_DEVELOPMENT } from "../../constants/app";
import { resetEnrollmentUser } from "../../store/actions";
import { Container } from "react-bootstrap";

const CURRENT_ALERT = 
<>
  <div className="landing-alert">
  <div className="alert alert-light text-charcoal" role="alert">
    <strong>Breaking</strong> &mdash;&nbsp;
    <Link to={`${ROUTES.PRESS}/20210901-SBIR`}>
      LiteraSeed Awarded NSF Grant to Develop Health Literacy App
    </Link>
  </div>
  </div>
</>;

const Navigation = ({
  isAuthenticated,
  isEmailLink,
  isAnonymous,
  role
}) => {
  const [t, i18n] = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    const language = i18n.language;
    setLang(language);
  }, [i18n.language]);

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value)
  };
  const location = useLocation();
  const [langsForFeature, setLangsForFeature] = useState(LanguageList)
  /* This is disabled because it is no longer comprehensive and the 
     langsForFeature list is no longer technically used. */
  // useEffect(() => {
  //   switch (location.pathname) {
  //     case ROUTES.HISTORY:
  //     case ROUTES.HISTORY_ANONYMOUS:
  //     // case ROUTES.HISTORY_PASSWORDLESS:
  //     case ROUTES.COVID_SCREENING:
  //       // we're in a questionnaire
  //       if (screenerType in AllowedTypes) {
  //         setLangsForFeature(AllowedTypes[screenerType]);
  //         break;
  //       } else if (IS_DEVELOPMENT && screenerType in DevTypes) {
  //         setLangsForFeature(DevTypes[screenerType]);
  //         break;
  //       } else {
  //         if (screenerType in ScreeningType) {
  //           console.error(`Screener ${screenerType} has no allowed languages`);
  //         }
  //       }
  //     default:
  //       setLangsForFeature(LanguageList);
  //   }
  // }, [location, screenerType])

  /**
   * NOTE: This used to be the main navigation bar for end-users / patients but
   * it is practically unused now, as it would only show for users who have role
   * Patient but did NOT login via key/link, and we don't currently have any of
   * those users in our flow.
   */
  const NavigationAuth = () => (
    <>
      <div className="basic-shadow">
        <Container>
          <Navbar
            collapseOnSelect
            expand="lg"
            className="navbar-light px-3"
          >
            <Navbar.Brand >
              <img
                src="/images/Green.svg"
                width="132"
                height=""
                className={`d-inline-block ${classes.BrandLogo}`}
                alt="LiteraSeed logo"
              />
            </Navbar.Brand>
            <Nav.Item className="mr-auto">
                <LanguageSelector
                  value={i18n.language}
                  onChange={changeLanguage}
                  currentlyValid={langsForFeature}
                />
            </Nav.Item>
            <Navbar.Toggle className="" aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav ml-auto">
              <Nav className="order-1 ml-auto align-items-center">
                <Nav.Item className="pr-4 order-md-2 my-auto">
                  <Link to={ROUTES.HOME} className={`${classes.NavText}`}>
                    <FaHome size={22} style={{ marginRight: "6px" }} /> Home
                  </Link>
                </Nav.Item>
                <div className="dropdown-divider"></div>
                <Nav.Item className="pr-4 my-auto">
                  <Link to={ROUTES.SIGN_OUT} className={`${classes.NavText} `}>
                    <FiLogOut size={22} style={{ marginRight: "6px" }} /> Logout
                  </Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </>
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const globalSearch = useSelector(s => s.provider.globalSearch);
  const [tempGlobalSearch, setTempGlobalSearch] = useState("");

  function changeSearch (val) {
    setTempGlobalSearch(val);
  }

  useEffect(() => {
    if (globalSearch !== tempGlobalSearch) {
      setTempGlobalSearch(globalSearch);
    }
  }, [globalSearch]);

  function submitSearch (e) {
    e?.preventDefault();
    dispatch({type: "update-search", payload: tempGlobalSearch});
    history.push(ROUTES.PROVIDER);
    return false;
  }

  const enrollee = useSelector(s => s.provider.enrollee);
  function enrollNewPatient (){
    if (enrollee.id) {
      // if we have an incomplete RE-enrollment, wipe it away since we asked for
      // a NEW enrollment...
      dispatch(resetEnrollmentUser());
      // otherwise we leave it since we want the user to be able to leave the
      // form and come back if interrupted
    }
    history.push(ROUTES.PROVIDER_ENROLL_PATIENT);
  }

  const debugMode = useSelector(s => s.session.debugMode);
  function toggleDebug () {
    dispatch({type: "debug/TOGGLE"});
  }

  /* The navbar for ANY user with Role.Provider or Role.Admin. */
  const navigationAuthProvider = (
    <>
      <div className="provider-bar">
        <Container>
          <Navbar
            collapseOnSelect
            expand="lg"
            className="navbar-light px-3 keep-layout-med"
            style={{justifyContent: "space-between"}}
          >
            {debugMode ? 
              <div className="text-warning" style={{position: "absolute", left: "0px", filter: "drop-shadow(0px 0px 4px yellow)"}} onClick={toggleDebug}><FaBug title="debug mode is on" /></div>
            : null}
            <Nav>
              <Navbar.Brand>
              <Link to={ROUTES.PROVIDER}>
                <img
                  src="/images/ltsd_light.svg"
                  width="132"
                  height=""
                  color="white"
                  className={`d-inline-block ${classes.BrandLogo}`}
                  alt="LiteraSeed logo"
                />
              </Link>
              </Navbar.Brand>
            </Nav>
            <form className="form-inline" onSubmit={e => submitSearch(e)}>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" onClick={submitSearch}><FaSearch/></span>
                </div>
                <input type="text" className="form-control" placeholder="Search patient" style={{width: "25vw", backgroundColor: tempGlobalSearch ? "lightyellow" : "white"}} value={tempGlobalSearch} onChange={e => changeSearch(e.target.value)}></input>
                {/* <div className="input-group-append">
                  <button type="button" className="btn btn-danger" onClick={() => changeSearch("")}><FaTimes/></button>
                </div> */}
              </div>
              <button type="button" className="btn btn-pink ml-2" onClick={e => enrollNewPatient(e)}>
                <FaUserPlus/>
                <span className="d-none d-lg-inline"> Send New</span>
              </button>
              {/* <button type="button" className="btn btn-pink-outline ml-2" onClick={e => submitSearch(e)}>
                <FaHome/>
                <span className="d-none d-lg-inline"> Home</span>
              </button> */}
            </form>
            <Nav className="order-md-1 align-items-center">
              {/* <Nav.Item className="pr-4 my-auto">
                <Link to={ROUTES.ACCOUNT} className={`${classes.NavText} `} title="My Account">
                  <FiUser size={22} style={{ marginRight: "6px" }} />
                </Link>
              </Nav.Item> */}
              <Nav.Item className="pr-4">
                <Link to={ROUTES.SIGN_OUT} className={`${classes.NavText}`}>
                  <FiLogOut size={22} style={{ marginRight: "6px" }} />
                  <span className="d-none d-lg-inline"> Logout</span>
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar>
        </Container>
      </div>
    </>
  );

  /* The navbar for "keyed" users (who logged in via access key link) */
  const NavigationNonAuth = () => (
    <div className="basic-shadow">
        {CURRENT_ALERT}
      <div className="bg-viridian-green" >
        <Container>
        <Navbar collapseOnSelect expand="lg" sticky="top" className="px-3 navbar-dark">
          <Navbar.Brand href={ROUTES.LANDING}>
            <img
              src="/images/ltsd_light.svg"
              width="132"
              height=""
              className={`d-inline-block ${classes.BrandLogo}`}
              alt="LiteraSeed logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {/* <LanguageSelector /> */}
              <li className={`${classes.NavText} nav-item dropdown`}>
                <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  About
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <Link className="dropdown-item" to={ROUTES.ABOUT}>
                    About Us
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.PRESS}>
                    Press
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.FAQ}>
                    FAQ
                  </Link>
                </div>
              </li>
              <Nav.Link href={ROUTES.PRODUCT} className={`${classes.NavText} text-white`}>
                Product
              </Nav.Link>
              <Nav.Link href={ROUTES.CAREERS} className={`${classes.NavText} text-white`}>
                Careers
              </Nav.Link>
              <Nav.Link href={ROUTES.CONTACT} className={`${classes.NavText} text-white`}>
                Contact
              </Nav.Link>
              <Nav.Link href={ROUTES.COVID_SCREENING} className={`${classes.NavText} text-white font-weight-bold`}>
                COVID Screening
              </Nav.Link>
            </Nav>

            <Nav>
              <Nav.Item
                className={`${classes.Callout} ${classes.Lower}`}>
                <Link
                  to={ROUTES.SIGN_IN}
                  className={`btn cta-primary-outline text-uppercase`}>
                  Provider Login{" "}<FaRegUserCircle style={{fontSize: "1em", verticalAlign: "middle"}}/>
                </Link>
              </Nav.Item>
              <Nav.Item
                className={`${classes.Callout} ${classes.Upper}`}>
                  {/* what is the NavCTA for? */}
                <Link to={ROUTES.CONTACT} className={`${classes.NavCTA} btn-lg btn cta-primary text-uppercase`}>
                  Request A Demo{" "}<FaArrowRight style={{fontSize: "0.8em", verticalAlign: "bottom"}}/>
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        </Container>
      </div>
    </div>
  );

  return (
    <div>
      {!(
        isAuthenticated &&
        (role === ROLES.PATIENT || role === ROLES.ADMIN ||
          (role === ROLES.PROVIDER))
      ) ? (
        <NavigationNonAuth />
      ) : ((role === ROLES.PROVIDER) || role === ROLES.ADMIN) ? (
        navigationAuthProvider
      ) : (isEmailLink || isAnonymous) ? (
        null
      ) : (
        <NavigationAuth />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isEmailLink: state.auth.isEmailLink,
    isAnonymous: state.auth.user.isAnonymous,
    role: state.auth.user ? state.auth.user.role : "",
  };
}

export default connect(mapStateToProps)(Navigation);
