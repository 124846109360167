import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Dots from "react-carousel-dots";
import "./IntroCard.css";
import { DynamicImage } from "../DynamicImage"
import PlayAudio from "../../Audio/PlayAudio";
import { ImageWithFallback } from "../ImageWithFallback";

// The "default" content is the *public* onboarding content.
const DefaultContent = [
  {
    title: "Get Better Care",
    description:
      "Completing these questions can help you and your doctor talk about your health concerns, including COVID-19. It may take you about 5 minutes.",
    image: {
      female: "/images/generic_fm_onboarding_1.png",
      male: "/images/generic_onboarding_1.png",
    },
    audioFileName: "",
    button: "Next",
  },
  {
    title: "Select Your Answers",
    description:
      'Click on the pictures that best match how you feel. If none of the pictures is a good match, click "none of these”. Include anything you want your doctor to know about.',
    image:
      "https://firebasestorage.googleapis.com/v0/b/literaseed-5b6b8.appspot.com/o/images%2Fobgyn%2Fobgyn_onboarding_2.svg?alt=media&token=06b76ecf-d844-42fa-96a2-ab250d30dc1a",
    // male: "/images/generic_onboarding_2.svg"
    audioFileName: "",
    button: "Next",
  },
  {
    title: "Better Patient Experience",
    description:
      "When you finish, you can print your report to share your answers with your doctor. You and your doctor will be more prepared for your visit.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/literaseed-5b6b8.appspot.com/o/images%2Fobgyn%2Fobgyn_onboarding_3.svg?alt=media&token=fa851b9e-8528-4896-95ce-7939b63815c1",
    // male: "/images/generic_onboarding_3.svg"
    audioFileName: "",
    button: "Start",
  },
];

const IntroCard = ({ content, customClass }) => {
  const [showModal, setShowModal] = useState(true);
  const [cardIndex, setCardIndex] = useState(0);

  if (!content) {
    content = DefaultContent;
  }

  const handleClose = () => {
    setShowModal(false);
  };

  const nextCard = (index) => {
    index++;
    if (index < content.length) {
      setCardIndex(index);
    } else {
      handleClose();
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} className={"customized-modal "+(customClass || "")}>
        <Modal.Body>
          <Card border="light" className="customized-modal-card">
            <ImageWithFallback
              className="card-img-top"
              src={content[cardIndex].image}
              alt=""
            />

            <Card.Body className="customized-modal-card-body">
              <div className="headerWithSound">
                <Card.Title className="modal-card-title">
                  {content[cardIndex].title}
                </Card.Title>
                <div className="headerSound">
                  <PlayAudio filename={content[cardIndex].audioFileName} style={{textAlign: "left"}} />
                </div>
              </div>
              <hr className="modal-card-hr"/>
              <Card.Text className="modal-card-text">
                {content[cardIndex].description}
              </Card.Text>
              <div className="body-text">

            </div>
            </Card.Body>
            <Card.Footer>
            <div className="customized-modal-card-dot">
              <Dots
                length={content.length}
                active={cardIndex}
              />
              <Button
                onClick={() => nextCard(cardIndex)}
                className="onboarding-btn"
              >
                {content[cardIndex].button}
              </Button>
            </div>
            </Card.Footer>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IntroCard;
