import React, { useEffect, useState } from "react";
import { Container } from "../UI/Grid/Grid";
import historyScss from "../../styles/History.module.scss";
import "../../styles/education.css"; 
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { educationActions, getCurrentModule, moduleContentRequested } from "../../store/slices/education";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Button from "../UI/Button/Button";
import { useTextDirection } from "../../i18n";
import { useHistory } from "react-router-dom";


export const SimpleModule = () => {
  const [markdown, setMarkdown] = useState("");
  const module = useSelector(getCurrentModule);
  const maxPage = module ? module.pageCount - 1 : 0;
  const currentPage = useSelector(s => s.education.currentPage);
  const currentContent = useSelector(s => getCurrentModule(s)?.content?.[currentPage]);
  const dispatch = useDispatch();
  const history = useHistory();
  const dir = useTextDirection();

  function fetchCurrentAndAdjacent () {
    if (!module) return;
    const pagesToLoad = [currentPage - 1, currentPage, currentPage + 1].filter(
      i => i >= 0 && i <= maxPage && !(Array.isArray(module.content) && (i in module.content)));
    pagesToLoad.forEach(i => dispatch(moduleContentRequested({module, page: i})));
  }

  useEffect(() => {
    fetchCurrentAndAdjacent();
  }, [module, currentPage]);

  useEffect(() => {
    setMarkdown(currentContent || "");
  }, [currentContent]);

  function prevPage () {
    if (currentPage <= 0) {
      history.goBack();
    } else {
      dispatch(educationActions.previousPage());
    }
  }
  function nextPage () {
    if (currentPage >= maxPage) {
      alert("You've reached the end of the module. You will now be redirected to your previous page.");
      history.goBack();
    } else {
      dispatch(educationActions.nextPage());
    }
  }


  return <Container style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "75%"}}>
    {module ?
      <div className={historyScss.InnerContainer}>
        <h2 className={`${historyScss.QuestionText} pt-3 pb-2 pb-md-4 pt-md-5`}>
          {module.title}
        </h2>
        <ReactMarkdown
          children={markdown}
          rehypePlugins={[rehypeRaw]}
          components={{}}
          className="education module-page text-left">
        </ReactMarkdown>
      </div>
      : <h2>No module loaded!</h2>}
    <div className={historyScss.Footer}>
      <div className={`text-center`}><small>&copy; All rights reserved</small></div>
      <div>
        {/* we might want to re-examine these as Bootstrap buttons... we're manually overriding the hover effect here for example */}
        <Button className="back-btn pl-3 pr-3 mr-3 ml-3" style={{color: "#17207a"}} onClick={prevPage}>
          {dir === 'ltr' ? <FaArrowLeft /> : <FaArrowRight />} <span className="pr-2 font-weight-bold">Back</span>
        </Button>
        <Button className="forward-btn pl-3 pr-3 mr-3 ml-3 text-white" onClick={nextPage} >
          <span className="pr-2 font-weight-bold">Next</span> {dir === 'ltr' ? <FaArrowRight /> : <FaArrowLeft />}
        </Button>
      </div>
    </div>
  </Container>
};
