import React from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { NEWS_CATEGORIES, NEWS_ENTRIES } from "../Press/list";
import { Col, Container, Row } from "../UI/Grid/Grid";


const Product = () => (
  <>
    <Row className="title-banner bg-light-red-orange m-0">
      <Container>
        <h1 className="heading1 mb-0 text-left text-magenta-pink">How It Works</h1>
      </Container>
    </Row>

    <Container>
      <Row className="justify-content-center mb-5">
        <Col className="col-11 col-lg-6 align-self-center text-left py-4">
          <div className="text-wrapper">
            <h2 className="h1 text-magenta-pink">Visual Symptom Intake</h2>
            <p>
              LiteraSeed uses pictures and visual tools to make it easy for patients
              to explain what's wrong. A final report helps medical providers be
              more prepared to serve patients with better care.
            </p>
          </div>
        </Col>
        <Col className="col-12 col-lg-6">
          <img src="images/desk_background.jpeg" style={{ width: "100%" }} />
          <img
            className="img-fluid cartoon-img"
            src="images/obgyn_preview.gif"
            alt="LiteraSeed demo"
            style={{
              position: "absolute",
              top: "20px",
              left: "75px",
              height: "90%",
            }}
          />
        </Col>
      </Row>
    </Container>

    <Container className="text-center">
      <Row className="justify-content-center">
        <Col className="col-12 col-md-6 mb-5">
          <h2 className="h1 text-viridian-green mt-5">Easy as...</h2>
        </Col>
        <Row className="mb-5 justify-content-center">
          <Col className="col-10 col-md-6 col-lg-3 mb-4">
            <img
              className="card-img-top"
              src="/images/generic_onboarding_1.png"
              alt="Scan QR Code"
            />
            <div className="card-body">
              <p className="card-text"><span className="text-viridian-green" style={{fontWeight: "bold"}}>1.</span> Sign onto the app.</p>
            </div>
          </Col>
          <Col className="col-10 col-md-6 col-lg-3 mx-5 mb-4">
            <img
              className="card-img-top"
              src="/images/generic_onboarding_2.png"
              alt="Answer Screening Questions"
            />
            <div className="card-body">
              <p className="card-text">
                <span className="text-viridian-green" style={{fontWeight: "bold"}}>2.</span> Tap on the pictures that match your symptoms.
              </p>
            </div>
          </Col>
          <Col className="col-10 col-md-6 col-lg-3 mb-4">
            <img
              className="card-img-top"
              src="/images/generic_onboarding_3.png"
              alt="Share Report with Provider"
            />
            <div className="card-body">
              <p className="card-text">
                <span className="text-viridian-green" style={{fontWeight: "bold"}}>3.</span> Send the report to your medical provider before the visit.
              </p>
            </div>
          </Col>
        </Row>
      </Row>
    </Container>
    

    <Row className="justify-content-center text-center m-0">
      <Col className="col-12 col-md-6">
        <h2 className="h1 text-viridian-green mt-5">Products</h2>
      </Col>
    </Row>

    <Container>
        <Col className="col-10 mx-auto">
          <div id="product_slides" className="carousel slide mt-5" data-ride="carousel">
            <ol className="carousel-indicators">
              <li data-target="#product_slides" data-slide-to="0" className="active"></li>
              <li data-target="#product_slides" data-slide-to="1"></li>
              <li data-target="#product_slides" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="d-block w-75" src="images/product/OBGYN_slide_larger.png" alt="" style={{marginBottom: "160px", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
                <div className="carousel-caption text-dark-grey d-none d-md-block">
                  <p>Our OBGYN app is the first iteration of our planned pregnancy and maternal health tracking application that helps set expectations for the doctor and patient, enabling the visit to be more productive as a result.</p>
                </div>
              </div>
              <div className="carousel-item">
                <img className="d-block w-75" src="images/product/behavioral_slide_larger.png" alt="" style={{marginBottom: "160px", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
                <div className="carousel-caption text-dark-grey d-none d-md-block">
                  <p>Our mental health app measures characteristic attitudes and symptoms of depression, to enable accurate and complete responses to sensitive questions that can be difficult to share in an in-person encounter with a healthcare professional.</p>
                </div>
              </div>
              <div className="carousel-item">
                <img className="d-block w-75" src="images/product/COVID_slide_larger.png" alt="" style={{marginBottom: "160px", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
                <div className="carousel-caption text-dark-grey d-none d-md-block">
                  <p>Our COVID screening app can be used by people who have new or worsening symptoms that might be COVID. Users can answer questions using pictures as a guide to input their symptoms and risk factors.</p>
                </div>
              </div>
            </div>
            <a className="carousel-control-prev" href="#product_slides" role="button" data-slide="prev">
              <FaChevronCircleLeft color="#000000" size="36px"/>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#product_slides" role="button" data-slide="next">
              <FaChevronCircleRight color="#000000" size="36px"/>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </Col>
    </Container>
    
    <div className="spacer2"/>

    <Container>
      <Row className="justify-content-center">
          <Col className="col-12 col-md-6 mb-5">
            <h2 className="h1 text-viridian-green mt-5 text-center">Clinician Dashboard</h2>
          </Col>
          <img  
                style={{width: "75%"}}
                src="/images/clinician_dashboard.png"
                alt="Annotated clinician dashboard features"
              />
      </Row>
    </Container>


    <div className="spacer2"/>
    
    <Row className="justify-content-center text-center">
      <Col className="col-12 col-md-6">
        <h2 className="h1 text-magenta-pink">Product Updates</h2>
        <ul className="list-">
        {NEWS_ENTRIES.filter(e => e.category === NEWS_CATEGORIES.CHANGELOGS).map(newsData => 
          <li className="" key={newsData.url}>
          <a
            href={newsData.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>
              {newsData.title}
            </h3>
            <p className="text-muted small mb-0">
              {newsData.byline}
            </p>
          </a>
        </li>
        )}
        {/* <li className="mt-4">
          <em>For additional updates, check out the archive on our <strong><Link to="/status">Status Page</Link></strong>.</em>
        </li> */}
        </ul>
      </Col>
    </Row>
    
    <div className="spacer2"></div>

    <Footer />
  </>
);
export default Product;
