import * as actionTypes from '../actions/actionTypes';
import { ApplicationFeature } from '../actions';
import { spliceOutOf } from '../../utils';
const initalState = {
  activeFeature: ApplicationFeature.None,
  debugMode: false,
  alerts: [],
  snacks: [],
  recentRecords: []
};

const MAX_RECENTS_SIZE = 10;

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_SESSION_FEATURE:
      return {
        ...state,
        activeFeature: action.payload.feature
      };
    case actionTypes.CLOSE_SESSION_FEATURE:
      return {
        ...state,
        activeFeature: ApplicationFeature.None
      };
    case "debug/TOGGLE":
      return {
        ...state,
        debugMode: !state.debugMode
      }
    case "alerts/NEW":
      return {
        ...state,
        alerts: state.alerts.concat(action.payload)
      }
    case "snack/NEW":
      return {
        ...state,
        snacks: state.snacks.concat(action.payload)
      }
    case "alerts/DISMISS":
      return {
        ...state,
        alerts: []
      }
    case "snack/DISMISS":
      return {
        ...state,
        snacks: spliceOutOf(s => s.id === action.payload, state.snacks)
      }
    case "confirm/ASK":
      return {
        ...state,
        confirmation: action.payload
      }
    case "confirm/DISMISS":
      return {
        ...state,
        confirmation: null
      }
    case "recent-records/REMEMBER":
      const existingIndex = state.recentRecords.findIndex(r => r === action.payload);
      if (existingIndex > -1) {
        return {
          ...state,
          recentRecords: [action.payload].concat(
            state.recentRecords.slice(0, existingIndex),
            state.recentRecords.slice(existingIndex+1, state.recentRecords.length))
        }
      } else {
        return {
          ...state,
          recentRecords: [action.payload].concat(state.recentRecords.slice(0, MAX_RECENTS_SIZE))
        }
      }
    default:
      return state;
  }
}

export default reducer;