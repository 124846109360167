import { createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../database';
import { OPEN_SESSION_FEATURE, CLOSE_SESSION_FEATURE } from './actionTypes';

export const ApplicationFeature = {
  None: 0,
  Questionnaire: 10,
  Reporting: 20
}

export const openSessionFeature = (feature) => ({
  type: OPEN_SESSION_FEATURE,
  payload: {feature}
});

export const closeSessionFeature = (feature) => ({
  type: CLOSE_SESSION_FEATURE,
  payload: {feature}
});

export const recordPageData = createAsyncThunk("LOG/page-data", async (_, thunkAPI) => {
  const s = thunkAPI.getState();
  const userId = s.auth.user?.uid;
  const sessionId = s.auth?.sessionDataId;
  if (!userId || !sessionId) return;
  const questionnaireNav = {
    pageNumber: s.questionnaire.navigation.number,
    sectionKey: s.questionnaire.navigation.details?.sectionKey || "",
    pageKey: s.questionnaire.navigation.details?.key || "",
    answerCount: Object.values(s.questionnaire.answers).filter(a => a && !a.isMeta).length,
    timeStarted: s.questionnaire.timeStarted
  }
  return db.collection("users").doc(userId).collection("sessions").doc(sessionId).update({questionnaireNav})
})