import { chunkedBy } from ".";

export const Fulfilled = (result) => new Promise(resolve => resolve(result));
export const Rejected = (result) => new Promise((resolve, reject) => reject(result));
export const Timeout = (milliseconds) => {
  let cancel;
  const promise = new Promise((resolve, reject) => {
    const timerId = setTimeout(resolve, milliseconds)
    cancel = () => {
      clearTimeout(timerId);
      reject();
    };
  });
  return {promise, cancel};
};

export function chunkedPromise (arrArg, promiseBuilder, chunkSize) {
  const chunkedInput = chunkedBy(arrArg, chunkSize);
  return Promise.all(chunkedInput.map(promiseBuilder)).then(chunkedOutput => chunkedOutput.flatMap(i => i))
}
export function getRelationsChunked (idList, collRef, field) {
  if (!Array.isArray(idList) || idList.length === 0) return [];
  const chunkedIds = (idList.length < 10) ? [idList] : chunkedBy(idList, 10);
  return Promise.all(chunkedIds.map(innerList => collRef.where(field, "in", innerList).get())).then(chunkedSnaps => {
    return chunkedSnaps.flatMap(snap => {
      return snap.docs;
    }).map(i => ({...i.data(), id: i.id}));
  });
}
export function getComplexRelations (pathList, collRef, field) {
  const chunkedPaths = pathList.flatMap(path => {
    if (path[2].length < 10) return path;
    const chunkedFinal = chunkedBy(path[2], 10);
    return chunkedFinal.map(finalChunk => ([path[0], path[1], finalChunk]));
});
  return Promise.all(chunkedPaths.map(path => collRef.doc(path[0]).collection(path[1]).where(field, "in", path[2]).get())).then(chunkedSnaps => {
    return chunkedSnaps.flatMap(snap => {
      return snap.docs;
    }).map(i => ({...i.data(), id: i.id}));
  });
}
