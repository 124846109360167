import React from "react";
import { LanguageList, LanguageEndonym, Language, dualName } from "../../constants/locales";

function LanguageSelector({
  value,
  onChange,
  currentlyValid,
  showEnglish,
  width,
  whitelist
}) {
  if (!Array.isArray(currentlyValid)) {
    currentlyValid = LanguageList;
  }

  // default width
  width = width || "100px";

  const FIXED_SORTS = {
    [Language.English]: -1000,
    [Language.UNDETERMINED]: -10000
  }

  // set a canonical order for the language list
  // right now: set English first, then order the rest by ISO codes
  const languageOrder = LanguageList.slice()
    .filter(l => !Array.isArray(whitelist) || whitelist.includes(l))
    .sort((a, b) => FIXED_SORTS[a] ?? (-1 * FIXED_SORTS[b]) ?? a.localeCompare(b));

    // borderColor: "transparent"
  return (
      <select
        className="form-select-md"
        style={{width, padding: "4px", backgroundColor: "white", borderRadius: "36px"}}
        value={value}
        onChange={onChange}
      >
        {languageOrder.map(lang =>
          <option
            value={lang}
            key={lang}
            disabled={!currentlyValid.includes(lang)}
            title={currentlyValid.includes(lang) ? LanguageEndonym[lang] : "Not available for the current questionnaire"}
          >
            {lang === Language.UNDETERMINED ? ("* use language selector *") : (showEnglish ? dualName(lang) : LanguageEndonym[lang])}
          </option>
        )}
      </select>
  );
}

export default LanguageSelector;