import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {firebase} from "../../database";
import { useDispatch } from "react-redux";
import { alertEvent } from "../UI/GlobalAlerts";

const INITIAL_STATE = {
    password: '',
    error: null,
    success: false
};

const MIN_LENGTH_PASSWORD = 10;
function passwordErrors (password) {
    if (password.length < MIN_LENGTH_PASSWORD) {
        return `Password must be at least ${MIN_LENGTH_PASSWORD} characters`;
    }
    return false;
}

export const PasswordChangeForm = () => {
    const [password, setPassword] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();

    function submit (event) {
        event.preventDefault();
        if (event.target.passwordConfirm.value !== password) {
            dispatch(alertEvent("The passwords do not match!"));
            return false;
        }
        const error = passwordErrors(password);
        if (error !== false) {
            dispatch(alertEvent(error));
            return false;
        }
        let user = firebase.auth().currentUser;
        user.updatePassword(password).then(updated => {
            setSuccess(true);
            setError(false);
            setPassword("");
            event.target.passwordConfirm.value = "";
        }).catch(err => {
            console.error(err);
            if (err.code === 'auth/requires-recent-login') {
                setError({message: "This operation requires you to have logged in recently. Please log out and then try again."});
            } else {
                setError(err || {message: "Unknown server error attempting reset. Please contact LiteraSeed if this issue persists."});
            }
        });
    };

    return (
        <Form onSubmit={submit}>
            <Form.Group>
                <Row>
                    <Col>
                        <Form.Control
                            name="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            placeholder="New Password"
                            />
                    </Col>
                    <Col>
                        <Form.Control
                            name="passwordConfirm"
                            type="password"
                            placeholder="Confirm Password"
                        />
                    </Col>

                    <Col>
                        <Button variant="dark" disabled={!password} type="submit">
                            Reset My Password
                        </Button>
                    </Col>
                </Row>
                {success || error ? <Row>
                    {error ? <p className="text-danger">{error.message}</p> : null}
                    {success ? <p className="text-success">Successfully changed your password!</p> : null}
                </Row> : null}
            </Form.Group>
        </Form>
    );
}

export default PasswordChangeForm;
