import React from "react";
import "./Card.module.scss";
// import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { RiEmotionHappyLine, RiEmotionUnhappyLine } from "react-icons/ri";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

export const Card = (props) => (
  <div {...props} className={`card ${props.className ? props.className : ""}`}>
    {props.children}
  </div>
);

export const CardHeader = (props) => (
  <div
    {...props}
    className={`card-header ${props.className ? props.className : ""}`}
  >
    {props.children}
  </div>
);

export const CardBody = (props) => (
  <div
    {...props}
    onClick={props.onClick}
    className={`card-body ${props.className ? props.className : ""}`}
  >
    {props.children}
  </div>
);

export const CardTitle = (props) => (
  <div
    {...props}
    className={`card-title ${props.className ? props.className : ""}`}
  >
    {props.children}
  </div>
);

export const CardText = (props) => (
  <div
    onClick={props.onClick}
    className={`card-text ${props.className ? props.className : ""}`}
  >
    {props.children}
  </div>
);

export const CardFooter = (props) => (
  <div className={`card-footer ${props.className ? props.className : ""}`}>
    {props.children}
  </div>
);

export const CardImg = (props) => (
  <>
    {props.value === "/images/none.jpg" ? (
      <NotInterestedIcon
        className="svg"
        style={{
          margin: "0 auto",
          height: "120px",
          fontSize: "5rem",
          color: "#dc3545",
        }}
        onClick={props.onClick}
      />
    ) : (
      <img
        src={props.src}
        alt={props.report_text}
        onClick={props.onClick}
        className={`card-img-top ${props.className ? props.className : ""}`}
      >
        {props.children}
      </img>
    )}
  </>
);

export const CardSlider = (props) => (
  <>
    <Typography
      id="track-false-slider"
      style={{ textAlign: "center" }}
      gutterBottom
    >
      How Bad?
    </Typography>
    <Slider
      track={false}
      aria-labelledby="track-false-slider"
      getAriaValueText={props.valuetext}
      defaultValue={0}
      onChange={props.onChange}
      step={1}
      min={0}
      max={10}
      marks={[
        { value: 0, label: "0" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
      ]}
    />
    <div style={{ height: "2rem" }}>
      <span style={{ float: "left" }}>
        <RiEmotionHappyLine
          style={{ height: "2rem", width: "2rem", color: "#339499" }}
        />
      </span>
      <span style={{ float: "right" }}>
        <RiEmotionUnhappyLine
          style={{ height: "2rem", width: "2rem", color: "#339499" }}
        />
      </span>
    </div>
  </>
);
