import React, {useState} from "react";
import { Container, Row, Col } from "../UI/Grid/Grid";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Footer from "../Footer/Footer";
import { sendEmail } from '../../store/actions';
import { connect, useDispatch } from 'react-redux';
import { snack } from "../UI/GlobalAlerts";

// import { FiPhoneCall } from "react-icons/fi";
// import { GoMail } from "react-icons/go";
// import { FaFacebook, FaLinkedinIn } from "react-icons/fa";

// ! Expected to return a value in arrow function (below)
const Contact =({
  onSendEmail,
  isSent = false
}) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [params, setParams] = useState({
    'firstName': '',
    'lastName': '',
    'email':'',
    'role': '',
    'purpose': '',
    'message': ''
  });

  const [isProcessing, setIsProcessing] = useState(false);

   const names = {
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'email':'Email',
    'role': 'Role',
    'purpose': 'Purpose',
    'message': 'Message'
  };
  const handleChange = (e) => {
    e.preventDefault();
    if (typeof e.target.value !== 'undefined' && typeof e.target.name !== 'undefined') {
      setParams({
        ...params,
        [e.target.name]: e.target.value});
    }
  }
  const sendMessage = (e) => {
    e.preventDefault();
    let info = [];
    Object.keys(params).map((key, value) => {
      info.push({
        field: names[key],
        details: params[key],
      });
    });

    if (params.firstName && params.email && params.message) {
      // TODO: send the email and display success message to user
      let emailContent = {
        // TODO: switch to emailing both Aziza and John, not hello!
        bcc: 'taylor@literaseed.io, gap.space@gmail.com',
        template: {
          name: 'ContactInfoReport',
          data: {
            info
          }
        }
      }

      setIsProcessing(true);
      setParams({
        'firstName': '',
        'lastName': '',
        'email':'',
        'role': '',
        'purpose': '',
        'message': ''
      });
      dispatch(sendEmail(emailContent)).then(r => {
        setIsProcessing(false);
        dispatch(snack("Email sent!"));
      });
    } else {
      // TODO: all the other error handling here
      setErrorMessage("Please fill in the fields marked with *.");
    }
    return false;
  };


  return (
    <>
    <Row className="title-banner bg-light-red-orange m-0">
      <Container>
        <h1 className="heading1 mb-0 text-left text-magenta-pink">Contact Us</h1>
      </Container>
    </Row>
    
    <Container className="text-center">
    { true ?
      <>
      <Row className="justify-content-center my-5">
        <Col className="col-12 col-md-8">
          <h2 className="h1 text-center text-viridian-green">Let's Start a Conversation</h2>
          <p>Do you have a question or want to tell us something?</p>

          <p>
            Please fill out the contact form and we will get back to you
            within three business days.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center my-5 text-left">
        <Col className="col-12 col-md-8">
          <Form onSubmit={sendMessage} disabled={isProcessing}>
            <Row className="mb-4">
              <Col className="col-12 col-md-6 ">
                <Form.Group>
                  <Form.Label>First Name*:</Form.Label>
                  <Form.Control
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    value={params['firstName']}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-6 ">
                {/* TODO: start requiring last name */}
                <Form.Group>
                  <Form.Label>Last Name:</Form.Label>
                  <Form.Control
                    name="lastName"
                    type="text"
                    placeholder="Last name"
                    value={params['lastName']}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Group>
                  <Form.Label>Email*:</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="name@example.com"
                    value={params['email']}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-4">
              {/* I am asking as a.. and purpose fields should be dropdowns (see figma?) */}
              <Col className="col-12 col-md-6 ">
                <Form.Group>
                  <Form.Label>What is your role?</Form.Label>
                  <Form.Control
                    name="role"
                    type="text"
                    placeholder="I am asking as a..."
                    value={params['role']}
                    onChange={(e) => handleChange(e)}

                  />
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-6 ">
                <Form.Group>
                  <Form.Label>
                    What is the purpose of this message?
                  </Form.Label>
                  <Form.Control
                    name="purpose"
                    type="text"
                    placeholder="I want to..."
                    value={params['purpose']}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
              </Col>
              {/* organization? */}
              {/* TODO: opt-in for mailing list */}
            </Row>
            <Row className="my-4">
              <Col>
                <Form.Group>
                  <Form.Label>Message*:</Form.Label>
                  <Form.Control
                    name="message"
                    as="textarea"
                    placeholder="Write your message here"
                    rows="3"
                    value={params['message']}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {errorMessage}
            <Button
              type="submit"
              className="btn cta-primary btn-lg btn-block mb-5">
              <h4>Send</h4>
            </Button>
          </Form>
        </Col>
      </Row>
      </> :
     <>
      <Row className="justify-content-center my-5">
        <Col className="col-12 col-md-8">
          <h2 className="h1 text-center text-viridian-green">Thank you!</h2>
          <p>We have received your message of interest and will get back to you as soon as possible!</p>
        </Col>
      </Row>
     </>
     }

    </Container>
    <Footer />
    </>

  );
}


const mapDispatchToProps = dispatch => {
  return {};
};

function mapStateToProps(state, ownProps) {
  return {
    isLoading: state.email.isLoading,
    isSent: state.email.isSent,
    isError: state.email.isError
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Contact);