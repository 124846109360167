import React from "react";
import { Container, Row, Col } from "../UI/Grid/Grid";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
// import Button from "../UI/Button/Button";
// import * as ROUTES from "../../constants/routes";
import YoutubeEmbed from "./YoutubeEmbed";
import * as ROUTES from "../../constants/routes";
import { NEWS_ENTRIES, NEWS_CATEGORIES } from "./list";
import VimeoEmbed from "./VimeoEmbed";

// const pressReleases = Object.entries(PRESS_RELEASES).map(([key, release]) =>
//   <div className="otherNews__item" key={key}>
//     <Link to={`${ROUTES.PRESS}/${key}`}>
//       <h3>{release.title}</h3>
//       <p className="text-muted small mb-0">
//         Press Release by LiteraSeed from {release.dateText}
//       </p>
//     </Link>
//   </div>
//   );

function buildNewsEntry (newsData) {
  return <div className="otherNews__item" key={newsData.url}>
    <a
      href={newsData.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <h3>
        {newsData.title}
      </h3>
      <p className="text-muted small mb-0">
        {newsData.byline}
      </p>
    </a>
  </div>;
}

function buildNewsCard (newsData) {
  return <div className="card card-horizontal text-left mb-4 align-items-center">
    <img
      src={newsData.cardImage}
      className=" col-md-4"
      alt={newsData.cardAltText}
    ></img>
    <div className="card-body col-md-8">
      <h4 className="card-title mb-0 heading3 text-viridian-green">
        {newsData.title}
      </h4>
      <p className="text-muted small">{newsData.byline}</p>
      <a
        href={newsData.url}
        target="_blank"
        rel="noopener noreferrer"
        className="link-underline"
      >
        Read More
      </a>
    </div>
  </div>
}

const Press = () => (
  <>
      <Row className="title-banner bg-light-red-orange m-0">
        <Container>
          <h1 className="heading1 mb-0 text-left text-magenta-pink">Meet LiteraSeed</h1>
        </Container>
      </Row>

      <Container className="text-center public-page">
      {/* Video Gallery */}
      <Row className="justify-content-center mt-5 text-center" style={{width: "80%", margin: "auto"}}>
        <Col className="col-12">
          <h2 className="text-viridian-green mb-4">Video Gallery</h2>
          <div
            id="pressVideoGallery"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#pressVideoGallery"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#pressVideoGallery" data-slide-to="1"></li>
              <li data-target="#pressVideoGallery" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <YoutubeEmbed
                  embedId="VvAuByZC2_Y"
                  className="d-block w-100"
                  alt="LiteraSeed"
                />
              </div>
              <div className="carousel-item">
                <VimeoEmbed
                  embedId="592984978?h=a6f2ba5791"
                  className="d-block w-100"
                  alt="Phoenix Business Journal Healthcare Heroes"
                />
              </div>
              <div className="carousel-item">
                <YoutubeEmbed
                  embedId="qaMs1_Ww4e8"
                  className="d-block w-100"
                  alt="LiteraSeed on Channel 3"
                />
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#pressVideoGallery"
              role="button"
              data-slide="prev"
            >
              <i className="fas fa-chevron-left" aria-hidden="true"></i>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#pressVideoGallery"
              role="button"
              data-slide="next"
            >
              <i className="fas fa-chevron-right" aria-hidden="true"></i>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </Col>
      </Row>
      {/* Featured News */}
      <Row className="justify-content-center mt-5 text-center"></Row>
      <Row className="spacer2 justify-content-center">
        <Col className="col-12 col-lg-10">
          <h2 className="h1 text-viridian-green mb-5">In the News</h2>
          <h3 className="text-viridian-green mb-4">Featured News</h3>
          <Col className="mb-4">
            {NEWS_ENTRIES.filter(e => e.isFeatured).map(buildNewsCard)}
          </Col>
        </Col>
      </Row>
      {/* Other News */}
      <Row className="spacer2 justify-content-center">
        <Col className="col-12 col-lg-10">
          <ul className="nav nav-pills nav-fill mb-4" id="otherNews" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                id="otherNews__companyNews__tab"
                href="#otherNews__companyNews"
                data-toggle="tab"
                role="tab"
                aria-controls="company news"
                aria-selected="true"
              >
                Company News
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="otherNews__blog__tab"
                href="#otherNews__blog"
                data-toggle="tab"
                role="tab"
                aria-controls="company news"
                aria-selected="false"
              >
                Our Blog
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${NEWS_ENTRIES.find(e => e.category === NEWS_CATEGORIES.PUBLICATIONS) ? "" : "disabled"}`}
                id="otherNews__publications__tab"
                href="#otherNews__publications"
                data-toggle="tab"
                role="tab"
                aria-controls="company news"
                aria-selected="false"
              >
                Publications &#38; Presentations
              </a>
            </li>
          </ul>
          <div className="tab-content text-left" id="otherNews__content">
            <div
              id="otherNews__companyNews"
              className="tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="otherNews__companyNews__tab"
            >
              {NEWS_ENTRIES.filter(e => e.category === NEWS_CATEGORIES.COMPANY_NEWS).map(buildNewsEntry)}
            </div>
            <div
              id="otherNews__blog"
              className="tab-pane fade show"
              role="tabpanel"
              aria-labelledby="otherNews__blog__tab"
            >
              {NEWS_ENTRIES.filter(e => e.category === NEWS_CATEGORIES.BLOG).map(buildNewsEntry)}
            </div>
            <div
              id="otherNews__publications"
              className="tab-pane fade show"
              role="tabpanel"
              aria-labelledby="otherNews__publications__tab">
              {NEWS_ENTRIES.filter(e => e.category === NEWS_CATEGORIES.PUBLICATIONS).map(buildNewsEntry)}
            </div>
          </div>
        </Col>
      </Row>
    </Container>

    <Footer />
  </>
);

export default Press;
