import {db} from "../../database";
import * as actionTypes from './actionTypes';
const waitlistRef = db.collection('waitlist');

/*Create Waitlist action functions*/
const waitlistStart = () => {
	return {
		type: actionTypes.WAITLIST_START
	};
};

const waitlistSuccess = (id, info) => {
	return {
		type: actionTypes.WAITLIST_SUCCESS,
		data: info,
		id: id
	};
};

const waitlistFail = (error) => {
	return {
		type: actionTypes.WAITLIST_FAIL,
		error
	};
};

export const waitlist = (userData) => dispatch => {
 dispatch(waitlistStart());

 waitlistRef.add({
 	 userData
 }).then((doc) => {
 	if (!doc || !doc.id) {
 		dispatch(waitlistFail(new Error('Fail to create a waitlist!')));
 	} else {
 		dispatch(waitlistSuccess(doc.id, userData));
 	}
 })
 .catch(error=> {
 	dispatch(waitlistFail(error));
 });
}