import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import { DataGrid, GridToolbar, GridToolbarExport } from "@mui/x-data-grid";
import moment from "moment";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { ListGroup, Modal } from 'react-bootstrap';
import { FaArrowLeft, FaChartArea, FaCheckSquare, FaEnvelope, FaExclamationTriangle, FaEyeSlash, FaFileContract, FaFileExport, FaFileMedical, FaPencilAlt, FaPrint, FaRegEye, FaRegTimesCircle, FaSms, FaWhatsapp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { dualName, Language } from "../../constants/locales";
import {
  PROVIDER,
  PROVIDER_ENROLL_PATIENT,
  REPORTS
} from "../../constants/routes";
import { ScreenerShortNames } from "../../constants/screenings";
import { ScreeningStatusHR } from '../../database/model/user';
import { buildInviteUrl, getSpecificUser, getUserAlerts, getUserInvites, getUserMessages, getUserReports, getUserScheduledInvites, getUserScores, updateEnrollmentUser, updateScheduledInvite, updateUserAlert, updateUserDetails } from "../../store/actions";
import { newEnrollment } from "../../store/reducers/provider";
import { loadOrLocalQuestionnaire } from "../../store/slices/questionnaire";
import { getAllScreenings } from "../../store/slices/screenings";
import { _switch } from "../../utils";
import { Card, CardBody, CardHeader, CardText, CardTitle } from "../UI/Card/Card";
import { alertEvent, snack } from '../UI/GlobalAlerts';
import { Col, Container, Row } from "../UI/Grid/Grid";
import Input from "../UI/Input/Input";
import { ALWAYS_SHOW_TIME_FMT, DAY_ONLY_RELATIVE_FMT } from "./ClinicianHome";
import * as d3 from "d3";

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/line-chart
function DualLineChart(data, dataB, {
  x = ([x]) => x, // given d in data, returns the (temporal) x-value
  y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
  r = 3,
  defined, // for gaps in data
  definedB, // for gaps in data
  curve = d3.curveLinear, // method of interpolation between points
  marginTop = 20, // top margin, in pixels
  marginRight = 30, // right margin, in pixels
  marginBottom = 30, // bottom margin, in pixels
  marginLeft = 40, // left margin, in pixels
  width = 640, // outer width, in pixels
  height = 400, // outer height, in pixels
  xType = d3.scaleUtc, // the x-scale type
  xDomain, // [xmin, xmax]
  xDomainB, // [xmin, xmax]
  xRange = [marginLeft, width - marginRight], // [left, right]
  yType = d3.scaleLinear, // the y-scale type
  yDomain, // [ymin, ymax]
  yRange = [height - marginBottom, marginTop], // [bottom, top]
  yFormat, // a format specifier string for the y-axis
  yLabel, // a label for the y-axis
  yTypeB = d3.scaleLinear, // the y-scale type
  yDomainB, // [ymin, ymax]
  yRangeB = [height - marginBottom, marginTop], // [bottom, top]
  yFormatB, // a format specifier string for the y-axis
  yLabelB, // a label for the y-axis
  xTicks = width / 80,
  fill = "white",
  colorA = "currentColor", // stroke color of line
  colorB = "orange", // stroke color of line
  strokeLinecap = "round", // stroke line cap of the line
  strokeLinejoin = "round", // stroke line join of the line
  strokeWidth = 1.5, // stroke width of line, in pixels
  strokeOpacity = 1, // stroke opacity of line
} = {}) {
  // Compute values.
  const X = d3.map(data, x);
  const XB = d3.map(dataB, x);
  const Y = d3.map(data, y);
  const YB = d3.map(dataB, y);
  const I = d3.range(Math.max(X.length, XB.length));
  if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
  if (definedB === undefined) definedB = (d, i) => !isNaN(XB[i]) && !isNaN(YB[i]);
  const D = d3.map(data, defined);
  const DB = d3.map(dataB, definedB);

  // Compute default domains.
  if (xDomain === undefined) xDomain = d3.extent(X);
  // if (xDomainB === undefined) xDomainB = d3.extent(XB);
  if (yDomain === undefined) yDomain = [0, d3.max(Y) * 1.05];
  if (yDomainB === undefined) yDomainB = [0, d3.max(YB) * 1.05];

  // Construct scales and axes.
  const xScale = xType(xDomain, xRange);
  const yScale = yType(yDomain, yRange);
  const yScaleB = yTypeB(yDomainB, yRangeB);
  const xAxis = d3.axisBottom(xScale).ticks(xTicks).tickSizeOuter(5);
  const yAxis = d3.axisLeft(yScale).ticks(height / 40, yFormat);
  const yAxisB = d3.axisRight(yScaleB).ticks(height / 40, yFormatB);

  // Construct a line generator.
  const line = d3.line()
      .defined(i => D[i])
      .curve(curve)
      .x(i => xScale(X[i]))
      .y(i => yScale(Y[i]));
  const lineB = d3.line()
      .defined(i => DB[i])
      .curve(curve)
      .x(i => xScale(XB[i]))
      .y(i => yScaleB(YB[i]));

  const svg = d3.create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

  svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(xAxis);

  svg.append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(yAxis)
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll(".tick line").clone()
          .attr("x2", width - marginLeft - marginRight)
          .attr("stroke-opacity", 0.1))
      .call(g => g.append("text")
          .attr("x", -marginLeft)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .text(yLabel)
          .attr("fill", colorA));
  svg.append("g")
      .attr("transform", `translate(${width-marginRight},0)`)
      .call(yAxisB)
      .call(g => g.select(".domain").remove())
      .call(g => g.append("text")
      .attr("x", -marginRight)
      .attr("y", 10)
      .attr("fill", "currentColor")
      .attr("text-anchor", "start")
      .text(yLabelB)
      .attr("fill", colorB));

  svg.append("g")
      .attr("fill", fill)
      .attr("stroke", colorA)
        .attr("stroke-width", strokeWidth)
      .selectAll("circle")
      .data(I.filter(i => D[i]))
      .join("circle")
        .attr("cx", i => xScale(X[i]))
        .attr("cy", i => yScale(Y[i]))
        .attr("r", r);
  svg.append("g")
      .attr("fill", fill)
      .attr("stroke", colorB)
        .attr("stroke-width", strokeWidth)
      .selectAll("circle")
      .data(I.filter(i => DB[i]))
      .join("circle")
        .attr("cx", i => xScale(XB[i]))
        .attr("cy", i => yScaleB(YB[i]))
        .attr("r", r);

  svg.append("path")
      .attr("fill", "none")
      .attr("stroke", colorA)
      .attr("stroke-width", strokeWidth)
      .attr("stroke-linecap", strokeLinecap)
      .attr("stroke-linejoin", strokeLinejoin)
      .attr("stroke-opacity", strokeOpacity)
      .attr("d", line(I));
  svg.append("path")
      .attr("fill", "none")
      .attr("stroke", colorB)
      .attr("stroke-opacity", 0.25)
      .attr("stroke-width", strokeWidth)
      .attr("stroke-linecap", strokeLinecap)
      .attr("stroke-linejoin", strokeLinejoin)
      .attr("stroke-opacity", strokeOpacity)
      .attr("d", lineB(I));

  return svg.node();
}

const PatientDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const debugMode = useSelector((s) => s.session.debugMode);
  const userId = props?.match?.params?.patientId;

  let user = useSelector(
    (s) => s.provider.users.find((u) => u.id === userId) || {}
  );
  let userScreenings = useSelector((s) =>
    getAllScreenings(s).filter((sc) => sc.userId === userId)
  );
  const [changeInviteDialog, setChangeInviteDialog] = useState({ show: false });
  const [detailsModal, setDetailsModal] = useState(false);

  useEffect(() => {
    dispatch({ type: "recent-records/REMEMBER", payload: userId });
  }, [userId]);

  useEffect(() => {
    // console.error(user, userId);
    if (!user.id && userId) {
      dispatch(getSpecificUser(userId));
    }
  }, [user, userId]);

  const [inviteLoadTimes, setInviteLoadTimes] = useState({});
  const [eventHistory, setEventHistory] = useState([]);
  const [fullReports, setFullReports] = useState([]);
  function loadInvites(userId) {
    setInviteLoadTimes({ ...inviteLoadTimes, [userId]: -1 });
    return Promise.all([
      dispatch(getUserInvites(userId)),
      dispatch(getUserReports(userId)),
      dispatch(getUserScheduledInvites(userId)),
      dispatch(getUserMessages(userId)),
      dispatch(getUserScores(userId)),
      dispatch(getUserAlerts(userId)),
    ]).then(
      ([inviteUpdate, reportUpdate, scheduledsInvite, messagesUpdate, scoresUpdate, alertsUpdate]) => {
        setInviteLoadTimes({ ...inviteLoadTimes, [userId]: Date.now() });
        // console.log(inviteUpdate);
        // console.log(reportUpdate);
        setFullReports(reportUpdate.reports);
        const newHistory = [];
        messagesUpdate.payload
          .filter((m) => m.DIR === "out")
          .forEach((message, i) => {
            const screening = inviteUpdate.invites.find(
              (s) => s.id === message.screeningId
            );
            const contactMismatch =
              message.method === "email"
                ? message.toAddress !== user.email
                : message.toAddress !== user.phone;
            newHistory.push({
              eventType: message.type,
              status: message.status,
              timestamp: message.t_statusUpdated,
              target: message.toAddress,
              messageType: message.method,
              inviteId: message.screeningId,
              screening: screening?.type,
              contactMismatch,
              index: i,
            });
          });
        reportUpdate.reports.forEach((report) => {
          newHistory.push({
            eventType: "report-completed",
            timestamp: report.t_submitted,
            screening: report.type,
          });
        });
        newHistory.sort((a, b) => b.timestamp - a.timestamp); // we want reverse order!
        setEventHistory(newHistory);
        console.warn(newHistory);
        // expanded[userId] = true;
        // setExpanded({ ...expanded });
      }
    );
  }

  const [selectedScoreType, setSelectedScoreType] = useState(null);
  const trueReportCount = useMemo(() => (new Set(fullReports.map(r => r.screeningId))).size, [fullReports]);
  const lastScore = useMemo(() => {
    return (user?.scores || []).reduce((a, b) => a?.t_generated > b?.t_generated ? a : b, {t_generated: -1})
  }, [user]);
  const scoreTypes = useMemo(() => {
    if (Array.isArray(user?.scores)) {
      if (selectedScoreType === null && user.scores.length > 0) {
        setSelectedScoreType(lastScore.screener);
      }
      return Array.from(new Set(user.scores.map(s => s.screener)));
    }
    return [];
  }, [user]);
  const lastReport = useMemo(() => {
    return fullReports?.reduce((a, b) => a?.t_submitted > b?.t_submitted ? a : b, {t_submitted: -1})
  }, [fullReports]);

  useEffect(() => {
    if (user.id) {
      if (user.id in inviteLoadTimes) {
        console.warn(
          `User ${user.id} already has a load request for reports & screenings`
        );
      } else {
        console.warn("Loading reports and screenings!");
        loadInvites(user.id);
      }
    }
  }, [user.id]);

  let mostRecentReport;
  let mostRecentReportDate = Number.MIN_SAFE_INTEGER;
  if (user.t_lastReport) {
    mostRecentReport = user.t_lastReport;
  } else {
    user?.reports?.forEach((r) => {
      if (typeof r === "object" && r.t_submitted > mostRecentReportDate) {
        mostRecentReportDate = r.t_submitted;
        mostRecentReport = r;
      }
    });
  }

  const trendChartContainer = createRef();
  const [trendChartSVG, setTrendChartSVG] = useState(null);
  const [expandCard, setExpandCard] = useState(null);
  function attachTrendChartTo (parentElement) {
    const {width, height} = parentElement.getBoundingClientRect();
    
    const scoreData = user?.scores ? user.scores.filter(s => s.screener === selectedScoreType).sort((sa, sz) => sa?.t_generated - sz?.t_generated).map(s => ({
      date: moment(s.t_generated).toDate(),
      value: s.value
    })) : [];
    const durationData = fullReports ? fullReports.filter(r => r.type === selectedScoreType).sort((ra, rz) => ra?.t_submitted - rz?.t_submitted).map(r => ({
      date: moment(r.t_submitted).toDate(),
      value: r.durationSeconds
    })) : [];
    const maxDuration = durationData.reduce((a,b) => Math.max(+a, +(b?.value)), 60);
    const datesForDomain = scoreData.map(d => d.date).concat(durationData.map(d => d.date));
    const firstDay = moment(Math.min(...datesForDomain)).subtract(1, "day").startOf('day');
    const lastDay = moment(Math.max(...datesForDomain)).add(1, "day").startOf('day');
    const dayCount = lastDay.diff(firstDay, "day");
    const tickFn = dayCount > 1 ? d3.timeDay.filter(d=> d3.timeDay.count(0, d) % Math.ceil(dayCount / 5) === 0) : 5;
    const chartNode = DualLineChart(scoreData, durationData, {
      x: d => d.date,
      y: d => d.value,
      xType: d3.scaleTime,
      xDomain: (dayCount > 1) ? [firstDay.toDate(), lastDay.toDate()] : d3.extent(datesForDomain), // should always be the first one now, second option kept for reference for our refactor
      xTicks: tickFn,
      yLabel: "Score",
      yDomain: [0, 63],
      yDomainB: [0, maxDuration],
      yLabelB: "Duration (s)",
      width,
      height: Math.max(height, expandCard === "trends" ? 500 : 300),
      colorA: "steelblue",
      colorB: "orange"
    });
    parentElement.replaceChildren(chartNode);
    return chartNode;
  }

  function rebuildTrendChart () {
    setTrendChartSVG(attachTrendChartTo(trendChartContainer.current));
  }

  useEffect(() => {
    if (trendChartContainer.current instanceof HTMLDivElement && !trendChartSVG && Array.isArray(user?.scores) && fullReports?.length > 0) {
      rebuildTrendChart(trendChartContainer.current);
    }
  }, [trendChartContainer, trendChartSVG, user, fullReports]);

  function toggleExpandedTrend () {
    if (expandCard === "trends") {
      setExpandCard(null);
    } else {
      setExpandCard("trends");
    }
    setTrendChartSVG(null);
  }

  const REPORT_COLUMNS = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      flex: 0.75,
      disableExport: true,
    },
    {
      field: "submitter.lastName",
      headerName: "Last Name",
      valueGetter: (params) => params.row.submitter?.lastName || "",
      hide: true,
    },
    {
      field: "submitter.dob",
      headerName: "DOB",
      valueGetter: (params) => params.row.submitter?.dob || "",
      hide: true,
    },
    {
      field: "t_submitted",
      headerName: "Date",
      // type: "number",
      valueFormatter: ({ value }) =>
        value ? moment(value).format("M/D/YY h:mm a") : "",
      flex: 1.5,
    },
    // {
    //   field: 'lastName',
    //   headerName: "Last Name",
    //   valueGetter: (params) => {
    //     const submitter = params.getValue(params.id, "submitter");
    //     if (submitter) {
    //       return submitter.lastName;
    //     }
    //     return "";
    //   }
    // },
    {
      field: "type",
      headerName: "Screening",
      valueFormatter: ({ value }) => ScreenerShortNames[value] || value,
    },
    {
      field: "screenerVersion",
      headerName: "Scr. Version",
      hide: true,
      disableExport: true,
    },
    {
      field: "completionLanguage",
      headerName: "Language",
      valueFormatter: ({ value }) => dualName(value),
      renderCell: ({ value }) => (
        <span className="badge badge-light">{dualName(value)}</span>
      ),
    },
    {
      field: "durationSeconds",
      headerName: "Duration",
      valueFormatter: ({ value }) =>
        value >= 60 ? `${Math.floor(value / 60)}m ${value % 60}s` : `${value}s`,
    },
    {
      field: "requesterId",
      headerName: "Requester ID",
      hide: true,
      disableExport: true,
    },
    {
      field: "answer count",
      headerName: "Answers",
      valueGetter: (params) => {
        const a = params.getValue(params.id, "answers");
        return a ? Object.values(a).length : 0;
      },
      disableExport: true,
    },
    {
      field: "outcome count",
      headerName: "Outcome count",
      valueGetter: (params) => {
        const a = params.getValue(params.id, "outcomes");
        return a ? Object.values(a).length : 0;
      },
      hide: true,
      disableExport: true,
    },
    {
      field: "version",
      headerName: "DB Version",
      hide: true,
      disableExport: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <button
          className="btn btn-pink btn-sm"
          onClick={() => openReport(params.id)}
        >
          <FaFileExport /> View
        </button>
      ),
      disableExport: true,
    },
  ].map((i) => {
    if (!i.flex) i.flex = 1;
    return i;
  });

  const INVITE_COLUMNS = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      flex: 0.75,
    },
    {
      field: "t_lastSent",
      headerName: "Last Sent",
      valueFormatter: ({ value }) =>
        value ? moment(value).format("M/D/YY h:mm a") : "",
    },
    {
      field: "t_created",
      headerName: "First Sent",
      hide: true,
      valueFormatter: ({ value }) =>
        value ? moment(value).format("M/D/YY h:mm a") : "",
    },
    {
      field: "t_submitted",
      headerName: "Report Submitted",
      hide: true,
      valueFormatter: ({ value }) =>
        value ? moment(value).format("M/D/YY h:mm a") : "",
    },
    {
      field: "t_inactive",
      headerName: "Date Inactive",
      hide: true,
      valueFormatter: ({ value }) =>
        value ? moment(value).format("M/D/YY h:mm a") : "",
    },
    {
      field: "t_statusUpdated",
      headerName: "Last Status Update",
      hide: true,
      valueFormatter: ({ value }) =>
        value ? moment(value).format("M/D/YY h:mm a") : "",
    },
    {
      field: "type",
      headerName: "Type",
      valueFormatter: ({ value }) => ScreenerShortNames[value] || value,
    },
    {
      field: "lang",
      headerName: "Language",
      renderCell: ({ value }) => (
        <span className="badge badge-light">{dualName(value)}</span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) =>
        ScreeningStatusHR[params.row.status] || params.row.status,
      renderCell: ({ value }) => (
        <span className={`badge badge-secondary`}>{value}</span>
      ), // StatusBadgeClass[value]
    },
    {
      field: "messageCount",
      headerName: "Msg. Count",
      valueGetter: (params) =>
        params.getValue(params.id, "messages")?.length || 0,
    },
    {
      field: "nextAppointmentDateString",
      headerName: "Appt",
      valueGetter: (params) => {
        const timestamp = params.getValue(params.id, "t_appointment");
        const dateString = params.getValue(params.id, "d_appointment");
        if (timestamp) {
          return moment(timestamp).format("M/D/YY");
        }
        if (dateString) {
          return moment(dateString).format("M/D/YY");
        }
        return "-";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <a
          href="#"
          className="text-primary"
          onClick={(e) => copyInviteLink(params.row)}
        >
          Copy URL
        </a>
      ),
    },
  ].map((i) => {
    if (!i.flex) i.flex = 1;
    return i;
  });

  function copyInviteLink(screening) {
    const url = buildInviteUrl(
      screening.accessKey,
      "direct_copy"
    );
    navigator.clipboard
      .writeText(url)
      .then(() => console.log(`Invite URL was copied to clipboard:\n${url}`));
  }

  const SCHEDULED_INVITE_COLUMNS = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      flex: 0.75,
    },
    {
      field: "type",
      headerName: "Type",
      valueGetter: (params) => {
        const type = params.getValue(params.id, "template")?.type;
        return ScreenerShortNames[type] || type;
      },
    },
    {
      field: "period",
      headerName: "Period",
    },
    {
      field: "preferredDay",
      headerName: "Day",
    },
    {
      field: "preferredHour",
      headerName: "Hour",
    },
    {
      field: "active",
      headerName: "Active?",
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <button
          className="btn RedButton btn-sm text-white"
          disabled={!params.getValue(params.id, "active")}
          onClick={() => cancelScheduled(params.id)}
        >
          <FaRegTimesCircle /> Cancel
        </button>
      ),
    },
  ].map((i) => {
    if (!i.flex) i.flex = 1;
    return i;
  });

  function openReport(id) {
    history.push(`${REPORTS}/${id}`, { reportId: id });
  }

  function clickRow(params, event) {
    openReport(params.id);
  }

  function cancelScheduled(id) {
    dispatch(updateScheduledInvite(id, { active: false }, userId));
  }

  function createNewInvite() {
    dispatch(
      updateEnrollmentUser({
        ...newEnrollment(),
        ...user,
      })
    );
    history.push(PROVIDER_ENROLL_PATIENT);
  }

  function printLastReport() {
    const SENTINEL_REPORT = { t_submitted: Number.MIN_SAFE_INTEGER };
    let newestReportSoFar = SENTINEL_REPORT;
    // classic one-pass approach to find highest value
    fullReports.forEach((r) => {
      if (r.t_submitted > newestReportSoFar.t_submitted) {
        newestReportSoFar = r;
      }
    });
    if (newestReportSoFar !== SENTINEL_REPORT) {
      history.push(`${REPORTS}/${newestReportSoFar.id}`, {
        reportId: newestReportSoFar.id,
        launchPrintDialog: true,
      });
    }
  }

  const LabeledToolbar = (title, forceDebug) => () => {
    return (
      <>
        <div className="tableLabel">
          <h3>{title}</h3>
        </div>
        {debugMode || forceDebug ? <GridToolbar /> : null}
      </>
    );
  };

  const ReportsToolbar = () => {
    return (
      <>
        <div
          className="tableLabel"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Reports</h3>
          {reportColumnsExpanded ? (
            <GridToolbarExport
              style={{ background: "white" }}
              csvOptions={{
                allColumns: true,
                fileName: `literaseed_export_${stringForFile(
                  user.lastName
                )}_${moment().format("YYYYMMDD")}`,
                utf8WithBom: true,
              }}
            />
          ) : (
            <span
              className="btn btn-sm btn-pink-outline"
              onClick={() => injectAnswerColumns()}
            >
              Generate Export
            </span>
          )}
        </div>
        {debugMode ? <GridToolbar /> : null}
      </>
    );
  };

  function stringForFile(s) {
    return `${s}`.replace(/\s/g, "_").replace(/[^0-9A-Za-z_-]/g, "");
  }

  function getAnswerForCSV(r, k) {
    const a = r?.answers?.[k]?.value;
    if (Array.isArray(a)) return a.join(" | ");
    return a;
  }
  function getSelectedForCSV(r, k) {
    const a = r?.answers?.[k]?.selected;
    if (Array.isArray(a)) return a.join(" | ");
    return a;
  }
  function getOutcomeForCSV(r, k) {
    const a = r?.outcomes?.[k];
    if (Array.isArray(a)) return a.join(" | ");
    return a;
  }

  /**
   * Generate DataGrid columns for all of the answers currently present on all
   * reports in the reports table. These columns are primarily of use to the
   * export format, but could feasibly be useful
   */
  const injectAnswerColumns = (confirmMultiple = false) => {
    // the column definitions as they are generated
    const newColumns = [];
    // list of the answer keys seen so far whose definitions have been added
    const colsFound = new Set();

    // if the table has different questionnaire types, the report gets a little
    // weird (this is outside our current use case) so warn the user and check
    // if they want to progress
    const screenersNeeded = [...new Set(fullReports.map((r) => r.type))];
    if (screenersNeeded.length > 1) {
      if (
        !window.confirm(
          "This user has multiple different screenings in their reports table. Do you still want to export all?"
        )
      ) {
        return;
      }
    }

    // get all of the necessary screening definitions and once done, we progress
    const promise = Promise.all(
      screenersNeeded.map((s) =>
        dispatch(
          loadOrLocalQuestionnaire({
            type: s,
            locale: { language: Language.English },
          })
        )
      )
    );
    return promise.then((responses) => {
      const asMap = new Map(
        responses.map((r, qi) => {
          r.payload.__loadOrder = qi; // TODO: move to slice
          return [r.payload.screener, r.payload];
        })
      );
      // add definitions for each answer and outcome not yet seen
      fullReports.forEach((r) => {
        const which =
          asMap.get(r.type)?.reportSettings?.exportColumns || "value";
        Object.entries(r.answers).forEach(([answerKey, answerValue]) => {
          if (!colsFound.has(answerKey)) {
            // TODO: prevent conflicts between questionnaires?
            colsFound.add(answerKey);
            const defn = asMap.get(r.type)?.questions?.[answerKey] || {};
            const basis = {
              field: `${r.type}:${answerKey}`,
              headerName: answerKey,
              hide: true,
              valueGetter: (params) => getAnswerForCSV(params.row, answerKey),
              screener: r.type,
              order: Number.isSafeInteger(defn.orderInReport)
                ? defn.orderInReport
                : defn.naturalOrder + (1 << 20),
            };
            if (which === "value" || which === "both") {
              newColumns.push({
                ...basis,
                valueGetter: (params) => getAnswerForCSV(params.row, answerKey),
              });
            }
            if (which === "selected" || which === "both") {
              newColumns.push({
                ...basis,
                field: basis.field + "#",
                headerName: basis.headerName + " selected",
                order: basis.order + 0.5,
                valueGetter: (params) =>
                  getSelectedForCSV(params.row, answerKey),
              });
            }
          }
        });
        Object.entries(r.outcomes).forEach(
          ([outcomeKey, outcomeValue], index) => {
            if (!colsFound.has(outcomeKey)) {
              colsFound.add(outcomeKey);
              newColumns.push({
                field: `${r.type}=${outcomeKey}`,
                headerName: outcomeKey,
                hide: true,
                order: (1 << 30) + index,
                valueGetter: (params) =>
                  getOutcomeForCSV(params.row, outcomeKey),
              });
            }
          }
        );
      });
      // sort by the various order values we set
      newColumns.sort((a, b) => a.order - b.order);
      // we'll also need to sort at a higher level by which questionnaire each
      // answer comes from, since some patients may have multiple sources [TODO]
      setReportColumnsExpanded(REPORT_COLUMNS.concat(newColumns));
    });
  };

  const [reportColumnsExpanded, setReportColumnsExpanded] = useState(null);

  const DOT_COLOR_BY_TYPE = {
    invite_template: "gold",
    invite_template_RESEND: "red",
    "report-completed": "green",
  };
  const DESCRIPTION_BY_TYPE = {
    invite_template: "initial screening invite",
    invite_template_RESEND: "re-sent screening invite",
    "report-completed": "completed a report",
  };
  function DotForType({ eventType, messageType }) {
    return (
      <TimelineDot
        style={{ background: DOT_COLOR_BY_TYPE[eventType] || "black" }}
        title={DESCRIPTION_BY_TYPE[eventType] || "no type given"}
      >
        {eventType?.startsWith("invite_template")
          ? _switch(
              messageType,
              "sms",
              <FaSms color="white" />,
              "whatsapp",
              <FaWhatsapp color="white" />,
              "email",
              <FaEnvelope color="white" />
            )
          : null}
        {eventType === "report-completed" ? (
          <FaFileContract color="white" />
        ) : null}
      </TimelineDot>
    );
  }
  const BADGE_FOR_SEND_STATUS = {
    requested: "badge-light",
    queued: "badge-light",
    sent: "badge-warning",
    delivered: "badge-success",
    error: "badge-danger",
  };

  // return (
  //   <Container className="patient-details-page pr-0 pl-0" style={{}}>
  //     <Row className="m-3">
  //       <Col style={{ display: "flex", alignItems: "center", padding: "0" }}>
  //         {/* <Link className="btn btn-primary-outline p-2" to={PROVIDER}>
  //           <FaArrowLeft /> Back to Dashboard
  //         </Link> */}
  //       </Col>
  //       <Col className="col-3">
  //         <button
  //           className="btn btn-primary-outline w-100 p-2"
  //           onClick={() => createNewInvite()}
  //         >
  //           <FaFileMedical /> Send New 
  //         </button>
  //       </Col>
  //       <Col className="col-3">
  //         <button
  //           className="btn btn-primary-outline w-100 p-2"
  //           onClick={() => printLastReport()}
  //         >
  //           <FaPrint /> Print Recent
  //         </button>
  //       </Col>
  //     </Row>

  const [updatedDetails, setUpdatedDetails] = useState({
    dob: "01/01/1900",
    firstName: "",
    lastName: "",
    email: "",
    phone: ""
  });
  function showDetailsModal () {
    setUpdatedDetails({
      firstName: user.firstName,
      lastName: user.lastName,
      dob: moment(user.dob).format("MM/DD/YYYY"),
      email: user.email,
      phone: user.phone
    });
    setDetailsModal(true);
  }
  function submitNewDetails () {
    let out = {...updatedDetails};
    if (out.dob) {
      out.dob = moment(out.dob).format("YYYY-MM-DD");
      if (out.dob === user.dob) delete out.dob;
    }
    ["lastName", "firstName", "email", "phone"].forEach(field => {
      if (out[field] === user[field]) {
        delete out[field];
      }
    });
    dispatch(updateUserDetails({id: user.id, update: out})).then(
      success => {setDetailsModal(false); dispatch(snack(`Successfully updated user details!`))},
      err => dispatch(alertEvent(`Error updating! ${err}`))
    );
  }

  const userLangs = useMemo(() => {
    if (!userScreenings) return [];
    const s = new Set(userScreenings.map(s => s.lang)); // .filter(l => l !== user?.preferredLang))
    if (user?.preferredLanguage) {
      s.add(user.preferredLanguage);
    }
    return Array.from(s);
  }, [userScreenings]);

  function severitySort (a, z) {
    const d = a?.severity - z?.severity;
    return (d === 0) ? (a?.value - z?.value) : d;
  }

  function setAlertActive (alertId, value) {
    dispatch(updateUserAlert({alertId, changes: {active: value}}));
  }

  const [alertViewActive, setAlertViewActive] = useState(true);

  return <Container className="patient-details-page pr-0 pl-0 mt-4" style={{}}>
    {/* <Row className="">
      <Col>
        <small><Link className="d-inline-block" style={{lineHeight: "24px", paddingTop: "9px", marginLeft: "-40px"}} to={PROVIDER}><FaArrowLeft/> Back to Dashboard</Link></small>
      </Col>
    </Row> */}

    <Row>
      <Col className="col-6">
      <h3 className="mt-2">
        Patient Summary
        {" "}
        <span className="badge badge-info" onClick={() => showDetailsModal()}><FaPencilAlt /> Edit</span>
      </h3>
      </Col>
      <Col className="col-3">
        <button className="btn btn-primary-outline w-100 p-2" style={{position: "relative", top: "-0.5rem"}} onClick={() => createNewInvite()}><FaFileMedical/> Send New</button>
      </Col>
      <Col className="col-3">
        <button className="btn btn-primary-outline w-100 p-2" style={{position: "relative", top: "-0.5rem"}} onClick={() => printLastReport()}><FaPrint/> Print Recent</button>
      </Col>
    </Row>
    <table className="patient-summary">
      <tbody>
        <tr>
          <th className="patient-name">{user.lastName?.toLocaleUpperCase()}{user.firstName ? ", " + user.firstName.toLocaleUpperCase() : ""}</th>
          <td><label>Phone:</label> {user.phone || <kbd>not set</kbd>}</td>
          <td><label>Next Appt:</label>
           {user.t_nextAppointment
              ? moment(user.t_nextAppointment).calendar(ALWAYS_SHOW_TIME_FMT)
              : user.d_nextAppointment
                ? moment(user.d_nextAppointment).calendar(DAY_ONLY_RELATIVE_FMT)
                : <span className="badge badge-dark">N/A</span>}
          </td>
        </tr>
        <tr>
          <th className="patient-age">
            DOB: {moment(user.dob).format("MM/DD/YYYY")}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {moment().diff(user.dob, "years", false)} y.o.
          </th>
          <td><label>Email:</label> {user.email ? <a href={`mailto:${user.email}`}>{user.email}</a> : <kbd>not set</kbd>}</td>
          <td><label>Languages:</label> {userLangs.length > 0 ? 
            userLangs.map(l => 
              <span
                className={`badge ${l === user?.preferredLanguage ? "badge-success" : "badge-dark"}`}
                title={l === user?.preferredLanguage ? "This is the user's preferred language" : "This language is not preferred but was used in at least 1 report"}>
                {dualName(l)}
              </span>)
            : <span className="badge badge-error">No language set</span>}
          </td>
          {/* <td><label>Last Report:</label> {mostRecentReport ? moment(mostRecentReportDate).format("MM/DD/YYYY h:mm a") : "none"}</td> */}
        </tr>
      </tbody>
    </table>

    <Row>
        <Col>
          <Row>
            <Col>
              <h4 className="text-viridian-green mb-0">Highlights</h4>
            </Col>
            <Col>
            
              {/* <label for="standard-select" className="text-viridian-green text-right" style={{float: "left", width: "10em", marginRight: "1em"}}>
                <strong>Questionnaire</strong>
              </label>
              <div className="dropdown-dashboard">
                <select id="standard-select">
                  <option value="1">Beck's Depression</option>
                  <option value="2">Beck's Anxiety</option>
                  <option value="3">All Screenings</option>
                </select>
                <span className="focus"></span>
              </div> */}
            </Col>
          </Row>

          <hr
            style={{
              height: "2px",
              backgroundColor: "#339499",
              border: "none",
              marginTop: "8px",
            }}
          />
          {/* <Card> */}
          {/* <CardHeader>
              
            </CardHeader> */}
          {/* <CardBody> */}
        <Row className="">
          <Col>
              <Card>
                <CardBody>
                  <CardTitle className="text-viridian-green mb-0">
                    <strong>
                      <h3 className="mb-0">{lastScore?.value ?? <span className="badge badge-dark">N/A</span>}</h3>
                    </strong>
                  </CardTitle>
                  Last {lastScore?.title || "Score"}
                  {lastScore?.screener ? <span className="badge badge-light float-right">{lastScore.screener}</span> : null}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="text-viridian-green mb-0">
                    <strong>
                      <h3 className="mb-0">{lastReport?.durationSeconds ? `${Math.trunc(lastReport.durationSeconds / 60)}m ${lastReport.durationSeconds % 60}s` : <span className="badge badge-dark">N/A</span>}</h3>
                    </strong>
                  </CardTitle>
                  Time Spent
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="text-viridian-green mb-0">
                    <strong>
                      <h3 className="mb-0">{trueReportCount || <span className="badge badge-dark">N/A</span>}</h3>
                    </strong>
                  </CardTitle>
                  Report Submissions
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="text-viridian-green mb-0">
                    <strong>
                      <h3 className="mb-0">
                        {trueReportCount}/{userScreenings?.length || 0}
                        <span className="badge badge-light float-right">{Math.trunc(trueReportCount / Math.max(userScreenings?.length, trueReportCount) * 100)}%</span>
                      </h3>
                    </strong>
                  </CardTitle>
                  Completion Rate
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* </CardBody>
          </Card> */}
        </Col>
      </Row>

      <Row>
        <Col className={`${expandCard === "trends" ? "col-12" : "col"} mt-4`}>
          <Card>
            <CardHeader style={{ background: "#339499", color: "white" }} onClick={toggleExpandedTrend}>
              <h3 className="mb-0">
                <FaChartArea /> Trends
                {scoreTypes.length > 1 ? 
                <span className="float-right">
                  <select className="custom-select custom-select-sm" onClick={ev => ev.stopPropagation()} value={selectedScoreType} onChange={e => {setSelectedScoreType(e.target.value); setTrendChartSVG(null);}}>
                    {scoreTypes.map(st => <option key={`st-${st}`} value={st}>{ScreenerShortNames[st] ?? st}</option>)}
                  </select>
                </span> : null
              }
              </h3>
              <CardText className="text-muted">
                <small className="text-white">
                  Patient's symptoms over time.
                </small>
              </CardText>
            </CardHeader>
            <CardBody>
              <div ref={trendChartContainer} className="text-center"><span className="my-5 badge badge-warning">No data</span></div>
            </CardBody>
          </Card>

        </Col>
        <Col className="mt-4">
          <Card>
            <CardHeader style={{ background: "#339499", color: "white" }}>
              <Row className="align-items-center">
                <Col className="col-8">
                  <h3 className="mb-0">
                    <FaExclamationTriangle /> {alertViewActive ? "" : "Inactive "}Patient Alerts
                  </h3>
                  <CardText className="text-grey">
                    <small className="text-white">
                      {alertViewActive ? "Patient alerts needing attention." : "Patient alerts that have been dismissed."}
                    </small>
                  </CardText>
                </Col>
                <Col className="col-4 text-right">
                  <button className="btn btn-sm btn-primary" onClick={() => setAlertViewActive(!alertViewActive)}>View {alertViewActive ? "Inactive" : "Active"}</button>
                </Col>
              </Row>
            </CardHeader>

            <ListGroup variant="flush" style={{maxHeight: "340px", height: "100%", overflowY: "scroll", justifyContent: "flex-start"}}>
            {user?.alerts?.some?.(a => a.active === alertViewActive) ? 
              <ul className="list-group">
                 {user.alerts.filter(a => a.active === alertViewActive).sort(severitySort).map(a =>
                <li className="list-group-item" title={`Severity: <${a.severity},${a.value}>`}>
                  <strong>{a.title}</strong> on {moment(a.t_generated).format("M/D/YY")}
                  {alertViewActive ? <span className="badge badge-danger float-right" onClick={() => setAlertActive(a.id, false)}>
                    <FaEyeSlash title="Mark inactive" />
                    {" "}
                    Clear
                  </span> : <span className="badge badge-warning float-right" onClick={() => setAlertActive(a.id, true)}>
                    <FaRegEye title="Mark inactive" />
                    {" "}
                    Reactivate
                  </span>}
                </li>)}
                <li className="list-group-item"></li>
              </ul> : <div className="text-center my-5"><span className="badge badge-light">No alerts</span></div>}
            </ListGroup>

            {/* Limit list with option to view more in a new window or screen, add action items and "addressed" to close item */}
          </Card>
        </Col>
        {/* <Col>
          <Card>
            <CardBody>
              <CardTitle className="text-center" style={{ fontSize: "2rem" }}>
                <FaClock />
              </CardTitle>
              <h2 className="text-center mb-0 pb-0 text-viridian-green">
                5 min
              </h2>
              <CardText className="text-grey text-center">
                <small>Average Completion Time</small>
              </CardText>
            </CardBody>
          </Card>
        </Col> */}
      </Row>

      <Row className="mt-4">
        <Col>
    <DataGrid
      className="reports-grid"
      columns={reportColumnsExpanded || REPORT_COLUMNS}
      rows={fullReports}
      loading={inviteLoadTimes[userId] === -1}
      components={{Toolbar: ReportsToolbar}}
      pageSize={25}
      autoHeight={true}
      onRowClick={(params, event) => clickRow(params, event)}
      />
      <br/>
      <DataGrid
      className="reports-grid"
      columns={INVITE_COLUMNS}
      rows={userScreenings || []}
      loading={inviteLoadTimes[userId] === -1}
      components={{Toolbar: LabeledToolbar("Screening Requests")}}
      pageSize={25}
      autoHeight={true}
      />
      <DataGrid
      className="reports-grid"
      columns={SCHEDULED_INVITE_COLUMNS}
      rows={user.scheduledInvites || []}
      loading={inviteLoadTimes[userId] === -1}
      components={{Toolbar: LabeledToolbar("Scheduled Invites")}}
      pageSize={25}
      autoHeight={true}
      />
      </Col>

    <Col className="col-4">
    <Timeline className="history-timeline">
      <h4 className="history-title">Messages</h4>
      {eventHistory.map((e, i) => <TimelineItem key={i}>
        <TimelineOppositeContent style={{flex: 0.5}}>{moment(e.timestamp).format("M/D/YY")}<br/>{moment(e.timestamp).format("H:mm a")}</TimelineOppositeContent>
        <TimelineSeparator>
          <DotForType eventType={e.eventType} messageType={e.messageType} />
          {i === eventHistory.length - 1 ? null : <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent className="timeline-card">
          {e.eventType === "invite_template" ? <>new invite <em className="small text-muted float-right" title={e.target}>{e.messageType}</em></> : null}
          {e.eventType === "invite_template_RESEND" ? <>followup invite <em className="small text-muted float-right" title={e.target}>{e.messageType}</em></> : null}
          {e.eventType === "report-completed" ? `report received` : null}
          {e.contactMismatch ? <><br/><span className="text-danger small" title="This message used a contact different than the one stored on the user record!">using {e.messageType}: {e.target}</span></> : null}
          <br/><span className="badge badge-dark">{ScreenerShortNames[e.screening]}</span><span className={`badge ${BADGE_FOR_SEND_STATUS[e.status] || "badge-light"}`}>{e.waRead ? "read" : e.status}</span>
        </TimelineContent>
      </TimelineItem>)}
    </Timeline>
    </Col>
    </Row>

    <Modal show={!!detailsModal} onHide={() => setDetailsModal(false)} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Edit User Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p className="text-info mb-4">
        You are about to override previously-entered user details. By 
        proceeding with this action, any active scheduled or recurring invites
        will be updated accordingly.
      </p>
      <Input
        id="lastName"
        name="lastName"
        // type="text"
        elementType="input"
        label="Last Name"
        placeholder="Patient Last Name"
        value={updatedDetails.lastName}
        changed={value => setUpdatedDetails({...updatedDetails, lastName: value})} />
      <Input
        id="dob"
        name="dob"
        type="date"
        elementType="date"
        label="Date of Birth"
        placeholder="mm/dd/yyyy"
        value={updatedDetails.dob}
        changed={value => setUpdatedDetails({...updatedDetails, dob: value})} />
      <Input
        id="phoneNumber"
        name="phoneNumber"
        type="input"
        elementType="input"
        label="Phone Number"
        placeholder="(xxx) yyy-zzzz"
        value={updatedDetails.phone}
        changed={value => setUpdatedDetails({...updatedDetails, phone: value})} />
      <Input
        id="patientEmail"
        name="patientEmail"
        type="email"
        elementType="input"
        label="Patient Email"
        placeholder="address@example.com"
        value={updatedDetails.email}
        changed={value => setUpdatedDetails({...updatedDetails, email: value})} />
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-danger" onClick={() => setDetailsModal(false)}>Cancel</button>
      <button className="btn btn-success" onClick={() => submitNewDetails()}>Update Details</button>
    </Modal.Footer>
  </Modal>
  </Container>;
};

export default PatientDetails;
