
export const NEWS_CATEGORIES = {
    HIDDEN: -2,
    FEATURED_ONLY: -1,
    COMPANY_NEWS: 1,
    BLOG: 2,
    PUBLICATIONS: 3,
    CHANGELOGS: 10
};

/**
 * This list is used to generate the content on the Press page.
 * (At some point it will likely be moved to a back-end location where it can be
 * modified outside of the application lifecycle, but this is a useful interim
 * step)
 *
 * To add a news entry, copy the template below and paste it into the array.
 * Entries are displayed in the same order as they are listed here.
 *
 * To create a mini-entry in the Other News section, be sure to select one of
 * the main news categories (COMPANY_NEWS, BLOG, or PUBLICATIONS) and set
 * isFeatured to false.
 *
 * To create a large "card" entry in the main section, choose FEATURED_ONLY and
 * set isFeatured to true. Also be sure to set "cardImage" to the URL of the
 * image to appear alongside it. (This is currently *required* for featured news
 * and does nothing for non-featured news.)
 *
 * The logic currently allows an entry to be displayed as both a featured card
 * AND an other news list item (by setting a category and isFeatured) but that
 * is discouraged.
 *
 * To create a news article hosted on our site, such as a press release, create
 * a Markdown file with the article content in the
 * /public/content/press folder and the extension ".md" Then give it the URL of
 * /press/FILENAME_WITHOUT_MD_EXTENSION. That page will automatically render
 * the Markdown into HTML. [TODO: More details about formatting] Then be sure
 * to set isLocal to true, or the rendered won't recognize the URL as valid.
 *
 * To remove an item from the list either delete it entirely or simply set the
 * category to HIDDEN and isFeatured to false.
 */

/* TEMPLATE
    {
        "title": "",
        "byline": "",
        "url": "",
        "category": NEWS_CATEGORIES.COMPANY_NEWS,
        "isFeatured": false,
        "cardImage": "",
        "cardAltText": "",
        "isLocal": false,
    },
*/
export const NEWS_ENTRIES = [
    {
        "title": "Release Notes for LiteraSeed v1.18",
        "byline": "Updated July 15th, 2022",
        "url": "/changelogs/1.18",
        "category": NEWS_CATEGORIES.CHANGELOGS,
        "isFeatured": false,
        "isLocal": true,
    },
    {
        "title": "LiteraSeed Awarded NSF Grant to Accelerate Development of Health Literacy App for Underserved Populations",
        "byline": "Press Release, 9/1/21",
        "url": "/press/20210901-SBIR",
        "category": NEWS_CATEGORIES.COMPANY_NEWS,
        "isFeatured": false,
        "isLocal": true,
    },
    {
        "title": "Health Care Heroes 2021 Innovator winner: Aziza Ismail - Phoenix Business Journal",
        "byline": "By Erin Edgemon on 8/26/21",
        "url": "https://www.bizjournals.com/phoenix/news/2021/08/26/health-care-heroes-2021-aziza-ismail.html",
        "category": NEWS_CATEGORIES.COMPANY_NEWS,
        "isFeatured": false,
        "isLocal": false,
    },
    {
        "title": "Maricopa County healthcare system, Valleywise, undergoes major overhaul - Chamber Business News",
        "byline": "By Victoria Harker on 4/21/21",
        "url": "https://chamberbusinessnews.com/2021/04/21/valleywiseexpand/",
        "category": NEWS_CATEGORIES.COMPANY_NEWS,
        "isFeatured": false,
        "isLocal": false,
    },
    {
        "title": "New App Helps Bridge Language Gap Between Phoenix (AZ) Refugees and Doctors",
        "byline": "By JEMS Staff, 5/4/2021",
        "url": "https://www.jems.com/news/new-app-helps-bridge-language-gap-between-phoenix-az-refugees-and-doctors/",
        "category": NEWS_CATEGORIES.FEATURED_ONLY,
        "isFeatured": true,
        "cardImage": "https://d3vik3nb0t4obe.cloudfront.net/wp-content/uploads/2020/03/Ambulance-8-e1610556732231.jpg",
        "cardAltText": "ambulance logo",
        "isLocal": false
    },
    {
        "title": "New app helps break down medical language barriers for Phoenix's refugees",
        "byline": "By Max Gorden, 5/3/2021",
        "url": "https://web.archive.org/web/20210923115359/https://www.azfamily.com/news/new-app-helps-break-down-medical-language-barriers-for-phoenixs-refugees/article_e8cef694-ac6e-11eb-b4fd-73f50f17f38c.html",
        "category": NEWS_CATEGORIES.FEATURED_ONLY,
        "isFeatured": true,
        "cardImage": "/images/az-family-ss.png",
        "cardAltText": "screenshot of literaseed app",
        "isLocal": false,
    },
    {
        "title": "Phoenix-based LiteraSeed launches partnership with Valleywise Health for underserved populations",
        "byline": "By Andy Blye on 4/14/21",
        "url": "https://www.bizjournals.com/phoenix/news/2021/04/14/startup-partners-valleywise-health-underserved.html",
        "category": NEWS_CATEGORIES.COMPANY_NEWS,
        "isFeatured": false,
        "isLocal": false,
    },
    {
        "title": "Valleywise Health Collaborates with Local Startup, LiteraSeed, to Reduce Health Disparities and Cultural Barriers to Care",
        "byline": "Press Release, 4/5/2021",
        "url": "https://valleywisehealth.org/valleywise-health-collaborates-with-local-startup-literaseed-to-reduce-health-disparities-and-cultural-barriers-to-care/",
        "category": NEWS_CATEGORIES.FEATURED_ONLY,
        "isFeatured": true,
        "cardImage": "https://valleywisehealth.org/wp-content/uploads/2019/04/Valleywise-Health.svg?x53060",
        "cardAltText": "valleywise health logo",
        "isLocal": false,
    },
    {
        "title": "The Importance of Founding LiteraSeed",
        "byline": "By Aziza Ismail on 3/2/21",
        "url": "https://aziza-ismail.medium.com/the-importance-of-founding-literaseed-ca8d147f7ae4",
        "category": NEWS_CATEGORIES.BLOG,
        "isFeatured": false,
        "isLocal": false,
    },
    {
        "title": "Two Is Better Than One: Finding The Right Co-founder",
        "byline": "By Aziza Ismail on 5/26/21",
        "url": "https://aziza-ismail.medium.com/two-is-better-than-one-finding-the-right-co-founder-f28293b1d53a",
        "category": NEWS_CATEGORIES.BLOG,
        "isFeatured": false,
        "isLocal": false,
    },
    {
        "title": "Medical Literacy Startup LiteraSeed Grew Out of Barrett Alumna Aziza Ismail’s Honors Thesis",
        "byline": "By Greta Forslund 8/25/20",
        "url": "https://barretthonors.asu.edu/news-events/news/medical-literacy-startup-literaseed-grew-out-barrett-alumna-aziza-ismail%E2%80%99s-honors",
        "category": NEWS_CATEGORIES.COMPANY_NEWS,
        "isFeatured": false,
        "isLocal": false,
    },
    {
        "title": "LiteraSeed Founder Aziza Ismail on Protecting the Vulnerable",
        "byline": "By Olivia Strobl on 8/25/20",
        "url": "https://www.kingscrowd.com/founder-profile-literaseed-aziza-ismail/",
        "category": NEWS_CATEGORIES.COMPANY_NEWS,
        "isFeatured": false,
        "isLocal": false,
    },
    {
        "title": "A Visual-based Universal Symptom Capture App for Refugee Patients with Low Literacy",
        "byline": "2021 Health Literacy Annual Research Conference (HARC)",
        "url": "https://drive.google.com/file/d/1OeYB8lIbMfB9PJEg8TeU9flo-HuQl-we/view",
        "category": NEWS_CATEGORIES.PUBLICATIONS,
        "isFeatured": false,
        "isLocal": false,
    }
];


/**
 * This array is just used for posterity's sake to track removed news items.
 */
// eslint-disable-line no-unused-vars
const REMOVED = [
];
