import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { ROLES } from "../../constants/roles";

const WaitlistThanks = ({
  isAuthenicated,
  role,
}) => {
  const content =
    isAuthenicated && role === ROLES.PATIENT ? (
      <Redirect to={ROUTES.SIGN_IN} />
    ) : isAuthenicated &&
      role === ROLES.PROVIDER ? (
      <Redirect to={ROUTES.PROVIDER_SIGN_IN} />
    ) : (
      <div className="row justify-content-center spacer2">
        <h1 className="h1 text-viridian-green">
          Thanks for joining our waitlist!
        </h1>
        {/*<p className="lead mt-4">We will send you an email informing the sign up process</p>*/}
      </div>
    );

  return content;
};

function mapStateToProps(state, ownProps) {
  return {
    isAuthenicated: state.auth.isAuthenicated,
    role: state.auth.user ? state.auth.user.role : "",
    isWaitlisted: state.auth.user.isWaitlisted,
  };
}

export default connect(mapStateToProps)(WaitlistThanks);
