import React from "react";
import { Container, Row, Col } from "../UI/Grid/Grid";
import Footer from "../Footer/Footer";
import "../../styles/banner.css";
import Hero from "../Landing/hero";

const Careers = () => (
  <>
    <div className="public-page">
    <Hero prelim="Help Us" noButton={true}/>

    <Row className="title-banner bg-light-red-orange m-0">
      <Container>
        <h1 className="heading1 mb-0 text-left text-magenta-pink">Careers</h1>
      </Container>
    </Row>

{/* <div className="banner" style={{background: "#ECF2F9"}}>
    <Container className="text-center">
      <h3 className="heading3 text-viridian-green" style={{marginBottom: "1rem"}}>Our Mission</h3>
        <p style={{width: "75%", margin: "auto"}}>
          Reduce medical harm from miscommunication and
          build a future where everyone can obtain high-quality, life-saving
          care.
        </p>
    </Container>
</div> */}


    <Container className="pb-4">
      <Row
        className="my-3 mx-0"
      >
        <Col className="col-7 col-md-6 my-4">
          <div className="subtitle text-viridian-green">The Opportunity</div>
          <p>
            Come join an exciting, early-stage startup that is improving
            equitable access to high quality healthcare for underserved
            populations.
          </p>

          <div className="subtitle text-viridian-green">The Problem</div>
          <p>
            In the U.S., 67.3 million U.S. residents speak a non-English
            language as their primary language. Additionally, almost 120 million
            people, or 36% of the population, have low health literacy, which
            disproportionately affects both racial and ethnic minority groups
            and low-income households. LiteraSeed is focusing on a major,
            overlooked problem within the U.S. healthcare system that is also
            translatable globally.
          </p>

          <div className="subtitle text-viridian-green">The Benefits</div>
          <p>
            We are a small team looking to make a HUGE impact on an overlooked,
            systemic problem in healthcare. You'll be working remotely from home
            with flexible hours, have an amazing opportunity for career growth,
            and working alongside fun-loving teammates.
            <br /> <br /> 
            <ul style={{ listStyleType: "disc", paddingLeft: "35px" }}>
              <li>Small team, huge impact</li>
              <li>Remote / work from home</li>
              <li>Flexible hours</li>
              <li>Career growth</li>
              <li>Work alongside fun-loving teammates</li>
            </ul>
          </p>

          <div className="subtitle text-viridian-green">Get in Touch</div>
          <p>jobs@literaseed.io</p>
        </Col>
        <Col className="col-8 col-md-4 my-4 ml-5">
          <div className="subtitle text-viridian-green">Job Listings</div>
          <p>Please check back later...</p>
        </Col>
      </Row>
    </Container>
    </div>
    <Footer />
  </>
);

export default Careers;

/*
// OLD SOFTWARE ENGINEER LISTING
<Row className="justify-content-center mt-5 text-center mx-0">
        <Col className="col-12 ">
          <h2 className="font-weight-bold">
            Founding Full Stack Software Engineer
          </h2>
          <p className="caps">Remote / Engineering / Full-Time / Part-Time</p>
          <p>
            <strong>Start Date:</strong> June 1, 2021 to July 1, 2021
          </p>
        </Col>
      </Row>

      <Row className="my-4 mx-0">
        <h2 className="heading2 text-viridian-green">The Opportunity</h2>
        <p>
          Come join an exciting early-stage startup that is improving equitable
          access to high-quality healthcare for underserved populations. At
          LiteraSeed, we’re looking for our first hire, a software engineer to
          join our founding team!
        </p>
        <p>
          <strong>Mission: </strong>
          We are advancing health equity for the most vulnerable by improving
          communication between patients and doctors. LiteraSeed strongly
          believes that nobody should be denied high-quality healthcare because
          of a communication barrier.
        </p>
        <p>
          <strong>Traction: </strong>
          We’ve been receiving an OUTPOURING of interest over the past few
          weeks, with multiple hospitals and clinics in the pipeline awaiting
          access. We need immediate help building and scaling our product! Check
          out our{" "}
          <a
            href={"https://literaseed.io/press"}
            className="text-viridian-green"
          >
            <strong>Press page</strong>
          </a>{" "}
          to see some of the coverage we’ve been receiving.
        </p>
        <p>
          <strong>About: </strong>
          LiteraSeed is a pre-seed startup with an early product and an active
          pilot with one of the nation's largest safety-net healthcare systems.
          We are building a visuals-based communication platform to bridge the
          gap and build trust. Specifically, our technology will make it easier
          for patients with low literacy to capture symptoms and discuss those
          symptoms with their doctor(s).
        </p>
        <p>
          <strong>The Problem: </strong>
          In the U.S., 67.3 million U.S. residents speak a non-English language
          as their primary language. Additionally, almost 120 million people, or
          36% of the population, have low health literacy, which
          disproportionately affects both racial and ethnic minority groups and
          low-income households. LiteraSeed is focusing on a major, overlooked
          problem in the U.S. healthcare system that is also translatable
          globally.
        </p>
      </Row>
      <Row className="mt-5 mx-0">
        <h2 className="heading2 text-viridian-green">The Role</h2>
        <p>
          This is an opportunity to be the first engineer on the LiteraSeed team
          and to build the company’s tech stack, hire, and build the engineering
          team from the ground up. If you are someone with a passion to help us
          quickly build and iterate products to reach key launch milestones for
          hospitals and clinic clients, we’d love to talk to you!
        </p>
        <p>
          We are a small team looking to make a HUGE impact on an overlooked,
          systemic problem in healthcare. You’ll be working remote from home
          with flexible hours, have an amazing opportunity for career growth,
          and working alongside fun-loving teammates.
        </p>
      </Row>
      <Row className=" my-4 mx-0">
        <h3 className="font-weight-bold">Day-to-Day Responsibilities: </h3>
        <ul className="list">
          <li className="list-li">
            Work closely and regularly communicate with the co-founders.
          </li>
          <li className="list-li">
            Quickly iterate and build upon/enhance the existing product per user
            and customer feedback.
          </li>
          <li className="list-li">
            Work within the existing HIPAA compliant framework, which may
            include web and mobile technologies and integration within clinical
            workflows.
          </li>
        </ul>
      </Row>
      <Row className=" my-4 mx-0">
        <h3 className="font-weight-bold">Minimum Requirements: </h3>
        <ul className="list">
          <li className="list-li">
            Ability to execute fast and launch quickly while maintaining an
            agile software development flow.
          </li>
          <li className="list-li">
            Ability to work with subject matter experts and lead technical,
            design, and data science teams in building a simple, yet powerful
            UX/UI driven by ML algorithms on the backend.
          </li>
          <li className="list-li">
            Excellent communication skills to keep everyone in sync on progress
            across working teams.
          </li>
          <li className="list-li">
            Ability to multitask and manage multiple project deadlines, while
            keeping a clear and strategic focus on product development.
          </li>
          <li className="list-li">
            Current technologies used include React.JS, Node, and Firebase.
          </li>
        </ul>
      </Row>
      <Row className="my-4 mx-0 flex-column">
        <h3 className="font-weight-bold">Nice-to-Haves: </h3>
        <ul className="list">
          <li className="list-li">
            Software engineer who can grow into a managerial position and grow a
            team.
          </li>
          <li className="list-li">
            Must be comfortable with taking risks and have a strong
            determination for success.
          </li>
          <li className="list-li">Social impact-driven.</li>
          <li className="list-li">2+ years software engineering experience.</li>
          <li className="list-li">
            Works autonomously, takes ownership, and is willing to dig in and
            run with hard problems.
          </li>
        </ul>
      </Row>
      <Row className="spacer2 justify-content-center text-center mx-0">
        <h2 className="heading2 text-viridian-green ">
          To apply, please reach out to Aziza and John at{" "}
          <a className="link-underline" href="mailto:jobs@literaseed.io">
            jobs@literaseed.io
          </a>
        </h2>
      </Row>
      */
