import React from "react";
import { Col, ContainerFluid, Row } from "../UI/Grid/Grid";
import "../../styles/banner.css";
import "../../styles/button.css";
import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";
// import * as ROUTES from "../../constants/routes";

const Banner = () => (
  <section id="banner">
    <div className="banner banner-signup" style={{background: "#ECF2F9", padding: "30px 0"}}>
    {/* stroke: "#CCCCCC", strokeWidth: "0.25" */}
      <form
        action="https://literaseed.us15.list-manage.com/subscribe/post?u=75046940797701e74224deadf&amp;id=161a3e380a"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <Row className="align-items-center m-0">
        <Col className="col-sm-12 col-lg-4 mx-auto">
          <h3 className="col-sm-12 col-lg-12 mr-auto text-magenta-pink no-margin-md">
            Subscribe to our newsletter!
          </h3>
        </Col>
        <Col className="col-12 col-lg-8 col-sm-12 mx-auto">
          <Row>
            <Col className="col-12 col-lg-8 col-sm-12">
              <input
                type="email"
                name="EMAIL"
                className="form-control"
                id="mce-EMAIL"
                placeholder="email address"
                required
                style={{
                  borderRadius: "4px",
                  boxShadow: "0 3px 2px rgba(0, 0, 0, 0.3)"
                }}/>
            </Col>
            <Col className="col-4 text-left" style={{margin: "auto"}}>
              <input
                type="submit"
                value="Sign up"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button btn btn-bottom"
                style={{
                  color: "white",
                  fontSize: "16px",
                  backgroundColor: "#E14798",
                  boxShadow: "0 3px 2px rgba(0, 0, 0, 0.3)"
                }}/>
            </Col>
          </Row>
        </Col>
        </Row>
        {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
          >
          <input
            type="text"
            name="b_75046940797701e74224deadf_161a3e380a"
            tabIndex="-1"
            defaultValue=""
            style={{}}
            />
        </div>
      </form>
    </div>
  </section>
);

export default Banner;
