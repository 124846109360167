import React from "react";
import { Container, Row, Col } from "../UI/Grid/Grid";
import Footer from "../Footer/Footer";

const FAQ = () => (
  <>
    <Container className="my-5 ">
      <Row className="justify-content-center">
        <Col className="col-12 col-lg-9">
          <h2 className="h1 text-viridian-green text-center mb-5 pb-5">FAQ</h2>

          <Row className="my-4">
            <Col>
              <h3 className="heading3 text-viridian-green">
                How can I try LiteraSeed?
              </h3>

              <p>
                At this time we are in private beta with a clinical partner. You
                may sign up for our{" "}
                <a
                  href="/waitlist"
                  className="text-viridian-green link-underline font-weight-bold"
                >
                  waitlist
                </a>{" "}
                once we're ready to launch to the public.
              </p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <h3 className="heading3 text-viridian-green">
                How can I get my provider to use LiteraSeed?
              </h3>
              <p>
                Please direct your provider to{" "}
                <a
                  href="/contact"
                  className="text-viridian-green link-underline font-weight-bold"
                >
                  contact us
                </a>.
              </p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <h3 className="heading3 text-viridian-green">
                How can I share my report with my provider?
              </h3>
              <ul className="list" style={{fontSize: "1rem"}}>
                <li>
                  If you are with a partnering healthcare organization, the report
                  is shared with your medical provider automatically after you
                  input your information.
                </li>
                <li>
                  If you are NOT with a partnering healthcare organization, you
                  can print the report and share it with your medical provider
                  during your scheduled appointment.
                </li>
                <li>
                  You may also choose to simply use the report as a guide to help you
                  prepare for your visit without sharing it directly with
                  your medical provider.
                </li>
              </ul>
              <p>
                LiteraSeed is in the earliest stages of development. While we are
                confident and excited in the product, each provider may choose to
                use or interpret the results differently.
              </p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <h3 className="heading3 text-viridian-green">
                Are you HIPAA compliant?
              </h3>
              <p>Yes, we are.</p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <h3 className="heading3 text-viridian-green">
                What languages do you support?
              </h3>
              <p>
                All of our content is available in English. In addition, our core products
                are available in Arabic, Swahili, Burmese, and Spanish, with more
                languages and combinations on the way.
              </p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <h3 className="heading3 text-viridian-green">
                Why is this a safe, credible app for my sensitive health
                information?
              </h3>
              <p>
                LiteraSeed was developed under the gudiance of medical
                professionals.
              </p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <h3 className="heading3 text-viridian-green">
                What is LiteraSeed’s Privacy Policy?
              </h3>
              <p>
                Privacy is important to us. View our{" "}
                <a
                  href="/privacynotice"
                  className="text-viridian-green link-underline font-weight-bold"
                >
                  Privacy Policy
                </a>{" "}
                here.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    <Footer />
  </>
);

export default FAQ;
