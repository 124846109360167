import * as actionTypes from '../actions/actionTypes';

const initialState = {
	isLoading: false,
	isError: false,
	isWaitlist: false,
	error: {}
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.WAITLIST_START:
			return {
				...state,
				isLoading: true,
				isWaitlist: true,
				isError: false
			};
		case actionTypes.WAITLIST_SUCCESS:
			return {
				...state,
				isLoading: false
			}
		case actionTypes.WAITLIST_FAIL:
			return {
        ...state,
        isLoading: false,
        isError: true,
        isWaitlist: false,
        error: action.error
			};
		default:
			return state;
	}
};

export default reducer;