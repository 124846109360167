import React from "react";
import { Container, Row, Col } from "../UI/Grid/Grid";
import { FaFacebook, FaLinkedinIn, FaTwitter } from "react-icons/fa";
// import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";

const Footer = () => (
  <section id="footer" className="bg-dark-cyan basic-shadow">
    <Container className="pt-5" style={{width: "85%"}}>
      <Row className="mb-5">
        <Col className="col-md-6">
        <img
          src="/images/ltsd_light.svg"
          width="132"
          height=""
          className="d-inline-block"
          alt="LiteraSeed logo"
        />
          <p className="text-white mb-0 mt-2">
            Copyright &copy; {new Date().getFullYear()} LiteraSeed&trade;, Inc. All rights reserved.
          </p>
          {/* <p className="text-white">
            <Link to={ROUTES.CAREERS} className={`nav-link`}>
                Careers
            </Link>
            {" | "}
            <Link to={ROUTES.CONTACT} className={`nav-link`}>
                Contact Us
            </Link>
          </p> */}
        </Col>
        <Col className="col-md-6" style={{paddingRight: "1.5rem"}}>
          <img src="/images/hipaa_logo_white.png" className="centered-img"/>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-md-8 text-center ">
        </Col>
      </Row>
    </Container>
  </section>
);

export default Footer;
