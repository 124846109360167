import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ADMIN } from "../../constants/routes";
import { ScreenerNames, ScreenerShortNames } from "../../constants/screenings";
import { updateOrganizationSettings } from "../../store/actions";
import { getUserOrganization } from "../../store/slices/admin";
import "../../styles/homepage.css";
import { Col, Row } from '../UI/Grid/Grid';
import Input from "../UI/Input/Input";





const OrganizationDetails = (props) => {
  const masterOrg = useSelector(s => s.auth.organization || {});
  const orgId = props.match?.params?.orgId;
  const org = useSelector(s => s.admin.organizations?.[orgId]);
  const [attempted, setAttempted] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (orgId && !attempted[orgId]) {
      dispatch(getUserOrganization(orgId));
      setAttempted({...attempted, [orgId]: "loading"})
    }
  }, [orgId])

  return (
    <div className="container">
    <Row className="py-4 justify-content-center">
      <Col lg="6">
        <div className="mb-2">
          {/* <Link to={ADMIN} className="btn btn-outline">← Back to Admin Home</Link> */}
        </div>
        <span className="badge badge-light float-right">{orgId}</span>
        <h1 title={orgId}>Organization Details</h1>
        {org ? <>
        <table className="table">
          <tbody>
            <tr>
              <th>Name</th>
              <td colSpan={2}>{org.name}</td>
            </tr>
            <tr>
              <th>Logo</th>
              <td>{org.logoURL}</td>
              <td><img style={{maxHeight: "40px"}} src={org.logoURL} /></td>
            </tr>
            <tr>
              <th>Timezone</th>
              <td>{org.timezone?.name || "N/A"}</td>
              <td>{org.timezone?.offsets ? <ul>
                <li><strong>std offset:</strong> {org.timezone.offsets[0]}hr</li>
                <li><strong>dst offset:</strong> {org.timezone.offsets[1]}hr</li>
              </ul> : "N/A"}</td>
            </tr>
            <tr>
              <th>Enrollable Reports</th>
              <td colSpan={2}><ul>
                {org.allowedScreeners?.map(s => <li>
                  <span className="badge badge-dark">{s}</span>
                  {" "}
                  {ScreenerShortNames[s]}
                  </li>)}
              </ul></td>
            </tr>
            <tr>
              <th>Auth Types</th>
              <td colSpan={2}>
                {org.allowedCredentialTypes ?
                  <ul>
                    {org.allowedCredentialTypes?.map(act => <li>
                      <span className="badge badge-dark">{act}</span>
                    </li>)}
                  </ul>
                : <em>Not set (will apply <span className="badge badge-dark">name+DOB</span>)</em>}
              </td>
            </tr>
            <tr>
              <th>Message Templates</th>
              <td colSpan={2}>
                {/* <h3>Presets</h3> */}
                {org.templates ?
                  <ul>
                    {Object.entries(org.templates).map(([kind, temp]) => <li>
                      <em>{kind}:</em> <span className="badge badge-light">{temp}</span>
                    </li>)}
                  </ul> : "None set"}
              </td>
            </tr>
            <tr>
              <th>Custom Messages <span className={`badge badge-${org.customMessages?.enabled ? "success" : "warning"}`}>{org.customMessages?.enabled ? "enabled" : "disabled"}</span></th>
              <td colSpan={2}>
                <h3>Presets</h3>
                {org.customMessages?.presets ?
                  <ul>
                    {Object.entries(org.customMessages.presets).map(([name, message]) => <li>
                      <span className="badge badge-light">{name}:</span> {message}
                    </li>)}
                  </ul> : "None set"}
              </td>
            </tr>
            <tr><th colSpan={3}>Settings</th></tr>
            {Object.entries(org.settings).map(([setting, value]) =>
              <tr>
                <td>{setting}</td>
                <td colSpan={2}><tt>{JSON.stringify(value)}</tt></td>
              </tr>
            )}
            <tr>
              <th>Managing Users</th>
              <td colSpan={2}>
                <ul>
                  {org.adminUsers?.map(au =>
                    <li><span className="badge badge-light">{au}</span></li>
                  )}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <br/>
        {/* <h2>Modify...</h2> */}
        </> : <h3 className="text-danger">{orgId ? attempted[orgId] : `Could not load ID: ${orgId}`}</h3>}
      </Col>
    </Row>
  </div>
  );
}
export default OrganizationDetails;
