import { alertEvent } from '../../components/UI/GlobalAlerts';
import { debugTweakAccessKey, getClinicianColleagues, getUsersForProvider, recordDeviceFingerprint, sessionExpired, updateAccessKeyDetail, updateOrganizationSettings } from '../actions';
import * as actionTypes from '../actions/actionTypes';
import { getScreeningsByManager } from '../slices/screenings';

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  isEmailLink: false,
  isError: false,
  error: {},
  user: {},
  loginTime: null,
  verifiedPatient: {},
  confirmedDetails: false,
  accessKey: null,
  organization: {},
  provider: {},
  lastActivity: null
};

const NON_REFRESHING_ACTIONS = [
  getUsersForProvider.pending,
  getUsersForProvider.rejected,
  getUsersForProvider.fulfilled,
  getScreeningsByManager.pending,
  getScreeningsByManager.rejected,
  getScreeningsByManager.fulfilled,
];

const SYSTEM_TIMEOUT_LIMITS = {
  inactivity: 8*60,
  maximum: 24*60
}

let lastRanTimeoutCheck = 0;
const MINUTE = 60000;
export const sessionTimeoutMiddleware = store => next => action => {
  const now = Date.now();
  // limit our checks to once a minute... they aren't terribly costly, but there
  // really isn't a need to check at a greater frequency
  if (now - lastRanTimeoutCheck > MINUTE) {
    lastRanTimeoutCheck = now;
    const authState = store.getState().auth;
    const inactivity = Math.min(SYSTEM_TIMEOUT_LIMITS.inactivity, (authState.organization?.settings?.inactiveSessionTimeout || Number.MAX_SAFE_INTEGER));
    const maximum = Math.min(SYSTEM_TIMEOUT_LIMITS.maximum, (authState.organization?.settings?.maximumSessionTimeout || Number.MAX_SAFE_INTEGER));
    const deadline = Math.min(inactivity * MINUTE + authState.lastActivity, authState.loginTime !== null ? (maximum * MINUTE + authState.loginTime) : Number.MAX_SAFE_INTEGER);
    if (deadline < now) {
      next(sessionExpired());
      next(alertEvent("You've been logged out, either due to inactivity or for hitting the overall session limit"));
      return new Error("Session timeout reached!");
    } else {
      console.log("Session has not hit timeout limits, current expected timeout is "+new Date(deadline));
    }
  }
  return next(action);
}

const reducer = ( state = initialState, action ) => {
  if (!NON_REFRESHING_ACTIONS.includes(action.type)) {
    // this is considered an action for the purposes of extending the session
    // timeout limit
    state = {...state, lastActivity: Date.now()};
  }
  switch (action.type) {
    // case actionTypes.SIGNUP_START:
    //   return {
    //     ...state,
    //     isAuthenticated: false,
    //     isLoading: true,
    //     isVerified: false,
    //     isError: false,
    //     error: {}
    //   };
    // case actionTypes.SIGNUP_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     isAuthenticated: true,
    //     isEmailLink: false,
    //     // TODO: no need to check it
    //     isEmailVerifySent: true,
    //     user: {
    //       ...state.user,
    //       ...action.user,
    //       isAnonymous: false,
    //     }
    //   };
    // case actionTypes.SIGNUP_FAIL:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     isAuthenticated: false,
    //     isError: true,
    //     error: action.error
    //   };

    case actionTypes.LOGIN_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: {}
      };
    case actionTypes.LOGIN_SUCCESS:{
      return {
        ...state,
        isAuthenticated: true,
        isEmailLink: false,
        isLoading: false,
        user: action.user,
        loginTime: Date.now()
      };
    }
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isError: true,
        error: action.error,
        loginTime: null
      };
    // case actionTypes.SEND_SIGNIN_LINK_TO_EMAIL_START:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     isError: false,
    //     error: {},
    //   };
    // case actionTypes.SEND_SIGNIN_LINK_TO_EMAIL_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false
    //   };

    //   case actionTypes.SEND_SIGNIN_LINK_TO_EMAIL_FAIL:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     isError: true,
    //     error: action.error
    //   };
    // case actionTypes.SIGNIN_EMAIL_LINK_START:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     isError: false,
    //     isAuthenticated: false,
    //     isEmailLink: true,
    //     error: {},
    //   };
    // case actionTypes.SIGNIN_EMAIL_LINK_SUCCESS:
    //   return {
    //     ...state,
    //     isAuthenticated: true,
    //     isEmailLink: true,
    //     isVerified: true,
    //     isLoading: false,
    //     user: {
    //       ...state.user,
    //       ...action.data
    //     }
    //   };

    // case actionTypes.SIGNIN_EMAIL_LINK_FAIL:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     isAuthenticated: false,
    //     isEmailLink: false,
    //     isVerified: false,
    //     isError: true,
    //     error: action.error
    //   };
    case actionTypes.SIGNIN_ANONYMOUSLY_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isAuthenticated: false,
        error: {},
      };
    case actionTypes.SIGNIN_ANONYMOUSLY_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: {
          ...action.user,
          isAnonymous: true
        }
      };
    case actionTypes.SIGNIN_ANONYMOUSLY_FAIL:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isError: true,
        error: action.error
      };

    case actionTypes.LOGOUT_START:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case `${sessionExpired}`:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isEmailLink: false,
        user: {},
        signinTime: null
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isEmailLink: false,
        user: {},
        signinTime: null
      };
    case actionTypes.LOGOUT_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
      case actionTypes.AUTH_START:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        isError: false,
        error: {}
      };
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: {
          ...state.user,
          ...action.user
        },
        loginTime: Date.now()
      };
    case actionTypes.AUTH_FAIL:
      // if (action.error instanceof Error) {
      // }
      return {
        ...state,
        isLoading: false,
        error: `${action.error}`
      };
    case actionTypes.AUTH_RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: {}
      };
    case actionTypes.AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.AUTH_RESET_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case actionTypes.CHECK_PRE_CREATED_PATIENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isAuthenticated: false,
        error: {},
      };
    case actionTypes.CHECK_PRE_CREATED_PATIENT_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...action.payload.user,
          isAnonymous: true
        },
        verifiedPatient: {
          ...action.payload.user
        },
        isLoading: false,
      };
    case actionTypes.CHECK_PRE_CREATED_PATIENT_FAIL:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isError: true,
        error: action.error
      };
    case "access-key/AUTHENTICATED":
      return {
        ...state,
        accessKey: action.payload, 
      };
    case `${updateAccessKeyDetail.fulfilled}`:
      const updates = {t_updated: Date.now()};
      if (action.meta.arg?.language) {
        updates.language = action.meta.arg?.language;
        updates.chosenLanguage = action.meta.arg?.language;
      }
      if (action.meta.arg?.method) {
        updates.lastMethodUsed = action.meta.arg?.method;
      }
      if (action.meta.arg?.referrer) {
        updates.lastReferrer = action.meta.arg?.referrer;
      }
      return {
        ...state,
        accessKey: {...state.accessKey, ...updates}
      }
    case `${debugTweakAccessKey}`:
      return {
        ...state,
        accessKey: {...state.accessKey, ...action.payload}
      }
    case "user/PROVIDER-FOUND":
      return {
        ...state,
        provider: action.payload,
      };
    case "organization/FOUND":
      const isManager = action.payload.adminUsers?.includes(state.user.uid);
      return {
        ...state,
        organization: {users: [], ...action.payload},
        user: {...state.user, isManager}
      };
    case `${getClinicianColleagues.fulfilled}`:
      if (action.meta.arg !== state.organization.id) return state;
      return {
        ...state,
        organization: {...state.organization, users: action.payload.filter(u => u.active !== false).sort((a, b) => (a.name || "").localeCompare(b.name || ""))}
      };
    case `${updateOrganizationSettings.fulfilled}`:
      return {
        ...state,
        organization: {...state.organization, settings: action.meta.arg.updates}
      };
    case "auth-details/CONFIRM-CREDENTIALS-LOCALLY":
      return {
        ...state,
        confirmedDetails: true
      }
    case "auth-details/BEGIN-ON-SITE-FLOW":
      return {
        ...state,
        confirmedDetails: true
      }
    case `${recordDeviceFingerprint.fulfilled}`:
      return {
        ...state,
        sessionDataId: action.payload
      }
    default:
      return state;
  }
};

export default reducer;
