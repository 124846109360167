

export function sanitizeEmptyValues (answers) {
  return Object.fromEntries(Object.entries(answers).filter(([key, answer]) => {
    const using = (answer.answered && answer.value !== undefined && answer.value !== null) || Number.isInteger(answer.skipReason);
    if (!using) {
      console.error("Skipping submission of "+key+" due to unanswered or null-, or undefined-answer (and no skipReason)");
      console.warn(answer);
    }
    return using;
  }).map(([key, answer]) => {
    const cleanAnswer = {...answer};
    const newOptions = {};
    if (Array.isArray(answer.selected)) {
      for (let index of answer.selected) {
        const option = answer.options?.[index];
        if (option) {
          if (Number.isInteger(option.key)) {
            newOptions[index] = {key: option.key};
            continue;
          }
          if (option.value !== undefined && option.value !== null) {
            newOptions[index] = {value: option.value};
          } else if ("value" in option) {
            console.error(`Found nullish value in ${key} at index ${index} - stringifying for DB storage!`);
            newOptions[index] = {value: `${option.value}`};
          }
        } else {
          // TODO: some modes of skip enforcement should probably be preventing this case
          console.error(`Found index in ${key}.selected (${index}) with no matching option`);
        }
      }
    } else {
      console.error(`Selected was not an array in answer ${key}: ${answer.selected}`);
      cleanAnswer.selected = [];
    }
    cleanAnswer.options = newOptions;
    return [key, cleanAnswer];
  }));
}