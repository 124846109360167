import React from "react";
import { Row, Col } from "../UI/Grid/Grid";

const Results = () => (
  <>
    <Row className="bg-light-purple title-banner">
      <div className="text-white">Our Results</div>
    </Row>

    <Row>
      <Col>
        <h2>Why Use LiteraSeed?</h2>
        <p>
          By capturing symptoms data accurately from patients early in the
          progression of an illness, we can support doctors in making timely,
          accurate diagnoses that can save lives.
        </p>
        <p>
          LiteraSeed uses simple language, graphics, and audio to help patients
          provide a full picture of their health condition and symptoms. We
          offer a visual-based screening app initially for a more efficient and
          effective assessment of a patient's symptoms prior to the medical
          visit.
        </p>
      </Col>

      <Col>
        <img src="" />
      </Col>
    </Row>

    <Row>
      <div>
        <h3>Patients</h3>
        <p>Feel more comfortable and confident in their providers.</p>
        <p>Seek medical care earlier, including preventive care.</p>
        <p>Improve health outcomes and quality of life.</p>
      </div>
      <div>
        <h3>Medical Providers</h3>
        <p>
          Be more prepared for patient visits with timely, accurate information.
        </p>
        <p>
          Increase productivity and time efficiency during clinical
          consultations.
        </p>
        <p>Decrease post-visit time and effort charting.</p>
      </div>
      <div>
        <h3>Healthcare Admins</h3>
        <p>Reduce risk of medical errors.</p>
        <p>
          Improve operating efficiency by reducing unnecessary slowdowns in
          workflow.
        </p>
        <p>Reduce preventable inpatient and emergency admissions.</p>
      </div>
      {/* <Card></Card>
      <Card></Card>
      <Card></Card> */}
    </Row>
    <h2>2 out of 3 providers rate LiteraSeed a 4 out of 5 or higher.</h2>

    <div>
      <div className="subtitle">
        “LiteraSeed alerted me to a recent COVID infection and facilitated
        dialogue about recovery.”
      </div>
      <p>Preparedness Rating: 5/5</p>
    </div>

    <div>
      <div className="subtitle">
        “A 60+ y/o non-English speaking, refugee patient who came to have her
        results read to her was discovered by LiteraSeed to have 3 symptoms,
        which could have been missed.”
      </div>
      <p>Preparedness Rating: 4/5</p>
    </div>

    <div>
      <div className="subtitle">
        “The LiteraSeed report was more specific than the reason for
        appointment, which stated “abdominal pain” and was not accurate as the
        patient had chest pain.”
      </div>
      <p>Preparedness Rating: 4/5</p>
    </div>

    <div>
      <Row>
        <h2>
          Early studies indicate LiteraSeed detects more symptoms in 20% of
          patient cases that could lead to misdiagnosis or underdiagnosis.
        </h2>
        <div>
          *Preliminary results (n=30) of a 300-patient study with non-and
          mixed-literate patients at Valleywise Health Refugee Women's Health
          Clinic.
        </div>
      </Row>

      <Row>
        <h2>
          On average for every 4 patients, LiteraSeed identifies 1 more symptom
          than the provider.
        </h2>
      </Row>

      <Row>
        <Col>
          LiteraSeed identified more symptoms in 20% of cases (6 out of 30,
          averaging 2.7 more symptoms per patient)
        </Col>
        <Col>The same symptoms in 53.3% of cases (16 out of 30 cases)</Col>
        <Col>
          Less symptoms in 26.7% of cases (8 out of 30, averaging 1 less symptom
          per patient)
        </Col>
      </Row>
    </div>
  </>
);

export default Results;
