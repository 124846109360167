import { RecentActorsOutlined, SwapVerticalCircleTwoTone } from "@material-ui/icons";
import * as d3 from "d3";
import moment from "moment";
import { createRef, React, useEffect, useMemo, useRef, useState } from "react";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaCalendar, FaChartArea,
  FaChartBar, FaCog, FaCompressArrowsAlt, FaEnvelope, FaExclamationTriangle, FaExpandArrowsAlt, FaEyeSlash, FaFileContract, FaFolderOpen, FaList, FaRegEye, FaRegWindowRestore, FaTable
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Language } from "../../constants/locales";
import { PROVIDER_PATIENT_DETAILS } from "../../constants/routes";
import { ScreenerNames, ScreeningType } from "../../constants/screenings";
import { ScreeningStatus, ScreeningStatusHR } from "../../database/model/user";
import { getReportsByOrganization, getReportsByRequester, getUserAlertsByOrganization, getUserScores, updateUserAlert } from "../../store/actions";
import { getQuestionnaireByTypeAndKind, loadOrLocalQuestionnaire, requestQuestionnaire } from "../../store/slices/questionnaire";
import { getAllScreenings, getScreeningsByOrganization } from "../../store/slices/screenings";
import { capitalizeFirstLetter, ordinals, snakeToLowercase } from "../../utils";
import CustomCalendar from "../UI/Calendar/Calendar";
import { Card, CardBody, CardHeader, CardText } from "../UI/Card/Card";
import { snack } from "../UI/GlobalAlerts";
import { Col, Container, Row } from "../UI/Grid/Grid";

function appointmentDateString (screening) {
  if (screening.t_appointment){
    return moment(screening.t_appointment).format("M/D/YY H:MMa");
  }
  if (screening.d_appointment) {
    return moment(screening.d_appointment).format("M/D/YY (no time)");
  }
  return "no appt data";
}

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/histogram
function Histogram(data, {
  value = d => d, // convenience alias for x
  domain, // convenience alias for xDomain
  label, // convenience alias for xLabel
  format, // convenience alias for xFormat
  type = d3.scaleLinear, // convenience alias for xType
  x = value, // given d in data, returns the (quantitative) x-value
  y = () => 1, // given d in data, returns the (quantitative) weight
  thresholds = 40, // approximate number of bins to generate, or threshold function
  normalize, // whether to normalize values to a total of 100%
  marginTop = 20, // top margin, in pixels
  marginRight = 30, // right margin, in pixels
  marginBottom = 30, // bottom margin, in pixels
  marginLeft = 40, // left margin, in pixels
  width = 640, // outer width of chart, in pixels
  height = 400, // outer height of chart, in pixels
  insetLeft = 0.5, // inset left edge of bar
  insetRight = 0.5, // inset right edge of bar
  xType = type, // type of x-scale
  xDomain = domain, // [xmin, xmax]
  xRange = [marginLeft, width - marginRight], // [left, right]
  xLabel = label, // a label for the x-axis
  xFormat = format, // a format specifier string for the x-axis
  yType = d3.scaleLinear, // type of y-scale
  yDomain, // [ymin, ymax]
  yRange = [height - marginBottom, marginTop], // [bottom, top]
  yLabel = "↑ Frequency", // a label for the y-axis
  yFormat = normalize ? "%" : undefined, // a format specifier string for the y-axis
  color = "currentColor" // bar fill color
} = {}) {
  // Compute values.
  const X = d3.map(data, x);
  const Y0 = d3.map(data, y);
  const I = d3.range(X.length);

  // Compute bins.
  const bins = d3.bin().thresholds(thresholds).value(i => X[i])(I);
  const Y = Array.from(bins, I => d3.sum(I, i => Y0[i]));
  if (normalize) {
    const total = d3.sum(Y);
    for (let i = 0; i < Y.length; ++i) Y[i] /= total;
  }

  // Compute default domains.
  if (xDomain === undefined) xDomain = [bins[0].x0, bins[bins.length - 1].x1];
  if (yDomain === undefined) yDomain = [0, d3.max(Y)];

  // Construct scales and axes.
  const xScale = xType(xDomain, xRange);
  const yScale = yType(yDomain, yRange);
  const xAxis = d3.axisBottom(xScale).ticks(width / 80, xFormat).tickSizeOuter(0);
  const yAxis = d3.axisLeft(yScale).ticks(height / 40, yFormat);
  yFormat = yScale.tickFormat(100, yFormat);

  const svg = d3.create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

  svg.append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(yAxis)
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll(".tick line").clone()
          .attr("x2", width - marginLeft - marginRight)
          .attr("stroke-opacity", 0.1))
      .call(g => g.append("text")
          .attr("x", -marginLeft)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .text(yLabel));

  svg.append("g")
      .attr("fill", color)
    .selectAll("rect")
    .data(bins)
    .join("rect")
      .attr("x", d => xScale(d.x0) + insetLeft)
      .attr("width", d => Math.max(0, xScale(d.x1) - xScale(d.x0) - insetLeft - insetRight))
      .attr("y", (d, i) => yScale(Y[i]))
      .attr("height", (d, i) => yScale(0) - yScale(Y[i]))
    .append("title")
      .text((d, i) => [`${d.x0} ≤ x < ${d.x1}`, yFormat(Y[i])].join("\n"));

  svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(xAxis)
      .call(g => g.append("text")
          .attr("x", width - marginRight)
          .attr("y", 27)
          .attr("fill", "currentColor")
          .attr("text-anchor", "end")
          .text(xLabel));

  return svg.node();
}

// from Observable
// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/stacked-area-chart
function StackedAreaChart(data, {
  x = ([x]) => x, // given d in data, returns the (ordinal) x-value
  y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
  z = () => 1, // given d in data, returns the (categorical) z-value
  marginTop = 20, // top margin, in pixels
  marginRight = 30, // right margin, in pixels
  marginBottom = 30, // bottom margin, in pixels
  marginLeft = 40, // left margin, in pixels
  margin,
  width = 640, // outer width, in pixels
  height = 400, // outer height, in pixels
  xType = d3.scaleUtc, // type of x-scale
  xDomain, // [xmin, xmax]
  xRange = [marginLeft, width - marginRight], // [left, right]
  yType = d3.scaleLinear, // type of y-scale
  yDomain, // [ymin, ymax]
  yRange = [height - marginBottom, marginTop], // [bottom, top]
  zDomain, // array of z-values
  offset = d3.stackOffsetDiverging, // stack offset method
  order = d3.stackOrderNone, // stack order method
  xFormat, // a format specifier string for the x-axis
  yFormat, // a format specifier for the y-axis
  yLabel, // a label for the y-axis
  colors = d3.schemeTableau10, // array of colors for z
  xTicks
} = {}) {
  if (Number.isSafeInteger(margin)) {
    marginTop = margin;
    marginRight = margin;
    marginBottom = margin;
    marginLeft = margin;
  }
  // Compute values.
  const X = d3.map(data, x);
  const Y = d3.map(data, y);
  const Z = d3.map(data, z);

  // Compute default x- and z-domains, and unique the z-domain.
  if (xDomain === undefined) xDomain = d3.extent(X);
  if (zDomain === undefined) zDomain = Z;
  zDomain = new d3.InternSet(zDomain);

  // Omit any data not present in the z-domain.
  const I = d3.range(X.length).filter(i => zDomain.has(Z[i]));

  // Compute a nested array of series where each series is [[y1, y2], [y1, y2],
  // [y1, y2], …] representing the y-extent of each stacked rect. In addition,
  // each tuple has an i (index) property so that we can refer back to the
  // original data point (data[i]). This code assumes that there is only one
  // data point for a given unique x- and z-value.
  const series = d3.stack()
      .keys(zDomain)
      .value(([x, I], z) => Y[I.get(z)])
      .order(order)
      .offset(offset)
    (d3.rollup(I, ([i]) => i, i => X[i], i => Z[i]))
    .map(s => s.map(d => Object.assign(d, {i: d.data[1].get(s.key)})));

  // Compute the default y-domain. Note: diverging stacks can be negative.
  if (yDomain === undefined) yDomain = d3.extent(series.flat(2));

  // Construct scales and axes.
  const xScale = xType(xDomain, xRange);
  const yScale = yType(yDomain, yRange);
  const color = d3.scaleOrdinal(zDomain, colors);
  if (typeof xTicks !== "function") {
    xTicks = axis => axis.ticks(width / 80, xFormat).tickSizeOuter(0);
  }
  const xAxis = xTicks(d3.axisBottom(xScale));
  const yAxis = d3.axisLeft(yScale).ticks(height / 50, yFormat);

  const area = d3.area()
      .x(({i}) => xScale(X[i]))
      .y0(([y1]) => yScale(y1))
      .y1(([, y2]) => yScale(y2));

  const svg = d3.create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

  svg.append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(yAxis)
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll(".tick line").clone()
          .attr("x2", width - marginLeft - marginRight)
          .attr("stroke-opacity", 0.1))
      .call(g => g.append("text")
          .attr("x", -marginLeft)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .text(yLabel));

  svg.append("g")
    .selectAll("path")
    .data(series)
    .join("path")
      .attr("fill", ([{i}]) => color(Z[i]))
      .attr("d", area)
    .append("title")
      .text(([{i}]) => Z[i]);

  svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(xAxis);

  return Object.assign(svg.node(), {scales: {color}});
}

function Swatches({color,
  columns = "100px",
  format,
  unknown: formatUnknown,
  swatchSize = 15,
  swatchWidth = swatchSize,
  swatchHeight = swatchSize,
  marginLeft = 0
}) {
  const unknown = formatUnknown == null ? undefined : color.unknown();
  const unknowns = unknown == null || unknown === d3.scaleImplicit ? [] : [unknown];
  const domain = color.domain().concat(unknowns);
  if (format === undefined) format = x => x === unknown ? formatUnknown : x;

  const itemStyle = {
    breakInside: "avoid",
    display: "flex",
    alignItems: "center",
    paddingBottom: "1px"
  };
  const swatchStyle = {
    width: `${+swatchWidth}px`,
    height: `${+swatchHeight}px`,
    margin: "0 0.5em 0 0"
  };
  const labelStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: `calc(100% - ${+swatchWidth}px - 0.5em)`
  }
  return <div style={`display: flex; align-items: center; margin-left: ${+marginLeft}px; min-height: 33px; font: 10px sans-serif;`}>
  <div style={{width: "100%", columns}}>{domain.map(value => {
    const label = `${format(value)}`;
    return <div style={itemStyle}>
      <div style={{...swatchStyle, background: color(value)}}></div>
      <div title={label} style={labelStyle}>{label}</div>
    </div>
  })}
  </div>
</div>;
}
const WIDGET_TYPES = {
  Funnel: "funnel_chart",
  UnitCounts: "unit_counts",
  ScoreStrata: "score_strata_table",
  AggregateAlerts: "alerts_all",
  LargestIncreases: "recent_score_increases",
  EmptyWidget: "empty"
}
const WIDGET_RINGS = [
  [WIDGET_TYPES.Funnel, WIDGET_TYPES.UnitCounts],
  [WIDGET_TYPES.ScoreStrata],
  [WIDGET_TYPES.AggregateAlerts, WIDGET_TYPES.LargestIncreases]
];
function nextInRing (i, current) {
  const ring = WIDGET_RINGS[i];
  return ring[(ring.indexOf(current) + 1) % ring.length];
}

const AnalyticsDashboard = () => {
  // const [expandCard, setExpandCard] = useState(null);
  const [currentWidgets, setCurrentWidgets] = useState([
    {view: WIDGET_RINGS[0][0], expanded: false, containerRef: createRef()},
    {view: WIDGET_RINGS[1][0], expanded: false},
    {view: WIDGET_RINGS[2][0], expanded: false}
  ]);
  function nextWidgetView (index, target) {
    if (!ordinals(3).includes(index)) return;
    const newWidget = currentWidgets.slice();
    const nextWidget = (Number.isSafeInteger(target) && target >= 0 && target < WIDGET_RINGS[index].length) ?
      (WIDGET_RINGS[index][target]) :
      (nextInRing(index, newWidget[index].view));
    newWidget[index] = {...newWidget[index], view: nextWidget};
    setCurrentWidgets(newWidget);
  }
  const [timeWindow, setTimeWindow_raw] = useState({start: 0, end: moment().valueOf(), unit: "ALL"});
  const organization = useSelector(s => s.auth.organization);
  const screenings = useSelector(getAllScreenings);
  const reports = useSelector(s => s.provider.reports);
  const scoresWindowed = useMemo(() => {
    if (!Array.isArray(reports)) return [];
    const scores = reports.flatMap(r => {
      if (!r.REL_scores) return [];
      return r.REL_scores.filter(s => s.t_generated >= timeWindow.start && s.t_generated < timeWindow.end).map(x => ({...x, userId: r.userId}));
    });
    return scores;
  }, [reports, timeWindow])
  const alerts = useSelector(s => s.provider.alerts);
  function severitySort (a, z) {
    const d = a?.severity - z?.severity;
    return (d === 0) ? (a?.value - z?.value) : d;
  }
  function setAlertActive (alertId, value) {
    dispatch(updateUserAlert({alertId, changes: {active: value}})).then(req => {
      if (req.meta.requestStatus === "fulfilled") {
        dispatch(snack(`Alert ${alertId.slice(0, 5)} was marked ${value ? "active" : "inactive"}`))
      } else {
        dispatch(snack(`Unknown error attempting to update alert ${alertId}`, undefined, {style: "error"}));
        console.error({alertResult: req});
      }
    });

  }
  const userNameMap = useMemo(() => {
    if (Array.isArray(screenings)) {
      const map = {};
      screenings.forEach(s => {
        if (!(s.userId in map)) {
          map[s.userId] = s.userLastName;
        }
      });
      return map;
    }
    return ({});
  }, [screenings]);
  const alertsGrouped = useMemo(() => {
    const sorted = alerts.filter(s => s.t_generated >= timeWindow.start && s.t_generated < timeWindow.end).sort((a, z) => severitySort(z, a));
    const groupsFound = [];
    sorted.forEach(a => {
      // note we aren't using a classic functional set-logic approach here
      // because we very specifically want the group names in their original severity order
      if (groupsFound.indexOf(a.title) === -1) groupsFound.push(a.title);
    });
    return groupsFound.map(title => {
      const forGroup = sorted.filter(a => a.title === title);
      return ({
        title,
        severity: forGroup[0].severity,
        active: forGroup.filter(a => a.active),
        inactive: forGroup.filter(a => !a.active)
      });
    });

  }, [alerts, timeWindow]);
  const dispatch = useDispatch();

  const pastRequests = useRef(new Set());
  function setTimeWindow (x) {
    setTimeWindow_raw(x);
    // redrawWidget(0);
    // redrawWidget(1);
    // redrawWidget(2);
  }
  function setCustomWindow () {
    setTimeWindow_raw({...timeWindow, unit: "CUSTOM"});
  }
  function buildRange (unit, anchor) {
    return {start: moment(anchor).startOf(unit).valueOf(), end: moment(anchor).endOf(unit).valueOf(), unit};
  }
  const STEPPABLE_TIME_UNITS = ["year", "month", "week", "day"];
  function stepTimeWindow (forward = true) {
    if (!STEPPABLE_TIME_UNITS.includes(timeWindow.unit)) {
      return dispatch(snack(
        `Cannot move to ${forward ? "next" : "previous"} date range when unit type is ${timeWindow.unit}`,
        undefined,
        {style: "error"}));
    }
    const newAnchor = moment(forward ? timeWindow.end : timeWindow.start).add((forward ? 1 : -1), "minute");
    const range = buildRange(timeWindow.unit, newAnchor);
    setTimeWindow(range)
  }

  const managerNames = useMemo(() => {
    if (Array.isArray(organization?.users)) {
      const nameMap = Object.fromEntries(organization.users.map(u => ([u.id, u.name])));
      console.log({nameMap});
      return nameMap;
    } else {
      return {};
    }
  }, [organization])

  const requested = useMemo(() => {
    if (screenings?.length && organization.id) {
      const result = screenings.filter(s => (
        s.organizationId === organization.id &&
        [ScreeningStatus.InviteSent, ScreeningStatus.InviteOpened, ScreeningStatus.Started].includes(s.status) &&
        timeWindow.start <= s.t_created &&
        timeWindow.end > s.t_created
      ))
      .sort((a, b) => a.t_created - b.t_created);
      return result;
    }
    return [];
  }, [timeWindow, screenings, organization])
  const received = useMemo(() => {
    if (screenings?.length && organization.id) {
      const result = screenings.filter(s => (
        s.organizationId === organization.id &&
        [ScreeningStatus.ReportReady].includes(s.status) &&
        timeWindow.start <= s.t_created &&
        timeWindow.end > s.t_created
      ))
      .sort((a, b) => b.t_submitted - a.t_submitted);
      return result;
    }
    return [];
  }, [timeWindow, screenings, organization])

  useEffect(() => {
    if (organization.id && !pastRequests.current?.has?.(timeWindow.unit)) {
      dispatch(getScreeningsByOrganization({organizationId: organization.id, start: timeWindow.start, end: timeWindow.end, withMessages: true})); // TODO: switch to org-specific
      dispatch(getUserAlertsByOrganization({organizationId: organization.id, timeWindow, withScores: true}));
      dispatch(getReportsByOrganization({organizationId: organization.id, timeWindow, withScores: true}));
      pastRequests.current.add(timeWindow.unit);
    }
  }, [timeWindow, organization])

  const ANALYTICS_SCREENINGS = [ScreeningType.BECKSDEP, ScreeningType.ANXIETY];
  const qBecks = useSelector(s => getQuestionnaireByTypeAndKind(s, ScreeningType.BECKSDEP, {language: Language.English}));
  const qAnxiety = useSelector(s => getQuestionnaireByTypeAndKind(s, ScreeningType.BECKSDEP, {language: Language.English}));
  // const questionnaires = useSelector(s => analyticsScreenings.map(a => getQuestionnaireByTypeAndKind(s, a, {language: Language.English})));
  const questionnaires = useMemo(() => {
    return [qBecks, qAnxiety];
  }, [qBecks, qAnxiety]);
  const [qLoadAttempted, setQLoadAttempted] = useState(false);
  useEffect(() => {
    if (questionnaires.some(s => s === undefined) && !qLoadAttempted) {
      setQLoadAttempted(true);
      questionnaires.forEach((a, i) => {
        console.log("Requesting load of " + ANALYTICS_SCREENINGS[i]);
        if (a === undefined) {
          dispatch(requestQuestionnaire({type: ANALYTICS_SCREENINGS[i], locale: {language: Language.English}}));
        }
      });
    }
  }, [questionnaires, qLoadAttempted])

  function diceRandom (max, iter = 3) {
    let sum = 0;
    for (let i = 0; i < iter; i++) {
      sum += Math.random() * max;
    }
    return Math.floor(sum / iter);
  }
  function gradientBar (ratio) {
    let p = Math.floor(ratio * 1000) / 10;
    return `linear-gradient(90deg, #349499 ${p}%, rgba(0, 0, 0, 0.05) ${p+1}%)`
  }
  const [scoreRegenFlag, _setScoreRegenFlag] = useState(0);
  function regenRandomScores () {_setScoreRegenFlag(scoreRegenFlag + 1);}
  // const recentScores = useMemo(() => {
  //   // replace with loader once we decide where this goes
  //   // dispatch(getProviderRecentScores()) // ?
  //   const justNow = Date.now() - 1000;
  //   return ordinals(Math.floor(Math.random() * 12 + 18)).map(i => ({screener: "becksdpr", title: "Score", t_generated: justNow, type: "number", value: diceRandom(64 - i)}))
  // }, [scoreRegenFlag])
  const BANDS_TEMP = {
    [ScreeningType.BECKSDEP]: [
      {"min": 0, "max": 10, "label": "Normal"},
      {"min": 10, "max": 16, "label": "Mild"},
      {"min": 16, "max": 20, "label": "Borderline"},
      {"min": 20, "max": 30, "label": "Moderate"},
      {"min": 30, "max": 40, "label": "Severe"},
      {"min": 40, "max": Number.MAX_SAFE_INTEGER, "label": "Extreme"}
    ],
    [ScreeningType.ANXIETY]: [
      {"min": 0, "max": 10, "label": "Normal"},
      {"min": 10, "max": 16, "label": "Mild"},
      {"min": 16, "max": 20, "label": "Borderline"},
      {"min": 20, "max": 30, "label": "Moderate"},
      {"min": 30, "max": 40, "label": "Severe"},
      {"min": 40, "max": Number.MAX_SAFE_INTEGER, "label": "Extreme"}
    ],
  }

  function toggleTab (index) {
    const updatedWidgets = currentWidgets.slice();
    updatedWidgets[index] = {...updatedWidgets[index], expanded: !updatedWidgets[index].expanded}
    setCurrentWidgets(updatedWidgets);
  }
  function setTab (index, w) {
    const updatedWidgets = currentWidgets.slice();
    setCurrentWidgets({...updatedWidgets[index], expanded: !updatedWidgets[index].expanded});
  }

  const history = useHistory();
  function openPatientDetails (userId) {
    history.push(`${PROVIDER_PATIENT_DETAILS}/${userId}`);
  }

  const AnalyticsWidget = ({widget, index, toggleExpand, cycleView}) => {
    const TYPE_ACCEPTS_ALL = [WIDGET_TYPES.Funnel, WIDGET_TYPES.UnitCounts].includes(widget.view)
    const [screenerSelection, setScreenerSelection_raw] = useState(TYPE_ACCEPTS_ALL ? "ALL" : ANALYTICS_SCREENINGS[0]);
    function setScreenerSelection (event) {
      setScreenerSelection_raw(event.target.value);
      setChartSVG(null);
    }
    const ScreenerSelector = () => <select className="form-control form-control-sm" value={screenerSelection} onChange={setScreenerSelection}>
        <option value="ALL" disabled={!TYPE_ACCEPTS_ALL}>ALL REPORTS</option>
        {ANALYTICS_SCREENINGS.map(s => <option value={s}>{ScreenerNames[s]}</option>)}
      </select>;


    const STATUS_ORDER = ["sent", "opened", "started", "has-report", "INACTIVE"]
    const METHOD_LABELS = ["email", "sms", "whatsapp", "other"];
    const [liveChartSVG, setChartSVG] = useState(null);
    function attachFunnelChart (parentElement, index) {
      const {width, height} = parentElement.getBoundingClientRect();
      const screeningsFunnelData = STATUS_ORDER.map(label => ({label, email: 0, sms: 0, whatsapp: 0, other: 0}));
      let error = null;
      screenings.forEach(s => {
        if (s.t_created > timeWindow.start && s.t_created < timeWindow.end && (screenerSelection === "ALL" || screenerSelection === s.type)) {
          const stage = STATUS_ORDER.indexOf(s.status);
          const methodsFound = new Set();
          if (!Array.isArray(s.REL_externalMessages)) {
            error = "screenings are loaded but have no message data";
          } else {
            s.REL_externalMessages.forEach(m => methodsFound.add(m.method))
          }
          const methodLabel = s.lastMethodUsed ?? ((methodsFound.size === 1) ? Array.from(methodsFound)[0] : `other`);
          for (let i = 0; i < stage; i++) {
            screeningsFunnelData[i][methodLabel]++;
          }
        }
      });
      if (error) {
        dispatch(snack(error, undefined, {style: "error"}));
      }
      const EPSILON = 0.001;
      const chartUsableData = screeningsFunnelData.flatMap(
        (s,i) => METHOD_LABELS.map(
          l => ({
            method: l,
            statusLabel: s.label,
            statusIndex: i,
            count: (s[l] > 0 || i === 0) ? s[l] :
              (screeningsFunnelData[i-1][l] > 0 ? EPSILON : 0)
          })
        ));
      const margins = ((currentWidgets[index].expanded) ? {
        marginTop: 20,
        marginRight: 30,
        marginBottom: 30,
        marginLeft: 40
      } : {
        marginLeft: 40,
        marginRight: 9,
        marginTop: 6,
        marginBottom: 18
      });
      const chartNode = StackedAreaChart(chartUsableData, {
        x: d => d.statusLabel,
        y: d => d.count,
        z: d => d.method,
        xType: d3.scaleOrdinal,
        xDomain: STATUS_ORDER,
        xRange: STATUS_ORDER.map((_, t) => d3.interpolate(margins.marginLeft, width - margins.marginRight)(t/(STATUS_ORDER.length - 1))),
        yLabel: "",
        width,
        height,
        ...margins
      });
      parentElement.replaceChildren(chartNode);
      return chartNode;
    }
  
    function rebuildFunnel () {
      console.warn("Rebuilding funnel!")
      setChartSVG(attachFunnelChart(widget.containerRef.current, index));
    }
    function rebuildUnitCounts () {
      console.warn("Rebuilding units chart!")
      setChartSVG(attachUnitCounts(widget.containerRef.current, index));
    }

    const [unitCountMode, setUnitCountMode] = useState("Reports")

    function attachUnitCounts (parentElement, index) {
      const {width, height} = parentElement.getBoundingClientRect();
      // const screeningsFunnelData = STATUS_ORDER.map(label => ({label, email: 0, sms: 0, whatsapp: 0, other: 0}));
      // screenings.forEach(s => {
      //   if (s.t_created > timeWindow.start && s.t_created < timeWindow.end && (screenerSelection === "ALL" || screenerSelection === s.type)) {
      //     const stage = STATUS_ORDER.indexOf(s.status);
      //     const methodsFound = new Set();
      //     s.REL_externalMessages.forEach(m => methodsFound.add(m.method))
      //     const methodLabel = s.lastMethodUsed ?? ((methodsFound.size === 1) ? Array.from(methodsFound)[0] : `other`);
      //     for (let i = 0; i < stage; i++) {
      //       screeningsFunnelData[i][methodLabel]++;
      //     }
      //   }
      // });
      // const EPSILON = 0.001;
      // const chartUsableData = screeningsFunnelData.flatMap(
      //   (s,i) => METHOD_LABELS.map(
      //     l => ({
      //       method: l,
      //       statusLabel: s.label,
      //       statusIndex: i,
      //       count: (s[l] > 0 || i === 0) ? s[l] :
      //         (screeningsFunnelData[i-1][l] > 0 ? EPSILON : 0)
      //     })
      //   ));
      const chartUsableData = reports.filter(r => (screenerSelection === "ALL" || r.type === screenerSelection) && r.t_submitted >= timeWindow.start && r.t_submitted < timeWindow.end);
      const margins = ((currentWidgets[index].expanded) ? {
        marginTop: 20,
        marginRight: 30,
        marginBottom: 30,
        marginLeft: 40
      } : {
        marginLeft: 40,
        marginRight: 9,
        marginTop: 6,
        marginBottom: 18
      });
      const chartNode = Histogram(chartUsableData, {
        value: d => moment(d.t_submitted).toDate(),
        xType: d3.scaleUtc,
        xLabel: "Date",
        yLabel: unitCountMode,
        width,
        height,
        color: "steelblue"
      });
      //   {
      //   x: d => d.statusLabel,
      //   y: d => d.count,
      //   z: d => d.method,
      //   xType: d3.scaleOrdinal,
      //   xDomain: STATUS_ORDER,
      //   xRange: STATUS_ORDER.map((_, t) => d3.interpolate(margins.marginLeft, width - margins.marginRight)(t/(STATUS_ORDER.length - 1))),
      //   yLabel: "",
      //   width,
      //   height,
      //   ...margins
      // });
      parentElement.replaceChildren(chartNode);
      return chartNode;
    }
  
    useEffect(() => {
      if (widget.view === WIDGET_TYPES.Funnel) {
        if (widget.containerRef.current instanceof HTMLDivElement && !liveChartSVG && screenings?.length > 0) {
          rebuildFunnel();
        }
      }
      if (widget.view === WIDGET_TYPES.UnitCounts) {
        if (widget.containerRef.current instanceof HTMLDivElement && !liveChartSVG && reports?.length > 0) {
          rebuildUnitCounts();
        }
      }
    }, [widget, liveChartSVG, screenings, reports]);

    const recentScoreFrequency = useMemo(() => {
      if (!Array.isArray(scoresWindowed) || ! BANDS_TEMP[screenerSelection] || widget.view !== WIDGET_TYPES.ScoreStrata) return [];
      return BANDS_TEMP[screenerSelection].map(band => {
        const count = scoresWindowed.filter(({screener, value}) => screener === screenerSelection && value >= band.min && value < band.max).length;
        return ({...band, count, ratio: scoresWindowed.length > 0 ? (count/scoresWindowed.length) : 0});
      });
    }, [scoresWindowed, screenerSelection]);

    const INCREASES_SIZE = 10;
    const [largestIncreases, saveLargestIncreases] = useState(null);
    function determineLargestIncreases () {
      const userIds = Array.from(new Set(scoresWindowed.map(s => s.userId)));
      const deltas = [];
      Promise.all(userIds.map(id => dispatch(getUserScores(id)))).then(results => {
        results.forEach(r => {
          const sorted = r.payload.slice().sort((a, b) => b.t_generated - a.t_generated);
          scoresWindowed.filter(s => s.userId === r.meta.arg).forEach(current => {
            const previous = sorted.find(compare => compare.screener === current.screener && compare.t_generated < current.t_generated);
            if (previous && current.value > previous.value) {
              deltas.push({
                current,
                previous,
                delta: current.value - previous.value
              });
            }
          })
        });
        deltas.sort((a, b) => b.delta - a.delta);
        saveLargestIncreases(deltas.slice(0, INCREASES_SIZE));
      })
    }

    const [alertViewActive, setAlertViewActive] = useState(true);
    const expandIcon = widget.expanded ?
      <FaCompressArrowsAlt onClick={toggleExpand} title="Shrink this card"/> :
      <FaExpandArrowsAlt onClick={toggleExpand} title="Expand this card"/>;
    const cycleIcon = WIDGET_RINGS[index].length > 1 ? <>
        <span className="dropdown">
          <FaList title="Change widget" className="dropdown-toggle mr-2" id="dropdownMenuButton" data-toggle="dropdown"/>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            {WIDGET_RINGS[index].map((w, i) => 
              <a className={`dropdown-item ${widget.view === w ? "active" : ""}`} href="#" onClick={() => cycleView(i)}>{snakeToLowercase(w)}</a>
            )}
          </div>
        </span>
    </> : null;
    const headerIcons = (<div className="float-right">{cycleIcon}{" "}{expandIcon}</div>);


    switch (widget.view) {
      case WIDGET_TYPES.Funnel:
        return (<Card className="">
          <CardHeader>
            <FaChartBar /> Progress Funnel
            {headerIcons}
          </CardHeader>
          <CardBody style={{height: "calc(100% - 24px)", boxSizing: "border-box", overflowY: "scroll", justifyContent: "flex-start"}}>
            <CardText className="text-grey">
              <small>Completion rate of each step to obtain report.</small>
              <div className="mb-2">
                <ScreenerSelector/>
              </div>
            </CardText>
            <div
              ref={widget.containerRef}
              style={{
                display: "flex",
                width: "inherit",
                minHeight: "150px",
                height: widget.expanded ? "400px" : "calc(100% - 64px)",
                background: "white",
                justifyContent: "center",
                alignItems: "start",
                color: "grey"
              }}
            >
            </div>
          </CardBody>
        </Card>);


      case WIDGET_TYPES.ScoreStrata:
        return (<Card>
          <CardHeader>
            <FaChartArea /> Score Thresholds
            {headerIcons}
          </CardHeader>
          <CardBody style={{height: "100%", overflowY: "scroll", justifyContent: "flex-start"}}>
            <CardText className="text-grey">
              <small>Frequencies of recent scores.</small>
              <ScreenerSelector/>
            </CardText>
            {(recentScoreFrequency) ? /* && qBecks && qAnxiety */
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th className="text-center">Count</th>
                    <th className="text-center" colSpan={2}>Percent</th>
                  </tr>
                </thead>
                <tbody>
                  {recentScoreFrequency.map(freq => <tr>
                    <td>{freq.label}</td>
                    <td className="text-center">{freq.count}</td>
                    <td className="text-right">{Math.round(freq.ratio * 100)}%</td>
                    <td style={{minWidth: "50px", background: gradientBar(freq.ratio)}}>{" "}</td>
                  </tr>)}
                </tbody>
              </table>
              : <h3 className="text-muted">loading</h3>
            }
          </CardBody>
        </Card>);


      case WIDGET_TYPES.AggregateAlerts:
        return (<Card>
              <CardHeader>
                <FaExclamationTriangle /> Patient Alerts
                {headerIcons}
              </CardHeader>
              <CardBody className="pt-2" style={{maxHeight: "400px", height: "100%", overflowY: "scroll", justifyContent: "flex-start"}}>
                <CardText className="text-grey">
                  <small>Patient alerts needing attention.</small>
                </CardText>
                {/*
                <li className="list-group-item">Suicidal ideation</li>
                <li className="list-group-item">Extreme depression score</li>
                <li className="list-group-item">Sudden escalation of symptoms</li> */}
                {alertsGrouped.length > 0 ? 
              <ul className="list-group">
                 {alertsGrouped.map(group =>
                 <>
                  <li className="list-group-item" title={`Severity: <${group.severity}>`}>
                    <strong>{group.title}</strong> <span className="badge badge-dark float-right">{group.active.length}</span>
                  </li>
                  {group.active.map(a => <li className="list-group-item pl-4" style={{fontSize: widget.expanded ? "inherit" : "0.8rem"}}>
                    <em>{userNameMap[a.userId]}</em> on {moment(a.t_generated).format("M/D/YY")}
                    {alertViewActive ? <span className="badge badge-danger float-right" onClick={() => setAlertActive(a.id, false)}>
                      <FaEyeSlash title="Mark inactive" />
                      {widget.expanded ? " Clear" : null}
                    </span> : <span className="badge badge-warning float-right" onClick={() => setAlertActive(a.id, true)}>
                      <FaRegEye title="Mark inactive" />
                      {widget.expanded ? " Reactivate" : null}
                    </span>}
                    <span className="badge badge-info float-right" onClick={() => openPatientDetails(a.userId)}>
                      <FaFolderOpen title="View patient details" />
                      {widget.expanded ? " View" : null}
                    </span>
                  </li>)}
                  </>)}
              </ul> : <div className="text-center my-5"><span className="badge badge-light">No alerts for this period</span></div>}
              {/* Limit list with option to view more in a new window or screen, add action items and "addressed" to close item */}
              </CardBody>
            </Card>);


      case WIDGET_TYPES.LargestIncreases:
        return (<Card>
          <CardHeader>
            <FaExclamationTriangle /> Score Increases
            {headerIcons}
          </CardHeader>
          <CardBody className="pt-2" style={{maxHeight: "400px", height: "100%", overflowY: "scroll", justifyContent: "flex-start"}}>
            <CardText className="text-grey">
              <small>Largest outcome differences since last report.</small>
            </CardText>
            {largestIncreases === null ? <button className="btn btn-warning" onClick={determineLargestIncreases}>Evaluate</button> :
              <table className="table table-sm">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Increase</th>
                  <th>Timespan</th>
                </tr>
              </thead>
              <tbody>
                {largestIncreases.map(i => <tr>
                  <td>{i.current.userId?.slice(0,5)}</td>
                  <td>+{i.delta}</td>
                  <td>{moment(i.current.t_generated).diff(moment(i.previous.t_generated), "days")} days</td>
                </tr>)}
                </tbody>
              </table>
            }
          </CardBody>
        </Card>);

      case WIDGET_TYPES.UnitCounts:
        return (<Card className="">
          <CardHeader>
            <FaChartBar /> Responses by Date
            {headerIcons}
          </CardHeader>
          <CardBody style={{height: "calc(100% - 24px)", boxSizing: "border-box", overflowY: "scroll", justifyContent: "flex-start"}}>
            <CardText className="text-grey">
              <small>Number of reports returned each day.</small>
              <div className="mb-2">
                <ScreenerSelector/>
              </div>
            </CardText>
            <div
              ref={widget.containerRef}
              style={{
                display: "flex",
                width: "inherit",
                minHeight: "150px",
                height: widget.expanded ? "400px" : "calc(100% - 64px)",
                background: "white",
                justifyContent: "center",
                alignItems: "start",
                color: "grey"
              }}
            >
            </div>
          </CardBody>
        </Card>);

      case WIDGET_TYPES.EmptyWidget:
      default:
        return <Card>
          <h3 className="text-warning">Unknown widget type: <em>{widget.view}</em></h3>
          <div>{headerIcons}</div>
        </Card>
    }
  };

  function timeWindowUnitName () {
    if (timeWindow && timeWindow.unit !== "CUSTOM" && timeWindow.unit !== "ALL") {
      return capitalizeFirstLetter(timeWindow.unit);
    }
    return "Period";
  }

  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col>
            <div>
              <h4>
                <FaTable /> Dashboard
              </h4>
              <p className="text-grey">
                Track invites sent for each user over any given time.
              </p>
            </div>
          </Col>
          <Col>
          <div>
            <Row><Col>
            <nav aria-label="...">
              <ul
                className="pagination pagination-sm mb-0"
                style={{ justifyContent: "space-around" }}
              >
                <li
                  className={`page-item text-center ${timeWindow?.unit === "CUSTOM" ? "active" : ""}`}
                  style={{ width: "100%" }}
                >
                  <a className="page-link" href="#"
                    style={timeWindow?.unit === "CUSTOM" ? {backgroundColor: "#339499"} : {color: "#339499"}}
                    onClick={() => setCustomWindow()}>
                  <FaCalendar/> Custom
                  </a>
                </li>
                <li className={`page-item text-center ${timeWindow?.unit === "day" ? "active" : ""}`} style={{ width: "100%" }}>
                  <a className="page-link" href="#"
                    style={timeWindow?.unit === "day" ? {backgroundColor: "#339499"} : {color: "#339499"}}
                    onClick={() => setTimeWindow(buildRange("day"))}
                    >
                    Day
                  </a>
                </li>
                <li className={`page-item text-center ${timeWindow?.unit === "week" ? "active" : ""}`} style={{ width: "100%" }} aria-current="page">
                <a
                    className="page-link"
                    href="#"
                    style={timeWindow?.unit === "week" ? {backgroundColor: "#339499"} : {color: "#339499"}}
                    onClick={() => setTimeWindow(buildRange("week"))}
                  >Week</a>
                </li>
                <li className={`page-item text-center ${timeWindow?.unit === "month" ? "active" : ""}`} style={{ width: "100%" }}>
                  <a className="page-link" href="#"
                    style={timeWindow?.unit === "month" ? {backgroundColor: "#339499"} : {color: "#339499"}}
                    onClick={() => setTimeWindow(buildRange("month"))}
                    >
                    Month
                  </a>
                </li>
                <li className={`page-item text-center ${timeWindow?.unit === "year" ? "active" : ""}`} style={{ width: "100%" }}>
                  <a className="page-link" href="#"
                    style={timeWindow?.unit === "year" ? {backgroundColor: "#339499"} : {color: "#339499"}}
                    onClick={() => setTimeWindow(buildRange("year"))}
                    >
                    Year
                  </a>
                </li>
              </ul>
            </nav>
            </Col></Row>
            {timeWindow?.unit === "CUSTOM" ?
              <Row className="text-muted" style={{height: "24px", scale: "0.75"}}>
                <Col><CustomCalendar
                  className=""
                  inline={true}
                  sendEventOnInvalid={false}
                  changed={(value) => setTimeWindow({...timeWindow, start: moment(value).startOf().valueOf()})}
                  value={moment(timeWindow?.start).format("MM-DD-yyyy")}
                  disableFuture={true}
                /></Col>
                <Col className="col-auto text-center" style={{position: "relative", top: "8px"}}> to </Col> 
                <Col><CustomCalendar
                  className=""
                  inline={true}
                  sendEventOnInvalid={false}
                  changed={(value) => setTimeWindow({...timeWindow, end: moment(value).endOf().valueOf()})}
                  value={moment(timeWindow?.end).format("MM-DD-yyyy")}
                /></Col>
              </Row>
              :
              <Row className="text-muted">
                <Col className="text-center">{moment(timeWindow.start).calendar().toLocaleLowerCase()} — {moment(timeWindow.end).calendar().toLocaleLowerCase()}</Col>
              </Row>
            }
            <Row style={timeWindow?.unit === "CUSTOM" ? {opacity: "40%", pointerEvents: "none"} : null}>
              <Col><a className="link mx-2 float-left" href="#" onClick={() => stepTimeWindow(false)}> <FaArrowAltCircleLeft/> Prev. {timeWindowUnitName()}</a></Col>
              <Col><a className="link mx-2 float-right" href="#" onClick={() => stepTimeWindow(true)}>Next {timeWindowUnitName()} <FaArrowAltCircleRight/></a></Col>
            </Row>
          </div>
          </Col>
        </Row>
        <Row className="">
        {currentWidgets.map((widget, i) => 
          <Col className={(widget.expanded ? "col-12 " : "") + " mt-4"}>
            <AnalyticsWidget index={i} widget={widget} toggleExpand={() => toggleTab(i)} cycleView={t => nextWidgetView(i, t)}/>
          </Col>
        )}
        </Row>
        {/* give option to select date range */}
        <Row className="mt-4">
          <Col>
            <div className="tableLabel">
              <h4>
                <FaFileContract /> Received
                <span className="badge badge-light float-right">{received?.length ?? 0}</span>
              </h4>
            </div>
            <div style={{overflowY: "scroll", maxHeight: "500px"}}>
            <table className="table table-striped">
              <thead>
                <tr  style={{position: "sticky", top: "0px", background: "white", boxSizing: "border-box"}}>
                  <th>Client</th>
                  <th>Clinician</th>
                  <th>Appointment</th>
                  <th>Completed Screening</th>
                  <th>Received Date</th>
                  {/* <th>Status</th> */}
                  {/* <th>Answers</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              {received.length > 0 ? <tbody>
                {received.map(r => <tr>
                  <td>{r.userLastName}</td>
                  <td>{managerNames[r.managerId] ?? <em className="text-muted" title={r.managerId}>unknown</em>}</td>
                  <td>{appointmentDateString(r)}</td>
                  <td>{ScreenerNames[r.type] ?? `unknown: ${r.type}`}</td>
                  <td>{moment(r.t_created).format("M/D/YY H:MMa")}</td>
                  {/* <td>{ScreeningStatusHR[r.status] ?? `unknown: ${r.status}`}</td> */}
                  {/* <td></td> */}
                  <td><a className="link" onClick={() => openPatientDetails(r.userId)}>View User</a></td>
                </tr>)}
              </tbody> : <h3>No reports received in this period</h3>}
            </table>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <div className="tableLabel">
              <h4>
                <FaEnvelope /> Requested
                <span className="badge badge-light float-right">{requested?.length ?? 0}</span>
              </h4>
            </div>
            <div style={{overflowY: "scroll", maxHeight: "500px"}}>
            <table className="table table-striped">
              <thead>
                <tr style={{position: "sticky", top: "0px", background: "white", boxSizing: "border-box"}}>
                  <th>Client</th>
                  <th>Clinician</th>
                  <th>Appointment</th>
                  <th>Requested Screening</th>
                  <th>Send Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {requested.length > 0 ? <tbody>
                {requested.map(r => <tr>
                  <td>{r.userLastName}</td>
                  <td>{managerNames[r.managerId] ?? <em className="text-muted" title={r.managerId}>unknown</em>}</td>
                  <td>{appointmentDateString(r)}</td>
                  <td>{ScreenerNames[r.type] ?? `unknown: ${r.type}`}</td>
                  <td>{moment(r.t_created).format("M/D/YY H:MMa")}</td>
                  <td>{ScreeningStatusHR[r.status] ?? `unknown: ${r.status}`}</td>
                  <td><a className="link" onClick={() => openPatientDetails(r.userId)}>View User</a></td>
                </tr>)}
              </tbody> : <h3>No requests sent in this period.</h3>}
            </table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AnalyticsDashboard;
