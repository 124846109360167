import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import * as actionTypes from "../actions/actionTypes";

const modulesAdapter = createEntityAdapter({
  selectId: m => m.name
});

const initialState = {
  baseDef: null,
  modules: modulesAdapter.getInitialState(),
  currentModule: null,
  currentPage: 0,
  resources: {}
};

export const moduleBaseDefinitionRequested = createAsyncThunk(
  'education/module-base',
  async  (_, thunkAPI) => {
    const response = await fetch('/content/education/modules.json');
    if (!response.ok) throw new Error(`error fetching base module [${response.status}]: ${response.statusText}`);
    return response.json();
  }
);

export const moduleContentRequested = createAsyncThunk(
  'education/module-content',
  async ({module, page}, thunkAPI) => {
    let contentURL;
    if (Array.isArray(module.pages)) {
      // module lists its pages individually
      contentURL = module.pages[page];
    } else {
      contentURL = module.baseURL?.replace("<page>", `${page}`);
    }
    if (!contentURL) throw new Error(`No content URL available for module ${module.name} page ${page}`);
    const response = await fetch(contentURL);
    if (!response.ok) throw new Error(
      `error fetching content for module ${module.name} page ${page} [${response.status}]: ${response.statusText}`);
    const content = await response.text();
    // trick to detect when we loaded the app on accident
    // in actuality we should avoid this problem altogether by preventing URLs
    // pointing to the public folder from loading the app
    if (content.startsWith(`<!DOCTYPE html>`)) throw new Error(`Could not find content for module ${module.name} page ${page}`);
    return {moduleName: module.name, page, content};
  }
);

export const resourceMarkdownRequested = createAsyncThunk(
  'education/resource-markdown',
  async (resourceURL, thunkAPI) => {
    const response = await fetch(resourceURL);
    if (!response.ok) throw new Error(
      `error fetching content for resource ${resourceURL} [${response.status}]: ${response.statusText}`);
    const content = await response.text();
    // trick to detect when we loaded the app on accident
    // in actuality we should avoid this problem altogether by preventing URLs
    // pointing to the public folder from loading the app
    if (content.startsWith(`<!DOCTYPE html>`)) throw new Error(`Could not find content for module ${resourceURL}`);
    return {url: resourceURL, content};
  }
);


export const moduleSelectors = modulesAdapter.getSelectors(state => state.education.modules);
export const getCurrentModule = createSelector(
  s => s.education.currentModule,
  s => s,
  (current, state) => moduleSelectors.selectById(state, current)
);

const educationSlice = createSlice({
  name: 'education',
  initialState,
  reducers: {
    startModule: (state, action) => {
      state.currentModule = action.payload;
      state.currentPage = 0;
    },
    nextPage: state => {
      if (state.currentPage < state.modules.entities[state.currentModule]?.pageCount - 1) {
        state.currentPage++;
      }
    },
    previousPage: state => {
      if (state.currentPage > 0) {
        state.currentPage--;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(moduleBaseDefinitionRequested.fulfilled, (state, action) => {
      state.baseDef = action.payload;
      modulesAdapter.setAll(state.modules, action.payload.modules);
    });
    builder.addCase(actionTypes.QUESTIONNAIRE_RECEIVED, (state, action) => {
      // TODO: do we want questionnaires to define their own overrides?
    });
    builder.addCase(moduleContentRequested.fulfilled, (state, action) => {
      const module = state.modules.entities[action.payload.moduleName];
      if (!module) {
        throw new Error(`Couldn't find entity ${action.payload.moduleName}`);
      }
      if (!module.content) {
        module.content = [];
      }
      module.content[action.payload.page] = action.payload.content;
    });
    builder.addCase(moduleContentRequested.rejected, (state, action) => {
      const module = state.modules.entities[action.meta.arg.module?.name];
      if (!module) {
        throw new Error(`Couldn't find entity ${action.payload.moduleName}`);
      }
      if (!module.content) {
        module.content = [];
      }
      module.content[action.meta.arg.page] = "## Content could not be loaded";
    });
    builder.addCase(resourceMarkdownRequested.fulfilled, (state, action) => {
      state.resources[action.payload.url] = action.payload.content;
    });
    builder.addCase(resourceMarkdownRequested.rejected, (state, action) => {
      state.resources[action.meta.arg] = {error: action.error};
    });
  }
});



export const getAllModules = moduleSelectors.selectAll;
export const educationActions = educationSlice.actions;

export default educationSlice.reducer;
