import { createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../database";
import * as actionTypes from './actionTypes';
const feedbackRef = db.collection('feedback');

const feedbackStart = () => ({
  type: actionTypes.FEEDBACK_START
});

const feedbackSuccess = (id, info) => ({
  type: actionTypes.FEEDBACK_SUCCESS
});

const feedbackFail = (error) => ({
  type: actionTypes.FEEDBACK_FAIL,
  error
});

export const findFeedbackForUser = (userId) => dispatch => {
  return feedbackRef.where("userId", "==", userId).get().then(snapshot => {
    return snapshot.docs.map(d => d.data());
  })
}

export const sendFeedback = (feedbackData, userId, datetime) => dispatch => {
  dispatch(feedbackStart());

  return feedbackRef.add({
    feedbackData,
    userId,
    datetime
  }).then((doc) => {
    if (!doc || !doc.id) {
      dispatch(feedbackFail(new Error('Fail to create a feedback!')));
    } else {
      dispatch(feedbackSuccess(doc.id, feedbackData));
      return doc.id;
    }
  }).catch(error=> {
    dispatch(feedbackFail(error));
  });
}

export const updateFeedback = createAsyncThunk("feedback-update", async update => {
  if (!update.id) throw new Error("Update feedback must be called with the feedback object's id")
  const result = await feedbackRef.doc(update.id).update(update);
  return result;
})

export const createUserTicket = createAsyncThunk("create-ticket", async (content) => {
  const result = await db.collection("userTickets").add(content);
  return result;
})