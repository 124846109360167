import React, {useState} from 'react';
import { connect } from 'react-redux';
import { FaEnvelope } from 'react-icons/fa';
import { Container, Row, Col } from '../UI/Grid/Grid';
import Input from '../UI/Input/Input';
import Alert from '../UI/Alert/Alert';
import Spinner from '../UI/Spinner/Spinner';
import {updateObject} from '../UI/Input/helpers';
import Button from "react-bootstrap/Button";
import { authResetPassword } from "../../store/actions";


const PasswordForgetPage = ({
  onResetPassword,
  isError,
  isLoading,
  error
}) => {

  const INITIAL_STATE = {
    email: {
      required: true,
      placeholder: 'Email',
      inputIcon: FaEnvelope,
      type: 'email',
      elementType: 'input',
      value: '',
      valid: false,
      viewInfo: false,
      viewErrors: true,
      viewSuccess: false,
      rules: {
        email: true
      }
    }
  };

  const [formData, setFormData] = useState({ ...INITIAL_STATE});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Called with each element change
  const inputChangedHandler = (value, valid, inputIdentifier) => {

      const updatedFormElement = updateObject(formData[inputIdentifier], {
          value,
          valid
      });
      const updatedForm = updateObject(formData, {
          [inputIdentifier]: updatedFormElement
      });

      let isFormValid = true;
      for (let inputIdentifier in updatedForm) {
          isFormValid = updatedForm[inputIdentifier].valid && isFormValid;
      }
      setFormData(updatedForm);
      setIsFormValid(isFormValid);
  }

  const formElementsArray = [];
  for (let key in formData) {
      formElementsArray.push({
          id: key,
          ...formData[key]
      });
  }

  const submitHandler = ( event ) => {
      event.preventDefault();

      setIsSubmitted(true);
      onResetPassword({email: formData.email.value})

      event.preventDefault();
  }

  const content = (
    <div>
      <form onSubmit={submitHandler} autoComplete="off">
        {formElementsArray.map(formElement => (
          <Input
            key={formElement.id}
            elementType={formElement.elementType}
            name={formElement.name}
            label={formElement.label}
            InputIcon={formElement.inputIcon}
            placeholder={formElement.placeholder}
            value={formElement.value}
            required={formElement.required}
            options={formElement.options}
            rules={formElement.rules}
            type={formElement.type}
            autoComplete="false"
            changed={(value, valid) => inputChangedHandler(value, valid, formElement.id)}
            viewErrors={formElement.viewErrors}
            viewSuccess={formElement.viewSuccess}
            viewInfo={formElement.viewInfo}
          />
        ))}
        <Button type="submit" className="btn-primary btn-block" disabled={!isFormValid}>Send Email</Button>
      </form>
    </div>
    );

    const forgotPassword =
        isLoading?
        <Spinner />
        :
        (isSubmitted)?
        <div className="jumbotron">
          <p>An email has been sent to you. Please follow the directions in the email to reset your password</p>
        </div>
        :
        <Container>
          <Row className="justify-content-center mt-5">
            {error && <Alert error={error} />}
          </Row>
          <Row className="justify-content-center mt-5">
            <Col className="col-12 text-center">
            <h1 className="h3 mb-5">Forgot Password!</h1>
            </Col>
            <Col className="col-12 col-md-8 col-lg-6 text-center">
            <p className="pb-3">Having a problem logging? No problem, type your email below and we'll send you an email to reset your password.</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="col-12 col-md-8 col-lg-6 ">
              <div className="bg-light p-5">
              {content}
              </div>
            </Col>
          </Row>
          </Container>

    return forgotPassword;
};

const mapDispatchToProps = dispatch => {

  return {
      onResetPassword: (userData) => dispatch( authResetPassword(userData) )
  };
};

function mapStateToProps(state, ownProps) {
  return {
    isLoading: state.auth.isLoading,
    isError: state.auth.isError,
    error: state.auth.error
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgetPage);
