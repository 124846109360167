import React, { useEffect, useState } from 'react'; // useState,
import { Redirect, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { sendEmail } from '../../store/actions';
import Spinner from '../UI/Spinner/Spinner';
import EmailConfirmation from './EmailConfirmation';
// import ContactInfo from './ContactInfo';
import * as ROUTES from '../../constants/routes';
import {capitalizeFirstLetter} from '../../utils';
import { ScreenerNames, ScreeningType } from "../../constants/screenings";

const HACKY_TEMP_WAY_TO_TOTALLY_DISABLE_EMAIL = true;

const SendEmail = ( {
  type,
  bccUids,
  // providerName,
  lastName,
  patientLastName,
  dob,
  // email,
  // phone,
  time,
  // attachments,
  // providerId,
  preferredProvider,
  isLoading,
  isSent,
  // history,
  report,
  isError,
  emailOverride,
  reportId,
  isAnonymous
 } ) => {
  const dispatch = useDispatch();
  const debugMode = useSelector(s => s.session.debugMode);
  const [skipped, setSkipped] = useState(false);

  useEffect (() => {

    if (type === "Email Report" && !isSent) {
      setSkipped(false);

      let surveyName = ScreenerNames[report.type];
      let emailContact = emailOverride ? emailOverride : 'hello@literaseed.io';

      // Fill email content
      let emailContent = {
        // ccUids: [preferredProvider.uid],
        // * Will reinstate sending to provider Email via bcc only after the pilot period is over (or replace with EMR integration)
        // TODO: we need to determine logic around deciding on provider emails
        // and how provider data is formalized [tdhs]
        bcc: emailContact,
        template:
        {
          name: 'ReportTemplate',
          data:
          {
            surveyName,
            report
            // TODO - OBGYN questionnaire 1 should be "Well Women's" if GYN Path
            // TODO - and "Prenatal" if OB Path
          }
        }
      }
      if (patientLastName &&
        (!lastName
          || (patientLastName.toLowerCase() !== lastName.toLowerCase()))) {
        emailContent.template.data.patientLastName = patientLastName;
      }
      // Send email
      if (!debugMode || window.confirm(`Do you want to send the email?\n${JSON.stringify(emailContent)}`)) {
        dispatch(sendEmail(emailContent));
      } else {
        setSkipped(true);
        console.warn("Skipping email send!")
      }
    }
  }, [report,
      bccUids,
      preferredProvider]);

  return (
    isError ? <em>We could not send an email at this time</em> :
    (isLoading && !skipped) ? <Spinner /> : <EmailConfirmation />
  );
}

function mapStateToProps(state, ownProps) {
  // console.log(ownProps.location.state);
  // console.log(ownProps.location.state.report);
  const providerId = ownProps?.location?.providerId || state.auth.user?.provider?.uid;
  //TODO return error if no provider id
  return {
    preferredProvider: state.provider.preferredProvider,
    bccUids: providerId? [providerId] : [state.auth.user.uid],
    providerName: state.provider.preferredProvider? state.provider.preferredProvider.name : "",
    lastName: state.auth.user? capitalizeFirstLetter(state.auth.user.lastName) : "",
    patientLastName: state.auth.user? capitalizeFirstLetter(state.auth.user.patientInputLastName) : "",
    dob: state.auth.user? state.auth.user.dob : "",
    email: ownProps.location && ownProps.location.email? ownProps.location.email : "",
    phone: ownProps.location && ownProps.location.phone? ownProps.location.phone : "",
    attachments: ownProps.location && ownProps.location.attachments? ownProps.location.attachments.slice() : "",
    type: ownProps.location?.state?.type,
    // history: ownProps.location?.state?.history,
    report: ownProps.location?.state?.report,
    time: ownProps.location?.state?.time,
    isLoading: state.email.isLoading,
    isSent: HACKY_TEMP_WAY_TO_TOTALLY_DISABLE_EMAIL || state.email.isSent,
    isError: state.email.isError,
    reportId: state.report.reportId,
    isAnonymous: state.auth.user.isAnonymous,
    emailOverride: state.report.emailOverride
  }
}


export default connect(mapStateToProps)(SendEmail);
