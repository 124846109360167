import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { ROLES } from "../../constants/roles";
import { REPORTS } from "../../constants/routes";
import { getAllUsers, getUserById } from "../../store/slices/admin";
import { _switch } from "../../utils";

const UsersTable = ({users, adminUsers, providerUsers, keyedPatients, separateRoles}) => {
  const allNames = user =>
    (user.name ? `${user.name}` : `${user.firstName || ""} ${user.lastName || ""}`)
    + (user.providerName ? ` (${user.providerName})` : "");
  const refs = new Map(users.map(u => [u.id, React.createRef()]));
  const [highlight, setHighlight] = useState(null);
  const scrollTo = id => {
    const ref = refs.get(id);
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setHighlight(id);
      setTimeout(() => setHighlight(null), 3000);
    }
  }

  useEffect(() => {
    setAdminSorting({result: adminUsers, field: null, descending: true});
    setProviderSorting({result: providerUsers, field: null, descending: true});
    setPatientSorting({result: keyedPatients, field: null, descending: true});
    setAllUserSorting({result: users, field: null, descending: true});
  }, [users]);

  const [adminSorting, setAdminSorting] = useState({result: adminUsers, field: null, descending: true});
  const [providerSorting, setProviderSorting] = useState({result: providerUsers, field: null, descending: true});
  const [patientSorting, setPatientSorting] = useState({result: keyedPatients, field: null, descending: true});
  const [allUserSorting, setAllUserSorting] = useState({result: users, field: null, descending: true});
  const sortBy = (sortContext, comparator, label, invert) => {
    const sortObj = _switch(sortContext, ROLES.ADMIN, adminSorting, ROLES.PROVIDER, providerSorting, ROLES.PATIENT, patientSorting, null, allUserSorting);
    const sortMethod = _switch(sortContext, ROLES.ADMIN, setAdminSorting, ROLES.PROVIDER, setProviderSorting, ROLES.PATIENT, setPatientSorting, null, setAllUserSorting);
    if (sortObj.field === label) {
      sortObj.descending = !sortObj.descending;
    } else {
      sortObj.field = label;
      sortObj.descending = invert !== true;
    }
    let newSort = sortObj.result.sort(
      (a, b) => sortObj.descending ? comparator(a,b) : -1 * comparator(a,b));
      sortMethod({...sortObj, result: newSort});
  }
  const sortMethod = {
    id: (context) => (inv) => {
      sortBy(context, (a, b) => a.id.localeCompare(b.id), "id", inv);
    },
    name: (context) => (inv) => {
      sortBy(context, (a, b) => allNames(a).localeCompare(allNames(b)), "name", inv);
    },
    contact: (context) => (inv) => {
      sortBy(context, (a, b) => (a.email || a.phone).localeCompare(b.email || b.phone), "contact", inv);
    },
    created: (context) => (inv) => {
      sortBy(context, (a, b) => (a.t_created || 0) - (b.t_created || 0), "created", inv);
    },
    version: (context) => (inv) => {
      sortBy(context, (a, b) => (a.version || 0) - (b.version || 0), "version", inv);
    },
    reports: (context) => (inv) => {
      sortBy(context, (a, b) => (a.reports?.length || 0) - (b.reports?.length || 0), "reports", inv);
    }
  }

  const history = useHistory();
  const viewReport = (reportId) => () => {
    history.push(`${REPORTS}/${reportId}`, {reportId});
  }

  if (separateRoles) {
    return <>
      <h3>Admins</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="sortable" onClick={sortMethod.id(ROLES.ADMIN)}>ID {adminSorting.field == "id" ? (adminSorting.descending ? "↓" : "↑") : null}</th>
            <th className="sortable" onClick={sortMethod.name(ROLES.ADMIN)}>Name {adminSorting.field == "name" ? (adminSorting.descending ? "↓" : "↑") : null}</th>
            <th className="sortable" onClick={sortMethod.contact(ROLES.ADMIN)}>Email {adminSorting.field == "contact" ? (adminSorting.descending ? "↓" : "↑") : null}</th>
            <th>Verified</th>
            <th className="sortable" onClick={sortMethod.version(ROLES.ADMIN)}>Version {adminSorting.field == "version" ? (adminSorting.descending ? "↓" : "↑") : null}</th>
          </tr>
        </thead>
        <tbody>
          {adminSorting.result.map(u =>
            <tr key={u.id} ref={refs.get(u.id)} className={highlight === u.id ? "highlight-row" : "highlightable"}>
              <td><small>{u.id}</small></td>
              <td>{allNames(u)}</td>
              <td><a href={"mailto:" + u.email}>{u.email}</a></td>
              <td>
                {u.version === 1 ?
                  <>{u.verified?.by}<br/>{u.verified?.date}</> : `N/A (pre-v1)`}
              </td>
              <td>{u.version || 0}</td>
            </tr>
          )}
        </tbody>
      </table>

      <h3>Clinicians</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="sortable" onClick={sortMethod.id(ROLES.PROVIDER)}>ID {providerSorting.field == "id" ? (providerSorting.descending ? "↓" : "↑") : null}</th>
            <th className="sortable" onClick={sortMethod.name(ROLES.PROVIDER)}>Name {providerSorting.field == "name" ? (providerSorting.descending ? "↓" : "↑") : null}</th>
            <th className="sortable" onClick={sortMethod.contact(ROLES.PROVIDER)}>Email {providerSorting.field == "contact" ? (providerSorting.descending ? "↓" : "↑") : null}</th>
            <th>Verified</th>
            <th className="sortable" onClick={sortMethod.version(ROLES.PROVIDER)}>Version {providerSorting.field == "version" ? (providerSorting.descending ? "↓" : "↑") : null}</th>
          </tr>
        </thead>
        <tbody>
          {providerSorting.result.map(u =>
            <tr key={u.id} ref={refs.get(u.id)} className={highlight === u.id ? "highlight-row" : ""}>
              <td><small>{u.id}</small></td>
              <td>{allNames(u)}</td>
              <td><a href={"mailto:" + u.email}>{u.email}</a></td>
              <td>
                {u.version === 1 ?
                  <>{u.verified?.by}<br/>{u.verified?.date}</> : `N/A (pre-v1)`}
              </td>
              <td>{u.version || 0}</td>
            </tr>
          )}
        </tbody>
      </table>

      <h3>Invited Patients</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="sortable" onClick={sortMethod.id(ROLES.PATIENT)}>ID {patientSorting.field == "id" ? (patientSorting.descending ? "↓" : "↑") : null}</th>
            <th className="sortable" onClick={sortMethod.name(ROLES.PATIENT)}>Name/DOB {patientSorting.field == "name" ? (patientSorting.descending ? "↓" : "↑") : null}</th>
            <th className="sortable" onClick={sortMethod.contact(ROLES.PATIENT)}>Contact {patientSorting.field == "contact" ? (patientSorting.descending ? "↓" : "↑") : null}</th>
            <th>Provider</th>
            <th className="sortable" onClick={sortMethod.reports(ROLES.PATIENT)}>Reports {patientSorting.field == "reports" ? (patientSorting.descending ? "↓" : "↑") : null}</th>
            <th className="sortable" onClick={sortMethod.created(ROLES.PATIENT)}>Created {patientSorting.field == "created" ? (patientSorting.descending ? "↓" : "↑") : null}</th>
            <th className="sortable" onClick={sortMethod.version(ROLES.PATIENT)}>Version {patientSorting.field == "version" ? (patientSorting.descending ? "↓" : "↑") : null}</th>
          </tr>
        </thead>
        <tbody>
          {patientSorting.result.map(u =>
            <tr key={u.id} id={u.id}>
              <td><small>{u.id}</small></td>
              <td>
                {u.firstName || ""} {u.lastName}<br/>
                <small>{u.dob}</small>
              </td>
              <td>
                <a href={"mailto:" + u.email}>{u.email}</a><br/>
                <a href={"tel:" + u.phone}>{u.phone}</a>
              </td>
              <td>
                <small><a onClick={() => scrollTo(u.providerId)}>{u.providerId}</a></small>
                <br/>
                {u._linkedProvider ? allNames(u._linkedProvider) : <em className="text-warning">[clinician not found]</em>}
              </td>
              <td>{u.reports?.length || 0}</td>
              <td>
                {u.t_created ? moment.utc(u.t_created).local().format("MM/DD/YY HH:mm") : "N/A (pre-v2)"}
              </td>
              <td>{u.version || 0}</td>
            </tr>
          )}
        </tbody>
      </table>

    </>;
  } else {
    const classNameByRole = {
      [ROLES.ADMIN]: "text-danger",
      [ROLES.PROVIDER]: "text-primary",
      [ROLES.PATIENT]: ""
    };
    return <>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Role/ID</th>
            <th><span className="sortable" onClick={sortMethod.name(null)}>Name</span>/DOB {allUserSorting.field == "name" ? (allUserSorting.descending ? "↓" : "↑") : null}</th>
            <th>Contact</th>
            {/* <th>Created by</th> */}
            <th>Completed Reports</th>
            <th className="sortable" onClick={sortMethod.created(null)}>Date Invited {allUserSorting.field == "created" ? (allUserSorting.descending ? "↓" : "↑") : null}</th>
            {/* <th>Version</th> */}
          </tr>
        </thead>
        <tbody>
          {allUserSorting.result.map((u, i) =>
            <tr key={i} id={i}>
              <td>
                <p className={classNameByRole[u.role]}>{u.role}</p>
                <small>{u.id}</small>
              </td>
              <td>
                {u.firstName || ""} {u.lastName}<br/>
                <small>{u.dob}</small>
              </td>
              <td>
                <a href={"mailto:" + u.email}>{u.email}</a><br/>
                <a href={"tel:" + u.phone}>{u.phone}</a>
              </td>
              {/*u.providerId ?
                <td>
                  <strong>Provider:</strong><br/>
                  <small><a onClick={() => {}}>{u.providerId}</a></small><br/>
                  {u._linkedProvider ? allNames(u._linkedProvider) : <em className="text-warning">[clinician not found]</em>}
                </td>
              : u.version === 1 ? <td>{u.verified?.by}<br/>{u.verified?.date}</td> : <td>no verifier</td>
              */}
              <td>{(u.reports?.length)>0 ? <ul>{u.reports.map(r => <li key={r} onClick={viewReport(r)}>{r}</li>)}</ul> : "None yet"}</td>
              <td>
                {u.t_created ? moment.utc(u.t_created).local().format("MM/DD/YY HH:mm") : "unknown"}
              </td>
              {/* <td>{u.version || 0}</td> */}
            </tr>
          )}
        </tbody>
      </table>
    </>;
  }
}

function mapStateToProps(state, ownProps) {
  const users = Array.isArray(ownProps.users) ? ownProps.users : getAllUsers(state);
  const adminUsers = [];
  const providerUsers = [];
  const accountPatients = [];
  const keyedPatients = [];
  users.forEach(u => {
    if (u.role === ROLES.ADMIN) {
      adminUsers.push(u);
    } else if (u.role === ROLES.PROVIDER) {
      providerUsers.push(u);
    } else if (u.hasAccount) {
      // TODO: how do we decide they are an "account-holding" user? obviously we
      // could look in the auth "table", but it would be preferred if we could
      // tell from the users table alone... [tdhs]
      accountPatients.push(u);
    } else {
      keyedPatients.push({...u, _linkedProvider: u.providerId ? getUserById(state, u.providerId) : null});
    }
  });
  console.warn(`User counts: admins ${adminUsers.length}, providers ${providerUsers.length}, full accts ${accountPatients.length}, keyed ${keyedPatients.length}`)
  return {
    separateRoles: ownProps.separateRoles !== false,
    adminUsers,
    providerUsers,
    accountPatients,
    keyedPatients,
    users
  };
}

export default connect(mapStateToProps)(UsersTable);