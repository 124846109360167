import React, {useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import * as ROUTES from '../../constants/routes';
import { ROLES } from '../../constants/roles';
import { Row, Col, Container} from '../UI/Grid/Grid';
import Button from "react-bootstrap/Button";
import { updateObject } from '../UI/Input/helpers';
import { Input } from '../UI/Input/Input';
import Alert from '../UI/Alert/Alert';
import { FaEnvelope, FaKey } from 'react-icons/fa';
import { login } from "../../store/actions";
import Spinner from '../../components/UI/Spinner/Spinner';

import "../../styles/signin.css";

const SigninPage = ({
    onLogin,
    isAuthenticated,
    isLoading,
    redirectPath,
    isInactive,
    role,
    error
  }) => {

  const INITIAL_STATE = {
    email: {
      label: "Email",
      required: true,
      placeholder: 'Email',
      inputIcon: FaEnvelope,
      type: 'email',
      elementType: 'input',
      value: '',
      valid: false,
      viewInfo: false,
      viewErrors: true,
      viewSuccess: false,
      initialValidate: false,
      rules: {
        email: true
      }
    },
    password: {
      label: "Password",
      required: true,
      placeholder: 'Password',
      inputIcon: FaKey,
      type: 'password',
      elementType: 'input',
      value: '',
      viewInfo: false,
      viewErrors: false,
      viewSuccess: false,
      initialValidate: false,
      valid: false,
      rules: {
        required: true
      }
    }
  };

  const [signinForm , setSigninForm] = useState ({ ...INITIAL_STATE });
  const [isFormValid, setIsFormValid] = useState(false);

  // Called with each element change
  const inputChangedHandler = (value, valid, inputIdentifier) => {

      const updatedFormElement = updateObject(signinForm[inputIdentifier], {
          value,
          valid
      });
      const updatedForm = updateObject(signinForm, {
          [inputIdentifier]: updatedFormElement
      });

      let isFormValid = true;
      for (let inputIdentifier in updatedForm) {
          isFormValid = updatedForm[inputIdentifier].valid && isFormValid;
      }
      setSigninForm(updatedForm);
      setIsFormValid(isFormValid);
  }

  const submitHandler = ( event ) => {
      event.preventDefault();

      if (!isFormValid) {
        let updatedForm = {...signinForm};

        for (let key in updatedForm) {
          updatedForm[key].viewErrors = true;
          updatedForm[key].viewInfo = false;
          updatedForm[key].initialValidate = true;
        }

        setSigninForm(updatedForm);
      } else {
        const formData = {};
        for (let formElementIdentifier in signinForm) {
            formData[formElementIdentifier] = signinForm[formElementIdentifier].value;
        }

        onLogin(formData.email, formData.password, ROLES.PROVIDER);
      }
      event.preventDefault();
  }

  const signupLink = (
      <p>
        Do not have an account? <Link to={ROUTES.CONTACT}><u className="text-info">Contact Us</u></Link>
      </p>
    );

  const passwordForgetLink = (
        <p>
            <Link to={ROUTES.PASSWORD_FORGET}><u className="text-info">Forgot Password?</u></Link>
        </p>
    );

  let content = '';

  content = (
    <div>
      <form onSubmit={submitHandler} autoComplete="off">
        { Object.keys(signinForm).sort().map((key) => (
          <Input
            key={key}
            id={key}
            elementType={signinForm[key].elementType}
            name={signinForm[key].name}
            label={signinForm[key].label}
            labelClassName="font-weight-bold"
            InputIcon={signinForm[key].inputIcon}
            placeholder={signinForm[key].placeholder}
            value={signinForm[key].value}
            required={signinForm[key].required}
            options={signinForm[key].options}
            rules={signinForm[key].rules}
            type={signinForm[key].type}
            autoComplete="false"
            changed={(value, valid) => inputChangedHandler(value, valid, key)}
            viewErrors={signinForm[key].viewErrors}
            viewSuccess={signinForm[key].viewSuccess}
            viewInfo={signinForm[key].viewInfo}
            initialValidate={signinForm[key].initialValidate}
          />
        ))}
        <Button type="submit" className="btn-viridian-green btn-block">Signin</Button>
      </form>
      <div className="mt-3 mb-3">
        {passwordForgetLink}
        {signupLink}
      </div>
      <p className="mt-4 mb-3 text-muted">&copy; LiteraSeed, Inc.</p>
    </div>
    )

    if (isLoading) {
      content = <Spinner />
    }

    const isActiveMsg = isInactive? <Alert error={new Error("For security reasons your session has expired!")} /> : "";

    const signin =
        (role === ROLES.PATIENT)? <Redirect to={ROUTES.SIGN_IN} />
        :
        (isAuthenticated && !isLoading && (role === ROLES.PROVIDER))? <Redirect to={redirectPath} />
        :
        <Container className="pr-0 pl-0">
          <Row className="justify-content-center mt-5">
            {error && <Alert error={error} />}
            {isActiveMsg}
          </Row>
          <Row className="justify-content-center mt-5">
            <Col className="col-12">
              <h1 className="h3 mb-5 text-center px-3">Welcome To Provider Login!</h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="col-12 col-md-8 col-lg-6">
              <div className="bg-light-grey p-3 p-sm-5">
              {content}
              </div>
            </Col>
          </Row>
          </Container>

    return signin;
}


const mapDispatchToProps = dispatch => {
  return {
      onLogin: (email, password) => dispatch( login(email, password) )
  };
};

function mapStateToProps(state, ownProps) {

  return {
    isAuthenticated: state.auth.isAuthenticated,
    role: state.auth.user? state.auth.user.role : "",
    isLoading: state.auth.isLoading,
    error: state.auth.error,
    redirectPath: ROUTES.PROVIDER,
    isInactive: ownProps.location.isInactive
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);
