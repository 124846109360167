import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../constants/communication";
import { dualName, generateQuestionnaireKind, Language } from "../../constants/locales";
import { allows, FileAliases, PageType, ScreenerNames } from "../../constants/screenings";
import { assignOnly, compareSemver, deepAssignMulti, isObject, positiveModulo, wraparoundAccess } from "../../utils";
import { evaluator } from "../../utils/evaluator";
import { Fulfilled } from "../../utils/promises";
import { CHECK_PRE_CREATED_PATIENT_SUCCESS } from "../actions/actionTypes";

const questionnaireDefinitionAdapter = createEntityAdapter();

const initialState = {
  status: Status.Unstarted,
  error: {},
  questionnaires: questionnaireDefinitionAdapter.getInitialState({
    error: null,
    current: null
  }),
  newestVersions: {},
  timeStarted: null,
  answers: {},
  dynamicText: {},
  prefill: {},
  outcomes: {},
  tags: {},
  currentSynthReport: null,
  bodyTypePreference: null,
  
  // keyPreviouslyUsed: false,
  currentScreeningID: null,

  navigation: baseNavigationObject(),
};

function baseNavigationObject (number = 0) {
  return {
    number,
    details: {type: PageType.Empty},
    lastMovement: 0,
    history: [number],
    multiplex: {
      row: -1,
      rowCount: -1,
      columnPages: []
    },
    followups: {
      index: -1,
      forCurrent: []
    }
  }
}

const SectionIntoSetting = {
  Enabled: true,
  ForwardOnly: "forward-only",
  OnceOnly: "once"
};
const DefaultPageSettings = {
  "sectionIntros": SectionIntoSetting.Enabled,
  "reviewReport": false,
  "highlightKeywords": true
};

function buildQuestionPage ([key, dfn], index) {
  const newPage = {
    type: PageType.QuestionPage,
    index,
    sectionKey: dfn.section,
    addlNavKeys: {},
    key,
  };
  if (dfn.subsection) newPage.addlNavKeys.subsection = dfn.subsection;
  if (dfn.additionalOccurrenceOf) {
    newPage.key = dfn.additionalOccurrenceOf;
    newPage.isDuplicate = true;
    newPage.originalKey = key;
    assignOnly(dfn, newPage, ["skipWhen", "displayWhen", "section"], true);
  }
  if (dfn.groupWith) {
    newPage.type = PageType.QuestionGroup;
    newPage.additionalKeys = dfn.groupWith;
  } else if (dfn.tableWith) {
    newPage.type = PageType.QuestionTable;
    newPage.additionalKeys = dfn.tableWith;
  } else if (dfn.multiplexOver) {
    newPage.multiplex = {
      key: JSON.stringify(dfn.multiplexOver),
      formula: dfn.multiplexOver,
      order: dfn.multiplexOrder || "by-question",
      unitLabel: dfn.multiplexUnit
    }
  }
  // if (dfn.customInputRenderer) lazy(() => import(dfn.customInputRenderer));
  return newPage;
}

function buildPages (q) {
  let pages = [];
  const settings = Object.assign({}, DefaultPageSettings, q.pageSettings);
  q.pageSettings = settings;
  const introducedSections = new Set();
  const questionEntries = Object.entries(q.questions);
  for (let i = 0; i < questionEntries.length; i++) {
    if (questionEntries[i][1].special) continue;
    let sectionName = questionEntries[i][1].section;
    if (settings.sectionIntros === SectionIntoSetting.Enabled && !introducedSections.has(sectionName)) {
      pages.push({
        type: PageType.SectionIntro,
        key: sectionName,
        sectionKey: sectionName,
        // reference: q.sections[sectionName]
      });
      introducedSections.add(sectionName);
    }
    if (!(sectionName in q.sections)) {
      console.error(`[${questionEntries[i][0]}]: could not find section named ${sectionName}; generating stub`);
      q.sections[sectionName] = {
        _fallbackSection: true,
        imageUrl: "",
        audioFileName: "",
        title: `Auto-generated Section for «${sectionName}»`,
        sectionDescription: "The questionnaire did not define a section object with the given key, so this stub was generated by the system. Please address this issue before production!"
      };
    }
    pages.push(buildQuestionPage(questionEntries[i], i))
  }
  if (settings.reviewReport) {
    pages.push({
      type: PageType.ReviewReport
    })
  }
  pages.push({
    type: PageType.SubmitPage
  });
  return pages;
}

export function replaceFormulaAliases (formula, aliases) {
  if (Array.isArray(formula) && formula.length > 1) {
    return formula.map(item => replaceFormulaAliases(item, aliases))
  }
  if (typeof formula === "string") {
    if (formula.startsWith("=") && formula.slice(1) in aliases) {
      return aliases[formula.slice(1)].slice();
    }
  }
  return formula;
}

const COMPARATORS = ["IS", "=", "EQUALS", "IS_NOT", "≠", "CONTAINS"];
function hasCheckableComparatorFormat (formula) {
  return (
    typeof formula[0] === "string" &&
    (formula[0].startsWith("?") || formula[0].startsWith("⁇")) &&
    COMPARATORS.includes(formula[1]) &&
    (["number", "boolean"].includes(typeof formula[2]) ||
     (
      typeof formula[2] === "string" && !formula[2].startsWith("=")
     )
    )
  );
}
export function checkForCommonErrors (formula, q, label) {
  if (Array.isArray(formula)) {
    if (formula.length === 3) {
      if (hasCheckableComparatorFormat(formula)) {
        const qk = formula[0].slice(1);
        if (q.questions[qk]?.responses?.every(
          r => r.value !== formula[2] &&
          !(['scale', 'textbox', 'freeform', 'calendar', 'number'].includes(r.inputType)) &&
          (
            !Array.isArray(r.inputOptions) ||
            r.inputOptions.every(o => o.value !== formula[2])
          )
        )) {
          console.error(`Warning in formula ${label}:\n could not find response with value «${formula[2]}» in question '${qk}'`);
        }
      }
    }
    return formula.map(inner => checkForCommonErrors(inner, q, label));
  }
  if (typeof formula === "string") {
    if (formula.startsWith("?") || formula.startsWith("⁇")) {
      const qk = formula.slice(1);
      if (!(qk in q.questions)) {
        console.error(`Warning in formula ${label}:\n could not find question matching query ${formula}`);
      }
    }
  }
}

/**
 * Traverse all the formulas in a questionnaire and transform them with the
 * given function.
 * 
 * @param {{questions: any, sections: any, reportOutcomes: any}} q Questionnaire definitionn
 * @param {(questionnaireDefinition: any, formula: any[] | string, path: string) => any[]} fn Transformer, which should return a formula
 * @param {boolean} visitOnly whether to not transform the questionnaire but simply to visit
 */
export function mapFormulas (q, fn, visitOnly = false, includeRawAliases = false) {
  const set = visitOnly ?
    (obj, prop, path) => fn(q, obj[prop], path)
    : (obj, prop, path) => obj[prop] = fn(q, obj[prop], path);
  Object.entries(q.questions).forEach(([k, x]) => {
    if (x.skipWhen) {
      set(x, "skipWhen", `questions.${k}.skipWhen`);
    }
    if (x.displayWhen) {
      set(x, "displayWhen", `questions.${k}.displayWhen`);
    }
    if (x.flags) {
      Object.values(x.flags).forEach((f, i) => {
        set(f, "displayWhen", `questions.${k}.flags[${i}].displayWhen`);
        if (Array.isArray(f.description)) {
          set(f, "description", `questions.${k}.flags[${i}].description`);
        }
      });
    }
    if (Array.isArray(x.responses)) {
      x.responses.forEach((r, i) => {
        if (r.skipWhen) {
          set(r, "skipWhen", `questions.${k}.responses.${i}.skipWhen`);
        }
        if (r.displayWhen) {
          set(r, "displayWhen", `questions.${k}.responses.${i}.displayWhen`);
        }
        if (r.suggestWhen) {
          set(r, "suggestWhen", `questions.${k}.responses.${i}.suggestWhen`);
        }
      })
    }
    if (isObject(x.text)) {
      set(x, "text", `questions.${k}.text`);
    }
  });
  if (isObject(q.sections)) {
    Object.entries(q.sections).forEach(([k, s]) => {
      if (s.skipWhen) {
        set(s, "skipWhen", `sections.${k}.skipWhen`);
      }
      if (s.displayWhen) {
        set(s, "displayWhen", `sections.${k}.displayWhen`);
      }
      if (s.commonFlags) {
        Object.values(s.commonFlags).forEach((f, i) => {
          set(f, "displayWhen", `sections.${k}.commonFlags[${i}].displayWhen`);
          if (Array.isArray(f.description)) {
            set(f, "description", `sections.${k}.commonFlags[${i}].description`);
          }
        });
      }
    });
  }
  if (isObject(q.reportOutcomes)) {
    Object.entries(q.reportOutcomes).forEach(([k, o]) => {
      set(o, "formula", `reportOutcomes.${k}.formula`);
      if (o.flags) {
        Object.values(o.flags).forEach((f, i) => {
          set(f, "displayWhen", `reportOutcomes.${k}.flag#${i}.displayWhen`);
          if (Array.isArray(f.description)) {
            set(f, "description", `reportOutcomes.${k}.flag#${i}.description`);
          }
        });
      }
    });
  }
  if (includeRawAliases && isObject(q.formulaAliases)) {
    Object.entries(q.formulaAliases).forEach(([k, f]) => {
      set(q.formulaAliases, k, `formulaAliases.${k}.formula definition`);
    });
  }
}

function checkExternal (url, locationLabel) {
  try {
    if ((new URL(url, window.location.origin)).origin !== window.location.origin) {
      console.warn(`Config warning: avoid using external imgUrl. Found '${url}' at ${locationLabel}`);
    }
  } catch (err) {
    console.warn(`Config warning: browser did not understand imgUrl: '${url}' at ${locationLabel}`);
  }
}

export function replaceAliases (q) {
  // formulas
  if (q.formulaAliases) {
    mapFormulas(q, (_, formula, path) => {
      const replacement = replaceFormulaAliases(formula, q.formulaAliases);
      checkForCommonErrors(formula, q, path);
      return replacement;
    });
  }
}

function logCommonConfigIssues (q) {
   // questions
  Object.entries(q.questions).forEach(([key, question]) => {
    if (question.imgUrl) checkExternal(question.imgUrl, `key ${key}`);
    question.responses?.forEach((resp, index) => {
      if (resp.imgUrl) checkExternal(resp.imgUrl, `key ${key}, resp ${index}`);
    });
  });
}

function neutralizeNavigation (q) {
  mapFormulas(q, (_, formula, path) => {
    if (path.endsWith(".skipWhen")) {
      return [false];
    }
    if (path.endsWith(".displayWhen")) {
      return [true];
    }
    return formula;
  })
}

const initialAnswersFromPrefill = (state, questionnaire) => {
  return Object.fromEntries(
    Object.entries(state.prefill)
      .filter(([key]) => key in questionnaire.questions)
      .map(
        ([key, value]) => {
          const q = questionnaire?.questions[key];
          const id = q?.responses?.findIndex(r => r.value === value);
          return [key, {value, prefilled: true, answered: true, selected: [id]}];
        }));
}

const preprocessReport = (reportData) => {
  Object.keys(reportData.questions).forEach((key, index) => {
    reportData.questions[key].naturalOrder = index;
  });
}

const LOAD_LOCAL_QUESTIONNAIRES_ONLY = true;
const MAX_SCREENER_INDIRECTIONS = 1;

function currentDefinitionIsValid (q) {
  return true;
}

function getCachedDefinitonByType (state, type, language = Language.English) {
  const id = generateQuestionnaireKind({screener: type, locale: {language}});
  return state.questionnaire.questionnaires.entities[id];
}

/**
 * Load JSON from a URL and return it aynchronously
 * @param {string} url 
 * @returns any JSON object
 */
async function fetchAndUnwrap (url) {
  const res = await fetch(url);
  // TODO: rewrap errors as below?
  // console.error(`While fetching ${configURL}:`, error);
  // const err = new Error(`The «${screener}» questionnaire could not be loaded for ${locale.language} (${LanguageEndonym[locale.language]}).`);
  console.warn(res.status, res.statusText);
  if (res.ok) {
    console.log(`successfully fetched: ${res.url} [${res.status}]`);
    const json = await res.json();
    json.readFrom = url;
    return json;
  } else {
    throw new Error(`error fetching ${res.url} [${res.status}]: ${res.statusText}`);
  }
}

export const buildDebugQuestionnaire = createAsyncThunk(
  'debug-questionnaire',
  async ({type, locale, flags, definition}) => {
    return {screener: type, locale, ...definition};
  }
)

export const requestQuestionnaire = createAsyncThunk(
  'questionnaire',
  async ({type, locale, forReport, flags}, thunkAPI) => {
    if (!allows(type, locale.language)) {
      throw new Error(`The ${ScreenerNames[type]} screener is not available in ${dualName(locale.language)}`);
    }

    // const existing = getCachedDefinitonByType(thunkAPI.getState(), type, locale);
    
    if (LOAD_LOCAL_QUESTIONNAIRES_ONLY) {
      let filename = `${type}_${locale.language}.json`;
      // look up manual override for filename
      if (FileAliases.has(filename)) {
        filename = FileAliases.get(filename);
      }
      const configURL = `/content/screening/${filename}`;

      // Get the (first) definition file
      const initialDefinition = await fetchAndUnwrap(configURL);

      // Assert we have the type and locale we expected. Note that we only make
      // these assertions for the first retrieved (i.e. the target or leaf) and 
      // not its parents, since they may have different types or locales.
      if (initialDefinition.screener !== type) {
        throw new Error(`The questionnaire had screening type ${initialDefinition.screener} (expecting ${type})\n[in ${initialDefinition?.readFrom}]`);
      }
      if (initialDefinition.locale.language !== locale.language) {
        throw new Error(`The questionnaire had language ${initialDefinition.locale.language} (expecting ${locale.language})\n[in ${initialDefinition?.readFrom}]`);
      }

      const derivedScreeners = [];
      let currentDefinition = initialDefinition;
      while (typeof currentDefinition.base === "string") {
        if (derivedScreeners.length >= MAX_SCREENER_INDIRECTIONS) {
          throw new Error(`screener inheritance chain had more than maximum levels (${MAX_SCREENER_INDIRECTIONS})`);
        }
        derivedScreeners.push(currentDefinition);
        // TODO: validate that URL is internal? is that a security concern?
        currentDefinition = await fetchAndUnwrap(currentDefinition.base);
      }

      if (derivedScreeners.length === 0) {
        // simple case, we had no base screeners anywhere, so just return this one
        return initialDefinition;
      }
      const SEP = " ≣ "; // "\u2028"
      const translationHandler = (items) => {
        if (typeof items[0].translated === 'string') {
          if (flags?.dualLanguage) {
            return `${items[0].translated}\n${SEP}\n${items[0].original}`;
          }
          return items[0].translated;
        }
        return undefined;
      }
      return deepAssignMulti(translationHandler, ...derivedScreeners.concat(currentDefinition));
    } else { // LOAD_LOCAL_QUESTIONNAIRES_ONLY == false
      // TODO: some day we will likely switch back to a DB or DB-like storage
      // method, since files provide us with less control and more difficult
      // updates, but for now this is unused [tdhs]
      throw new Error("Database load is not enabled for this application version!");
    }
  }
)

function getAnswerKey (state, baseKey, basePage) {
  const details = (basePage?.details || state.navigation?.details);
  if (details?.multiplex) {
    return `${baseKey || details.key}[${state.navigation?.multiplexRow}]`;
  } else {
    return baseKey || details?.key;
  }
}

function updatedNavigationObject (index, pages, prevNav, multiplexUpdate = {}) {
  const details = pages?.[index] ?? {type: PageType.Empty};
  // if (!Number.isInteger(index) || index < 0 || index >= pages.length) {
  //   throw new Error(`Cannot set page number to ${index}, not in interval [0, ${pages.length})!`);
  // }
  return {
    number: index,
    details,
    lastMovement: index - prevNav.number,
    history: prevNav.history.concat(index),
    multiplex: {...prevNav.multiplex, ...multiplexUpdate},
    followups: {
      index: -1,
      forCurrent: []
    }
  };
}

function moveWithinMultiplex (state, movement) {
  const page = state.navigation;
  const q = getCurrentQuestionnaireDirect(state);
  const pages = q?.pages;
  const multiplexRow = page.multiplex.row;
  const multiplexRowCount = page.multiplex.rowCount;
  const questions = q?.questions;

  if (Math.abs(movement) !== 1) throw new Error(`multiplex move ${movement} was not size 1`);
  const mx = page.details.multiplex;
  const newColumns = pages.map((p, i) => [i , p])
    .filter(([i, p]) => (
      p.multiplex?.key === page.details.multiplex.key
       && !shouldSkipInternal(questions[p.key], `multiplex column ${p.key}`, state)));
  const columnIndex = newColumns.findIndex(([i, col]) => col.key === page.details.key);
  if (columnIndex === -1) throw new Error(`Illegal multiplex trigger behavior: disabled question ${page.details.key} as it was being left`);
  state.navigation.multiplex.columns = newColumns;
  const nextColumn = columnIndex + movement;
  const nextRow = multiplexRow + movement;
  if (mx.order === "by-question") {
    if (nextRow >= multiplexRowCount || nextRow < 0) {
      if (nextColumn in newColumns) {
        state.navigation = updatedNavigationObject(newColumns[nextColumn][0], pages, state.navigation, {row: positiveModulo(nextRow, state.navigation.multiplex.rowCount)});
        return true;
      }
      return false; // leaving the multiplex
    } else {
      state.navigation.multiplex.row = (nextRow);
      return true;
    }
  } else if (mx.order === "by-index") {
    if (nextColumn in newColumns) {
      // just go to next column in table
      state.navigation = updatedNavigationObject(newColumns[nextColumn][0], pages, state.navigation);
      return true;
    }
    // we need to wrap around
    if (nextRow >= multiplexRowCount || nextRow < 0) {
      // but we're out of indexes, so leave
      return false;
    } else {
      // the next index is a valid row
      state.navigation = updatedNavigationObject(wraparoundAccess(newColumns, nextColumn)[0], pages, state.navigation, {row: nextRow});
      return true;
    }
  } else {
    throw new Error(`Unknown multiplexing order: ${page.details.multiplex.order}`);
  }
}

function findNextPage (currentPageNumber, movement, q, s) {
  let targetNumber = currentPageNumber;
  let targetPage;
  let skipTarget;
  let multiplexUpdate = null;
  const sectionAnswers = {};
  const multiplexSizes = {};
  do {
    // advance
    targetNumber = targetNumber + movement;
    targetPage = q.pages[targetNumber];
    if (!targetPage) {
      console.error(`Could not navigate to page at index ${targetNumber}`);
      return [currentPageNumber, q.pages[currentPageNumber]];
    }

    // check section conditions first
    let skipSection;
    if (targetPage.sectionKey in sectionAnswers) {
      skipSection = sectionAnswers[targetPage.sectionKey];
    } else {
      const section = q.sections[targetPage.sectionKey];
      if (!section) {
        skipSection = false
      } else {
        skipSection = shouldSkipInternal(section, `sections.${section.title}`, s);
        sectionAnswers[targetPage.sectionKey] = skipSection;
      }
    }
    if (skipSection === true) {
      // if the section is supposed to be skipped we ignore question settings
      skipTarget = true;
    } else {
      // otherwise check the question for its own conditions
      if (targetPage.type === PageType.QuestionPage) {
        const question = targetPage.isDuplicate ? targetPage : q.questions[targetPage.key];
        skipTarget = shouldSkipInternal(question, targetPage.isDuplicate ? `questions.${targetPage.originalKey}{dupe of ${targetPage.key}}` : `questions.${targetPage.key}`, s) || false;
      } else if (targetPage.type === PageType.QuestionGroup || targetPage.type === PageType.QuestionTable) {
        const groupKeys = [targetPage.key].concat(targetPage.additionalKeys);
        const groupName = targetPage.key;
        const activeKeys = groupKeys.filter(k => !shouldSkipInternal(q.questions[k], `questions.${k}{in group ${groupName}}`, s));
        skipTarget = activeKeys.length === 0;
      } else if (targetPage.type === PageType.SectionIntro) {
        skipTarget = false;
      } else {
        // until defined, by default we stop at all other page types
        skipTarget = false;
      }

      // lastly if the target page is part of a multiplex, we have to check if
      // it has any size (rows) or not
      if (!skipTarget && targetPage.multiplex) {
        const multiplexKey = targetPage.multiplex.key;
        if (multiplexKey in multiplexSizes) {
          skipTarget = multiplexSizes[targetPage.multiplex.key] === 0;
        } else {
          let count = evaluator(targetPage.multiplex.formula, s.answers, getEvaluatorContext(s), false);
          if (!Number.isSafeInteger(count) || count <= 0) {
            console.error(`Expected multiplex formula to return positive integer, got ${count}... skipping`);
            count = 0;
          }
          multiplexSizes[multiplexKey] = count;
          skipTarget = count === 0;
          if (!skipTarget) {
            // NOTE: we can safely assume that we are newly-entering the
            // multiplex group because movements within the group should be
            // fully handled by moveWithinMultiplex and NOT require this fn
            multiplexUpdate = {
              rowCount: count,
              row: movement > 0 ? 0 : count - 1,
              columns: q.pages.map((p, i) => [i , p])
              .filter(([i, p]) => (
                p.multiplex?.key === multiplexKey
                && !shouldSkipInternal(q.questions[p.key], `quesionts.${p.key}{in multiplex ${multiplexKey}}`, s)))
            }
          }
        }
      }
    }
  } while (skipTarget)

  // TODO: middleware
  // if (targetPage.type === PageType.QuestionPage) {
  //   preArrival(targetPage);
  // }
  return [targetNumber, targetPage, multiplexUpdate];
}

function getEvaluatorContext (state) {
  return {...getCurrentQuestionnaireDirect(state), _currentRow: state.navigation.multiplex.row}
}

function shouldSkipInternal (skippableObj, logName, state) {
  return shouldSkip(skippableObj, state, logName);
}
export function shouldSkipCustomContext (skippableObj, answers, context, logName) {
  if (Array.isArray(skippableObj?.skipWhen)) {
    // console.log(`Checking ${logName} skipWhen:`);
    return evaluator(skippableObj.skipWhen, answers, context, true, false, logName+".skipWhen");
  } else if (Array.isArray(skippableObj?.displayWhen)) {
    // console.log(`Checking ${logName} displayWhen:`);
    return !evaluator(skippableObj.displayWhen, answers, context, true, false, logName+".displayWhen");
  }
  return null;
}
export function shouldSkip (skippableObj, qState, logName) {
  const context = getEvaluatorContext(qState);
  if (Array.isArray(skippableObj?.skipWhen)) {
    // console.log(`Checking ${logName} skipWhen:`);
    return evaluator(skippableObj.skipWhen, qState.answers, context, true, false, logName+".skipWhen");
  } else if (Array.isArray(skippableObj?.displayWhen)) {
    // console.log(`Checking ${logName} displayWhen:`);
    return !evaluator(skippableObj.displayWhen, qState.answers, context, true, false, logName+".displayWhen");
  }
  return null;
}

function hydrateNavForNewQuestionnaire (state, questionnaire) {
  const q = questionnaire ?? getCurrentQuestionnaireDirect(state);
  if (q) {
    state.navigation.details = q.pages[state.navigation.number];
    // TODO: fallback for not found?
  }
}

function updateTags (oldSelected, newSelected, value, answerKey, question, tags) {
  // const [added, removed] = deltaArrays(oldSelected, newSelected);
  question.responses.forEach((r, i) => {
    const wasSelected = oldSelected.indexOf(i) > -1;
    const isSelected = newSelected.indexOf(i) > -1;
    if (wasSelected) {
      if (isSelected) {
        // option stayed on, do nothing
      } else {
        // removed
        if (r.affirmsTag && tags[r.affirmsTag]?.sourceKey === answerKey) {
          delete tags[r.affirmsTag];
        } else if (r.deniesTag && tags[r.deniesTag]?.sourceKey === answerKey) {
          delete tags[r.deniesTag];
        }
      }
    } else {
      if (isSelected) {
        if (r.affirmsTag || r.deniesTag) {
          tags[r.affirmsTag ?? r.deniesTag] = ({
            sourceKey: answerKey,
            sourceMethod: r.affirmsTag ? "affirmsKey" : "deniesKey",
            sourceResponse: i,
            value: !!r.affirmsTag
          });
        }
      } else {
        // option stayed off, do nothing
      }
    }
  });
  if (question.setsTag) {
    tags[question.setsTag] = ({
      sourceKey: answerKey,
      sourceMethod: "setsTag",
      value
    });
  }
}

function wrapMetaValuesAsAnswers (value) {
  return {
    isMeta: true,
    selected: [],
    value
  }
}

function applyQuestionDefaults (def) {
  const questionDefaults = def.defaults?.questions ? Object.entries(def.defaults.questions) : [];
  const responseDefaults = def.defaults?.responses ? Object.entries(def.defaults.responses) : [];
  for (const question of Object.values(def.questions)) {
    questionDefaults.forEach(([dKey, dValue]) => {
      if (!(dKey in question)) {
        question[dKey] = dValue;
      }
    });
    question.responses.forEach((resp, index) => {
      responseDefaults.forEach(([dKey, dValue]) => {
        if (!(dKey in resp)) {
          resp[dKey] = dValue;
        }
      });
    });
  }
  // return def.questions;
}

function acceptNewQuestionnaire (state, action) {
  const id = action.meta.arg.flags?.overrideId || generateQuestionnaireKind(action.payload);
  const definition = {
    ...action.payload,
    id,
    // kind: generateQuestionnaireKind(action.payload),
    pages: buildPages(action.payload),
    status: Status.Ready
  };

  logCommonConfigIssues(definition);
  if (!action.meta.arg.flags?.noAliasReplacement) {
    replaceAliases(definition);
  }
  if (action.meta.arg.flags?.forceLinearPagination) {
    neutralizeNavigation(definition);
  }
  if (action.payload.defaults) {
    applyQuestionDefaults(definition);
  }
  // identifyDynamicText(definition);
  state.dynamicText = {}; // TODO: run once right away?
  preprocessReport(definition);


  questionnaireDefinitionAdapter.upsertOne(state.questionnaires, definition);
  // TODO: these should move to invite slice
  if (!action.meta.arg.forReport) {
    state.answers = {
      _bodyType: wrapMetaValuesAsAnswers(state.bodyTypePreference),
      _language: wrapMetaValuesAsAnswers(definition.locale?.language),
      _questionCount: wrapMetaValuesAsAnswers(Object.keys(definition.questions).length),
      ...initialAnswersFromPrefill(state, action.payload)
    };
    state.outcomes = {};
    state.questionnaires.current = id;
    state.timeStarted = Date.now();
  }

  if (!state.newestVersions[id] || compareSemver(definition.version, state.newestVersions[id]) > 0) {
    state.newestVersions[id] = definition.version;
  }
  hydrateNavForNewQuestionnaire(state);
}

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    changeAnswer (state, action) {
      const q = getCurrentQuestionnaireDirect(state);
      Object.entries(action.payload).forEach(([key, update]) => {
        if (q.questions?.[key]) {
          const oldSelected = state.answers[key]?.selected ?? [];
          const newSelected = update.selected ?? oldSelected;
          updateTags(oldSelected, newSelected, update.value, key, q.questions?.[key])
        }
        state.answers[key] = update;
      });
    },
    changeOutcomes (state, action) {
      state.outcomes = action.payload.outcomes;
    },
    setPageFromBrowserNav (state, action) {
      state.navigation = baseNavigationObject(action.payload);
    },
    setBodyTypePreference (state, action) {
      state.bodyTypePreference = action.payload;
    },
    /**
     * @param {{payload: {page: number}}} action
     */
    jumpToPage (state, action) {
      const index = action.payload.page;
      const pages = getCurrentQuestionnaireDirect(state)?.pages;
      state.navigation = updatedNavigationObject(index, pages, state.navigation);
    },
    walkPage (state, action) {
      let nextPageNumber, nextPage, multiplexUpdate;
      let q = getCurrentQuestionnaireDirect(state);

      if (action.payload.backward) {
        if (state.navigation.details.type === PageType.QuestionPage && state.navigation.followups.index > -1) {
          state.navigation.followups.index--;
          return;
        }
        if (state.navigation.number === 0) {
          return console.error("UI asked to go back while on first page");
        }
        switch (state.navigation.details.type) {
          case PageType.QuestionGroup:
          case PageType.QuestionPage:
            if (state.navigation.details.multiplex) {
              if (moveWithinMultiplex(state, -1)) break;
            }
            // eslint-disable-next-line no-fallthrough
          case PageType.QuestionTable:
          case PageType.SectionIntro:
          case PageType.ReviewReport:
            const [nextPageNumber, nextPage, multiplexUpdate] = findNextPage(state.navigation.number, -1, q, state);
            state.navigation = updatedNavigationObject(nextPageNumber, q.pages, state.navigation, multiplexUpdate);
            break;
          default:
            throw new Error(`Unhandled page type ${state.navigation.details.type}`);
        }
      } else {
        switch (state.navigation.details.type) {
          case PageType.QuestionPage:
            const followups = state.answers[getAnswerKey(state)]?.selected?.map(
              i => q.questions[state.navigation.details.key]?.responses?.[i]?.followupQuestions || []
            )?.flat() || [];
            state.navigation.followups.forCurrent = followups;
            if (followups.length > 0 && state.navigation.followups.index < (followups.length - 1)) {
              // if we have followups and we aren't at the last one yet, we just
              // advance forward in the list
              state.navigation.followups.index++;
              return;
            } else {
              // continue on to "standard" page movements
              // (followup index will naturally be reset when setPage is called)
            }
            // eslint-disable-next-line no-fallthrough
          case PageType.QuestionGroup:
            if (state.navigation.details.multiplex) {
              if (moveWithinMultiplex(state, 1)) break;
            }
            // eslint-disable-next-line no-fallthrough
          case PageType.QuestionTable: // tables aren't allowed followups OR multiplexes yet
          case PageType.SectionIntro:
          case PageType.ReviewReport:
            if (nextPageNumber === undefined)
              [nextPageNumber, nextPage, multiplexUpdate] = findNextPage(state.navigation.number, 1, q, state);
            if (nextPage?.type === PageType.SubmitPage) {
              if (q.status === Status.Ready) {
                // just update the page, the component will handle submission
                state.navigation = updatedNavigationObject(nextPageNumber, q.pages, state.navigation, multiplexUpdate);
              } else {
                throw new Error(`Attempted to submit while status was not READY (${q.status})`);
              }
            } else if (nextPageNumber > -1) {
              state.navigation = updatedNavigationObject(nextPageNumber, q.pages, state.navigation, multiplexUpdate);
              // recordPageNavigation(nextPageNumber);
            }
            break;
          default:
            throw new Error(`Unhandled page type ${state.navigation.details.type}`);
        }
      }
    },
    dismissFollowup (state, action) {
      state.navgiation.followups.index = -1;
    }
  },
  extraReducers: builder => {
    builder.addCase(requestQuestionnaire.pending, (state, action) => {
      questionnaireDefinitionAdapter.upsertOne(state.questionnaires,
        {id: generateQuestionnaireKind(action.meta.arg), status: Status.Loading});
    });
    builder.addCase(requestQuestionnaire.fulfilled, acceptNewQuestionnaire);
    builder.addCase(buildDebugQuestionnaire.fulfilled, acceptNewQuestionnaire);
    builder.addCase(requestQuestionnaire.rejected, (state, action) => {

    });
    builder.addCase(CHECK_PRE_CREATED_PATIENT_SUCCESS, (state, action) => {
      state.prefill = action.payload.user?.prefill || {};
      state.currentScreeningID = action.payload.screeningId;
      hydrateNavForNewQuestionnaire(state);
    });
    builder.addCase("dynamic-text/UPDATE", (state, action) => {
      state.dynamicText = {...state.dynamicText, ...action.payload};
    })
  }
});

const definitionSelectors = questionnaireDefinitionAdapter.getSelectors(state => state.questionnaire.questionnaires);

export const getQuestionnaireById = definitionSelectors.selectById;
export const getQuestionnaireByTypeAndKind = (state, type, locale) => {
  const id = generateQuestionnaireKind({screener: type, locale});
  return getQuestionnaireById(state, id);
}
export const getCurrentQuestionnaire = (state) => {
  return getQuestionnaireById(state, state.questionnaire.questionnaires.current);
};
function getCurrentQuestionnaireDirect (s) {
  return s.questionnaires.entities[s.questionnaires.current];
}

export const loadOrLocalQuestionnaire = ({type, locale}) => (dispatch, getState) => {
  const id = generateQuestionnaireKind({screener: type, locale});
  const cached = getQuestionnaireById(getState(), id);
  if (cached) {
    return Fulfilled(cached);
  }
  return dispatch(requestQuestionnaire({type, locale}));
}
// export const getAllUsers = userSelectors.selectAll;
// export const getUserById = userSelectors.selectById;
// const reportSelectors = reportsAdapter.getSelectors(state => state.admin.reports);
// export const getAllReports = reportSelectors.selectAll;
// export const getReportById = reportSelectors.selectById;

export const basicQuestionnaireActions = questionnaireSlice.actions;
export const changeAnswers = basicQuestionnaireActions.changeAnswer;
export const changeOutcomes = basicQuestionnaireActions.changeOutcomes;

export default questionnaireSlice.reducer;
