
// const userRef = db.collection('users');

export const UserClinicStatus = {
  NotFound: "not found",
  Created: "created",
  // AddedToClinic: "~", // for pre-existing users
  InviteSent: "invite sent",
  InviteReceived: "invite read",
  // DirectResponse: "incoming message",
  AppOpened: "invite opened",
  ReportReady: "report ready",
  ReportViewed: "report viewed",
  AppointmentCompleted: "appt. attended",
  AppointmentNoShow: "appt. no-show",
  Archived: "archived"
}

export const ScreeningStatus = {
  Scheduled: "scheduled",
  InviteSent: "sent",
  InviteOpened: "opened",
  Started: "started",
  ReportReady: "has-report",
  Inactive: "INACTIVE",
  Expired: "EXPIRED"
};

export const ScreeningStatusHR = {
  [ScreeningStatus.Scheduled]: "Scheduled",
  [ScreeningStatus.InviteSent]: "Invite Sent",
  [ScreeningStatus.InviteOpened]: "Link Opened",
  [ScreeningStatus.Started]: "Form Started",
  [ScreeningStatus.ReportReady]: "Report Complete",
  [ScreeningStatus.Inactive]: "Inactive",
  [ScreeningStatus.Inactive]: "Expired",

}

const ordered = [
  UserClinicStatus.NotFound,
  UserClinicStatus.Created,
  UserClinicStatus.InviteSent,
  UserClinicStatus.InviteReceived,
  UserClinicStatus.AppOpened,
  UserClinicStatus.ReportReady,
  UserClinicStatus.ReportViewed,
  UserClinicStatus.AppointmentNoShow,
  UserClinicStatus.AppointmentCompleted,
  UserClinicStatus.Archived
];
export const UserClinicStatusSort = (a, b) => ordered.indexOf(a) - ordered.indexOf(b);

export const UserInviteStatus = {
  Queued: "queued",
  Delivered: "sent",
  Read: "read",
  Interaction: "link clicked", // i.e. link clicked
  Complete: "complete",
  Changed: "changed (below)",
  // failures
  DeliveryFailed: "failed",
  Cancelled: "cancelled",
  Expired: "link expired" // if access key expires BEFORE interaction
}

export const User = {
  // getById: (uid) => {

  // }
}

