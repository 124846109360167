import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { educationActions, getAllModules } from "../../store/slices/education";
import { evaluator } from "../../utils/evaluator";
import { SIMPLE_ED_MODULE } from "../../constants/routes";
import { Col, Container, Row } from "../UI/Grid/Grid";
import { Input } from "../UI/Input/Input";
import { getCurrentQuestionnaire, buildDebugQuestionnaire } from "../../store/slices/questionnaire";
import { PlayAudio } from "../Audio/PlayAudio";
import { dualName, LanguageList } from "../../constants/locales";
import { Card, CardBody, CardTitle } from "../UI/Card/Card";
import QuestionnairePage from "../History/QuestionnairePage";

export const DebugPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modules = useSelector(getAllModules);
  const answers = useSelector(s => s.questionnaire.answers);
  const questionnaire = useSelector(getCurrentQuestionnaire);
  const [activeFlags, setActiveFlags] = useState([]);
  useEffect(() => {
    if (!Array.isArray(modules)) {
      if (Array.isArray(activeFlags)) setActiveFlags([]);
      return;
    }
    const newActive = modules.map(m => !!m.displayWhen && evaluator(m.displayWhen, answers, questionnaire, true));
    setActiveFlags(newActive);
  }, [modules, answers, questionnaire]);

  useEffect(() => {
    dispatch(buildDebugQuestionnaire({type: "DEBUG", locale: {language: "en"}, definition: {questions: {
      q1: {
        display: "options",
        text: "TEST",
      }
    }}}));
  }, [])

  function goToModule (module) {
    dispatch(educationActions.startModule(module.name));
    history.push(SIMPLE_ED_MODULE);
  }

  const [date, setDate] = useState(new Date());
  window.__updateDate = (d) => {
    setDate(d);
  }

  const [count, setCount] = useState(0);
  return <Container>
    <Row>
      <Col>
        <h3>SVG Demo</h3>
        <p>
          <strong>Current value:</strong>{count}
        </p>
        <p>
          <button onClick={() => setCount(count + 1)} className="btn btn-sm btn-info m-2">Increase</button>
          <button onClick={() => setCount(count - 1)} className="btn btn-sm btn-danger m-2">Decrease</button>
        </p>
        <svg width="600" height="600" viewBox="0 0 800 800" style={{border: "1px solid gray", padding: "10px"}}>
          <g fill="blue">
            <rect x="50" y="50" width="200" height="200" opacity={count >= 1 ? 1.0 : 0.2}/>
            <rect x="300" y="50" width="200" height="200" opacity={count >= 2 ? 1.0 : 0.2}/>
            <rect x="550" y="50" width="200" height="200" opacity={count >= 3 ? 1.0 : 0.2}/>
            <rect x="50" y="300" width="200" height="200" opacity={count >= 4 ? 1.0 : 0.2}/>
            <rect x="300" y="300" width="200" height="200" opacity={count >= 5 ? 1.0 : 0.2}/>
            <rect x="550" y="300" width="200" height="200" opacity={count >= 6 ? 1.0 : 0.2}/>
            <rect x="50" y="550" width="200" height="200" opacity={count >= 7 ? 1.0 : 0.2}/>
            <rect x="300" y="550" width="200" height="200" opacity={count >= 8 ? 1.0 : 0.2}/>
            <rect x="550" y="550" width="200" height="200" opacity={count >= 9 ? 1.0 : 0.2}/>
          </g>
        </svg>
      </Col>
    </Row>
  </Container>;
}