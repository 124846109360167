import React, { useEffect, useRef, useState } from "react";
import { FaVolumeDown, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { connect, useDispatch, useSelector } from "react-redux";
import audio, { getAudioSource, playLocalAudio, resolveAudioURLs } from "../../store/slices/audio";
import "../../styles/icons.css";

export const PlayAudio = ({
  filename,
  skipLoad
}) => {
  const dispatch = useDispatch();
  const activeAudio = useSelector(s => s.audio.activeAudio);
  const audioObject = useSelector(s => {
    return getAudioSource(s, filename);
  })
  const LOADING_GRACE_PERIOD = 1500;
  const [gracePeriod, setGracePeriod] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {setGracePeriod(false)}, LOADING_GRACE_PERIOD);
    return () => clearTimeout(timer);
  }, []);

  /** @type {"not_loaded" | "playable" | "not_found" | "playback_error" | "playing"} */
  const [status, setStatus] = useState("not_loaded");

  useEffect(() => {
    if (activeAudio?.filename === filename && activeAudio?.playing) {
      setStatus("playing");
      return;
    }
    if (audioObject) {
      if (audioObject.error) {
        setStatus("playback_error");
      } else if (!audioObject.src) {
        setStatus("not_found");
      } else {
        setStatus("playable");
      }
    } else {
      setStatus("not_loaded");
    }
  }, [audioObject, activeAudio]);

  useEffect(() => {
    if (filename && !skipLoad) {
      dispatch(resolveAudioURLs({filenames: [filename]}))
    }
  }, [filename, skipLoad])

  function togglePlay (event) {
    if (status === "playable") {
      dispatch(playLocalAudio(filename));
    } else {
      console.warn(`Requested to play unplayable audio: "${filename}" (status ${status})`)
    }
    event.stopPropagation();
  }

  return <span onClick={e => togglePlay(e)}>
      {status === "not_loaded" ? (gracePeriod ? <FaVolumeDown color="gray" /> : <FaVolumeMute />) : null}
      {status === "not_found" ? <FaVolumeMute /> : null}
      {status === "playback_error" ? 
       /* imported directly from fa_derived_volume_error.svg, manually trimmed */
        <span
          className="text-danger"
          style={{opacity: 0.5 /*color: "#52373a"*/}}
          title={"We had an issue trying to play this sound."}>
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 500 500"
          height="1em"
          width="1em">
          <defs
            id="defs176" />
          <path
            d="M 245.03,71.05 156.06,160 H 54 c -13.26,0 -24,10.74 -24,24 v 144 c 0,13.25 10.74,24 24,24 h 102.06 l 88.97,88.95 C 260.06,455.98 286,445.42 286,423.98 V 88.02 C 286,66.56 260.04,56.04 245.03,71.05 Z"
            id="path170" />
          <path
            d="m 462.01127,357.91074 c 0,25.31088 -20.59205,45.90293 -45.90293,45.90293 -25.31088,0 -45.90293,-20.59205 -45.90293,-45.90293 0,-25.31087 20.59205,-45.90293 45.90293,-45.90293 25.31088,0 45.90293,20.59206 45.90293,45.90293 z m -86.4926,-233.41698 7.8035,156.06997 c 0.36665,7.32898 6.4155,13.08291 13.75367,13.08291 h 38.065 c 7.33816,0 13.38701,-5.75393 13.75366,-13.08291 l 7.8035,-156.06997 c 0.39305,-7.86604 -5.87786,-14.45886 -13.75366,-14.45886 h -53.67201 c -7.87579,0 -14.1467,6.59282 -13.75366,14.45886 z"
            id="path282"
            style={{strokeWidth: 0}} />
        </svg>
        </span>
        : null}
      {status === "playable" ? <FaVolumeUp /> : null}
      {status === "playing" ? <FaVolumeUp color="green" /> : null}
    </span>;
}

export default PlayAudio;